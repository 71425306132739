var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import DropDown from '../DropDown/DropDown';
export var Wrapper = styled(DropDown)(function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    & .c-dropdown-select {\n      margin-top: ", " !important;\n\n      &.drop-down-trigger {\n        width: 100%;\n        padding: 6px 40px 6px 10px;\n        background: #fff;\n        text-align: left;\n      }\n\n      &.drop-down-icon {\n        position: absolute;\n        top: 0;\n        right: 0;\n        padding: 6px 11px;\n        color: #fff;\n\n        & svg {\n          margin: 0 !important;\n        }\n      }\n\n      &.dropdown-show-content {\n        top: 101%;\n        width: 100%;\n        animation: fadeIn 0.3s;\n        box-shadow: none;\n\n        &:before {\n          content: none;\n        }\n      }\n\n      &.c-dropdown-select_content {\n        padding: ", ";\n      }\n    }\n\n    @keyframes fadeIn {\n      from {\n        opacity: 0;\n      }\n      to {\n        opacity: 1;\n      }\n    }\n  "], ["\n    & .c-dropdown-select {\n      margin-top: ", " !important;\n\n      &.drop-down-trigger {\n        width: 100%;\n        padding: 6px 40px 6px 10px;\n        background: #fff;\n        text-align: left;\n      }\n\n      &.drop-down-icon {\n        position: absolute;\n        top: 0;\n        right: 0;\n        padding: 6px 11px;\n        color: #fff;\n\n        & svg {\n          margin: 0 !important;\n        }\n      }\n\n      &.dropdown-show-content {\n        top: 101%;\n        width: 100%;\n        animation: fadeIn 0.3s;\n        box-shadow: none;\n\n        &:before {\n          content: none;\n        }\n      }\n\n      &.c-dropdown-select_content {\n        padding: ", ";\n      }\n    }\n\n    @keyframes fadeIn {\n      from {\n        opacity: 0;\n      }\n      to {\n        opacity: 1;\n      }\n    }\n  "])), theme.spacing.small, theme.spacing.small);
});
var templateObject_1;
