var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var svgSizes = {
    'xx-small': '0.5rem',
    'x-small': '0.75rem',
    small: '0.875rem',
    medium: '1rem',
    'x-medium': '2.75rem',
    large: '1.125rem',
    'x-large': '1.25rem',
    'xx-large': '1.5rem',
    'xxx-large': '1.75rem',
    'xxxx-large': '3.75rem',
};
export var Wrapper = styled('svg')(function (_a) {
    var $size = _a.$size;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    fill: currentColor;\n    width: ", ";\n    height: ", ";\n\n    svg {\n      pointer-events: none;\n    }\n  "], ["\n    fill: currentColor;\n    width: ", ";\n    height: ", ";\n\n    svg {\n      pointer-events: none;\n    }\n  "])), svgSizes[$size], svgSizes[$size]);
});
var templateObject_1;
