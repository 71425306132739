var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var TextStyled = styled('p')(function (_a) {
    var theme = _a.theme, $variant = _a.$variant, $color = _a.$color, $margin = _a.$margin, $lineClamp = _a.$lineClamp;
    var _b = theme.typography[$variant], fontSize = _b.fontSize, fontWeight = _b.fontWeight, lineHeight = _b.lineHeight, fontFamily = _b.fontFamily;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin: ", ";\n    font-size: ", ";\n    font-family: ", ";\n    font-weight: ", ";\n    line-height: ", ";\n    color: ", ";\n    &::first-letter {\n      /* text-transform: capitalize; */\n    }\n    ", "\n    .page-header__title {\n      h1 {\n        font-weight: 700;\n      }\n    }\n  "], ["\n    margin: ", ";\n    font-size: ", ";\n    font-family: ", ";\n    font-weight: ", ";\n    line-height: ", ";\n    color: ", ";\n    &::first-letter {\n      /* text-transform: capitalize; */\n    }\n    ", "\n    .page-header__title {\n      h1 {\n        font-weight: 700;\n      }\n    }\n  "])), $margin || 0, fontSize, fontFamily, fontWeight, lineHeight, theme.palette.text[$color], $lineClamp
        ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          display: -webkit-box;\n          -webkit-line-clamp: ", ";\n          -webkit-box-orient: vertical;\n          overflow: hidden;\n\n          @media all and (-ms-high-contrast: none),\n            (-ms-high-contrast: active) {\n            /* IE fallback */\n            position: relative;\n            ", "\n          }\n        "], ["\n          display: -webkit-box;\n          -webkit-line-clamp: ", ";\n          -webkit-box-orient: vertical;\n          overflow: hidden;\n\n          @media all and (-ms-high-contrast: none),\n            (-ms-high-contrast: active) {\n            /* IE fallback */\n            position: relative;\n            ", "\n          }\n        "])), $lineClamp, $lineClamp === 1
            ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n                  text-overflow: ellipsis;\n                  white-space: nowrap;\n                "], ["\n                  text-overflow: ellipsis;\n                  white-space: nowrap;\n                "]))) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n                  max-height: calc(", " * ", ");\n                  &:after {\n                    content: '...';\n                    position: absolute;\n                    bottom: 0;\n                    left: 0;\n                    text-align: right;\n                    /* height: ", "; */\n                  }\n                "], ["\n                  max-height: calc(", " * ", ");\n                  &:after {\n                    content: '...';\n                    position: absolute;\n                    bottom: 0;\n                    left: 0;\n                    text-align: right;\n                    /* height: ", "; */\n                  }\n                "])), lineHeight, $lineClamp, lineHeight)) : '');
});
export { TextStyled };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
