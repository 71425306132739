var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Switch from '@commons/Switch';
import { debounce } from 'services/AppUtils';
import SearchStore from 'stores/SearchStore';
import DateSelectorSearch from 'new-components/DateSelectorSearch';
import { Wrapper } from './FavoriteSearchDate.styles';
var propTypes = {
    lastPeriod: PropTypes.any.isRequired,
    onUpdateState: PropTypes.func.isRequired,
    isEnabled: PropTypes.bool.isRequired,
};
var FavoriteSearchDate = function (_a) {
    var isEnabled = _a.isEnabled, onUpdateState = _a.onUpdateState, lastPeriod = _a.lastPeriod;
    var onToggleDateSelectorHandler = function () {
        onUpdateState({
            dateFilterActive: !isEnabled,
        }, function () {
            onChange(dateMode, dates, true);
        });
    };
    useEffect(function () {
        km.global.lastPeriod = __assign({}, lastPeriod);
        SearchStore.setLastPeriod(__assign({}, lastPeriod));
    }, [lastPeriod]);
    var handleDateSelectorChange = function (mode, dates) {
        if (mode === 'custom' &&
            (!dates.universalDateFrom || !dates.universalDateTo)) {
            return;
        }
        SearchStore.setLogicalDate(mode);
        SearchStore.setLastPeriod({
            from: moment(dates.universalDateFrom).format('YYYY-MM-DD HH:mm:ss'),
            to: moment(dates.universalDateTo).format('YYYY-MM-DD HH:mm:ss'),
            logicalDate: SearchStore.getLogicalDate(true),
            dateType: SearchStore.getDateType(),
        });
        km.global.lastPeriod = {
            from: moment(dates.universalDateFrom).format('YYYY-MM-DD HH:mm:ss'),
            to: moment(dates.universalDateTo).format('YYYY-MM-DD HH:mm:ss'),
            logicalDate: SearchStore.getLogicalDate(true),
            dateType: SearchStore.getDateType(),
        };
        // QueryBuilderStore.resetFieldValues();
        // updateFilter();
    };
    var setDateSelectorDisableState = function () {
        if (disabled)
            return true;
        if (!isEnabled)
            return true;
        return false;
    };
    return (_jsxs(Wrapper, { className: "c-date-filter", children: [_jsx(Switch, { className: "enable-filters", onChange: debounce(onToggleDateSelectorHandler, 100), value: isEnabled ? 1 : 0, styles: {
                    track: {
                        backgroundColor: '#ddd',
                    },
                    trackChecked: {
                        backgroundColor: '#2196f3',
                    },
                    button: {
                        backgroundColor: '#999',
                    },
                    buttonChecked: {
                        backgroundColor: '#eee',
                    },
                }, children: _jsx("span", { className: "hidden", children: "enable widget date filter" }) }), _jsx(DateSelectorSearch, { mode: "favoriteSearch", lastPeriod: km.global.lastPeriod, onChange: handleDateSelectorChange, disabled: isEnabled })] }));
};
FavoriteSearchDate.propTypes = propTypes;
export default FavoriteSearchDate;
