var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable eqeqeq */
import ActionTypes from '@constants/actionTypes';
import parameters from '@constants/parameters';
import BaseStore from '@stores/BaseStore';
var CODIFICATION_TYPE_ENUM = parameters.CODIFICATION_TYPE_ENUM;
var GET_CODIFICATION_EVENT = 'getCodification';
var GET_EVALUATION_EVENT = 'getEvaluation';
var SAVE_EVALUATION_EVENT = 'saveEvaluation';
var GET_MEDIA_IMPACT_SCORE_EVENT = 'getMediaImpactScore';
var CodificationStore = /** @class */ (function (_super) {
    __extends(CodificationStore, _super);
    function CodificationStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    CodificationStore.prototype.initializeState = function () {
        this.form = null;
        this.evaluationModel = null;
        this.evaluations = [];
        this.lockStatus = null;
        this.misModel = null;
        this.mediaImpactScore = 0;
    };
    CodificationStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(CodificationStore.prototype, "codificationForm", {
        get: function () {
            if (!this.form)
                return [];
            return this.form;
        },
        enumerable: false,
        configurable: true
    });
    CodificationStore.prototype.getGaugeVisible = function () {
        if (!this.form)
            return false;
        return (this.form.find(function (field) {
            return field.displayName.includes('MediaImpactScore');
        }) !== undefined);
    };
    CodificationStore.prototype.getEvaluationForm = function () {
        return this.evaluationModel;
    };
    CodificationStore.prototype.getLockStatus = function () {
        return this.lockStatus;
    };
    CodificationStore.prototype.setLockStatus = function (lockStatus) {
        this.lockStatus = lockStatus;
    };
    CodificationStore.prototype.setCodification = function (model) {
        if (!model) {
            this.form = [];
            return;
        }
        // CustomersMertic in store are mutated to have boolean wit bool value and string with empty string ''
        var newModel = __spreadArray([], model, true).map(function (field) {
            var f = field;
            if (field.type === CODIFICATION_TYPE_ENUM.boolean) {
                f.customerMetricValue = false;
            }
            if (field.type === CODIFICATION_TYPE_ENUM.string) {
                f.customerMetricValue = '';
            }
            return f;
        });
        this.form = newModel;
    };
    CodificationStore.prototype.setMisModel = function (model) {
        this.misModel = model;
    };
    CodificationStore.prototype.setEvaluations = function (clipsIds, evaluations) {
        var _this = this;
        if (clipsIds.length && evaluations) {
            clipsIds.forEach(function (clipId) {
                var evaluation = _this.evaluations.find(function (_a) {
                    var customerClipId = _a.customerClipId;
                    return customerClipId === clipId;
                });
                if (evaluation) {
                    evaluation.customerMetrics = evaluations;
                }
                else {
                    _this.evaluations.push({
                        customerClipId: clipId,
                        customerMetrics: evaluations,
                    });
                }
            });
        }
    };
    CodificationStore.prototype.setAddedValues = function (values) {
        var _this = this;
        if (!values || values.length === 0)
            return;
        values.forEach(function (addedValue) {
            var field = _this.getFieldById(addedValue.customerMetricId);
            // @ts-expect-error TODO: get all customerMetricValue
            field === null || field === void 0 ? void 0 : field.customerMetricValue.push({
                customerMetricValueId: addedValue.customerMetricValueId,
                value: addedValue.value,
                customerMetricId: addedValue.customerMetricId,
            });
        });
    };
    CodificationStore.prototype.setNewState = function (state, values) {
        var _this = this;
        if (!values || values.length === 0)
            return;
        var newState = state;
        values.forEach(function (addedValue) {
            var field = _this.getFieldById(addedValue.customerMetricId);
            if (field) {
                var stateValue = newState[field.storageName];
                var index = (Array.isArray(stateValue) &&
                    stateValue.findIndex(function (v) { return v === addedValue.value; })) ||
                    null;
                if (index) {
                    newState[field.storageName][index] =
                        addedValue.customerMetricValueId;
                }
                else {
                    newState[field.storageName] = addedValue.customerMetricValueId;
                }
            }
        });
    };
    CodificationStore.prototype.setMediaImpactScore = function (value) {
        this.mediaImpactScore = value;
    };
    Object.defineProperty(CodificationStore.prototype, "mediaImpactScoreModel", {
        get: function () {
            return this.misModel;
        },
        enumerable: false,
        configurable: true
    });
    CodificationStore.prototype.getMediaImpactScore = function () {
        return this.mediaImpactScore;
    };
    CodificationStore.prototype.getEvaluationByClipId = function (clipId) {
        var _a;
        return (_a = this.evaluations.find(function (ev) { return ev.customerClipId === clipId; })) === null || _a === void 0 ? void 0 : _a.customerMetrics;
    };
    CodificationStore.prototype.getLockStatusByClipId = function (clipId) {
        var _a;
        var clip;
        if (this.lockStatus) {
            clip = (_a = this.lockStatus) === null || _a === void 0 ? void 0 : _a.find(function (ls) {
                if (ls && ls.customerClipId === clipId) {
                    return ls;
                }
                return false;
            });
        }
        return clip;
    };
    CodificationStore.prototype.getLockStatusByClipsIds = function (clipsIds) {
        var _this = this;
        var lockedClips = [];
        clipsIds.forEach(function (clipId) {
            var lc = _this.getLockStatusByClipId(clipId);
            if (lc) {
                lockedClips.push(lc);
            }
        });
        return lockedClips;
    };
    CodificationStore.prototype.getLockedClipsByOther = function (clipsIds) {
        var _this = this;
        var lockedClips = [];
        clipsIds.forEach(function (clipId) {
            var lc = _this.getLockStatusByClipId(clipId);
            if ((lc === null || lc === void 0 ? void 0 : lc.isLocked) && !lc.islockedByMe) {
                lockedClips.push(lc);
            }
        });
        return lockedClips;
    };
    CodificationStore.prototype.getEvaluationByClipIds = function (clipsIds) {
        var _this = this;
        var evals = [];
        clipsIds.forEach(function (clipId) {
            var evaluation = _this.getEvaluationByClipId(clipId);
            if (evaluation) {
                evals.push(evaluation);
            }
        });
        return evals;
    };
    CodificationStore.prototype.getFieldByName = function (fieldName) {
        var _a;
        var field = (_a = this.form) === null || _a === void 0 ? void 0 : _a.find(function (f) { return f.storageName === fieldName; });
        if (field) {
            return __assign({}, field);
        }
    };
    CodificationStore.prototype.getFieldById = function (id) {
        var _a;
        var field = (_a = this.form) === null || _a === void 0 ? void 0 : _a.find(function (f) { return f.customerMetricId == Number(id) || f.storageName === String(id); });
        if (field) {
            return __assign({}, field);
        }
    };
    CodificationStore.prototype.resetEvaluations = function () {
        this.evaluationModel = null;
        this.evaluations = [];
        this.lockStatus = null;
    };
    /* GET CODIFICATION EVENTS */
    CodificationStore.prototype.emitGetCodification = function () {
        this.emit(GET_CODIFICATION_EVENT);
    };
    CodificationStore.prototype.addGetCodificationListener = function (callback) {
        this.on(GET_CODIFICATION_EVENT, callback);
    };
    CodificationStore.prototype.removeGetCodificationListener = function () {
        this.removeAllListeners(GET_CODIFICATION_EVENT);
    };
    /* GET EVALUATIONS EVENTS */
    CodificationStore.prototype.emitGetEvaluation = function () {
        this.emit(GET_EVALUATION_EVENT);
    };
    CodificationStore.prototype.addGetEvaluationListener = function (callback) {
        this.on(GET_EVALUATION_EVENT, callback);
    };
    CodificationStore.prototype.removeGetEvaluationListener = function () {
        this.removeAllListeners(GET_EVALUATION_EVENT);
    };
    /* SAVE EVALUATIONS EVENTS */
    CodificationStore.prototype.emitSaveEvaluation = function (model) {
        this.emit(SAVE_EVALUATION_EVENT, model);
    };
    CodificationStore.prototype.addSaveEvaluationListener = function (callback) {
        this.on(SAVE_EVALUATION_EVENT, callback);
    };
    CodificationStore.prototype.removeSaveEvaluationListener = function () {
        this.removeAllListeners(SAVE_EVALUATION_EVENT);
    };
    /* GET MEDIA IMPACT SCORE EVENTS */
    CodificationStore.prototype.emitGetMediaImpactScore = function (value) {
        this.emit(GET_MEDIA_IMPACT_SCORE_EVENT, value);
    };
    CodificationStore.prototype.addGetMediaImpactScoreListener = function (callback) {
        this.on(GET_MEDIA_IMPACT_SCORE_EVENT, callback);
    };
    CodificationStore.prototype.removeGetMediaImpactScoreListener = function () {
        this.removeAllListeners(GET_MEDIA_IMPACT_SCORE_EVENT);
    };
    CodificationStore.prototype.registerToActions = function (payload) {
        var _a;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_CUSTOMERMETRICS_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.setCodification(data.customerMetrics);
                    this.setMediaImpactScore(data.mediaImpactScore);
                    this.setMisModel(data.fieldsToCalculeMIS);
                    this.initialized = true;
                    this.emitChange();
                }
                else {
                    this.emitError('GET_CUSTOMERMETRICS_RESPONSE');
                }
                break;
            case ActionTypes.GET_EVALUATION_RESPONSE:
                if (action.json) {
                    var _b = action.json, data = _b.data, model = _b.model;
                    if (data.customerMetrics) {
                        var clipsIds = model.clipsIds.map(function (_a) {
                            var id = _a.id;
                            return id;
                        });
                        this.setEvaluations(clipsIds, data.customerMetrics);
                    }
                    this.setLockStatus(data.customerClipLockStauts);
                    this.emitGetEvaluation();
                }
                else {
                    this.emitError('GET_EVALUATION_RESPONSE');
                }
                break;
            case ActionTypes.SAVE_EVALUATION_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    var state = JSON.parse(action.json.model.metricValues);
                    this.setAddedValues(data.addedCustomerMetricValues);
                    this.setNewState(state, data.addedCustomerMetricValues);
                    // this.evaluationModel = state;
                    this.emitSaveEvaluation({
                        user: (_a = data.notUpdatedClips[0]) === null || _a === void 0 ? void 0 : _a.lockedByName,
                        updatedClips: data.updatedClips.length,
                        notUpdatedClips: data.notUpdatedClips.length,
                    });
                }
                else {
                    this.emitError('GET_EVALUATION_RESPONSE');
                }
                break;
            case ActionTypes.ASSIGN_TO_ME_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.setLockStatus(data);
                    this.emitChange('assignToMe');
                }
                break;
            case ActionTypes.UNLOCK_CLIPS_RESPONSE:
                if (action.json) {
                    this.emitChange('unlockClips');
                }
                break;
            case ActionTypes.GET_MEDIA_IMPACT_SCORE_RESPONSE:
                if (action.json) {
                    this.emitGetMediaImpactScore(action.json.data);
                }
                break;
            default:
                break;
        }
    };
    return CodificationStore;
}(BaseStore));
export default new CodificationStore();
