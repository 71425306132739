import ActionTypes from '@constants/actionTypes';
import config from '@constants/parameters';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
import { postFetch } from '@services/AppUtils';
var LoginActionCreators = {
    login: function (model) {
        postFetch('/api/access/login', model, function (json) {
            Dispatcher.handleServerAction({
                actionType: ActionTypes.GET_LOGIN_RESPONSE,
                errors: {},
                json: json,
            });
        });
    },
    publicLogin: function (model) {
        postFetch('/api/access/publiclogin', model, function (json) {
            Dispatcher.handleServerAction({
                actionType: ActionTypes.GET_PUBLIC_LOGIN_RESPONSE,
                errors: {},
                json: json,
            });
        });
    },
    loginWithTicket: function (model, callback) {
        postFetch('/api/Access/LoginWithTicket', model, function (json) {
            Dispatcher.handleServerAction({
                actionType: ActionTypes.GET_LOGIN_WITH_TICKET_RESPONSE,
                errors: {},
                json: json,
            });
            callback(json);
        });
    },
    getSupportContacts: function () {
        WebApi.getSupportContacts();
    },
    getBanners: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_NEWS_BANNERS_REQUEST,
            data: model,
        });
        WebApi.getNewsBanners(model);
    },
    getIsLoginSSOPage: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_IS_LOGIN_REQUEST,
            data: model,
        });
        WebApi.getIsLoginSSOPage(model, callback);
    },
    logOut: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_LOGOUT_REQUEST,
        });
        window.localStorage.removeItem('LoginSSO');
        window.localStorage.removeItem('SESSION');
        window.localStorage.removeItem('UserSession');
        window.location.replace("".concat(config.API_ROOT, "/api/Access/LogOff"));
    },
    sendEmail: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.SEND_EMAIL_SUPPORT_CONTACTS_REQUEST,
        });
        WebApi.sendEmail(model);
    },
};
export default LoginActionCreators;
