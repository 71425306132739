var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import ActionTypes from 'constants/actionTypes';
import objectHash from 'object-hash';
import { createDateAsUTC } from 'services/AppUtils';
import BaseStore from 'stores/BaseStore';
import ClipStore from 'stores/ClipStore';
import moment from 'moment';
import { parseDate } from 'services/DateUtils';
var CONSUMPTION_EVENT = 'consumption';
var PDF_STATE_EVENT = 'pdfState';
var SUCCESS_CREATION_EVENT = 'successCreation';
var ACCESS_REVIEW_BY_TYPE_EVENT = 'accessReviewByType';
var ReviewStore = /** @class */ (function (_super) {
    __extends(ReviewStore, _super);
    function ReviewStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this._registerToActions.bind(_this); });
        return _this;
    }
    ReviewStore.setAccountLogo = function (review, accountLogo) {
        if (accountLogo === void 0) { accountLogo = ''; }
        if (typeof accountLogo === 'string') {
            review.accountLogo = accountLogo;
        }
    };
    ReviewStore.setAccountName = function (review, accountName) {
        if (accountName === void 0) { accountName = ''; }
        if (typeof accountName === 'string') {
            review.accountName = accountName;
        }
    };
    ReviewStore.setAuthor = function (review, author) {
        if (author === void 0) { author = ''; }
        if (typeof author === 'string') {
            review.author = author;
        }
    };
    ReviewStore.setGlobalPdf = function (review, globalPdf) {
        if (globalPdf === void 0) { globalPdf = 0; }
        if (typeof globalPdf === 'number') {
            review.globalPdf = globalPdf;
        }
    };
    ReviewStore.setClipAuthor = function (clip, author) {
        if (author === void 0) { author = ''; }
        if (typeof author === 'string') {
            clip.author = author;
        }
    };
    ReviewStore.setTotalClips = function (review, totalClips) {
        if (totalClips === void 0) { totalClips = 0; }
        if (typeof totalClips === 'number') {
            review.totalClips = totalClips;
        }
    };
    ReviewStore.setTreeCollection = function (review, treeCollection) {
        if (treeCollection === void 0) { treeCollection = []; }
        treeCollection.forEach(function (tree) {
            if (tree.clips) {
                tree.clips.forEach(function (clip) {
                    clip.deliveredDate = parseDate(clip.deliveredDate);
                    clip.publicationDate = parseDate(clip.publicationDate);
                    ReviewStore.setClipAuthor(clip, clip.author);
                });
            }
        });
        review.treeCollection = treeCollection;
    };
    ReviewStore.setDefaultTab = function (review, defaultTab) {
        if (defaultTab === void 0) { defaultTab = {}; }
        review.defaultTab = defaultTab;
    };
    ReviewStore.prototype.initializeState = function () {
        this._pdf = {};
        this._reviews = [];
        this._reviewsTotal = 0;
        this._searches = {};
        this.selectedTab = '';
        var reviewModel = localStorage.getItem('reviewModel');
        this._reviewModel =
            reviewModel && reviewModel !== 'undefined'
                ? JSON.parse(reviewModel)
                : {
                    model: {
                        UniversalDateFrom: moment()
                            .startOf('day')
                            .format('YYYYMMDDHHmmss'),
                        UniversalDateTo: moment(new Date()).format('YYYYMMDDHHmmss'),
                    },
                };
    };
    ReviewStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        localStorage.removeItem('reviewModel');
        localStorage.removeItem('modePage');
        this.initializeState();
    };
    ReviewStore.prototype.setReviewModel = function (model) {
        if (model) {
            localStorage.setItem('reviewModel', JSON.stringify(model));
            this._reviewModel = __assign({}, model);
        }
    };
    ReviewStore.prototype.setReviewModelTitle = function (title) {
        var newModel = __assign(__assign({}, this._reviewModel), { title: title });
        localStorage.setItem('reviewModel', JSON.stringify(newModel));
        this._reviewModel = newModel;
    };
    ReviewStore.prototype.setReviewModelType = function (selectType) {
        var newModel = __assign(__assign({}, this._reviewModel), { selectType: selectType });
        localStorage.setItem('reviewModel', JSON.stringify(newModel));
        this._reviewModel = newModel;
    };
    ReviewStore.prototype.setReviewModelIdAndIndexName = function (id, indexName) {
        this.setReviewModel(__assign(__assign({}, this._reviewModel), { draftInfo: { id: id, indexName: indexName } }));
    };
    Object.defineProperty(ReviewStore.prototype, "reviewModel", {
        get: function () {
            if (Object.keys(this._reviewModel).length === 0) {
                return {
                    model: {
                        UniversalDateFrom: moment()
                            .startOf('day')
                            .format('YYYYMMDDHHmmss'),
                        UniversalDateTo: moment()
                            .endOf('day')
                            .format('YYYYMMDDHHmmss'),
                        showReview: true,
                        showDraft: false,
                    },
                    type: 'today',
                };
            }
            return this._reviewModel;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ReviewStore.prototype, "pdf", {
        get: function () {
            return this._pdf;
        },
        set: function (value) {
            this._pdf = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ReviewStore.prototype, "reviews", {
        get: function () {
            return this._reviews;
        },
        set: function (value) {
            this._reviews = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ReviewStore.prototype, "searches", {
        get: function () {
            return this._searches;
        },
        enumerable: false,
        configurable: true
    });
    ReviewStore.prototype.getSelectedTab = function () {
        return this.selectedTab;
    };
    ReviewStore.prototype.setSelectedTab = function (value) {
        this.selectedTab = value;
    };
    ReviewStore.prototype.getDefaultTab = function (hash) {
        var review = this.getReviewByHash(hash);
        return review.defaultTab;
    };
    ReviewStore.prototype.getReviewsTotal = function () {
        return this._reviewsTotal;
    };
    ReviewStore.prototype.getSearchHash = function (model) {
        var _a;
        var modelWSkip = __assign({}, model);
        delete modelWSkip.skip;
        var searchHash = objectHash(modelWSkip);
        return ((_a = this.searches[searchHash]) === null || _a === void 0 ? void 0 : _a.length) ? searchHash : null;
    };
    ReviewStore.prototype.addReview = function (_a) {
        var creationDate = _a.creationDate, hash = _a.hash, title = _a.title, isDraft = _a.isDraft, pushmailJobId = _a.pushmailJobId, indexName = _a.indexName, id = _a.id;
        var review = this.getReviewByHash(hash);
        var date = new Date(creationDate);
        if (Object.prototype.toString.call(date) === '[object Date]' &&
            !Number.isNaN(date.getTime()) &&
            typeof hash === 'string' &&
            typeof title === 'string' &&
            !review) {
            var newReview = {
                creationDate: parseDate(date) || createDateAsUTC(date),
                hash: hash,
                title: title,
                isDraft: isDraft,
                pushmailJobId: pushmailJobId,
                id: id,
                indexName: indexName,
            };
            this.pushmailJobId = '';
            this.reviews.push(newReview);
            return newReview;
        }
        return review;
    };
    ReviewStore.prototype.getReviewByHash = function (hash) {
        return this.reviews.find(function (reviewItem) { return reviewItem.hash === hash; });
    };
    ReviewStore.prototype.getSearchByHash = function (hash) {
        return this.searches[hash];
    };
    ReviewStore.prototype.updateDefaultTab = function (hash, key, value) {
        var review = this.getReviewByHash(hash);
        review.defaultTab[key] = value;
        return review.defaultTab;
    };
    ReviewStore.prototype.emitPdfState = function () {
        this.emit(PDF_STATE_EVENT);
    };
    ReviewStore.prototype.addPdfStateListener = function (callback) {
        this.on(PDF_STATE_EVENT, callback);
    };
    ReviewStore.prototype.removePdfStateListener = function (callback) {
        this.removeListener(PDF_STATE_EVENT, callback);
    };
    ReviewStore.prototype.emitConsumption = function (id, type) {
        this.emit(CONSUMPTION_EVENT, id, type);
    };
    ReviewStore.prototype.addConsumptionListener = function (callback) {
        this.on(CONSUMPTION_EVENT, callback);
    };
    ReviewStore.prototype.removeConsumptionListener = function (callback) {
        this.removeListener(CONSUMPTION_EVENT, callback);
    };
    ReviewStore.prototype.emitSuccessCreation = function (data) {
        this.emit(SUCCESS_CREATION_EVENT, data);
    };
    ReviewStore.prototype.addSuccessCreationListener = function (callback) {
        this.on(SUCCESS_CREATION_EVENT, callback);
    };
    ReviewStore.prototype.removeSuccessCreationListener = function (callback) {
        this.removeListener(SUCCESS_CREATION_EVENT, callback);
    };
    ReviewStore.prototype.emitAccessReviewByType = function (data) {
        this.emit(ACCESS_REVIEW_BY_TYPE_EVENT, data);
    };
    ReviewStore.prototype.addAccessReviewByTypeListener = function (callback) {
        this.on(ACCESS_REVIEW_BY_TYPE_EVENT, callback);
    };
    ReviewStore.prototype.removeAccessReviewByTypeListener = function (callback) {
        this.removeListener(ACCESS_REVIEW_BY_TYPE_EVENT, callback);
    };
    ReviewStore.prototype._registerToActions = function (payload) {
        var _this = this;
        var _a;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.CREATE_REVIEW_RESPONSE: {
                if (action.json) {
                    var _b = action.json.data, hash = _b.hash, id = _b.id, indexName = _b.indexName, ticket = _b.ticket;
                    var review = action.json.model;
                    review.id = id;
                    review.indexName = indexName;
                    review.hash = hash;
                    review.ticket = ticket;
                    review.creationDate = new Date();
                    this._reviews.push(review);
                    this.initialized = true;
                    this.emitSuccessCreation(review);
                    this.emitChange();
                }
                else {
                    this.emitError('CREATE_REVIEW');
                }
                break;
            }
            case ActionTypes.GET_REVIEW_RESPONSE: {
                if (action.json) {
                    var _c = action.json.data, accountLogo = _c.accountLogo, accountName = _c.accountName, author = _c.author, creationDate = _c.creationDate, hash = _c.hash, title = _c.title, totalClips = _c.totalClips, treeCollection = _c.treeCollection, defaultTab = _c.defaultTab, globalPdf = _c.globalPdf;
                    var review = this.addReview({
                        creationDate: creationDate,
                        hash: hash,
                        title: title,
                        pushmailJobId: this._tempPushmailJobId,
                    });
                    if (review) {
                        ReviewStore.setAccountLogo(review, accountLogo);
                        ReviewStore.setAccountName(review, accountName);
                        ReviewStore.setAuthor(review, author);
                        ReviewStore.setGlobalPdf(review, globalPdf);
                        ReviewStore.setTotalClips(review, totalClips);
                        ReviewStore.setTreeCollection(review, treeCollection);
                        ReviewStore.setDefaultTab(review, defaultTab);
                        this.initialized = true;
                        this.emitChange(hash);
                    }
                    else {
                        var message = typeof action.json.data === 'string' &&
                            action.json.data.length > 0
                            ? action.json.data
                            : 'generic';
                        this.emitError('GET_REVIEW', message);
                    }
                }
                else {
                    this.emitError('GET_REVIEW', action.errors);
                }
                break;
            }
            case ActionTypes.GET_REVIEW_PDF_RESPONSE:
                if (action.json) {
                    this._pdf = action.json.data;
                    this.emitPdfState();
                }
                else {
                    this.emitError('GET_REVIEW_PDF');
                }
                break;
            case ActionTypes.GET_REVIEW_PDF_STATE_RESPONSE:
                if (action.json) {
                    this._pdf = action.json.data;
                    this.emitPdfState();
                }
                else {
                    this.emitError('GET_REVIEW_PDF_STATE');
                }
                break;
            case ActionTypes.GET_REVIEWS_RESPONSE:
                if ((_a = action.json) === null || _a === void 0 ? void 0 : _a.data) {
                    var _d = action.json, _e = _d.data, reviewPageList = _e.reviewPageList, total = _e.total, model = _d.model;
                    var modelWSkip = __assign({}, model);
                    delete modelWSkip.skip;
                    var searchHash = objectHash(modelWSkip);
                    this._reviewsTotal = total;
                    var newReviews = reviewPageList
                        .map(function (_a) {
                        var author = _a.author, creationDate = _a.creationDate, hash = _a.hash, title = _a.title, totalClips = _a.totalClips, isDraft = _a.isDraft, id = _a.id, indexName = _a.indexName;
                        var review = _this.addReview({
                            creationDate: creationDate,
                            hash: hash,
                            title: title,
                            isDraft: isDraft,
                            id: id,
                            indexName: indexName,
                        });
                        if (review) {
                            ReviewStore.setAuthor(review, author);
                            ReviewStore.setTotalClips(review, totalClips);
                        }
                        return review;
                    })
                        .filter(function (review) { return review; });
                    this.searches[searchHash] = model.skip
                        ? __spreadArray(__spreadArray([], (this.searches[searchHash] || []), true), newReviews, true) : newReviews;
                    this.initialized = true;
                    this.emitChange(searchHash);
                }
                else {
                    this.emitError('GET_REVIEWS');
                }
                break;
            case ActionTypes.SET_SELECTED_TAB_RESPONSE:
                if (action.json) {
                    var content = action.json.data;
                    var _f = action.json.model, id = _f.id, type = _f.type;
                    if (type === 'pdf') {
                        ClipStore.setFileUrlByType(id, type, content);
                        this.setSelectedTab('pdf');
                    }
                    else if (type === 'text') {
                        ClipStore.updateClipText(id, content);
                        this.setSelectedTab('text');
                    }
                    if (content) {
                        this.emitConsumption(action.json.model.id, action.json.model.type);
                    }
                }
                else {
                    this.emitError();
                }
                break;
            case ActionTypes.ACESS_REVIEW_BY_TYPE_RESPONSE:
                if (action.json && action.json.data) {
                    var data = action.json.data;
                    // this._pdf = action.json.data;
                    this._tempPushmailJobId = data.pushmailJobId;
                    this.emitAccessReviewByType(data);
                }
                else {
                    this.emitError('ACCES_REVIEW_BY_TYPE', action.errors.error);
                }
                break;
            case ActionTypes.UPDATE_REVIEW_CLIPS_ALSO_IN_REQUEST:
                if (action.json && action.json.data) {
                    console.log(action.json.data);
                }
                else {
                    this.emitError('UPDATE_REVIEW_CLIPS_ALSO_IN_REQUEST');
                }
                break;
            default:
                break;
        }
    };
    return ReviewStore;
}(BaseStore));
export default new ReviewStore();
