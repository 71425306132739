var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable no-underscore-dangle */
import ActionTypes from 'constants/actionTypes';
import * as StringUtils from 'services/StringUtils';
import { parseDate } from 'services/DateUtils';
import BaseStore from 'stores/BaseStore';
var ACTIVATE_DEACTIVATE_CUSTOM_METRICS_EVENT = 'activateDeactivateMetric';
var UPDATE_CUSTOM_METRICS_EVENT = 'updateMetric';
var ACTIVATE_DEACTIVATE_CUSTOM_METRICS_VALUES_EVENT = 'activateDeactivateMetricValue';
var ADD_CUSTOM_METRICS_EVENT = 'addCustomerMetric';
var ADD_CUSTOM_METRICS_ERROR_EVENT = 'addCustomerMetricError';
var ADD_CUSTOM_METRICS_VALUES_EVENT = 'addCustomerMetricValues';
var UPDATE_CUSTOM_METRICS_VALUES_EVENT = 'updateMetricValues';
var GET_CUSTOM_METRICS_RANGES_EVENT = 'getMetricRanges';
var GET_CUSTOM_METRICS_ACTIVE_VALUES_EVENT = 'getMetricActiveValues';
var ADD_CUSTOM_METRICS_RANGES_EVENT = 'addCustomMetricsRanges';
var UPDATE_CUSTOM_METRICS_RANGES_EVENT = 'updateCustomMetricsRanges';
var GET_CUSTOM_METRICS_INACTIVE_VALUES_EVENT = 'getMetricInactiveValues';
var CustomMetricsStore = /** @class */ (function (_super) {
    __extends(CustomMetricsStore, _super);
    function CustomMetricsStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        _this.setMandatory = _this.setMandatory.bind(_this);
        _this.setVisible = _this.setVisible.bind(_this);
        _this.setNewOrder = _this.setNewOrder.bind(_this);
        return _this;
    }
    CustomMetricsStore.prototype.initializeState = function () {
        this._customMetrics = [];
        this._customMetricsActivesValues = [];
        this._customMetricsInactivesValues = [];
        this._customMetricsactiveValuesTotal = 0;
        this._customMetricsInactiveValuesTotal = 0;
        this._isActive = 0;
        this._customMetricsRanges = [];
    };
    CustomMetricsStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(CustomMetricsStore.prototype, "customMetrics", {
        get: function () {
            return this._customMetrics;
        },
        set: function (metrics) {
            this._customMetrics = metrics;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomMetricsStore.prototype, "customMetricsActivesValues", {
        get: function () {
            return this._customMetricsActivesValues;
        },
        set: function (metricsValues) {
            this._customMetricsActivesValues = metricsValues;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomMetricsStore.prototype, "customMetricsInactivesValues", {
        get: function () {
            return this._customMetricsInactivesValues;
        },
        set: function (metricsValues) {
            this._customMetricsInactivesValues = metricsValues;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomMetricsStore.prototype, "customMetricsactiveValuesTotal", {
        get: function () {
            return this._customMetricsactiveValuesTotal;
        },
        set: function (metrics) {
            this._customMetricsactiveValuesTotal = metrics;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomMetricsStore.prototype, "customMetricsInactiveValuesTotal", {
        get: function () {
            return this._customMetricsInactiveValuesTotal;
        },
        set: function (metrics) {
            this._customMetricsInactiveValuesTotal = metrics;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomMetricsStore.prototype, "customMetricsValuesActive", {
        get: function () {
            return this._isActive;
        },
        set: function (active) {
            this._isActive = active;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomMetricsStore.prototype, "customMetricsRange", {
        get: function () {
            return this._customMetricsRanges;
        },
        set: function (ranges) {
            this._customMetricsRanges = ranges;
        },
        enumerable: false,
        configurable: true
    });
    CustomMetricsStore.prototype.emitChangeActiveValues = function (data) {
        this.emit(GET_CUSTOM_METRICS_ACTIVE_VALUES_EVENT, data);
    };
    CustomMetricsStore.prototype.updateMetricGetActiveValues = function (callback) {
        this.on(GET_CUSTOM_METRICS_ACTIVE_VALUES_EVENT, callback);
    };
    CustomMetricsStore.prototype.removeMetricGetActiveValues = function (callback) {
        this.removeListener(GET_CUSTOM_METRICS_ACTIVE_VALUES_EVENT, callback);
    };
    CustomMetricsStore.prototype.emitChangeInctiveValues = function (data) {
        this.emit(GET_CUSTOM_METRICS_INACTIVE_VALUES_EVENT, data);
    };
    CustomMetricsStore.prototype.updateMetricGetInactiveValues = function (callback) {
        this.on(GET_CUSTOM_METRICS_INACTIVE_VALUES_EVENT, callback);
    };
    CustomMetricsStore.prototype.removeMetricGetInactiveValues = function (callback) {
        this.removeListener(GET_CUSTOM_METRICS_INACTIVE_VALUES_EVENT, callback);
    };
    CustomMetricsStore.prototype.emitUpdateMetricRange = function () {
        this.emit(UPDATE_CUSTOM_METRICS_RANGES_EVENT);
    };
    CustomMetricsStore.prototype.updateMetricRangeListener = function (callback) {
        this.on(UPDATE_CUSTOM_METRICS_RANGES_EVENT, callback);
    };
    CustomMetricsStore.prototype.removeUpdateMetricRangeListener = function (callback) {
        this.removeListener(UPDATE_CUSTOM_METRICS_RANGES_EVENT, callback);
    };
    CustomMetricsStore.prototype.emitAddMetricRange = function () {
        this.emit(ADD_CUSTOM_METRICS_RANGES_EVENT);
    };
    CustomMetricsStore.prototype.addMetricRangeListener = function (callback) {
        this.on(ADD_CUSTOM_METRICS_RANGES_EVENT, callback);
    };
    CustomMetricsStore.prototype.removeAddMetricRangeListener = function (callback) {
        this.removeListener(ADD_CUSTOM_METRICS_RANGES_EVENT, callback);
    };
    CustomMetricsStore.prototype.emitGetMetricRange = function () {
        this.emit(GET_CUSTOM_METRICS_RANGES_EVENT);
    };
    CustomMetricsStore.prototype.getMetricRangeListener = function (callback) {
        this.on(GET_CUSTOM_METRICS_RANGES_EVENT, callback);
    };
    CustomMetricsStore.prototype.removegetMetricRangeListener = function (callback) {
        this.removeListener(GET_CUSTOM_METRICS_RANGES_EVENT, callback);
    };
    CustomMetricsStore.prototype.emitActivateDeactivateMetric = function (data) {
        this.emit(ACTIVATE_DEACTIVATE_CUSTOM_METRICS_EVENT, data);
    };
    CustomMetricsStore.prototype.activateDeactivateMetricListener = function (callback) {
        this.on(ACTIVATE_DEACTIVATE_CUSTOM_METRICS_EVENT, callback);
    };
    CustomMetricsStore.prototype.removeactivateDeactivateMetricListener = function (callback) {
        this.removeListener(ACTIVATE_DEACTIVATE_CUSTOM_METRICS_EVENT, callback);
    };
    CustomMetricsStore.prototype.emitAddCustomerMetricChange = function (data, code) {
        this.emit(ADD_CUSTOM_METRICS_EVENT, data, code);
    };
    CustomMetricsStore.prototype.addCustomerMetricListener = function (callback) {
        this.on(ADD_CUSTOM_METRICS_EVENT, callback);
    };
    CustomMetricsStore.prototype.removeAddCustomerMetricListener = function (callback) {
        this.removeListener(ADD_CUSTOM_METRICS_EVENT, callback);
    };
    //
    CustomMetricsStore.prototype.emitErrorAddMetricListner = function (message) {
        this.emit(ADD_CUSTOM_METRICS_ERROR_EVENT, message);
    };
    CustomMetricsStore.prototype.addErrorLitener = function (callback) {
        this.on(ADD_CUSTOM_METRICS_ERROR_EVENT, callback);
    };
    CustomMetricsStore.prototype.removeErrorLitener = function (callback) {
        this.removeListener(ADD_CUSTOM_METRICS_ERROR_EVENT, callback);
    };
    //
    CustomMetricsStore.prototype.emitActivateDeactivateMetricValues = function (data, message) {
        this.emit(ACTIVATE_DEACTIVATE_CUSTOM_METRICS_VALUES_EVENT, data, message);
    };
    CustomMetricsStore.prototype.activateDeactivateMetricListenerValues = function (callback) {
        this.on(ACTIVATE_DEACTIVATE_CUSTOM_METRICS_VALUES_EVENT, callback);
    };
    CustomMetricsStore.prototype.removeactivateDeactivateMetricListenerValues = function (callback) {
        this.removeListener(ACTIVATE_DEACTIVATE_CUSTOM_METRICS_VALUES_EVENT, callback);
    };
    CustomMetricsStore.prototype.emitAddMetricValues = function (data) {
        this.emit(ADD_CUSTOM_METRICS_VALUES_EVENT, data);
    };
    CustomMetricsStore.prototype.addMetricValueListener = function (callback) {
        this.on(ADD_CUSTOM_METRICS_VALUES_EVENT, callback);
    };
    CustomMetricsStore.prototype.removeaddMetricValueListener = function (callback) {
        this.removeListener(ADD_CUSTOM_METRICS_VALUES_EVENT, callback);
    };
    CustomMetricsStore.prototype.emitUpdateMetric = function () {
        this.emit(UPDATE_CUSTOM_METRICS_EVENT);
    };
    CustomMetricsStore.prototype.updateMetricListener = function (callback) {
        this.on(UPDATE_CUSTOM_METRICS_EVENT, callback);
    };
    CustomMetricsStore.prototype.removeUpdateMetricListener = function (callback) {
        this.removeListener(UPDATE_CUSTOM_METRICS_EVENT, callback);
    };
    CustomMetricsStore.prototype.emitUpdateMetricValue = function () {
        this.emit(UPDATE_CUSTOM_METRICS_VALUES_EVENT);
    };
    CustomMetricsStore.prototype.updateMetricValuesListener = function (callback) {
        this.on(UPDATE_CUSTOM_METRICS_VALUES_EVENT, callback);
    };
    CustomMetricsStore.prototype.removeUpdateMetricValuesListener = function (callback) {
        this.removeListener(UPDATE_CUSTOM_METRICS_VALUES_EVENT, callback);
    };
    CustomMetricsStore.prototype.isNameExist = function (draftId, string) {
        return (this._customMetrics.find(function (_a) {
            var id = _a.id, displayName = _a.displayName;
            return id !== draftId &&
                displayName.toLowerCase() === StringUtils.trim(string).toLowerCase();
        }) != null);
    };
    CustomMetricsStore.prototype.setMandatory = function (cmId, mandatoryValue) {
        var metricIndex = this._customMetrics.findIndex(function (cm) { return parseInt(cm.customerMetricId, 10) === parseInt(cmId, 10); });
        if (metricIndex === -1)
            return;
        var metric = this._customMetrics[metricIndex];
        metric.mandatory = mandatoryValue;
        this.emitChange();
    };
    CustomMetricsStore.prototype.setVisible = function (cmId, visibleValue, desactivatedDate) {
        var metricIndex = this.customMetrics.findIndex(function (cm) { return cm.customerMetricId === cmId; });
        if (metricIndex === -1)
            return;
        var metric = this.customMetrics[metricIndex];
        metric.status = visibleValue;
        metric.desactivatedDate = desactivatedDate || metric.desactivatedDate;
        if (desactivatedDate) {
            this.customMetrics = this.customMetrics.map(function (elm) {
                return elm.sortingOrder >= metric.sortingOrder + 1
                    ? __assign(__assign({}, elm), { sortingOrder: elm.sortingOrder - 1 }) : elm;
            });
            metric.sortingOrder = 0;
        }
        else {
            metric.sortingOrder = this.customMetrics.filter(function (elm) { return elm.status === false; }).length;
        }
    };
    CustomMetricsStore.prototype.setNewOrder = function (newMetric) {
        this.customMetrics = newMetric;
        this.emitChange();
    };
    CustomMetricsStore.prototype.sethideInFilter = function (cmId, hideInFilterValue) {
        var metricIndex = this._customMetrics.findIndex(function (cm) { return parseInt(cm.customerMetricId, 10) === parseInt(cmId, 10); });
        if (metricIndex === -1)
            return;
        var metric = this._customMetrics[metricIndex];
        metric.hideInFilter = hideInFilterValue;
        this.emitChange();
    };
    CustomMetricsStore.prototype.registerToActions = function (payload) {
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_ALL_CUSTOM_METRICS_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.customMetrics = data.map(function (cm) {
                        return cm.desactivatedDate
                            ? __assign(__assign({}, cm), { desactivatedDate: parseDate(cm.desactivatedDate) }) : cm;
                    });
                    this.initialized = true;
                    this.emitChange();
                }
                else {
                    this.emitError('GET_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.ADD_CUSTOM_METRICS_RESPONSE:
                if (action.json) {
                    var _a = action.json, data = _a.data, code = _a.code;
                    this.emitAddCustomerMetricChange(data, code);
                }
                else {
                    this.emitErrorAddMetricListner(action.errors.error);
                }
                break;
            case ActionTypes.GET_CUSTOM_METRICS_ACTIVE_VALUES_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    // const { metricValues } = data;
                    this.customMetricsActivesValues = data.metricValues
                        ? data.metricValues
                        : [];
                    this.customMetricsactiveValuesTotal = data.total ? data.total : 0;
                    this.customMetricsValuesActive = data.isActive;
                    this.initialized = true;
                    this.emitChangeActiveValues(data);
                }
                else {
                    this.emitError('GET_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.GET_CUSTOM_METRICS_INACTIVE_VALUES_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    // const { metricValues } = data;
                    this.customMetricsInactiveValues = data.metricValues;
                    this.customMetricsInactiveValuesTotal = data.total;
                    this.customMetricsValuesActive = data.isActive;
                    this.initialized = true;
                    this.emitChangeInctiveValues(data);
                }
                else {
                    this.emitError('GET_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.ACTIVATE_DEACTIVATE_CUSTOM_METRICS_RESPONSE:
                if (action.json) {
                    var model = action.json.model;
                    var status = model.status;
                    if (status) {
                        var desactivatedDate = window.moment
                            .tz(new Date(), km.getTimeZone())
                            .format('YYYY-MM-DDTHH:mm:ss');
                        this.setVisible(model.customerMetricId, status, desactivatedDate);
                    }
                    else {
                        this.setVisible(model.customerMetricId, status, null);
                    }
                    this.emitChange('ACTIVATE_DEACTIVATE_CUSTOM_METRICS');
                }
                else {
                    this.emitError('GET_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.UPDATE_CUSTOM_METRIC_RESPONSE:
                if (action.json) {
                    this.emitUpdateMetric();
                }
                else {
                    this.emitErrorAddMetricListner(action.errors.error);
                }
                break;
            case ActionTypes.UPDATE_CUSTOM_METRICS_RESPONSE:
                if (action.json) {
                    // this.emitUpdateMetric();
                    this.emitChange();
                }
                else {
                    this.emitErrorAddMetricListner(action.errors.error);
                }
                break;
            case ActionTypes.ACTIVATE_DEACTIVATE_CUSTOM_METRICS_VALUES_RESPONSE:
                if (action.json) {
                    var _b = action.json, data = _b.data, message = _b.message;
                    this.emitActivateDeactivateMetricValues(data, message);
                }
                else {
                    this.emitError('GET_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.UPDATE_CUSTOM_METRICS_VALUES_RESPONSE:
                if (action.json) {
                    var data_1 = action.json.data;
                    this.customMetricsActivesValues = this._customMetricsActivesValues.map(function (item) {
                        var item2 = data_1.find(function (i2) { return i2.customerMetricValueId === item.customerMetricValueId; });
                        return item2 ? __assign(__assign({}, item), item2) : item;
                    });
                    this.emitUpdateMetricValue();
                }
                else {
                    this.emitError('GET_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.ADD_CUSTOM_METRICS_VALUES_RESPONSE:
                if (action.json) {
                    this.emitAddMetricValues();
                }
                else {
                    this.emitError('GET_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.ADD_CUSTOM_METRICS_RANGES_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.customMetricsRange = __spreadArray(__spreadArray([], this._customMetricsRanges, true), data, true);
                    this.emitAddMetricRange();
                }
                else {
                    this.emitError('GET_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.GET_CUSTOM_METRICS_RANGES_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.customMetricsRange = data;
                    this.emitGetMetricRange();
                }
                else {
                    this.emitError('GET_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.UPDATE_CUSTOM_METRICS_RANGES_RESPONSE:
                if (action.json) {
                    var data_2 = action.json.data;
                    this.customMetricsRange = this._customMetricsRanges.map(function (item) {
                        var item2 = data_2.find(function (i2) { return i2.customerMetricRangeId === item.customerMetricRangeId; });
                        return item2 ? __assign(__assign({}, item), item2) : item;
                    });
                    this.emitUpdateMetricRange();
                }
                else {
                    this.emitError('GET_KIOSKS_RESPONSE');
                }
                break;
            default:
                break;
        }
    };
    return CustomMetricsStore;
}(BaseStore));
export default new CustomMetricsStore();
