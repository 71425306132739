var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ValueSelector, ValueEditor, ActionElement } from './controls';
import Input from 'commons/Input';
var Rule = /** @class */ (function (_super) {
    __extends(Rule, _super);
    function Rule(props) {
        var _this = _super.call(this, props) || this;
        _this.field = props.field;
        _this.operator = props.ops;
        _this.value = props.value;
        _this.onFieldChanged = _this.onFieldChanged.bind(_this);
        _this.onOperatorChanged = _this.onOperatorChanged.bind(_this);
        _this.onValueChanged = _this.onValueChanged.bind(_this);
        _this.onElementChanged = _this.onElementChanged.bind(_this);
        _this.removeRule = _this.removeRule.bind(_this);
        return _this;
    }
    Rule.prototype.componentDidMount = function () {
        var _a = this.props, id = _a.id, onRuleChange = _a.onRuleChange;
        onRuleChange(this.field, this.operator, this.value, id, true);
    };
    Rule.prototype.componentDidUpdate = function (prevProps) {
        var isNewCombinator = JSON.stringify(prevProps.combinator) !==
            JSON.stringify(this.props.combinator);
        if (isNewCombinator) {
            this.combinator = this.props.combinator;
            this.forceUpdate();
        }
    };
    Rule.prototype.onFieldChanged = function (value) {
        var _a = this.props, onRuleChange = _a.onRuleChange, id = _a.id;
        this.field = value.value;
        this.onElementChanged('field', value.value);
        if (onRuleChange) {
            onRuleChange(this.field, this.operator, this.value, id);
        }
        this.value = '';
    };
    Rule.prototype.onOperatorChanged = function (value) {
        var _a = this.props, onRuleChange = _a.onRuleChange, id = _a.id;
        this.operator = (value && value.value && value.value) || null;
        this.onElementChanged('ops', this.operator);
        if (onRuleChange) {
            onRuleChange(this.field, this.operator, this.value, id);
        }
    };
    Rule.prototype.onValueChanged = function (value) {
        var _a = this.props, onRuleChange = _a.onRuleChange, id = _a.id;
        var val = (typeof value === 'string' && value) || value;
        this.value = val;
        this.onElementChanged('value', val);
        if (onRuleChange) {
            onRuleChange(this.field, this.operator, this.value, id);
        }
    };
    Rule.prototype.onElementChanged = function (property, value) {
        var _a = this.props, id = _a.id, onPropChange = _a.schema.onPropChange;
        onPropChange(property, value, id);
    };
    Rule.prototype.removeRule = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.schema.onRuleRemove(this.props.id, this.props.parentId);
    };
    Rule.prototype.render = function () {
        var formatMessage = this.props.intl.formatMessage;
        var _a = this.props, id = _a.id, lastRuleId = _a.lastRuleId, field = _a.field, ops = _a.ops, value = _a.value, translations = _a.translations, mode = _a.mode, _b = _a.schema, fields = _b.fields, getOperators = _b.getOperators, getLevel = _b.getLevel, classNames = _b.classNames;
        var level = getLevel(this.props.id);
        var elasticFieldType = fields.length !== 0 && field !== ''
            ? fields.find(function (elm) { return elm.name === field; }).elasticFieldType
            : '';
        return (_jsxs("div", { className: "rule ".concat(classNames.rule, " ").concat((id === lastRuleId &&
                'rule-highlight') ||
                ''), children: [(this.combinator && (_jsx("div", { className: "operator_label", children: (this.props.index === 0 &&
                        formatMessage({ id: 'QueryBuilder.select' })) ||
                        (this.combinator &&
                            formatMessage({ id: "QueryBuilder.".concat(this.combinator.ops) })) }))) || (_jsx("div", { className: "operator_label", children: formatMessage({ id: 'QueryBuilder.select' }) })), _jsx(ValueSelector, { className: "rule-fields ".concat(classNames.fields), handleOnChange: this.onFieldChanged, level: level, options: fields, placeholder: formatMessage({ id: 'QueryBuilder.selectOption' }), title: translations.fields.title, value: field }), _jsx(ValueSelector, { className: "rule-operators ".concat(classNames.operators), field: field, handleOnChange: this.onOperatorChanged, level: level, options: getOperators(field), placeholder: formatMessage({ id: 'QueryBuilder.selectOperator' }), title: translations.operators.title, value: ops }), mode === 'percolator' ? ((elasticFieldType === 'ListString' &&
                    (Number(ops) !== 1 || Number(ops) !== 2)) ||
                    !elasticFieldType ? (_jsx(ValueEditor, { className: "rule-value ".concat(classNames.value), field: field, fields: fields, handleOnChange: this.onValueChanged, level: level, ops: ops, placeholder: formatMessage({ id: 'QueryBuilder.selectValue' }), title: translations.value.title, value: this.value })) : elasticFieldType !== 'ListString' &&
                    (Number(ops) === 1 || Number(ops) === 2) ? (_jsx("span", {})) : (_jsx(Input, { className: "rule-value ".concat(classNames.value), onChange: this.onValueChanged, value: this.value, "aria-label": "input" }))) : (_jsx(ValueEditor, { className: "rule-value ".concat(classNames.value), field: field, fields: fields, handleOnChange: this.onValueChanged, level: level, ops: ops, placeholder: formatMessage({ id: 'QueryBuilder.selectValue' }), title: translations.value.title, value: this.value })), _jsx(ActionElement, { id: translations.removeRule.id, label: translations.removeRule.label, title: translations.removeRule.title, className: "rule-remove ".concat(classNames.removeRule), handleOnClick: this.removeRule, level: level })] }));
    };
    return Rule;
}(React.Component));
Rule.propTypes = {
    intl: PropTypes.object.isRequired,
    field: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            startDate: PropTypes.instanceOf(Date),
            endDate: PropTypes.instanceOf(Date),
        }),
    ]).isRequired,
    ops: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        }),
    ]),
    translations: PropTypes.shape({
        fields: PropTypes.shape({
            title: PropTypes.string,
        }),
        operators: PropTypes.shape({
            title: PropTypes.string,
        }),
        value: PropTypes.shape({
            title: PropTypes.string,
        }),
        removeRule: PropTypes.shape({
            title: PropTypes.string,
            label: PropTypes.string,
        }),
        removeGroup: PropTypes.shape({
            title: PropTypes.string,
            label: PropTypes.string,
        }),
        addRule: PropTypes.shape({
            title: PropTypes.string,
            label: PropTypes.string,
        }),
        addGroup: PropTypes.shape({
            title: PropTypes.string,
            label: PropTypes.string,
        }),
    }).isRequired,
    onRuleRemove: PropTypes.func.isRequired,
    onRuleChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        })),
        PropTypes.string,
        PropTypes.instanceOf(Date),
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    ]).isRequired,
    schema: PropTypes.shape({
        fields: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.instanceOf(Date),
                PropTypes.shape({
                    startDate: PropTypes.instanceOf(moment),
                    endDate: PropTypes.instanceOf(moment),
                }),
            ]),
        })).isRequired,
        operators: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        })).isRequired,
        classNames: PropTypes.shape({
            queryBuilder: PropTypes.string,
            ruleGroup: PropTypes.string,
            addRule: PropTypes.string,
            addGroup: PropTypes.string,
            removeGroup: PropTypes.string,
            rule: PropTypes.string,
            fields: PropTypes.string,
            operators: PropTypes.string,
            value: PropTypes.string,
            removeRule: PropTypes.string,
        }).isRequired,
        createRule: PropTypes.func.isRequired,
        createRuleGroup: PropTypes.func.isRequired,
        onRuleAdd: PropTypes.func.isRequired,
        onGroupAdd: PropTypes.func.isRequired,
        onRuleRemove: PropTypes.func.isRequired,
        onGroupRemove: PropTypes.func.isRequired,
        onPropChange: PropTypes.func.isRequired,
        getLevel: PropTypes.func.isRequired,
        isRuleGroup: PropTypes.func.isRequired,
        controls: PropTypes.shape({
            addGroupAction: PropTypes.func,
            addRuleAction: PropTypes.func,
            combinatorSelector: PropTypes.func,
            fieldSelector: PropTypes.func,
            operatorSelector: PropTypes.func,
            removeGroupAction: PropTypes.func,
            removeRuleAction: PropTypes.func,
            valueEditor: PropTypes.func,
        }).isRequired,
        getOperators: PropTypes.func.isRequired,
    }).isRequired,
};
Rule.defaultProps = {
    intl: {},
};
export default injectIntl(Rule, { forwardRef: true });
