import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
import 'core-js';
import 'vanilla-cookieconsent/dist/cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { pdfjs } from 'react-pdf';
import { ErrorBoundary } from 'react-error-boundary';
import svg4everybody from 'svg4everybody';
import swal from 'sweetalert';
import moment from 'moment';
import SweetPopup from 'commons/SweetPopup';
import Spinner from 'commons/Spinner';
import { getLang, isoCodeToLocale, initiateIntercom } from 'services/AppUtils';
import TranslationStore from 'stores/TranslationStore';
import ServerActionCreators from 'actions/ServerActionCreators';
import { Translations } from 'constants/messages';
import { postFetch } from '@services/AppUtils';
import 'dom4';
// eslint-disable-next-line
import momentTimezone from 'moment-timezone'; // even it's not used in this file but this dependency is required
import 'moment/locale/ca';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/eu';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/zh-cn';
import { SnackbarProvider } from 'context/SnackbarContext';
import { SweetPopupProvider } from 'context/SweetPopupContext';
import { AlertBannerProvider } from 'context/AlertBannerContext';
import { FilterProvider } from 'context/FilterContext';
import config from 'constants/parameters';
import UserActionCreators from 'actions/UserActionCreators';
import CommonActionCreators from 'actions/CommonActionCreators';
import PermissionActionCreators from 'actions/PermissionActionCreators';
import UserStore from 'stores/UserStore';
import CommonStore from 'stores/CommonStore';
import PermissionStore from 'stores/PermissionStore';
import { logOut } from 'services/AuthUtils';
import ErrorFallback from 'utils/ErrorBoundary';
import RoutesTree from './Routes';
import 'public/css/cookieconsent.css';
import 'public/css/main.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-tree/assets/index.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import ThemeProvider from '../src/theme/ThemeProvider';
import LoginActionCreators from 'actions/LoginActionCreators';
pdfjs.GlobalWorkerOptions.workerSrc = "//unpkg.com/pdfjs-dist@".concat(pdfjs.version, "/build/pdf.worker.min.js");
// window.Intl = require('intl');
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
        },
    },
});
var lang = getLang();
var clean = function (url) { return (url.match(/\/\/+/g) ? url.replace(/\/\/+/g, '/') : url); };
function App() {
    var navigate = useNavigate();
    var _a = useState({}), currentUser = _a[0], setCurrentUser = _a[1];
    var _b = useState(false), messagesFetched = _b[0], setMessagesFetched = _b[1];
    var _c = useState(isoCodeToLocale(lang)), locale = _c[0], setNewLanguage = _c[1];
    var _d = useState(TranslationStore.translations), translationData = _d[0], setTranslationData = _d[1];
    window.config = config;
    var isLoginUser = window.localStorage.getItem('SESSION');
    var handlePopstate = function () {
        if (!isLoginUser) {
            navigate('/login'); // Redirect to the login page
        }
    };
    var RedirectionManager = function () {
        var _a;
        if (window.location.pathname === '/organize-review') {
            navigate('/monitoring', { replace: true });
        }
        if (window.location.pathname.toLowerCase() === '/access/logoff') {
            var session = window.localStorage.getItem('SESSION');
            if (session) {
                LoginActionCreators.logOut();
            }
            logOut();
            window.location.replace('/login');
        }
        if (!window.lastVisitedUrl) {
            window.lastVisitedUrl =
                window.location.pathname === '/login' ||
                    window.location.pathname.toLowerCase().includes('/access/login') ||
                    window.location.pathname === '/' ||
                    window.location.pathname === '' ||
                    window.location.pathname === '/agencies'
                    ? '/monitoring'
                    : clean("".concat(window.location.pathname).concat(window.location.search));
        }
        if (window.location.pathname === '/monitoring' ||
            ((_a = window.lastVisitedUrl) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('/public'))) {
            var session = window.localStorage.getItem('SESSION');
            var LoginSSO = window.localStorage.getItem('LoginSSO');
            if (LoginSSO && !session)
                window.localStorage.setItem('SESSION', true);
        }
        if (window.location.pathname.includes('/Public/GetFileClip')) {
            window.location.href = "".concat(window.config.API_ROOT).concat(window.location.pathname).concat(window.location.search);
            return _jsx(Spinner, { fullPage: true });
        }
    };
    useEffect(function () {
        window.addEventListener('popstate', handlePopstate);
        return function () {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [isLoginUser, history]);
    useEffect(function () {
        // Initiate Intercom
        initiateIntercom();
        var handleOnchangeTranslations = function (isoCode) {
            if (isoCode)
                window.localStorage.setItem('lang', isoCode.toLowerCase());
            setTranslationData(TranslationStore.translations);
            setMessagesFetched(true);
        };
        document.documentElement.setAttribute('lang', lang);
        TranslationStore.addChangeListener(handleOnchangeTranslations);
        function getTranslations() {
            if (messagesFetched)
                setMessagesFetched(false);
            fetch("".concat(config.API_RESOURCES, "/localization/").concat(isoCodeToLocale(lang), ".json"), 
            //  switch to Local to test new translation
            // `${config.API_ROOT}/messages.json?culture=${isoCodeToLocale(lang)}`,
            {
                credentials: 'same-origin',
            })
                .then(function (response) { return response.json(); })
                .then(function (json) {
                window.Messages = json;
                ServerActionCreators.receiveGetTranslations({ lang: lang, translations: window.Messages }, null);
            })
                .catch(function () {
                window.Messages = Translations;
                ServerActionCreators.receiveGetTranslations({ lang: lang, translations: Translations }, null);
                navigate('/authorization', {
                    state: { errorType: 'InternalServer' },
                });
            });
        }
        RedirectionManager();
        getTranslations();
        if (window.Messages) {
            ServerActionCreators.receiveGetTranslations({ lang: lang, translations: window.Messages }, null);
            // setMessagesFetched(true);
        }
        svg4everybody();
        swal.setDefaults({ allowOutsideClick: true });
        moment.locale(lang);
        window.moment = moment;
        window.locale = locale;
        window.km = {
            global: {
                lastPeriod: {
                    logicalDate: 1,
                    from: moment().subtract(1, 'days').startOf('day').toString(),
                    to: moment().subtract(1, 'days').endOf('day').toString(),
                },
            },
            getLang: function () {
                return lang;
            },
            getTimeZone: function () {
                return null;
            },
        };
        var handleOnchangeUserLanguage = function (isoCode) {
            if ((lang === null || lang === void 0 ? void 0 : lang.toLowerCase()) !== (isoCode === null || isoCode === void 0 ? void 0 : isoCode.toLowerCase())) {
                document.documentElement.setAttribute('lang', isoCode);
                var _local = isoCodeToLocale(isoCode);
                setNewLanguage(_local);
                lang = isoCode;
                getTranslations();
            }
        };
        var onChangeUserStore = function () {
            var isPublic = window.location.pathname
                .toLowerCase()
                .includes('/public');
            if (UserStore.currentUser.userId) {
                setCurrentUser(UserStore.currentUser);
            }
            if (!PermissionStore.isInit() &&
                UserStore.currentUser.accountId &&
                !isPublic) {
                PermissionActionCreators.getPermissions();
            }
            if (UserStore.selectedTimeZone) {
                window.km.getTimeZone = function () { return UserStore.selectedTimeZone; };
            }
            if (UserStore.currentUser.userAccountId) {
                localStorage.setItem('checkSession', UserStore.currentUser.userAccountId);
            }
            if (!UserStore.currentUser.userAccountId &&
                !UserStore.currentUser.userId) {
                localStorage.removeItem('checkSession');
            }
        };
        // 01 Detect when user returns to tab
        document.addEventListener('visibilitychange', function () {
            var session = localStorage.getItem('SESSION');
            var isTimeLapsed = !localStorage.getItem('sessionTime') ||
                new Date().getTime() - Number(localStorage.getItem('sessionTime')) >
                    5 * 60 * 1000;
            if (session && isTimeLapsed) {
                localStorage.setItem('sessionTime', new Date().getTime().toString());
                postFetch('/api/access/hasSession', null, function (json) {
                    if (json.code === 401) {
                        logOut();
                        navigate('/login?inactive=true');
                        localStorage.setItem('checkSession', 'inactive');
                    }
                });
            }
        });
        // 01 onSessionChange
        window.addEventListener('storage', function (event) {
            var key = event.key, newValue = event.newValue, oldValue = event.oldValue;
            if (key === 'checkSession') {
                if (newValue === 'inactive') {
                    logOut();
                    navigate('/login?inactive=true');
                }
                else if (!!newValue && !oldValue) {
                    window.location.reload();
                }
                if (oldValue !== 'inactive' && !newValue && !!oldValue) {
                    logOut();
                    navigate('login');
                }
            }
        });
        UserStore.addGetCurrentUserListener(onChangeUserStore);
        UserStore.addCurrentUserLanguageListener(handleOnchangeUserLanguage);
        return function () {
            UserStore.removeGetCurrentUserListener(onChangeUserStore);
            UserStore.removeCurrentUserLanguageListener(handleOnchangeUserLanguage);
            TranslationStore.removeChangeListener(handleOnchangeTranslations);
        };
    }, []);
    useEffect(function () {
        if (messagesFetched) {
            var isLoggedIn = window.localStorage.getItem('SESSION');
            var isPublic = window.location.pathname
                .toLowerCase()
                .includes('/public');
            if (isLoggedIn &&
                !window.location.pathname.toLowerCase().includes('agencies') &&
                !isPublic) {
                if (!CommonStore.isInit()) {
                    CommonActionCreators.getUserCountries();
                }
                if (!UserStore.isInit()) {
                    UserActionCreators.getCurrentUser();
                }
            }
        }
    }, [messagesFetched]);
    return messagesFetched && window.Messages && translationData ? (_jsx(QueryClientProvider, { client: queryClient, children: _jsx(IntlProvider, { locale: locale, messages: translationData, children: _jsx(ErrorBoundary, { FallbackComponent: ErrorFallback, children: _jsx(ThemeProvider, { children: _jsx(SnackbarProvider, { children: _jsx(SweetPopupProvider, { children: _jsx(AlertBannerProvider, { children: _jsxs(FilterProvider, { children: [_jsx(RoutesTree, {}), _jsx(SweetPopup, {})] }) }) }) }) }) }) }) })) : (_jsx(Spinner, { fullPage: true }));
}
ReactDOM.render(_jsxs(BrowserRouter, { children: [_jsx(App, {}), ' '] }), document.getElementById('app'));
