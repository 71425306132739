var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import SearchStore from 'stores/SearchStore';
import Input from 'commons/Input';
import FavoriteSearchDate from './FavoriteSearchDate';
var requiredFields = ['favoriteSearchName'];
var proptypes = {
    mode: PropTypes.string,
    favoriteSearch: PropTypes.node,
    getFormValues: PropTypes.func,
    isError: PropTypes.bool,
    handleChangeErrorState: PropTypes.func,
};
var defaultProps = {
    mode: '',
    favoriteSearch: undefined,
    getFormValues: function () { },
    isError: false,
    handleChangeErrorState: function () { },
};
var FavoriteSearchForm = function (_a) {
    var mode = _a.mode, favoriteSearch = _a.favoriteSearch, getFormValues = _a.getFormValues, isError = _a.isError, handleChangeErrorState = _a.handleChangeErrorState;
    var formatMessage = useIntl().formatMessage;
    var searchModelGeneral = JSON.parse(localStorage.getItem('searchModel'));
    var initState = mode === 'editFavorite'
        ? {
            favoriteSearchName: favoriteSearch.name,
            favoriteSearchId: favoriteSearch.id,
            searchField: '',
            lastPeriod: {
                from: favoriteSearch.criterias.searchModel.DateFrom,
                to: favoriteSearch.criterias.searchModel.DateTo,
                logicalDate: favoriteSearch.criterias.searchModel.logicalDate,
                dateType: favoriteSearch.criterias.searchModel.DateType,
            },
            dateFilterActive: !!(favoriteSearch.criterias.searchModel.DateFrom &&
                favoriteSearch.criterias.searchModel.DateTo),
            errors: [],
        }
        : {
            favoriteSearchName: '',
            favoriteSearchId: null,
            searchField: '',
            lastPeriod: {
                from: searchModelGeneral.searchModel.DateFrom,
                to: searchModelGeneral.searchModel.DateTo,
                logicalDate: SearchStore.getLogicalDate(true),
                dateType: SearchStore.getDateType(),
            },
            dateFilterActive: false,
            errors: [],
        };
    var _b = useState(initState), state = _b[0], setState = _b[1];
    useEffect(function () {
        getFormValues(state);
    }, [state]);
    var handleChange = function (value, options) {
        var _a;
        var id = options.id;
        var newState = (_a = {}, _a[id] = value, _a);
        handleChangeErrorState(false);
        if (value.length > 0 && state.errors.includes(id)) {
            newState.errors = state.errors.filter(function (errorItem) { return errorItem !== id; });
        }
        setState(__assign(__assign({}, state), newState));
    };
    var handleBlur = function (value, options) {
        var _a;
        var id = options.id;
        var newValue = value.trim();
        var newState = (_a = {}, _a[id] = newValue, _a);
        handleChangeErrorState(false);
        if (requiredFields.includes(id) &&
            newValue.length === 0 &&
            !state.errors.includes(id)) {
            newState.errors = __spreadArray(__spreadArray([], state.errors, true), [id], false);
        }
        else if (value.length > 0 && state.errors.includes(id)) {
            newState.errors = state.errors.filter(function (errorItem) { return errorItem !== id; });
        }
        setState(__assign(__assign({}, state), newState));
    };
    var renderInput = function (id) { return (_jsx(Input, { iconLeft: id === 'searchField' ? 'search' : null, className: "qa-add-article-".concat(id), error: state.errors.includes(id) || isError
            ? formatMessage({ id: 'Global.requiredField' })
            : null, onBlur: handleBlur, onChange: handleChange, placeholder: formatMessage({
            id: "FavoriteSearch.favoriteSearchModal.favoriteSearchFormPlaceholder.".concat(id),
        }), props: { id: id }, type: "text", value: state[id] })); };
    var onUpdateState = function (value, callback) {
        if (state) {
            setState(__assign(__assign({}, state), value));
        }
        else if (callback) {
            callback();
        }
    };
    return (_jsx("div", { children: mode !== 'AdvancedFilters' && (_jsxs("div", { className: "second-row-container", children: [renderInput('favoriteSearchName'), _jsx("div", { className: "c-modal-add-widget__date-selector", children: _jsx(FavoriteSearchDate, { onUpdateState: onUpdateState, isEnabled: state.dateFilterActive, lastPeriod: state.lastPeriod }) })] })) }));
};
FavoriteSearchForm.propTypes = proptypes;
FavoriteSearchForm.defaultProps = defaultProps;
export default FavoriteSearchForm;
