import React from 'react';
var useClickAwayListener = function (_a) {
    var ref = _a.ref, callback = _a.callback;
    React.useEffect(function () {
        var handleDocumentClick = function (event) {
            if (ref === null || ref === void 0 ? void 0 : ref.current) {
                var node = ref === null || ref === void 0 ? void 0 : ref.current;
                var doc = (node && node.ownerDocument) || document;
                if (doc.documentElement &&
                    doc.documentElement.contains(event.target) &&
                    !node.contains(event.target)) {
                    callback(event);
                }
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return function () {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [ref]);
};
export default useClickAwayListener;
