var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import moment from 'moment';
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
import { convertToUserTZ } from '@services/DateUtils';
var PRODUCT_BY_ID = 'productById';
var PRODUCT_TYPES = 'PRODUCT_TYPES';
var RELATED_PRODUCT_NEWS = 'RELATED_PRODUCT_NEWS';
var ProductNewsStore = /** @class */ (function (_super) {
    __extends(ProductNewsStore, _super);
    function ProductNewsStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    ProductNewsStore.prototype.initializeState = function () {
        this._productNews = [];
        this._types = [];
        this._relatedProductNews = [];
        this._params = {
            dateTo: moment().format('YYYY-MM-DD'),
            dateFrom: moment().add(-30, 'days').startOf('day').format('YYYY-MM-DD'),
            type: [1, 2],
            logicalDate: 'lastmonth',
            query: '',
            isRead: null,
            toggleFilter: 'disable-date-selector',
        };
        this._unreadProductNewsCount = 0;
    };
    ProductNewsStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(ProductNewsStore.prototype, "params", {
        get: function () {
            return this._params;
        },
        set: function (paramsObj) {
            this._params = paramsObj;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductNewsStore.prototype, "productNews", {
        get: function () {
            return this._productNews;
        },
        set: function (productNews) {
            this._productNews = productNews;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductNewsStore.prototype, "types", {
        get: function () {
            return this._types;
        },
        set: function (types) {
            this._types = types;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductNewsStore.prototype, "relatedProductNews", {
        get: function () {
            return this._relatedProductNews;
        },
        set: function (relatedProductNews) {
            this._relatedProductNews = relatedProductNews;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductNewsStore.prototype, "unreadProductNewsCount", {
        get: function () {
            return this._unreadProductNewsCount;
        },
        set: function (unreadCount) {
            this._unreadProductNewsCount = unreadCount;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Get a product by its ID.
     * @param {string} productNewsId.
     * @return {ProductNews}
     */
    ProductNewsStore.prototype.getProductById = function (id) {
        var _a;
        return (_a = this.productNews) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
            var productNewsId = _a.productNewsId;
            return id === productNewsId;
        });
    };
    ProductNewsStore.prototype.getAllProductAsRead = function () {
        return this.productNews.map(function (_a) {
            var productNewsId = _a.productNewsId;
            return productNewsId;
        });
    };
    ProductNewsStore.prototype.emitGetProductById = function (type) {
        this.emit(PRODUCT_BY_ID, type);
    };
    ProductNewsStore.prototype.addGetProductByIdListener = function (callback) {
        this.on(PRODUCT_BY_ID, callback);
    };
    ProductNewsStore.prototype.removeGetProductByIdListener = function (callback) {
        this.removeListener(PRODUCT_BY_ID, callback);
    };
    ProductNewsStore.prototype.emitGetProductTypes = function (data) {
        this.emit(PRODUCT_TYPES, data);
    };
    ProductNewsStore.prototype.addGetProductTypesListener = function (callback) {
        this.on(PRODUCT_TYPES, callback);
    };
    ProductNewsStore.prototype.removeGetProductTypesListener = function (callback) {
        this.removeListener(PRODUCT_TYPES, callback);
    };
    ProductNewsStore.prototype.emitGetRelatedProductNews = function (type) {
        this.emit(RELATED_PRODUCT_NEWS, type);
    };
    ProductNewsStore.prototype.addGetRelatedProductNewsListener = function (callback) {
        this.on(RELATED_PRODUCT_NEWS, callback);
    };
    ProductNewsStore.prototype.removeGetRelatedProductNewsListener = function (callback) {
        this.removeListener(RELATED_PRODUCT_NEWS, callback);
    };
    ProductNewsStore.prototype.markAllAsRead = function () {
        this.productNews = this.productNews.map(function (item) { return (__assign(__assign({}, item), { isRead: true })); });
    };
    ProductNewsStore.prototype.registerToActions = function (payload) {
        var _a, _b, _c, _d, _e;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_PRODUCT_NEWS_RESPONSE:
                if ((_a = action.json) === null || _a === void 0 ? void 0 : _a.data) {
                    var result = action.json.data.map(function (elm) { return (__assign(__assign({}, elm), { lastPublishedDate: convertToUserTZ(elm.lastPublishedDate, 'YYYY/MM/DD - HH:mm') })); });
                    this.productNews = result;
                    this.emitChange('GET_PRODUCT_NEWS_RESPONSE');
                }
                else {
                    this.emitError('GET_PRODUCT_NEWS_RESPONSE');
                }
                break;
            case ActionTypes.GET_RELATED_PRODUCT_NEWS_RESPONSE:
                if ((_b = action.json) === null || _b === void 0 ? void 0 : _b.data) {
                    var data = action.json.data;
                    var result = data.map(function (elm) { return (__assign(__assign({}, elm), { lastPublishedDate: convertToUserTZ(elm.lastPublishedDate, 'YYYY/MM/DD - HH:mm') })); });
                    this.relatedProductNews = result;
                    this.emitGetRelatedProductNews('GET_RELATED_PRODUCT_NEWS_RESPONSE');
                }
                else {
                    this.emitError('GET_RELATED_PRODUCT_NEWS_RESPONSE');
                }
                break;
            case ActionTypes.GET_PRODUCT_NEWS_TYPES_RESPONSE:
                if ((_c = action.json) === null || _c === void 0 ? void 0 : _c.data) {
                    this.types = action.json.data;
                    this.emitGetProductTypes(action.json.data);
                }
                else {
                    this.emitError('GET_PRODUCT_NEWS_TYPES');
                }
                break;
            case ActionTypes.GET_PRODUCT_NEWS_BY_ID_RESPONSE:
                if (action.json) {
                    if (((_d = this.productNews) === null || _d === void 0 ? void 0 : _d.length) > 0) {
                        var productIndex = this.productNews.findIndex(function (elm) { return elm.productNewsId === action.json.model.productNewsId; });
                        var productItem = this.productNews.splice(productIndex, 1, action.json.data);
                        var res = this.productNews.filter(function (_a) {
                            var isRead = _a.isRead;
                            return !isRead;
                        }).length;
                        this.unreadProductNewsCount = res - productItem.length;
                    }
                    this.emitGetProductById('GET_PRODUCT_NEWS_BY_ID_RESPONSE');
                }
                else {
                    this.emitError('GET_PRODUCT_NEWS_BY_ID_RESPONSE');
                }
                break;
            case ActionTypes.MARK_ALL_PRODUCT_NEWS_AS_READ_RESPONSE:
                if (action.json) {
                    this.markAllAsRead();
                    this.unreadProductNewsCount = 0;
                    this.emitChange();
                }
                else {
                    this.emitError('MARK_ALL_PRODUCT_NEWS_AS_READ_RESPONSE');
                }
                break;
            case ActionTypes.MARK_UNREADABLE_COUNT_PRODUCT_NEWS_RESPONSE:
                if ((_e = action.json) === null || _e === void 0 ? void 0 : _e.data) {
                    this.unreadProductNewsCount = action.json.data.unReadCount;
                    this.emitChange();
                }
                else {
                    this.emitError('MARK_UNREADABLE_COUNT_PRODUCT_NEWS_RESPONSE');
                }
                break;
            default:
                break;
        }
    };
    return ProductNewsStore;
}(BaseStore));
export default new ProductNewsStore();
