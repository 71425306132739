var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import FacetGroup from 'new-components/FacetGroup';
import FacetItem from 'new-components/FacetItem';
import CodificationStore from 'stores/CodificationStore';
var propTypes = {
    onFacetItemChange: PropTypes.func.isRequired,
    facet: PropTypes.object.isRequired,
    selectedFacetItemIds: PropTypes.arrayOf(PropTypes.string.isRequired)
        .isRequired,
};
function FilterCustomerMetrics(_a) {
    var onFacetItemChange = _a.onFacetItemChange, selectedFacetItemIds = _a.selectedFacetItemIds, facet = _a.facet;
    var _b = useState(false), setRerender = _b[1];
    useEffect(function () {
        CodificationStore.addGetCodificationListener(onChange);
        return function () { return CodificationStore.removeGetCodificationListener(onChange); };
    }, []);
    var onChange = function () {
        setRerender(function (prevState) { return !prevState; });
    };
    var handleOpenFacetGroup = function () {
        setRerender(function (prevState) { return !prevState; });
    };
    var renderFacetItemList = function () {
        var props = {
            facetGroupId: facet.filterElasticFieldPath,
            onChange: onFacetItemChange,
            selectedFacetItemIds: selectedFacetItemIds,
        };
        var renderFacetItems = function () {
            return Array.isArray(facet.facetItems)
                ? facet.facetItems.map(function (_a) {
                    var id = _a.id, name = _a.name, numberOfClips = _a.numberOfClips;
                    return (_jsx(FacetItem, __assign({ item: { id: id, name: name, numberOfClips: numberOfClips } }, props), id));
                })
                : null;
        };
        return _jsx("div", { children: renderFacetItems() });
    };
    return (_jsx(FacetGroup, { className: "c-facet-group--customer-metrics", hasAdvancedFilters: true, id: facet.filterElasticFieldPath, name: facet.filterElasticFieldName, onOpen: handleOpenFacetGroup, children: renderFacetItemList() }));
}
FilterCustomerMetrics.propTypes = propTypes;
export default FilterCustomerMetrics;
