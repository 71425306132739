var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import { Wrapper } from './FormElement.styles';
var FormElement = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.controlClassName, controlClassName = _c === void 0 ? '' : _c, _d = _a.error, error = _d === void 0 ? '' : _d, _e = _a.hasError, hasError = _e === void 0 ? false : _e, _f = _a.hasLeftIcon, hasLeftIcon = _f === void 0 ? false : _f, _g = _a.hasRightIcon, hasRightIcon = _g === void 0 ? false : _g, _h = _a.helpMessage, helpMessage = _h === void 0 ? '' : _h, id = _a.id, _j = _a.required, required = _j === void 0 ? false : _j, _k = _a.tooltip, tooltip = _k === void 0 ? '' : _k, _l = _a.themableLabel, themableLabel = _l === void 0 ? false : _l, _m = _a.showLabel, showLabel = _m === void 0 ? true : _m, _o = _a.label, label = _o === void 0 ? '' : _o, children = _a.children;
    var renderRequired = function () { return (_jsx("abbr", { className: "required", children: "* " })); };
    var renderControl = function () {
        var elementControlClassName = classnames('c-form-element__control', {
            'c-input-has-icon': hasLeftIcon || hasRightIcon,
            'c-input-has-icon--left': hasLeftIcon && !hasRightIcon,
            'c-input-has-icon--left-right': hasLeftIcon && hasRightIcon,
            'c-input-has-icon--right': hasRightIcon && !hasLeftIcon,
        }, controlClassName);
        return _jsx("div", { className: elementControlClassName, children: children });
    };
    var renderLabel = function () { return (_jsxs("label", __assign({ className: "c-form-element__label ".concat((themableLabel && 't-color-primary') || null, " ").concat(!showLabel ? 'hidden' : ''), htmlFor: id }, (tooltip ? { 'data-tooltip': tooltip } : {}), { children: [required && renderRequired(), _jsx("strong", { children: label })] }))); };
    var renderHelp = function () {
        var createMarkup = { __html: helpMessage || error };
        return (_jsx("span", { className: "c-form-element__help ".concat((error && error !== '') || hasError ? 'has-error' : ''), dangerouslySetInnerHTML: createMarkup }));
    };
    var formElementClassNames = classnames('c-form-element', { 'has-error': error || hasError }, className);
    return (_jsxs(Wrapper, { className: formElementClassNames, children: [label && renderLabel(), renderControl(), (error || helpMessage) && renderHelp()] }));
};
export default FormElement;
