import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, memo, useEffect, useContext } from 'react';
import { useLocation, useMatch, Outlet } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import SubNavbar from 'commons/SubNavbar';
import Navbar from 'commons/Navbar';
import SearchBar from 'new-components/SearchBar';
import AlertBanner from 'commons/AlertBanner';
import UpdateEmailAlert from 'pages/EmailAlerts/UpdateEmailAlert';
import useStyledTheme from 'hooks/useStyledTheme';
import { SubNavbarProvider } from 'context/SubNavbarContext';
import { KioskProvider } from 'context/KioskContext';
import { getUrlParameter } from 'services/AppUtils';
// import UserActionCreators from 'actions/UserActionCreators';
import { AlertBannerContext } from 'context/AlertBannerContext';
import UserStore from 'stores/UserStore';
import { Wrapper } from './Layout.styles';
var Layout = function () {
    var breakPoints = useStyledTheme().breakPoints;
    var location = useLocation();
    var _a = useContext(AlertBannerContext), alertBannerObj = _a.alertBannerObj, setAlertBannerObj = _a.setAlertBannerObj;
    var _b = useState(true), openUpdateEmailAlert = _b[0], setOpenUpdateEmailAlert = _b[1];
    var isMonitoringPage = useMatch('/monitoring');
    var isKioskPage = useMatch('/kiosk');
    var isSettingPage = useMatch('/settings');
    var isAnalytics = useMatch('/analytics');
    var isNotAgencyPage = !useMatch('/agencies');
    var isLandingPage = isMonitoringPage || isAnalytics || isKioskPage || isSettingPage;
    var isAnalyticsPage = location.pathname === '/analytics' &&
        getUrlParameter('dashboardType') !== 'PowerBI';
    var isPublic = location.pathname.includes('Public/');
    var isContact = location.pathname.includes('/contact');
    var isErrorPage = location.pathname.includes('/authorization') ||
        location.pathname.includes('/unexpected');
    var isAccessLogin = location.pathname.includes('/Access/Login');
    var isNotLogin = location.pathname.includes('/login') &&
        location.pathname.toLowerCase() !== '/contact' &&
        !location.pathname.toLowerCase().includes('/access/passwordexpiration') &&
        location.pathname.toLocaleLowerCase() !== '/rp';
    var lastPeriod = (km === null || km === void 0 ? void 0 : km.global).lastPeriod;
    var matchesMinSm = useMediaQuery("(min-width: ".concat(breakPoints.sm, "px)"));
    useEffect(function () {
        var onGetIncidentMessage = function () {
            var messageData = UserStore.getIncidentMessageData();
            if (messageData) {
                setAlertBannerObj({
                    open: true,
                    message: messageData.body,
                    isRead: messageData.isRead,
                });
            }
        };
        onGetIncidentMessage();
        UserStore.addChangeIncidentMessageListener(onGetIncidentMessage);
        return function () {
            UserStore.removeIncidentMessageListener(onGetIncidentMessage);
        };
    }, []);
    var handleCloseModal = function () {
        localStorage.removeItem('userLogin');
        setOpenUpdateEmailAlert(false);
    };
    var isUserEmail = JSON.parse(localStorage.getItem('userLogin'));
    var restrictedPages = isNotAgencyPage &&
        !isPublic &&
        !isErrorPage &&
        !isContact &&
        !isAccessLogin &&
        !isNotLogin;
    return (_jsxs(_Fragment, { children: [_jsxs(SubNavbarProvider, { children: [matchesMinSm && _jsx(SubNavbar, {}), location.pathname !== '/login' &&
                        location.pathname.toLowerCase() !== '/contact' &&
                        !window.location.pathname.toLowerCase().includes('/access/login') &&
                        !window.location.pathname
                            .toLowerCase()
                            .includes('/access/passwordexpiration') && _jsx(Navbar, {})] }), _jsx(KioskProvider, { children: _jsxs(Wrapper, { className: "container-wrapper", children: [(isMonitoringPage || isAnalyticsPage) && (_jsx(SearchBar, { lastPeriod: lastPeriod })), restrictedPages &&
                            alertBannerObj.message &&
                            !alertBannerObj.isRead && _jsx(AlertBanner, {}), isLandingPage && isUserEmail && !isUserEmail.isEmail && (_jsx(UpdateEmailAlert, { handleCloseModal: handleCloseModal, openUpdateEmailAlert: openUpdateEmailAlert })), _jsx("div", { className: "container-page-content", children: _jsx(Outlet, {}) })] }) })] }));
};
export default memo(Layout);
