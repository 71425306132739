import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
var ProductNewsActionCreators = {
    getProductNews: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_PRODUCT_NEWS_REQUEST,
        });
        WebApi.getProductNews(model);
    },
    getRelatedProductNews: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_RELATED_PRODUCT_NEWS_REQUEST,
        });
        WebApi.getRelatedProductNews(model);
    },
    getProductNewsById: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_PRODUCT_NEWS_BY_ID_REQUEST,
        });
        WebApi.getProductNewsById(model);
    },
    getProductNewsTypes: function () {
        WebApi.getProductNewsTypes();
    },
    markAllProductAsRead: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.MARK_ALL_PRODUCT_NEWS_AS_READ_REQUEST,
        });
        WebApi.markAllProductAsRead(model, callback);
    },
    UnreadableCountProductNews: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.MARK_UNREADABLE_COUNT_PRODUCT_NEWS_REQUEST,
        });
        WebApi.getUnreadableCountProductNews();
    },
};
export default ProductNewsActionCreators;
