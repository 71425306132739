import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
var ModalFooter = function (_a) {
    var children = _a.children, className = _a.className;
    var modalFooterClassNames = classnames('modal__footer', className, {
        'modal__footer-3elements': (children === null || children === void 0 ? void 0 : children.length) >= 3,
    });
    return _jsx("div", { className: modalFooterClassNames, children: children });
};
export default ModalFooter;
