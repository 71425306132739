var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import swal from 'sweetalert';
import Button from 'commons/Button';
import QueryBuilder from 'components/QueryBuilder';
import Spinner from 'commons/Spinner';
import { ModalBody, ModalFooter, ModalHeader } from 'commons/Modal';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import SearchStore from 'stores/SearchStore';
import { SweetPopupContext } from 'context/SweetPopupContext';
import QueryBuilderStore from 'stores/QueryBuilderStore';
import QueryBuilderActionCreators from 'actions/QueryBuilderActionCreators';
import AdvancedSearchModal from './AdvancedSearchModal';
import { extractFieldsFromQuery, cleanQuery, isQueryValid, } from './QueryBuilderUtils';
import { Wrapper, FacetsConfigurationVariables, } from './QueryBuilderConfiguration.styles';
var propTypes = {
    intl: PropTypes.object.isRequired,
    updateFilter: PropTypes.func,
};
var defaultProps = {
    intl: {},
    updateFilter: function () { },
};
var GlobalStyle = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  :root {\n    ", "\n  }\n"], ["\n  :root {\n    ", "\n  }\n"])), FacetsConfigurationVariables);
var QueryBuilderConfiguration = /** @class */ (function (_super) {
    __extends(QueryBuilderConfiguration, _super);
    function QueryBuilderConfiguration(props) {
        var _this = _super.call(this, props) || this;
        _this.openAdvancedSearchModal = function () {
            _this.setState({ openAdvancedSearchModal: true });
        };
        _this.handlecloseSearchModal = function () {
            _this.setState({ openAdvancedSearchModal: false });
        };
        _this.query = null;
        _this.fields = [];
        _this.state = {
            modalIsOpen: false,
            loading: false,
            options: [],
            openAdvancedSearchModal: false,
        };
        _this.handleGetFieldsCallback = _this.handleGetFieldsCallback.bind(_this);
        _this.handleValidationClick = _this.handleValidationClick.bind(_this);
        _this.handleCloseModal = _this.handleCloseModal.bind(_this);
        _this.handleResetQuery = _this.handleResetQuery.bind(_this);
        _this.handleOpenModal = _this.handleOpenModal.bind(_this);
        _this.getTranslations = _this.getTranslations.bind(_this);
        _this.updateQuery = _this.updateQuery.bind(_this);
        _this.onSearchChange = _this.onSearchChange.bind(_this);
        _this.onRuleChange = _this.onRuleChange.bind(_this);
        _this.onChange = _this.onChange.bind(_this);
        _this.onError = _this.onError.bind(_this);
        _this.onSave = _this.onSave.bind(_this);
        _this.queryBuilderRef = React.createRef();
        SearchStore.addChangeListener(_this.onSearchChange);
        QueryBuilderStore.addGetFieldsListener(_this.handleGetFieldsCallback);
        QueryBuilderStore.addChangeListener(_this.onChange);
        QueryBuilderStore.addErrorListener(_this.onError);
        return _this;
    }
    QueryBuilderConfiguration.getOperators = function (fieldName) {
        var operators = QueryBuilderStore.getOperatorsByFieldName(fieldName);
        if (Array.isArray(operators)) {
            var ops = operators.map(function (op) { return ({
                label: op.value,
                name: op.key,
            }); });
            return ops;
        }
        return null;
    };
    QueryBuilderConfiguration.prototype.componentDidMount = function () {
        var openModalButtonElement = document.getElementById('filters-querybuilder-btn');
        if (openModalButtonElement != null) {
            openModalButtonElement.addEventListener('click', this.handleOpenModal);
        }
    };
    QueryBuilderConfiguration.prototype.componentWillUnmount = function () {
        var openModalButtonElement = document.getElementById('filters-querybuilder-btn');
        if (openModalButtonElement != null) {
            openModalButtonElement.removeEventListener('click', this.handleOpenModal);
        }
        SearchStore.removeChangeListener(this.onSearchChange);
        QueryBuilderStore.removeGetFieldsListener(this.handleGetFieldsCallback);
        QueryBuilderStore.removeChangeListener(this.onChange);
        QueryBuilderStore.removeErrorListener(this.onError);
    };
    QueryBuilderConfiguration.prototype.onSearchChange = function () {
        this.query = SearchStore.getRawQueryBuilder();
        this.handleGetFieldsCallback();
        this.forceUpdate();
    };
    QueryBuilderConfiguration.prototype.onChange = function (type) {
        var state = {};
        state.loading = false;
        this.fields = QueryBuilderStore.getFields();
        if (type === 'GET_QUERYBUILDER_FIELD_VALUES') {
            state.options = QueryBuilderStore.getValuesByFieldId(this.fieldId);
        }
        this.setState(__assign({}, state));
    };
    QueryBuilderConfiguration.prototype.onError = function () {
        var formatMessage = this.props.intl.formatMessage;
        swal({
            text: formatMessage({ id: 'Global.genericError' }),
            title: formatMessage({ id: 'Global.error' }),
            type: 'error',
        });
        this.setState({
            loading: false,
        });
    };
    QueryBuilderConfiguration.prototype.onSave = function () {
        this.handleCloseModal();
    };
    QueryBuilderConfiguration.prototype.onRuleChange = function (field) {
        var _this = this;
        var loading = this.state.loading;
        var searchModel = SearchStore.buildSearchModel().searchModel;
        if (loading)
            return;
        var storeField = QueryBuilderStore.getFieldByName(field);
        this.fieldId = storeField && storeField.filterElasticFieldPath;
        var fieldValues = QueryBuilderStore.getValuesByFieldId(this.fieldId);
        if (fieldValues &&
            Array.isArray(fieldValues) &&
            QueryBuilderStore.isFieldInitialized(this.fieldId)) {
            this.setState({
                options: fieldValues,
            });
        }
        else {
            if (!this.fieldId)
                return;
            this.setState({
                loading: true,
            });
            var menu = SearchStore.AdvancedFilterMenu || [];
            var selectedFilter = menu.find(function (m) { return m.filterElasticFieldPath === _this.fieldId; });
            QueryBuilderActionCreators.getFieldValues({
                selectedFilter: selectedFilter,
                culture: km.getLang() || 'en',
                searchModel: (searchModel && searchModel) || {},
                fieldType: storeField.filterSourceType,
            });
        }
    };
    QueryBuilderConfiguration.prototype.getTranslations = function () {
        var formatMessage = this.props.intl.formatMessage;
        var translations = {
            fields: {
                title: 'Fields',
            },
            operators: {
                title: 'Operators',
            },
            value: {
                title: 'Value',
            },
            removeRule: {
                id: 'remove',
                label: formatMessage({ id: 'QueryBuilder.remove' }),
                title: formatMessage({ id: 'QueryBuilder.remove' }),
            },
            removeGroup: {
                id: 'removeGroup',
                label: formatMessage({ id: 'QueryBuilder.remove' }),
                title: formatMessage({ id: 'QueryBuilder.remove' }),
            },
            addRule: {
                id: 'addRule',
                label: formatMessage({ id: 'QueryBuilder.addRule' }),
                title: formatMessage({ id: 'QueryBuilder.addRule' }),
            },
            addGroup: {
                id: 'addGroup',
                label: formatMessage({ id: 'QueryBuilder.addGroup' }),
                title: formatMessage({ id: 'QueryBuilder.addGroup' }),
            },
        };
        return translations;
    };
    QueryBuilderConfiguration.prototype.handleResetQuery = function () {
        var setQueryBuilder = SearchStore.setQueryBuilder, setRawQueryBuilder = SearchStore.setRawQueryBuilder;
        setQueryBuilder(null);
        setRawQueryBuilder(null);
        this.query = null;
        this.forceUpdate();
    };
    QueryBuilderConfiguration.prototype.handleCloseModal = function () {
        this.queryBuilderRef.current.onInitializeAll();
        this.setState({ modalIsOpen: false });
    };
    QueryBuilderConfiguration.prototype.handleOpenModal = function () {
        var state = {};
        var query = SearchStore.getRawQueryBuilder();
        var parsed = JSON.parse(query);
        if (parsed) {
            this.query = parsed;
        }
        this.setState(__assign(__assign({}, state), { modalIsOpen: true }));
    };
    QueryBuilderConfiguration.prototype.handleGetFieldsCallback = function () {
        var _this = this;
        var query = (this.query && __assign({}, this.query)) || SearchStore.getQueryBuilder();
        var fields = extractFieldsFromQuery(query);
        var searchModel = SearchStore.searchObject;
        if (SearchStore.getQueryBuilder() || !this.query) {
            this.query = SearchStore.getQueryBuilder();
        }
        fields.forEach(function (field) {
            var storeField = QueryBuilderStore.getFieldByName(field);
            var fieldId = storeField && storeField.fieldId;
            var fieldValues = QueryBuilderStore.getValuesByFieldId(fieldId);
            if (fieldId &&
                fieldValues &&
                Array.isArray(fieldValues) &&
                QueryBuilderStore.isFieldInitialized(fieldId)) {
                _this.setState({
                    options: fieldValues,
                });
            }
            else {
                QueryBuilderActionCreators.getFieldValues({
                    fieldId: fieldId,
                    culture: km.getLang() || 'en',
                    searchModel: (searchModel && searchModel) || {},
                });
            }
        });
    };
    QueryBuilderConfiguration.prototype.handleValidationClick = function () {
        var formatMessage = this.props.intl.formatMessage;
        var isValidQuery = isQueryValid(this.query);
        var updateFilter = this.props.updateFilter;
        var setSweetPopup = this.context.setSweetPopup;
        var query = cleanQuery(JSON.parse(JSON.stringify(this.query)));
        var addFacet = SearchStore.addFacet, getSelectedFacets = SearchStore.getSelectedFacets, setQueryBuilder = SearchStore.setQueryBuilder, setRawQueryBuilder = SearchStore.setRawQueryBuilder;
        if (!isValidQuery) {
            setSweetPopup({
                isOpen: true,
                title: formatMessage({ id: 'QueryBuilder.invalidQueryTitle' }),
                bodyMessage: formatMessage({ id: 'QueryBuilder.invalidQueryText' }),
                showCancelButton: true,
                cancelText: formatMessage({ id: 'Global.cancel' }),
            });
        }
        else {
            var selectedFacetItemIds = Object.keys(getSelectedFacets());
            setQueryBuilder(query);
            setRawQueryBuilder(this.query);
            var facetItemObject = {
                id: 'query.builder',
                text: formatMessage({ id: 'QueryBuilder.facet' }),
                value: 'queryBuilderFilter',
            };
            var isFacetAlreadyAdded = selectedFacetItemIds.includes('query.builder');
            // clear filters
            SearchStore.setHash(null);
            SearchStore.setDraftId(null);
            SearchStore.setQ('');
            // SearchStore.clearAllFacetsExceptQB();
            if (!isFacetAlreadyAdded) {
                addFacet(facetItemObject);
            }
            updateFilter();
            this.setState({
                modalIsOpen: false,
            });
        }
    };
    QueryBuilderConfiguration.prototype.updateQuery = function (query) {
        this.query = query;
    };
    QueryBuilderConfiguration.prototype.render = function () {
        var formatMessage = this.props.intl.formatMessage;
        var _a = this.state, modalIsOpen = _a.modalIsOpen, loading = _a.loading, options = _a.options, openAdvancedSearchModal = _a.openAdvancedSearchModal;
        var fields = this.fields.map(function (field) { return ({
            name: field.filterElasticFieldPath,
            label: field.translation,
            type: 'String',
        }); });
        return (_jsxs(_Fragment, { children: [_jsx(GlobalStyle, {}), _jsxs(Wrapper, { isOpen: modalIsOpen, onCancel: this.handleCloseModal, width: "large", children: [_jsxs(ModalHeader, { onClose: this.handleCloseModal, children: [_jsx(FormattedMessage, { id: "QueryBuilder.modalTitle" }), "\u00A0", _jsx(FormattedMessage, { id: "QueryBuilder.modalTitleStrong" }), "\u00A0", _jsx(Button, { onClick: this.openAdvancedSearchModal, variant: "text", color: "primary", leftIcon: _jsx(AiOutlineQuestionCircle, { size: "20" }), title: formatMessage({
                                        id: 'global.userGuide',
                                    }) })] }), _jsx(ModalBody, { children: _jsxs("div", { className: "c-query-builder-configuration clearfix", children: [(this.fields.length === 0 || loading) && _jsx(Spinner, { fullPage: true }), _jsx(QueryBuilder, { ref: this.queryBuilderRef, translations: this.getTranslations(), availableOptions: options, query: this.query || null, fields: fields || [], getOperators: QueryBuilderConfiguration.getOperators, onRuleChange: this.onRuleChange, onQueryChange: this.updateQuery })] }) }), _jsxs(ModalFooter, { className: "text-right", children: [_jsx(Button, { onClick: this.handleResetQuery, variant: "outline", title: "Reset query builder", ariaLabel: "Reset query builder", children: _jsx(FormattedMessage, { id: "Global.reset" }) }), _jsx(Button, { variant: "outline", color: "primary", onClick: this.handleCloseModal, title: formatMessage({ id: 'Global.cancel' }), ariaLabel: "Close query builder modal", children: _jsx(FormattedMessage, { id: "Global.cancel" }) }), _jsx(Button, { onClick: this.handleValidationClick, color: "secondary", title: "Validate query builder form", ariaLabel: "Validate query builder form", children: _jsx(FormattedMessage, { id: "Global.validate" }) })] })] }), openAdvancedSearchModal && (_jsx(AdvancedSearchModal, { isOpenAdvancedSearch: openAdvancedSearchModal, handlecloseSearchModal: this.handlecloseSearchModal }))] }));
    };
    QueryBuilderConfiguration.contextType = SweetPopupContext;
    return QueryBuilderConfiguration;
}(Component));
QueryBuilderConfiguration.propTypes = propTypes;
QueryBuilderConfiguration.defaultProps = defaultProps;
export default injectIntl(QueryBuilderConfiguration, { forwardRef: true });
var templateObject_1;
