var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import moment from 'moment';
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
var PermissionStore = /** @class */ (function (_super) {
    __extends(PermissionStore, _super);
    function PermissionStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this._registerToActions.bind(_this); });
        return _this;
    }
    PermissionStore.prototype.initializeState = function () {
        this._roles = [];
        this._features = [];
        this._servicesModules = [];
        this._agilityUserToken = '';
        this._insightMenu = [];
        this.retentionPeriod = new Date(moment().subtract(26, 'months').format('YYYY-MM-DD HH:mm:ss'));
        this.savedSettings = {};
    };
    PermissionStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(PermissionStore.prototype, "roles", {
        get: function () {
            return this._roles;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PermissionStore.prototype, "features", {
        get: function () {
            return this._features;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PermissionStore.prototype, "servicesModules", {
        get: function () {
            return this._servicesModules;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PermissionStore.prototype, "agilityUserToken", {
        get: function () {
            return this._agilityUserToken;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PermissionStore.prototype, "insightMenu", {
        get: function () {
            return this._insightMenu;
        },
        enumerable: false,
        configurable: true
    });
    PermissionStore.prototype.setInsightMenu = function (insights) {
        this._insightMenu = insights;
    };
    PermissionStore.prototype.setFeatures = function (features) {
        this._features = features;
    };
    PermissionStore.prototype.setRoles = function (roles) {
        this._roles = roles;
    };
    PermissionStore.prototype.setServicesModules = function (servicesModules) {
        this._servicesModules = servicesModules;
    };
    PermissionStore.prototype.setInitialized = function (isInitialized) {
        this.initialized = isInitialized;
    };
    PermissionStore.prototype.setAgilityUserToken = function (token) {
        this._agilityUserToken = token;
    };
    PermissionStore.prototype.getSavedSettings = function (key) {
        return this.savedSettings[key];
    };
    PermissionStore.prototype.getRetentionPeriod = function () {
        return this.retentionPeriod;
    };
    PermissionStore.prototype.setRetentionPeriod = function (rP) {
        var retention = parseInt(rP, 10);
        this.retentionPeriod = new Date(moment().subtract(retention, 'months').format('YYYY-MM-DD HH:mm:ss'));
    };
    PermissionStore.prototype._registerToActions = function (payload) {
        var _a, _b, _c;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_LOGIN_RESPONSE:
                if (action.json && action.json.resulType === 'SUCCESS') {
                    var insightMenu = action.json.insightMenu;
                    if (!action.json.isMoreThenOneAccount) {
                        this.setInsightMenu(insightMenu || []);
                        this.setInitialized(true);
                        this.setRoles(action.json.roles);
                        this.setFeatures(action.json.features);
                        this.emitChange();
                    }
                }
                else {
                    this.emitError('GET_LOGIN');
                }
                break;
            case ActionTypes.GET_AGENCY_LOGIN_RESPONSE:
                if ((_a = action.json) === null || _a === void 0 ? void 0 : _a.data) {
                    var _d = action.json.data, roles = _d.roles, features = _d.features;
                    this._roles = roles;
                    this._features = features;
                }
                else {
                    this.emitError('GET_PERMISSIONS_RESPONSE');
                }
                break;
            case ActionTypes.GET_PERMISSIONS_RESPONSE:
                if ((_b = action.json) === null || _b === void 0 ? void 0 : _b.data) {
                    var _e = action.json.data, listModules = _e.listModules, agilityUserToken = _e.agilityUserToken, insightMenu = _e.insightMenu, roles = _e.roles, features = _e.features, retentionPeriod = _e.retentionPeriod, exportDashboarduserSetting = _e.exportDashboarduserSetting, isAvailableCoverPage = _e.isAvailableCoverPage, isAvailableFullPage = _e.isAvailableFullPage, isAvailableImagesPage = _e.isAvailableImagesPage, isAvailableTextPage = _e.isAvailableTextPage;
                    var servicesModules = listModules === null || listModules === void 0 ? void 0 : listModules.map(function (module) { return (__assign(__assign({}, module), { image: '' })); });
                    if (servicesModules)
                        this.setServicesModules(servicesModules);
                    if (agilityUserToken)
                        this.setAgilityUserToken(agilityUserToken);
                    if (insightMenu)
                        this.setInsightMenu(insightMenu || []);
                    this._roles = roles;
                    this._features = features;
                    this.setRetentionPeriod(retentionPeriod);
                    this.savedSettings.exportDashboardPdf = exportDashboarduserSetting
                        ? JSON.parse(exportDashboarduserSetting)
                        : null;
                    this.savedSettings.isAvailableCoverPage = isAvailableCoverPage;
                    this.savedSettings.isAvailableFullPage = isAvailableFullPage;
                    this.savedSettings.isAvailableImagesPage = isAvailableImagesPage;
                    this.savedSettings.isAvailableTextPage = isAvailableTextPage;
                    this.setInitialized(true);
                    this.emitChange();
                }
                else {
                    this.emitError('GET_PERMISSIONS_RESPONSE');
                }
                break;
            case ActionTypes.GET_REVIEW_RESPONSE:
            case ActionTypes.GET_NEWSDIGEST_RESPONSE:
                if ((_c = action.json) === null || _c === void 0 ? void 0 : _c.data) {
                    var _f = action.json.data, roleIds = _f.roleIds, isAvailableCoverPage = _f.isAvailableCoverPage, isAvailableFullPage = _f.isAvailableFullPage, isAvailableImagesPage = _f.isAvailableImagesPage, isAvailableTextPage = _f.isAvailableTextPage;
                    if (Array.isArray(roleIds)) {
                        this._roles = roleIds.filter(function (roleItem) { return typeof roleItem === 'number'; });
                    }
                    this.savedSettings.isAvailableCoverPage = isAvailableCoverPage;
                    this.savedSettings.isAvailableFullPage = isAvailableFullPage;
                    this.savedSettings.isAvailableImagesPage = isAvailableImagesPage;
                    this.savedSettings.isAvailableTextPage = isAvailableTextPage;
                    this.emitChange();
                }
                break;
            default:
                break;
        }
    };
    return PermissionStore;
}(BaseStore));
export default new PermissionStore();
