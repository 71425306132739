import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Button from '@commons/Button';
import { BsArrowRightCircle, BsArrowLeftCircle } from 'react-icons/bs';
var DualListBoxControlsMove = function (_a) {
    var onMoveItem = _a.onMoveItem, selectedItems = _a.selectedItems, displayedItems = _a.displayedItems, availableItems = _a.availableItems;
    var isMoveButtonDisabled = function (scopeItems) {
        var result = true;
        if ((scopeItems === null || scopeItems === void 0 ? void 0 : scopeItems.length) > 0 && selectedItems && selectedItems.length > 0) {
            scopeItems.forEach(function (item) {
                if (selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.includes(item.id)) {
                    result = false;
                }
            });
        }
        return result;
    };
    var handleLeftClick = function () {
        if (typeof onMoveItem === 'function')
            onMoveItem('left');
    };
    var handleRightClick = function () {
        if (typeof onMoveItem === 'function')
            onMoveItem('right');
    };
    return (_jsx("div", { className: "c-dualListBoxControlsMove", children: _jsx("div", { className: "dualListBoxControls__controls", children: _jsxs("div", { className: "dualListBoxControls__button__button-group button-group", children: [_jsx(Button, { variant: "text", className: "dualListBoxControls__button", disabled: availableItems && isMoveButtonDisabled(availableItems), onClick: handleRightClick, title: "Move to right", children: _jsx(BsArrowRightCircle, { size: "1.5em" }) }), _jsx(Button, { variant: "text", className: "dualListBoxControls__button", disabled: isMoveButtonDisabled(displayedItems), onClick: handleLeftClick, title: "Move to left", children: _jsx(BsArrowLeftCircle, { size: "1.5em" }) })] }) }) }));
};
export default DualListBoxControlsMove;
