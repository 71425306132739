/* eslint-disable sort-keys */
import keyMirror from 'fbjs/lib/keyMirror';
export default keyMirror({
    // ACCOUNT LIST
    GET_ACCOUNTS_REQUEST: null,
    GET_ACCOUNTS_RESPONSE: null,
    GET_AGENCY_LOGIN_REQUEST: null,
    GET_AGENCY_LOGIN_RESPONSE: null,
    GET_AGENCY_LOGOUT_REQUEST: null,
    GET_AGENCY_LOGOUT_RESPONSE: null,
    UPDATE_ACCOUNT_FAVORITE_REQUEST: null,
    UPDATE_ACCOUNT_FAVORITE_RESPONSE: null,
    GET_INITIAL_PROFIL_RESPONSE: null,
    // CUSTOM METRICS
    GET_ALL_CUSTOM_METRICS_REQUEST: null,
    GET_ALL_CUSTOM_METRICS_RESPONSE: null,
    ADD_CUSTOM_METRICS_REQUEST: null,
    ADD_CUSTOM_METRICS_RESPONSE: null,
    GET_CUSTOM_METRICS_ACTIVE_VALUES_RESPONSE: null,
    GET_CUSTOM_METRICS_ACTIVE_VALUES_REQUEST: null,
    GET_CUSTOM_METRICS_INACTIVE_VALUES_RESPONSE: null,
    GET_CUSTOM_METRICS_INACTIVE_VALUES_REQUEST: null,
    ADD_CUSTOM_METRICS_VALUES_REQUEST: null,
    ADD_CUSTOM_METRICS_VALUES_RESPONSE: null,
    ACTIVATE_DEACTIVATE_CUSTOM_METRICS_REQUEST: null,
    ACTIVATE_DEACTIVATE_CUSTOM_METRICS_RESPONSE: null,
    UPDATE_CUSTOM_METRIC_REQUEST: null,
    UPDATE_CUSTOM_METRIC_RESPONSE: null,
    UPDATE_CUSTOM_METRICS_REQUEST: null,
    UPDATE_CUSTOM_METRICS_RESPONSE: null,
    ACTIVATE_DEACTIVATE_CUSTOM_METRICS_VALUES_REQUEST: null,
    ACTIVATE_DEACTIVATE_CUSTOM_METRICS_VALUES_RESPONSE: null,
    UPDATE_CUSTOM_METRICS_VALUES_REQUEST: null,
    UPDATE_CUSTOM_METRICS_VALUES_RESPONSE: null,
    GET_CUSTOM_METRICS_RANGES_REQUEST: null,
    GET_CUSTOM_METRICS_RANGES_RESPONSE: null,
    ADD_CUSTOM_METRICS_RANGES_REQUEST: null,
    ADD_CUSTOM_METRICS_RANGES_RESPONSE: null,
    UPDATE_CUSTOM_METRICS_RANGES_REQUEST: null,
    UPDATE_CUSTOM_METRICS_RANGES_RESPONSE: null,
    // KIOSK
    GET_KIOSKS_REQUEST: null,
    GET_KIOSKS_RESPONSE: null,
    REMOVE_KIOSKS_REQUEST: null,
    REMOVE_KIOSKS_RESPONSE: null,
    EDIT_KIOSKS_REQUEST: null,
    EDIT_KIOSKS_RESPONSE: null,
    GET_KIOSK_REVIEW_REQUEST: null,
    GET_KIOSK_REVIEW_RESPONSE: null,
    GET_KIOSK_NEW_DIGEST_REQUEST: null,
    GET_KIOSK_NEW_DIGEST_RESPONSE: null,
    EDIT_KIOSK_REVIEW_RESPONSE: null,
    EDIT_KIOSK_REVIEW_REQUEST: null,
    EDIT_KIOSK_NEW_DIGEST_RESPONSE: null,
    EDIT_KIOSK_NEW_DIGEST_REQUEST: null,
    EDIT_KIOSK_INFOGRAPHY_RESPONSE: null,
    EDIT_KIOSK_INFOGRAPHY_REQUEST: null,
    EDIT_KIOSK_DASHBORD_RESPONSE: null,
    EDIT_KIOSK_DASHBORD_REQUEST: null,
    REMOVE_KIOSK_INFOGRAPHY_RESPONSE: null,
    REMOVE_KIOSK_INFOGRAPHY_REQUEST: null,
    REMOVE_KIOSK_REVIEW_RESPONSE: null,
    REMOVE_KIOSK_REVIEW_REQUEST: null,
    REMOVE_KIOSK_DASHBORD_RESPONSE: null,
    REMOVE_KIOSK_DASHBORD_REQUEST: null,
    REMOVE_KIOSK_NEWS_DIGEST_RESPONSE: null,
    REMOVE_KIOSK_NEWS_DIGEST_REQUEST: null,
    // LOGIN
    GET_SUPPORT_CONTACTS_REQUEST: null,
    GET_SUPPORT_CONTACTS_RESPONSE: null,
    SEND_EMAIL_SUPPORT_CONTACTS_REQUEST: null,
    SEND_EMAIL_SUPPORT_CONTACTS_RESPONSE: null,
    GET_NEWS_BANNERS_REQUEST: null,
    GET_NEWS_BANNERS_RESPONSE: null,
    GET_IS_LOGIN_REQUEST: null,
    GET_IS_LOGIN_RESPONSE: null,
    // CLIP
    INIT_SEARCH_MODEL: null,
    ADD_CLIP_REQUEST: null,
    ADD_CLIP_RESPONSE: null,
    ADD_CONSUMPTION_REQUEST: null,
    ADD_CONSUMPTION_RESPONSE: null,
    ADD_EVALUATE_CONSUMPTION_REQUEST: null,
    ADD_EVALUATE_CONSUMPTION_RESPONSE: null,
    ADD_HIGHLIGHT_REQUEST: null,
    ADD_HIGHLIGHT_RESPONSE: null,
    CATEGORIZE_CLIP_REQUEST: null,
    CATEGORIZE_CLIP_RESPONSE: null,
    CLIP_READ_REQUEST: null,
    CREATE_CLIP_ORDER_REQUEST: null,
    CREATE_CLIP_ORDER_RESPONSE: null,
    DELETE_HIGHLIGHT_REQUEST: null,
    DELETE_HIGHLIGHT_RESPONSE: null,
    DENY_ARTICLE_REQUEST: null,
    DENY_ARTICLE_RESPONSE: null,
    GET_ALL_CLIPS_REQUEST: null,
    GET_ALL_CLIPS_RESPONSE: null,
    GET_CLIP_REQUEST: null,
    GET_CLIP_RESPONSE: null,
    SET_CLIP_SELECTED_TAB_REQUEST: null,
    SET_CLIP_SELECTED_TAB_RESPONSE: null,
    GET_CLIP_SELECTIONS_BY_CLIP_IDS_REQUEST: null,
    GET_CLIP_SELECTIONS_BY_CLIP_IDS_RESPONSE: null,
    GET_ALL_CLIP_SELECTIONS_RESPONSE: null,
    GET_ALL_COUNTRIES_RESPONSE: null,
    GET_ALL_SERVICES_RESPONSE: null,
    GET_CLIPS_REQUEST: null,
    GET_CLIPS_RESPONSE: null,
    GET_CLIPS_READ_REQUEST: null,
    GET_CLIPS_READ_RESPONSE: null,
    GET_MEDIA_CLIP_REQUEST: null,
    GET_MEDIA_CLIP_RESPONSE: null,
    GET_MEDIA_CLIP_NEWS_DIGEST_REQUEST: null,
    GET_MEDIA_CLIP_NEWS_DIGEST_RESPONSE: null,
    GET_NEXT_CLIPS_REQUEST: null,
    GET_NEXT_CLIPS_RESPONSE: null,
    GET_FULLTEXT_CLIP_REQUEST: null,
    GET_FULLTEXT_CLIP_RESPONSE: null,
    GET_RELATED_CLIPS_REQUEST: null,
    GET_RELATED_CLIPS_RESPONSE: null,
    RESET_SESSION_CLIP_REQUEST: null,
    RESET_SESSION_CLIP_RESPONSE: null,
    UPDATE_CLIP_REQUEST: null,
    UPDATE_CLIP_RESPONSE: null,
    UPDATE_LIKE_REQUEST: null,
    UPDATE_LIKE_RESPONSE: null,
    UPDATE_LIKES_REQUEST: null,
    UPDATE_LIKES_RESPONSE: null,
    UPDATE_FAVOURITE_REQUEST: null,
    UPDATE_FAVOURITE_RESPONSE: null,
    UPDATE_FAVOURITES_REQUEST: null,
    UPDATE_FAVOURITES_RESPONSE: null,
    UPDATE_SENTIMENTS_REQUEST: null,
    UPDATE_SENTIMENTS_RESPONSE: null,
    UPDATE_TRASH_RESPONSE: null,
    UPDATE_TRASH_REQUEST: null,
    GET_LANGUAGES_RESPONSE: null,
    GET_LANGUAGES_REQUEST: null,
    GET_MICROSOFT_LANGUAGES_REQUEST: null,
    GET_MICROSOFT_LANGUAGES_RESPONSE: null,
    CREATE_PDF_REQUEST: null,
    CREATE_PDF_RESPONSE: null,
    // UNIT PDF
    CREATE_UNIT_PDF_REQUEST: null,
    CREATE_UNIT_PDF_RESPONSE: null,
    TRANSLATE_CLIP_REQUEST: null,
    TRANSLATE_CLIP_RESPONSE: null,
    // CODIFICATION
    GET_CODIFICATION_REQUEST: null,
    GET_CODIFICATION_RESPONSE: null,
    GET_CUSTOMERMETRICS_REQUEST: null,
    GET_CUSTOMERMETRICS_RESPONSE: null,
    GET_EVALUATION_REQUEST: null,
    GET_EVALUATION_RESPONSE: null,
    SAVE_EVALUATION_REQUEST: null,
    SAVE_EVALUATION_RESPONSE: null,
    ASSIGN_TO_ME_REQUEST: null,
    ASSIGN_TO_ME_RESPONSE: null,
    UNLOCK_CLIPS_REQUEST: null,
    UNLOCK_CLIPS_RESPONSE: null,
    GET_MEDIA_IMPACT_SCORE_REQUEST: null,
    GET_MEDIA_IMPACT_SCORE_RESPONSE: null,
    // CLIP SELECTION
    GET_CLIPSELECTIONS_REQUEST: null,
    GET_CLIPSELECTIONS_RESPONSE: null,
    SET_CLIPSELECTIONS: null,
    // COMMENT
    ADD_COMMENT_REQUEST: null,
    ADD_COMMENT_RESPONSE: null,
    DELETE_COMMENT_REQUEST: null,
    DELETE_COMMENT_RESPONSE: null,
    UPDATE_COMMENT_REQUEST: null,
    UPDATE_COMMENT_RESPONSE: null,
    // DRAFTS
    ADD_TO_DRAFT_REQUEST: null,
    ADD_TO_DRAFT_RESPONSE: null,
    DELETE_DRAFT_RESPONSE: null,
    GET_DRAFT_REQUEST: null,
    GET_DRAFT_RESPONSE: null,
    GET_DRAFTS_REQUEST: null,
    GET_DRAFT_REVIEW_RESPONSE: null,
    GET_DRAFT_REVIEW_REQUEST: null,
    GET_CLIPS_FOR_DRAFT_RESPONSE: null,
    GET_CLIPS_FOR_DRAFT_REQUEST: null,
    GET_DRAFTS_RESPONSE: null,
    GET_DRAFT_USER_PREFERENCE_REQUEST: null,
    GET_DRAFT_USER_PREFERENCE_RESPONSE: null,
    REMOVE_FROM_DRAFT_REQUEST: null,
    REMOVE_FROM_DRAFT_RESPONSE: null,
    SAVE_DRAFT_REQUEST: null,
    SAVE_DRAFT_RESPONSE: null,
    UPDATE_DRAFT_REQUEST: null,
    UPDATE_DRAFT_RESPONSE: null,
    DUPLICATE_DRAFT_REQUEST: null,
    DUPLICATE_DRAFT_RESPONSE: null,
    UPDATE_USER_LIST_PREFERENCE_REQUEST: null,
    UPDATE_USER_LIST_PREFERENCE_RESPONSE: null,
    // FACETS
    GET_INSIGHT_FACET_ITEMS_REQUEST: null,
    GET_INSIGHT_FACET_ITEMS_RESPONSE: null,
    SAVE_INSIGHTS_FILTERS_USER_REQUEST: null,
    SAVE_INSIGHTS_FILTERS_USER_RESPONSE: null,
    // EXCEL CONFIG
    GET_EXCEL_HEADER_ITEMS_REQUEST: null,
    GET_EXCEL_HEADER_ITEMS_RESPONSE: null,
    SAVE_EXCEL_HEADER_ITEMS_REQUEST: null,
    // FILTER
    GET_ADVANCED_FILTER_MENU_REQUEST: null,
    GET_ADVANCED_FILTER_MENU_RESPONSE: null,
    GET_INSIGHT_ADVANCED_FILTER_MENU_REQUEST: null,
    GET_INSIGHT_ADVANCED_FILTER_MENU_RESPONSE: null,
    UPDATE_FILTER_REQUEST: null,
    UPDATE_FILTER_RESPONSE: null,
    UPDATE_INSIGHT_FILTER_REQUEST: null,
    UPDATE_INSIGHT_FILTER_RESPONSE: null,
    GET_FAVOURITE_SEARCHES_REQUEST: null,
    GET_FAVOURITE_SEARCHES_RESPONSE: null,
    CREATE_FAVOURITE_SEARCH_REQUEST: null,
    CREATE_FAVOURITE_SEARCH_RESPONSE: null,
    UPDATE_FAVORITE_SEARCH_REQUEST: null,
    UPDATE_FAVORITE_SEARCH_RESPONSE: null,
    DELETE_FAVORITE_SEARCH_REQUEST: null,
    DELETE_FAVORITE_SEARCH_RESPONSE: null,
    // HIERARCHIES
    GET_HIERARCHIES_REQUEST: null,
    GET_HIERARCHIES_RESPONSE: null,
    GET_HIERARCHY_REQUEST: null,
    GET_HIERARCHY_RESPONSE: null,
    SAVE_HIERARCHY_REQUEST: null,
    SAVE_HIERARCHY_RESPONSE: null,
    DELETE_HIERARCHY_REQUEST: null,
    DELETE_HIERARCHY_RESPONSE: null,
    SAVE_TEMPORARY_HIERARCHY: null,
    // PERMISSIONS
    GET_PERMISSIONS_REQUEST: null,
    GET_PERMISSIONS_RESPONSE: null,
    // PUSHMAIL
    GET_PUSHMAIL_REQUEST: null,
    GET_PUSHMAIL_RESPONSE: null,
    SAVE_PUSHMAIL_REQUEST: null,
    SAVE_PUSHMAIL_RESPONSE: null,
    GET_VISUALIZATION_REQUEST: null,
    GET_VISUALIZATION_RESPONSE: null,
    // TRANSLATIONS
    GET_TRANSLATIONS_REQUEST: null,
    GET_TRANSLATIONS_RESPONSE: null,
    // REVIEWS
    CREATE_REVIEW_REQUEST: null,
    CREATE_REVIEW_RESPONSE: null,
    GET_PDF_TEMPLATES_RESPONSE: null,
    CREATE_REVIEW_PDF_RESPONSE: null,
    CREATE_ZIP_RESPONSE: null,
    CREATE_EXCEL_RESPONSE: null,
    UPDATE_REVIEW_PDF_TEMPLATE_RESPONSE: null,
    USER_CONFIRMATION_RESPONSE: null,
    GET_EDITING_REQUEST_STATE: null,
    GET_REVIEW_REQUEST: null,
    GET_REVIEW_RESPONSE: null,
    GET_REVIEW_PDF_REQUEST: null,
    GET_REVIEW_PDF_RESPONSE: null,
    GET_REVIEW_PDF_STATE_REQUEST: null,
    GET_REVIEW_PDF_STATE_RESPONSE: null,
    GET_REVIEWS_REQUEST: null,
    GET_REVIEWS_RESPONSE: null,
    SET_SELECTED_TAB_REQUEST: null,
    SET_SELECTED_TAB_RESPONSE: null,
    ACESS_REVIEW_BY_TYPE_REQUEST: null,
    ACESS_REVIEW_BY_TYPE_RESPONSE: null,
    UPDATE_REVIEW_CLIPS_ALSO_IN_REQUEST: null,
    UPDATE_REVIEW_CLIPS_ALSO_IN_RESPONSE: null,
    // NEWSDIGEST
    CREATE_NEWSDIGEST_REQUEST: null,
    CREATE_NEWSDIGEST_RESPONSE: null,
    GET_NEWSDIGEST_REQUEST: null,
    GET_NEWSDIGEST_RESPONSE: null,
    GET_NEWSDIGEST_PDF_REQUEST: null,
    GET_NEWSDIGEST_PDF_RESPONSE: null,
    GET_NEWSDIGEST_PDF_STATE_REQUEST: null,
    GET_NEWSDIGEST_PDF_STATE_RESPONSE: null,
    GET_NEWSDIGESTS_REQUEST: null,
    GET_NEWSDIGESTS_RESPONSE: null,
    SET_NEWSDIGESTSELECTED_TAB_REQUEST: null,
    SET_NEWSDIGESTSELECTED_TAB_RESPONSE: null,
    ACCESS_NEWSDIGEST_BY_TYPE_REQUEST: null,
    ACCESS_NEWSDIGEST_BY_TYPE_RESPONSE: null,
    CONVERT_INFOGRAPHY_TO_LINK_REQUEST: null,
    CONVERT_INFOGRAPHY_TO_LINK_RESPONSE: null,
    CONVERT_INFOGRAPHY_TO_PDF_REQUEST: null,
    CONVERT_INFOGRAPHY_TO_PDF_RESPONSE: null,
    DELETE_INFOGRAPHY_REQUEST: null,
    DELETE_INFOGRAPHY_RESPONSE: null,
    EDIT_INFOGRAPHY_REQUEST: null,
    EDIT_INFOGRAPHY_RESPONSE: null,
    // INFOGRAPHIE
    GET_INFOGRAPHIE_REQUEST: null,
    GET_INFOGRAPHIE_RESPONSE: null,
    GET_WIDGET_INFOGRAPHY_REQUEST: null,
    GET_WIDGET_INFOGRAPHY_RESPONSE: null,
    INSERT_INFOGRAPHY_RQUEST: null,
    INSERT_INFOGRAPHY_RESPONSE: null,
    GET_INFOGRAPPHY_BY_HASH_REQUEST: null,
    GET_INFOGRAPPHY_BY_HASH_RESPONSE: null,
    // DASHBOARD
    GET_DASHBOARD_RESPONSE: null,
    GET_DASHBOARD_REQUEST: null,
    // USERS
    GET_LOGIN_RESPONSE: null,
    GET_PUBLIC_LOGIN_RESPONSE: null,
    GET_LOGIN_WITH_TICKET_RESPONSE: null,
    GET_LOGOUT_RESPONSE: null,
    GET_CURRENT_USER_REQUEST: null,
    GET_CURRENT_USER_RESPONSE: null,
    GET_USERS_BY_GROUPS_REQUEST: null,
    GET_USERS_BY_GROUPS_RESPONSE: null,
    GET_USER_PROFILE_DATA_REQUEST: null,
    GET_USER_PROFILE_DATA_RESPONSE: null,
    SAVE_USER_PROFILE_DATA_REQUEST: null,
    SAVE_USER_PROFILE_DATA_RESPONSE: null,
    UPDATE_USER_PASSWORD_RESPONSE: null,
    UPDATE_USER_PASSWORD_REQUEST: null,
    GET_USER_COUNTRIES_REQUEST: null,
    GET_USER_COUNTRIES_RESPONSE: null,
    GET_LOGOUT_REQUEST: null,
    UPDATE_USER_BRANDING_REQUEST: null,
    UPDATE_USER_BRANDING_RESPONSE: null,
    // VISUALIZATIONS
    GET_VISUALIZATIONS_REQUEST: null,
    GET_VISUALIZATIONS_RESPONSE: null,
    // CopyrightDeclaration
    UPDATE_COPYRIGHT_DECLARATION_REQUEST: null,
    UPDATE_COPYRIGHT_DECLARATION_RESPONSE: null,
    // WIDGETS
    ADD_WIDGET_REQUEST: null,
    ADD_WIDGET_RESPONSE: null,
    GET_WIDGETS_REQUEST: null,
    GET_WIDGETS_RESPONSE: null,
    GET_WIDGET_DRILLDOWN_DATA_REQUEST: null,
    GET_WIDGET_DRILLDOWN_DATA_RESPONSE: null,
    GET_NEXT_WIDGET_DRILLDOWN_DATA_REQUEST: null,
    GET_NEXT_WIDGET_DRILLDOWN_DATA_RESPONSE: null,
    // COMMON
    GET_SOURCE_TYPES_REQUEST: null,
    GET_SOURCE_TYPES_RESPONSE: null,
    SAVE_PDF_EXPORT_USER_SETTINGS_REQUEST: null,
    SAVE_PDF_EXPORT_USER_SETTINGS_RESPONSE: null,
    // INSIGHTS
    ADD_INSIGHTS_WIDGET_REQUEST: null,
    ADD_INSIGHTS_WIDGET_RESPONSE: null,
    CREATE_WIDGET_AS_MY_QUICK_WIDGET_REQUEST: null,
    CREATE_WIDGET_AS_MY_QUICK_WIDGET_RESPONSE: null,
    GET_DASHBOARD_INFO_RESPONSE: null,
    GET_DASHBOARD_INFO_REQUEST: null,
    GET_CREATE_WIDGET_MODEL_REQUEST: null,
    GET_CREATE_WIDGET_MODEL_RESPONSE: null,
    // INSIGHTS: DASHBOARDS
    GET_DASHBOARDS_REQUEST: null,
    GET_DASHBOARDS_RESPONSE: null,
    GET_DASHBOARD_TABS_REQUEST: null,
    GET_DASHBOARD_TABS_RESPONSE: null,
    UPDATE_DASHBOARD_NAME_REQUEST: null,
    UPDATE_DASHBOARD_NAME_RESPONSE: null,
    REMOVE_DASHBOARD_REQUEST: null,
    REMOVE_DASHBOARD_RESPONSE: null,
    ADD_DASHBOARD_REQUEST: null,
    ADD_DASHBOARD_RESPONSE: null,
    DUPLICATE_DASHBOARD_REQUEST: null,
    DUPLICATE_DASHBOARD_RESPONSE: null,
    SET_DEFAULT_DASHBOARD_REQUEST: null,
    SET_DEFAULT_DASHBOARD_RESPONSE: null,
    GENERATE_DUPLICATE_DASHBOARD_NAME_REQUEST: null,
    GENERATE_DUPLICATE_DASHBOARD_NAME_RESPONSE: null,
    GET_PDF_SNAPSHOT_REQUEST: null,
    GET_PDF_SNAPSHOT_RESPONSE: null,
    GET_DASHBOARD_SNAPSHOT_REQUEST: null,
    GET_DASHBOARD_SNAPSHOT_RESPONSE: null,
    // INSIGHTS: TABULATIONS
    GET_TABULATION_WIDGETS_REQUEST: null,
    GET_TABULATION_WIDGETS_RESPONSE: null,
    SET_TABULATION_ORDER_REQUEST: null,
    SET_TABULATION_ORDER_RESPONSE: null,
    COPY_TABULATION_REQUEST: null,
    COPY_TABULATION_RESPONSE: null,
    RENAME_TABULATION_REQUEST: null,
    RENAME_TABULATION_RESPONSE: null,
    ADD_TAB_RESPONSE: null,
    ADD_TAB_REQUEST: null,
    UPDATE_TAB_REQUEST: null,
    UPDATE_TAB_RESPONSE: null,
    DELETE_TAB_REQUEST: null,
    DELETE_TAB_RESPONSE: null,
    DOWNLOAD_TAB_PDF_REQUEST: null,
    DOWNLOAD_TAB_PDF_RESPONSE: null,
    DOWNLOAD_TAB_IMAGE_REQUEST: null,
    DOWNLOAD_TAB_IMAGE_RESPONSE: null,
    DOWNLOAD_TAB_POWERPOINT_REQUEST: null,
    DOWNLOAD_TAB_POWERPOINT_RESPONSE: null,
    GENERATE_DUPLICATE_TABULATION_NAME_REQUEST: null,
    GENERATE_DUPLICATE_TABULATION_NAME_RESPONSE: null,
    // INSIGHTS: WIDGETS
    CREATE_WIDGET_REQUEST: null,
    CREATE_WIDGET_RESPONSE: null,
    UPDATE_WIDGET_REQUEST: null,
    UPDATE_WIDGET_RESPONSE: null,
    GET_WIDGET_DATA_REQUEST: null,
    GET_WIDGET_DATA_RESPONSE: null,
    GET_WIDGET_DIMENSIONS_REQUEST: null,
    GET_WIDGET_DIMENSIONS_RESPONSE: null,
    GET_QUICK_WIDGETS_REQUEST: null,
    GET_QUICK_WIDGETS_RESPONSE: null,
    SORT_WIDGETS_REQUEST: null,
    SORT_WIDGETS_RESPONSE: null,
    DELETE_WIDGET_REQUEST: null,
    DELETE_WIDGET_RESPONSE: null,
    UPDATE_WIDGET_SETTINGS_REQUEST: null,
    UPDATE_WIDGET_SETTINGS_RESPONSE: null,
    UPDATE_EXPANDED_WIDGET_PARAMETERS_REQUEST: null,
    UPDATE_EXPANDED_WIDGET_PARAMETERS_RESPONSE: null,
    GET_NEXT_TIMELINE_DATA_REQUEST: null,
    GET_NEXT_TIMELINE_DATA_RESPONSE: null,
    COPY_WIDGET_REQUEST: null,
    COPY_WIDGET_RESPONSE: null,
    GET_WIDGET_FILTER_SEARCH_DATE_REQUEST: null,
    GET_WIDGET_FILTER_SEARCH_DATE_RESPONSE: null,
    GET_HASH_DASHBOARD_REQUEST: null,
    GET_HASH_DASHBOARD_RESPONSE: null,
    // product news
    GET_PRODUCT_NEWS_REQUEST: null,
    GET_PRODUCT_NEWS_RESPONSE: null,
    GET_RELATED_PRODUCT_NEWS_REQUEST: null,
    GET_RELATED_PRODUCT_NEWS_RESPONSE: null,
    GET_PRODUCT_NEWS_TYPES_RESPONSE: null,
    GET_PRODUCT_NEWS_BY_ID_REQUEST: null,
    GET_PRODUCT_NEWS_BY_ID_RESPONSE: null,
    MARK_ALL_PRODUCT_NEWS_AS_READ_REQUEST: null,
    MARK_ALL_PRODUCT_NEWS_AS_READ_RESPONSE: null,
    MARK_UNREADABLE_COUNT_PRODUCT_NEWS_REQUEST: null,
    MARK_UNREADABLE_COUNT_PRODUCT_NEWS_RESPONSE: null,
    // PERCOLATORS
    GET_ALL_PERCOLATORS_REQUEST: null,
    GET_ALL_PERCOLATORS_RESPONSE: null,
    GET_SEARCH_MODEL_PERCOLATOR_RESPONSE: null,
    GET_LIMIT_OF_PERCOLATOR_RESPONSE: null,
    GET_IS_ACTIVE_PERCOLATOR_RESPONSE: null,
    GET_ALL_PERCOLATORS_RETROACTIVELY_REQUEST: null,
    GET_ALL_PERCOLATORS_RETROACTIVELY_RESPONSE: null,
    SAVE_PERCOLATORS_RETROACTIVELY_REQUEST: null,
    SAVE_PERCOLATORS_RETROACTIVELY_RESPONSE: null,
    DELETE_PERCOLATORS_RETROACTIVELY_REQUEST: null,
    DELETE_PERCOLATORS_RETROACTIVELY_RESPONSE: null,
    CANCEL_PERCOLATORS_RETROACTIVELY_REQUEST: null,
    CANCEL_PERCOLATORS_RETROACTIVELY_RESPONSE: null,
    GET_Auto_TAGGING_INFOS_REQUEST: null,
    GET_Auto_TAGGING_INFOS_RESPONSE: null,
    // QUERY BUILDER
    GET_ALL_FILTERS_REQUEST: null,
    GET_ALL_FILTERS_RESPONSE: null,
    GET_QUERYBUILDER_FIELD_VALUES_REQUEST: null,
    GET_QUERYBUILDER_FIELD_VALUES_RESPONSE: null,
    GET_QUERYBUILDER_FIELDS_REQUEST: null,
    GET_QUERYBUILDER_FIELDS_RESPONSE: null,
    GET_QUERY_STRING_PERCOLATOR_REQUEST: null,
    GET_QUERY_STRING_PERCOLATOR_RESPONSE: null,
    ACTIVATE_DESACTIVATE_PERCOLATOR_REQUEST: null,
    ACTIVATE_DESACTIVATE_PERCOLATOR_RESPONSE: null,
    DELETE_PERCOLATOR_REQUEST: null,
    DELETE_PERCOLATOR_RESPONSE: null,
    INSERT_PERCOLATOR_REQUEST: null,
    INSERT_PERCOLATOR_RESPONSE: null,
    TEST_PERCOLATOR_REQUEST: null,
    TEST_PERCOLATOR_RESPONSE: null,
    CHECK_PERCOLATOR_REQUEST: null,
    CHECK_PERCOLATOR_RESPONSE: null,
    // Cookies & Privacy
    GET_COOKIES_AND_PRIVACY_RESPONSE: null,
    GET_COOKIES_AND_PRIVACY_REQUEST: null,
    // Users list
    GET_USERS_GROUP_PROFILE_REQUEST: null,
    GET_USERS_GROUP_PROFILE_RESPONSE: null,
    GET_GROUPS_ROLES_BY_USER_ACCOUNT_REQUEST: null,
    GET_GROUPS_ROLES_BY_USER_ACCOUNT_RESPONSE: null,
    GET_DELETE_USER_REQUEST: null,
    GET_DELETE_USER_RESPONSE: null,
    GET_INSERT_NEW_USER_REQUEST: null,
    GET_INSERT_NEW_USER_RESPONSE: null,
    GET_PROFILES_REQUEST: null,
    GET_PROFILES_RESPONSE: null,
    GET_EXPORTED_INFO_USERS_LISTS_EXCEL_REQUEST: null,
    GET_EXPORTED_INFO_USERS_LISTS_EXCEL_RESPONSE: null,
    GET_INFO_GROUPS_LIST_REQUEST: null,
    GET_INFO_GROUPS_LIST_RESPONSE: null,
    UPDATE_GROUP_USER_MEMBERSHIP_REQUEST: null,
    UPDATE_GROUP_USER_MEMBERSHIP_RESPONSE: null,
    RESET_THEME_PROVIDER_RESPONSE: null,
    // EMAIL ALERTS
    GET_EMAIL_ALERTS_REQUEST: null,
    GET_EMAIL_ALERTS_RESPONSE: null,
    ACTIVATE_DEACTIVATE_EMAIL_ALERTS_REQUEST: null,
    ACTIVATE_DEACTIVATE_EMAIL_ALERTS_RESPONSE: null,
    DELETE_EMAIL_ALERTS_REQUEST: null,
    DELETE_EMAIL_ALERTS_RESPONSE: null,
    GET_EMAIL_ALERTS_PUSHMAIL_LAYOUT_REQUEST: null,
    GET_EMAIL_ALERTS_PUSHMAIL_LAYOUT_RESPONSE: null,
    GET_EMAIL_ALERTS_USER_CLIP_SELECTION_REQUEST: null,
    GET_EMAIL_ALERTS_USER_CLIP_SELECTION_RESPONSE: null,
    GET_EMAIL_ALERT_BY_ID_REQUEST: null,
    GET_EMAIL_ALERT_BY_ID_RESPONSE: null,
    UPDATE_EMAIL_ALERT_REQUEST: null,
    UPDATE_EMAIL_ALERT_RESPONSE: null,
    INSERT_EMAIL_ALERT_REQUEST: null,
    INSERT_EMAIL_ALERT_RESPONSE: null,
    GET_CATEGORIES_RESPONSE: null,
    GET_CATEGORIES_REQUEST: null,
    GET_CATEGORIES_GROUPS_RESPONSE: null,
    GET_CATEGORIES_GROUPS_REQUEST: null,
    GET_CATEGORIES_GROUPS_MEMBERS_REQUEST: null,
    GET_CATEGORIES_GROUPS_MEMBERS_RESPONSE: null,
    ADD_CLIP_SELECTION_RESPONSE: null,
    ADD_CLIP_SELECTION_REQUEST: null,
    UPDATE_CLIP_SELECTION_RESPONSE: null,
    UPDATE_CLIP_SELECTION_REQUEST: null,
    DELETE_CLIP_SELECTION_RESPONSE: null,
    DELETE_CLIP_SELECTION_REQUEST: null,
    SET_CLIP_SELECTION_AUTHORIZATION_REQUEST: null,
    SET_CLIP_SELECTION_AUTHORIZATION_RESPONSE: null,
    // display incident message
    GET_INCIDENT_MESSAGE_REQUEST: null,
    GET_INCIDENT_MESSAGE_RESPONSE: null,
    GET_COPYRIGHT_DECLARATIONS_REQUEST: null,
    GET_COPYRIGHT_DECLARATIONS_RESPONSE: null,
    GET_TOP_VISUALIZATIONS_BY_SOURCE_RESPONSE: null,
    GET_TOP_VISUALIZATIONS_BY_SOURCE_REQUEST: null,
    GET_TOP_VISUALIZATIONS_BY_CLIP_RESPONSE: null,
    GET_TOP_VISUALIZATIONS_BY_CLIP_REQUEST: null,
    GET_TOP_VISUALIZATIONS_BY_DATE_RESPONSE: null,
    GET_TOP_VISUALIZATIONS_BY_DATE_REQUEST: null,
    GET_DISTRIBUTIONS_REQUEST: null,
    GET_DISTRIBUTIONS_RESPONSE: null,
    GET_DISTRIBUTIONS_MEMBERS_REQUEST: null,
    GET_DISTRIBUTIONS_MEMBERS_RESPONSE: null,
    GET_DISTRIBUTIONS_CATEGORIES_REQUEST: null,
    GET_DISTRIBUTIONS_CATEGORIES_RESPONSE: null,
    GET_PROFILE_BY_ID_REQUEST: null,
    GET_PROFILE_BY_ID_RESPONSE: null,
    UPDATE_PROFILE_ROLES_REQUEST: null,
    UPDATE_PROFILE_ROLES_RESPONSE: null,
    CREATE_PROFILE_REQUEST: null,
    CREATE_PROFILE_RESPONSE: null,
    UPDATE_PROFILE_REQUEST: null,
    UPDATE_PROFILE_RESPONSE: null,
    DELETE_PROFILE_REQUEST: null,
    DELETE_PROFILE_RESPONSE: null,
    CREATE_DISTRIBUTION_REQUEST: null,
    CREATE_DISTRIBUTION_RESPONSE: null,
    UPDATE_DISTRIBUTION_REQUEST: null,
    UPDATE_DISTRIBUTION_RESPONSE: null,
    DELETE_DISTRIBUTION_REQUEST: null,
    DELETE_DISTRIBUTION_RESPONSE: null,
    DELETE_GROUP_MEMBER_REQUEST: null,
    DELETE_GROUP_MEMBER_RESPONSE: null,
    DELETE_USER_PROFILE_REQUEST: null,
    DELETE_USER_PROFILE_RESPONSE: null,
    UPDATE_USER_PROFILE_REQUEST: null,
    UPDATE_USER_PROFILE_RESPONSE: null,
    CREATE_HIERARCHY_REQUEST: null,
    CREATE_HIERARCHY_RESPONSE: null,
    CHANGE_USER_ACCOUNT_STATUS_RESPONSE: null,
    CHANGE_USER_ACCOUNT_STATUS_REQUEST: null,
    UPDATE_USERNAME_REQUEST: null,
    UPDATE_USERNAME_RESPONSE: null,
});
