var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
var AccordionStyled = styled(Accordion)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: max-content;\n  box-shadow: none;\n  border: none;\n  &:before {\n    display: none;\n  }\n"], ["\n  height: max-content;\n  box-shadow: none;\n  border: none;\n  &:before {\n    display: none;\n  }\n"])));
var AccordionSummaryStyled = styled(AccordionSummary)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function () { return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: 1px solid var(--palette-common-border1);\n    border-radius: var(--borderRadius-md);\n    &[class*='Mui-expanded'] {\n      background: var(--palette-common-paperBg1);\n      border-color: transparent;\n    }\n  "], ["\n    border: 1px solid var(--palette-common-border1);\n    border-radius: var(--borderRadius-md);\n    &[class*='Mui-expanded'] {\n      background: var(--palette-common-paperBg1);\n      border-color: transparent;\n    }\n  "]))); });
export { AccordionStyled, AccordionSummaryStyled, AccordionDetails };
var templateObject_1, templateObject_2, templateObject_3;
