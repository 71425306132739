import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import { useRef, useMemo, useImperativeHandle, forwardRef, useLayoutEffect, } from 'react';
import { v4 as uuid } from 'uuid';
import { Wrapper } from './Checkbox.styles';
var Checkbox = forwardRef(function (props, ref) {
    var _a = props.checked, checked = _a === void 0 ? false : _a, _b = props.children, children = _b === void 0 ? null : _b, _c = props.className, className = _c === void 0 ? '' : _c, _d = props.classNameLabel, classNameLabel = _d === void 0 ? '' : _d, _e = props.disabled, disabled = _e === void 0 ? false : _e, idProp = props.id, _f = props.indeterminate, indeterminate = _f === void 0 ? false : _f, onChange = props.onChange, title = props.title, _g = props.name, name = _g === void 0 ? '' : _g;
    var id = useMemo(function () { return idProp !== null && idProp !== void 0 ? idProp : "checkbox-".concat(uuid()); }, []);
    var inputElementRef = useRef(null);
    var getValue = function () { var _a; return Boolean((_a = inputElementRef.current) === null || _a === void 0 ? void 0 : _a.checked); };
    var checkboxClassNames = classnames('checkbox', className);
    useImperativeHandle(ref, function () { return ({
        getValue: getValue,
    }); });
    useLayoutEffect(function () {
        if (inputElementRef.current) {
            inputElementRef.current.indeterminate = Boolean(props.indeterminate);
        }
    });
    return (_jsxs(Wrapper, { className: checkboxClassNames, title: title, children: [_jsx("input", { checked: checked && !indeterminate, "aria-checked": checked && !indeterminate, disabled: disabled, id: id, onChange: onChange, ref: inputElementRef, type: "checkbox", name: name, title: title, "aria-label": title }), _jsxs("label", { className: classnames('align-middle', classNameLabel), htmlFor: id, children: [!children && _jsx("span", { className: "hidden", children: id }), children] })] }));
});
Checkbox.displayName = 'Checkbox';
export default Checkbox;
