var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MenuItem from '@mui/material/MenuItem';
import { SelectStyled } from './Select.styles';
function Select(_a) {
    var _b = _a.labelKey, labelKey = _b === void 0 ? 'label' : _b, _c = _a.valueKey, valueKey = _c === void 0 ? 'value' : _c, _d = _a.options, options = _d === void 0 ? [] : _d, _e = _a.placeholder, placeholder = _e === void 0 ? '' : _e, children = _a.children, _f = _a.displayEmpty, displayEmpty = _f === void 0 ? false : _f, restProps = __rest(_a, ["labelKey", "valueKey", "options", "placeholder", "children", "displayEmpty"]);
    return (_jsxs(SelectStyled, __assign({ MenuProps: {
            elevation: 0,
            anchorPosition: { left: 0, top: 40 },
        }, displayEmpty: Boolean(placeholder) || displayEmpty }, restProps, { children: [placeholder && (_jsx(MenuItem, { value: "", disabled: true, children: placeholder })), options === null || options === void 0 ? void 0 : options.map(function (option) { return (_jsx(MenuItem, { value: option[valueKey], children: option[labelKey] }, option[valueKey])); }), children] })));
}
export default Select;
export { MenuItem };
