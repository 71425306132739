var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
var GET_COPYRIGHT_DECLARATIONS_EVENT = 'getCopyrightsEvent';
var CopyrightStore = /** @class */ (function (_super) {
    __extends(CopyrightStore, _super);
    function CopyrightStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    CopyrightStore.prototype.initializeState = function () {
        this._copyrights = [];
    };
    CopyrightStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(CopyrightStore.prototype, "copyrights", {
        get: function () {
            return this._copyrights;
        },
        set: function (copyrights) {
            this._copyrights = copyrights;
        },
        enumerable: false,
        configurable: true
    });
    CopyrightStore.prototype.emitGetCopyrights = function () {
        this.emit(GET_COPYRIGHT_DECLARATIONS_EVENT);
    };
    CopyrightStore.prototype.addGetCopyrightsListener = function (callback) {
        this.on(GET_COPYRIGHT_DECLARATIONS_EVENT, callback);
    };
    CopyrightStore.prototype.removeGetCopyrightsListener = function (callback) {
        this.removeListener(GET_COPYRIGHT_DECLARATIONS_EVENT, callback);
    };
    CopyrightStore.prototype.registerToActions = function (payload) {
        var _a;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_COPYRIGHT_DECLARATIONS_RESPONSE:
                if ((_a = action.json) === null || _a === void 0 ? void 0 : _a.data) {
                    this.copyrights = action.json.data;
                    this.initialized = true;
                    this.emitGetCopyrights();
                }
                else {
                    this.emitError('GET_COPYRIGHT_DECLARATIONS');
                }
                break;
            case ActionTypes.UPDATE_COPYRIGHT_DECLARATION_RESPONSE:
                if (action.json) {
                    var model_1 = action.json.model;
                    var copyRightsCopy = __spreadArray([], this.copyrights, true);
                    var findIndex = copyRightsCopy.findIndex(function (elm) { return elm.id === model_1[0].id && elm.version === model_1[0].version; });
                    copyRightsCopy[findIndex].declaredRecipients =
                        model_1[0].declarationNumber;
                    this.copyrights = copyRightsCopy;
                    this.emitGetCopyrights();
                }
                else {
                    this.emitError('GET_COPYRIGHT_DECLARATIONS');
                }
                break;
            default:
                break;
        }
    };
    return CopyrightStore;
}(BaseStore));
export default new CopyrightStore();
