var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from 'styled-components';
import Modal from '@commons/Modal';
var pop = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    transform: scale(0.3);\n    opacity: 0.4;\n  }\n  75% {\n    transform: scale(1.2);\n    opacity: 1;\n  }\n  100% {\n    transform: scale(1)\n  }\n"], ["\n  0% {\n    transform: scale(0.3);\n    opacity: 0.4;\n  }\n  75% {\n    transform: scale(1.2);\n    opacity: 1;\n  }\n  100% {\n    transform: scale(1)\n  }\n"])));
var ModalStyled = styled(Modal)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .modal__body {\n    height: 15rem;\n  }\n  .modal__container {\n    animation: ", " 0.3s ease-out;\n    .modal__body {\n      min-height: 5rem;\n    }\n  }\n  .subnav__item-btn {\n  }\n  .subnav__item {\n    img {\n    }\n    span {\n    }\n  }\n  ul {\n    padding-left: 15px;\n    list-style: initial;\n  }\n"], ["\n  .modal__body {\n    height: 15rem;\n  }\n  .modal__container {\n    animation: ", " 0.3s ease-out;\n    .modal__body {\n      min-height: 5rem;\n    }\n  }\n  .subnav__item-btn {\n  }\n  .subnav__item {\n    img {\n    }\n    span {\n    }\n  }\n  ul {\n    padding-left: 15px;\n    list-style: initial;\n  }\n"])), pop);
export { ModalStyled };
var templateObject_1, templateObject_2;
