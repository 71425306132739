import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import keycode from 'keycode';
import PropTypes from 'prop-types';
import Svg from 'commons/Svg/Svg';
import ButtonIcon from 'commons/ButtonIcon';
var propTypes = {
    item: PropTypes.shape({
        icon: PropTypes.string,
        id: PropTypes.string,
        isActive: PropTypes.bool,
        bundle: PropTypes.string,
        isTranslated: PropTypes.bool,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    isDeletable: PropTypes.bool,
    isEmbedded: PropTypes.bool,
    onRemoveElement: PropTypes.func,
};
var defaultProps = {
    isDeletable: false,
    isEmbedded: false,
    onRemoveElement: Function.prototype,
};
var AdvancedFiltersMenuElement = function (_a) {
    var _b = _a.item, filterName = _b.filterName, icon = _b.icon, id = _b.id, isActive = _b.isActive, item = _a.item, onClick = _a.onClick, isDeletable = _a.isDeletable, isEmbedded = _a.isEmbedded, onRemoveElement = _a.onRemoveElement, selectedFacetItems = _a.selectedFacetItems;
    var handleClick = function () { return onClick(id); };
    var handleRemove = function (e) {
        e.preventDefault();
        e.stopPropagation();
        onRemoveElement(item);
    };
    var handleKeyDown = function (event) {
        if (event.keyCode === keycode('enter')) {
            handleClick();
        }
    };
    var advancedFiltersMenuElementClassNames = classnames("".concat(isEmbedded
        ? 'c-advanced-filters-menu-element-embedded'
        : 'c-advanced-filters-menu-element'), { 'is-active-embedded t-color-primary-border': isEmbedded && isActive }, { 'is-active background-item': isActive && !isEmbedded });
    var selectedFacetLenth = selectedFacetItems && selectedFacetItems.length > 0
        ? selectedFacetItems.filter(function (elm) { return elm.includes(id); }).length
        : 0;
    return (_jsxs("li", { className: advancedFiltersMenuElementClassNames, onClick: handleClick, onKeyDown: handleKeyDown, role: "menuitem", tabIndex: 0, title: filterName, children: [isDeletable ? (_jsx(ButtonIcon, { className: "delete-icon", onClick: handleRemove, onKeyUp: handleRemove, title: "Remove button", children: _jsx(Svg, { icon: "close", size: "x-small" }) })) : null, _jsx(Svg, { icon: icon, size: "large", className: "filter-icon" }), _jsxs("span", { className: "c-advanced-filters-menu-element__label text-truncate", children: [filterName, ' ', selectedFacetLenth > 0 ? (_jsxs("span", { className: "number-filters", children: ["(", selectedFacetLenth, ")"] })) : (false)] })] }));
};
AdvancedFiltersMenuElement.defaultProps = defaultProps;
AdvancedFiltersMenuElement.propTypes = propTypes;
export default AdvancedFiltersMenuElement;
