import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
var PushmailActionCreators = {
    getPushmailData: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_PUSHMAIL_REQUEST,
        });
        WebApi.getPushmailData(model);
    },
    savePushmail: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.SAVE_PUSHMAIL_REQUEST,
        });
        WebApi.savePushmail(model, callback);
    },
    showPushmailVisualization: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_VISUALIZATIONS_REQUEST,
        });
        WebApi.getVisualization(model);
    },
    getUsersByGroups: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_USERS_BY_GROUPS_REQUEST,
        });
        WebApi.getUsersByGroups();
    },
};
export default PushmailActionCreators;
