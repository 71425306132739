import keyMirror from 'fbjs/lib/keyMirror';
export default {
    API_ROOT: 'https://front-api.test.reputation.onclusive.com',
    // API_ROOT: 'https://testenv-ewp.kantarprod.com',
    API_RESOURCES: 'https://infrakantar.s3.eu-west-1.amazonaws.com/webapp/assets',
    INTERCOM_APPID: 'v7y83osb',
    HEADLINE_TAB_ENUM: {
        HEADLINE: 0,
        ORIGINAL_HEADLINE: 1,
    },
    SUMMARY_TAB_ENUM: {
        SUMMARY: 0,
        ORIGINAL_SUMMARY: 1,
        FULLTEXT: 2,
    },
    BREAKPOINT: {
        xl: 1200,
        lg: 992,
        md: 768,
        sm: 768,
        // sm: 544,
    },
    // change the order of colors only when the order of the data of the pie chart change
    NATO_CHART_SENTIMENT_COLORS: [
        '#0be881', // very positive
        '#90f3b0', // slightly positive
        '#ff978c', // slightly negative
        '#ff4d4d', // very negative
        '#aeaeae', // neutral
    ],
    NATO_CHART_SENTIMENT_COLORS_BAR: {
        117: '#0be881', // very positive
        118: '#90f3b0', // slightly positive
        119: '#aeaeae', // neutral
        120: '#ff978c', // slightly negative
        121: '#ff4d4d', // very negative
    },
    INSIGHT_NATO_CHART_SENTIMENT_COLORS_BAR: {
        'very-positive': '#0be881', // very positive
        'slightly-positive': '#90f3b0', // slightly positive
        neutral: '#aeaeae', // neutral
        'slightly-negative': '#ff978c', // slightly negative
        'very-negative': '#ff4d4d', // very negative
    },
    ROLES_ENUM: {
        ANALYTICS_ACCESS: 30,
        MONITORING_ACCESS: 20,
        KIOSK_ACCESS: 34,
        CAN_MANAGE_SYSTEM_OBJECTS: 14,
        CAN_ACCESS_BACKEND: 16,
        CAN_ACCESS_TRASH: 17,
        CAN_ADMINISTRATE: 18,
        CAN_HANDLE_REQUESTS: 19,
        CAN_CATEGORIZE_CLIP: 21,
        CAN_ORDER_BRODCAST: 22,
        CAN_CHANGE_SENTIMENT: 23,
        CAN_DENY_CLIP: 24,
        CAN_EXPORT_SELECTION: 25,
        CAN_EXPORT_SELECTION_TO_EXCEL: 26,
        CAN_UPLOAD_CLIP: 27,
        CAN_TRASH_CLIP: 28,
        CAN_ACCESS_TRASH_KM: 29,
        CAN_EDIT_DASHBOARD: 31,
        CAN_EXPORT_DASHBOARD: 32,
        CAN_EXPORT_INFOGRAPHY: 33,
        CAN_EDIT_KIOSK_ITEM: 35,
        CAN_MANAGE_USER: 36,
        CAN_MANAGE_PROFIL: 37,
        CAN_MANAGE_CATEGORIES: 38,
        CAN_MANAGE_RULES: 39,
        CAN_MANAGE_BRANDING: 40,
        CAN_MANAGE_PRESS_REVIEW_TEMPLATE: 41,
        CAN_MANAGE_PUSHMAIL_TEMPLATE: 42,
        CAN_ACCESS_SUBSCRIPTION: 43,
        CAN_VIEW_CLIP: 44,
        CAN_ORGANIZE_FILTERS: 45,
        CAN_MANAGE_DRAFT: 46,
        CAN_ACCESS_EXPORT: 47,
        CAN_EDIT_ARTICLE: 48,
        CAN_ORDER_BROADCAST_FROM_REVIEW_PAGE: 49,
        CAN_SHARE_ON_SOCIAL_WEBSITES_MONITORING: 50,
        CAN_SHARE_ON_SOCIAL_WEBSITES_REVIEW: 51,
        ALLOW_NAVIGATE_WITHIN_PRESS_REVIEWS: 52,
        CAN_DOWNLOAD_BROADCAST: 53,
        CAN_MANAGE_ARCHIVE_REQUEST: 54,
        CAN_SHARE_BY_EMAIL: 55,
        CAN_ACCESS_CODIFICATION: 56,
        CAN_UNLOCK_CODIFICATION: 57,
        CAN_EDIT_CODIFICATION: 59,
        CAN_EXPORT_PRESS_REVIEW: 60,
        CAN_EXPORT_PERMALINK: 61,
        CAN_EXPORT_PDF: 62,
        ADMIN: 63,
        CAN_PLAY_BROADCAST: 64,
        CAN_MANAGE_COPYRIGHT: 65,
        CAN_ACCESS_PRODUCT_NEWS: 66,
        CAN_MANAGE_AUTOTAGGING: 67,
        CAN_ACCESS_CONSUMPTION: 68,
        CAN_SWITCH_ON_NEW_SITE: 69,
    },
    CHART_COLORS: [
        '#1f77b4',
        '#aec7e8',
        '#ff7f0e',
        '#ffbb78',
        '#2ca02c',
        '#98df8a',
        '#d62728',
        '#ff9896',
        '#9467bd',
        '#c5b0d5',
        '#8c564b',
        '#c49c94',
        '#e377c2',
        '#f7b6d2',
        '#7f7f7f',
        '#c7c7c7',
        '#bcbd22',
        '#dbdb8d',
        '#17becf',
        '#9edae5',
        '#3957ff',
        '#d3fe14',
        '#c9080a',
        '#fec7f8',
        '#0b7b3e',
        '#0bf0e9',
        '#c203c8',
        '#fd9b39',
        '#888593',
        '#906407',
        '#98ba7f',
        '#fe6794',
        '#10b0ff',
        '#ac7bff',
        '#fee7c0',
        '#964c63',
        '#1da49c',
        '#0ad811',
        '#bbd9fd',
        '#fe6cfe',
        '#297192',
        '#d1a09c',
        '#78579e',
        '#81ffad',
        '#739400',
        '#ca6949',
        '#d9bf01',
        '#646a58',
        '#d5097e',
        '#bb73a9',
        '#ccf6e9',
        '#9cb4b6',
        '#b6a7d4',
        '#9e8c62',
        '#6e83c8',
        '#01af64',
        '#a71afd',
        '#cfe589',
        '#d4ccd1',
        '#fd4109',
        '#bf8f0e',
        '#2f786e',
        '#4ed1a5',
        '#d8bb7d',
        '#a54509',
        '#6a9276',
        '#a4777a',
        '#fc12c9',
        '#606f15',
        '#3cc4d9',
        '#f31c4e',
        '#73616f',
        '#f097c6',
        '#fc8772',
        '#92a6fe',
        '#875b44',
        '#699ab3',
        '#94bc19',
        '#7d5bf0',
        '#d24dfe',
        '#c85b74',
        '#68ff57',
        '#b62347',
        '#994b91',
        '#646b8c',
        '#977ab4',
        '#d694fd',
        '#c4d5b5',
        '#fdc4bd',
        '#1cae05',
        '#7bd972',
        '#e9700a',
        '#d08f5d',
        '#8bb9e1',
        '#fde945',
        '#a29d98',
        '#1682fb',
        '#9ad9e0',
        '#d6cafe',
        '#8d8328',
        '#b091a7',
        '#647579',
        '#1f8d11',
        '#e7eafd',
        '#b9660b',
        '#a4a644',
        '#fec24c',
        '#b1168c',
        '#188cc1',
        '#7ab297',
        '#4468ae',
        '#c949a6',
        '#d48295',
        '#eb6dc2',
        '#d5b0cb',
        '#ff9ffb',
        '#fdb082',
        '#af4d44',
        '#a759c4',
        '#a9e03a',
    ],
    INSIGHTS_CHART_COLORS: [
        '#63cdda',
        '#A788FC',
        '#eb3b5a',
        '#4bcffa',
        '#fed330',
        '#6bc9b4',
        '#889fc4',
        '#ea5e6f',
        '#9ECC66',
        '#D6A2E8',
        '#FFC257',
        '#FF8D38',
        '#2ec7c9',
        '#b6a2de',
        '#5ab1ef',
        '#ffb980',
        '#d87a80',
        '#8d98b3',
        '#e5cf0d',
        '#97b552',
        '#95706d',
        '#dc69aa',
        '#07a2a4',
        '#9a7fd1',
        '#588dd5',
        '#f5994e',
        '#c05050',
        '#59678c',
        '#c9ab00',
        '#7eb00a',
        '#6f5553',
        '#c14089',
    ],
    CODIFICATION_TYPE_ENUM: {
        single: 1,
        boolean: 2,
        string: 10,
        country: 11,
    },
    SHAREARTICLE_TYPE_ENUM: {
        single: 1,
        multi: 2,
        checkbox: 3,
        input: 4,
        textarea: 5,
        tags: 6,
        grid: 7,
    },
    COLORS: {
        balanced: '#ffc30a',
        facebook: '#3b5998',
        negative: '#e53410',
        neutral: '#808080',
        notanalyzed: '#e1e1e1',
        other: '#4A525F',
        positive: '#07be47',
        press: '#34495e',
        radio: '#f1c40f',
        'social-media': '#2980b9',
        tv: '#e67e22',
        twitter: '#1da1f2',
        web: '#a84d98',
    },
    INSIGHTS_COLORS: {
        balanced: '#ffc30a',
        facebook: '#1877f2',
        negative: '#ff4d4d',
        neutral: '#aeaeae',
        notanalyzed: '#e1e1e1',
        other: '#4A525F',
        positive: '#0be881',
        press: '#575fcf',
        radio: '#feca57',
        'social-media': '#00AFF0',
        tv: '#ff9f43',
        twitter: '#1da1f2',
        web: '#ff6b6b',
        youtube: '#ff0000',
        instagram: '#c32aa3',
        news: '#FFC312',
        cumulate_others: '#f19066',
        xx: '#f19066',
    },
    DOCUMENT_TYPE_ENUM: ['coverpage', 'doc', 'docx', 'fullpage', 'pdf'],
    DOCUMENT_MIME_ENUM: ['application/pdf', 'application/msword'],
    FACET_ENUM: {
        facetMedium: 1,
        facetTheme: 2,
        facetMediumType: 3,
        facetSourceRegion: 4,
        facetSourceName: 5,
        facetCountry: 6,
        facetAuthor: 7,
        facetHierarchies: 8,
        facetSentiment: 9,
        facetLanguage: 10,
        facetContentTyp: 12,
    },
    FACET_ITEM_KEY_SEPARATOR: '**||**',
    FILTER_ENUM: {
        'source.medium': 1,
        'clipSelections.id': 2,
        'source.type': 3,
        'source.region': 4,
        'source.name.untouched': 5,
        country: 6,
        'author.name': 7,
        hierarchies: 8,
        'sentiment.level': 9,
        'headlines.isoCode': 10,
        customerMetrics: 11,
        contentType: 12,
        'feeder.feedId': 13,
    },
    LEGEND_INTERVALS: [
        {
            method: 'timeMinute',
            minutes: 1,
            number: 1,
        },
        {
            method: 'timeMinute',
            minutes: 2,
            number: 2,
        },
        {
            method: 'timeMinute',
            minutes: 5,
            number: 5,
        },
        {
            method: 'timeMinute',
            minutes: 10,
            number: 10,
        },
        {
            method: 'timeMinute',
            minutes: 15,
            number: 15,
        },
        {
            method: 'timeMinute',
            minutes: 30,
            number: 30,
        },
        {
            method: 'timeHour',
            minutes: 60,
            number: 1,
        },
        {
            method: 'timeHour',
            minutes: 120,
            number: 2,
        },
        {
            method: 'timeHour',
            minutes: 180,
            number: 3,
        },
        {
            method: 'timeHour',
            minutes: 240,
            number: 4,
        },
        {
            method: 'timeHour',
            minutes: 360,
            number: 6,
        },
        {
            method: 'timeHour',
            minutes: 480,
            number: 8,
        },
        {
            method: 'timeHour',
            minutes: 720,
            number: 12,
        },
        {
            method: 'timeDay',
            minutes: 1440,
            number: 1,
        },
        {
            method: 'timeDay',
            minutes: 2880,
            number: 2,
        },
        {
            method: 'timeDay',
            minutes: 5760,
            number: 4,
        },
        {
            method: 'timeWeek',
            minutes: 10080,
            number: 1,
        },
        {
            method: 'timeWeek',
            minutes: 20160,
            number: 2,
        },
        {
            method: 'timeMonth',
            minutes: 43830,
            number: 1,
        },
        {
            method: 'timeMonth',
            minutes: 87660,
            number: 2,
        },
        {
            method: 'timeMonth',
            minutes: 131490,
            number: 3,
        },
        {
            method: 'timeMonth',
            minutes: 262980,
            number: 6,
        },
        {
            method: 'timeYear',
            minutes: 525960,
            number: 1,
        },
    ],
    MEDIA_TYPE_ENUM: ['audio', 'video'],
    SENTIMENT_ENUM: [
        'positive',
        'balanced',
        'negative',
        'neutral',
        'notanalyzed',
    ],
    MAX_PUSHMAIL_COMMENT_CARACTERS: 600,
    PAYLOAD: keyMirror({
        SERVER_ACTION: null,
        VIEW_ACTION: null,
    }),
    TIMEOUT_BEFORE_FILTER_UPDATE: 1000,
    EUROPE_COUNTRIES: {
        Azerbaijan: 'AZ',
        Albania: 'AL',
        Armenia: 'AM',
        'Bosnia and Herzegovina': 'BA',
        Bulgaria: 'BG',
        Cyprus: 'CY',
        Denmark: 'DK',
        France: 'FR',
        Ireland: 'IE',
        India: 'IN',
        Estonia: 'EE',
        Finland: 'FI',
        Italy: 'IT',
        Germany: 'DE',
        Spain: 'ES',
        'Czech Republic': 'CZ',
        Austria: 'AT',
        'United Kingdom': 'GB',
        Portugal: 'PT',
        Poland: 'PL',
        Belgium: 'BE',
        Slovakia: 'SK',
        Georgia: 'GE',
        Greece: 'GR',
        Croatia: 'HR',
        Hungary: 'HU',
        Iceland: 'IS',
        Israel: 'IL',
        Latvia: 'LV',
        Belarus: 'BV',
        Lithuania: 'LT',
        Macedonia: 'MK',
        Malta: 'MT',
        'Faroe Islands': 'FO',
        Andorra: 'AD',
        Luxembourg: 'LU',
        Monaco: 'MC',
        Netherlands: 'NL',
        Norway: 'NO',
        Romania: 'RO',
        'Republic of Moldova': 'MD',
        Slovenia: 'SI',
        Sweden: 'SE',
        Switzerland: 'CH',
        Turkey: 'TR',
        Ukraine: 'UA',
        'San Marino': 'SM',
        Serbia: 'RS',
        'Vatican City': 'VA',
        Russia: 'RU',
    },
    WORLD_COUNTRIES: {
        Afghanistan: 'AF',
        Angola: 'AO',
        Albania: 'AL',
        'United Arab Emirates': 'AE',
        Argentina: 'AR',
        Armenia: 'AM',
        Australia: 'AU',
        Austria: 'AT',
        Azerbaijan: 'AZ',
        Burundi: 'BI',
        Belgium: 'BE',
        Benin: 'BJ',
        'Burkina Faso': 'BF',
        Bangladesh: 'BD',
        Bulgaria: 'BG',
        'The Bahamas': 'BS',
        'Bosnia and Herzegovina': 'BA',
        Belarus: 'BY',
        Belize: 'BZ',
        Bolivia: 'BO',
        Brazil: 'BR',
        Brunei: 'BN',
        Bhutan: 'BT',
        Botswana: 'BW',
        'Central African Republic': 'CF',
        Canada: 'CA',
        Switzerland: 'CH',
        Chile: 'CL',
        China: 'CN',
        'TAIWAN, REPUBLIC OF CHINA': 'TW',
        'Ivory Coast': 'CI',
        Cameroon: 'CM',
        'Democratic Republic of the Congo': 'CD',
        'Republic of the Congo': 'CG',
        Colombia: 'CO',
        'Costa Rica': 'CR',
        Cuba: 'CU',
        'Northern Cyprus': 'CY',
        Cyprus: 'CY',
        'Czech Republic': 'CZ',
        Germany: 'DE',
        Djibouti: 'DJ',
        Denmark: 'DK',
        'Dominican Republic': 'DO',
        Algeria: 'DZ',
        Ecuador: 'EC',
        Egypt: 'EG',
        Eritrea: 'ER',
        Spain: 'ES',
        Estonia: 'EE',
        Ethiopia: 'ET',
        Finland: 'FI',
        Fiji: 'FJ',
        'United Kingdom': 'GB',
        France: 'FR',
        Gabon: 'GA',
        Georgia: 'GE',
        Ghana: 'GH',
        Guinea: 'GN',
        Gambia: 'GM',
        'Guinea Bissau': 'GW',
        'Equatorial Guinea': 'GQ',
        Greece: 'GR',
        Guatemala: 'GT',
        Guyana: 'GY',
        Honduras: 'HN',
        Croatia: 'HR',
        Haiti: 'HT',
        Hungary: 'HU',
        Indonesia: 'ID',
        India: 'IN',
        Ireland: 'IE',
        Iran: 'IR',
        Iraq: 'IQ',
        Iceland: 'IS',
        Israel: 'IL',
        Italy: 'IT',
        Jamaica: 'JM',
        Jordan: 'JO',
        Japan: 'JP',
        Kashmir: 'IN',
        Kazakhstan: 'KZ',
        Kenya: 'KE',
        Kyrgyzstan: 'KG',
        Cambodia: 'KH',
        'KOREA, REPUBLIC OF': 'KR',
        Kosovo: 'XK',
        Kuwait: 'KW',
        Laos: 'LA',
        Lebanon: 'LB',
        Liberia: 'LR',
        Libya: 'LY',
        'Sri Lanka': 'LK',
        Lesotho: 'LS',
        Lithuania: 'LT',
        Luxembourg: 'LU',
        Latvia: 'LV',
        Morocco: 'MA',
        Moldova: 'MD',
        Madagascar: 'MG',
        Mexico: 'MX',
        Macedonia: 'MK',
        Mali: 'ML',
        Myanmar: 'MM',
        Montenegro: 'ME',
        Mongolia: 'MN',
        Mozambique: 'MZ',
        Mauritania: 'MR',
        Malawi: 'MW',
        Malaysia: 'MY',
        Namibia: 'NA',
        Niger: 'NE',
        Nigeria: 'NG',
        Nicaragua: 'NI',
        Netherlands: 'NL',
        Norway: 'NO',
        Nepal: 'NP',
        'New Zealand': 'NZ',
        Oman: 'OM',
        Pakistan: 'PK',
        Panama: 'PA',
        Peru: 'PE',
        Philippines: 'PH',
        'Papua New Guinea': 'PG',
        Poland: 'PL',
        'United States of America': 'US',
        'North Korea': 'KP',
        Portugal: 'PT',
        Paraguay: 'PY',
        Qatar: 'QA',
        Romania: 'RO',
        Russia: 'RU',
        Rwanda: 'RW',
        'Western Sahara': 'EH',
        'Saudi Arabia': 'SA',
        Sudan: 'SD',
        'South Sudan': 'SS',
        Senegal: 'SN',
        'Solomon Islands': 'SB',
        'Sierra Leone': 'SL',
        'El Salvador': 'SV',
        Somaliland: 'SO',
        Somalia: 'SO',
        'Republic of Serbia': 'RS',
        Suriname: 'SR',
        Slovakia: 'SK',
        Slovenia: 'SI',
        Sweden: 'SE',
        Swaziland: 'SZ',
        Syria: 'SY',
        Chad: 'TD',
        Togo: 'TG',
        Thailand: 'TH',
        Tajikistan: 'TJ',
        Turkmenistan: 'TM',
        'East Timor': 'TL',
        'Trinidad and Tobago': 'TT',
        Tunisia: 'TN',
        Turkey: 'TR',
        'United Republic of Tanzania': 'TZ',
        Uganda: 'UG',
        Ukraine: 'UA',
        Uruguay: 'UY',
        Uzbekistan: 'UZ',
        Venezuela: 'VE',
        Vietnam: 'VN',
        Vanuatu: 'VU',
        Yemen: 'YE',
        'South Africa': 'ZA',
        Zambia: 'ZM',
        Zimbabwe: 'ZW',
        Unknown: 'XX',
    },
    SEGMENT_INTERVAL: {
        second: 0,
        minute: 1,
        hour: 2,
        day: 3,
        week: 4,
        month: 5,
        quarter: 6,
        semester: 8,
        year: 7,
    },
    URLS: {
        kantarLogo: 'https://kantar-web-frontend.s3-eu-west-1.amazonaws.com/assets/Onclusive-logo-small.png',
    },
};
