var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as TagsInput from 'react-tagsinput';
import { Wrapper } from './InputTags.styles';
var InputTags = function (props) {
    var inputProps = {
        className: 'c-input-tags__input',
        id: props.id,
        placeholder: '',
    };
    var tagProps = {
        className: 'c-input-tags__tag',
        classNameRemove: 'c-input-tags__remove',
    };
    if (props.placeholder) {
        inputProps.placeholder = props.placeholder;
    }
    return (_jsxs(Wrapper, { children: [_jsx("label", { htmlFor: props.id, className: "hidden", children: props.id }), _jsx(TagsInput, __assign({ addOnBlur: true, className: "c-input-tags", focusedClassName: "has-focus", inputProps: inputProps, tagProps: tagProps }, props))] }));
};
export default InputTags;
