var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TextStyled } from './Typography.styles';
var variantTags = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    subtitle1: 'h6',
    subtitle2: 'h6',
    subtitle3: 'h6',
    body1: 'p',
    body2: 'p',
    info1: 'span',
    info2: 'span',
    title1: 'p',
};
function Typography(_a) {
    var children = _a.children, margin = _a.margin, _b = _a.variant, variant = _b === void 0 ? 'body1' : _b, component = _a.component, _c = _a.color, color = _c === void 0 ? 'primary' : _c, lineClamp = _a.lineClamp, props = __rest(_a, ["children", "margin", "variant", "component", "color", "lineClamp"]);
    var tag = component || variantTags[variant];
    return (_jsx(TextStyled, __assign({ "$color": color, "$margin": margin, "$lineClamp": lineClamp, as: tag, "$variant": variant }, props, { children: children })));
}
export default Typography;
