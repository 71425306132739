var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var Text = styled('div')(function (_a) {
    var $maxWidth = _a.$maxWidth, $fontSize = _a.$fontSize, $isLast = _a.$isLast, $multiLine = _a.$multiLine, $margin = _a.$margin;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: ", ";\n    width: ", ";\n    min-width: 40px;\n    margin-bottom: ", ";\n    max-width: ", ";\n    ", ";\n  "], ["\n    height: ", ";\n    width: ", ";\n    min-width: 40px;\n    margin-bottom: ", ";\n    max-width: ", ";\n    ", ";\n  "])), "".concat($fontSize + 2, "px"), $isLast ? '50% ' : '100% ', $multiLine ? '5px' : 0, $maxWidth ? "".concat($maxWidth, "px") : 'unset', $margin ? "margin: ".concat($margin) : '');
});
export { Text };
var templateObject_1;
