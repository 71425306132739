var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable prefer-destructuring */
import ActionTypes from 'constants/actionTypes';
import BaseStore from 'stores/BaseStore';
var GET_FIELDS = 'getFields';
var QueryBuilderStore = /** @class */ (function (_super) {
    __extends(QueryBuilderStore, _super);
    function QueryBuilderStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    QueryBuilderStore.prototype.initializeState = function () {
        this.fields = [];
        this.percolatorsfields = [];
        this.fieldsValues = {};
        this.fieldsOperators = {};
        this.initializedFields = {};
    };
    QueryBuilderStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    QueryBuilderStore.prototype.getFields = function () {
        return this.fields;
    };
    QueryBuilderStore.prototype.getPercolatorFields = function () {
        return this.percolatorsfields;
    };
    QueryBuilderStore.prototype.setFields = function (fields) {
        var _this = this;
        var alphabetically = function (a, b) {
            var aa = a.translation.toLowerCase();
            var bb = b.translation.toLowerCase();
            if (aa < bb)
                return -1;
            if (aa > bb)
                return 1;
            return 0;
        };
        var newFields = fields.map(function (f) { return (__assign(__assign({}, f), { translation: f.filterSourceType === 'ES'
                ? window.Messages[f.bundle]
                : f.filterElasticFieldName })); });
        var customerMetricsIndex = newFields.findIndex(function (field) { return field.filterSourceType === 'CM'; });
        var customerMetrics = (newFields[customerMetricsIndex] && __spreadArray([], newFields.slice(customerMetricsIndex, newFields.length), true)) ||
            null;
        if (customerMetrics) {
            this.fields = __spreadArray(__spreadArray([], newFields.slice(0, customerMetricsIndex).sort(alphabetically), true), customerMetrics.sort(alphabetically), true);
        }
        else {
            this.fields = newFields.sort(alphabetically);
        }
        // this.fields = this.fields.filter(Boolean);
        this.fields.forEach(function (field) {
            _this.setFieldOperators(field.filterElasticFieldPath, field.standardOperators);
        });
        return this.fields;
    };
    QueryBuilderStore.prototype.setFieldValues = function (fieldId, values) {
        if (!fieldId || !values)
            return;
        this.initializedFields[fieldId] = true;
        this.fieldsValues[fieldId] = values.sort(function (a, b) {
            if (a.value < b.value)
                return -1;
            if (a.value > b.value)
                return 1;
            return 0;
        });
    };
    QueryBuilderStore.prototype.setFieldOperators = function (fieldId, operators) {
        if (!fieldId || !operators)
            return;
        this.initializedFields[fieldId] = true;
        this.fieldsOperators[fieldId] = operators;
    };
    QueryBuilderStore.prototype.getValuesByFieldId = function (fieldId) {
        var values = this.fieldsValues[fieldId];
        if (values && (Array.isArray(values) || Object.keys(values).length)) {
            return values;
        }
        return null;
    };
    QueryBuilderStore.prototype.getOperatorsByFieldName = function (fieldName) {
        if (!fieldName)
            return null;
        var fieldObject = this.fields.find(function (field) { return field.filterElasticFieldPath === fieldName; });
        if (!fieldObject)
            return null;
        var operators = this.fieldsOperators[fieldObject.filterElasticFieldPath];
        if (operators &&
            (Array.isArray(operators) || Object.keys(operators).length)) {
            return operators;
        }
        return null;
    };
    QueryBuilderStore.prototype.getFieldByName = function (Name) {
        var fieldObject = this.fields.filter(function (field) { return field.filterElasticFieldPath === Name; });
        if (fieldObject && Array.isArray(fieldObject) && fieldObject[0]) {
            return fieldObject[0];
        }
        return null;
    };
    QueryBuilderStore.prototype.resetFieldValues = function () {
        this.fieldsValues = [];
    };
    QueryBuilderStore.prototype.emitGetFieldsCallback = function (settings) {
        this.emit(GET_FIELDS, settings);
    };
    QueryBuilderStore.prototype.addGetFieldsListener = function (callback) {
        this.on(GET_FIELDS, callback);
    };
    QueryBuilderStore.prototype.removeGetFieldsListener = function (callback) {
        this.removeListener(GET_FIELDS, callback);
    };
    QueryBuilderStore.prototype.isFieldInitialized = function (fieldId) {
        return this.initializedFields[fieldId];
    };
    QueryBuilderStore.prototype.registerToActions = function (payload) {
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.UPDATE_FILTER_RESPONSE:
                if (action.json &&
                    action.json.data &&
                    Array.isArray(action.json.data.allFilters) &&
                    action.json.data.allFilters.length > 0) {
                    var allFilters = action.json.data.allFilters;
                    var queryBuilderFields = allFilters.filter(function (af) { return af.isQueryBuilder; });
                    this.fields = this.setFields(queryBuilderFields);
                    this.emitGetFieldsCallback();
                    this.initialized = true;
                    this.emitChange();
                }
                break;
            case ActionTypes.GET_ALL_FILTERS_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    var queryBuilderFields = data.filter(function (af) { return af.forPercolator; });
                    this.percolatorsfields = this.setFields(queryBuilderFields);
                    this.emitGetFieldsCallback();
                    this.initialized = true;
                    this.emitChange();
                }
                break;
            case ActionTypes.GET_QUERYBUILDER_FIELD_VALUES_RESPONSE:
                if (action.json) {
                    var _a = action.json, selectedFilter = _a.model.selectedFilter, data = _a.data;
                    var idOfField = selectedFilter.filterElasticFieldPath;
                    // if (idOfField && idOfField.indexOf('customerMetrics.') > -1) {
                    //   idOfField = idOfField.split('.')[1];
                    // }
                    this.setFieldValues(idOfField, data);
                    this.emitChange('GET_QUERYBUILDER_FIELD_VALUES', selectedFilter);
                }
                else {
                    this.emitError('GET_QUERYBUILDER_FIELD_VALUES');
                }
                break;
            default:
                break;
        }
    };
    return QueryBuilderStore;
}(BaseStore));
export default new QueryBuilderStore();
