import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FormattedDate } from 'react-intl';
import Typography from '@commons/Typography';
import Checkbox from '@commons/Checkbox';
import Svg from '@commons/Svg';
import { Wrapper } from './ReviewItem.styles';
function ReviewItem(_a) {
    var id = _a.id, name = _a.name, totalClips = _a.totalClips, createdDate = _a.createdDate, _b = _a.withCheckbox, withCheckbox = _b === void 0 ? false : _b, author = _a.author, _c = _a.checked, checked = _c === void 0 ? false : _c, onChange = _a.onChange;
    return (_jsxs(Wrapper, { id: id, "$checked": checked, onClick: onChange, children: [withCheckbox && (_jsx(Checkbox, { onChange: onChange, checked: checked, id: id, name: "checkbox-article" })), _jsx(Typography, { variant: "h5", className: "review__title", children: name }), _jsx("span", { className: "review__info", children: author }), _jsxs("div", { className: "review__date review__info", children: [_jsx(Svg, { className: "m-right--x-small m-left--x-small", icon: "publication_date", size: "x-small" }), _jsx(FormattedDate, { day: "2-digit", month: "2-digit", value: createdDate, year: "numeric" }), ', ', _jsx(FormattedDate, { hour: "numeric", minute: "numeric", value: createdDate })] }), _jsxs("div", { className: "review__info review__articles", children: [_jsx(Svg, { icon: "kiosk", size: "small" }), "\u00A0", totalClips] })] }));
}
export default ReviewItem;
