export function getPieLabelContent(formatMessage, widgetFamilyName) {
    /* if (widgetFamilyName === 'Circle') {
      return [
        {
          value: 'none',
          label: formatMessage({
            id: 'Insight.widget.settings.layout.none',
          }),
        },
        {
          value: 'value',
          label: formatMessage({
            id: 'Insight.widget.settings.layout.value',
          }),
        },
      ];
    } */
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
        {
            value: 'percentage',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.percentage',
            }),
        },
        {
            value: 'valuePercentage',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.valuePercentage',
            }),
        },
    ];
}
export function getPieLabelPosition(formatMessage) {
    return [
        {
            value: 'inside',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.inside',
            }),
        },
        {
            value: 'right',
            label: formatMessage({
                id: 'Global.right',
            }),
        },
    ];
}
export function getPieLabelTooltip(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getPieLegendContent(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getPieLegendPosition(formatMessage) {
    return [
        {
            value: 'top',
            label: formatMessage({
                id: 'Global.top',
            }),
        },
        {
            value: 'bottom',
            label: formatMessage({
                id: 'Global.bottom',
            }),
        },
        {
            value: 'right',
            label: formatMessage({
                id: 'Global.right',
            }),
        },
    ];
}
export function getDefaultPieWidgetSettings(props) {
    var widgetOptionsDictionary = props.widget.widgetOptionsDictionary;
    return {
        labelContent: widgetOptionsDictionary && widgetOptionsDictionary.labelContent
            ? widgetOptionsDictionary.labelContent
            : 'none',
        labelPosition: widgetOptionsDictionary && widgetOptionsDictionary.labelPosition
            ? widgetOptionsDictionary.labelPosition
            : 'inside',
        labelTooltip: widgetOptionsDictionary && widgetOptionsDictionary.labelTooltip
            ? widgetOptionsDictionary.labelTooltip
            : 'value',
        legendContent: widgetOptionsDictionary && widgetOptionsDictionary.legendContent
            ? widgetOptionsDictionary.legendContent
            : 'value',
        legendPosition: widgetOptionsDictionary && widgetOptionsDictionary.legendPosition
            ? widgetOptionsDictionary.legendPosition
            : 'bottom',
    };
}
