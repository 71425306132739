var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Dispatcher from '@services/AppDispatcher';
import { EventEmitter } from 'events';
var CHANGE_EVENT = 'change';
var ERROR_EVENT = 'errors';
var IS_LOADING = 'loading';
var BaseStore = /** @class */ (function (_super) {
    __extends(BaseStore, _super);
    function BaseStore() {
        var _this = _super.call(this) || this;
        _this.dispatcher = Dispatcher;
        _this.initialized = false;
        _this.loading = false;
        return _this;
    }
    /**
     * Subscribe to the Dispatcher.
     */
    BaseStore.prototype.subscribe = function (actionSubscribe) {
        this.dispatchToken = Dispatcher.register(actionSubscribe());
    };
    /**
     * Get the state of the Store.
     */
    BaseStore.prototype.isInit = function () {
        return this.initialized;
    };
    /**
     * Reset the Store.
     */
    BaseStore.prototype.reset = function () {
        this.initialized = false;
    };
    /**
     * @param {*} [event].
     */
    BaseStore.prototype.emitChange = function (event, params) {
        this.emit(CHANGE_EVENT, event, params);
    };
    BaseStore.prototype.addChangeListener = function (callback) {
        this.on(CHANGE_EVENT, callback);
    };
    BaseStore.prototype.removeChangeListener = function (callback) {
        this.removeListener(CHANGE_EVENT, callback);
    };
    BaseStore.prototype.emitError = function (type, message) {
        this.emit(ERROR_EVENT, type, message);
    };
    BaseStore.prototype.addErrorListener = function (callback) {
        this.on(ERROR_EVENT, callback);
    };
    BaseStore.prototype.removeErrorListener = function (callback) {
        this.removeListener(ERROR_EVENT, callback);
    };
    BaseStore.prototype.emitLoading = function (loading) {
        this.emit(IS_LOADING, loading);
    };
    BaseStore.prototype.addLoadingListener = function (callback) {
        this.on(IS_LOADING, callback);
    };
    BaseStore.prototype.removeLoadingListener = function (callback) {
        this.removeListener(IS_LOADING, callback);
    };
    return BaseStore;
}(EventEmitter));
export default BaseStore;
