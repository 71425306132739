var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function getVerticalBarLabelContent(formatMessage, widgetFamilyName) {
    if (widgetFamilyName === 'Stacked' || widgetFamilyName === 'Stacked (%)') {
        return [
            {
                value: 'none',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.none',
                }),
            },
            {
                value: 'value',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.value',
                }),
            },
            {
                value: 'percentage',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.percentage',
                }),
            },
            {
                value: 'valuePercentage',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.valuePercentage',
                }),
            },
        ];
    }
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getVerticalBarLabelPosition(formatMessage, widgetFamilyName) {
    if (widgetFamilyName === 'Stacked' || widgetFamilyName === 'Stacked (%)') {
        return [
            {
                value: 'inside',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.inside',
                }),
            },
        ];
    }
    return [
        {
            value: 'inside',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.inside',
            }),
        },
        {
            value: 'outside',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.outside',
            }),
        },
    ];
}
export function getVerticalBarLabelTooltip(formatMessage, widgetFamilyName) {
    if (widgetFamilyName === 'Stacked' || widgetFamilyName === 'Stacked (%)') {
        return [
            {
                value: 'none',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.none',
                }),
            },
            {
                value: 'value',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.value',
                }),
            },
            {
                value: 'percentage',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.percentage',
                }),
            },
            {
                value: 'valuePercentage',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.valuePercentage',
                }),
            },
        ];
    }
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getVerticalBarLegendContent(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getVerticalBarLegendPosition(formatMessage) {
    return [
        {
            value: 'top',
            label: formatMessage({
                id: 'Global.top',
            }),
        },
        {
            value: 'bottom',
            label: formatMessage({
                id: 'Global.bottom',
            }),
        },
        {
            value: 'right',
            label: formatMessage({
                id: 'Global.right',
            }),
        },
    ];
}
export function getVerticalBarDisplayedAxis(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'xAxis',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.xAxis',
            }),
        },
        {
            value: 'yAxis',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.yAxis',
            }),
        },
        {
            value: 'all',
            label: formatMessage({
                id: 'Global.All',
            }),
        },
    ];
}
export function getVerticalBarXAxisOrientation(formatMessage) {
    return [
        {
            value: 'horizontal',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.horizontal',
            }),
        },
        {
            value: 'leftRight',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.leftRight',
            }),
        },
        {
            value: 'vertical',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.vertical',
            }),
        },
    ];
}
export function getDefaultVerticalBarWidgetSettings(props) {
    var _a = props.widget, widgetOptionsDictionary = _a.widgetOptionsDictionary, widgetFamilyName = _a.widgetFamilyName;
    var getLabelPositionDefaultSetting = function () {
        if (widgetFamilyName === 'Stacked (%)' || widgetFamilyName === 'Stacked')
            return null;
        return {
            labelPosition: widgetOptionsDictionary && widgetOptionsDictionary.labelPosition
                ? widgetOptionsDictionary.labelPosition
                : 'inside',
        };
    };
    return __assign(__assign({ labelContent: widgetOptionsDictionary && widgetOptionsDictionary.labelContent
            ? widgetOptionsDictionary.labelContent
            : 'none' }, getLabelPositionDefaultSetting()), { labelTooltip: widgetOptionsDictionary && widgetOptionsDictionary.labelTooltip
            ? widgetOptionsDictionary.labelTooltip
            : 'value', legendContent: widgetOptionsDictionary && widgetOptionsDictionary.legendContent
            ? widgetOptionsDictionary.legendContent
            : 'value', legendPosition: widgetOptionsDictionary && widgetOptionsDictionary.legendPosition
            ? widgetOptionsDictionary.legendPosition
            : 'bottom', displayedAxis: widgetOptionsDictionary && widgetOptionsDictionary.displayedAxis
            ? widgetOptionsDictionary.displayedAxis
            : 'all', xAxisOrientation: widgetOptionsDictionary && widgetOptionsDictionary.xAxisOrientation
            ? widgetOptionsDictionary.xAxisOrientation
            : 'leftRight' });
}
