import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
var propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    resetErrorBoundary: PropTypes.func,
};
var defaultProps = {
    error: null,
    resetErrorBoundary: null,
};
var ErrorFallback = function (_a) {
    var error = _a.error, resetErrorBoundary = _a.resetErrorBoundary;
    var navigate = useNavigate();
    if (process.env.NODE_ENV !== 'hotReload') {
        resetErrorBoundary();
        navigate('/unexpected');
        return null;
    }
    return (_jsxs("div", { role: "alert", style: {
            position: 'fixed',
            inset: 0,
            zIndex: 9999999999,
            background: 'rgba(255, 255, 255, 0.6)',
            padding: 40,
        }, children: [_jsx("p", { children: "Something went wrong:" }), _jsx("pre", { style: { color: 'red' }, children: error.message })] }));
};
ErrorFallback.defaultProps = defaultProps;
ErrorFallback.propTypes = propTypes;
export default ErrorFallback;
