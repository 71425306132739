var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ActionTypes from 'constants/actionTypes';
import BaseStore from 'stores/BaseStore';
var GET_DASHBOARDS = 'getDashboards';
var HIDE_DASHBOARD_LOADER = 'hideDashboardLoader';
var GET_TABS = 'getTabs';
var HIDE_RENAME_DASHBOARD_LOADER = 'renameDashboard';
var ADD_TAB = 'addTab';
var COPY_TAB = 'copyTab';
var REMOVE_DASHBOARD = 'removeDashboard';
var ADD_DASHBOARD = 'addDashboard';
var DELETE_TAB = 'deleteTab';
var DUPLICATE_TAB = 'duplicateTab';
var UPDATE_TAB = 'updateTab';
var DOWNLOAD_TAB_IMAGE = 'downloadTabImage';
var DOWNLOAD_TAB_POWERPOINT = 'downloadTabPowerpoint';
var RENAME_TAB = 'renameTab';
var SORT_TABS = 'sortTabs';
var CHANGE_DASHBOARD = 'changeDashboard';
var GENERATE_DUPLICATE_DASHBOARD_NAME = 'generateDuplicateDashboardName';
var GENERATE_DUPLICATE_TABULATION_NAME = 'generateDuplicateTabulationName';
var GET_HASH_DASHBOARD = 'getHashDashboard';
var DASHBOARD_SNAPSHOT_EXPORT = 'dashboarsSnapshotExport';
var GET_DASHBOARD_EVENT = 'getDashboardEvent';
var GET_DASHBOARD_INFO = 'GET_DASHBOARD_INFO';
var DashboardStore = /** @class */ (function (_super) {
    __extends(DashboardStore, _super);
    function DashboardStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    DashboardStore.prototype.initializeState = function () {
        this.dashboards = [];
        this.dashboardTabs = [];
        this.scrollLeft = 0;
        this.currentTabPosition = 0;
        this.defaulTabulation = 0;
    };
    DashboardStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    DashboardStore.prototype.getDefaultTabIndex = function () {
        return this.defaulTabulation;
    };
    DashboardStore.prototype.setDefaultTabIndex = function (value) {
        this.defaulTabulation = value;
    };
    DashboardStore.prototype.setCurrentTabPosition = function (value) {
        this.currentTabPosition = value;
    };
    DashboardStore.prototype.getCurrentTabPosition = function () {
        return this.currentTabPosition;
    };
    DashboardStore.prototype.setScrollLeft = function (value) {
        this.scrollLeft = value;
    };
    DashboardStore.prototype.getScrollLeft = function () {
        return this.scrollLeft;
    };
    DashboardStore.prototype.getDashboards = function () {
        return this.dashboards;
    };
    DashboardStore.prototype.setDashboards = function (dashboards) {
        this.dashboards = dashboards || [];
    };
    DashboardStore.prototype.getDashboardTabs = function () {
        return this.dashboardTabs;
    };
    DashboardStore.prototype.setDashboardTabs = function (tabs) {
        this.dashboardTabs = tabs || [];
    };
    DashboardStore.prototype.updateTab = function (tab) {
        var resultTab = this.dashboardTabs.find(function (item) { return item.tabulationId === tab.tabulationId; });
        for (var property in tab) {
            if (resultTab.hasOwnProperty(property)) {
                resultTab[property] = tab[property];
            }
        }
    };
    DashboardStore.prototype.setDashboardName = function (id, name) {
        var dashboard = this.getDashboardById(id);
        if (!dashboard ||
            (dashboard.dashboardName && typeof dashboard.dashboardName !== 'string'))
            return;
        dashboard.dashboardName = typeof name === 'string' ? name : '';
    };
    DashboardStore.prototype.getDashboardById = function (id) {
        return this.dashboards.find(function (dashboard) { return dashboard.dashboardId === id; });
    };
    DashboardStore.prototype.getDashboardByName = function (name) {
        if (!this.dashboards)
            return false;
        return this.dashboards.find(function (dashboard) { return dashboard.dashboardName.toLowerCase() === name.toLowerCase(); });
    };
    DashboardStore.prototype.getTabById = function (id) {
        return this.dashboardTabs.find(function (tabulation) { return tabulation.tabulationId === id; });
    };
    DashboardStore.prototype.getTabByName = function (name) {
        if (!this.dashboardTabs)
            return false;
        return this.dashboardTabs.filter(function (tab) { return tab.tabulationName.toLowerCase() === name.toLowerCase(); });
    };
    DashboardStore.prototype.tabNameAlreadyExists = function (name) {
        return this.getTabByName(name).length > 0;
    };
    DashboardStore.prototype.removeDashboard = function (id) {
        this.dashboards = this.dashboards.filter(function (dashboard) { return dashboard.dashboardId !== id; });
    };
    DashboardStore.prototype.addTab = function (id, tab) {
        var item = {
            order: tab.order,
            tabulationName: tab.tabulationName,
            tabulationId: id,
        };
        this.dashboardTabs.push(item);
    };
    DashboardStore.prototype.addDashboard = function (id, name) {
        var item = {
            dashboardName: name,
            dashboardId: id,
        };
        this.dashboards.push(item);
    };
    DashboardStore.prototype.deleteTab = function (id) {
        this.dashboardTabs = this.dashboardTabs.filter(function (item) { return item.tabulationId !== id; });
    };
    DashboardStore.prototype.setDefaultDashboard = function (id) {
        var _this = this;
        this.dashboardTabs.forEach(function (item, index) {
            if (item.dashboardId !== id) {
                _this.dashboardTabs[index].isDefault = true;
            }
            else {
                _this.dashboardTabs[index].isDefault = false;
            }
        });
    };
    DashboardStore.prototype.setTabulationOrder = function (tabs) {
        var _this = this;
        tabs.forEach(function (tab) {
            _this.dashboardTabs.find(function (item) { return item.tabulationId === tab.tabulationId; }).order = tab.order;
        });
        this.dashboardTabs.sort(function (a, b) { return (a.order > b.order ? 1 : -1); });
    };
    DashboardStore.prototype.emitGetDashboards = function () {
        this.emit(GET_DASHBOARDS);
    };
    DashboardStore.prototype.addGetDashboardsListener = function (callback) {
        this.on(GET_DASHBOARDS, callback);
    };
    DashboardStore.prototype.removeGetDashboardsListener = function (callback) {
        this.removeListener(GET_DASHBOARDS, callback);
    };
    DashboardStore.prototype.emitAddDashboardCallback = function (id) {
        this.emit(ADD_DASHBOARD, id);
    };
    DashboardStore.prototype.addAddDashboardListener = function (callback) {
        this.on(ADD_DASHBOARD, callback);
    };
    DashboardStore.prototype.removeAddDashboardListener = function (callback) {
        this.removeListener(ADD_DASHBOARD, callback);
    };
    DashboardStore.prototype.emitDeleteTabCallback = function (tabId) {
        this.emit(DELETE_TAB, tabId);
    };
    DashboardStore.prototype.addDeleteTabListener = function (callback) {
        this.on(DELETE_TAB, callback);
    };
    DashboardStore.prototype.removeDeleteTabListener = function (callback) {
        this.removeListener(DELETE_TAB, callback);
    };
    DashboardStore.prototype.emitDuplicateDashboardCallback = function (id) {
        this.emit(DUPLICATE_TAB, id);
    };
    DashboardStore.prototype.addDuplicateDashboardListener = function (callback) {
        this.on(DUPLICATE_TAB, callback);
    };
    DashboardStore.prototype.removeDuplicateDashboardListener = function (callback) {
        this.removeListener(DUPLICATE_TAB, callback);
    };
    DashboardStore.prototype.emitGenerateDuplicateDashboardNameCallback = function (newName) {
        this.emit(GENERATE_DUPLICATE_DASHBOARD_NAME, newName);
    };
    DashboardStore.prototype.addGenerateDuplicateDashboardNameListener = function (callback) {
        this.on(GENERATE_DUPLICATE_DASHBOARD_NAME, callback);
    };
    DashboardStore.prototype.removeGenerateDuplicateDashboardNameListener = function (callback) {
        this.removeListener(GENERATE_DUPLICATE_DASHBOARD_NAME, callback);
    };
    DashboardStore.prototype.emitGenerateDuplicateTabulationNameCallback = function (newName) {
        this.emit(GENERATE_DUPLICATE_TABULATION_NAME, newName);
    };
    DashboardStore.prototype.addGenerateDuplicateTabulationNameListener = function (callback) {
        this.on(GENERATE_DUPLICATE_TABULATION_NAME, callback);
    };
    DashboardStore.prototype.removeGenerateDuplicateTabulationNameListener = function (callback) {
        this.removeListener(GENERATE_DUPLICATE_TABULATION_NAME, callback);
    };
    DashboardStore.prototype.emitRenameTabCallback = function () {
        this.emit(RENAME_TAB);
    };
    DashboardStore.prototype.addRenameTabListener = function (callback) {
        this.on(RENAME_TAB, callback);
    };
    DashboardStore.prototype.removeRenameTabListener = function (callback) {
        this.removeListener(RENAME_TAB, callback);
    };
    DashboardStore.prototype.emitUpdateTabCallback = function () {
        this.emit(UPDATE_TAB);
    };
    DashboardStore.prototype.addUpdateTabListener = function (callback) {
        this.on(UPDATE_TAB, callback);
    };
    DashboardStore.prototype.removeUpdateTabListener = function (callback) {
        this.removeListener(UPDATE_TAB, callback);
    };
    // download tab IMAGE
    DashboardStore.prototype.emitDownloadTabImageCallback = function () {
        this.emit(DOWNLOAD_TAB_IMAGE);
    };
    DashboardStore.prototype.addDownloadTabImageCallback = function (callback) {
        this.on(DOWNLOAD_TAB_IMAGE, callback);
    };
    DashboardStore.prototype.removeDownloadTabImageCallback = function (callback) {
        this.removeListener(DOWNLOAD_TAB_IMAGE, callback);
    };
    // get Hash Dashboars
    DashboardStore.prototype.emitGetHashDashboard = function (model) {
        this.emit(GET_HASH_DASHBOARD, model);
    };
    DashboardStore.prototype.addGetHashDashboardCallback = function (callback) {
        this.on(GET_HASH_DASHBOARD, callback);
    };
    DashboardStore.prototype.removeGetHashDashboardCallback = function (callback) {
        this.removeListener(GET_HASH_DASHBOARD, callback);
    };
    DashboardStore.prototype.emitHideDashboardLoader = function () {
        this.emit(HIDE_DASHBOARD_LOADER);
    };
    DashboardStore.prototype.addHideDashboardLoaderListener = function (callback) {
        this.on(HIDE_DASHBOARD_LOADER, callback);
    };
    DashboardStore.prototype.removeHideDashboardLoaderListener = function (callback) {
        this.removeListener(HIDE_DASHBOARD_LOADER, callback);
    };
    DashboardStore.prototype.emitChangeDashboardCallback = function () {
        this.emit(CHANGE_DASHBOARD);
    };
    DashboardStore.prototype.addChangeDashboardListener = function (callback) {
        this.on(CHANGE_DASHBOARD, callback);
    };
    DashboardStore.prototype.removeChangeDashboardListener = function (callback) {
        this.removeListener(CHANGE_DASHBOARD, callback);
    };
    DashboardStore.prototype.emitOnGetDashboardTabulationsCallback = function () {
        this.emit(GET_TABS);
    };
    DashboardStore.prototype.addOnGetDashboardTabulationsListener = function (callback) {
        this.on(GET_TABS, callback);
    };
    DashboardStore.prototype.removeOnGetDashboardTabulationsListener = function (callback) {
        this.removeListener(GET_TABS, callback);
    };
    DashboardStore.prototype.emitRemoveDashboard = function () {
        this.emit(REMOVE_DASHBOARD);
    };
    DashboardStore.prototype.addRemoveDashboardListener = function (callback) {
        this.on(REMOVE_DASHBOARD, callback);
    };
    DashboardStore.prototype.removeRemoveDashboardListener = function (callback) {
        this.removeListener(REMOVE_DASHBOARD, callback);
    };
    DashboardStore.prototype.emitHideModalRenameLoader = function () {
        this.emit(HIDE_RENAME_DASHBOARD_LOADER);
    };
    DashboardStore.prototype.addHideModalRenameListener = function (callback) {
        this.on(HIDE_RENAME_DASHBOARD_LOADER, callback);
    };
    DashboardStore.prototype.removeHideModalRenameListener = function (callback) {
        this.removeListener(HIDE_RENAME_DASHBOARD_LOADER, callback);
    };
    DashboardStore.prototype.emitAddTabCallBack = function (id) {
        this.emit(ADD_TAB, id);
    };
    DashboardStore.prototype.addAddTabListener = function (callback) {
        this.on(ADD_TAB, callback);
    };
    DashboardStore.prototype.removeAddTabListener = function (callback) {
        this.removeListener(ADD_TAB, callback);
    };
    DashboardStore.prototype.emitCopyTabCallback = function (id) {
        this.emit(COPY_TAB, id);
    };
    DashboardStore.prototype.addCopyTabListener = function (callback) {
        this.on(COPY_TAB, callback);
    };
    DashboardStore.prototype.removeCopyTabListener = function (callback) {
        this.removeListener(COPY_TAB, callback);
    };
    DashboardStore.prototype.emitSortTabsCallback = function () {
        this.emit(SORT_TABS);
    };
    DashboardStore.prototype.addSortTabsListener = function (callback) {
        this.on(SORT_TABS, callback);
    };
    DashboardStore.prototype.removeSortTabsListener = function (callback) {
        this.removeListener(SORT_TABS, callback);
    };
    DashboardStore.prototype.emitDashboardSnapshotExport = function (ticket) {
        this.emit(DASHBOARD_SNAPSHOT_EXPORT, ticket);
    };
    DashboardStore.prototype.addDashboardSnapshotExportListener = function (callback) {
        this.on(DASHBOARD_SNAPSHOT_EXPORT, callback);
    };
    DashboardStore.prototype.removeDashboardSnapshotExportListener = function (callback) {
        this.removeListener(DASHBOARD_SNAPSHOT_EXPORT, callback);
    };
    DashboardStore.prototype.emitDashboardSnapshot = function (dashboard) {
        this.emit(GET_DASHBOARD_EVENT, dashboard);
    };
    DashboardStore.prototype.addDashboardSnapshotListener = function (callback) {
        this.on(GET_DASHBOARD_EVENT, callback);
    };
    DashboardStore.prototype.removeDashboardSnapshotListener = function (callback) {
        this.removeListener(GET_DASHBOARD_EVENT, callback);
    };
    DashboardStore.prototype.emitDashboardInfo = function (dashboard) {
        this.emit(GET_DASHBOARD_INFO, dashboard);
    };
    DashboardStore.prototype.addDashboardInfoListener = function (callback) {
        this.on(GET_DASHBOARD_INFO, callback);
    };
    DashboardStore.prototype.removeDashboardInfoListener = function (callback) {
        this.removeListener(GET_DASHBOARD_INFO, callback);
    };
    DashboardStore.prototype.registerToActions = function (payload) {
        var _this = this;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_DASHBOARDS_RESPONSE:
                if (action.json && action.json.data) {
                    this.initialized = true;
                    this.setDashboards(action.json.data);
                    this.emitGetDashboards();
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.GET_DASHBOARD_TABS_RESPONSE:
                if (action.json && action.json.data) {
                    this.setDashboardTabs(action.json.data.tabulationsModel);
                    this.emitChangeDashboardCallback();
                    this.emitOnGetDashboardTabulationsCallback();
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.UPDATE_DASHBOARD_NAME_RESPONSE:
                if (action.json) {
                    this.setDashboardName(action.json.model.dashboardId, action.json.model.dashboardName);
                    this.emitHideModalRenameLoader();
                }
                else {
                    this.emitError(action.actionType, action.errors);
                }
                break;
            case ActionTypes.REMOVE_DASHBOARD_RESPONSE:
                if (action.json) {
                    this.removeDashboard(action.json.model.dashboardId);
                    this.emitRemoveDashboard();
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.ADD_TAB_RESPONSE:
                if (action.json && action.json.data) {
                    this.addTab(action.json.data, action.json.model);
                    this.emitAddTabCallBack(action.json.data);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.ADD_DASHBOARD_RESPONSE:
                if (action.json && action.json.data) {
                    this.addDashboard(action.json.data, action.json.model.DashboardName);
                    this.emitAddDashboardCallback(action.json.data);
                }
                else {
                    this.emitError(action.actionType, action.errors);
                }
                break;
            case ActionTypes.UPDATE_TAB_RESPONSE:
                if (action.json) {
                    this.updateTab(action.json.model);
                    this.emitUpdateTabCallback();
                }
                else {
                    this.emitError(action.actionType, action.errors);
                }
                break;
            case ActionTypes.RENAME_TABULATION_RESPONSE:
                if (action.json) {
                    this.updateTab(action.json.model);
                    this.emitRenameTabCallback();
                }
                else {
                    this.emitError(action.actionType, action.errors);
                }
                break;
            case ActionTypes.DUPLICATE_DASHBOARD_RESPONSE:
                if (action.json && action.json.data && !action.json.message) {
                    this.addDashboard(action.json.data, action.json.model.DashboardName);
                    this.emitDuplicateDashboardCallback(action.json.data);
                }
                else {
                    this.emitError(action.actionType, action.errors);
                }
                break;
            case ActionTypes.GENERATE_DUPLICATE_DASHBOARD_NAME_RESPONSE:
                if (action.json && action.json.data) {
                    this.emitGenerateDuplicateDashboardNameCallback(action.json.data);
                }
                else {
                    this.emitError(action.actionType, action.errors);
                }
                break;
            case ActionTypes.GENERATE_DUPLICATE_TABULATION_NAME_RESPONSE:
                if (action.json && action.json.data) {
                    this.emitGenerateDuplicateTabulationNameCallback(action.json.data);
                }
                else {
                    this.emitError(action.actionType, action.errors);
                }
                break;
            case ActionTypes.DELETE_TAB_RESPONSE:
                if (action.json) {
                    var defaulTabulation_1 = 0;
                    this.dashboardTabs.forEach(function (item, index) {
                        if (item.tabulationId === action.json.model.tabulationId) {
                            if (_this.dashboardTabs.length === 1) {
                                defaulTabulation_1 = 0;
                            }
                            else if (index === _this.dashboardTabs.length - 1) {
                                defaulTabulation_1 = index - 1;
                            }
                            else {
                                defaulTabulation_1 = index;
                            }
                        }
                    });
                    this.setDefaultTabIndex(defaulTabulation_1);
                    this.deleteTab(action.json.model.tabulationId);
                    this.emitDeleteTabCallback(action.json.model.tabulationId);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.GET_TABULATION_WIDGETS_RESPONSE:
                this.emitHideDashboardLoader();
                break;
            case ActionTypes.SET_DEFAULT_DASHBOARD_RESPONSE:
                if (action.json) {
                    this.setDefaultDashboard(action.json.model.dashboardId);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.SET_TABULATION_ORDER_RESPONSE:
                if (action.json) {
                    this.setTabulationOrder(action.json.model);
                    this.emitSortTabsCallback();
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.COPY_TABULATION_RESPONSE:
                if (action.json && action.json.data) {
                    this.addTab(action.json.data, action.json.model);
                    this.emitCopyTabCallback(action.json.data);
                }
                else {
                    this.emitError(action.actionType, action.errors);
                }
                break;
            case ActionTypes.DOWNLOAD_TAB_IMAGE_RESPONSE:
                if (action) {
                    this.emitDownloadTabImageCallback(action);
                }
                else {
                    this.emitError(action.actionType, action.errors);
                }
                break;
            case ActionTypes.GET_HASH_DASHBOARD_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.emitGetHashDashboard({
                        hash: data,
                        scope: action.json.model.scope,
                    });
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.GET_PDF_SNAPSHOT_RESPONSE:
                if (action.json) {
                    var Ticket = action.json.data.Ticket;
                    this.emitChange({
                        ticket: Ticket,
                        action: ActionTypes.GET_PDF_SNAPSHOT_RESPONSE,
                    });
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.GET_DASHBOARD_SNAPSHOT_RESPONSE:
                if (action.json) {
                    var ticket = action.json.data.ticket;
                    this.emitDashboardSnapshotExport(ticket);
                }
                else {
                    this.emitError('GET_DASHBOARD_SNAPSHOT_ERROR');
                }
                break;
            case ActionTypes.GET_DASHBOARD_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    var snapShotDashboard = data.snapShotDashboard;
                    this.emitDashboardSnapshot(snapShotDashboard);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.GET_DASHBOARD_INFO_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    var snapShotDashboard = data.snapShotDashboard;
                    this.emitDashboardSnapshot(snapShotDashboard);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            default:
                break;
        }
    };
    return DashboardStore;
}(BaseStore));
export default new DashboardStore();
