import { jsx as _jsx } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { v4 as uniqueId } from 'uuid';
import Button from 'commons/Button';
import RadioButton from 'commons/RadioButton';
var propTypes = {
    combinator: PropTypes.shape({
        ops: PropTypes.string.isRequired,
        not: PropTypes.bool.isRequired,
    }).isRequired,
    combinators: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        handleClick: PropTypes.func,
        disabled: PropTypes.bool,
        name: PropTypes.string,
        buttonClassNames: PropTypes.string,
        title: PropTypes.string,
        checked: PropTypes.string,
        children: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            handleClick: PropTypes.func,
            disabled: PropTypes.bool,
            name: PropTypes.string,
            buttonClassNames: PropTypes.string,
            title: PropTypes.string,
            checked: PropTypes.string,
        })),
    })),
};
var defaultProps = {
    combinators: [
        {
            checked: 'checked',
        },
    ],
};
function CombinatorsGroup(_a) {
    var combinators = _a.combinators, combinator = _a.combinator;
    var onClick = function (event) {
        var control = combinators.filter(function (item) { return item.name === event.target.getAttribute('name'); })[0];
        if (control) {
            var value = {
                ops: combinator.ops,
                not: !combinator.not,
            };
            control.handleClick(value);
        }
    };
    return (_jsx(Fragment, { children: combinators.map(function (control) {
            switch (control.type) {
                case 'button':
                    return (_jsx(Button, { type: "button", variant: combinator && combinator.not ? 'fill' : 'outline', size: "small", color: "primary", disabled: control.disabled, onClick: onClick, name: control.name, children: control.title }, uniqueId()));
                default:
                    return (_jsx(RadioButton, { combinators: control.children, combinator: combinator || {} }, uniqueId()));
            }
        }) }));
}
CombinatorsGroup.defaultProps = defaultProps;
CombinatorsGroup.propTypes = propTypes;
export default CombinatorsGroup;
