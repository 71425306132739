var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { v4 as uniqueId } from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import RuleGroup from './RuleGroup';
import { Wrapper } from './QueryBuilder.styles';
import { ActionElement, ValueEditor, ValueSelector } from './controls/index';
var QueryBuilder = /** @class */ (function (_super) {
    __extends(QueryBuilder, _super);
    function QueryBuilder() {
        var props = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            props[_i] = arguments[_i];
        }
        var _this = _super.apply(this, props) || this;
        var fields = props.fields, operators = props.operators, controlElements = props.controlElements, controlClassnames = props.controlClassnames;
        var classNames = Object.assign({}, QueryBuilder.defaultControlClassnames, controlClassnames);
        var controls = Object.assign({}, QueryBuilder.defaultControlElements, controlElements);
        _this.state = {
            root: _this.getInitialQuery(),
            schema: {
                fields: fields,
                operators: operators,
                classNames: classNames,
                createRule: _this.createRule.bind(_this),
                createRuleGroup: _this.createRuleGroup.bind(_this),
                onRuleAdd: _this.notifyQueryChange.bind(_this, _this.onRuleAdd),
                onGroupAdd: _this.notifyQueryChange.bind(_this, _this.onGroupAdd),
                onRuleRemove: _this.notifyQueryChange.bind(_this, _this.onRuleRemove),
                onGroupRemove: _this.notifyQueryChange.bind(_this, _this.onGroupRemove),
                onPropChange: _this.notifyQueryChange.bind(_this, _this.onPropChange),
                getLevel: _this.getLevel.bind(_this),
                isRuleGroup: _this.isRuleGroup.bind(_this),
                controls: controls,
                lastRuleId: null,
                getOperators: function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    return _this.getOperators.apply(_this, args);
                },
            },
        };
        _this.ruleGroupRef = React.createRef();
        return _this;
    }
    Object.defineProperty(QueryBuilder, "defaultTranslations", {
        get: function () {
            return {
                fields: {
                    title: 'Fields',
                },
                operators: {
                    title: 'Operators',
                },
                value: {
                    title: 'Value',
                },
                removeRule: {
                    id: 'remove',
                    label: 'Remove',
                    title: 'Remove rule',
                },
                removeGroup: {
                    id: 'removeGroup',
                    label: 'Remove',
                    title: 'Remove group',
                },
                addRule: {
                    id: 'addRule',
                    label: 'Add Rule',
                    title: 'Add rule',
                },
                addGroup: {
                    id: 'addGroup',
                    label: 'Add Group',
                    title: 'Add group',
                },
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QueryBuilder, "defaultOperators", {
        get: function () {
            return [
            /* { name: 'null', label: 'Is Null' },
            { name: 'notNull', label: 'Is Not Null' },
            { name: 'in', label: 'In' },
            { name: 'notIn', label: 'Not In' },
            { name: '=', label: '=' },
            { name: '!=', label: '!=' }, */
            /* { name: '<', label: '<' },
            { name: '>', label: '>' },
            { name: '<=', label: '<=' },
            { name: '>=', label: '>=' }, */
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QueryBuilder, "defaultControlClassnames", {
        get: function () {
            return {
                queryBuilder: '',
                ruleGroup: '',
                addRule: '',
                addGroup: '',
                removeGroup: '',
                rule: '',
                fields: '',
                operators: '',
                value: '',
                removeRule: '',
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QueryBuilder, "defaultControlElements", {
        get: function () {
            return {
                addGroupAction: ActionElement,
                removeGroupAction: ActionElement,
                addRuleAction: ActionElement,
                removeRuleAction: ActionElement,
                combinatorSelector: ValueSelector,
                fieldSelector: ValueSelector,
                operatorSelector: ValueSelector,
                valueEditor: ValueEditor,
            };
        },
        enumerable: false,
        configurable: true
    });
    QueryBuilder.prototype.componentDidMount = function () {
        this.notifyQueryChange(null);
        this.setState({
            schema: __assign(__assign({}, this.state.schema), { fields: this.props.fields }),
            root: (this.props.query &&
                this.props.query !== 'null' &&
                this.props.query) || {
                id: uniqueId(),
                isGroup: true,
                rules: [
                    {
                        id: uniqueId(),
                        field: '',
                        value: '',
                        ops: null,
                    },
                ],
                combinator: { ops: 'and', not: false },
            },
        });
    };
    QueryBuilder.prototype.componentDidUpdate = function (prevProps) {
        var isNewProps = JSON.stringify(prevProps) !== JSON.stringify(this.props);
        if (isNewProps) {
            this.setState({
                schema: __assign(__assign({}, this.state.schema), { fields: this.props.fields }),
                root: (this.props.query &&
                    this.props.query !== 'null' &&
                    this.props.query) || {
                    id: uniqueId(),
                    isGroup: true,
                    rules: [
                        {
                            id: uniqueId(),
                            field: '',
                            value: '',
                            ops: null,
                        },
                    ],
                    combinator: { ops: 'and', not: false },
                },
            });
        }
    };
    QueryBuilder.prototype.onRuleAdd = function (rule, parentId) {
        if (parentId) {
            var parent = this.findRule(parentId, this.state.root);
            parent.rules.unshift(rule);
        }
        else {
            this.state.root.rules.unshift(rule);
        }
        this.setState({
            root: this.state.root,
            lastRuleId: rule.id,
        });
    };
    QueryBuilder.prototype.onGroupAdd = function (group, parentId) {
        var parent = this.findRule(parentId, this.state.root);
        parent.rules.push(group);
        this.setState({
            root: this.state.root,
            lastRuleId: group.rules[0].id,
        });
    };
    QueryBuilder.prototype.onPropChange = function (prop, value, ruleId) {
        var _a;
        var rule = this.findRule(ruleId, this.state.root);
        var ruleValue = rule.field;
        Object.assign(rule, (_a = {}, _a[prop] = value, _a));
        if (['null', 'notNull'].includes(value) ||
            (prop === 'field' && ruleValue !== value)) {
            rule.value = '';
        }
        this.setState({ root: this.state.root });
    };
    QueryBuilder.prototype.onRuleRemove = function (ruleId, parentId) {
        var parent = this.findRule(parentId, this.state.root);
        var index = parent.rules.findIndex(function (x) { return x.id === ruleId; });
        parent.rules.splice(index, 1);
        this.setState({ root: this.state.root });
    };
    QueryBuilder.prototype.onGroupRemove = function (groupId, parentId) {
        var parent = this.findRule(parentId, this.state.root);
        var index = parent.rules.findIndex(function (x) { return x.id === groupId; });
        parent.rules.splice(index, 1);
        this.setState({ root: this.state.root });
    };
    QueryBuilder.prototype.onInitializeAll = function () {
        var root = this.state.root;
        //TODO:fix state mutation
        this.state.root.rules = [];
        var newRule = this.state.schema.createRule();
        this.onRuleAdd(newRule, root.id);
    };
    QueryBuilder.prototype.getInitialQuery = function () {
        return this.props.query && this.props.query !== 'null'
            ? this.props.query
            : this.createRuleGroup();
    };
    QueryBuilder.prototype.getOperators = function (field) {
        if (this.props.getOperators) {
            var ops = this.props.getOperators(field);
            if (ops) {
                return ops;
            }
        }
        return this.props.operators;
    };
    QueryBuilder.prototype.getLevel = function (id) {
        return this.getRecursiveLevel(id, 0, this.state.root);
    };
    QueryBuilder.prototype.getRecursiveLevel = function (id, index, root) {
        var _this = this;
        var isRuleGroup = this.state.schema.isRuleGroup;
        var foundAtIndex = -1;
        if (root.id === id) {
            foundAtIndex = index;
        }
        else if (isRuleGroup(root)) {
            root.rules.forEach(function (rule) {
                if (foundAtIndex === -1) {
                    var indexForRule = index;
                    if (isRuleGroup(rule))
                        indexForRule += 1;
                    foundAtIndex = _this.getRecursiveLevel(id, indexForRule, rule);
                }
            });
        }
        return foundAtIndex;
    };
    QueryBuilder.prototype.createRuleGroup = function () {
        var rule = this.createRule();
        return {
            id: "g-".concat(uniqueId(), "}"),
            isGroup: true,
            rules: [rule],
            combinator: {
                ops: 'and',
                not: false,
            },
        };
    };
    QueryBuilder.prototype.isRuleGroup = function (rule) {
        return !!(rule.combinator && rule.rules);
    };
    QueryBuilder.prototype.createRule = function () {
        return {
            id: "r-".concat(uniqueId()),
            field: '',
            value: '',
            ops: '',
        };
    };
    QueryBuilder.prototype.findRule = function (id, parent) {
        var isRuleGroup = this.state.schema.isRuleGroup;
        if (parent.id === id) {
            return parent;
        }
        for (var _i = 0, _a = parent.rules; _i < _a.length; _i++) {
            var rule = _a[_i];
            if (rule.id === id) {
                return rule;
            }
            else if (isRuleGroup(rule)) {
                var subRule = this.findRule(id, rule);
                if (subRule) {
                    return subRule;
                }
            }
        }
        return null;
    };
    QueryBuilder.prototype.notifyQueryChange = function (fn) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (fn) {
            fn.call.apply(fn, __spreadArray([this], args, false));
        }
        var onQueryChange = this.props.onQueryChange;
        if (onQueryChange) {
            var query = JSON.parse(JSON.stringify(this.state.root));
            onQueryChange(query);
        }
    };
    QueryBuilder.prototype.render = function () {
        var _a = this.state, _b = _a.root, id = _b.id, rules = _b.rules, combinator = _b.combinator, schema = _a.schema, lastRuleId = _a.lastRuleId;
        var _c = this.props, translations = _c.translations, onRuleChange = _c.onRuleChange;
        return (_jsx(Wrapper, { className: "queryBuilder ".concat(schema &&
                schema.classNames &&
                schema.classNames.queryBuilder), children: (schema === null || schema === void 0 ? void 0 : schema.fields) ? (_jsx(RuleGroup, { ref: this.ruleGroupRef, translations: translations, rules: rules, combinator: combinator, schema: schema, query: this.state.root, id: id, parentId: null, onRuleChange: onRuleChange, lastRuleId: lastRuleId })) : null }));
    };
    return QueryBuilder;
}(React.Component));
export default QueryBuilder;
QueryBuilder.defaultProps = {
    operators: QueryBuilder.defaultOperators,
    translations: QueryBuilder.defaultTranslations,
    controlElements: null,
    getOperators: null,
    onQueryChange: null,
    controlClassnames: null,
};
QueryBuilder.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(Date),
            PropTypes.shape({
                startDate: PropTypes.instanceOf(moment),
                endDate: PropTypes.instanceOf(moment),
            }),
        ]),
    })).isRequired,
    operators: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
    })),
    controlElements: PropTypes.shape({
        addGroupAction: PropTypes.func,
        removeGroupAction: PropTypes.func,
        addRuleAction: PropTypes.func,
        removeRuleAction: PropTypes.func,
        combinatorSelector: PropTypes.func,
        fieldSelector: PropTypes.func,
        operatorSelector: PropTypes.func,
        valueEditor: PropTypes.func,
    }),
    getOperators: PropTypes.func,
    onQueryChange: PropTypes.func,
    controlClassnames: PropTypes.shape({
        queryBuilder: PropTypes.string,
        ruleGroup: PropTypes.string,
        addRule: PropTypes.string,
        addGroup: PropTypes.string,
        removeGroup: PropTypes.string,
        rule: PropTypes.string,
        fields: PropTypes.string,
        operators: PropTypes.string,
        value: PropTypes.string,
        removeRule: PropTypes.string,
    }),
    translations: PropTypes.shape({
        fields: PropTypes.shape({
            title: PropTypes.string,
        }),
        operators: PropTypes.shape({
            title: PropTypes.string,
        }),
        value: PropTypes.shape({
            title: PropTypes.string,
        }),
        removeRule: PropTypes.shape({
            title: PropTypes.string,
            label: PropTypes.string,
        }),
        removeGroup: PropTypes.shape({
            title: PropTypes.string,
            label: PropTypes.string,
        }),
        addRule: PropTypes.shape({
            title: PropTypes.string,
            label: PropTypes.string,
        }),
        addGroup: PropTypes.shape({
            title: PropTypes.string,
            label: PropTypes.string,
        }),
    }),
};
