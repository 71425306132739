import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
var ExportActionCreators = {
    getExcelHeaderItems: function (callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_EXCEL_HEADER_ITEMS_REQUEST,
        });
        WebApi.getExcelHeader(callback);
    },
    updateReviewPdfTemplate: function (model, callback) {
        WebApi.updateReviewPdfTemplate(model, callback);
    },
    createReviewPDF: function (model, callback) {
        WebApi.createReviewPDF(model, callback);
    },
    createGlobalNewsDigestPDF: function (model, callback) {
        WebApi.createGlobalNewsDigestPDF(model, callback);
    },
    createGetCategoryNewsDigestPDF: function (callback) {
        WebApi.createGetCategoryNewsDigestPDF(callback);
    },
    createGlobalPDF: function (model, callback) {
        WebApi.createGlobalPDF(model, callback);
    },
    createUnitPushmailPDF: function (model, callback) {
        WebApi.createUnitPushmailPDF(model, callback);
    },
    createPDF: function (model, callback) {
        WebApi.createPDF(model, callback);
    },
    createUnitPDF: function (model, callback) {
        WebApi.createUnitPDF(model, callback);
    },
    cancelPdfGeneration: function (_a, callback) {
        var id = _a.id;
        if (id) {
            WebApi.cancelPdfGeneration({ id: id }, callback);
        }
    },
};
export default ExportActionCreators;
