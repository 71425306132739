import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Svg from 'commons/Svg';
import Button from 'components/Button';
var propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    selected: PropTypes.bool,
};
var defaultProps = {
    selected: false,
};
var FavoriteSearchItem = function (_a) {
    var item = _a.item, onDelete = _a.onDelete, onClick = _a.onClick, onEdit = _a.onEdit, selected = _a.selected;
    var handleEdit = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (typeof onEdit === 'function') {
            onEdit(item.id);
        }
    };
    return (_jsx("li", { className: "favourite-search_list__item ".concat(selected ? 'favourite-search_list__item--selected' : ''), children: _jsxs(Fragment, { children: [_jsx(Button, { className: "favourite-search_list__item-name text-truncate", onClick: onClick(item.id), title: item.name, children: item.name }), _jsx(Button, { onClick: handleEdit, title: "Edit search", children: _jsx(Svg, { icon: "pencil", size: "small" }) }), _jsx(Button, { onClick: onDelete(item.id), title: "Delete search", children: _jsx(Svg, { icon: "trash", size: "small" }) })] }) }));
};
FavoriteSearchItem.propTypes = propTypes;
FavoriteSearchItem.defaultProps = defaultProps;
export default FavoriteSearchItem;
