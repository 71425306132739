import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classnames from 'classnames';
// import keycode from 'keycode';
import parameters from '@constants/parameters';
import Button from '@commons/Button';
import Svg from '@commons/Svg';
import { Wrapper } from './DualListBoxItem.styles';
var BREAKPOINT = parameters.BREAKPOINT;
var DualListBoxItem = function (_a) {
    // const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    //   if (typeof onClick === 'function') onClick(id, event);
    // };
    // const handleKeyDown = event => {
    //   if (event.keyCode === keycode('enter')) {
    //     handleClick();
    //   }
    var _b = _a.item, position = _b.position, id = _b.id, label = _b.label, icon = _b.icon, onClick = _a.onClick, _c = _a.onDownClick, onDownClick = _c === void 0 ? null : _c, _d = _a.onUpClick, onUpClick = _d === void 0 ? null : _d, onMoveItem = _a.onMoveItem, selected = _a.selected, _e = _a.count, count = _e === void 0 ? null : _e, showCount = _a.showCount, windowWidth = _a.windowWidth, handleToggle = _a.handleToggle, idOption = _a.idOption;
    //   if (!selected) {
    //     return;
    //   }
    //   if (event.keyCode === keycode('left') && position !== 0) {
    //     onMoveItem(id, false);
    //   } else if (event.keyCode === keycode('right') && position === 0) {
    //     onMoveItem(id, true);
    //   } else if (onUpClick != null && event.keyCode === keycode('up')) {
    //     onUpClick();
    //   } else if (onDownClick != null && event.keyCode === keycode('down')) {
    //     onDownClick();
    //   }
    // };
    var getClassName = classnames('c-duallistbox-item', {
        'is-selected': selected && (windowWidth > BREAKPOINT.md || position > 0),
    });
    return (_jsxs(Wrapper, { className: getClassName, role: "presentation", children: [_jsxs(Button, { variant: "text", 
                // aria-selected={
                //   windowWidth < BREAKPOINT.md || position > 0 ? selected : ''
                // }
                className: "c-duallistbox-item__label text-truncate", onClick: function (event) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    windowWidth > BREAKPOINT.md || position > 0
                        ? onClick(id, event)
                        : null;
                }, title: "Select item", children: [icon ? (_jsx(Svg, { icon: icon, size: "medium", className: "filter-icon" })) : null, _jsxs("span", { className: "filter-title", children: [" ", label] })] }), windowWidth < BREAKPOINT.md ? (_jsx(Button, { className: "c-duallistbox-item__btn", onClick: function () {
                    handleToggle(Number(id), idOption);
                }, title: "Toggle button", children: _jsx(Svg, { icon: idOption === 'DualListBoxGroup_1' && position === 0
                        ? 'plus'
                        : 'close', size: "x-small" }) }, id)) : null, showCount && (_jsx("div", { className: "dualistbox__item_count", children: _jsx("span", { children: count }) }))] }));
};
export default DualListBoxItem;
