var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import moment from 'moment-timezone';
import { convertFromUserTZToBackend } from 'services/DateUtils';
import UserStore from 'stores/UserStore';
var NUMBER_FIELDS = ['ave', 'numberOfPages', 'ots', 'pageNumber'];
var DEFAULT_SELECTED_MEDIUM_TYPE = 'press';
var DEFAULT_REQUIRED_FIELDS = [
    'fullText',
    'language',
    'publicationDate',
    'sentiment',
    'sourceType',
    'title',
];
var ADDITIONAL_REQUIRED_FIELDS_BY_MEDIUM_TYPE = {
    press: ['source'],
    radio: ['source'],
    broadcast: ['source'],
    tv: ['source'],
    web: ['source'],
    social: ['socialMediaSource', 'country'],
};
var initialState = {
    author: '',
    ave: '',
    clipSelections: [],
    country: null,
    currentEditionIsoCode: '',
    duration: null,
    errors: [],
    files: [],
    fullText: '',
    headlines: [],
    isTranslationsFormOpen: false,
    keywords: [],
    language: null,
    loading: false,
    fileUploadLoading: false,
    mediumType: DEFAULT_SELECTED_MEDIUM_TYPE,
    numberOfPages: '',
    ots: '',
    pageNumber: '',
    program: null,
    mediaFamily: '',
    mediaCategory: '',
    programs: [],
    publicationDate: new Date(moment
        .tz(new Date(), UserStore.selectedTimeZone)
        .format('YYYY-MM-DDTHH:mm:ss')),
    requiredFields: __spreadArray(__spreadArray([], DEFAULT_REQUIRED_FIELDS, true), ADDITIONAL_REQUIRED_FIELDS_BY_MEDIUM_TYPE[DEFAULT_SELECTED_MEDIUM_TYPE], true),
    selectedSummaryIsoCode: '',
    sentiment: 'neutral',
    source: null,
    socialMediaSource: '',
    sources: [],
    sourceType: null,
    sourceTypes: [],
    summaries: [],
    summary: '',
    title: '',
    url: '',
    retentionPeriod: null,
    tabIndex: 0,
    isPreviewModalOpen: false,
    uploadedFiles: {},
    submitLoading: false,
    allresources: [],
};
var addArticleReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case 'REMOVE_ERROR':
            return __assign(__assign({}, state), { errors: state.errors.filter(function (error) { return error !== action.id; }) });
        case 'VALIDATE_ERROR': {
            var id = action.id;
            var errors = state.errors;
            var value = state[id];
            if (state.requiredFields.includes(id)) {
                if (!value) {
                    return __assign(__assign({}, state), { errors: __spreadArray(__spreadArray([], errors, true), [action.id], false) });
                }
                else if (value && errors.includes(id)) {
                    return __assign(__assign({}, state), { errors: errors.filter(function (error) { return error !== action.id; }) });
                }
            }
            return state;
        }
        case 'CHANGE_STATE':
            return __assign(__assign({}, state), action.payload);
        case 'CHANGE_MEDIUMTYPE':
            return __assign(__assign({}, state), { country: null, currentEditionIsoCode: '', errors: [], files: [], isTranslationsFormOpen: false, mediumType: action.value, program: null, programs: [], requiredFields: __spreadArray(__spreadArray([], DEFAULT_REQUIRED_FIELDS, true), ADDITIONAL_REQUIRED_FIELDS_BY_MEDIUM_TYPE[action.value], true), source: null, sources: [], sourceType: null, sourceTypes: [], allresources: [] });
        case 'UPDATE_FILES':
            return __assign(__assign({}, state), { files: __spreadArray([
                    action.payload
                ], state.files.filter(function (fileItem) { return fileItem.type !== action.payload.type; }), true) });
        case 'UPDATE_UPLOADED_FILES':
            return __assign(__assign({}, state), { uploadedFiles: __assign(__assign({}, state.uploadedFiles), action.payload) });
        case 'RESET':
            return initialState;
        default:
            return state;
    }
};
function prepareDataToSave(_a) {
    var author = _a.author, ave = _a.ave, clipSelections = _a.clipSelections, _b = _a.country, country = _b.label, countryIsoCode2 = _b.value, duration = _a.duration, fullText = _a.fullText, headlines = _a.headlines, keywords = _a.keywords, language = _a.language.value, mediumType = _a.mediumType, numberOfPages = _a.numberOfPages, ots = _a.ots, pageNumber = _a.pageNumber, program = _a.program, mediaCategory = _a.mediaCategory, mediaFamily = _a.mediaFamily, publicationDate = _a.publicationDate, sentiment = _a.sentiment, source = _a.source, socialMediaSource = _a.socialMediaSource, sourceType = _a.sourceType.key, summaries = _a.summaries, summary = _a.summary, title = _a.title, url = _a.url, userAccountId = _a.userAccountId;
    var withoutTime = ['press', 'web', 'social'].includes(mediumType);
    return {
        author: {
            name: author,
        },
        clipSelections: clipSelections,
        country: country,
        countryIsoCode2: countryIsoCode2,
        deliveredDate: convertFromUserTZToBackend(publicationDate, {
            withoutTime: true,
        }),
        fullText: fullText,
        headlines: __spreadArray([
            {
                isoCode: language,
                original: true,
                text: title,
            }
        ], headlines, true),
        keywords: keywords,
        language: language,
        mediaValue: typeof Number(ave) === 'number' ? Number(ave) : null,
        numberOfPages: typeof Number(numberOfPages) === 'number' ? Number(numberOfPages) : null,
        ots: typeof Number(ots) === 'number' ? Number(ots) : null,
        page: typeof Number(pageNumber) === 'number' ? pageNumber : '',
        publicationDate: convertFromUserTZToBackend(publicationDate, {
            withoutTime: withoutTime,
        }),
        /*eslint-disable*/
        seconds: duration
            ? duration.getHours() * 3600 +
                duration.getMinutes() * 60 +
                duration.getSeconds()
            : null,
        sentiment: { level: sentiment, userAccountId: userAccountId },
        source: {
            id: source === undefined || source === null ? 0 : source.globalId,
            medium: mediumType.toUpperCase(),
            name: source === undefined || source === null
                ? socialMediaSource
                : source.supportName,
            programme: program && program.editionName,
            type: sourceType.toUpperCase(),
            medfam: mediaFamily || null,
            medcat: mediaCategory || null,
        },
        summary: __spreadArray([
            summary.length > 0
                ? {
                    isoCode: language,
                    original: true,
                    text: summary,
                }
                : {}
        ], summaries, true),
        url: url,
    };
}
function getBase64(dataURI) {
    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    return dataURI.substring(base64Index);
}
function prepareFilesToSave(files) {
    return files.map(function (fileItem) {
        return fileItem.type !== 'audio' && fileItem.type !== 'video'
            ? __assign(__assign({}, fileItem), { main: getBase64(fileItem.main) }) : fileItem;
    });
}
function getMediaFamilyKey(mediaFamily) {
    return mediaFamily === null || mediaFamily === void 0 ? void 0 : mediaFamily.split('/')[0].replace(' ', '').toUpperCase();
}
function getMediaCategoryKey(mediaCategory) {
    return mediaCategory === null || mediaCategory === void 0 ? void 0 : mediaCategory.replace(' ', '').toUpperCase();
}
export { addArticleReducer, initialState, prepareDataToSave, prepareFilesToSave, getMediaFamilyKey, getMediaCategoryKey, NUMBER_FIELDS, };
