import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { IoMdClose, IoIosArrowBack } from 'react-icons/io';
import Button from '@commons/Button';
import ButtonIcon from '@commons/ButtonIcon';
import Box from '@commons/Box';
var ModalHeader = function (_a) {
    var className = _a.className, onClose = _a.onClose, children = _a.children, onBack = _a.onBack, backTo = _a.backTo, _b = _a.showClose, showClose = _b === void 0 ? true : _b;
    var modalHeaderClassNames = classnames('modal__header', className);
    return (_jsxs("div", { className: modalHeaderClassNames, children: [onBack ? (_jsxs(Box, { display: "flex", flexDirection: "column", children: [_jsx(Button, { className: "modal__back", onClick: onBack, variant: "text", size: "small", title: "Back to previous page button", "aria-label": "Back to previous page button", leftIcon: _jsx(IoIosArrowBack, { size: "18" }), children: backTo !== '' ? backTo : _jsx(FormattedMessage, { id: "Global.back" }) }), children] })) : (children), showClose && (_jsxs(ButtonIcon, { className: "modal__close", variant: "text", size: "small", onClick: onClose, title: "Close Modal", "aria-label": "Close Modal", children: [_jsx(IoMdClose, { size: 28 }), _jsx("span", { className: "hidden", children: "close Modal" })] }))] }));
};
export default ModalHeader;
