import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * Radio Input
 */
import { useRef } from 'react';
import classnames from 'classnames';
import { Wrapper } from './Radio.styles';
var Radio = function (_a) {
    var checked = _a.checked, children = _a.children, _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, id = _a.id, name = _a.name, onChange = _a.onChange, _d = _a.value, value = _d === void 0 ? '' : _d;
    var input = useRef();
    var refInputCallback = function (ref) {
        input.current = ref;
    };
    return (_jsx(Wrapper, { children: _jsxs("div", { className: classnames('radio', className), children: [_jsx("input", { checked: checked, disabled: disabled, id: id, name: name, onChange: onChange, ref: refInputCallback, type: "radio", value: value, title: name }), _jsx("label", { htmlFor: id, children: children == null ? null : (_jsx("span", { className: "radio__label", children: children })) })] }) }));
};
export default Radio;
