export function localeToIsoCode() {
    var locale = window.locale;
    var localeList = new Map([
        ['ca-ES', 'es'],
        ['de-DE', 'de'],
        ['en-GB', 'en'],
        ['es-ES', 'es'],
        ['eu-ES', 'eu'],
        ['it-IT', 'it'],
        ['fr-FR', 'fr'],
        ['zh-CN', 'zh'],
    ]);
    var isoCode = locale ? localeList.get(locale) : localeList.get('en');
    return isoCode;
}
export var cookieconsentOptions = {
    autorun: true,
    page_scripts: true,
    current_lang: 'en',
    languages: {
        en: {
            consent_modal: {
                title: 'I use cookies',
                description: 'This website uses cookies and similar technologies to function properly. Additionally, it may use analytical cookies to collect data on how users interact with the site. For more information, we invite you to consult the cookie policy. <button type="button" aria-label="View cookie settings" data-cc="c-settings" class="setting-btn">Settings</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all', // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Deny',
                    role: 'accept_necessary', // 'settings' or 'accept_necessary'
                },
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save & Accept',
                accept_all_btn: 'Accept all',
                cookie_table_headers: [
                    { col1: 'Name' },
                    { col2: 'Duration' },
                    { col3: 'Description' },
                ],
                blocks: [
                    {
                        title: 'Cookie usage',
                        description: 'This website uses cookies and other similar technologies to collect information without which it could not operate normally. For more information, we invite you to consult the cookie policy',
                    },
                    {
                        title: 'Strictly necessary cookies',
                        description: 'Necessary cookies are absolutely essential for the website to function properly. These cookies ensure basic functionalities and security features of the website, anonymously.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true,
                        },
                    },
                    {
                        title: 'Analytics cookies',
                        description: 'Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics the number of visitors, bounce rate, traffic source, etc.',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false,
                        },
                        cookie_table: [
                            {
                                col1: 'fs_uid',
                                col2: '12 months',
                                col3: "The 'fs_uid' cookie, installed by FullStory, includes 100% of the indexed data used for exploring, calculating, and analyzing historical trends. All of the session data necessary for replaying user sessions.",
                            },
                        ],
                    },
                ],
            },
        },
        fr: {
            consent_modal: {
                title: "J'utilise des cookies",
                description: 'Ce site Web utilise des cookies et des technologies similaires pour fonctionner correctement. De plus, il peut utiliser des cookies analytiques pour collecter des données sur la façon dont les utilisateurs interagissent avec le site. Pour plus d\'informations, nous vous invitons à consulter la politique en matière de cookies. <button type="button" aria-label="View cookie settings" data-cc="c-settings" class="setting-btn">Paramètres</button>',
                primary_btn: {
                    text: 'Tout accepter',
                    role: 'accept_all', // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Refuser',
                    role: 'accept_necessary', // 'settings' or 'accept_necessary'
                },
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Enregistrer & accepter',
                accept_all_btn: 'Accept all',
                cookie_table_headers: [
                    { col1: 'Nom' },
                    { col2: 'Durée' },
                    { col3: 'Description' },
                ],
                blocks: [
                    {
                        title: 'Utilisation des cookies',
                        description: "Ce site Web utilise des cookies et d'autres technologies similaires pour collecter des informations sans lesquelles il ne pourrait pas fonctionner normalement. Pour plus d'informations, nous vous invitons à consulter la politique relative aux cookies",
                    },
                    {
                        title: 'Cookies strictement nécessaires',
                        description: 'Les cookies nécessaires sont absolument essentiels au bon fonctionnement du site Web. Ces cookies assurent les fonctionnalités de base et les éléments de sécurité du site Web, de manière anonyme.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true,
                        },
                    },
                    {
                        title: 'Cookies analytiques',
                        description: 'Les cookies analytiques sont utilisés pour comprendre comment les visiteurs interagissent avec le site Web. Ces cookies aident à fournir des informations sur les métriques du nombre de visiteurs, du taux de rebond, de la source du trafic, etc.',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false,
                        },
                        cookie_table: [
                            {
                                col1: 'fs_uid',
                                col2: '12 months',
                                col3: "Le cookie 'fs_uid', installé par FullStory, inclut 100% des données indexées utilisées pour explorer, calculer et analyser les tendances historiques. Toutes les données de session nécessaires à la relecture des sessions utilisateur.",
                            },
                        ],
                    },
                ],
            },
        },
        de: {
            consent_modal: {
                title: 'Ich verwende Cookies',
                description: 'Dieses Website erfordert Cookies und ähnliche Technologien, um ordnungsgemäß zu funktionieren. Zusätzlich können Analyse-Cookies verwendet werden, um Daten darüber zu sammeln, wie Benutzer mit der Website interagieren. Weitere Informationen finden Sie in unserer Cookie-Richtlinie. <button type="button" aria-label="View cookie settings" data-cc="c-settings" class="setting-btn">Einstellungen</button>',
                primary_btn: {
                    text: 'Akzeptiere alle',
                    role: 'accept_all', // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Alle ablehnen',
                    role: 'accept_necessary', // 'settings' or 'accept_necessary'
                },
            },
            settings_modal: {
                title: 'Cookie-Einstellungen',
                save_settings_btn: 'Speichern & akzeptieren',
                accept_all_btn: 'Akzeptiere alle',
                cookie_table_headers: [
                    { col1: 'Name' },
                    { col2: 'Dauer' },
                    { col3: 'Beschreibung' },
                ],
                blocks: [
                    {
                        title: 'Cookie-Nutzung',
                        description: 'Diese Website verwendet Cookies und andere ähnliche Technologien, die Informationen sammeln, um Ihnen den bestmöglichen Service zu gewährleisten. Weitere Informationen dazu finden Sie in der Cookie-Richtlinie',
                    },
                    {
                        title: 'Unbedingt erforderliche Cookies',
                        description: 'Notwendige Cookies sind absolut notwendig, damit die Website ordnungsgemäß funktioniert. Diese Cookies gewährleisten anonym grundlegende Funktionalitäten und Sicherheitsmerkmale der Website.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true,
                        },
                    },
                    {
                        title: 'Analyse-Cookies',
                        description: 'Analytische Cookies werden verwendet, um zu verstehen, wie Besucher mit der Website interagieren. Diese Cookies helfen bei der Bereitstellung von Informationen zu Metriken wie Anzahl der Besucher, Absprungrate, Verkehrsquelle usw.',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false,
                        },
                        cookie_table: [
                            {
                                col1: 'fs_uid',
                                col2: '12 months',
                                col3: 'Der von FullStory installierte Cookie "fs_uid" enthält 100 % der Indexdaten, die zur Erforschung, Berechnung und Analyse historischer Trends verwendet werden. Alle Sitzungsdaten sind für die Wiedergabe von Benutzersitzungen erforderlich.',
                            },
                        ],
                    },
                ],
            },
        },
        es: {
            consent_modal: {
                title: 'Uso cookies',
                description: 'Este sitio web requiere cookies y tecnologías similares para funcionar correctamente. Además, puede utilizar cookies analíticas para recopilar datos sobre cómo los usuarios interactúan con el sitio. Para obtener más información, lo invitamos a consultar la política de cookies.  <button type="button" aria-label="View cookie settings" data-cc="c-settings" class="setting-btn">Ajustes</button>',
                primary_btn: {
                    text: 'Aceptar todas',
                    role: 'accept_all', // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Rechazar',
                    role: 'accept_necessary', // 'settings' or 'accept_necessary'
                },
            },
            settings_modal: {
                title: 'Preferencias de cookies',
                save_settings_btn: 'Guardar y aceptar',
                accept_all_btn: 'Accept all',
                cookie_table_headers: [
                    { col1: 'Nombre' },
                    { col2: 'Duración' },
                    { col3: 'Descripción' },
                ],
                blocks: [
                    {
                        title: 'Uso de cookies',
                        description: 'Esta web utiliza cookies y otras tecnologías similares para obtener información sin la cual no se podría operar con normalidad. Para más información, por favor, consulta nuestra política de cookies. Gracias.',
                    },
                    {
                        title: 'Cookies estrictamente necesarias',
                        description: 'Las cookies necesarias son absolutamente esenciales para que el sitio web funcione correctamente. Estas cookies garantizan funcionalidades básicas y características de seguridad del sitio web, de forma anónima.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true,
                        },
                    },
                    {
                        title: 'Cookies analíticas',
                        description: 'Las cookies analíticas se utilizan para comprender cómo interactúan los visitantes con el sitio web. Estas cookies ayudan a proporcionar información sobre métricas, el número de visitantes, la tasa de rebote, la fuente de tráfico, etc.',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false,
                        },
                        cookie_table: [
                            {
                                col1: 'fs_uid',
                                col2: '12 months',
                                col3: 'La cookie "fs_uid", instalada por FullStory, incluye el 100% de los datos indexados usados para explorar, calcular y analizar las tendencias históricas. Todos los datos de sesión son necesarios para reproducir las sesiones de los usuarios.',
                            },
                        ],
                    },
                ],
            },
        },
        it: {
            consent_modal: {
                title: 'Uso i cookie',
                description: 'Questo sito web richiede l\'utilizzo di cookie e tecnologie simili per funzionare correttamente. Inoltre, può utilizzare cookie analitici per raccogliere informazioni su come gli utenti interagiscono con il sito. Per ulteriori informazioni, ti invitiamo a consultare la politica sui cookie.  <button type="button" aria-label="View cookie settings" data-cc="c-settings" class="setting-btn">Impostazioni</button>',
                primary_btn: {
                    text: 'Accettare tutti',
                    role: 'accept_all', // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Declino',
                    role: 'accept_necessary', // 'settings' or 'accept_necessary'
                },
            },
            settings_modal: {
                title: 'Preferenze sui cookie',
                save_settings_btn: 'Salva e accetta',
                accept_all_btn: 'Accettare tutti',
                cookie_table_headers: [
                    { col1: 'Nome' },
                    { col2: 'Durata' },
                    { col3: 'Descrizione' },
                ],
                blocks: [
                    {
                        title: 'Utilizzo dei cookie',
                        description: 'Questo sito Web utilizza cookie e altre tecnologie simili per raccogliere informazioni senza le quali non potrebbe funzionare normalmente. Per maggiori informazioni, ti invitiamo a consultare la cookie policy',
                    },
                    {
                        title: 'Cookie strettamente necessari',
                        description: 'I cookie necessari sono assolutamente essenziali per il corretto funzionamento del sito web. Questi cookie garantiscono le funzionalità di base e le caratteristiche di sicurezza del sito web, in modo anonimo.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true,
                        },
                    },
                    {
                        title: 'Cookie analitici',
                        description: 'I cookie analitici vengono utilizzati per capire come i visitatori interagiscono con il sito web. Questi cookie aiutano a fornire informazioni sulle metriche del numero di visitatori, frequenza di rimbalzo, fonte di traffico, ecc.',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false,
                        },
                        cookie_table: [
                            {
                                col1: 'fs_uid',
                                col2: '12 months',
                                col3: "Il cookie 'fs_uid' installato da FullStory, include il 100% dei dati indicizzati utilizzati per esplorare, calcolare e analizzare le tendenze storiche. Tutte le sessioni utilizzano dati necessari per riprodurre le sessioni degli user.",
                            },
                        ],
                    },
                ],
            },
        },
    },
};
