var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { parseDate } from 'services/DateUtils';
import ActionTypes from 'constants/actionTypes';
import BaseStore from 'stores/BaseStore';
var EmailAlertsStores = /** @class */ (function (_super) {
    __extends(EmailAlertsStores, _super);
    function EmailAlertsStores() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    EmailAlertsStores.prototype.initializeState = function () {
        this.emailAlertes = [];
        this.userClipSelection = [];
        this.pushMailLayout = [];
        this.userCulture = 0;
    };
    EmailAlertsStores.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    EmailAlertsStores.prototype.getList = function () {
        return this.emailAlertes;
    };
    EmailAlertsStores.prototype.getCulture = function () {
        return this.userCulture;
    };
    EmailAlertsStores.prototype.getUserClipSelection = function () {
        return this.userClipSelection;
    };
    EmailAlertsStores.prototype.getPushMailLayout = function () {
        return this.pushMailLayout;
    };
    EmailAlertsStores.prototype.getEmailAlertById = function (id) {
        return this.emailAlertes.find(function (emailAlertItem) { return emailAlertItem.subscriptionId === id; });
    };
    EmailAlertsStores.prototype.addEmailAlert = function (_a) {
        var name = _a.name, subscriptionId = _a.subscriptionId, status = _a.status, lastModifiedDate = _a.lastModifiedDate, lastSentOn = _a.lastSentOn, plannedON = _a.plannedON, templateName = _a.templateName;
        var emailAlert = this.getEmailAlertById(subscriptionId);
        if (name && subscriptionId && !emailAlert) {
            var newEmailAlert = {
                name: name,
                subscriptionId: subscriptionId,
                status: status,
                lastModifiedDate: lastModifiedDate,
                lastSentOn: lastSentOn,
                plannedON: plannedON,
                templateName: templateName,
            };
            this.emailAlertes.push(newEmailAlert);
            return newEmailAlert;
        }
        return emailAlert;
    };
    EmailAlertsStores.setAlertName = function (emailAlert, name) {
        if (name === void 0) { name = ''; }
        emailAlert.name = typeof name === 'string' ? name : '';
    };
    EmailAlertsStores.setTimezone = function (emailAlert, timeZone) {
        if (timeZone === void 0) { timeZone = ''; }
        emailAlert.timeZone = typeof timeZone === 'string' ? timeZone : '';
    };
    EmailAlertsStores.setStatus = function (emailAlert, status) {
        if (status === void 0) { status = 0; }
        emailAlert.status = typeof status === 'number' ? status : 0;
    };
    EmailAlertsStores.setPushmailTemplateIdentifier = function (emailAlert, pushmailTemplateIdentifier) {
        if (pushmailTemplateIdentifier === void 0) { pushmailTemplateIdentifier = 0; }
        emailAlert.pushmailTemplateIdentifier =
            typeof pushmailTemplateIdentifier === 'number'
                ? pushmailTemplateIdentifier
                : 0;
    };
    EmailAlertsStores.setCron = function (emailAlert, cron) {
        if (cron === void 0) { cron = ''; }
        emailAlert.cron = typeof cron === 'string' ? cron : '';
    };
    EmailAlertsStores.setCulture = function (emailAlert, culture) {
        if (culture === void 0) { culture = 0; }
        emailAlert.culture = typeof culture === 'number' ? culture : 0;
    };
    EmailAlertsStores.setMediatypes = function (emailAlert, mediatypes) {
        if (mediatypes === void 0) { mediatypes = []; }
        emailAlert.mediatypes = Array.isArray(mediatypes) ? __spreadArray([], mediatypes, true) : [];
    };
    EmailAlertsStores.setWordTags = function (emailAlert, wordTags) {
        if (wordTags === void 0) { wordTags = []; }
        emailAlert.wordTags = Array.isArray(wordTags) ? __spreadArray([], wordTags, true) : [];
    };
    EmailAlertsStores.setSentiments = function (emailAlert, sentiments) {
        if (sentiments === void 0) { sentiments = []; }
        emailAlert.sentiments = Array.isArray(sentiments) ? __spreadArray([], sentiments, true) : [];
    };
    EmailAlertsStores.setSubscriptionClipSelectionOrders = function (emailAlert, subscriptionClipSelectionOrders) {
        if (subscriptionClipSelectionOrders === void 0) { subscriptionClipSelectionOrders = []; }
        emailAlert.subscriptionClipSelectionOrders = Array.isArray(subscriptionClipSelectionOrders)
            ? __spreadArray([], subscriptionClipSelectionOrders, true) : [];
    };
    EmailAlertsStores.setRecipientsIdentifiers = function (emailAlert, recipientsIdentifiers) {
        if (recipientsIdentifiers === void 0) { recipientsIdentifiers = []; }
        emailAlert.recipientsIdentifiers = Array.isArray(recipientsIdentifiers)
            ? __spreadArray([], recipientsIdentifiers, true) : [];
    };
    EmailAlertsStores.setFrom = function (emailAlert, from) {
        if (from === void 0) { from = ''; }
        emailAlert.from = typeof from === 'string' ? from : '';
    };
    EmailAlertsStores.setDisplayName = function (emailAlert, displayName) {
        if (displayName === void 0) { displayName = ''; }
        emailAlert.displayName = typeof displayName === 'string' ? displayName : '';
    };
    EmailAlertsStores.setCountries = function (emailAlert, Countries) {
        if (Countries === void 0) { Countries = []; }
        emailAlert.countries = Array.isArray(Countries) ? __spreadArray([], Countries, true) : [];
    };
    EmailAlertsStores.setWithActiveLinks = function (emailAlert, withActiveLinks) {
        if (withActiveLinks === void 0) { withActiveLinks = false; }
        emailAlert.withActiveLinks =
            typeof withActiveLinks === 'boolean' ? withActiveLinks : false;
    };
    EmailAlertsStores.setIsExplicitFilter = function (emailAlert, isExplicitFilter) {
        if (isExplicitFilter === void 0) { isExplicitFilter = false; }
        emailAlert.isExplicitFilter =
            typeof isExplicitFilter === 'boolean' ? isExplicitFilter : false;
    };
    EmailAlertsStores.setIncludeNotCategorizedClips = function (emailAlert, includeNotCategorizedClips) {
        if (includeNotCategorizedClips === void 0) { includeNotCategorizedClips = false; }
        emailAlert.includeNotCategorizedClips =
            typeof includeNotCategorizedClips === 'boolean'
                ? includeNotCategorizedClips
                : false;
    };
    EmailAlertsStores.setSourceNames = function (emailAlert, sourceNames) {
        emailAlert.sourceNames = Array.isArray(sourceNames) ? __spreadArray([], sourceNames, true) : [];
    };
    EmailAlertsStores.prototype.deleteEmailAlert = function (id) {
        var emailAlertIndex = this.emailAlertes.findIndex(function (ea) { return ea.subscriptionId === id; });
        if (emailAlertIndex === -1)
            return;
        this.emailAlertes = this.emailAlertes.filter(function (ea) { return ea.subscriptionId !== id; });
        // this.emitChange();
    };
    EmailAlertsStores.prototype.registerToActions = function (payload) {
        var _this = this;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_EMAIL_ALERTS_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    var emailAlerts = data.emailAlerts, culture = data.culture;
                    emailAlerts.forEach(function (EmlAlert) {
                        var alertName = EmlAlert.alertName, subscriptionId = EmlAlert.subscriptionId, status = EmlAlert.status, lastModifiedDate = EmlAlert.lastModifiedDate, lastSentOn = EmlAlert.lastSentOn, plannedON = EmlAlert.plannedON, templateName = EmlAlert.templateName;
                        var emailAlert = _this.addEmailAlert({
                            name: alertName,
                            subscriptionId: subscriptionId,
                            status: status,
                            lastModifiedDate: parseDate(lastModifiedDate),
                            lastSentOn: lastSentOn ? parseDate(lastSentOn) : null,
                            plannedON: plannedON,
                            templateName: templateName,
                        });
                        if (emailAlert) {
                            EmailAlertsStores.setAlertName(emailAlert, alertName);
                            EmailAlertsStores.setStatus(emailAlert, status);
                        }
                    });
                    this.userCulture = culture;
                    this.initialized = true;
                    this.emitChange();
                }
                else {
                    this.emitError('GET_EMAIL_ALERTS_RESPONSE');
                }
                break;
            case ActionTypes.UPDATE_EMAIL_ALERT_RESPONSE:
                if (action.json) {
                    this.emitChange('UPDATE_EMAIL_ALERT_RESPONSE');
                }
                else {
                    this.emitError('UPDATE_EMAIL_ALERT_RESPONSE');
                }
                break;
            case ActionTypes.INSERT_EMAIL_ALERT_RESPONSE:
                if (action.json) {
                    this.emitChange('INSERT_EMAIL_ALERT_RESPONSE');
                }
                else {
                    var error = action.errors.error;
                    this.emitError('INSERT_EMAIL_ALERT_RESPONSE', error);
                }
                break;
            case ActionTypes.GET_EMAIL_ALERT_BY_ID_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    var name = data.name, subscriptionId = data.subscriptionId, status = data.status, pushmailTemplateIdentifier = data.pushmailTemplateIdentifier, cron = data.cron, culture = data.culture, mediatypes = data.mediatypes, sentiments = data.sentiments, subscriptionClipSelectionOrders = data.subscriptionClipSelectionOrders, from = data.from, displayName = data.displayName, countries = data.countries, withActiveLinks = data.withActiveLinks, wordTags = data.wordTags, recipientsIdentifiers = data.recipientsIdentifiers, isExplicitFilter = data.isExplicitFilter, includeNotCategorizedClips = data.includeNotCategorizedClips, sourceNames = data.sourceNames, timeZone = data.timeZone;
                    var emailAlert = this.addEmailAlert({
                        name: name,
                        subscriptionId: subscriptionId,
                        status: status,
                        pushmailTemplateIdentifier: pushmailTemplateIdentifier,
                        cron: cron,
                        culture: culture,
                        mediatypes: mediatypes,
                        sentiments: sentiments,
                        subscriptionClipSelectionOrders: subscriptionClipSelectionOrders,
                        from: from,
                        displayName: displayName,
                        countries: countries,
                        withActiveLinks: withActiveLinks,
                        wordTags: wordTags,
                        recipientsIdentifiers: recipientsIdentifiers,
                        isExplicitFilter: isExplicitFilter,
                        includeNotCategorizedClips: includeNotCategorizedClips,
                        sourceNames: sourceNames,
                        timeZone: timeZone,
                    });
                    if (emailAlert) {
                        EmailAlertsStores.setAlertName(emailAlert, name);
                        EmailAlertsStores.setStatus(emailAlert, status);
                        EmailAlertsStores.setPushmailTemplateIdentifier(emailAlert, pushmailTemplateIdentifier);
                        EmailAlertsStores.setCron(emailAlert, cron);
                        EmailAlertsStores.setSourceNames(emailAlert, sourceNames);
                        EmailAlertsStores.setCulture(emailAlert, culture);
                        EmailAlertsStores.setMediatypes(emailAlert, mediatypes);
                        EmailAlertsStores.setWordTags(emailAlert, wordTags);
                        EmailAlertsStores.setCountries(emailAlert, countries);
                        EmailAlertsStores.setRecipientsIdentifiers(emailAlert, recipientsIdentifiers);
                        EmailAlertsStores.setSentiments(emailAlert, sentiments);
                        EmailAlertsStores.setSubscriptionClipSelectionOrders(emailAlert, subscriptionClipSelectionOrders);
                        EmailAlertsStores.setFrom(emailAlert, from);
                        EmailAlertsStores.setDisplayName(emailAlert, displayName);
                        EmailAlertsStores.setWithActiveLinks(emailAlert, withActiveLinks);
                        EmailAlertsStores.setIsExplicitFilter(emailAlert, isExplicitFilter);
                        EmailAlertsStores.setIncludeNotCategorizedClips(emailAlert, includeNotCategorizedClips);
                        EmailAlertsStores.setTimezone(emailAlert, timeZone);
                    }
                    this.initialized = true;
                    this.emitChange('GET_EMAIL_ALERT_BY_ID_RESPONSE');
                }
                else {
                    this.emitError('GET_EMAIL_ALERT_BY_ID_RESPONSE');
                }
                break;
            case ActionTypes.GET_EMAIL_ALERTS_USER_CLIP_SELECTION_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.userClipSelection = data;
                    this.initialized = true;
                    this.emitChange('GET_EMAIL_ALERTS_USER_CLIP_SELECTION_RESPONSE');
                }
                else {
                    this.emitError('GET_EMAIL_ALERTS_USER_CLIP_SELECTION_RESPONSE');
                }
                break;
            case ActionTypes.GET_EMAIL_ALERTS_PUSHMAIL_LAYOUT_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.pushMailLayout = data;
                    this.initialized = true;
                    this.emitChange('GET_EMAIL_ALERTS_PUSHMAIL_LAYOUT_RESPONSE');
                }
                else {
                    this.emitError('GET_EMAIL_ALERTS_PUSHMAIL_LAYOUT_RESPONSE');
                }
                break;
            case ActionTypes.ACTIVATE_DEACTIVATE_EMAIL_ALERTS_RESPONSE:
                if (action.json) {
                    var model = action.json.model;
                    var subscriptionId_1 = model.subscriptionId, status_1 = model.status;
                    this.emailAlertes = this.emailAlertes.map(function (ea) {
                        return ea.subscriptionId === subscriptionId_1 ? __assign(__assign({}, ea), { status: status_1 }) : ea;
                    });
                    this.emitChange();
                    // this.emitChange('ACTIVATE_EMAIL_ALERTS_RESPONSE');
                }
                else {
                    this.emitError('ERROR_ACTIVATE_EMAIL_ALERTS_RESPONSE');
                }
                break;
            case ActionTypes.DELETE_EMAIL_ALERTS_RESPONSE:
                if (action.json) {
                    var _a = action.json, data = _a.data, model = _a.model;
                    var subscriptionId = model.subscriptionId;
                    this.deleteEmailAlert(subscriptionId);
                    this.emitChange();
                }
                else {
                    this.emitError('ERROR_DELETE_EMAIL_ALERTS_RESPONSE');
                }
                break;
            default:
                break;
        }
    };
    return EmailAlertsStores;
}(BaseStore));
export default new EmailAlertsStores();
