/* eslint-disable consistent-return */
import QueryBuilderStore from 'stores/QueryBuilderStore';
var LOGICAL_OPERATORS = {
    and: 1,
    or: 2,
};
export var extractFieldsFromQuery = function (query, accumulator) {
    if (accumulator === void 0) { accumulator = null; }
    if (!query)
        return [];
    var fields = accumulator || [];
    if (Array.isArray(query.rules)) {
        query.rules.forEach(function (rule) {
            if (!fields.includes(rule.field)) {
                fields.push(rule.field);
            }
            if (rule.isGroup) {
                extractFieldsFromQuery(rule, fields);
            }
        });
    }
    return (fields.length && fields.filter(function (e) { return e; })) || [];
};
export var getRuleById = function (query, id) {
    if (!query || !id)
        return;
    var queryCopy = JSON.parse(JSON.stringify(query));
    var found = null;
    if (queryCopy.id === id) {
        if (queryCopy.rules) {
            delete queryCopy.rules;
        }
        found = queryCopy;
    }
    else if (queryCopy.rules) {
        getRuleById(query, id);
    }
    return found;
};
export var cleanQuery = function (query) {
    if (!query)
        return;
    var q = query;
    if (q.combinator && LOGICAL_OPERATORS[q.combinator.ops]) {
        q.combinator.ops = LOGICAL_OPERATORS[q.combinator.ops];
    }
    for (var index = 0; index < q.rules.length; index += 1) {
        var rule = q.rules[index];
        if (rule && rule.field) {
            var storeField = QueryBuilderStore.getFieldByName(rule.field);
            if (storeField &&
                storeField.isQueryBuilder &&
                storeField.filterElasticFieldPath) {
                rule.field = storeField.filterElasticFieldPath;
            }
        }
        if (rule && rule.ops) {
            rule.ops = parseInt(rule.ops, 10);
        }
        if (rule && rule.combinator) {
            rule.combinator.ops = LOGICAL_OPERATORS[rule.combinator.ops];
        }
        if (rule && Array.isArray(rule.value)) {
            rule.value = rule.value.map(function (val) { return val.value; }).map(String);
        }
        else if (rule && typeof rule.value === 'object' && rule.value !== null) {
            if (rule.ops && [3, 4].includes(rule.ops)) {
                rule.value = [rule.value.value];
            }
            else {
                rule.value = rule.value.value;
            }
        }
        if (rule && rule.rules) {
            cleanQuery(rule);
        }
    }
    return q;
};
export var isQueryValid = function (query) {
    if (!query || !query.rules || !query.rules.length)
        return false;
    var q = query;
    var isValid = true;
    if (q.isGroup && !q.combinator.ops) {
        isValid = false;
        return isValid;
    }
    else if (!q.isGroup && (!q.field || !q.ops)) {
        isValid = false;
        return isValid;
    }
    if (Array.isArray(q.rules)) {
        for (var index = 0; index < q.rules.length; index += 1) {
            var rule = q.rules[index];
            if (!rule.isGroup &&
                (!rule.ops ||
                    !['1', '2', '3', '4', '5', '6'].includes(rule.ops) ||
                    !rule.field)) {
                isValid = false;
                return isValid;
            }
            if (!rule.isGroup &&
                (!rule.ops || !['1', '2'].includes(rule.ops)) &&
                (rule.value === '' ||
                    !rule.value ||
                    (Array.isArray(rule.value) && rule.value.length === 0))) {
                isValid = false;
                return isValid;
            }
            if (rule.rules) {
                isValid = isQueryValid(rule);
            }
        }
    }
    return isValid;
};
