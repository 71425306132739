var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
import * as Utils from '@services/AppUtils';
var TranslationStore = /** @class */ (function (_super) {
    __extends(TranslationStore, _super);
    function TranslationStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    TranslationStore.prototype.initializeState = function () {
        this.countries = [];
        this.languages = [];
        this.sourceTypes = [];
        this.translations = {};
    };
    TranslationStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    TranslationStore.prototype.setCountries = function () {
        this.countries = this.setList('Global.Country.');
    };
    TranslationStore.prototype.setLanguages = function () {
        this.languages = this.setList('Global.Language.');
    };
    TranslationStore.prototype.setList = function (srt) {
        var _this = this;
        return Object.keys(this.translations)
            .filter(function (key) { return key.includes(srt); })
            .map(function (key) { return ({
            label: _this.translations[key],
            value: key.split(srt)[1].toLowerCase(),
        }); })
            .sort(function (a, b) { return Utils.sortStringAlphabetically(a, b, 'label'); });
    };
    TranslationStore.prototype.setSourceTypes = function () {
        this.sourceTypes = this.setList('Global.SourceType.');
    };
    TranslationStore.prototype.getCountryByIsoCode = function (isoCode) {
        var country = this.countries.find(function (_a) {
            var value = _a.value;
            return value === isoCode.toLowerCase();
        });
        return country == null
            ? { label: isoCode.toUpperCase(), value: isoCode }
            : country;
    };
    TranslationStore.prototype.getLanguageByIsoCode = function (isoCode) {
        var language = this.languages.find(function (_a) {
            var value = _a.value;
            return value === isoCode;
        });
        return language == null
            ? { label: isoCode.toUpperCase(), value: isoCode }
            : language;
    };
    TranslationStore.prototype.registerToActions = function (payload) {
        var _a;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_TRANSLATIONS_RESPONSE:
                if ((_a = action.json) === null || _a === void 0 ? void 0 : _a.translations) {
                    this.translations = action.json.translations;
                    this.setCountries();
                    this.setLanguages();
                    this.setSourceTypes();
                    this.initialized = true;
                    this.emitChange(action.json.lang);
                }
                else {
                    this.emitError('GET_TRANSLATIONS');
                }
                break;
            default:
                break;
        }
    };
    return TranslationStore;
}(BaseStore));
export default new TranslationStore();
