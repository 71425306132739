import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Iframe from '@commons/Iframe';
import Svg from '@commons/Svg';
import Divider from '@commons/Divider';
import { Wrapper, Navitem } from './NavbarMobile.styles';
// TODO: Remove when change FB integration
var accountHasSocialMedia = 'true';
// TODO: Remove when we add .env file
var loginUrl = 'https://dev.instarsocial.com/fb/login/reputation?locale=en-GB';
var NavbarMobile = function (_a) {
    var _b, _c;
    var open = _a.open, isNotAgencyPage = _a.isNotAgencyPage, currentPath = _a.currentPath, _d = _a.currentUser, currentUser = _d === void 0 ? null : _d, onLogOut = _a.onLogOut, onClose = _a.onClose;
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(Wrapper, { open: open, children: [isNotAgencyPage && (_jsxs(_Fragment, { children: [_jsx(Navitem, { "$selected": currentPath === 'monitoring', children: _jsx("button", { onClick: onClose, children: _jsxs(Link, { to: "monitoring", children: [_jsx(Svg, { icon: "monitoring", size: "x-large" }), formatMessage({
                                        id: 'Header.navigation.clipping',
                                    })] }) }) }), _jsx(Navitem, { "$selected": currentPath === 'analytics', children: _jsx("button", { onClick: onClose, children: _jsxs(Link, { to: "analytics", children: [_jsx(Svg, { icon: "analytics", size: "x-large" }), formatMessage({
                                        id: 'Header.navigation.Analytics',
                                    })] }) }) }), _jsx(Navitem, { "$selected": currentPath === 'kiosk', children: _jsx("button", { onClick: onClose, children: _jsxs(Link, { to: "kiosk", children: [_jsx(Svg, { icon: "kiosk", size: "x-large" }), formatMessage({
                                        id: 'Header.navigation.kiosque',
                                    })] }) }) }), _jsx(Navitem, { "$selected": currentPath === 'whats-new', children: _jsx("button", { onClick: onClose, children: _jsxs(Link, { to: "whats-new", children: [_jsx(Svg, { icon: "whats-new", size: "x-large" }), formatMessage({
                                        id: 'Delivery.ProductNews.topBar.title',
                                    })] }) }) })] })), _jsx(Divider, { margin: "small", variant: "middle" }), _jsxs("div", { className: "c-account-infos", children: [_jsx("div", { className: "c-account-infos-logo", children: (currentUser &&
                            "".concat((_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName) === null || _b === void 0 ? void 0 : _b.charAt(0)).concat((_c = currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastName) === null || _c === void 0 ? void 0 : _c.charAt(0))) ||
                            '' }), _jsxs("div", { className: "c-account-infos-text", children: [_jsx("span", { children: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.profileName) || '' }), _jsxs("div", { className: "user-firstName-lastName", children: [(currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName) || '', " ", (currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastName) || ''] })] })] }), _jsx(Divider, { margin: "small", variant: "middle" }), isNotAgencyPage && (_jsx(Navitem, { children: _jsx("button", { onClick: onClose, children: _jsx(Link, { to: "settings", children: _jsx(FormattedMessage, { id: "Settings.goToSettings" }) }) }) })), accountHasSocialMedia.toLowerCase() === 'true' && (_jsx(Navitem, { children: _jsxs("div", { children: [_jsx(FormattedMessage, { id: "Settings.increaseSocialMediaCoverage" }), _jsx(Iframe, { className: "iframe_facebook", id: "iframe_facebook", src: loginUrl, title: formatMessage({
                                id: 'Header.accountMenu.facebookIframeTitle',
                            }) })] }) })), _jsx(Divider, { margin: "small", variant: "middle" }), isNotAgencyPage && (_jsx(Navitem, { children: _jsx("button", { onClick: onClose, children: _jsx(Link, { to: "agencies", children: _jsx(FormattedMessage, { id: "Header.accountMenu.backAgencyPage" }) }) }) })), _jsx(Navitem, { children: _jsx("button", { onClick: onLogOut, children: _jsx(FormattedMessage, { id: "Settings.logout" }) }) })] }));
};
export default NavbarMobile;
