var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * Wrapper for the react-select external component.
 * This allows you to configure custom options.
 */
import React from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import Creatable from 'react-select/creatable';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import Svg from '@commons/Svg';
import { Wrapper } from './ReactSelect.styles';
var styles = {
    control: function (base) { return (__assign(__assign({}, base), { border: 'none', boxShadow: 'none', outline: 'none' })); },
    input: function (provided) { return (__assign(__assign({}, provided), { maxWidth: '200px' })); },
    dropdownIndicator: function (base) { return (__assign(__assign({}, base), { color: '#000000' })); },
    indicatorSeparator: function () { return ({ display: 'none' }); },
    option: function (base, state) { return (__assign(__assign({}, base), { background: state.isFocused ? '#eeeeee' : 'transparent', color: '#333333' })); },
};
var ReactSelect = function (_a) {
    var _b = _a.async, async = _b === void 0 ? false : _b, _c = _a.canAddNewTags, canAddNewTags = _c === void 0 ? false : _c, _d = _a.className, className = _d === void 0 ? '' : _d, _e = _a.hideBackground, hideBackground = _e === void 0 ? false : _e, _f = _a.hideBorder, hideBorder = _f === void 0 ? false : _f, classNames = _a.classNames, rest = __rest(_a, ["async", "canAddNewTags", "className", "hideBackground", "hideBorder", "classNames"]);
    var formatMessage = useIntl().formatMessage;
    var DropdownIndicator = function (props) {
        var menuIsOpen = props.selectProps.menuIsOpen;
        return (_jsx(components.DropdownIndicator, __assign({}, props, { children: _jsxs("span", { title: menuIsOpen
                    ? formatMessage({ id: 'Global.collapse' })
                    : formatMessage({ id: 'Global.expand' }), children: [' ', _jsx(Svg, { icon: menuIsOpen ? 'up' : 'down', size: "x-small" })] }) })));
    };
    var customProps = {
        addLabelText: formatMessage({ id: 'ReactSelect.addLabelText' }),
        arrowRenderer: function (_a) {
            var isOpen = _a.isOpen;
            return isOpen ? (_jsx(TiArrowSortedUp, { size: "20", title: formatMessage({ id: 'Global.collapse' }) })) : (_jsx(TiArrowSortedDown, { size: "20", title: formatMessage({ id: 'Global.expand' }) }));
        },
        backspaceToRemoveMessage: formatMessage({
            id: 'ReactSelect.backspaceToRemoveMessage',
        }),
        className: classnames(className, 'react-select__root', { 'select--hide-background': hideBackground }, { 'select--hide-border': hideBorder }),
        classNames: __assign(__assign({}, (classNames || {})), { control: function () { return 'react-select__control'; } }),
        clearable: false,
        clearAllText: formatMessage({ id: 'ReactSelect.clearAllText' }),
        clearValueText: formatMessage({ id: 'ReactSelect.clearValueText' }),
        ignoreAccents: false,
        loadingPlaceholder: formatMessage({
            id: 'ReactSelect.loadingPlaceholder',
        }),
        noResultsText: formatMessage({ id: 'ReactSelect.noResultsText' }),
        searchPromptText: formatMessage({ id: 'ReactSelect.searchPromptText' }),
        styles: styles,
        components: {
            DropdownIndicator: DropdownIndicator,
        },
    };
    var props = __assign(__assign({}, customProps), rest);
    if (canAddNewTags) {
        return (_jsx(Wrapper, { children: _jsx(Creatable, __assign({}, props)) }));
    }
    if (async) {
        return (_jsx(Wrapper, { children: _jsx(AsyncSelect, __assign({}, props)) }));
    }
    return (_jsx(Wrapper, { children: _jsx(Select, __assign({}, props, { required: true })) }));
};
export default ReactSelect;
