import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
import classnames from 'classnames';
import { Wrapper } from './Scroll.styles';
var Scroll = function (_a) {
    var children = _a.children, _b = _a.className, className = _b === void 0 ? '' : _b;
    var scroll = useRef();
    var refScrollCallback = function (ref) {
        scroll.current = ref;
    };
    var scrollClassNames = classnames('scroll-content', 'scrollable--y', className);
    return (_jsx(Wrapper, { className: scrollClassNames, ref: refScrollCallback, children: children }));
};
export default Scroll;
