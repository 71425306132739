var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl, FormattedMessage } from 'react-intl';
import { SnackbarContext } from 'context/SnackbarContext';
import Col from 'commons/Col';
import ReactSelect from 'commons/ReactSelect';
import Row from 'commons/Row';
import Input from 'commons/Input';
import Checkbox from 'commons/Checkbox';
import AdvancedFiltersBody from 'new-components/AdvancedFilters/AdvancedFiltersBody';
import AdvancedFiltersList from 'new-components/AdvancedFilters/AdvancedFiltersList';
import AdvancedFiltersMenu from 'new-components/AdvancedFilters/AdvancedFiltersMenu';
import LibraryReview from 'new-components/LibraryReview';
import parameters from 'constants/parameters';
import ClipSelectionStore from 'stores/ClipSelectionStore';
import SearchStore from 'stores/SearchStore';
import FacetStore from 'stores/FacetStore';
import UserStore from 'stores/UserStore';
import SearchActionCreators from 'actions/SearchActionCreators';
import ReviewStore from 'stores/ReviewStore';
import ReviewActionCreators from 'actions/ReviewActionCreators';
import ClipSelectionActionCreators from 'actions/ClipSelectionActionCreators';
import FacetActionCreators from 'actions/FacetActionCreators';
var propTypes = {
    intl: PropTypes.object.isRequired,
    onSelectionChange: PropTypes.func,
    embedded: PropTypes.bool,
    searchField: PropTypes.string,
};
var defaultProps = {
    embedded: false,
    onSelectionChange: function () { },
    searchField: '',
};
var BREAKPOINT = parameters.BREAKPOINT, FACET_ITEM_KEY_SEPARATOR = parameters.FACET_ITEM_KEY_SEPARATOR;
var AdvancedFiltersTools = /** @class */ (function (_super) {
    __extends(AdvancedFiltersTools, _super);
    function AdvancedFiltersTools(props) {
        var _this = _super.call(this, props) || this;
        _this.onCreateFavoriteSearch = function (type) {
            if (type === 'CREATE_FAVOURITE_SEARCH_RESPONSE') {
                _this.props.handleCloseModal();
            }
        };
        _this.onError = function (type, data) {
            var setSnackbarObj = _this.context.setSnackbarObj;
            var formatMessage = _this.props.intl.formatMessage;
            if (data === null || data === void 0 ? void 0 : data.nameExist) {
                setSnackbarObj({
                    open: true,
                    status: 'error',
                    message: formatMessage({ id: 'Configuration.nameExistsError' }),
                });
            }
        };
        _this.handleGetAllFacetItems = function (newArray) {
            var res = newArray.map(function (elm) {
                return _this.state.selectedMenuElementId + FACET_ITEM_KEY_SEPARATOR + elm.id;
            });
            _this.setState({
                listData: { filters: __spreadArray([], res, true) },
                items: newArray.map(function (elm) { return elm.id; }),
            });
        };
        _this.handleSelectAll = function () {
            _this.handleItemChange(_this.state.items);
        };
        _this.renderAllCategories = function () {
            var _a = _this.state, allClipSelections = _a.allClipSelections, selectedMenuElementId = _a.selectedMenuElementId;
            return selectedMenuElementId === 'clipSelections.id' ? (_jsx(Checkbox, { checked: allClipSelections, onChange: _this.handleSearchAllCategories, children: _jsx(FormattedMessage, { tagName: "span", id: "AdvancedFilters.LoadAllCategoriesCheckboxLabel" }) })) : null;
        };
        _this.tabMenuElements = [];
        // Set the categories tab by default
        _this.defaultMenuElementId = '';
        // this.myRef = React.createRef();
        _this.state = {
            allClipSelections: false,
            loading: false,
            modalIsOpen: false,
            selectedFacetItems: props.mode === 'editFavorite' &&
                props.favoriteSearch.criterias.facetsSelected.length > 0
                ? props.favoriteSearch.criterias.facetsSelected.map(function (elm) { return elm.id + FACET_ITEM_KEY_SEPARATOR + elm.value; })
                : JSON.parse(localStorage.getItem('searchModel')).facetsSelected,
            selectedMenuElementId: _this.defaultMenuElementId,
            windowWidth: window.innerWidth,
            menuElements: [],
            dropDownElements: [],
            searchFieldByName: '',
            searchField: '',
            listData: { filters: [] },
            items: [],
            selectedFacetsOfMenuId: [],
        };
        _this.lastMenuElementId = _this.defaultMenuElementId;
        _this.onChange = _this.onChange.bind(_this);
        _this.onGetMenu = _this.onGetMenu.bind(_this);
        _this.onSearchChanged = _this.onSearchChanged.bind(_this);
        _this.openDefaultMenuElement = _this.openDefaultMenuElement.bind(_this);
        _this.openReviewModal = _this.openReviewModal.bind(_this);
        _this.getOpenState = _this.getOpenState.bind(_this);
        _this.handleCloseModal = _this.handleCloseModal.bind(_this);
        _this.handleItemChange = _this.handleItemChange.bind(_this);
        _this.handleMenuElementClick = _this.handleMenuElementClick.bind(_this);
        _this.handleReviewChange = _this.handleReviewChange.bind(_this);
        _this.handleSearchAllCategories = _this.handleSearchAllCategories.bind(_this);
        _this.handleValidationClick = _this.handleValidationClick.bind(_this);
        _this.onResize = _this.onResize.bind(_this);
        _this.handleAddFilter = _this.handleAddFilter.bind(_this);
        _this.handleRemoveFilter = _this.handleRemoveFilter.bind(_this);
        _this.handleChangeSearchByValue = _this.handleChangeSearchByValue.bind(_this);
        _this.handleChangeSearchByName = _this.handleChangeSearchByName.bind(_this);
        _this.onGetInsightFacetItemsCallback =
            _this.onGetInsightFacetItemsCallback.bind(_this);
        ReviewStore.addChangeListener(_this.onChange);
        ClipSelectionStore.addChangeListener(_this.onChange);
        SearchStore.addGetAdvancedFilterMenuListener(_this.onGetMenu);
        FacetStore.addChangeListener(_this.onChange);
        FacetStore.addGetInsightFacetItemsListener(_this.onGetInsightFacetItemsCallback);
        SearchStore.addChangeListener(_this.onCreateFavoriteSearch);
        SearchStore.addErrorListener(_this.onError);
        return _this;
    }
    AdvancedFiltersTools.prototype.componentDidMount = function () {
        var openModalButton = document.getElementById('advanced-search-button');
        var showReviewButton = document.getElementById('show-review-button');
        if (openModalButton != null) {
            openModalButton.addEventListener('click', this.openDefaultMenuElement);
        }
        if (showReviewButton != null) {
            showReviewButton.addEventListener('click', this.openReviewModal);
        }
        window.addEventListener('resize', this.onResize);
        this.onGetMenu();
    };
    // TODO: remove unused code
    AdvancedFiltersTools.getDerivedStateFromProps = function (nextProps) {
        var filters = nextProps.filters;
        var facets = [];
        // const { dropDownElements, menuElements } = this.state;
        // const tempMenuElements =
        //   (Array.isArray(menuElements) && menuElements.filter(e => e)) || [];
        // let tempDropDownElements = dropDownElements;
        if (filters &&
            typeof filters === 'object' &&
            Object.keys(filters).length > 0) {
            Object.keys(filters).forEach(function (key) {
                if (key !== 'deliveredDate' && key !== 'PublicationDate') {
                    var values = filters[key];
                    if (Array.isArray(values)) {
                        values.forEach(function (value) {
                            facets.push(key + FACET_ITEM_KEY_SEPARATOR + value);
                        });
                    }
                }
            });
            return {
                selectedFacetItems: facets,
            };
        }
        else if (filters && Object.keys(filters).length === 0) {
            return {
                selectedFacetItems: [],
            };
        }
        return null;
    };
    AdvancedFiltersTools.prototype.componentWillUnmount = function () {
        var openModalButton = document.getElementById('advanced-search-button');
        var showReviewButton = document.getElementById('show-review-button');
        if (openModalButton != null) {
            openModalButton.removeEventListener('click', this.openDefaultMenuElement);
        }
        if (showReviewButton != null) {
            showReviewButton.removeEventListener('click', this.openReviewModal);
        }
        ClipSelectionStore.removeChangeListener(this.onChange);
        FacetStore.removeChangeListener(this.onChange);
        window.removeEventListener('resize', this.onResize);
        FacetStore.removeGetInsightFacetItemsListener(this.onGetInsightFacetItemsCallback);
        ReviewStore.removeChangeListener(this.onChange);
        SearchStore.removeChangeListener(this.onCreateFavoriteSearch);
        SearchStore.removeGetAdvancedFilterMenuListener(this.onGetMenu);
        SearchStore.removeErrorListener(this.onError);
    };
    AdvancedFiltersTools.prototype.onChange = function () {
        this.setState({ loading: false });
    };
    AdvancedFiltersTools.prototype.onGetInsightFacetItemsCallback = function () {
        this.forceUpdate();
    };
    AdvancedFiltersTools.prototype.onGetMenu = function () {
        var _this = this;
        var selectedMenuElementId = this.state.selectedMenuElementId;
        var formatMessage = this.props.intl.formatMessage;
        var menuElements = [];
        var dropDownElements = [];
        var menu = SearchStore.advancedFilterMenu;
        this.tabMenuElements = [];
        this.tabMenuElements = Array.from(new Set(__spreadArray(__spreadArray([], this.tabMenuElements, true), menu.map(function (_a) {
            var dimensionId = _a.dimensionId, bundle = _a.bundle, isTranslated = _a.isTranslated, icon = _a.icon, filterWidgetSelected = _a.filterWidgetSelected, filterWidgetPosition = _a.filterWidgetPosition, filterFacetSelected = _a.filterFacetSelected, filterElasticFieldName = _a.filterElasticFieldName, filterElasticFieldPath = _a.filterElasticFieldPath, filterScope = _a.filterScope, forFacetFilter = _a.forFacetFilter, forAdvancedFacetFilter = _a.forAdvancedFacetFilter, forWidgetFilter = _a.forWidgetFilter;
            return ({
                dimensionId: dimensionId,
                bundle: bundle,
                isTranslated: isTranslated,
                icon: icon,
                id: filterElasticFieldPath,
                label: bundle,
                filterWidgetSelected: filterWidgetSelected,
                filterWidgetPosition: filterWidgetPosition,
                filterFacetSelected: filterFacetSelected,
                filterElasticFieldName: filterElasticFieldName,
                filterScope: filterScope,
                forFacetFilter: forFacetFilter,
                forAdvancedFacetFilter: forAdvancedFacetFilter,
                forWidgetFilter: forWidgetFilter,
            });
        }), true)));
        this.tabMenuElements = this.tabMenuElements.sort(function (a, b) { return a.filterWidgetPosition - b.filterWidgetPosition; });
        var getActiveItem = function (menuItem) {
            if (selectedMenuElementId === '') {
                if (_this.lastMenuElementId === '' && _this.defaultMenuElementId === '') {
                    _this.lastMenuElementId = menuItem.id;
                    _this.defaultMenuElementId = menuItem.id;
                    return true;
                }
                return false;
            }
            if (selectedMenuElementId === menuItem) {
                return true;
            }
            return false;
        };
        menuElements = this.tabMenuElements
            .filter(function (menuItem, index) {
            return menuItem.forWidgetFilter && menuItem.filterWidgetSelected;
        })
            .map(function (menuItem, index) { return (__assign(__assign({}, menuItem), { isActive: getActiveItem(menuItem, index) })); });
        dropDownElements = this.tabMenuElements
            .filter(function (menuItem, index) {
            return menuItem.forWidgetFilter && !menuItem.filterWidgetSelected;
        })
            .map(function (menuItem, index) { return (__assign(__assign({}, menuItem), { label: menuItem.isTranslated
                ? formatMessage({
                    id: menuItem.bundle || 'Global.ok',
                })
                : menuItem.filterElasticFieldName })); });
        this.setState({
            selectedMenuElementId: this.defaultMenuElementId,
            defaultMenuElementId: this.defaultMenuElementId,
            loading: false,
            menuElements: menuElements,
            dropDownElements: dropDownElements,
        }, function () { });
    };
    AdvancedFiltersTools.prototype.onResize = function (e) {
        this.setState({ windowWidth: e.target.innerWidth });
    };
    AdvancedFiltersTools.prototype.onSearchChanged = function (nameField) {
        this.handleMenuElementClick(nameField);
    };
    AdvancedFiltersTools.prototype.getLastMenuElementId = function () {
        return this.lastMenuElementId;
    };
    AdvancedFiltersTools.prototype.getOpenState = function () {
        var facetsSelected = SearchStore.buildSearchModel().facetsSelected;
        return {
            modalIsOpen: true,
            selectedFacetItems: facetsSelected,
        };
    };
    AdvancedFiltersTools.prototype.getUserFiltersPreferences = function () {
        var menuElements = this.state.menuElements;
        return menuElements;
    };
    AdvancedFiltersTools.prototype.openDefaultMenuElement = function () {
        this.handleMenuElementClick(this.defaultMenuElementId, this.getOpenState());
    };
    AdvancedFiltersTools.prototype.openReviewModal = function () {
        this.handleMenuElementClick('source.review', this.getOpenState());
    };
    AdvancedFiltersTools.prototype.handleAddFilter = function (value) {
        var _this = this;
        var menuElements = this.state.menuElements;
        var tempMenuElements = menuElements;
        tempMenuElements.push(value);
        this.setState({
            menuElements: tempMenuElements,
            dropDownElements: this.state.dropDownElements.filter(function (val) { return val !== value; }),
        }, function () {
            _this.handleMenuElementClick(value.id);
            _this.forceUpdate();
        });
    };
    AdvancedFiltersTools.prototype.handleRemoveFilter = function (value) {
        var _a = this.state, menuElements = _a.menuElements, dropDownElements = _a.dropDownElements;
        var formatMessage = this.props.intl.formatMessage;
        var tempDropDownElements = dropDownElements;
        var removedIndex = 0;
        var tempMenuElements = menuElements.filter(function (val, index) {
            if (val.id === value.id) {
                if (index < menuElements.length) {
                    removedIndex = index;
                }
                else {
                    removedIndex = index - 1;
                }
            }
            return val.id !== value.id;
        });
        var element = tempMenuElements[removedIndex - 1];
        tempDropDownElements.push(value);
        tempDropDownElements.forEach(function (item) {
            item.label = item.isTranslated
                ? formatMessage({ id: item.bundle })
                : item.filterElasticFieldName;
        });
        this.setState({
            menuElements: tempMenuElements,
            dropDownElements: tempDropDownElements,
            selectedMenuElementId: (element === null || element === void 0 ? void 0 : element.id) || (tempMenuElements.length > 0 && tempMenuElements[0].id),
        });
    };
    AdvancedFiltersTools.prototype.handleCloseModal = function () {
        this.setState({
            allClipSelections: false,
            modalIsOpen: false,
            selectedFacetItems: [],
        });
    };
    AdvancedFiltersTools.prototype.handleItemChange = function (id) {
        var _this = this;
        var _a = this.state, selectedMenuElementId = _a.selectedMenuElementId, allClipSelections = _a.allClipSelections;
        var onSelectionChange = this.props.onSelectionChange;
        this.setState({ listData: { filters: [] } });
        var ids = Array.isArray(id) ? id : [id];
        this.setState(function (prevState) {
            var newFacetItemKeys = ids.map(function (singleId) { return selectedMenuElementId + FACET_ITEM_KEY_SEPARATOR + singleId; });
            var isAlreadyAdded = newFacetItemKeys.some(function (newFacetItemKey) {
                return prevState.selectedFacetItems.includes(newFacetItemKey);
            });
            var addFacetItemsIdForSelectedMenu = function () { return newFacetItemKeys; };
            var removeFacetItemsIdForSelectedMenu = function () {
                return prevState.selectedFacetsOfMenuId.filter(function (facetItemKey) {
                    return newFacetItemKeys.every(function (newFacetItemKey) { return newFacetItemKey !== facetItemKey; });
                });
            };
            var addFacetItemIds = function () { return __spreadArray(__spreadArray([], prevState.selectedFacetItems, true), newFacetItemKeys, true); };
            var removeFacetItemIds = function () {
                return prevState.selectedFacetItems.filter(function (facetItemKey) {
                    return newFacetItemKeys.every(function (newFacetItemKey) { return newFacetItemKey !== facetItemKey; });
                });
            };
            if (!isAlreadyAdded) {
                _this.setState({
                    countFacetItems: prevState.selectedFacetItems.length + ids.length,
                });
            }
            else {
                _this.setState({
                    countFacetItems: prevState.countFacetItems - ids.length,
                });
            }
            var selectedFacet = {
                selectedFacetItems: isAlreadyAdded
                    ? removeFacetItemIds()
                    : addFacetItemIds(),
                selectedFacetsOfMenuId: isAlreadyAdded
                    ? removeFacetItemsIdForSelectedMenu()
                    : addFacetItemsIdForSelectedMenu(),
            };
            if (typeof onSelectionChange === 'function') {
                onSelectionChange(selectedMenuElementId, ids, isAlreadyAdded ? 'remove' : 'add', allClipSelections);
            }
            _this.props.getNumberFilter(selectedFacet.selectedFacetItems.length);
            return selectedFacet;
        });
    };
    AdvancedFiltersTools.prototype.handleMenuElementClick = function (menuElementId, state, dates) {
        if (state === void 0) { state = {}; }
        if (dates === void 0) { dates = null; }
        var _a = this.props, embedded = _a.embedded, favoriteSeachFormState = _a.favoriteSeachFormState;
        this.lastMenuElementId = menuElementId;
        var menuId = typeof menuElementId === 'string'
            ? (menuElementId === 'country' && 'countryIsoCode2') || menuElementId
            : menuElementId.value;
        var facetGroup = FacetStore.getFacetGroupById(menuId);
        var selectedFacetElement = SearchStore.advancedFilterMenu.find(function (el) { return el.filterElasticFieldPath === menuId || el.dimensionId === menuId; });
        var hasAllFacetItems = facetGroup == null ? false : facetGroup.hasAllFacetItems;
        var hasLoading = menuId === 'source.review' && embedded ? false : !hasAllFacetItems;
        if (selectedFacetElement &&
            selectedFacetElement.filterElasticFieldPath !== 'source.review') {
            var searchModel = {};
            searchModel = JSON.parse(JSON.stringify(__assign({}, SearchStore.buildSearchModel())));
            if (dates &&
                typeof dates === 'object' &&
                dates.universalDateFrom &&
                dates.universalDateTo) {
                searchModel.searchModel.UniversalDateFrom = dates.universalDateFrom;
                searchModel.searchModel.UniversalDateTo = dates.universalDateTo;
            }
            var _b = JSON.parse(localStorage.getItem('searchModel')).searchModel, DateFrom = _b.DateFrom, DateTo = _b.DateTo, logicalDate = _b.logicalDate, UniversalDateTo = _b.UniversalDateTo, UniversalDateFrom = _b.UniversalDateFrom;
            FacetActionCreators.getInsightFacetItems(__assign(__assign({}, searchModel), { lastPeriod: JSON.stringify({
                    from: !searchModel.searchModel.DateFrom
                        ? DateFrom
                        : searchModel.searchModel.DateFrom,
                    to: !searchModel.searchModel.DateTo
                        ? DateTo
                        : searchModel.searchModel.DateTo,
                    logicalDate: !searchModel.searchModel.DateFrom && !searchModel.searchModel.DateTo
                        ? logicalDate
                        : searchModel.searchModel.DateTo,
                }), searchModel: __assign(__assign({}, searchModel.searchModel), { DateFrom: !searchModel.searchModel.DateFrom
                        ? DateFrom
                        : searchModel.searchModel.DateFrom, DateTo: !searchModel.searchModel.DateTo
                        ? DateTo
                        : searchModel.searchModel.logicalDate, logicalDate: !searchModel.searchModel.DateTo
                        ? logicalDate
                        : searchModel.searchModel.logicalDate, UniversalDateFrom: !favoriteSeachFormState.dateFilterActive
                        ? UniversalDateFrom
                        : searchModel.searchModel.UniversalDateFrom, UniversalDateTo: !favoriteSeachFormState.dateFilterActive
                        ? UniversalDateTo
                        : searchModel.searchModel.UniversalDateTo, displayAllElements: false }), selectedFacetFilter: selectedFacetElement }));
        }
        else if (!embedded) {
            var model = {
                universalDateFrom: moment().startOf('day').format('YYYYMMDDHHmmss'),
                UniversalDateTo: moment().endOf('day').format('YYYYMMDDHHmmss'),
            };
            var reviewModel = ReviewStore.reviewModel;
            ReviewActionCreators.getReviews(Object.keys(reviewModel.model).length === 0 ? model : reviewModel.model);
            // ReviewActionCreators.getReviews(model);
        }
        this.setState(__assign(__assign({}, state), { loading: hasLoading, selectedMenuElementId: selectedFacetElement.filterElasticFieldPath }));
    };
    AdvancedFiltersTools.prototype.handleReviewChange = function (_a) {
        var hash = _a.hash, title = _a.title;
        var updateFilter = this.props.updateFilter;
        var setDraftId = SearchStore.setDraftId, setHash = SearchStore.setHash, clearAllFacets = SearchStore.clearAllFacets;
        this.handleCloseModal();
        setDraftId(null);
        setHash(hash);
        clearAllFacets();
        var favoriteSearchCreateButtonElement = document.getElementById('favorite-search-create-button');
        if (favoriteSearchCreateButtonElement != null) {
            favoriteSearchCreateButtonElement.style.display = 'none';
        }
        updateFilter('review');
        var selectboxValue = document.getElementById('textfilter');
        if (selectboxValue != null) {
            selectboxValue.textContent = title;
        }
    };
    AdvancedFiltersTools.prototype.handleSearchAllCategories = function () {
        var allClipSelections = this.state.allClipSelections;
        var newState = { allClipSelections: !allClipSelections };
        if (!allClipSelections && !ClipSelectionStore.isInit()) {
            ClipSelectionActionCreators.getClipSelections();
            newState.loading = true;
        }
        this.setState(newState);
    };
    AdvancedFiltersTools.prototype.handleValidationClick = function () {
        var _a = this.props, mode = _a.mode, favoriteSeachFormState = _a.favoriteSeachFormState, favoriteSearch = _a.favoriteSearch, shareWithCoworker = _a.shareWithCoworker, updateFilter = _a.updateFilter;
        var _b = this.state, allClipSelections = _b.allClipSelections, selectedFacetItems = _b.selectedFacetItems;
        var addFacet = SearchStore.addFacet, getSelectedFacetsObjectsArray = SearchStore.getSelectedFacetsObjectsArray, clearAllFacets = SearchStore.clearAllFacets;
        var facetItemsInSearch = getSelectedFacetsObjectsArray();
        var getFacetItem = function (key) {
            var _a = key.split(FACET_ITEM_KEY_SEPARATOR), facetGroupId = _a[0], facetItemId = _a[1];
            var facetGroup = FacetStore.getFacetGroupById(facetGroupId);
            var facetItemsInStore = facetGroup === null || facetGroup === void 0 ? void 0 : facetGroup.facetItems;
            var facetItemIdsInStore = facetItemsInStore === null || facetItemsInStore === void 0 ? void 0 : facetItemsInStore.map(function (_a) {
                var id = _a.id;
                return id;
            });
            var allFacetItems = facetItemsInSearch.reduce(function (previous, _a) {
                var id = _a.id, text = _a.text, value = _a.value;
                return id === facetGroupId &&
                    facetItemIdsInStore &&
                    facetItemIdsInStore.length > 0 &&
                    !facetItemIdsInStore.includes(value)
                    ? __spreadArray(__spreadArray([], previous, true), [{ id: value, name: text }], false) : previous;
            }, facetItemsInStore);
            return allFacetItems === null || allFacetItems === void 0 ? void 0 : allFacetItems.find(function (_a) {
                var name = _a.name, id = _a.id;
                return name === facetItemId || id === facetItemId;
            });
        };
        SearchStore.clearWithoutQueryBuilder();
        // clearAllFacets();
        selectedFacetItems.forEach(function (key) {
            var _a = key.split(FACET_ITEM_KEY_SEPARATOR), facetGroupId = _a[0], facetItemId = _a[1];
            var facetName = getFacetItem(key);
            var name = allClipSelections && facetGroupId === 'clipSelections.id'
                ? ClipSelectionStore.getClipSelectionById(facetItemId).name
                : ((facetName === null || facetName === void 0 ? void 0 : facetName.name) && facetName.name) || '';
            addFacet({
                id: facetGroupId,
                text: name,
                value: facetItemId,
            });
        });
        // update searchDate
        var initialSerchModel = SearchStore.buildSearchModel({
            favoriteSearch: true,
        });
        if (favoriteSeachFormState.dateFilterActive &&
            initialSerchModel.searchModel.logicalDate === '1') {
            var dates = {};
            var todayFrom = moment().startOf('day');
            var todayTo = moment();
            dates.universalDateFrom = todayFrom.format('YYYY-MM-DD HH:mm:ss');
            dates.universalDateTo = todayTo.format('YYYY-MM-DD HH:mm:ss');
            var utcDates = window.getDatesInUserTZ('1', dates);
            var UniversalDateFrom = utcDates.universalDateFrom;
            var UniversalDateTo = utcDates.universalDateTo;
            var DateFrom = dates.universalDateFrom;
            var DateTo = dates.universalDateTo;
            initialSerchModel = __assign(__assign({}, initialSerchModel), { searchModel: __assign(__assign({}, searchModel), { DateFrom: DateFrom, DateTo: DateTo, UniversalDateFrom: UniversalDateFrom, UniversalDateTo: UniversalDateTo, logicalDate: '1', DateType: initialSerchModel.searchModel.DateType, favoriteSearchId: favoriteSeachFormState.favoriteSearchId, favoriteSearchName: favoriteSeachFormState.favoriteSearchName }) });
        }
        var serchModelWithDateOption = __assign(__assign({}, initialSerchModel), { searchModel: __assign(__assign({}, initialSerchModel.searchModel), { DateFrom: favoriteSeachFormState.dateFilterActive
                    ? initialSerchModel.searchModel.DateFrom
                    : null, DateTo: favoriteSeachFormState.dateFilterActive
                    ? initialSerchModel.searchModel.DateTo
                    : null, UniversalDateFrom: favoriteSeachFormState.dateFilterActive
                    ? initialSerchModel.searchModel.UniversalDateFrom
                    : null, UniversalDateTo: favoriteSeachFormState.dateFilterActive
                    ? initialSerchModel.searchModel.UniversalDateTo
                    : null, logicalDate: favoriteSeachFormState.dateFilterActive
                    ? initialSerchModel.searchModel.logicalDate
                    : '', DateType: favoriteSeachFormState.dateFilterActive
                    ? initialSerchModel.searchModel.DateType
                    : null, favoriteSearchId: favoriteSeachFormState.favoriteSearchId, favoriteSearchName: favoriteSeachFormState.favoriteSearchName }) });
        var initialSearchModel = JSON.parse(localStorage.getItem('searchModel'));
        var searchModel = JSON.stringify(serchModelWithDateOption);
        var model = {};
        if (mode === 'saveFavorite') {
            model = {
                criterias: searchModel,
                id: 0,
                name: favoriteSeachFormState.favoriteSearchName,
                type: 0,
                ShareWithAll: shareWithCoworker,
            };
            SearchActionCreators.createFavoriteSearch(model);
            SearchStore.setSearchModelProperties(initialSearchModel, 'advancedFilterTools');
        }
        else if (mode === 'editFavorite') {
            model = {
                criterias: searchModel,
                id: favoriteSearch.id,
                name: favoriteSeachFormState.favoriteSearchName,
                type: favoriteSearch.Type,
                ShareWithAll: shareWithCoworker,
            };
            if (serchModelWithDateOption.searchModel.favoriteSearchId ===
                favoriteSeachFormState.favoriteSearchId) {
                SearchActionCreators.updateFavoriteSearch(model, function () { return updateFilter(); });
            }
            else if (!serchModelWithDateOption.searchModel.favoriteSearchId &&
                !SearchStore.getSelectedFavouriteSearch()) {
                SearchStore.clearAllFacets();
                initialSearchModel.facetsSelected.forEach(function (key) {
                    var _a = key.split(FACET_ITEM_KEY_SEPARATOR), facetGroupId = _a[0], facetItemId = _a[1];
                    var facetName = getFacetItem(key);
                    var name = allClipSelections && facetGroupId === 'clipSelections.id'
                        ? ClipSelectionStore.getClipSelectionById(facetItemId).name
                        : ((facetName === null || facetName === void 0 ? void 0 : facetName.name) && facetName.name) || '';
                    addFacet({
                        id: facetGroupId,
                        text: name,
                        value: facetItemId,
                    });
                });
                SearchStore.setSearchModelProperties(initialSearchModel, 'advancedFilterTools');
            }
            else {
                SearchActionCreators.updateFavoriteSearch(model);
                clearAllFacets();
            }
        }
        this.props.handleCloseModal();
    };
    AdvancedFiltersTools.prototype.handleChangeSearchByValue = function (value) {
        this.setState({ searchField: value });
    };
    AdvancedFiltersTools.prototype.handleChangeSearchByName = function (value) {
        this.setState({ searchFieldByName: value });
    };
    AdvancedFiltersTools.prototype.renderList = function () {
        var _a = this.state, allClipSelections = _a.allClipSelections, loading = _a.loading, selectedFacetItems = _a.selectedFacetItems, selectedMenuElementId = _a.selectedMenuElementId, searchField = _a.searchField;
        return (_jsx(AdvancedFiltersList
        // searchField={favoriteSeachFormState.searchField}
        , { 
            // searchField={favoriteSeachFormState.searchField}
            allClipSelections: allClipSelections, loading: loading && FacetStore.isInit(), onItemChange: this.handleItemChange, onSearchAllCategories: this.handleSearchAllCategories, selectedFacetItems: selectedFacetItems, selectedMenuElementId: selectedMenuElementId, searchField: searchField, handleGetAllFacetItems: this.handleGetAllFacetItems }));
    };
    AdvancedFiltersTools.prototype.renderLibraryReview = function () {
        var _a = this.props, onSelectionChange = _a.onSelectionChange, embedded = _a.embedded, filters = _a.filters;
        return (_jsx("div", { children: _jsx(LibraryReview, { onCancel: this.handleCloseModal, onReviewChange: this.handleReviewChange, onSelectionChange: onSelectionChange, mode: "advancedFiltertools", selectedReview: (filters === null || filters === void 0 ? void 0 : filters['source.review']) || '', shouldFetch: true, embedded: embedded }) }));
    };
    AdvancedFiltersTools.prototype.render = function () {
        var _a, _b;
        var _c = this.state, listData = _c.listData, selectedMenuElementId = _c.selectedMenuElementId, windowWidth = _c.windowWidth, selectedFacetItems = _c.selectedFacetItems, searchFieldByName = _c.searchFieldByName, searchField = _c.searchField, selectedFacetsOfMenuId = _c.selectedFacetsOfMenuId;
        var formatMessage = this.props.intl.formatMessage;
        if (selectedMenuElementId === '')
            return null;
        var menuElements = this.tabMenuElements
            .map(function (tabMenuElement) { return (__assign(__assign({}, tabMenuElement), { isActive: tabMenuElement.id === selectedMenuElementId })); })
            .filter(function (el) { return !el.forFacetFilterpe; });
        var currentMenuElement = this.tabMenuElements.find(function (_a) {
            var id = _a.id;
            return id === selectedMenuElementId;
        }) || { label: '', icon: '' };
        var selectOptions = this.tabMenuElements.map(function (_a) {
            var dimensionId = _a.dimensionId, bundle = _a.bundle, filterElasticFieldName = _a.filterElasticFieldName;
            return ({
                label: bundle ? formatMessage({ id: bundle }) : filterElasticFieldName,
                value: dimensionId,
            });
        });
        var getFilterBodyTitle = function () {
            var accountCurrency = UserStore.getCurrentUser().accountCurrency;
            var result = '';
            if (currentMenuElement.isTranslated) {
                result = formatMessage({ id: currentMenuElement.bundle });
            }
            else {
                result = currentMenuElement.filterElasticFieldName;
            }
            result +=
                selectedMenuElementId === 'mediaValue' ? " ".concat(accountCurrency) : '';
            return result;
        };
        var countFacetItems = selectedFacetItems.length;
        var menuElmForFilterMenue = menuElements.filter(function (elm) { return elm.forAdvancedFacetFilter; });
        var isCheckAll = (_a = listData === null || listData === void 0 ? void 0 : listData.filters) === null || _a === void 0 ? void 0 : _a.some(function (element) {
            return selectedFacetItems.includes(element);
        });
        var isIndeterminate = isCheckAll &&
            ((_b = listData.filters) === null || _b === void 0 ? void 0 : _b.some(function (element) { return !selectedFacetItems.includes(element); }));
        return (_jsx("div", { className: "c-advanced-filters", children: _jsxs(Row, { gutter: 0, className: "advanced-row", children: [_jsxs(Col, { md: 3, className: "c-advanced-filters-menu-column new-scrollbar", children: [_jsx("div", { className: "searchFieldByName-container", children: _jsx(Input, { iconLeft: "search", className: "searchFieldByName", onChange: this.handleChangeSearchByName, placeholder: formatMessage({
                                        id: 'FavoriteSearch.favoriteSearchModal.favoriteSearchFormPlaceholder.searchField',
                                    }), type: "text", value: searchFieldByName, hideBackground: true }) }), windowWidth > BREAKPOINT.md ? (_jsx(AdvancedFiltersMenu, { menuElements: menuElmForFilterMenue, selectedFacetItems: selectedFacetItems || [], onMenuElementClick: this.handleMenuElementClick, searchFieldByName: searchFieldByName })) : (_jsx("nav", { className: "c-advanced-filters__nav-select", children: _jsx(ReactSelect, { onChange: this.handleMenuElementClick, options: selectOptions, searchable: false, value: selectOptions.map(function (item) { return item.value === selectedMenuElementId; }) }) }))] }), _jsxs(Col, { md: 9, className: "u-full-height-advanced-filter", children: [_jsxs("div", { className: "searchFieldByValue-container ".concat(selectedMenuElementId === 'source.review'
                                    ? 'hidden-SearchInput'
                                    : ''), children: [_jsx(Checkbox, { checked: isCheckAll, indeterminate: isIndeterminate, onChange: this.handleSelectAll, children: _jsx("span", { children: formatMessage({
                                                id: "".concat(isIndeterminate || isCheckAll ? 'Global.clearAll' : 'TopBarSecond.selectAllButtonLabelMobile'),
                                            }) }) }), this.renderAllCategories(), _jsx(Input, { iconLeft: "search", className: "searchFieldByValue", onChange: this.handleChangeSearchByValue, placeholder: formatMessage({
                                            id: 'FavoriteSearch.favoriteSearchModal.favoriteSearchFormPlaceholder.searchFieldByValue',
                                        }), type: "text", value: searchField, hideBackground: true })] }), _jsx(AdvancedFiltersBody, { icon: currentMenuElement.icon, children: selectedMenuElementId === 'source.review'
                                    ? this.renderLibraryReview()
                                    : this.renderList() })] })] }) }));
    };
    AdvancedFiltersTools.contextType = SnackbarContext;
    return AdvancedFiltersTools;
}(Component));
AdvancedFiltersTools.propTypes = propTypes;
AdvancedFiltersTools.defaultProps = defaultProps;
export default injectIntl(AdvancedFiltersTools, { forwardRef: true });
