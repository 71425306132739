var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Fragment, useEffect, useState, useRef, useContext, } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AiOutlineSave } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { SweetPopupContext } from 'context/SweetPopupContext';
import Modal, { ModalBody, ModalHeader, ModalFooter } from 'commons/Modal';
import Button from 'commons/Button';
import DropDownSelect from 'commons/DropDownSelect';
import Checkbox from 'commons/Checkbox';
import Loader from 'commons/TinyLoader';
import Svg from 'commons/Svg';
import { ClickAwayListener } from 'commons';
import FavoriteSearch from 'new-components/FavoriteSearch';
import { getDatesInUserTZ } from 'services/DateUtils';
import SearchActionCreators from 'actions/SearchActionCreators';
import SearchStore from 'stores/SearchStore';
import Input from 'commons/Input';
import FavoriteSearchItem from './FavoriteSearchItem';
import { Wrapper } from './FavouriteSearch.styles';
import { GlobaStyles } from '../../../TopBarFilter/AppliedFilters/AppliedFilters.styles';
var propTypes = {
    updateFilter: PropTypes.func.isRequired,
};
var DATES_MODES = {
    1: 'today',
    2: 'yesterday',
    3: 'lastweek',
    4: 'lastmonth',
    5: 'custom',
    6: 'lastquarter',
};
function FavouriteSearch(_a) {
    var updateFilter = _a.updateFilter;
    var formatMessage = useIntl().formatMessage;
    var myRef = useRef(null);
    var beingEditedId = useRef(null);
    var fromInside = useRef(false);
    var setSweetPopup = useContext(SweetPopupContext).setSweetPopup;
    var _b = useState({
        selectedSearch: null,
        expandedDropDown: false,
        favouriteSearchLoading: false,
        modalIsOpen: false,
        favouriteSearchtoEdit: {},
        filterSelectedNumber: null,
        shareWithCoworker: '',
    }), state = _b[0], setState = _b[1];
    var selectedSearch = state.selectedSearch, expandedDropDown = state.expandedDropDown, favouriteSearchLoading = state.favouriteSearchLoading, modalIsOpen = state.modalIsOpen, favouriteSearchtoEdit = state.favouriteSearchtoEdit, filterSelectedNumber = state.filterSelectedNumber, shareWithCoworker = state.shareWithCoworker;
    var _c = useState(''), searchFavorite = _c[0], setFavoriteSearch = _c[1];
    var _d = useState([]), favSearchesList = _d[0], setFavSearchesList = _d[1];
    useEffect(function () {
        SearchStore.addSearchObjectUpdateListener(onSearchModelUpdate);
        SearchStore.addChangeListener(onSearchChange);
        return function () {
            SearchStore.removeSearchObjectUpdateListener(onSearchModelUpdate);
            SearchStore.removeChangeListener(onSearchChange);
        };
    }, []);
    var onSearchModelUpdate = function () {
        var fav = SearchStore.getSelectedFavouriteSearch();
        if (!fav) {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedSearch: null })); });
        }
    };
    var onSearchChange = function () {
        var searchModel = SearchStore.searchModel;
        var favouriteSearch = null;
        if (searchModel.favoriteSearchId || searchModel.favoriteSearchName) {
            favouriteSearch = {
                Id: searchModel.favoriteSearchId,
                Name: searchModel.favoriteSearchName,
            };
        }
        var favoriteList = SearchStore.getFavouriteSearches();
        setFavoriteSearch('');
        setFavSearchesList(favoriteList);
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedSearch: favouriteSearch, favouriteSearchLoading: false })); });
    };
    var handleOnClick = function () {
        var fsLoading = false;
        if (!SearchStore.isInitialized('favouriteSearches')) {
            fsLoading = true;
            SearchStore.setShouldGetClips(false);
            SearchActionCreators.getFavouriteSearches();
            fromInside.current = true;
        }
        else {
            setFavSearchesList(SearchStore.getFavouriteSearches());
        }
        setState(function (prevState) { return (__assign(__assign({}, prevState), { favouriteSearchLoading: fsLoading, expandedDropDown: true })); });
    };
    var handleItemClick = function (id) { return function (e) {
        e.stopPropagation();
        if (id) {
            var favouriteSearch_1 = SearchStore.getFavouriteSearchById(id);
            var criterias = JSON.parse(favouriteSearch_1.criterias);
            var cSearchModel = criterias.searchModel;
            var initailSerchModel = SearchStore.buildSearchModel({
                favoriteSearch: true,
            });
            SearchStore.setIsFavorite(false);
            SearchStore.setQ(null);
            SearchStore.setHash(null);
            SearchStore.setTrashed(false);
            SearchStore.setDraftId(null);
            SearchStore.clearAllFacets();
            SearchStore.addFacets(criterias.facetsSelected);
            SearchStore.setSelectedFavouriteSearch(favouriteSearch_1.id); // the order of this line is very important
            SearchStore.setSelectedFavouriteSearchName(favouriteSearch_1.name);
            // if there is no date for the favoiteSearch
            if (cSearchModel.DateFrom && cSearchModel.DateTo) {
                if (parseInt(cSearchModel.logicalDate, 10) < 5) {
                    var favSearchDates = {
                        universalDateFrom: window
                            .moment(cSearchModel.UniversalDateFrom, 'YYYYMMDDHHmmss')
                            .format('YYYY-MM-DD HH:mm:ss'),
                        universalDateTo: window
                            .moment(cSearchModel.UniversalDateTo, 'YYYYMMDDHHmmss')
                            .format('YYYY-MM-DD HH:mm:ss'),
                    };
                    var formattedDates = getDatesInUserTZ(cSearchModel.logicalDate, favSearchDates);
                    if (formattedDates) {
                        cSearchModel.UniversalDateFrom = formattedDates.universalDateFrom;
                        cSearchModel.UniversalDateTo = formattedDates.universalDateTo;
                    }
                }
            }
            else {
                cSearchModel = __assign(__assign({}, cSearchModel), { DateFrom: initailSerchModel.searchModel.DateFrom, DateTo: initailSerchModel.searchModel.DateTo, UniversalDateFrom: initailSerchModel.searchModel.UniversalDateFrom, UniversalDateTo: initailSerchModel.searchModel.UniversalDateTo, logicalDate: initailSerchModel.searchModel.logicalDate, DateType: initailSerchModel.searchModel.DateType });
            }
            SearchStore.searchModel = cSearchModel;
            updateFilter();
            setState(function (prevState) { return (__assign(__assign({}, prevState), { favouriteSearchLoading: true, selectedSearch: favouriteSearch_1, expandedDropDown: false })); });
            setFavoriteSearch('');
        }
    }; };
    var handleFavoriteSearchSave = function (title, id) { return function () {
        if (id) {
            var favoriteSearch = SearchStore.getFavouriteSearchById(id);
            SearchActionCreators.updateFavoriteSearch(__assign(__assign({}, favoriteSearch), { name: title }));
            setState(function (prevState) { return (__assign(__assign({}, prevState), { favouriteSearchLoading: true })); });
        }
    }; };
    var handleFavoriteSearchDelete = function (id) { return function () {
        if (id) {
            var favoriteSearch_1 = SearchStore.getFavouriteSearchById(id);
            setSweetPopup(function (prevState) { return (__assign(__assign({}, prevState), { isOpen: true, title: formatMessage({ id: 'Global.areYouSure' }), bodyMessage: formatMessage({
                    id: 'FavouriteSearch.warningDeleteFavouriteSearch',
                }), okClick: function () {
                    setState(function (previousState) { return (__assign(__assign({}, previousState), { favouriteSearchLoading: true, selectedSearch: null })); });
                    SearchActionCreators.deleteFavoriteSearch({
                        Id: favoriteSearch_1.id,
                    });
                }, cancelText: formatMessage({ id: 'Global.cancelAction' }) })); });
            setState(function (prevState) { return (__assign(__assign({}, prevState), { expandedDropDown: true })); });
        }
    }; };
    var handleFavoriteSearchEdit = function (id) {
        var favSearches = SearchStore.getFavouriteSearches();
        var newFavouriteSearchtoEdit = favSearches
            .filter(function (fav) { return fav.id === id; })
            .map(function (fav) { return (__assign(__assign({}, fav), { criterias: JSON.parse(fav.criterias) })); })
            .sort(function (a, b) { return b.id - a.id; });
        beingEditedId.current = id;
        setState(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), { modalIsOpen: true, favouriteSearchtoEdit: newFavouriteSearchtoEdit[0], shareWithCoworker: (_a = newFavouriteSearchtoEdit[0]) === null || _a === void 0 ? void 0 : _a.shareWithAll }));
        });
    };
    var renderDropDownTitle = function () { return (_jsxs("div", { className: "favoriteSearch-placeholder", children: [(selectedSearch === null || selectedSearch === void 0 ? void 0 : selectedSearch.Id) && selectedSearch.Id !== 0 ? (_jsx("span", { title: selectedSearch.Name, children: selectedSearch.Name })) : (_jsx("span", { children: formatMessage({ id: 'SearchBar.favoriteSearch.mySearches' }) })), favouriteSearchLoading && _jsx(Loader, { className: "favourite-search_loader" }), _jsx("div", { title: formatMessage({
                    id: 'FavouriteSearch.refineMySearch.tooltip',
                }), children: _jsx(Svg, { icon: "search", size: "small" }) })] })); };
    var favSearches = (favSearchesList === null || favSearchesList === void 0 ? void 0 : favSearchesList.length) > 0
        ? favSearchesList
            .filter(function (favSearch) {
            return favSearch.name
                .toLowerCase()
                .indexOf(searchFavorite.toLowerCase()) !== -1;
        })
            .sort(function (a, b) { return a.name.localeCompare(b.name); })
        : [];
    var handleOnchangeSearch = function (value) {
        setFavoriteSearch(value);
    };
    var renderFavouriteSearches = function () { return (_jsxs(Fragment, { children: [_jsxs("ul", { className: "favourite-search_list", children: [Array.isArray(favSearchesList) && favSearchesList.length > 0 && (_jsx(Input, { value: searchFavorite, placeholder: "Search field", onChange: handleOnchangeSearch })), Array.isArray(favSearches) &&
                        favSearches.length > 0 &&
                        (favSearches.map(function (fs) { return (_jsx(FavoriteSearchItem, { item: fs, onSave: handleFavoriteSearchSave, onEdit: function () { return handleFavoriteSearchEdit(fs.id); }, onDelete: function () { return handleFavoriteSearchDelete(fs.id); }, onClick: handleItemClick, mode: beingEditedId.current === fs.id ? 'edit' : 'list', selected: SearchStore.searchModel.favoriteSearchId === fs.id }, fs.id)); }) ||
                            null), Array.isArray(favSearches) && favSearches.length === 0 && (_jsx("div", { className: "empty-result", children: _jsx(FormattedMessage, { id: "ReactSelect.noResultsText" }) }))] }), favSearches && !!favSearches.length && _jsx("div", { className: "separator" })] })); };
    var handleCloseModal = function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { modalIsOpen: false, filterSelectedNumber: null })); });
        var initialSearchModel = JSON.parse(window.localStorage.getItem('searchModel'));
        SearchStore.setLogicalDate(DATES_MODES[initialSearchModel.searchModel.logicalDate]);
        SearchStore.setLastPeriod({
            from: initialSearchModel.searchModel.DateFrom,
            to: initialSearchModel.searchModel.DateTo,
            logicalDate: initialSearchModel.searchModel.logicalDate,
            dateType: initialSearchModel.searchModel.DateType,
        });
    };
    var handleClickAway = function () {
        setFavoriteSearch('');
    };
    var handleShareWithCoWorker = function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { shareWithCoworker: !prevState.shareWithCoworker })); });
    };
    var renderShareWithCoworker = function () { return (_jsx(Checkbox, { checked: shareWithCoworker, className: "share-coworker-checkbox", onChange: handleShareWithCoWorker, children: _jsx(FormattedMessage, { id: "FavoriteSearch.favoriteSearchModal.shareWithCoworker" }) })); };
    var handleUpdateFavorite = function () {
        if (myRef.current)
            myRef.current.handleValidationClick();
    };
    var getNumberFilter = function (value) {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { filterSelectedNumber: value })); });
    };
    var renderModal = function () { return (_jsxs(Modal
    // className="c-advanced-filters-modal"
    , { 
        // className="c-advanced-filters-modal"
        isOpen: modalIsOpen, onCancel: handleCloseModal, width: "medium", children: [_jsx(ModalHeader, { onClose: handleCloseModal, children: _jsx(FormattedMessage, { id: "FavoriteSearch.favoriteSearchModal.titleEdit" }) }), _jsx(ModalBody, { className: "advancedFilter-tools", children: favouriteSearchtoEdit && (_jsx(FavoriteSearch, { mode: "editFavorite", ref: myRef, favoriteSearch: favouriteSearchtoEdit, handleCloseModal: handleCloseModal, getNumberFilter: getNumberFilter, shareWithCoworker: shareWithCoworker, updateFilter: updateFilter })) }), _jsxs(ModalFooter, { className: "advancedFilter-tools-footer", children: [favouriteSearchtoEdit &&
                        Object.keys(favouriteSearchtoEdit).length > 0 && (_jsx("span", { className: "selected-filter-count", children: formatMessage({ id: 'Monitoring.SearchBar.AllYourFilter.SelectedFilters' }, {
                            countFacetItems: filterSelectedNumber !== null
                                ? filterSelectedNumber
                                : favouriteSearchtoEdit.criterias.facetsSelected.length,
                        }) })), _jsxs("div", { className: "action-favolriteSearch-modal", children: [renderShareWithCoworker(), _jsx(Button, { onClick: handleUpdateFavorite, variant: "fill", color: "secondary", title: "Save advanced fitlers button", ariaLabel: "Save advanced fitlers button", leftIcon: _jsx(AiOutlineSave, { size: "24px", className: "margin-right" }), children: _jsx(FormattedMessage, { id: "FavoriteSearch.favoriteSearchModal.updateSearchButton" }) })] })] })] })); };
    return (_jsxs(_Fragment, { children: [_jsx(ClickAwayListener, { onClickAway: handleClickAway, children: _jsxs(Wrapper, { children: [_jsx(DropDownSelect, { title: renderDropDownTitle, onClick: handleOnClick, collapsed: expandedDropDown, className: "c-favourite-search", showArrow: true, tooltip: formatMessage({ id: 'FavouriteSearch.mySearch.tooltip' }), children: !favouriteSearchLoading && renderFavouriteSearches() }), renderModal()] }) }), _jsx(GlobaStyles, {})] }));
}
FavouriteSearch.propTypes = propTypes;
export default FavouriteSearch;
