import parameters from 'constants/parameters';
var SHAREARTICLE_TYPE_ENUM = parameters.SHAREARTICLE_TYPE_ENUM, MAX_PUSHMAIL_COMMENT_CARACTERS = parameters.MAX_PUSHMAIL_COMMENT_CARACTERS;
var ShareArticleFormModel = /** @class */ (function () {
    function ShareArticleFormModel(intl) {
        var formatMessage = intl.formatMessage;
        this.intl = intl;
        this.formModelProperty = [
            {
                fieldName: 'pushmailTheme',
                label: formatMessage({ id: 'ShareArticles.pushmailModel' }),
                required: true,
                placeholder: formatMessage({
                    id: 'ShareArticles.pushmailModel.placeholder',
                }),
                type: SHAREARTICLE_TYPE_ENUM.single,
            },
            {
                fieldName: 'withActiveLinks',
                required: false,
                type: SHAREARTICLE_TYPE_ENUM.checkbox,
            },
            {
                fieldName: 'subject',
                label: formatMessage({ id: 'ShareArticles.subject' }),
                required: true,
                placeholder: formatMessage({ id: 'ShareArticles.subject.placeholder' }),
                type: SHAREARTICLE_TYPE_ENUM.input,
            },
            {
                fieldName: 'comment',
                label: formatMessage({ id: 'ShareArticles.labelComment' }),
                required: false,
                // placeholder: formatMessage(
                //   { id: 'ShareArticles.placeholderComment' },
                //   { maxCaracters: MAX_PUSHMAIL_COMMENT_CARACTERS }
                // ),
                placeholder: formatMessage({ id: 'ShareArticles.placeholderComment' }),
                type: SHAREARTICLE_TYPE_ENUM.textarea,
            },
            {
                fieldName: 'mailingList',
                label: formatMessage({ id: 'ShareArticles.grouplist' }),
                required: false,
                placeholder: formatMessage({
                    id: 'ShareArticles.grouplist.placeholder',
                }),
                type: SHAREARTICLE_TYPE_ENUM.multi,
            },
            {
                fieldName: 'userList',
                required: false,
                type: SHAREARTICLE_TYPE_ENUM.grid,
            },
            {
                fieldName: 'externalRecepient',
                label: formatMessage({ id: 'ShareArticles.externalUser' }),
                required: false,
                placeholder: formatMessage({
                    id: 'ShareArticles.externalUser.placeholder',
                }),
                note: formatMessage({ id: 'ShareArticles.noteMail' }),
                type: SHAREARTICLE_TYPE_ENUM.tags,
            },
        ];
    }
    Object.defineProperty(ShareArticleFormModel.prototype, "formModel", {
        get: function () {
            return this.formModelProperty;
        },
        enumerable: false,
        configurable: true
    });
    return ShareArticleFormModel;
}());
export default ShareArticleFormModel;
