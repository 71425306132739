var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function getHorizontalBarLabelContent(formatMessage, widgetFamilyName) {
    if (widgetFamilyName === 'Stacked' || widgetFamilyName === 'Stacked (%)') {
        return [
            {
                value: 'none',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.none',
                }),
            },
            {
                value: 'value',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.value',
                }),
            },
            {
                value: 'percentage',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.percentage',
                }),
            },
            {
                value: 'valuePercentage',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.valuePercentage',
                }),
            },
        ];
    }
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getHorizontalBarLabelPosition(formatMessage, orientation, widgetFamilyName) {
    if (widgetFamilyName === 'Stacked' || widgetFamilyName === 'Stacked (%)') {
        return [
            {
                value: 'inside',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.inside',
                }),
            },
        ];
    }
    return [
        {
            value: 'inside',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.inside',
            }),
        },
        {
            value: 'right',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.outside',
            }),
        },
    ];
}
export function getHorizontalBarLabelTooltip(formatMessage, widgetFamilyName) {
    if (widgetFamilyName === 'Stacked' || widgetFamilyName === 'Stacked (%)') {
        return [
            {
                value: 'none',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.none',
                }),
            },
            {
                value: 'value',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.value',
                }),
            },
            {
                value: 'percentage',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.percentage',
                }),
            },
            {
                value: 'valuePercentage',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.valuePercentage',
                }),
            },
        ];
    }
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getHorizontalBarLegendContent(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getHorizontalBarLegendPosition(formatMessage) {
    return [
        {
            value: 'top',
            label: formatMessage({
                id: 'Global.top',
            }),
        },
        {
            value: 'bottom',
            label: formatMessage({
                id: 'Global.bottom',
            }),
        },
    ];
}
export function getHorizontalBarDisplayedAxis(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'xAxis',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.xAxis',
            }),
        },
        {
            value: 'yAxis',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.yAxis',
            }),
        },
        {
            value: 'all',
            label: formatMessage({
                id: 'Global.All',
            }),
        },
    ];
}
export function getHorizontalBarXAxisOrientation(formatMessage) {
    return [
        {
            value: 'horizontal',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.horizontal',
            }),
        },
        {
            value: 'leftRight',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.leftRight',
            }),
        },
        {
            value: 'vertical',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.vertical',
            }),
        },
    ];
}
export function getHorizontalBarYAxisPosition(formatMessage, widgetFamilyName) {
    if (widgetFamilyName === 'Grouped') {
        return [
            {
                value: 'left',
                label: formatMessage({
                    id: 'Global.left',
                }),
            },
        ];
    }
    return [
        {
            value: 'left',
            label: formatMessage({
                id: 'Global.left',
            }),
        },
        {
            value: 'above',
            label: formatMessage({
                id: 'Global.above',
            }),
        },
    ];
}
export function getDefaultHorizontalBarWidgetSettings(props) {
    var widgetOptionsDictionary = props.widget.widgetOptionsDictionary;
    var getLabelPositionDefaultSetting = function () {
        var widgetFamilyName = props.widget.widgetFamilyName;
        if (widgetFamilyName === 'Stacked (%)' || widgetFamilyName === 'Stacked')
            return null;
        return {
            labelPosition: widgetOptionsDictionary && widgetOptionsDictionary.labelPosition
                ? widgetOptionsDictionary.labelPosition
                : 'inside',
        };
    };
    var getYAxisPositionDefaultSetting = function () {
        var widgetFamilyName = props.widget.widgetFamilyName;
        if (widgetFamilyName === 'Grouped')
            return null;
        return {
            yAxisPosition: widgetOptionsDictionary && widgetOptionsDictionary.yAxisPosition
                ? widgetOptionsDictionary.yAxisPosition
                : 'left',
        };
    };
    return __assign(__assign(__assign({ labelContent: widgetOptionsDictionary && widgetOptionsDictionary.labelContent
            ? widgetOptionsDictionary.labelContent
            : 'none' }, getLabelPositionDefaultSetting()), { labelTooltip: widgetOptionsDictionary && widgetOptionsDictionary.labelTooltip
            ? widgetOptionsDictionary.labelTooltip
            : 'value', legendContent: widgetOptionsDictionary && widgetOptionsDictionary.legendContent
            ? widgetOptionsDictionary.legendContent
            : 'value', legendPosition: widgetOptionsDictionary && widgetOptionsDictionary.legendPosition
            ? widgetOptionsDictionary.legendPosition
            : 'bottom', displayedAxis: widgetOptionsDictionary && widgetOptionsDictionary.displayedAxis
            ? widgetOptionsDictionary.displayedAxis
            : 'all', xAxisOrientation: widgetOptionsDictionary && widgetOptionsDictionary.xAxisOrientation
            ? widgetOptionsDictionary.xAxisOrientation
            : 'leftRight' }), getYAxisPositionDefaultSetting());
}
