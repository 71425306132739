var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable no-bitwise */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import moment from 'moment';
import config from '@constants/parameters';
import { convertToUserTZ } from '@services/DateUtils';
function getDateFormatString() {
    var locale = isoCodeToLocale(getLang());
    if ('formatToParts' in Intl.DateTimeFormat(locale)) {
        var formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());
        return formatObj
            .map(function (obj) {
            switch (obj.type) {
                case 'day':
                    return 'DD';
                case 'month':
                    return 'MM';
                case 'year':
                    return 'YYYY';
                default:
                    return obj.value;
            }
        })
            .join('');
    }
    else if (getLang().toLowerCase() === 'fr') {
        return 'DD/MM/YYYY';
    }
    else if (getLang().toLowerCase() === 'en') {
        return 'DD/MM/YYYY';
    }
    return 'DD/MM/YYYY';
}
export function localeDateFormat() {
    return getDateFormatString();
}
export function createDateAsUTC(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}
export function createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0;
        var v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
export function lowerCaseKeys(obj) {
    var isObject = function (o) {
        return Object.prototype.toString.apply(o) === '[object Object]';
    };
    var isArray = function (o) {
        return Object.prototype.toString.apply(o) === '[object Array]';
    };
    var transformedObj = isArray(obj) ? [] : {};
    for (var key in obj) {
        // replace the following with any transform function
        var transformedKey = key.replace(/^\w/, function (c, _) { return c.toLowerCase(); });
        if (isObject(obj[key]) || isArray(obj[key])) {
            transformedObj[transformedKey] = lowerCaseKeys(obj[key]);
        }
        else {
            transformedObj[transformedKey] = obj[key];
        }
    }
    return transformedObj;
}
export function getUrlParameter(parameter) {
    var _a;
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    var value = vars.find(function (element) { return element.split('=')[0] === parameter; });
    var result = value ? value.split('=')[1] : false;
    if (!result &&
        parameter === 'ticket' &&
        document.getElementById('ticketValue')) {
        // @ts-expect-error element ticketValue does have custom attribut value
        result = (_a = document.getElementById('ticketValue')) === null || _a === void 0 ? void 0 : _a.value;
    }
    return result;
}
export function isKantarNamespaceExist() {
    return typeof window.km !== 'undefined';
}
export function getLang() {
    var supportedLang = ['ca', 'de', 'en', 'es', 'eu', 'it', 'fr', 'zh'];
    var browserLang = navigator.language;
    var storageLang = window.localStorage.getItem('lang');
    var applicationLang = storageLang ||
        (isKantarNamespaceExist() &&
            typeof window.km.getLang === 'function' &&
            window.km.getLang()) ||
        null;
    return (applicationLang ||
        (supportedLang.includes(browserLang.split('-')[0]) &&
            browserLang.split('-')[0]) ||
        'en');
}
export function isoCodeToLocale(isoCode) {
    var isoCodeList = new Map([
        ['ca', 'ca-ES'],
        ['de', 'de-DE'],
        ['en', 'en-GB'],
        ['es', 'es-ES'],
        ['eu', 'eu-ES'],
        ['it', 'it-IT'],
        ['fr', 'fr-FR'],
        ['zh', 'zh-CN'],
    ]);
    var locale = isoCode
        ? isoCodeList.get(isoCode.toLowerCase())
        : isoCodeList.get('en');
    return locale;
}
export function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}
var FormatNumber = /** @class */ (function () {
    function FormatNumber(number) {
        this.number = number;
        this.ranges = [
            {
                divider: 1e24,
                suffix: 'Y',
            },
            {
                divider: 1e21,
                suffix: 'Z',
            },
            {
                divider: 1e18,
                suffix: 'E',
            },
            {
                divider: 1e15,
                suffix: 'P',
            },
            {
                divider: 1e12,
                suffix: 'T',
            },
            {
                divider: 1e9,
                suffix: window.Messages['Global.formatNumberG'],
            },
            {
                divider: 1e6,
                suffix: window.Messages['Global.formatNumberM'],
            },
            {
                divider: 1e3,
                suffix: window.Messages['Global.formatNumberK'],
            },
            {
                divider: 1,
                suffix: '',
            },
            {
                divider: 0,
                suffix: '',
            },
        ];
    }
    FormatNumber.prototype.convert = function () {
        var _this = this;
        var item = this.ranges.find(function (e) {
            return _this.number >= 0
                ? _this.number >= e.divider
                : _this.number * -1 >= e.divider;
        });
        if (!item)
            return [];
        var number = new Intl.NumberFormat(isoCodeToLocale(getLang()));
        var isSpanish = item.divider === 1e9 && ['ca', 'es', 'eu'].includes(getLang());
        if (this.number === 0 || this.number === 1) {
            return [number.format(this.number), ''];
        }
        return [
            isSpanish
                ? number.format(Number(((this.number / item.divider) * 1e3).toPrecision(3)))
                : number.format(Number((this.number / item.divider).toPrecision(3))),
            isSpanish ? 'Mill' : item.suffix,
        ];
    };
    FormatNumber.prototype.divider = function () {
        var _this = this;
        var _a;
        return (_a = this.ranges.find(function (e) { return _this.number >= e.divider; })) === null || _a === void 0 ? void 0 : _a.divider;
    };
    FormatNumber.prototype.format = function () {
        var convert = this.convert();
        return convert[0] + convert[1];
    };
    FormatNumber.prototype.suffix = function () {
        var _this = this;
        var _a, _b;
        return (_b = (_a = this.ranges) === null || _a === void 0 ? void 0 : _a.find(function (e) { return _this.number >= e.divider; })) === null || _b === void 0 ? void 0 : _b.suffix;
    };
    return FormatNumber;
}());
export { FormatNumber };
// @ts-expect-error FormatNumber is not on global types so we can remove it form window obj
window.FormatNumber = FormatNumber;
export function convertCurrencyNameToCurrencySymbol(currencyName) {
    var enums = new Map([
        ['EUR', '€'],
        ['GBP', '£'],
        ['USD', '$'],
    ]);
    var currencySymbol = enums.get(currencyName);
    return currencySymbol || '';
}
export function convertCurrencySymbolToCurrencyName(currencySymbole) {
    var enums = new Map([
        ['€', 'EUR'],
        ['£', 'GBP'],
        ['$', 'USD'],
    ]);
    var currencyName = enums.get(currencySymbole);
    return currencyName || '';
}
export function convertMediumEnumtoMediumName(mediumEnum) {
    var enums = new Map([
        [2, 'press'],
        [3, 'radio'],
        [4, 'tv'],
        [5, 'web'],
        [6, 'social'],
        [7, 'script'],
        [8, 'broadcast'],
    ]);
    var mediumName = enums.get(Number(mediumEnum));
    return mediumName || '';
}
export function gradientColor(color1, color2, percent) {
    function channel(a, b) {
        return a + Math.round((b - a) * (percent / 100));
    }
    function colorPiece(numberIn) {
        var str = '0';
        var numberOut = numberIn;
        numberOut = Math.min(numberOut, 255);
        numberOut = Math.max(numberOut, 0);
        str = numberOut.toString(16);
        if (str.length < 2) {
            str = "0".concat(str);
        }
        return str;
    }
    return "#".concat(colorPiece(channel(color1.r, color2.r))).concat(colorPiece(channel(color1.g, color2.g))).concat(colorPiece(channel(color1.b, color2.b)));
}
export function convertDateToBackEndFormat(date) {
    var sdate = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    var userTZDate = convertToUserTZ(sdate);
    return window.moment(userTZDate).utc().format('YYYYMMDDHHmmss');
}
export function convertFromTimeStringToDateTime(date) {
    var year = date.substring(0, 4);
    var month = date.substring(4, 6);
    var day = date.substring(6, 8);
    var hours = date.substring(8, 10);
    var minutes = date.substring(10, 12);
    var seconds = date.length === 14 ? date.substring(12, 14) : '00';
    return "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hours, ":").concat(minutes, ":").concat(seconds);
}
export function removeSpecialChars(str) {
    return str
        .replace(/(?!\w|\s)./g, '_')
        .replace(/\s+/g, '_')
        .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2')
        .toLowerCase();
}
export function sortStringAlphabetically(a, b, propertyName) {
    if (typeof a[propertyName] !== 'string' ||
        typeof b[propertyName] !== 'string') {
        return 0;
    }
    return global.Intl && typeof Intl.Collator === 'function'
        ? new Intl.Collator().compare(a[propertyName], b[propertyName])
        : a[propertyName].localeCompare(b[propertyName]);
}
export function dowloadZip(serverMethodUrl, jsonParameters, callback) {
    return postFetchFile(serverMethodUrl, jsonParameters, function (resp) {
        var contentDisposition = resp.contentDisposition, blob = resp.blob;
        var fileNameFromTemplate = '';
        if (contentDisposition === null || contentDisposition === void 0 ? void 0 : contentDisposition.includes('attachment')) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(contentDisposition);
            if (matches === null || matches === void 0 ? void 0 : matches[1]) {
                fileNameFromTemplate = matches[1].replace(/['"]/g, '');
            }
        }
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "".concat(fileNameFromTemplate || 'filename');
        document.body.appendChild(a);
        a.click();
        a.remove();
        callback(resp);
    });
}
export function exportExcelData(serverMethodUrl, jsonParameters, fileName, callback) {
    return postFetchFile(serverMethodUrl, jsonParameters, function (reponse) {
        var contentDisposition = reponse.contentDisposition, blob = reponse.blob;
        var fileNameFromTemplate = '';
        if (contentDisposition === null || contentDisposition === void 0 ? void 0 : contentDisposition.includes('attachment')) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(contentDisposition);
            if (matches === null || matches === void 0 ? void 0 : matches[1]) {
                fileNameFromTemplate = matches[1].replace(/['"]/g, '');
            }
        }
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileNameFromTemplate || fileName || 'excel.xls';
        document.body.appendChild(a);
        a.click();
        a.remove();
        if (callback)
            callback(reponse);
    });
}
export function postFetchFile(url, model, callback) {
    return __awaiter(this, void 0, Promise, function () {
        var headers, init, req, res, blob, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    headers = new Headers({
                        Accept: 'application/json, charset=utf-8',
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    });
                    init = {
                        body: model ? JSON.stringify(model) : undefined,
                        credentials: 'include',
                        headers: headers,
                        method: 'POST',
                    };
                    req = new Request("".concat(config.API_ROOT, "/").concat(url), init);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch(req, init)];
                case 2:
                    res = _a.sent();
                    return [4 /*yield*/, res.blob()];
                case 3:
                    blob = _a.sent();
                    if (blob) {
                        callback({
                            contentDisposition: res.headers.get('Content-Disposition'),
                            blob: blob,
                        });
                    }
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    console.warn(e_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    });
}
export function postFetchQuery(url, model) {
    return __awaiter(this, void 0, Promise, function () {
        var headers, init, req, response, responseJson;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    headers = new Headers({
                        Accept: 'application/json, charset=utf-8',
                        'Content-Type': 'application/json',
                    });
                    init = {
                        body: model ? JSON.stringify(model) : undefined,
                        credentials: 'include',
                        headers: headers,
                        method: 'POST',
                    };
                    req = new Request("".concat(config.API_ROOT, "/").concat(url), init);
                    return [4 /*yield*/, fetch(req, init)];
                case 1:
                    response = _a.sent();
                    if (!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2:
                    responseJson = _a.sent();
                    if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.code) === 200) {
                        return [2 /*return*/, responseJson];
                    }
                    throw new Error(responseJson === null || responseJson === void 0 ? void 0 : responseJson.message);
                case 3: throw new Error('Network_error');
            }
        });
    });
}
export function postFetch(url, model, callback, errorCallback) {
    var headers = new Headers({
        Accept: 'application/json, charset=utf-8',
        'Content-Type': 'application/json',
    });
    var init = {
        body: model ? JSON.stringify(model) : undefined,
        credentials: 'include',
        headers: headers,
        method: 'POST',
    };
    var req = new Request("".concat(config.API_ROOT, "/").concat(url), init);
    fetch(req, init)
        .then(function (response) { return response.json(); })
        .then(function (json) {
        callback === null || callback === void 0 ? void 0 : callback(json);
    })
        .catch(function (e) { return errorCallback === null || errorCallback === void 0 ? void 0 : errorCallback(e); });
}
export function removeMultipleSpacesFromString(string) {
    return string.trim().replace(/ +(?= )/g, '');
}
export function convertHTMLLineBreaksToTextLineBreaks(string) {
    return string.replace(/<br\s*[/]?>/gi, '\n');
}
export function convertTextLineBreaksToHTMLLineBreaks(string) {
    return string.replace(/(?:\r\n|\r|\n)/g, '<br/>');
}
export function isJsonStringified(item) {
    var i = item;
    try {
        i = JSON.parse(i);
    }
    catch (e) {
        return false;
    }
    if (typeof i === 'object' && i !== null) {
        return true;
    }
    return false;
}
export var ACCOUNT = {
    isNato: function () { var _a, _b; return ((_b = (_a = window.km) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.currentId) === '45236'; },
};
export var supportsCSS = Boolean((_a = window.CSS) === null || _a === void 0 ? void 0 : _a.supports);
export function debounce(func, wait, immediate) {
    var _this = this;
    var timeout;
    return function () {
        // eslint-disable-next-line
        var context = _this;
        // @ts-expect-error arguments refert to the parent function
        var args = arguments;
        var later = function () {
            timeout = undefined;
            if (!immediate)
                func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow)
            func.apply(context, args);
    };
}
var isObject = function (item) {
    return item && typeof item === 'object' && !Array.isArray(item);
};
export var mergeDeep = function (target) {
    var _a, _b;
    var sources = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        sources[_i - 1] = arguments[_i];
    }
    if (!sources.length)
        return target;
    var source = sources.shift();
    if (isObject(target) && isObject(source)) {
        // eslint-disable-next-line no-restricted-syntax
        for (var key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, (_a = {},
                        _a[key] = {},
                        _a));
                }
                mergeDeep(target[key], source[key]);
            }
            else {
                Object.assign(target, (_b = {},
                    _b[key] = source[key],
                    _b));
            }
        }
    }
    // eslint-disable-next-line
    return mergeDeep.apply(void 0, __spreadArray([target], sources, false));
};
export function increaseBrightness(hex, percent) {
    if (!hex || !percent)
        return;
    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, '');
    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length == 3) {
        hex = hex.replace(/(.)/g, '$1$1');
    }
    var r = parseInt(hex.substr(0, 2), 16);
    var g = parseInt(hex.substr(2, 2), 16);
    var b = parseInt(hex.substr(4, 2), 16);
    return "#".concat((0 | ((1 << 8) + r + ((256 - r) * percent) / 100))
        .toString(16)
        .substr(1)).concat((0 | ((1 << 8) + g + ((256 - g) * percent) / 100))
        .toString(16)
        .substr(1)).concat((0 | ((1 << 8) + b + ((256 - b) * percent) / 100))
        .toString(16)
        .substr(1));
}
export function hasJsonStructure(str) {
    if (typeof str !== 'string')
        return false;
    try {
        var result = JSON.parse(str);
        var type = Object.prototype.toString.call(result);
        return type === '[object Object]' || type === '[object Array]';
    }
    catch (err) {
        return false;
    }
}
export function validateEmail(email) {
    var regEmail = 
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(String(email));
}
export function initiateIntercom() {
    // @ts-expect-error intercom is add on html script
    window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: config.INTERCOM_APPID,
    });
}
export function convertDisplayFormat(value, formatNumber) {
    if (value) {
        var roundedValue = Math.round(value);
        var resultValue = roundedValue;
        var unit = '';
        var decimals = 1;
        if (roundedValue >= 10000000000) {
            resultValue = roundedValue / 1000000000;
            unit = ' B';
            if (roundedValue >= 100000000000)
                decimals = 0;
        }
        else if (roundedValue >= 10000000) {
            resultValue = roundedValue / 1000000;
            unit = ' M';
            if (roundedValue >= 100000000)
                decimals = 0;
        }
        else if (roundedValue >= 10000) {
            resultValue = roundedValue / 1000;
            unit = ' K';
            if (roundedValue >= 100000)
                decimals = 0;
        }
        else
            decimals = 0;
        return "".concat(formatNumber(resultValue.toFixed(decimals))).concat(unit).replace(/\.0$/, '');
    }
    return '0';
}
