var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { ImSpinner2 } from 'react-icons/im';
import { Wrapper } from './Button.styles';
function Button(_a) {
    var _b = _a.color, color = _b === void 0 ? 'default' : _b, _c = _a.size, size = _c === void 0 ? 'medium' : _c, _d = _a.fullWidth, fullWidth = _d === void 0 ? false : _d, _e = _a.loading, loading = _e === void 0 ? false : _e, _f = _a.className, className = _f === void 0 ? '' : _f, _g = _a.disabled, disabled = _g === void 0 ? false : _g, onClick = _a.onClick, _h = _a.radius, radius = _h === void 0 ? 4 : _h, _j = _a.variant, variant = _j === void 0 ? 'fill' : _j, children = _a.children, _k = _a.leftIcon, leftIcon = _k === void 0 ? null : _k, _l = _a.rightIcon, rightIcon = _l === void 0 ? null : _l, href = _a.href, _m = _a.visible, visible = _m === void 0 ? true : _m, _o = _a.hideBackground, hideBackground = _o === void 0 ? false : _o, restProps = __rest(_a, ["color", "size", "fullWidth", "loading", "className", "disabled", "onClick", "radius", "variant", "children", "leftIcon", "rightIcon", "href", "visible", "hideBackground"]);
    return (_jsxs(Wrapper, __assign({ as: Boolean(href) ? 'a' : 'button', href: href, className: className, "$hasLeftIcon": Boolean(leftIcon), "$hasRightIcon": Boolean(rightIcon), onClick: onClick, disabled: loading || disabled, radius: radius || 0, "$fullWidth": fullWidth, size: size, variant: variant, color: color, hideBackground: hideBackground }, restProps, { children: [loading ? _jsx(ImSpinner2, { className: "button__spinner" }) : leftIcon, children, rightIcon] })));
}
export default memo(Button);
