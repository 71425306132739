import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
var PermissionActionCreators = {
    getPermissions: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_PERMISSIONS_REQUEST,
        });
        WebApi.getPermissions();
    },
};
export default PermissionActionCreators;
