import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
var HierarchyActionCreators = {
    getHierarchies: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_HIERARCHIES_REQUEST,
            data: model,
        });
        WebApi.getHierarchies(model, callback);
    },
    getHierarchy: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_HIERARCHY_REQUEST,
            data: model,
        });
        WebApi.getHierarchy(model);
    },
    saveHierarchy: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.SAVE_HIERARCHY_REQUEST,
            data: model,
        });
        WebApi.saveHierarchy(model, callback);
    },
    // TODO--verify data properties
    saveTemporaryHierarchy: function (data) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.SAVE_TEMPORARY_HIERARCHY,
            data: data,
        });
    },
    createHierarchy: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.CREATE_HIERARCHY_REQUEST,
            data: model,
        });
        WebApi.createHierarchy(model, callback);
    },
    deleteHierarchy: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.DELETE_HIERARCHY_REQUEST,
            data: model,
        });
        WebApi.deleteHierarchy(model, callback);
    },
};
export default HierarchyActionCreators;
