var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { FaExclamationCircle, FaCheckCircle, FaInfoCircle, } from 'react-icons/fa';
import { BsX } from 'react-icons/bs';
import ButtonIcon from '@commons/ButtonIcon';
import Button from '@commons/Button';
import { Wrapper } from './Snackbar.styles';
var icons = {
    info: FaInfoCircle,
    success: FaCheckCircle,
    error: FaExclamationCircle,
};
function Snackbar(_a) {
    var snackbarObj = _a.snackbarObj, setSnackbarObj = _a.setSnackbarObj;
    var status = snackbarObj.status, message = snackbarObj.message, open = snackbarObj.open, buttonText = snackbarObj.buttonText, buttonOnClick = snackbarObj.buttonOnClick;
    var init = useMemo(function () {
        if (!snackbarObj.init && snackbarObj.open) {
            return true;
        }
        return false;
    }, [open]);
    var handleClose = function () {
        setSnackbarObj(__assign(__assign({}, snackbarObj), { open: false }));
    };
    var handleOnClickButton = function () {
        if (buttonOnClick)
            buttonOnClick();
        handleClose();
    };
    var Icon = useMemo(function () { return icons[status]; }, [status]);
    var messageToRender = useMemo(function () {
        var isHtmlReg = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
        if (!message) {
            return null;
        }
        else if (isHtmlReg.test(message)) {
            return _jsx("span", { dangerouslySetInnerHTML: { __html: message } });
        }
        return message;
    }, [message]);
    return (_jsxs(Wrapper, { "$status": status, "$open": open, "$init": init, children: [_jsx(Icon, { className: "snackbar__icon", size: 30 }), _jsx("p", { className: "snackbar__text", children: messageToRender }), buttonText && buttonOnClick && (_jsx(Button, { variant: "text", color: "primary", onClick: handleOnClickButton, children: buttonText })), _jsx(ButtonIcon, { onClick: handleClose, children: _jsx(BsX, { size: 24 }) })] }));
}
export default Snackbar;
