import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import moment from 'moment';
import Svg from '@commons/Svg';
import Button from '@commons/Button';
var dateFormat = 'YYYY-MM-DD HH:mm:ss';
var DateSelector = function (_a) {
    var label = _a.label, _b = _a.mode, mode = _b === void 0 ? 'list' : _b, onClick = _a.onClick, _c = _a.icon, icon = _c === void 0 ? null : _c, selected = _a.selected;
    var dates = {
        universalDateFrom: '',
        universalDateTo: '',
        dateFrom: '',
        dateTo: '',
    };
    switch (mode) {
        case 'today': {
            var todayFrom = moment().startOf('day');
            var todayTo = moment();
            dates.universalDateFrom = todayFrom.format(dateFormat);
            dates.universalDateTo = todayTo.format(dateFormat);
            dates.dateFrom = todayFrom.format(dateFormat);
            dates.dateTo = todayTo.format(dateFormat);
            break;
        }
        case 'yesterday': {
            var yesterdayFrom = moment().add(-1, 'days').startOf('day');
            var yesterdayTo = moment();
            dates.universalDateFrom = yesterdayFrom.format(dateFormat);
            dates.universalDateTo = yesterdayTo.format(dateFormat);
            dates.dateFrom = yesterdayTo.format(dateFormat);
            dates.dateTo = yesterdayTo.format(dateFormat);
            break;
        }
        case 'lastweek': {
            var lastweekFrom = moment().add(-7, 'days').startOf('day');
            var lastweekTo = moment();
            dates.universalDateFrom = lastweekFrom.format(dateFormat);
            dates.universalDateTo = lastweekTo.format(dateFormat);
            dates.dateFrom = lastweekTo.format(dateFormat);
            dates.dateTo = lastweekTo.format(dateFormat);
            break;
        }
        case 'lastmonth': {
            var lastmonthFrom = moment().add(-30, 'days').startOf('day');
            var lastmonthTo = moment();
            dates.universalDateFrom = lastmonthFrom.format(dateFormat);
            dates.universalDateTo = lastmonthTo.format(dateFormat);
            dates.dateFrom = lastmonthTo.format(dateFormat);
            dates.dateTo = lastmonthTo.format(dateFormat);
            break;
        }
        case 'lastquarter': {
            var lastquarterFrom = moment().add(-90, 'days').startOf('day');
            var lastquarterTo = moment();
            dates.universalDateFrom = lastquarterFrom.format(dateFormat);
            dates.universalDateTo = lastquarterTo.format(dateFormat);
            dates.dateFrom = lastquarterTo.format(dateFormat);
            dates.dateTo = lastquarterTo.format(dateFormat);
            break;
        }
        default:
            break;
    }
    return (_jsx("li", { className: "date-selector_list__item ".concat((selected && 'date-selector_list__item--selected') || ''), children: _jsxs(Button, { variant: "text", onClick: function () {
                onClick(mode, dates);
            }, onKeyUp: function () {
                onClick(mode, dates);
            }, title: "Select date item", children: [_jsx("div", { className: "date-selector_list__item-name text-truncate", children: label }), icon && (_jsx("span", { className: "date-selector_list__item-icon", children: _jsx(Svg, { icon: icon }) }))] }) }));
};
export default DateSelector;
