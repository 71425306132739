import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'commons/Svg';
import Button from 'components/Button';
var propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    mode: PropTypes.string,
    selected: PropTypes.bool.isRequired,
    icon: PropTypes.string,
};
var defaultProps = {
    mode: 'list',
    icon: null,
};
var DateSelectorItem = function (_a) {
    var label = _a.label, mode = _a.mode, onClick = _a.onClick, icon = _a.icon, selected = _a.selected;
    return (_jsx("li", { className: "date-selector_list__item ".concat((selected &&
            'date-selector_list__item--selected') ||
            ''), children: _jsxs(Button, { onClick: onClick(mode), onKeyUp: onClick(mode), title: "Select ".concat(label), children: [_jsx("div", { className: "date-selector_list__item-name text-truncate", children: label }), icon && (_jsx("span", { className: "date-selector_list__item-icon", children: _jsx(Svg, { icon: icon }) }))] }) }));
};
DateSelectorItem.propTypes = propTypes;
DateSelectorItem.defaultProps = defaultProps;
export default DateSelectorItem;
