export var Translations = {
    'Error.404.title': 'Apologies...',
    'Error.404.description': 'But we can\u0027t find the page or ressource that you\u0027re looking for. It might be an old link or maybe it moved.',
    'Error.pushmail.title': 'We can\u0027t find what you\u0027re looking for...',
    'Error.pushmail.description': 'The links of our emails are deactivated after 6 months. Please reach out to your account\u0027s administrator if you need to access old articles.',
    'Error.unauthorized.title': 'Unauthorized access',
    'Error.unauthorized.description': 'This email wasn\u0027t mean for you. Click below to go back to the login page and authenticate yourself.',
    'Error.internalServer.title': 'Something went wrong...',
    'Error.internalServer.description': 'Our team has been notified and will work on the issue as fast as possible. Please try again later, or contact us if the problem persists.',
    'Error.404.homePageButtonLabel': 'Onclusive platform home page',
    'Error.ExpiredAccount.title': 'Session Expired',
    'Delivery.Login.ContactUs': 'Contact us',
    'Delivery.Login.ErrorMessage': 'Incorrect username and/or password.\u003cbr /\u003ePlease try again or \u003ca href="http://www.kantarmedia.com/uk/contact-us" rel="noopener noreferrer" target="_blank"\u003econtact us\u003c/a\u003e.',
    'Delivery.Login.ExpiredPassword': 'Your current password has expired, click Login to setup a new password',
    'Delivery.Login.LinkExpiredPassword': 'This link has expired, please click on Forgot password to get a new link',
    'Delivery.Login.UserBlocked': 'Your account has been locked due to 5 consecutive attemps to log in, please click on the forgot password button to unlock it or please contact Onclusive customer service',
    'Delivery.Login.Username': 'Username',
    'Delivery.Login.Password': 'Password',
    'Delivery.Login.LogIn': 'Log in',
    'Delivery.Login.AttemptLeft': 'attempt left',
    'Delivery.Login.RememberMe': 'Remember me',
    'Delivery.Login.ForgetYourPassword': 'Forgot your password ?',
    'Delivery.Login.NeedHelp': 'Need some help?',
    'Delivery.Login.Sent': 'Your message was sent to us.\u003c/br\u003eWe will get back to you soon.\u003c/br\u003e\u003c/br\u003eThank you!',
    'Delivery.Login.FirstName': 'First Name',
    'Delivery.Login.LastName': 'Last Name',
    'Delivery.Login.Country': 'Country',
    'Delivery.Login.SentEmail': 'No Value for Delivery.Login.SentEmail in IsoCodeen',
    'Delivery.Login.Email': 'Email',
    'Delivery.Login.Subject': 'Subject',
    'Delivery.Login.Body': 'How Can We Help?',
    'Delivery.Login.ForgotPassword': 'Forgot Password',
    'Delivery.Login.ChangePassword': 'Change password',
    'Delivery.Login.Settings': 'Settings / User creation \u0026 Template customization',
    'Delivery.Login.Monitoring': 'Monitoring criteria',
    'Delivery.Login.ComercialRequest': 'Commercial request',
    'Delivery.Login.TrainingRequest': 'Training request',
    'Delivery.Login.Others': 'Others',
    'Delivery.Login.BackLogin': 'Back to login page',
};
