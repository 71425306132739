import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Spinner from '@commons/Spinner';
import PermissionStore from '@stores/PermissionStore';
import parameters from '@constants/parameters';
var PrivateRoute = function (_a) {
    var role = _a.role, children = _a.children;
    var isAccountConnected = localStorage.getItem('SESSION') === 'true';
    var _b = useState(PermissionStore.roles || []), roles = _b[0], setRoles = _b[1];
    var location = useLocation();
    var onPermissionChange = function () {
        setRoles(PermissionStore.roles);
    };
    useEffect(function () {
        PermissionStore.addChangeListener(onPermissionChange);
        return function () {
            PermissionStore.removeChangeListener(onPermissionChange);
        };
    }, []);
    if (isAccountConnected) {
        if (roles.length === 0) {
            return _jsx(Spinner, { fullPage: true });
        }
        else if (role) {
            var ROLES_ENUM = parameters.ROLES_ENUM;
            var hasRightRole = role && roles.includes(role);
            if (hasRightRole) {
                return children;
            }
            else {
                var landingPage = '';
                if (roles.includes(ROLES_ENUM.MONITORING_ACCESS)) {
                    landingPage = '/monitoring';
                }
                else if (roles.includes(ROLES_ENUM.ANALYTICS_ACCESS)) {
                    landingPage = '/analytics';
                }
                else if (roles.includes(ROLES_ENUM.KIOSK_ACCESS)) {
                    landingPage = '/kiosk';
                }
                else {
                    landingPage = '/settings';
                }
                return _jsx(Navigate, { to: landingPage, state: { from: location } });
            }
        }
        return children;
    }
    return _jsx(Navigate, { to: "/login", state: { from: location } });
};
export default PrivateRoute;
