var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import ActionTypes from '@constants/actionTypes';
import * as AppUtils from '@services/AppUtils';
import * as DateUtils from '@services/DateUtils';
import * as StringUtils from '@services/StringUtils';
import BaseStore from '@stores/BaseStore';
import UserStore from '@stores/UserStore';
var ADD_HIGHLIGHT_EVENT = 'addHighlight';
var DELETE_HIGHLIGHT_EVENT = 'deleteHighlight';
/**
 * Class representing a HighlightCollection.
 * @property {HighlightItem[]} collection
 */
var HighlightCollection = /** @class */ (function () {
    /**
     * Create a HighlightCollection.
     */
    function HighlightCollection() {
        // Default values
        this.collection = [];
    }
    /**
     * Check if the clip ID is valid.
     * @param {string} clipId
     * @return {boolean}
     */
    HighlightCollection.checkClipId = function (clipId) {
        return StringUtils.isNotEmpty(clipId);
    };
    /**
     * Check if the created date is valid.
     * @param {Date} createdDate
     * @return {boolean}
     */
    HighlightCollection.checkCreatedDate = function (createdDate) {
        return DateUtils.isValidDate(createdDate);
    };
    /**
     * Check if the text is valid.
     * @param {string} text
     * @return {boolean}
     */
    HighlightCollection.checkText = function (text) {
        return StringUtils.isNotEmpty(text);
    };
    /**
     * Check if the user account ID is valid.
     * @param {string} userAccountId
     * @return {boolean}
     */
    HighlightCollection.checkUserAccountId = function (userAccountId) {
        return StringUtils.isNotEmpty(userAccountId);
    };
    /**
     * Add a highlight item in the collection.
     * @param {object} highlightItem
     * @param {string} highlightItem.clipId
     * @param {string} highlightItem.createdDate
     * @param {string} highlightItem.firstName
     * @param {string} highlightItem.lastName
     * @param {string} highlightItem.text
     * @param {string} highlightItem.userAccountId
     * @return {HighlightItem}
     */
    HighlightCollection.prototype.add = function (highlightItem) {
        var clipId = highlightItem.clipId, createdDate = highlightItem.createdDate, firstName = highlightItem.firstName, lastName = highlightItem.lastName, text = highlightItem.text, userAccountId = highlightItem.userAccountId;
        var date = createdDate;
        if (HighlightCollection.checkClipId(clipId) &&
            HighlightCollection.checkCreatedDate(date) &&
            HighlightCollection.checkText(firstName) &&
            HighlightCollection.checkText(lastName) &&
            HighlightCollection.checkText(text) &&
            userAccountId) {
            var maybeExistingHighlightItem = this.getHighlightItemByClipIdAndCreatedDate(clipId, date);
            if (maybeExistingHighlightItem === null) {
                this.collection = __spreadArray(__spreadArray([], this.collection, true), [
                    __assign(__assign({}, highlightItem), { createdDate: date }),
                ], false);
                return highlightItem;
            }
            return maybeExistingHighlightItem;
        }
        return null;
    };
    /**
     * Delete a highlight item from the collection.
     * @param {string} clipId
     * @param {Date} createdDate
     * @return {HighlightCollection}
     */
    HighlightCollection.prototype.delete = function (clipId, createdDate) {
        this.collection = this.collection.filter(function (highlightItem) {
            return highlightItem.clipId === clipId &&
                !DateUtils.areSameDateTime(highlightItem.createdDate, createdDate);
        });
    };
    /**
     * Get a highlight collection by clip ID.
     * @param {string} clipId
     * @return {HighlightItem[]}
     */
    HighlightCollection.prototype.getHighlightsByClipId = function (clipId) {
        return (this.collection
            .filter(function (highlightItem) { return highlightItem.clipId === clipId; })
            // @ts-expect-error TODO: Refactor this sort with a proper Date Sort
            .sort(function (a, b) { return b.createdDate - a.createdDate; }));
    };
    /**
     * Get a highlight item by clip ID and created date.
     * @param {string} clipId
     * @param {Date} createdDate
     * @return {HighlightItem}
     */
    HighlightCollection.prototype.getHighlightItemByClipIdAndCreatedDate = function (clipId, createdDate) {
        return (this.collection.find(function (highlightItem) {
            return highlightItem.clipId === clipId &&
                DateUtils.areSameDateTime(highlightItem.createdDate, createdDate);
        }) || null);
    };
    /**
     * Reset the collection.
     */
    HighlightCollection.prototype.reset = function () {
        this.collection = [];
    };
    return HighlightCollection;
}());
var HighlightStore = /** @class */ (function (_super) {
    __extends(HighlightStore, _super);
    function HighlightStore() {
        var _this = _super.call(this) || this;
        _this.highlightCollection = new HighlightCollection();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    /**
     * Get highlight collection by clip ID.
     * @param {string} clipId
     * @return {HighlightItem[]}
     */
    HighlightStore.prototype.getHighlightsByClipId = function (clipId) {
        return this.highlightCollection.getHighlightsByClipId(clipId);
    };
    HighlightStore.prototype.emitAddHighlight = function (text) {
        this.emit(ADD_HIGHLIGHT_EVENT, text);
    };
    HighlightStore.prototype.addAddHighlightListener = function (callback) {
        this.on(ADD_HIGHLIGHT_EVENT, callback);
    };
    HighlightStore.prototype.removeAddHighlightListener = function (callback) {
        this.removeListener(ADD_HIGHLIGHT_EVENT, callback);
    };
    HighlightStore.prototype.emitDeleteHighlight = function () {
        this.emit(DELETE_HIGHLIGHT_EVENT);
    };
    HighlightStore.prototype.addDeleteHighlightListener = function (callback) {
        this.on(DELETE_HIGHLIGHT_EVENT, callback);
    };
    HighlightStore.prototype.removeDeleteHighlightListener = function (callback) {
        this.removeListener(DELETE_HIGHLIGHT_EVENT, callback);
    };
    /**
     * Reset the store.
     */
    HighlightStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.highlightCollection.reset();
    };
    HighlightStore.prototype.registerToActions = function (payload) {
        var _this = this;
        var _a;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_CLIP_RESPONSE:
                if ((_a = action.json) === null || _a === void 0 ? void 0 : _a.data) {
                    var _b = action.json.data, id = _b.id, highlights = _b.highlights;
                    var clipId_1 = id;
                    if (!id || !clipId_1) {
                        clipId_1 = action.json.model.id;
                    }
                    if ((highlights === null || highlights === void 0 ? void 0 : highlights.length) > 0) {
                        highlights.forEach(function (item) {
                            var createdDate = new Date("".concat(item.createdDate, ".000Z"));
                            _this.highlightCollection.add({
                                clipId: clipId_1,
                                createdDate: createdDate,
                                firstName: item.firstName,
                                lastName: item.lastName,
                                text: item.text,
                                userAccountId: item.userAccountId,
                                serializedHighlight: item.serializedHighlight,
                            });
                        });
                        this.emitAddHighlight();
                    }
                }
                break;
            /**
             * @property {string} ActionTypes.ADD_HIGHLIGHT_RESPONSE
             */
            case ActionTypes.ADD_HIGHLIGHT_RESPONSE:
                if (action.json == null) {
                    this.emitError('ADD_HIGHLIGHT');
                }
                else {
                    var _c = action.json.model, id = _c.id, text = _c.text, serializedHighlight = _c.serializedHighlight;
                    var _d = UserStore.getCurrentUser(), firstName = _d.firstName, lastName = _d.lastName;
                    var userAccountId = UserStore.currentUser.userAccountId;
                    var createdDate = new Date("".concat(action.json.data, ".000Z"));
                    this.highlightCollection.add({
                        clipId: id,
                        createdDate: createdDate,
                        firstName: firstName,
                        lastName: lastName,
                        text: text,
                        userAccountId: String(userAccountId),
                        serializedHighlight: serializedHighlight,
                    });
                    this.emitAddHighlight(text);
                }
                break;
            /**
             * @property {string} ActionTypes.DELETE_HIGHLIGHT_RESPONSE
             */
            case ActionTypes.DELETE_HIGHLIGHT_RESPONSE:
                if (action.json == null) {
                    this.emitError('DELETE_HIGHLIGHT');
                }
                else {
                    var _e = action.json.model, createdDate = _e.createdDate, id = _e.id;
                    var date = new Date("".concat(AppUtils.convertFromTimeStringToDateTime(createdDate), ".000Z"));
                    this.highlightCollection.delete(id, date);
                    this.emitDeleteHighlight();
                }
                break;
            default:
                break;
        }
    };
    return HighlightStore;
}(BaseStore));
export default new HighlightStore();
