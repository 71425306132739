import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Checkbox from 'commons/Checkbox';
import { getMediaCategoryKey, getMediaFamilyKey, } from '../../pages/AddArticlePage/AddArticle.utils';
var propTypes = {
    filterText: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        numberOfClips: PropTypes.number.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    menuElement: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    bundle: PropTypes.string,
};
var AdvancedFiltersListItem = function (_a) {
    var _b = _a.item, id = _b.id, name = _b.name, numberOfClips = _b.numberOfClips, isSelected = _a.isSelected, onChange = _a.onChange, menuElement = _a.menuElement, type = _a.type, bundle = _a.bundle;
    var translation = (function () {
        var property = type === 'name' ? name : id;
        switch (menuElement) {
            case 'headlines.isoCode':
                return "Global.Language.".concat(property.toUpperCase());
            case 'sentiment.level':
                return "Global.".concat(property);
            case 'source.medium':
                return "Global.".concat(property.split(' ')[0].toLowerCase());
            case 'source.type':
                return "Global.SourceType.".concat(property.replace(/\s/g, '').toUpperCase());
            case 'source.medfam':
                return "Global.SourceFamily.".concat(getMediaFamilyKey(property));
            case 'source.medcat':
                return "Global.SourceCategory.".concat(getMediaCategoryKey(property));
            case 'countryIsoCode2':
                return "".concat(bundle, ".").concat(id);
            case 'contentType':
                return "Global.ContentType.".concat(property);
            default:
                return null;
        }
    })();
    return (_jsxs(Checkbox, { checked: isSelected, className: "c-advanced-filters-item", classNameLabel: "u-full-width", onChange: function () { return onChange(id); }, label: "", children: [_jsx("span", { className: "c-advanced-filters-item__text text-truncate", children: _jsx("div", { title: name, children: !window.Messages[translation] ? ((type === 'name' && name) || id) : (_jsx(FormattedMessage, { tagName: "span", id: translation })) }) }), ' ', _jsx("span", { className: "c-advanced-filters-item__count text-truncate", children: "(".concat(numberOfClips, ")") })] }));
};
AdvancedFiltersListItem.propTypes = propTypes;
export default AdvancedFiltersListItem;
