import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
var ReviewActionCreators = {
    createReview: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.CREATE_REVIEW_REQUEST,
            data: model,
        });
        WebApi.createReview(model, callback);
    },
    getReview: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_KIOSK_REVIEW_REQUEST,
            data: model,
        });
        WebApi.getReview(model);
    },
    getReviewPdf: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_REVIEW_PDF_REQUEST,
            data: model,
        });
        WebApi.getReviewPdf(model);
    },
    getReviewPdfState: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_REVIEW_PDF_STATE_REQUEST,
            data: model,
        });
        WebApi.getReviewPdfState(model);
    },
    getReviews: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_REVIEWS_REQUEST,
            data: model,
        });
        WebApi.getReviews(model);
    },
    setSelectedTab: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.SET_SELECTED_TAB_REQUEST,
            data: model,
        });
        WebApi.setSelectedTab(model);
    },
    accessReviewByType: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.ACESS_REVIEW_BY_TYPE_REQUEST,
            data: model,
        });
        WebApi.accessReviewByType(model);
    },
    updateClipsAlsoIn: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.UPDATE_REVIEW_CLIPS_ALSO_IN_REQUEST,
            data: model,
        });
        WebApi.updateClipsAlsoIn(model);
    },
};
export default ReviewActionCreators;
