import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import Button from '@commons/Button';
import Svg from '@commons/Svg';
import Typography from '@commons/Typography';
import FacetGroupSearch from './FacetGroupSearch';
import { Wrapper } from './FacetGroup.styles';
function FacetGroup(_a) {
    var _b = _a.id, id = _b === void 0 ? '' : _b, _c = _a.onOpen, onOpen = _c === void 0 ? null : _c, children = _a.children, _d = _a.className, className = _d === void 0 ? '' : _d, _e = _a.hasAdvancedFilters, hasAdvancedFilters = _e === void 0 ? true : _e, name = _a.name, _f = _a.selectedFacetItemIds, selectedFacetItemIds = _f === void 0 ? [] : _f;
    var formatMessage = useIntl().formatMessage;
    var _g = useState(false), isExpanded = _g[0], setIsExpanded = _g[1];
    var handleToggle = function () {
        setIsExpanded(function (prevState) { return !prevState; });
        if (!isExpanded && typeof onOpen === 'function') {
            onOpen();
        }
        else if (typeof onOpen === 'function') {
            onOpen('close');
        }
    };
    {
        var selectedFacetLenth_1 = selectedFacetItemIds && selectedFacetItemIds.length > 0
            ? selectedFacetItemIds.filter(function (elm) { return elm.includes(id); }).length
            : 0;
        var renderLabel = function () { return (_jsx("div", { className: "c-facet-group__label text-truncate", children: _jsxs(Typography, { variant: "h5", component: "span", title: "".concat(formatMessage({ id: 'Global.filter' }), " ").concat(name), children: [name, selectedFacetLenth_1 > 0 ? "(".concat(selectedFacetLenth_1, ")") : ''] }) })); };
        var facetGroupClassNames = classnames('c-facet-group', className);
        return (_jsxs(Wrapper, { className: facetGroupClassNames, children: [_jsxs("div", { className: "button-group pull--right", children: [hasAdvancedFilters ? _jsx(FacetGroupSearch, { id: id, name: name }) : null, _jsxs(Button, { variant: "text", className: "c-facet-group__toggle", onClick: handleToggle, title: "".concat(formatMessage({
                                id: isExpanded ? 'Global.collapse' : 'Global.expand',
                            }), " ").concat(name), name: "".concat(name, " expand"), "aria-label": "Filter ".concat(name, " expand button"), children: [_jsx(Svg, { icon: isExpanded ? 'up' : 'down', size: "small" }), _jsx("span", { className: "hidden", children: "Filter ".concat(name, " expand button") })] })] }), renderLabel(), isExpanded ? (_jsx("ul", { className: "c-facet-group__content list-unstyled", children: children })) : null] }));
    }
}
export default FacetGroup;
