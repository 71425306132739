import { jsx as _jsx } from "react/jsx-runtime";
import Button from '@commons/Button';
import { Wrapper } from './RadioButton.styles';
function RadioButton(_a) {
    var _b = _a.combinators, combinators = _b === void 0 ? [
        {
            type: '',
            handleClick: function (value) { },
            disabled: false,
            name: '',
            buttonClassNames: '',
            title: '',
            checked: 'checked',
        },
    ] : _b, combinator = _a.combinator;
    var handleClick = function (event) {
        var _a;
        var currentTarget = event.currentTarget;
        if (currentTarget instanceof HTMLElement) {
            var control = combinators.filter(function (item) { return item.name === currentTarget.getAttribute('name'); })[0];
            if (control) {
                var value = {
                    ops: control.name,
                    not: (_a = combinator === null || combinator === void 0 ? void 0 : combinator.not) !== null && _a !== void 0 ? _a : false,
                };
                control.handleClick(value);
            }
        }
    };
    return (_jsx(Wrapper, { children: combinators.map(function (control) { return (_jsx(Button, { type: "button", size: "small", variant: control.name === (combinator === null || combinator === void 0 ? void 0 : combinator.ops) ? 'fill' : 'outline', disabled: control.disabled, onClick: handleClick, name: control.name, children: control.title }, control.name)); }) }));
}
export default RadioButton;
