var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import Checkbox from '@commons/Checkbox';
import parameters from '@constants/parameters';
import { getMediaCategoryKey, getMediaFamilyKey, } from '../../pages/AddArticlePage/AddArticle.utils';
import { Wrapper } from './FacetItem.styles';
var FACET_ITEM_KEY_SEPARATOR = parameters.FACET_ITEM_KEY_SEPARATOR;
function FacetItem(_a) {
    var facetGroupId = _a.facetGroupId, selectedFacetItemIds = _a.selectedFacetItemIds, facetGroupName = _a.facetGroupName, _b = _a.item, numberOfClips = _b.numberOfClips, id = _b.id, name = _b.name, onChange = _a.onChange;
    var formatMessage = useIntl().formatMessage;
    var handleChange = function () {
        var item = { id: id, name: name, numberOfClips: numberOfClips };
        if (typeof onChange === 'function') {
            var updatedItem = facetGroupName === null ? item : __assign(__assign({}, item), { name: facetGroupName });
            onChange(facetGroupId, updatedItem);
        }
    };
    var key = facetGroupId + FACET_ITEM_KEY_SEPARATOR + id;
    var isChecked = selectedFacetItemIds.includes(key);
    var translation = (function () {
        switch (facetGroupId) {
            case 'countryIsoCode2':
                return "Global.Country.".concat(id.toUpperCase());
            case 'sentiment.level':
                return "Global.".concat(id);
            case 'contentType':
                return "Global.ContentType.".concat(id);
            case 'source.medium':
                return "Global.".concat(id.split(' ')[0].toLowerCase());
            case 'source.type':
                return "Global.SourceType.".concat(name.replace(/\s/g, '').toUpperCase());
            case 'source.medfam':
                return "Global.SourceFamily.".concat(getMediaFamilyKey(id));
            case 'source.medcat':
                return "Global.SourceCategory.".concat(getMediaCategoryKey(id));
            default:
                return null;
        }
    })();
    var facetItemClassNames = classnames('c-facet-item', {
        'has-number-of-clips': numberOfClips != null,
    });
    return (_jsxs(Wrapper, { className: facetItemClassNames, "$hasNumberOfClips": numberOfClips != null, children: [numberOfClips == null ? null : (_jsxs("span", { className: "c-facet-item__number", title: String(numberOfClips), children: ["(", numberOfClips, ")"] })), _jsx(Checkbox, { checked: isChecked, classNameLabel: "text-truncate", onChange: handleChange, title: translation === null ? name : formatMessage({ id: translation }), id: id + translation, name: name, children: translation == null ? (_jsx("span", { children: name })) : (_jsx(FormattedMessage, { id: translation })) })] }));
}
export default FacetItem;
