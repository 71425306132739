var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { BsCheck2 } from 'react-icons/bs';
import Button from 'commons/Button';
import { ModalBody, ModalFooter, ModalHeader } from 'commons/Modal';
import Spinner from 'commons/Spinner';
import DualListBox from 'new-components/DualListBox';
import Row from 'commons/Row';
import FacetActionCreators from 'actions/FacetActionCreators';
import FacetStore from 'stores/FacetStore';
import SearchStore from 'stores/SearchStore';
import { Wrapper } from './FacetsConfiguration.styles';
var propTypes = {
    intl: PropTypes.object.isRequired,
    updateFilter: PropTypes.func,
};
var defaultProps = {
    updateFilter: function () { },
};
var FacetsConfiguration = forwardRef(function (_a, ref) {
    var formatMessage = _a.intl.formatMessage, updateFilter = _a.updateFilter;
    var dualListBoxRef = useRef();
    var _b = useState(false), modalIsOpen = _b[0], setModalIsOpen = _b[1];
    var _c = useState(true), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState([]), availableItems = _d[0], setAvailableItems = _d[1];
    var _e = useState([]), displayedItems = _e[0], setDisplayedItems = _e[1];
    useEffect(function () {
        FacetStore.addSaveListener(onSave);
        return function () {
            FacetStore.removeSaveListener(onSave);
        };
    }, []);
    var onSave = function () {
        var model = SearchStore.buildSearchModel();
        model = __assign(__assign({}, model), { updateWidget: false });
        SearchStore.setShouldGetClips(false);
        updateFilter(null, model);
        handleCloseModal();
    };
    var handleCloseModal = function () {
        setModalIsOpen(false);
    };
    var handleOpenModal = function () {
        var data = initData();
        setModalIsOpen(true);
        setIsLoading(false);
        setAvailableItems(data.availableItems);
        setDisplayedItems(data.displayedItems);
    };
    var handleValidationClick = function () {
        var newDisplayedItems = dualListBoxRef.current &&
            dualListBoxRef.current &&
            dualListBoxRef.current.displayedItems;
        newDisplayedItems.forEach(function (item) {
            item.id = item.name;
        });
        FacetActionCreators.saveInsightsFiltersUser(newDisplayedItems);
    };
    var initData = function () {
        var allMenu = SearchStore.advancedFilterMenu || [];
        var menu = allMenu.filter(function (item) { return item.forFacetFilter && (item.bundle || !item.isTranslated); });
        var allAvailableFacets = menu.reduce(function (previous, _a) {
            var filterElasticFieldPath = _a.filterElasticFieldPath, filterFacetSelected = _a.filterFacetSelected, bundle = _a.bundle, filterFacetPosition = _a.filterFacetPosition, icon = _a.icon, filterSourceType = _a.filterSourceType, dimensionId = _a.dimensionId, filterElasticFieldName = _a.filterElasticFieldName;
            return !filterFacetSelected
                ? __spreadArray(__spreadArray([], previous, true), [
                    {
                        id: dimensionId,
                        name: filterElasticFieldPath,
                        position: filterFacetPosition,
                        icon: icon,
                        bundle: bundle,
                        filterSourceType: filterSourceType,
                        filterElasticFieldName: filterElasticFieldName,
                        label: filterSourceType === 'CM'
                            ? filterElasticFieldName
                            : formatMessage({
                                id: bundle,
                            }),
                    },
                ], false) : previous;
        }, []);
        var ESfacets = allAvailableFacets
            .filter(function (el) { return el.filterSourceType === 'ES'; })
            .sort(function (a, b) {
            if (formatMessage({ id: a.bundle }) === formatMessage({ id: b.bundle })) {
                return 0;
            }
            return formatMessage({ id: a.bundle }) <
                formatMessage({ id: b.bundle })
                ? -1
                : 1;
        });
        var CMFacets = allAvailableFacets
            .filter(function (el) { return el.filterSourceType === 'CM'; })
            .sort(function (a, b) {
            if (a.id === b.id) {
                return 0;
            }
            return a.id < b.id ? -1 : 1;
        });
        var available = ESfacets.concat(CMFacets);
        var displayed = menu.reduce(function (previous, _a) {
            var filterElasticFieldPath = _a.filterElasticFieldPath, filterFacetSelected = _a.filterFacetSelected, bundle = _a.bundle, filterFacetPosition = _a.filterFacetPosition, dimensionId = _a.dimensionId, icon = _a.icon, filterSourceType = _a.filterSourceType, filterElasticFieldName = _a.filterElasticFieldName;
            return filterFacetSelected
                ? __spreadArray(__spreadArray([], previous, true), [
                    {
                        id: dimensionId,
                        position: filterFacetPosition,
                        name: filterElasticFieldPath,
                        icon: icon,
                        filterSourceType: filterSourceType,
                        filterElasticFieldName: filterElasticFieldName,
                        label: filterSourceType === 'CM'
                            ? filterElasticFieldName
                            : formatMessage({ id: bundle }),
                    },
                ], false) : previous;
        }, []);
        return {
            availableItems: available,
            displayedItems: displayed,
        };
    };
    var availableFacets = availableItems.sort(function (a, b) {
        return a.position > b.position ? 1 : -1;
    });
    var displayedFacets = displayedItems.sort(function (a, b) {
        return a.position > b.position ? 1 : -1;
    });
    useImperativeHandle(ref, function () { return ({
        handleOpenModal: handleOpenModal,
    }); });
    return (_jsxs(Wrapper, { isOpen: modalIsOpen, onCancel: handleCloseModal, size: "small", width: "small", className: "c-modal-facets-configurantion", children: [_jsx(ModalHeader, { onClose: handleCloseModal, children: _jsxs("span", { className: "align-middle", children: [_jsx(FormattedMessage, { id: "FacetsConfiguration.modalTitleStrong" }), ' ', _jsx(FormattedMessage, { id: "FacetsConfiguration.modalYourTitle" })] }) }), _jsx(ModalBody, { children: _jsxs(Row, { className: "row-dual-box", children: [isLoading && _jsx(Spinner, {}), modalIsOpen &&
                            ((availableItems && availableItems.length > 0) ||
                                (displayedItems && displayedItems.length > 0)) && (_jsx(DualListBox, { ref: dualListBoxRef, availableSectionTitle: formatMessage({
                                id: 'FacetsConfiguration.availableSectionTitle',
                            }), displayedSectionTitle: formatMessage({
                                id: 'FacetsConfiguration.displayedSectionTitle',
                            }), availableItems: availableFacets, displayedItems: displayedFacets }))] }) }), _jsxs(ModalFooter, { className: "text-right", children: [_jsx(Button, { onClick: handleCloseModal, variant: "outline", title: formatMessage({
                            id: 'Monitoring.SearchBar.ConfigureYourFilter.cancelConfiguration',
                        }), ariaLabel: formatMessage({
                            id: 'Monitoring.SearchBar.ConfigureYourFilter.cancelConfiguration',
                        }), className: "close-modal", children: _jsx(FormattedMessage, { id: "Monitoring.SearchBar.ConfigureYourFilter.cancelConfiguration" }) }), _jsx(Button, { onClick: handleValidationClick, color: "secondary", title: formatMessage({
                            id: 'Monitoring.SearchBar.ConfigureYourFilter.applyFilter',
                        }), ariaLabel: formatMessage({
                            id: 'Monitoring.SearchBar.ConfigureYourFilter.applyFilter',
                        }), className: "apply-filter", leftIcon: _jsx(BsCheck2, { size: 18 }), children: _jsx(FormattedMessage, { id: "Monitoring.SearchBar.ConfigureYourFilter.applyFilter" }) })] })] }));
});
FacetsConfiguration.propTypes = propTypes;
FacetsConfiguration.defaultProps = defaultProps;
export default injectIntl(FacetsConfiguration, { forwardRef: true });
