var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable no-undef */
/* eslint-disable no-case-declarations */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
import ActionTypes from 'constants/actionTypes';
import BaseStore from 'stores/BaseStore';
import { logicalDateTypes } from 'services/DateUtils';
import { isJsonStringified, lowerCaseKeys } from 'services/AppUtils';
import SearchActionCreators from 'actions/SearchActionCreators';
import CommonStore from './CommonStore';
import ReviewStore from './ReviewStore';
import DraftStore from './DraftStore';
import ClipSelectionStore from './ClipSelectionStore';
var SEARCH_OBJECT_UPDATE_EVENT = 'searchObjectUpdate';
var FILTERS_UPDATE_EVENT = 'filtersUpdateEvent';
var FACETS_UPDATE_EVENT = 'facetsUpdateEvent';
var GET_ADVANCED_FILTER_MENU = 'getAdvancedFilterMenu';
var UPDATE_LAST_PERIOD_EVENT = 'UPDATE_LAST_PERIOD_EVENT';
var CHANGE_EVENT = 'change';
var SearchStore = /** @class */ (function (_super) {
    __extends(SearchStore, _super);
    // static setFavoriteSearchName(fs, model, UserAccountId) {
    //   fs = { ...model, UserAccountId };
    // }
    function SearchStore() {
        var _this = _super.call(this) || this;
        _this.clearWithoutQueryBuilder = function () {
            _this.selectedFacets = {};
            _this.setIsFavorite(false);
            _this.setSelectedFavouriteSearch(null);
            _this.emitFacetsUpdate();
        };
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        _this.setQueryBuilder = _this.setQueryBuilder.bind(_this);
        _this.setRawQueryBuilder = _this.setRawQueryBuilder.bind(_this);
        _this.setQ = _this.setQ.bind(_this);
        _this.setQFields = _this.setQFields.bind(_this);
        _this.setDraftId = _this.setDraftId.bind(_this);
        _this.setHash = _this.setHash.bind(_this);
        _this.findFacetByKeyValue = _this.findFacetByKeyValue.bind(_this);
        _this.getSelectedFacets = _this.getSelectedFacets.bind(_this);
        _this.addFacet = _this.addFacet.bind(_this);
        _this.addFacets = _this.addFacets.bind(_this);
        _this.deleteFacet = _this.deleteFacet.bind(_this);
        _this.getSelectedFacetsArray = _this.getSelectedFacetsArray.bind(_this);
        _this.getSelectedFacetsObjectsArray =
            _this.getSelectedFacetsObjectsArray.bind(_this);
        _this.clearAllFacets = _this.clearAllFacets.bind(_this);
        _this.clearAllFacetsWithoutUpdating =
            _this.clearAllFacetsWithoutUpdating.bind(_this);
        _this.setAdvancedFilterMenu = _this.setAdvancedFilterMenu.bind(_this);
        _this.clearAllFacetsExceptQB = _this.clearAllFacetsExceptQB.bind(_this);
        _this.updateFilter = _this.updateFilter.bind(_this);
        return _this;
    }
    SearchStore.prototype.initializeState = function () {
        this.searchObj = {
            searchModel: {},
        };
        // this.searchObj = this.buildSearchModel();
        this.ClipIds = [];
        this.Indexes = [];
        this.TotalArticles = null;
        this.favouriteSearches = [];
        this.selectedFavouriteSearch = null;
        this.selectedFacets = {};
        this.AdvancedFilterMenu = [];
        this.init = {};
        this.shouldGetClips = true;
        this.shouldGetFilter = false;
    };
    SearchStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(SearchStore.prototype, "searchObject", {
        get: function () {
            return this.searchObj;
        },
        set: function (val) {
            this.searchObj = val;
            this.emitSearchObjectUpdate();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SearchStore.prototype, "indexes", {
        get: function () {
            return this.Indexes;
        },
        set: function (indexes) {
            this.Indexes = indexes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SearchStore.prototype, "searchModel", {
        get: function () {
            return this.searchObject.searchModel;
        },
        set: function (searchModel) {
            this.searchObject.searchModel = searchModel;
            this.emitSearchObjectUpdate();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SearchStore.prototype, "totalClips", {
        get: function () {
            return this.TotalArticles;
        },
        set: function (total) {
            this.TotalArticles = total;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SearchStore.prototype, "advancedFilterMenu", {
        get: function () {
            return this.AdvancedFilterMenu;
        },
        set: function (value) {
            this.AdvancedFilterMenu = value;
        },
        enumerable: false,
        configurable: true
    });
    SearchStore.prototype.getAdvancedFilterMenuById = function (id) {
        return this.AdvancedFilterMenu.find(function (afi) { return afi.id === id || afi.filterElasticFieldPath === id; });
    };
    SearchStore.prototype.setShouldGetClips = function (value) {
        this.shouldGetClips = value;
    };
    SearchStore.prototype.getShouldGetClips = function () {
        return this.shouldGetClips;
    };
    SearchStore.prototype.setShouldGetFilter = function (value) {
        this.shouldGetFilter = value;
    };
    SearchStore.prototype.getShouldGetFilter = function () {
        return this.shouldGetFilter;
    };
    SearchStore.prototype.getFavouriteSearches = function () {
        return this.favouriteSearches;
    };
    SearchStore.prototype.getFavouriteSearchById = function (id) {
        var fav = this.favouriteSearches.find(function (fs) { return fs.id === id; });
        return (fav && fav) || null;
    };
    SearchStore.prototype.getQ = function () {
        return (this.searchObject && this.searchObject.searchModel.Q) || null;
    };
    SearchStore.prototype.setQ = function (value) {
        if (this.selectedFavouriteSearch) {
            this.selectedFavouriteSearch = null;
        }
        this.searchObject.searchModel.Q = value;
        this.emitSearchObjectUpdate('q');
    };
    SearchStore.prototype.getQFields = function () {
        return (this.searchObject && this.searchObject.searchModel.QFields) || null;
    };
    SearchStore.prototype.setQFields = function (value) {
        if (this.selectedFavouriteSearch) {
            this.selectedFavouriteSearch = null;
        }
        this.searchObject.searchModel.QFields = value;
        this.emitSearchObjectUpdate('QFields');
    };
    SearchStore.prototype.getSelectedFavouriteSearch = function () {
        return this.selectedFavouriteSearch;
    };
    SearchStore.prototype.setSelectedFavouriteSearch = function (searchId) {
        this.selectedFavouriteSearch = searchId;
        this.emitSearchObjectUpdate();
    };
    SearchStore.prototype.getSelectedFavouriteSearchName = function () {
        return this.selectedFavouriteSearchName;
    };
    SearchStore.prototype.setSelectedFavouriteSearchName = function (favName) {
        this.selectedFavouriteSearchName = favName;
        this.emitSearchObjectUpdate();
    };
    SearchStore.prototype.setLastPeriod = function (lastPeriod) {
        var period = {};
        var logicalDate = lastPeriod.logicalDate;
        var dates = {};
        switch (logicalDate) {
            case '1':
                var todayFrom = moment().startOf('day');
                var todayTo = moment();
                dates.universalDateFrom = todayFrom.format('YYYY-MM-DD HH:mm:ss');
                dates.universalDateTo = todayTo.format('YYYY-MM-DD HH:mm:ss');
                break;
            case '2':
                var yesterdayFrom = moment().add(-1, 'days').startOf('day');
                var yesterdayTo = moment();
                dates.universalDateFrom = yesterdayFrom.format('YYYY-MM-DD HH:mm:ss');
                dates.universalDateTo = yesterdayTo.format('YYYY-MM-DD HH:mm:ss');
                break;
            case '3':
                var lastweekFrom = moment().add(-7, 'days').startOf('day');
                var lastweekTo = moment();
                dates.universalDateFrom = lastweekFrom.format('YYYY-MM-DD HH:mm:ss');
                dates.universalDateTo = lastweekTo.format('YYYY-MM-DD HH:mm:ss');
                break;
            case '4':
                var lastmonthFrom = moment().add(-30, 'days').startOf('day');
                var lastmonthTo = moment();
                dates.universalDateFrom = lastmonthFrom.format('YYYY-MM-DD HH:mm:ss');
                dates.universalDateTo = lastmonthTo.format('YYYY-MM-DD HH:mm:ss');
                break;
            case '5':
                var lastquarterFrom = moment().add(-90, 'days').startOf('day');
                var lastquarterTo = moment();
                dates.universalDateFrom = lastquarterFrom.format('YYYY-MM-DD HH:mm:ss');
                dates.universalDateTo = lastquarterTo.format('YYYY-MM-DD HH:mm:ss');
                break;
            case '6':
                dates.universalDateFrom = moment
                    .utc(lastPeriod.from, 'YYYY-MM-DD HH:mm:ss')
                    .format('YYYY-MM-DD HH:mm:ss');
                dates.universalDateTo = moment
                    .utc(lastPeriod.to, 'YYYY-MM-DD HH:mm:ss')
                    .format('YYYY-MM-DD HH:mm:ss');
                break;
            default:
                break;
        }
        this.setLogicalDate(logicalDateTypes[lastPeriod.logicalDate]);
        if (lastPeriod.from && lastPeriod.to) {
            period.from = lastPeriod.from;
            period.to = lastPeriod.to;
            this.setSearchDates({
                universalDateFrom: period.from,
                universalDateTo: period.to,
            });
        }
        else {
            this.setSearchDates(dates);
        }
        period.logicalDate = lastPeriod.logicalDate;
        this.searchObject.lastPeriod = JSON.stringify(period);
        this.emitSearchObjectUpdate();
    };
    SearchStore.prototype.setCustomerReviewType = function (type) {
        var customerReviewType = null;
        if (typeof type === 'string') {
            customerReviewType = type;
        }
        this.searchObject.searchModel.customerReviewType = customerReviewType;
    };
    SearchStore.prototype.getCustomerReviewType = function () {
        return ((this.searchObject && this.searchObject.searchModel.customerReviewType) ||
            null);
    };
    SearchStore.prototype.getCustomerReviewMode = function () {
        var _a, _b;
        return ((_b = (_a = this.searchObject) === null || _a === void 0 ? void 0 : _a.searchModel) === null || _b === void 0 ? void 0 : _b.customerReviewMode) || null;
    };
    SearchStore.prototype.getLastPeriod = function () {
        // if (mode === 'reset') {
        //   return JSON.stringify({
        //     ...this.searchObject.lastPeriod,
        //     logicalDate: '1',
        //   });
        // }
        return ((this.searchObject && this.searchObject.lastPeriod) ||
            "{logicalDate: '1'}");
    };
    SearchStore.prototype.getSearchDates = function () {
        return {
            from: (this.searchObject &&
                this.searchObject.searchModel.UniversalDateFrom) ||
                null,
            to: (this.searchObject && this.searchObject.searchModel.UniversalDateTo) ||
                null,
            dateFrom: (this.searchObject && this.searchObject.searchModel.DateFrom) || null,
            dateTo: (this.searchObject && this.searchObject.searchModel.DateTo) || null,
        };
    };
    SearchStore.prototype.setSearchDates = function (dates) {
        var logicalDate = '1';
        if (this.searchObject && this.searchObject.searchModel) {
            logicalDate = this.searchObject.searchModel.logicalDate;
        }
        var utcDates = window.getDatesInUserTZ(logicalDate, dates);
        this.searchObject.searchModel.UniversalDateFrom =
            utcDates.universalDateFrom;
        this.searchObject.searchModel.UniversalDateTo = utcDates.universalDateTo;
        this.searchObject.searchModel.DateFrom = dates.universalDateFrom;
        this.searchObject.searchModel.DateTo = dates.universalDateTo;
        this.emitSearchObjectUpdate();
    };
    SearchStore.prototype.getLogicalDate = function (raw) {
        if (raw === void 0) { raw = false; }
        var logical = null;
        var logicalDate = '1';
        if (this.searchObject && this.searchObject.searchModel) {
            logicalDate = this.searchObject.searchModel.logicalDate;
        }
        switch (logicalDate) {
            case '1':
                logical = 'today';
                break;
            case '2':
                logical = 'yesterday';
                break;
            case '3':
                logical = 'lastweek';
                break;
            case '4':
                logical = 'lastmonth';
                break;
            case '6':
                logical = 'lastquarter';
                break;
            case '5':
                logical = 'custom';
                break;
            default:
                logical = '1';
                break;
        }
        return raw ? logicalDate : logical;
    };
    SearchStore.prototype.setLogicalDate = function (mode) {
        var logicalDate = '1';
        switch (mode) {
            case 'today':
                logicalDate = '1';
                break;
            case 'yesterday':
                logicalDate = '2';
                break;
            case 'lastweek':
                logicalDate = '3';
                break;
            case 'lastmonth':
                logicalDate = '4';
                break;
            case 'lastquarter':
                logicalDate = '6';
                break;
            case 'custom':
                logicalDate = '5';
                break;
            default:
                logicalDate = this.getLastPeriod().logicalDate;
                break;
        }
        this.searchObject.searchModel.logicalDate = logicalDate;
        this.emitSearchObjectUpdate();
    };
    SearchStore.prototype.getDateType = function () {
        return ((this.searchObject && this.searchObject.searchModel.DateType) ||
            (km.global && km.global.lastPeriod && km.global.lastPeriod.dateType) ||
            2);
    };
    SearchStore.prototype.setDateType = function (type) {
        if (!type) {
            return;
        }
        this.searchObject.searchModel.DateType = type;
        this.emitSearchObjectUpdate();
    };
    SearchStore.prototype.getHash = function () {
        return (this.searchObject && this.searchObject.searchModel.Hashs) || null;
    };
    SearchStore.prototype.setHash = function (hash) {
        this.searchObject.searchModel.Hashs = hash;
        this.emitSearchObjectUpdate();
    };
    SearchStore.prototype.setCustomerReviewMode = function (value) {
        this.searchObject.searchModel.customerReviewMode = value;
        this.emitSearchObjectUpdate();
    };
    SearchStore.prototype.setLimit = function (limit) {
        if (limit) {
            this.searchObject.searchModel.limit = limit;
        }
        else {
            delete this.searchObject.searchModel.limit;
        }
        this.emitSearchObjectUpdate();
    };
    SearchStore.prototype.getDraftId = function () {
        return (this.searchObject && this.searchObject.searchModel.DraftIds) || [];
    };
    SearchStore.prototype.setDraftId = function (ids) {
        this.searchObject.searchModel.DraftIds = ids || null;
        this.emitSearchObjectUpdate();
    };
    SearchStore.prototype.getDraftName = function () {
        return ((this.searchObject && this.searchObject.searchModel.DraftName) || null);
    };
    SearchStore.prototype.setDraftName = function (name) {
        this.searchObject.searchModel.DraftName = name || null;
        this.emitSearchObjectUpdate('draftName');
    };
    SearchStore.prototype.setReviewName = function (name) {
        this.searchObject.searchModel.ReviewName = name || null;
        this.emitSearchObjectUpdate('reviewName');
    };
    SearchStore.prototype.getReviewName = function () {
        return ((this.searchObject && this.searchObject.searchModel.ReviewName) || null);
    };
    SearchStore.prototype.getTrashed = function () {
        return (this.searchObject && this.searchObject.searchModel.Trashed) || null;
    };
    SearchStore.prototype.setTrashed = function (value) {
        this.searchObject.searchModel.Trashed = value;
        this.emitSearchObjectUpdate('trashed');
    };
    SearchStore.prototype.getGroupBy = function () {
        return (this.searchObject && this.searchObject.searchModel.groupBy) || null;
    };
    SearchStore.prototype.setGroupBy = function (value) {
        this.searchObject.searchModel.groupBy = value;
        this.emitSearchObjectUpdate('groupby');
    };
    SearchStore.prototype.getIsFavorite = function () {
        return ((this.searchObject && this.searchObject.searchModel.IsFavourite) || null);
    };
    SearchStore.prototype.setIsFavorite = function (value) {
        this.searchObject.searchModel.IsFavourite = value;
        this.emitSearchObjectUpdate();
    };
    SearchStore.prototype.getSortBy = function () {
        return ((this.searchObject && this.searchObject.searchModel.sortBy) ||
            'deliveredDate');
    };
    SearchStore.prototype.setSortBy = function (value) {
        this.searchObject.searchModel.sortBy = value;
        this.emitSearchObjectUpdate('sortBy');
    };
    SearchStore.prototype.getSortDirection = function () {
        return !Number.isNaN(this.searchObject.searchModel.sortDirection)
            ? this.searchObject.searchModel.sortDirection
            : 1;
    };
    SearchStore.prototype.setSortDirection = function (value) {
        this.searchObject.searchModel.sortDirection = value;
        this.emitSearchObjectUpdate('sortDirection');
    };
    SearchStore.prototype.setQueryBuilder = function (query) {
        this.searchObject.searchModel.queryBuilderFilter = query;
        this.emitSearchObjectUpdate('queryBuilder');
    };
    SearchStore.prototype.getQueryBuilder = function () {
        return ((this.searchObject && this.searchObject.searchModel.queryBuilderFilter) ||
            null);
    };
    SearchStore.prototype.setRawQueryBuilder = function (rawQuery) {
        this.searchObject.searchModel.queryBuilderRaw = rawQuery;
        this.emitSearchObjectUpdate('rawQueryBuilder');
    };
    SearchStore.prototype.getRawQueryBuilder = function () {
        return ((this.searchObject && this.searchObject.searchModel.queryBuilderRaw) ||
            null);
    };
    SearchStore.prototype.deleteFavoriteSearchById = function (id) {
        var favIndex = this.favouriteSearches.findIndex(function (fs) { return fs.id === id; });
        if (favIndex > -1) {
            this.favouriteSearches.splice(favIndex, 1);
            return true;
        }
        return false;
    };
    /*
     *-------------------------------------------------------------------
     *          FACETS HELPER METHODS
     * ------------------------------------------------------------------
     */
    SearchStore.prototype.findFacetByKeyValue = function (key, value) {
        var facet = null;
        if (this.selectedFacets[key]) {
            this.selectedFacets[key].forEach(function (item) {
                if (item.value === value && !facet) {
                    facet = item;
                }
            });
        }
        return facet;
    };
    SearchStore.prototype.getSelectedFacets = function () {
        return this.selectedFacets;
    };
    SearchStore.prototype.addFacet = function (facet) {
        if (this.selectedFavouriteSearch) {
            this.selectedFavouriteSearch = null;
        }
        if (facet.id in this.selectedFacets) {
            if (this.findFacetByKeyValue(facet.id, facet.value)) {
                return false;
            }
            this.selectedFacets[facet.id].push({
                code: facet.code,
                text: facet.text,
                value: facet.value,
            });
            this.emitFacetsUpdate();
            return true;
        }
        this.selectedFacets[facet.id] = [
            {
                code: facet.code,
                text: facet.text,
                value: facet.value,
            },
        ];
        this.emitFacetsUpdate();
        return true;
    };
    SearchStore.prototype.addFacets = function (facets) {
        // maybe i should use let instead of const
        for (var i in facets) {
            this.addFacet(facets[i]);
        }
        this.emitFacetsUpdate();
        return true;
    };
    SearchStore.prototype.deleteFacet = function (facet) {
        var _this = this;
        if (facet && facet.id === 'favouriteFilter') {
            this.setIsFavorite(false);
            if (this.selectedFavouriteSearch) {
                this.selectedFavouriteSearch = null;
            }
            return;
        }
        if (this.selectedFavouriteSearch) {
            this.selectedFavouriteSearch = null;
        }
        if (facet.id in this.selectedFacets) {
            var findIndexFacetByKeyValue = function (key, value) {
                var index = null;
                for (var i in _this.selectedFacets[key]) {
                    if (_this.selectedFacets[key][i].value === value) {
                        index = i;
                    }
                }
                return index;
            };
            var tItemIndex = findIndexFacetByKeyValue(facet.id, facet.value);
            if (facet.id === 'query.builder') {
                delete this.selectedFacets['query.builder'];
                this.selectedFavouriteSearch = null;
                this.setQueryBuilder(null);
                this.setRawQueryBuilder(null);
            }
            if (tItemIndex) {
                this.selectedFacets[facet.id].splice(tItemIndex, 1);
                if (this.selectedFacets[facet.id].length === 0) {
                    delete this.selectedFacets[facet.id];
                }
            }
            else {
                this.emitFacetsUpdate();
                return;
            }
            this.emitFacetsUpdate();
        }
    };
    SearchStore.prototype.getSelectedFacetsArray = function () {
        var array = [];
        for (var i in this.selectedFacets) {
            if (i !== 'query.builder') {
                for (var item in this.selectedFacets[i]) {
                    array.push("".concat(i, "**||**").concat(this.selectedFacets[i][item].value));
                }
            }
        }
        return array;
    };
    SearchStore.prototype.getSelectedFacetsObjectsArray = function () {
        var array = [];
        var selectedFacets = this.selectedFacets;
        for (var i in selectedFacets) {
            for (var item in selectedFacets[i]) {
                array.push({
                    id: i,
                    value: selectedFacets[i][item].value,
                    text: selectedFacets[i][item].text,
                });
            }
        }
        return array;
    };
    SearchStore.prototype.getSelectedFacetsObjectsArrayGrouped = function () {
        var facets = this.getSelectedFacetsObjectsArray();
        var grouped = {};
        facets.forEach(function (facet) {
            if (grouped[facet.id]) {
                grouped[facet.id].push(facet);
            }
            else {
                grouped[facet.id] = [facet];
            }
        });
        return grouped;
    };
    SearchStore.prototype.clearAllFacets = function () {
        this.selectedFacets = {};
        this.setQueryBuilder(null);
        this.setRawQueryBuilder(null);
        this.setIsFavorite(false);
        this.setSelectedFavouriteSearch(null);
        this.emitFacetsUpdate();
    };
    SearchStore.prototype.clearAllFacetsWithoutUpdating = function () {
        this.selectedFacets = {};
        this.setQueryBuilder(null);
        this.setRawQueryBuilder(null);
        this.setIsFavorite(false);
        this.setSelectedFavouriteSearch(null);
    };
    SearchStore.prototype.clearAllFacetsExceptQB = function () {
        for (var item in this.selectedFacets) {
            if (item !== 'query.builder') {
                delete this.selectedFacets[item];
            }
        }
        this.emitFacetsUpdate();
    };
    SearchStore.prototype.updateFilter = function () {
        SearchActionCreators.updateFilter(this.buildSearchModel());
    };
    SearchStore.prototype.isInitialized = function (id) {
        return this.init[id];
    };
    SearchStore.prototype.updateAdvancedFilterPosition = function (items) {
        var _this = this;
        this.advancedFilterMenu.forEach(function (item) {
            item.filterWidgetPosition = 0;
            item.filterWidgetSelected = false;
        });
        if (items.length > 0) {
            items.forEach(function (item, index) {
                var advancedFilter = _this.advancedFilterMenu.find(function (element) { return element.dimensionId === item.dimensionId; });
                if (advancedFilter) {
                    advancedFilter.filterWidgetPosition = index + 1;
                    advancedFilter.filterWidgetSelected = true;
                }
            });
        }
    };
    SearchStore.prototype.buildSearchModel = function (_a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.favoriteSearch, favoriteSearch = _c === void 0 ? false : _c;
        var queryBuilder = this.getQueryBuilder() && this.getQueryBuilder() === 'null'
            ? 'null'
            : (isJsonStringified(this.getQueryBuilder()) &&
                this.getQueryBuilder()) ||
                JSON.stringify(this.getQueryBuilder());
        var queryBuilderRaw = this.getRawQueryBuilder() && this.getRawQueryBuilder() === 'null'
            ? 'null'
            : (isJsonStringified(this.getRawQueryBuilder()) &&
                this.getRawQueryBuilder()) ||
                JSON.stringify(this.getRawQueryBuilder());
        var dates = this.getSearchDates();
        var facets = this.getSelectedFacets();
        var storeDraft = this.getDraftId()
            ? DraftStore.getDraftById(this.getDraftId())
            : null;
        var hash = this.getHash();
        var storeReview = hash ? ReviewStore.getReviewByHash(hash) : null;
        // const storeReview = this.getHash()
        //   ? ReviewStore.getReviewByHash(this.getHash())
        //   : null;
        var draft = storeDraft || { name: this.getDraftName() };
        var review = storeReview || { title: this.getReviewName() };
        this.searchObject = {
            facetsSelected: favoriteSearch
                ? this.getSelectedFacetsObjectsArray()
                : this.getSelectedFacetsArray(),
            lastPeriod: JSON.stringify({
                from: dates.dateFrom,
                to: dates.dateTo,
                logicalDate: this.getLogicalDate(true),
                DateType: this.getDateType(),
            }),
            loadAllFilters: true,
            /* !(
                Array.isArray(this.advancedFilterMenu) &&
                this.advancedFilterMenu.length > 0
              ) ||
              !this.TotalArticles ||
              this.TotalArticles === 0 */
            searchModel: {
                ClipIds: hash ? [] : this.clipIds,
                DateType: this.getDateType(),
                DraftIds: this.getDraftId() ? this.getDraftId() : [],
                DraftName: draft ? draft.name : null,
                Hashs: hash || [],
                ReviewName: review ? review.title : null,
                IsFavourite: this.getIsFavorite() || false,
                Q: this.getQ() || '',
                QFields: this.getQFields() || [],
                TimeZone: moment.tz.guess(),
                Trashed: this.getTrashed(),
                DateFrom: dates.dateFrom,
                DateTo: dates.dateTo,
                UniversalDateFrom: dates.from,
                UniversalDateTo: dates.to,
                displayAllElements: false,
                groupBy: this.getGroupBy() || '',
                // indexes: this.Indexes,
                logicalDate: this.getLogicalDate(true) || '1',
                queryBuilderFilter: queryBuilder,
                queryBuilderRaw: queryBuilderRaw,
                sortBy: this.getSortBy() || '',
                sortDirection: this.getSortDirection(),
                favoriteSearchId: this.getSelectedFavouriteSearch(),
                favoriteSearchName: this.getSelectedFavouriteSearchName(),
                facetsRestore: JSON.stringify(facets),
                customerReviewType: this.getCustomerReviewType() || null,
                customerReviewMode: this.getCustomerReviewMode(),
            },
        };
        if (favoriteSearch) {
            delete this.searchObject.lastPeriod;
            delete this.searchObject.searchModel.ClipIds;
            delete this.searchObject.searchModel.indexes;
        }
        km.global.lastPeriod = {
            from: this.searchObject.searchModel.DateFrom,
            to: this.searchObject.searchModel.DateTo,
            logicalDate: this.getLogicalDate(true),
            dateType: this.getDateType(),
        };
        return this.searchObject;
    };
    SearchStore.prototype.defaultSearchModel = function (mode) {
        var searchDates = this.getSearchDates();
        var dates = getDatesInUserTZ('today', searchDates);
        if (mode === 'allArticles') {
            dates = getDatesInUserTZ(this.getLogicalDate(), searchDates);
        }
        else {
            dates = getDatesInUserTZ('today', searchDates);
        }
        this.selectedFacets = {};
        this.searchObject = {
            facetsSelected: [],
            lastPeriod: this.getLastPeriod('reset'),
            loadAllFilters: true,
            searchModel: {
                ClipIds: [],
                DateType: this.getDateType(),
                DraftIds: [],
                Hashs: [],
                IsFavourite: false,
                Q: '',
                QFields: [],
                TimeZone: window.km.getTimeZone(),
                Trashed: false,
                UniversalDateFrom: dates.from,
                UniversalDateTo: dates.to,
                DateFrom: dates.dateFrom,
                DateTo: dates.dateTo,
                displayAllElements: false,
                groupBy: '',
                // indexes: [],
                logicalDate: mode === 'allArticles' ? this.getLogicalDate(true) : '1',
                queryBuilderFilter: null,
                queryBuilderRaw: null,
                sortBy: 'deliveredDate',
                sortDirection: 1,
                facetsRestore: {},
                customerReviewType: null,
            },
        };
        if (mode === 'reset') {
            window.localStorage.setItem('searchModel', JSON.stringify(this.searchObject));
            km.global.lastPeriod = {
                from: moment(this.searchObject.UniversalDateFrom).format('YYYY-MM-DD HH:mm:ss'),
                to: moment(this.searchObject.UniversalDateTo).format('YYYY-MM-DD HH:mm:ss'),
                logicalDate: '1',
                dateType: this.getDateType(),
            };
        } // this.setSearchModel(this.searchObject)
        return this.searchObject;
    };
    SearchStore.prototype.setSearchModelProperties = function (modelToSet, mode) {
        var _this = this;
        var model = modelToSet || this.defaultSearchModel('reset');
        var searchModel = model.searchModel;
        this.clipIds = mode === 'advancedFilterTools' ? searchModel.ClipIds : [];
        this.indexes = searchModel.Indexes;
        this.initialized = true;
        this.init.filter = true;
        this.setQ(searchModel.Q);
        this.setQFields(searchModel.QFields);
        this.setDateType(searchModel.DateType);
        this.setLastPeriod(JSON.parse(model.lastPeriod));
        this.setHash(searchModel.Hashs);
        this.setDraftId(searchModel.DraftIds);
        this.setDraftName(searchModel.DraftName);
        this.setReviewName(searchModel.ReviewName);
        this.setTrashed(searchModel.Trashed);
        this.setGroupBy(searchModel.groupBy);
        this.setIsFavorite(searchModel.IsFavourite);
        this.setSortBy(searchModel.sortBy);
        this.setSortDirection(searchModel.sortDirection);
        this.setQueryBuilder(searchModel.queryBuilderFilter);
        this.setRawQueryBuilder(searchModel.queryBuilderRaw);
        this.setCustomerReviewType(searchModel.customerReviewType);
        this.setCustomerReviewMode(searchModel.customerReviewMode);
        // restore favorite search
        if (searchModel.favoriteSearchId) {
            this.setSelectedFavouriteSearch(searchModel.favoriteSearchId);
        }
        if (searchModel.favoriteSearchName && searchModel.favoriteSearchId) {
            this.setSelectedFavouriteSearchName(searchModel.favoriteSearchName);
        }
        // else {
        //   this.setSelectedFavouriteSearchName('');
        // }
        // restore query builder facet
        if (searchModel.queryBuilderFilter &&
            searchModel.queryBuilderFilter.length > 5) {
            this.addFacet({
                id: 'query.builder',
                text: 'Query Builder',
                value: searchModel.queryBuilderFilter,
            });
        }
        // restore applied facets
        if (Array.isArray(model.facetsSelected)) {
            model.facetsSelected.forEach(function (facet) {
                var f = facet.split('**||**');
                var translation = null;
                if (f[0] === 'countryIsoCode2') {
                    var countries = CommonStore.getCountries();
                    if (countries) {
                        if (km.getLang() !== undefined) {
                            translation = countries[f[1].toLowerCase()][km === null || km === void 0 ? void 0 : km.getLang()];
                        }
                    }
                }
                if (f[0] === 'clipSelections.id') {
                    var categories = ClipSelectionStore.clipSelections;
                    var cat = categories.find(function (c) { return c.id === f[1]; });
                    if (cat) {
                        translation = cat.name;
                    }
                }
                if (searchModel.facetsRestore && searchModel.facetsRestore.length > 5) {
                    var restoredFacets = JSON.parse(searchModel.facetsRestore);
                    var facets = restoredFacets[f[0]] || [];
                    var fac = facets.find(function (fa) { return fa.value === f[1]; });
                    if (fac) {
                        translation = fac.text;
                    }
                }
                _this.addFacet({
                    id: f[0],
                    text: (translation && translation) || f[1],
                    value: f[1],
                });
            });
        }
    };
    SearchStore.prototype.setAdvancedFilterMenu = function (filtersPreferences) {
        this.AdvancedFilterMenu.forEach(function (menu) {
            var filterPreference = filtersPreferences.filter(function (item) { return item.id === menu.id; });
            if (filterPreference.length > 0) {
                menu.isFilterUserPreference = true;
            }
            else {
                menu.isFilterUserPreference = false;
            }
        });
    };
    SearchStore.prototype.emitChange = function (event, param) {
        this.emit(CHANGE_EVENT, event, param);
    };
    SearchStore.prototype.emitGetAdvancedFilterMenu = function () {
        this.emit(GET_ADVANCED_FILTER_MENU);
    };
    SearchStore.prototype.addGetAdvancedFilterMenuListener = function (callback) {
        this.on(GET_ADVANCED_FILTER_MENU, callback);
    };
    SearchStore.prototype.removeGetAdvancedFilterMenuListener = function (callback) {
        this.removeListener(GET_ADVANCED_FILTER_MENU, callback);
    };
    SearchStore.prototype.emitSearchObjectUpdate = function (property) {
        this.emit(SEARCH_OBJECT_UPDATE_EVENT, property);
    };
    SearchStore.prototype.addSearchObjectUpdateListener = function (callback) {
        this.on(SEARCH_OBJECT_UPDATE_EVENT, callback);
    };
    SearchStore.prototype.removeSearchObjectUpdateListener = function (callback) {
        this.removeListener(SEARCH_OBJECT_UPDATE_EVENT, callback);
    };
    SearchStore.prototype.emitUpdateFilters = function (updateWidget) {
        this.emit(FILTERS_UPDATE_EVENT, updateWidget);
    };
    SearchStore.prototype.addUpdateFiltersListener = function (callback) {
        this.on(FILTERS_UPDATE_EVENT, callback);
    };
    SearchStore.prototype.removeUpdateFiltersListener = function (callback) {
        this.removeListener(FILTERS_UPDATE_EVENT, callback);
    };
    SearchStore.prototype.emitFacetsUpdate = function () {
        this.emit(FACETS_UPDATE_EVENT);
    };
    SearchStore.prototype.addFacetsUpdateListener = function (callback) {
        this.on(FACETS_UPDATE_EVENT, callback);
    };
    SearchStore.prototype.removeFacetsUpdateListener = function () {
        this.removeAllListeners(FACETS_UPDATE_EVENT);
    };
    SearchStore.prototype.emitUpdateLastPeriod = function () {
        this.emit(UPDATE_LAST_PERIOD_EVENT);
    };
    SearchStore.prototype.addUpdateLastPeriodListener = function (callback) {
        this.on(UPDATE_LAST_PERIOD_EVENT, callback);
    };
    SearchStore.prototype.removeUpdateLastPeriodListener = function () {
        this.removeAllListeners(UPDATE_LAST_PERIOD_EVENT);
    };
    SearchStore.prototype.registerToActions = function (payload) {
        var _this = this;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_CURRENT_USER_RESPONSE:
                if (action.json) {
                    var lastPeriod = action.json.data.lastPeriod;
                    if (lastPeriod) {
                        this.setLastPeriod(JSON.parse(lastPeriod));
                        this.setShouldGetFilter(true);
                        this.emitUpdateLastPeriod();
                    }
                }
                break;
            case ActionTypes.SAVE_INSIGHTS_FILTERS_USER_RESPONSE:
                if (action.json) {
                    var model_1 = action.json.model;
                    if (Array.isArray(model_1) && model_1.length > 0) {
                        var afm = this.advancedFilterMenu.map(function (af, index) {
                            var item = model_1.find(function (m) { return m.id === af.filterElasticFieldPath; });
                            var lastPosition = (item && item.position) || null;
                            if (item) {
                                return __assign(__assign({}, af), { filterFacetPosition: item.position, filterFacetSelected: true });
                            }
                            return __assign(__assign({}, af), { filterFacetSelected: false, filterFacetPosition: lastPosition !== null ? lastPosition + 1 : index + 1 });
                        });
                        this.advancedFilterMenu = afm;
                    }
                }
                break;
            case ActionTypes.UPDATE_FILTER_RESPONSE:
                if (action.json) {
                    var _a = action.json, data = _a.data, _b = _a.model, model = _b === void 0 ? {} : _b;
                    this.clipIds = data.clipIds || [];
                    this.indexes = data.indexes;
                    this.TotalArticles = data.totalArticles;
                    this.initialized = true;
                    this.init.filter = true;
                    this.init.advancedFilterMenu = true;
                    if (model.loadAllFilters && data.allFilters) {
                        this.advancedFilterMenu = data.allFilters;
                    }
                    window.localStorage.setItem('searchModel', JSON.stringify(model));
                    this.buildSearchModel();
                    var updateWidgets = model.updateWidget !== undefined ? model.updateWidget : false;
                    this.emitChange(action.actionType, updateWidgets);
                    this.emitUpdateFilters(updateWidgets);
                    this.emitGetAdvancedFilterMenu();
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.GET_FAVOURITE_SEARCHES_RESPONSE:
                if (action.json) {
                    this.favouriteSearches = action.json.data;
                    this.initialized = true;
                    this.init.favouriteSearches = true;
                    this.emitChange('GET_FAVOURITE_SEARCHES_RESPONSE');
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.REMOVE_FROM_DRAFT_RESPONSE:
                if (action.json) {
                    var clips = action.json.model.clips;
                    clips.forEach(function (_a) {
                        var id = _a.id;
                        if (_this.clipIds.includes(id)) {
                            var clipIndex = _this.clipIds.findIndex(function (cc) { return cc === id; });
                            if (clipIndex > -1) {
                                _this.clipIds.splice(clipIndex, 1);
                            }
                        }
                    });
                    this.clipIds = this.clipIds;
                    this.totalClips -= Math.abs(clips.length - 1);
                    this.emitSearchObjectUpdate();
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.CREATE_FAVOURITE_SEARCH_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    if (data && !data.nameExist) {
                        this.favouriteSearches.push(data);
                        this.emitChange(action.actionType);
                    }
                    else {
                        this.emitError(action.actionType, data);
                    }
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.UPDATE_FAVORITE_SEARCH_RESPONSE:
                if (action.json) {
                    var _c = action.json, model_2 = _c.model, data = _c.data;
                    var id_1 = data.id, userAccountId_1 = data.userAccountId;
                    this.favouriteSearches = this.favouriteSearches.map(function (fs) {
                        return fs.id === id_1 ? __assign(__assign({}, model_2), { userAccountId: userAccountId_1 }) : fs;
                    });
                    var searchModel = JSON.parse(localStorage.getItem('searchModel')).searchModel;
                    if (searchModel.favoriteSearchId === model_2.id) {
                        this.setSelectedFavouriteSearchName(model_2.name);
                        this.setSelectedFavouriteSearch(model_2.id);
                    }
                    this.emitChange('UPDATE_FAVORITE_SEARCH');
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.DELETE_FAVORITE_SEARCH_RESPONSE:
                if (action.json) {
                    var Id_1 = action.json.model.Id;
                    this.favouriteSearches = this.favouriteSearches.filter(function (fs) { return fs.id !== Id_1; });
                    this.emitChange(action.actionType);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.UPDATE_WIDGET_RESPONSE:
            case ActionTypes.ADD_INSIGHTS_WIDGET_RESPONSE:
            case ActionTypes.ADD_DASHBOARD_RESPONSE:
            case ActionTypes.CREATE_WIDGET_RESPONSE:
                if (action.json && action.json.data) {
                    if (action.json.model.userFiltersPreferences) {
                        this.setAdvancedFilterMenu(action.json.model.userFiltersPreferences);
                    }
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.DELETE_DRAFT_RESPONSE:
                if (action.json) {
                    this.setDraftId(null);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            default:
                break;
        }
    };
    return SearchStore;
}(BaseStore));
export default new SearchStore();
