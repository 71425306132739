var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, keyframes } from 'styled-components';
var opening = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      0% {\n        opacity: 0.2;\n        transform: translateY(-500px);\n      }\n      80% {\n        opacity: 1;\n        transform: translateY(20px);\n      }\n      100% {\n        opacity: 1;\n        transform: translate(0);\n      }\n    "], ["\n      0% {\n        opacity: 0.2;\n        transform: translateY(-500px);\n      }\n      80% {\n        opacity: 1;\n        transform: translateY(20px);\n      }\n      100% {\n        opacity: 1;\n        transform: translate(0);\n      }\n    "])));
var closing = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n 0% {\n   opacity: 1;\n        transform: translate(0);\n  }\n  80% {\n    opacity: 0.6;\n        transform: translateY(20px);\n      }\n\n  100% {\n   opacity: 0.4;\n        transform: translateY(-500px);\n  }\n"], ["\n 0% {\n   opacity: 1;\n        transform: translate(0);\n  }\n  80% {\n    opacity: 0.6;\n        transform: translateY(20px);\n      }\n\n  100% {\n   opacity: 0.4;\n        transform: translateY(-500px);\n  }\n"])));
var Wrapper = styled('div')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme, $open = _a.$open, $init = _a.$init;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: fixed;\n    top: 10px;\n    right: 10px;\n    left: 70px;\n\n    display: grid;\n    grid-template-columns: max-content 1fr max-content max-content;\n    align-items: center;\n    border: 1px solid ", ";\n    background-color: #fff;\n    padding: 0 ", ";\n    padding-right: 0;\n    border-left: 5px solid ", ";\n    border-radius: 8px 5px 5px 8px;\n    box-shadow: 0 5px 10px 0 rgba(35, 35, 35, 0.08);\n    ", ";\n    z-index: 9999999;\n    .alertBanner__text {\n      text-align: left;\n      padding: 5px 15px;\n      margin: 0;\n    }\n    .envelop-block {\n      padding: 8px;\n      background: ", ";\n      border-radius: 4px;\n      svg {\n        color: ", ";\n      }\n    }\n    svg {\n      max-height: unset;\n    }\n  "], ["\n    position: fixed;\n    top: 10px;\n    right: 10px;\n    left: 70px;\n\n    display: grid;\n    grid-template-columns: max-content 1fr max-content max-content;\n    align-items: center;\n    border: 1px solid ", ";\n    background-color: #fff;\n    padding: 0 ", ";\n    padding-right: 0;\n    border-left: 5px solid ", ";\n    border-radius: 8px 5px 5px 8px;\n    box-shadow: 0 5px 10px 0 rgba(35, 35, 35, 0.08);\n    ", ";\n    z-index: 9999999;\n    .alertBanner__text {\n      text-align: left;\n      padding: 5px 15px;\n      margin: 0;\n    }\n    .envelop-block {\n      padding: 8px;\n      background: ", ";\n      border-radius: 4px;\n      svg {\n        color: ", ";\n      }\n    }\n    svg {\n      max-height: unset;\n    }\n  "])), theme.palette.danger.main, theme.spacing['x-small'], theme.palette.danger.main, $init
        ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          animation: ", " 0.4s ease-out forwards;\n        "], ["\n          animation: ", " 0.4s ease-out forwards;\n        "])), $open ? opening : closing) : '', theme.palette.danger.main, theme.palette.secondary.contrastText);
});
export { Wrapper };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
