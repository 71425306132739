var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState, useEffect, } from 'react';
import Snackbar from '@commons/Snackbar';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
var initialSnackbarObj = {
    open: false,
    status: 'info',
    message: '',
    buttonText: '',
    buttonOnClick: null,
    init: false,
};
var SnackbarContext = React.createContext(null);
function SnackbarProvider(props) {
    var formatMessage = useIntl().formatMessage;
    var _a = useState(initialSnackbarObj), snackbarObj = _a[0], setSnackbarObj = _a[1];
    var value = useMemo(function () { return ({ setSnackbarObj: setSnackbarObj }); }, [setSnackbarObj]);
    var queryClient = useQueryClient();
    useEffect(function () {
        queryClient.setDefaultOptions({
            queries: {
                onError: function () {
                    setSnackbarObj({
                        open: true,
                        status: 'error',
                        message: formatMessage({ id: 'Global.serverErrorTitle' }),
                    });
                },
            },
        });
    }, []);
    useEffect(function () {
        var close = function () {
            setSnackbarObj(initialSnackbarObj);
        };
        var timeoutId;
        if (snackbarObj.open) {
            timeoutId = setTimeout(close, 10000);
        }
        else {
            clearTimeout(timeoutId);
        }
        return function () {
            clearTimeout(timeoutId);
        };
    }, [snackbarObj.open]);
    return (_jsxs(_Fragment, { children: [_jsx(Snackbar, { snackbarObj: snackbarObj, setSnackbarObj: setSnackbarObj }), _jsx(SnackbarContext.Provider
            // @ts-expect-error expect props to override value
            , __assign({ 
                // @ts-expect-error expect props to override value
                value: value }, props))] }));
}
export { SnackbarContext, SnackbarProvider };
