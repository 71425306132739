import { jsx as _jsx } from "react/jsx-runtime";
/*eslint-disable*/
import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import ClipStore from 'stores/ClipStore';
import SearchStore from 'stores/SearchStore';
import PushmailStore from 'stores/PushmailStore';
import PushmailActionCreators from 'actions/PushmailActionCreators';
import { SnackbarContext } from 'context/SnackbarContext';
import ShareArticleForm from './ShareArticleForm';
var PropTypes = {
    intl: propTypes.object.isRequired,
    selectedClips: propTypes.arrayOf(propTypes.string).isRequired,
    comeFrom: propTypes.string.isRequired,
    open: propTypes.bool,
    handleOverridecloseModalExport: propTypes.func,
};
var ShareArticleDefaultProps = {
    open: null,
    handleOverridecloseModalExport: null,
};
function ShareArticle(_a) {
    var comeFrom = _a.comeFrom, reviewPress = _a.reviewPress, returntokiosk = _a.returntokiosk, open = _a.open, selectedClips = _a.selectedClips, formatMessage = _a.intl.formatMessage, handleOverridecloseModalExport = _a.handleOverridecloseModalExport;
    var setSnackbarObj = useContext(SnackbarContext).setSnackbarObj;
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var navigate = useNavigate();
    useEffect(function () {
        var onChange = function (type) {
            if (type === 'SAVE_PUSHMAIL_RESPONSE') {
                setSnackbarObj({
                    open: true,
                    status: 'success',
                    message: formatMessage({ id: 'Pushmail.mailSended' }),
                });
                if (handleOverridecloseModalExport) {
                    handleOverridecloseModalExport();
                }
                goToMonitoring(comeFrom)();
            }
            setLoading(false);
        };
        var onError = function () {
            setSnackbarObj({
                open: true,
                status: 'error',
                message: formatMessage({ id: 'Global.error' }),
            });
            setLoading(false);
        };
        PushmailStore.addChangeListener(onChange);
        PushmailStore.addErrorListener(onError);
        if (!PushmailStore.isInit()) {
            PushmailActionCreators.getUsersByGroups();
        }
        PushmailStore.pushmailThemes = [];
        if (PushmailStore.pushmailThemes.length === 0) {
            PushmailActionCreators.getPushmailData(comeFrom === 'Kiosk'
                ? {
                    isShared: true,
                    isNewsDigest: reviewPress.type === 'newsDigest',
                }
                : {});
        }
        else {
            setLoading(false);
        }
        return function () {
            PushmailStore.removeChangeListener(onChange);
            PushmailStore.removeErrorListener(onError);
        };
    }, []);
    var goToMonitoring = function (comeFrom) { return function () {
        if (comeFrom === 'Clipping') {
            navigate('/monitoring');
        }
    }; };
    var handleFormSave = function (model, callback) {
        var _a;
        var searchModel = SearchStore.buildSearchModel().searchModel;
        var customerClipsIds = [];
        if (comeFrom === 'Clipping') {
            customerClipsIds = selectedClips.map(function (clipId) {
                var _a = ClipStore.getClipById(clipId), id = _a.id, indexName = _a.indexName;
                return {
                    id: id,
                    indexName: indexName,
                };
            });
        }
        var reviewInfos = function () {
            var reviewCollection = [];
            if ((comeFrom === 'Kiosk' && reviewPress.type == 'review') ||
                (comeFrom != 'Clipping' &&
                    reviewPress &&
                    reviewPress.type != 'newsDigest' &&
                    reviewPress.id &&
                    reviewPress.indexName)) {
                reviewCollection.push({
                    id: reviewPress.id,
                    indexName: reviewPress.indexName,
                });
            }
            return reviewCollection.length === 0
                ? null
                : JSON.stringify(reviewCollection);
        };
        // TODO: check when integrating newsDigest
        var newsDigestInfos = function () {
            var newsDigestCollection = [];
            if (comeFrom === 'Kiosk' && reviewPress.type === 'newsDigest') {
                newsDigestCollection.push({
                    id: reviewPress.id,
                    indexName: reviewPress.indexName,
                });
            }
            return newsDigestCollection.length === 0
                ? null
                : JSON.stringify(newsDigestCollection);
        };
        var saveModel = {
            AsAttachment: false,
            CreatedUserId: null,
            Culture: km.getLang().toUpperCase(),
            CustomerClipIds: comeFrom === 'Clipping' && customerClipsIds.length > 0
                ? JSON.stringify(customerClipsIds)
                : null,
            EmailRecipients: model.externalRecepient.join(),
            ESReviewId: reviewInfos(),
            ESNewsDigestId: newsDigestInfos(),
            PushmailId: ((_a = model.pushmailTheme.pushmailId) === null || _a === void 0 ? void 0 : _a.toString()) || null,
            PushmailJobId: 0,
            // TODO: to check when exporting clips without review
            SelectedFilter: comeFrom === 'Clipping' && !reviewPress
                ? JSON.stringify({
                    Grouping: searchModel ? searchModel.groupBy : null,
                    SortingField: searchModel
                        ? searchModel.sortBy === ''
                            ? 'deliveredDate'
                            : searchModel.sortBy
                        : null,
                    SortBy: searchModel
                        ? searchModel.sortDirection === 0
                            ? 'asc'
                            : 'desc'
                        : null,
                })
                : {},
            Subject: model.subject,
            Comment: model.comment,
            User: null,
            UserIds: model.selectedUsers.join(),
            WithActiveLinks: model.withActiveLinks,
        };
        setLoading(true);
        PushmailActionCreators.savePushmail({ pushmail: saveModel }, callback);
    };
    var isNewsDigest = (reviewPress && reviewPress.type === 'newsDigest') || false;
    var isReview = (reviewPress && reviewPress.type === 'review') || false;
    if (open === false)
        return null;
    return (_jsx(ShareArticleForm, { isNewsDigest: isNewsDigest, isReview: isReview, pressReview: reviewPress, onFormSave: handleFormSave, comeFrom: comeFrom, selectedClips: selectedClips, loading: loading, returntokiosk: function () { return returntokiosk(); } }));
}
ShareArticle.propTypes = PropTypes;
ShareArticle.defaultProps = ShareArticleDefaultProps;
export default injectIntl(ShareArticle, { forwardRef: true });
