var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
var GET_TOP_VISUALIZATIONS_BY_SOURCE_EVENT = 'getTopVisualizationsBySource';
var GET_TOP_VISUALIZATIONS_BY_CLIP_EVENT = 'getTopVisualizationsByClip';
var GET_TOP_VISUALIZATIONS_BY_DATE_EVENT = 'getTopVisualizationsByDate';
var VisualizationStore = /** @class */ (function (_super) {
    __extends(VisualizationStore, _super);
    function VisualizationStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    VisualizationStore.prototype.initializeState = function () {
        this._visualizationsBySource = [];
        this._visualizationsByClip = [];
        this._visualizationsByDate = [];
    };
    VisualizationStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(VisualizationStore.prototype, "visualizationsBySource", {
        get: function () {
            return this._visualizationsBySource;
        },
        set: function (visualizationsBySource) {
            this._visualizationsBySource = visualizationsBySource;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VisualizationStore.prototype, "visualizationsByClip", {
        get: function () {
            return this._visualizationsByClip;
        },
        set: function (visualizationsByClip) {
            this._visualizationsByClip = visualizationsByClip;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VisualizationStore.prototype, "visualizationsByDate", {
        get: function () {
            return this._visualizationsByDate;
        },
        set: function (visualizationsByDate) {
            this._visualizationsByDate = visualizationsByDate;
        },
        enumerable: false,
        configurable: true
    });
    VisualizationStore.prototype.emitGetVisualizationsBySource = function () {
        this.emit(GET_TOP_VISUALIZATIONS_BY_SOURCE_EVENT);
    };
    VisualizationStore.prototype.addGetVisualizationsBySourceListener = function (callback) {
        this.on(GET_TOP_VISUALIZATIONS_BY_SOURCE_EVENT, callback);
    };
    VisualizationStore.prototype.removeGetVisualizationsBySourceListener = function (callback) {
        this.removeListener(GET_TOP_VISUALIZATIONS_BY_SOURCE_EVENT, callback);
    };
    VisualizationStore.prototype.emitGetVisualizationsByClip = function () {
        this.emit(GET_TOP_VISUALIZATIONS_BY_CLIP_EVENT);
    };
    VisualizationStore.prototype.addGetVisualizationsByClipListener = function (callback) {
        this.on(GET_TOP_VISUALIZATIONS_BY_CLIP_EVENT, callback);
    };
    VisualizationStore.prototype.removeGetVisualizationsByClipListener = function (callback) {
        this.removeListener(GET_TOP_VISUALIZATIONS_BY_CLIP_EVENT, callback);
    };
    VisualizationStore.prototype.emitGetVisualizationsByDate = function () {
        this.emit(GET_TOP_VISUALIZATIONS_BY_DATE_EVENT);
    };
    VisualizationStore.prototype.addGetVisualizationsByDateListener = function (callback) {
        this.on(GET_TOP_VISUALIZATIONS_BY_DATE_EVENT, callback);
    };
    VisualizationStore.prototype.removeGetVisualizationsByDateListener = function (callback) {
        this.removeListener(GET_TOP_VISUALIZATIONS_BY_DATE_EVENT, callback);
    };
    VisualizationStore.prototype.registerToActions = function (payload) {
        var _a, _b, _c;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_TOP_VISUALIZATIONS_BY_SOURCE_RESPONSE:
                if ((_a = action.json) === null || _a === void 0 ? void 0 : _a.data) {
                    var sortByCount = action.json.data.sort(function (a, b) { return b.count - a.count; });
                    this.visualizationsBySource = sortByCount;
                    this.initialized = true;
                    this.emitGetVisualizationsBySource();
                }
                else {
                    this.emitError('GET_TOP_VISUALIZATIONS_BY_SOURCE');
                }
                break;
            case ActionTypes.GET_TOP_VISUALIZATIONS_BY_CLIP_RESPONSE:
                if ((_b = action.json) === null || _b === void 0 ? void 0 : _b.data) {
                    var data = action.json.data;
                    var sortByCount = data.sort(function (a, b) { return b.count - a.count; });
                    this.visualizationsByClip = sortByCount;
                    this.initialized = true;
                    this.emitGetVisualizationsByClip();
                }
                else {
                    this.emitError('GET_TOP_VISUALIZATIONS_BY_CLIP');
                }
                break;
            case ActionTypes.GET_TOP_VISUALIZATIONS_BY_DATE_RESPONSE:
                if ((_c = action.json) === null || _c === void 0 ? void 0 : _c.data) {
                    var data = action.json.data;
                    var sortByCount = data.sort(function (a, b) { return b.count - a.count; });
                    this.visualizationsByDate = sortByCount;
                    this.initialized = true;
                    this.emitGetVisualizationsByDate();
                }
                else {
                    this.emitError('GET_TOP_VISUALIZATIONS_BY_DATE');
                }
                break;
            default:
                break;
        }
    };
    return VisualizationStore;
}(BaseStore));
export default new VisualizationStore();
