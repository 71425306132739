var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
var FilterContext = React.createContext();
function FilterProvider(props) {
    var initialValue = localStorage.getItem('isFilterOpen') === 'true' || true;
    var _a = useState(initialValue), isFilterOpen = _a[0], setIsFilterOpen = _a[1];
    var handleToggle = function (value) {
        window.localStorage.setItem('isFilterOpen', value);
        // this will trigger the dashboard grid resize
        window.dispatchEvent(new Event('resize'));
        setIsFilterOpen(value);
    };
    return (_jsx(FilterContext.Provider, __assign({ value: { isFilterOpen: isFilterOpen, setIsFilterOpen: handleToggle } }, props)));
}
export { FilterContext, FilterProvider };
