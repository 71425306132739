function isValidColor(color) {
    var option = new Option().style;
    option.color = color;
    return option.color !== '';
}
function binarySearchObj(arr, item, key) {
    var word = item[key];
    var left = 0;
    var right = arr.length - 1;
    while (left <= right) {
        var mid = Math.floor((left + right) / 2);
        if (arr[mid][key] === word) {
            return mid;
        }
        else if (arr[mid][key] < word) {
            left = mid + 1;
        }
        else {
            right = mid - 1;
        }
    }
    return left;
}
export { isValidColor, binarySearchObj };
