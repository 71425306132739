var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import ActionTypes from 'constants/actionTypes';
import BaseStore from 'stores/BaseStore';
var GET_INFOGRAPHY_EVENT = 'getInfography';
var GET_WIDGET_INFOGRAPHY_EVENT = 'getWidgetInfographyEvent';
var CONVERT_INFOGRAPHY_EVENT = 'convertInfographyEvent';
var INSERT_INFOGRAPHY = 'insertInfography';
var InfographyStore = /** @class */ (function (_super) {
    __extends(InfographyStore, _super);
    function InfographyStore() {
        var _this = _super.call(this) || this;
        _this.getPermalink = function () { return _this.permalink; };
        _this.invertData = function (data) {
            var _a, _b, _c, _d;
            var result = [];
            if (((_b = (_a = data.x_Axis) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.length) > 0 &&
                ((_c = data.y_Axis[0]) === null || _c === void 0 ? void 0 : _c.series[0].data.length) > 0) {
                result = data.x_Axis.data.reduce(function (o, k, i) { return __spreadArray(__spreadArray([], o, true), [
                    {
                        key: k || '',
                        value: data.y_Axis[0].series[0].data[i],
                    },
                ], false); }, []);
            }
            else {
                result = (_d = data.y_Axis[0]) === null || _d === void 0 ? void 0 : _d.series[0].data.reduce(function (o, k, i) {
                    var _a, _b;
                    return __spreadArray(__spreadArray([], o, true), [
                        {
                            key: '',
                            value: (_b = (_a = data.y_Axis[0]) === null || _a === void 0 ? void 0 : _a.series[0]) === null || _b === void 0 ? void 0 : _b.data[i],
                        },
                    ], false);
                }, []);
            }
            return result || [];
        };
        _this._infographyData = {};
        _this.permalink = '';
        _this._widgetData = {
            media: [],
            sentiment: [],
            authors: [],
            volumeTweets: [],
            volumeClips: [],
            sumAve: [],
            sumReachClips: [],
            clipsByTweets: [],
            highlights: [],
            facetHistogram: [],
            facetSupport: [],
            highestReach: [],
            facetAve: [],
            clipsByFacebook: [],
            clipsByYoutube: [],
            clipsByInstagram: [],
        };
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    Object.defineProperty(InfographyStore.prototype, "InfographyData", {
        get: function () {
            return this._infographyData;
        },
        set: function (data) {
            this._infographyData = data;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InfographyStore.prototype, "InfographyWidgetData", {
        get: function () {
            return this._widgetData;
        },
        set: function (data) {
            this._widgetData = data;
        },
        enumerable: false,
        configurable: true
    });
    InfographyStore.prototype.setSocialData = function (socialType, data) {
        var key = socialType;
        if (socialType === 'Twitter') {
            key = 'Tweets';
        }
        this.widgetData["clipsBy".concat(key)] = data;
    };
    InfographyStore.prototype.emitGetInfography = function (type) {
        this.emit(GET_INFOGRAPHY_EVENT, type);
    };
    InfographyStore.prototype.addGetInfographyListener = function (callback) {
        this.on(GET_INFOGRAPHY_EVENT, callback);
    };
    InfographyStore.prototype.removeGetInfographyListener = function (callback) {
        this.removeListener(GET_INFOGRAPHY_EVENT, callback);
    };
    InfographyStore.prototype.emitGetWidgetInfography = function () {
        this.emit(GET_WIDGET_INFOGRAPHY_EVENT);
    };
    InfographyStore.prototype.addGetWidgetInfography = function (callback) {
        this.on(GET_WIDGET_INFOGRAPHY_EVENT, callback);
    };
    InfographyStore.prototype.removeGetWidgetInfography = function (callback) {
        this.removeListener(GET_WIDGET_INFOGRAPHY_EVENT, callback);
    };
    InfographyStore.prototype.emitConvertInfography = function () {
        this.emit(CONVERT_INFOGRAPHY_EVENT);
    };
    InfographyStore.prototype.addConvertInfography = function (callback) {
        this.on(CONVERT_INFOGRAPHY_EVENT, callback);
    };
    InfographyStore.prototype.removeConvertInfography = function (callback) {
        this.removeListener(CONVERT_INFOGRAPHY_EVENT, callback);
    };
    InfographyStore.prototype.emitInsertInfography = function () {
        this.emit(INSERT_INFOGRAPHY);
    };
    InfographyStore.prototype.addInsertInfographyListener = function (callback) {
        this.on(INSERT_INFOGRAPHY, callback);
    };
    InfographyStore.prototype.removeInsertInfographyListener = function (callback) {
        this.removeListener(INSERT_INFOGRAPHY, callback);
    };
    InfographyStore.prototype.registerToActions = function (payload) {
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_INFOGRAPHIE_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.infographyData = data;
                    this.initialized = true;
                    this.emitGetInfography('GET_INFOGRAPHIE_RESPONSE');
                }
                else {
                    this.emitError('GET_INFOGRAPHY_RESPONSE');
                }
                break;
            case ActionTypes.GET_WIDGET_INFOGRAPHY_RESPONSE:
                if (action.json) {
                    var InfographyOperationType = action.json.model.InfographyOperationType;
                    var sourceType = action.json.model.queryInJsonParameters.TermFilters
                        ? action.json.model.queryInJsonParameters.TermFilters[0].Value
                        : '';
                    var deleteModel = action.json;
                    delete deleteModel.model;
                    var data = action.json.data.data;
                    switch (InfographyOperationType) {
                        case 'loadFacetsByMedia':
                            this.widgetData = __assign(__assign({}, this.widgetData), { media: this.invertData(data) });
                            break;
                        case 'loadFacetsBySentiment':
                            this.widgetData = __assign(__assign({}, this.widgetData), { sentiment: this.invertData(data) });
                            break;
                        case 'loadFacetsTweetsByAuthor':
                            this.widgetData = __assign(__assign({}, this.widgetData), { authors: this.invertData(data) });
                            break;
                        case 'loadVolumeTweets':
                            this.widgetData = __assign(__assign({}, this.widgetData), { volumeTweets: this.invertData(data) });
                            break;
                        case 'loadVolumeClips':
                            this.widgetData = __assign(__assign({}, this.widgetData), { volumeClips: this.invertData(data) });
                            break;
                        case 'loadSumAveClips':
                            this.widgetData = __assign(__assign({}, this.widgetData), { sumAve: this.invertData(data) });
                            break;
                        case 'loadSumReachClips':
                            this.widgetData = __assign(__assign({}, this.widgetData), { sumReachClips: this.invertData(data) });
                            break;
                        case 'loadClipsBySourceType':
                            this.setSocialData(sourceType, action.json.data);
                            break;
                        case 'loadHighlights':
                            this.widgetData = __assign(__assign({}, this.widgetData), { highlights: action.json.data });
                            break;
                        case 'loadFacetHistogram':
                            this.widgetData = __assign(__assign({}, this.widgetData), { facetHistogram: this.invertData(data) });
                            break;
                        case 'loadFacetsBySupport':
                            this.widgetData = __assign(__assign({}, this.widgetData), { facetSupport: this.invertData(data) });
                            break;
                        case 'loadFacetsByHighestReach':
                            this.widgetData = __assign(__assign({}, this.widgetData), { highestReach: this.invertData(data) });
                            break;
                        case 'loadFacetsByHighestAve':
                            this.widgetData = __assign(__assign({}, this.widgetData), { facetAve: this.invertData(data) });
                            break;
                        default:
                            break;
                    }
                    this.initialized = true;
                    this.emitGetWidgetInfography();
                }
                else {
                    this.emitError('GET_WIDGET_INFOGRAPHY_RESPONSE');
                }
                break;
            case ActionTypes.INSERT_INFOGRAPHY_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.infographyData = data;
                    this.emitInsertInfography('INSERT_INFOGRAPHY_RESPONSE');
                }
                else {
                    this.emitError('INSERT_INFOGRAPHY_RESPONSE');
                }
                break;
            case ActionTypes.CONVERT_INFOGRAPHY_TO_LINK_RESPONSE:
                if (action.json) {
                    this.permalink = action.json.Permalink;
                    this.emitConvertInfography();
                }
                else {
                    this.emitError('CONVERT_INFOGRAPHY_TO_LINK_RESPONSE');
                }
                break;
            default:
                break;
        }
    };
    return InfographyStore;
}(BaseStore));
export default new InfographyStore();
