var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import { FaRegCalendarAlt } from 'react-icons/fa';
import Radio from 'commons/Radio';
import Alert from 'commons/Alert';
import Button from 'components/Button';
import Spinner from 'commons/Spinner';
import Box from 'commons/Box';
import Select from 'commons/Select';
import DateSelector from 'commons/DateSelector';
import ReviewItem from 'new-components/ReviewItem';
import LoaderPlaceholder from 'commons/LoaderPlaceholder';
import ReviewActionCreators from 'actions/ReviewActionCreators';
import { convertDateToBackEndFormat } from 'services/AppUtils';
import ReviewStore from 'stores/ReviewStore';
import SearchStore from 'stores/SearchStore';
import { SnackbarContext } from 'context/SnackbarContext';
import debounce from 'utils/debounce';
import { Wrapper } from './LibraryReview.styles';
var propTypes = {
    intl: PropTypes.object.isRequired,
    embedded: PropTypes.bool,
    selectedReview: PropTypes.string,
    creationDate: PropTypes.instanceOf(Date),
    onCancel: PropTypes.func.isRequired,
    onReviewChange: PropTypes.func.isRequired,
    shouldFetch: PropTypes.bool,
    onSelectionChange: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    type: PropTypes.string,
    mode: PropTypes.string,
    setCheckIfValidate: PropTypes.func,
    setCountArticles: PropTypes.func,
    ticket: PropTypes.string,
};
var defaultProps = {
    embedded: false,
    selectedReview: '',
    creationDate: new Date(),
    shouldFetch: false,
    onSelectionChange: Function.prototype,
    type: '',
    mode: '',
    ticket: '',
    setCheckIfValidate: function () { },
    setCountArticles: function () { },
};
var CustomDatePickDiv = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: white;\n  border: solid 0.1em #cbd4c9;\n  border-radius: 0.25em;\n  padding: 0.3em 1.6em 0 1.6em;\n  .custom-date-icon {\n    border: 0;\n    background: unset;\n    margin-left: auto;\n  }\n"], ["\n  background-color: white;\n  border: solid 0.1em #cbd4c9;\n  border-radius: 0.25em;\n  padding: 0.3em 1.6em 0 1.6em;\n  .custom-date-icon {\n    border: 0;\n    background: unset;\n    margin-left: auto;\n  }\n"])));
var CustomInput = React.forwardRef(function (_a, ref) {
    var className = _a.className, onClick = _a.onClick, placeholder = _a.placeholder, value = _a.value;
    return (_jsxs(CustomDatePickDiv, { className: className, children: [_jsx("button", { className: "fromTo-datePicker-btn", onClick: onClick, ref: ref, children: value || placeholder }), _jsx("button", { onClick: onClick, className: "custom-date-icon", children: _jsx(FaRegCalendarAlt, {}) })] }));
});
CustomInput.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    setHashsArray: PropTypes.func,
};
CustomInput.defaultProps = {
    className: '',
    onClick: function () { },
    placeholder: '',
    value: '',
    setHashsArray: null,
};
var LibraryReview = /** @class */ (function (_super) {
    __extends(LibraryReview, _super);
    function LibraryReview(props) {
        var _this = _super.call(this, props) || this;
        _this.handleSelectAll = function () {
            var setCountArticles = _this.props.setCountArticles;
            var hashsArray = _this.state.hashsArray;
            var reviews = __spreadArray([], _this.state.reviewList, true);
            var newCheckedReviws = reviews.length === hashsArray.length
                ? []
                : reviews.map(function (_a) {
                    var hash = _a.hash;
                    return hash;
                });
            _this.setState({
                hashsArray: newCheckedReviws,
            }, function () {
                setCountArticles(_this.state.hashsArray.length);
            });
        };
        _this.handleCheckChieldElement = function (event) {
            event.stopPropagation();
            var _a = _this.props, mode = _a.mode, setCountArticles = _a.setCountArticles, setHashsArray = _a.setHashsArray;
            var _b = _this.state, customerReviewMode = _b.customerReviewMode, hashsArray = _b.hashsArray, reviewList = _b.reviewList;
            var onSelectionChange = _this.props.onSelectionChange;
            var reviewHash = event.currentTarget.id;
            var checkedReviewIndex = hashsArray.findIndex(function (hash) { return reviewHash === hash; });
            var newChekedReviews = __spreadArray([], hashsArray, true);
            if (checkedReviewIndex === -1) {
                if (customerReviewMode === 'hierarchy') {
                    newChekedReviews = [reviewHash];
                }
                else {
                    newChekedReviews.push(reviewHash);
                }
            }
            else {
                newChekedReviews.splice(checkedReviewIndex, 1);
            }
            _this.setState({
                hashsArray: newChekedReviews,
            }, function () {
                if (typeof setHashsArray === 'function') {
                    setHashsArray(newChekedReviews);
                }
                setCountArticles(newChekedReviews.length);
                if (typeof onSelectionChange === 'function') {
                    onSelectionChange('source.review', newChekedReviews);
                }
                if (mode === 'advancedFiltertools') {
                    var finalHashs = reviewList.filter(function (elm) { return elm.isChecked; });
                    finalHashs = finalHashs.map(function (_a) {
                        var hash = _a.hash;
                        return hash;
                    });
                    SearchStore.setHash(finalHashs);
                }
            });
        };
        _this.handleOnClick = function () {
            _this.setState({
                expandedDropDown: !_this.state.expandedDropDown,
            });
        };
        _this.handleDateSelectorChange = function (mode, dates) {
            var _a = _this.state, showReview = _a.showReview, showDraft = _a.showDraft;
            if (mode === 'custom' &&
                (!dates.universalDateFrom || !dates.universalDateTo)) {
                return;
            }
            var startDate = moment(dates.universalDateFrom).startOf('day').toDate();
            var endDate = new Date(dates.universalDateTo);
            var universalStartDate = convertDateToBackEndFormat(startDate);
            var universalEndDate = convertDateToBackEndFormat(endDate);
            _this.setState({
                startDate: startDate,
                endDate: endDate,
            }, function () {
                _this.getReviews({
                    universalDateFrom: universalStartDate,
                    universalDateTo: universalEndDate,
                });
            });
            ReviewStore.setReviewModel({
                model: {
                    UniversalDateFrom: universalStartDate,
                    UniversalDateTo: universalEndDate,
                    showReview: showReview,
                    showDraft: showDraft,
                },
                type: mode,
                selectType: _this.state.customerReviewMode,
            });
        };
        _this.renderItem = function (item) {
            var _a = _this.state, hashsArray = _a.hashsArray, customerReviewMode = _a.customerReviewMode;
            return (_jsx(ReviewItem, { id: item.hash, name: item.title, totalClips: item.totalClips, createdDate: item.creationDate, withCheckbox: customerReviewMode !== 'hierarchy', checked: hashsArray.includes(item.hash), author: item.author, onChange: function (e) { return _this.handleCheckChieldElement(e); } }, item.hash));
        };
        _this.debouncedGetReviewType = debounce(function (input) { return _this.handleSetSideLiseFilter(input); }, 1000);
        _this.handleSetSideLiseFilter = function () {
            var reviewModel = ReviewStore.reviewModel;
            var model = reviewModel.model;
            _this.getReviews({
                universalDateFrom: model.UniversalDateFrom,
                universalDateTo: model.UniversalDateTo,
            });
            ReviewStore.setReviewModel(__assign(__assign({}, reviewModel), { model: __assign(__assign({}, model), { showReview: _this.state.showReview, showDraft: _this.state.showDraft }) }));
        };
        _this.handleChangesRevueType = function (key) {
            if (key === 'showReview') {
                _this.setState({
                    showReview: true,
                    showDraft: false,
                    hashsArray: [],
                }, function () {
                    _this.debouncedGetReviewType({ type: 'showReview' });
                });
            }
            else {
                _this.setState({
                    showDraft: true,
                    showReview: false,
                    hashsArray: [],
                }, function () {
                    _this.debouncedGetReviewType({ type: 'showDraft' });
                });
            }
        };
        _this.handleChangeCustomerReviewMode = function (event) {
            _this.setState({ customerReviewMode: event.target.value, hashsArray: [] }, function () {
                ReviewStore.setReviewModelType(_this.state.customerReviewMode);
            });
        };
        _this.handlePageChange = function (page) {
            _this.setState({ page: page.value });
        };
        _this.handlePerPageChange = function (event) {
            _this.setState({ perPage: event.target.value });
        };
        _this.handlePreviousPageClick = function () {
            _this.setState(function (_a) {
                var prevPage = _a.page;
                var previousPage = prevPage - 1;
                return { page: previousPage < 0 ? 0 : previousPage };
            });
        };
        _this.getNextReviews = function () {
            var _a = _this.state, startDate = _a.startDate, endDate = _a.endDate;
            _this.setState(function (prevState) { return (__assign(__assign({}, prevState), { skip: (prevState.skip || 0) + 20 })); });
            _this.getReviews({
                universalDateFrom: convertDateToBackEndFormat(startDate),
                universalDateTo: convertDateToBackEndFormat(endDate),
                shouldSkip: true,
            });
            _this.setState({
                nextLoading: true,
            });
        };
        _this.renderLoader = function () {
            return Array(6)
                .fill('')
                .map(function (_, index) { return _jsx(LoaderPlaceholder, { variant: "row" }, index); });
        };
        _this.disableFutureDt = function (current) {
            var today = moment();
            return current.isBefore(today);
        };
        _this.searchHash = '';
        var _a = ReviewStore.reviewModel, model = _a.model, type = _a.type;
        var startDate = moment(model === null || model === void 0 ? void 0 : model.UniversalDateFrom, 'YYYYMMDDHHmmss').toDate();
        var endDate = moment(model === null || model === void 0 ? void 0 : model.UniversalDateTo, 'YYYYMMDDHHmmss').toDate();
        var customerReviewMode = SearchStore.getCustomerReviewMode() || 'hierarchy';
        _this.state = {
            loading: false,
            reviewList: [],
            hashsArray: [],
            showDatePicker: false,
            expandedDropDown: false,
            startDate: startDate,
            endDate: endDate,
            showDraft: false,
            showReview: true,
            customerReviewMode: customerReviewMode,
            total: 20,
            logicalDate: type || 'today',
            skip: 0,
        };
        _this.handleClosePopover = _this.handleClosePopover.bind(_this);
        _this.handleDateIconClick = _this.handleDateIconClick.bind(_this);
        _this.getReviews = _this.getReviews.bind(_this);
        _this.handleValidation = _this.handleValidation.bind(_this);
        _this.onChange = _this.onChange.bind(_this);
        _this.debouncedGetReviewType = _this.debouncedGetReviewType.bind(_this);
        _this.handleSetSideLiseFilter = _this.handleSetSideLiseFilter.bind(_this);
        _this.handleChangeCustomerReviewMode =
            _this.handleChangeCustomerReviewMode.bind(_this);
        _this.handlePerPageChange = _this.handlePerPageChange.bind(_this);
        _this.handlePageChange = _this.handlePageChange.bind(_this);
        _this.handlePreviousPageClick = _this.handlePreviousPageClick.bind(_this);
        _this.getNextReviews = _this.getNextReviews.bind(_this);
        _this.isPublic =
            window.location.href.toLocaleLowerCase().search('/public') !== -1;
        return _this;
    }
    LibraryReview.prototype.componentDidMount = function () {
        var _a = this.props, creationDate = _a.creationDate, shouldFetch = _a.shouldFetch, embedded = _a.embedded, selectedReview = _a.selectedReview;
        var isDraft = SearchStore.getCustomerReviewType();
        if (embedded && selectedReview) {
            var date = selectedReview.date;
            if (date && shouldFetch) {
                this.getReviews(new Date(date));
            }
        }
        else if (embedded) {
            if (creationDate && shouldFetch) {
                this.getReviews(creationDate);
            }
        }
        var reviewHashs = SearchStore.getHash();
        var draftHashs = SearchStore.getDraftId();
        var showOptions = {
            showDraft: false,
            showReview: true,
        };
        switch (isDraft) {
            case null:
                this.setState(showOptions);
                break;
            case 'draft':
                showOptions.showDraft = true;
                showOptions.showReview = false;
                this.setState(__assign(__assign({}, showOptions), { hashsArray: draftHashs }));
                break;
            case 'review':
                this.setState(__assign(__assign({}, showOptions), { hashsArray: reviewHashs }));
                break;
            default:
                break;
        }
        var reviewModel = ReviewStore.reviewModel;
        this.getReviews(__assign({ universalDateFrom: reviewModel.model.UniversalDateFrom, universalDateTo: reviewModel.model.UniversalDateTo }, showOptions));
        ReviewStore.setReviewModelType(this.state.customerReviewMode);
        ReviewStore.addChangeListener(this.onChange);
    };
    LibraryReview.getDerivedStateFromProps = function (nextProps) {
        var type = nextProps.type;
        var reviewModel = ReviewStore.reviewModel;
        if (type === 'allArticle') {
            return {
                logicalDate: 'today',
            };
        }
        return {
            logicalDate: !reviewModel.type ? 'today' : reviewModel.type,
        };
    };
    LibraryReview.prototype.componentWillUnmount = function () {
        ReviewStore.removeChangeListener(this.onChange);
    };
    LibraryReview.prototype.onChange = function (hash) {
        var searchModel = JSON.parse(localStorage.getItem('searchModel')).searchModel;
        var hashs = searchModel.Hashs;
        var list = ReviewStore.getSearchByHash(hash);
        var elmReviews = [];
        list.forEach(function (item) {
            var elm = {
                title: item.title,
                author: item.author,
                creationDate: item.creationDate,
                totalClips: item.totalClips,
                hash: item.hash,
                isChecked: hashs.includes(item.hash),
                isDraft: item.isDraft,
            };
            elmReviews.push(elm);
        });
        var total = ReviewStore.getReviewsTotal();
        this.setState({
            loading: false,
            nextLoading: false,
            reviewList: elmReviews,
            total: total,
        });
    };
    LibraryReview.prototype.getReviews = function (_a) {
        var universalDateFrom = _a.universalDateFrom, universalDateTo = _a.universalDateTo, showReview = _a.showReview, showDraft = _a.showDraft, shouldSkip = _a.shouldSkip;
        var _b = this.state, showReviewState = _b.showReview, showDraftState = _b.showDraft, reviewList = _b.reviewList;
        var skip = reviewList.length;
        var model = {
            UniversalDateFrom: typeof universalDateFrom === 'string'
                ? universalDateFrom
                : convertDateToBackEndFormat(universalDateFrom),
            UniversalDateTo: typeof universalDateTo === 'string'
                ? universalDateTo
                : convertDateToBackEndFormat(universalDateTo),
            showReview: showReview !== null && showReview !== void 0 ? showReview : showReviewState,
            showDraft: showDraft !== null && showDraft !== void 0 ? showDraft : showDraftState,
            skip: shouldSkip ? skip : null,
        };
        var newState = shouldSkip ? { nextLoading: true } : { loading: true };
        this.setState(newState);
        var searchHash = ReviewStore.getSearchHash(model);
        if (searchHash && !shouldSkip) {
            this.onChange(searchHash);
        }
        else {
            ReviewActionCreators.getReviews(model);
        }
    };
    LibraryReview.prototype.handleClosePopover = function () {
        this.setState({ showDatePicker: false });
    };
    LibraryReview.prototype.handleDateIconClick = function () {
        var showDatePicker = this.state.showDatePicker;
        this.setState({
            showDatePicker: !showDatePicker,
        });
    };
    LibraryReview.prototype.handleValidation = function () {
        var _a = this.props, onCancel = _a.onCancel, onReviewChange = _a.onReviewChange, setCheckIfValidate = _a.setCheckIfValidate;
        var _b = this.state, showDraft = _b.showDraft, hashsArray = _b.hashsArray, customerReviewMode = _b.customerReviewMode;
        setCheckIfValidate(true);
        if (showDraft) {
            SearchStore.setCustomerReviewType('draft');
            SearchStore.setHash([]);
        }
        else {
            SearchStore.setCustomerReviewType('review');
            SearchStore.setDraftId([]);
        }
        SearchStore.setCustomerReviewMode(customerReviewMode);
        onReviewChange({
            hash: hashsArray,
            showDraft: showDraft,
            customerReviewMode: customerReviewMode,
        });
        localStorage.setItem('modePage', 'review');
        onCancel();
    };
    LibraryReview.prototype.renderList = function () {
        var _this = this;
        var _a = this.state, reviewList = _a.reviewList, skip = _a.skip, total = _a.total, loading = _a.loading;
        return (_jsx(Fragment, { children: reviewList && reviewList.length > 0 ? (_jsx(InfiniteScroll, { className: "c-infiniteScroll", dataLength: reviewList.length, next: this.getNextReviews, hasMore: reviewList.length < total, scrollableTarget: "library-review__body", loader: this.renderLoader(), children: loading && !skip ? (this.renderLoader()) : (_jsx("ul", { children: reviewList.map(function (reviewItem) { return _this.renderItem(reviewItem); }) })) })) : (_jsx("div", { className: "p-top--medium library__review-info", children: _jsx(Alert, { type: "info", children: _jsx(FormattedMessage, { id: "LibraryReview.noReview" }) }) })) }));
    };
    LibraryReview.prototype.render = function () {
        var _this = this;
        var _a = this.props, _b = _a.intl, formatMessage = _b.formatMessage, formatDate = _b.formatDate, embedded = _a.embedded;
        var _c = this.state, loading = _c.loading, showReview = _c.showReview, showDraft = _c.showDraft, hashsArray = _c.hashsArray, customerReviewMode = _c.customerReviewMode, startDate = _c.startDate, endDate = _c.endDate, logicalDate = _c.logicalDate;
        var isAtLeastOnReviewChecked = hashsArray.length;
        return (_jsxs(Wrapper, { className: "library-review__container", children: [_jsxs(Box, { display: "flex", flexDirection: "row", className: "library-review__action-row", children: [_jsxs("div", { className: "library-review--radio-action", children: [_jsx(Radio, { className: "m-right--medium", checked: showReview, id: "showReview", name: "content", onChange: function () { return _this.handleChangesRevueType('showReview'); }, children: _jsx(FormattedMessage, { id: "Kiosk.header.showReview" }) }), _jsx(Radio, { className: "m-right--medium", checked: showDraft, id: "showDraft", name: "content", onChange: function () { return _this.handleChangesRevueType('showDraft'); }, children: _jsx(FormattedMessage, { id: "Kiosk.header.showDraft" }) })] }), _jsx(Box, { flex: "1", display: "flex", alignItems: "flex-start", flexDirection: "column", padding: "0 10px", className: "library__review-first-section", children: _jsx(Select, { className: "paginator-select", id: "per-page-select", onChange: this.handleChangeCustomerReviewMode, options: [
                                    {
                                        label: formatMessage({
                                            id: 'Monitoring.Reviews.articleList',
                                        }),
                                        value: 'articleList',
                                    },
                                    {
                                        label: formatMessage({
                                            id: 'Monitoring.Reviews.hierarchy',
                                        }),
                                        value: 'hierarchy',
                                    },
                                ], value: customerReviewMode }) }), _jsx(Box, { display: "flex", flexDirection: "column", width: "0.5", smWidth: "max-content", padding: "0 10px", children: _jsx(DateSelector, { className: "qa-add-article-source", onChange: this.handleDateSelectorChange, dates: {
                                    universalDateFrom: formatDate(startDate, {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    }),
                                    universalDateTo: formatDate(endDate, {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    }),
                                }, dateMode: logicalDate, hideDateType: true, unlinkSearchModel: true }) })] }), _jsxs("div", { className: "library-review__layout", children: [_jsx("div", { id: "library-review__body", className: "library-review__body new-scrollbar", children: loading ? _jsx(Spinner, { fullPage: true }) : this.renderList() }), (!embedded && (_jsxs("div", { className: "pressReveiw-fromModal", children: [_jsx("span", { className: "selected-filter-count", children: showReview
                                        ? formatMessage({
                                            id: 'Monitoring.articlesFromPressReview.SelectedReviews',
                                        }, {
                                            countReviewItems: hashsArray.length,
                                        })
                                        : formatMessage({
                                            id: 'Monitoring.articlesFromPressReview.SelectedDrafts',
                                        }, {
                                            countDraftItems: hashsArray.length,
                                        }) }), _jsx(Button, { disabled: !isAtLeastOnReviewChecked, onClick: this.handleValidation, type: "primary", title: "Validate button", className: "validation-button", children: _jsx(FormattedMessage, { id: "Monitoring.articlesFromPressReview.openReviewButton" }) })] }))) ||
                            null] })] }));
    };
    LibraryReview.contextType = SnackbarContext;
    return LibraryReview;
}(Component));
LibraryReview.defaultProps = defaultProps;
LibraryReview.propTypes = propTypes;
export default injectIntl(LibraryReview, { forwardRef: true });
var templateObject_1;
