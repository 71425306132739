var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, } from 'react';
import { DragDropContext, } from 'react-beautiful-dnd';
import Col from '@commons/Col';
import parameters from '@constants/parameters';
import * as Utils from '@services/AppUtils';
import DualListBoxControls from './DualListBoxControls';
import DualListBoxGroup from './DualListBoxGroup/DualListBoxGroup';
import { Wrapper } from './DualListBox.styles';
var BREAKPOINT = parameters.BREAKPOINT;
var DualListBox = forwardRef(function (_a, ref) {
    var availableItemsProp = _a.availableItems, displayedItemsProp = _a.displayedItems, availableSectionTitle = _a.availableSectionTitle, displayedSectionTitle = _a.displayedSectionTitle;
    var availableItemsRef = useRef(availableItemsProp);
    var displayedItemsRef = useRef(displayedItemsProp);
    var selectedItemsRef = useRef([]);
    var _b = useState({
        availableItems: availableItemsProp,
        displayedItems: displayedItemsProp,
        selectedItems: [],
        draggableId: null,
        isDragging: false,
        availableSearchTerm: '',
        displayedSearchTerm: '',
        windowWidth: window.innerWidth,
    }), state = _b[0], setState = _b[1];
    var availableItems = state.availableItems, displayedItems = state.displayedItems, selectedItems = state.selectedItems, isDragging = state.isDragging, draggableId = state.draggableId, displayedSearchTerm = state.displayedSearchTerm, windowWidth = state.windowWidth;
    var reOrder = function (list, startPosition, endPosition) {
        var result = Array.from(list);
        var removed = result.splice(startPosition, 1)[0];
        result.splice(endPosition, 0, removed);
        for (var i = 0; i < result.length; i += 1) {
            result[i].position = i + 1;
        }
        return result.map(function (item) { return (__assign({}, item)); });
    };
    var multipleReOrder = function (selectedItemsArr, items, endIndex) {
        var result = __spreadArray([], items, true);
        var selectedItem = {};
        var itemsRemovedCount = 0;
        items.forEach(function (item) {
            if (selectedItemsArr.includes(item.id)) {
                var indexToRemove = items === null || items === void 0 ? void 0 : items.findIndex(function (element) { return element.id === item.id; });
                result.splice(indexToRemove - itemsRemovedCount, 1);
                itemsRemovedCount += 1;
            }
        });
        selectedItemsArr.reverse().forEach(function (item) {
            selectedItem = items.find(function (element) { return item === element.id; });
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            result.splice(endIndex - 1, 0, selectedItem);
        });
        for (var i = 0; i < result.length; i += 1) {
            result[i].position = i + 1;
        }
        return result;
    };
    var multipleSwitchGroup = function (selectedItemsArr, startList, endList, endIndex) {
        var startResult = __spreadArray([], startList, true);
        var endResult = __spreadArray([], endList, true);
        var selectedItem = {};
        var itemsRemovedCount = 0;
        startList.forEach(function (item) {
            if (selectedItemsArr.includes(item.id)) {
                var indexToRemove = startList === null || startList === void 0 ? void 0 : startList.findIndex(function (element) { return element.id === item.id; });
                startResult.splice(indexToRemove - itemsRemovedCount, 1);
                itemsRemovedCount += 1;
            }
        });
        selectedItemsArr.reverse().forEach(function (item) {
            selectedItem = startList.find(function (element) { return item === element.id; });
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            endResult.splice(endIndex, 0, selectedItem);
        });
        return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            startItems: startResult,
            endItems: endResult,
        };
    };
    var switchGroup = function (endList, startList, startPosition, endPosition) {
        var start = Array.from(startList);
        var result = Array.from(endList);
        // remove item from startList
        var removed = start.splice(startPosition, 1)[0];
        // add item to endList
        result.splice(endPosition, 0, removed);
        for (var i = 0; i < start.length; i += 1) {
            start[i].position = i + 1;
        }
        for (var i = 0; i < result.length; i += 1) {
            result[i].position = i + 1;
        }
        return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            startItems: start.map(function (item) { return (__assign({}, item)); }),
            endItems: result.map(function (item) { return (__assign({}, item)); }),
        };
    };
    useEffect(function () {
        window.addEventListener('resize', onResize);
        return function () {
            window.removeEventListener('resize', onResize);
        };
    }, []);
    useEffect(function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { availableItems: availableItemsProp, displayedItems: displayedItemsProp.sort(function (a, b) {
                return a.position > b.position ? 1 : -1;
            }) })); });
        if (availableItemsRef.current.length === 0) {
            availableItemsRef.current = availableItemsProp;
        }
        if (displayedItemsRef.current.length === 0) {
            displayedItemsRef.current = displayedItemsProp;
        }
    }, [availableItemsProp, displayedItemsProp]);
    useEffect(function () {
        var index = availableItems === null || availableItems === void 0 ? void 0 : availableItems.findIndex(function (item) {
            return selectedItems.includes(item.id);
        });
        if (index) {
            var optionsElement = document.getElementById('DualListBoxGroup_1_options');
            if (typeof (optionsElement === null || optionsElement === void 0 ? void 0 : optionsElement.scrollTo) === 'function') {
                optionsElement.scrollTo(0, index * 35);
            }
        }
    }, [availableItems]);
    useEffect(function () {
        var _a;
        if (selectedItems.includes((_a = displayedItems[displayedItems.length - 1]) === null || _a === void 0 ? void 0 : _a.id)) {
            var optionsElement = document.getElementById('DualListBoxGroup_2_options');
            if (typeof (optionsElement === null || optionsElement === void 0 ? void 0 : optionsElement.scrollTo) === 'function') {
                optionsElement.scrollTo(0, 5000);
            }
        }
    }, [displayedItems]);
    var onResize = function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { windowWidth: window.innerWidth })); });
    };
    var onAvailableSearchChangeHandler = function (value) {
        var result = availableItemsRef.current
            .filter(function (item) { var _a; return (_a = item.label) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(value.toLowerCase()); })
            .sort(function (a, b) {
            return Utils.sortStringAlphabetically(a, b, 'label');
        });
        var selectedItemsArr = [];
        result.forEach(function (item) {
            if (selectedItemsRef.current.includes(item.id)) {
                selectedItemsArr.push(String(item.id));
            }
        });
        setState(function (prevState) { return (__assign(__assign({}, prevState), { availableSearchTerm: value, availableItems: result, selectedItems: selectedItemsArr })); });
    };
    var onDisplayedSearchChangeHandler = function (value) {
        var result = displayedItemsRef.current
            .filter(function (item) { var _a; return (_a = item.label) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(value.toLowerCase()); })
            .sort(function (a, b) {
            return Utils.sortStringAlphabetically(a, b, 'label');
        });
        var selectedItemsArr = [];
        result.forEach(function (item) {
            if (selectedItemsRef.current.includes(item.id)) {
                selectedItemsArr.push(String(item.id));
            }
        });
        setState(function (prevState) { return (__assign(__assign({}, prevState), { displayedSearchTerm: value, displayedItems: result, selectedItems: selectedItemsArr })); });
    };
    var onDragStart = function (target) {
        var draggableId = target.draggableId, source = target.source;
        setState(function (prevState) { return (__assign(__assign({}, prevState), { isDragging: true, draggableId: draggableId })); });
        if (source.droppableId === 'DualListBoxGroup_1') {
            if (!selectedItems.includes(draggableId)) {
                handleAvailableItemClick(draggableId);
            }
        }
        else if (!selectedItems.includes(draggableId)) {
            handleDisplayedItemClick(draggableId);
        }
    };
    var onDragEnd = function (result) {
        var destination = result.destination, source = result.source;
        var switchGroupResult = {};
        var items = [];
        if (!destination || result.reason === 'CANCEL') {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { isDragging: false })); });
            return;
        }
        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { isDragging: false })); });
            return;
        }
        if (source.droppableId === 'DualListBoxGroup_1' &&
            destination.droppableId === 'DualListBoxGroup_1') {
            if (selectedItems.length > 1) {
                items = multipleReOrder(selectedItems, availableItemsRef.current, destination.index);
            }
            else {
                items = reOrder(availableItemsRef.current, source.index, destination.index);
            }
            availableItemsRef.current = items;
            setState(function (prevState) { return (__assign(__assign({}, prevState), { availableItems: items, isDragging: false })); });
        }
        else if (source.droppableId === 'DualListBoxGroup_2' &&
            destination.droppableId === 'DualListBoxGroup_2') {
            if (selectedItems.length > 1) {
                items = multipleReOrder(selectedItems, displayedItemsRef.current, destination.index);
            }
            else {
                items = reOrder(displayedItemsRef.current, source.index, destination.index);
            }
            displayedItemsRef.current = items;
            setState(function (prevState) { return (__assign(__assign({}, prevState), { displayedItems: items, isDragging: false })); });
        }
        else if (source.droppableId === 'DualListBoxGroup_1' &&
            destination.droppableId === 'DualListBoxGroup_2') {
            if (selectedItems.length > 1) {
                switchGroupResult = multipleSwitchGroup(selectedItems, availableItemsRef.current, displayedItemsRef.current, destination.index);
            }
            else {
                switchGroupResult = switchGroup(displayedItemsRef.current, availableItemsRef.current, source.index, destination.index);
            }
            availableItemsRef.current = switchGroupResult.startItems;
            displayedItemsRef.current = switchGroupResult.endItems;
            setState(function (prevState) { return (__assign(__assign({}, prevState), { availableItems: switchGroupResult.startItems, displayedItems: switchGroupResult.endItems, isDragging: false })); });
        }
        else {
            if (selectedItems.length > 1) {
                switchGroupResult = multipleSwitchGroup(selectedItems, displayedItemsRef.current, availableItemsRef.current, destination.index);
            }
            else {
                switchGroupResult = switchGroup(availableItemsRef.current, displayedItemsRef.current, source.index, destination.index);
            }
            for (var i = 0; i < switchGroupResult.endItems.length; i += 1) {
                switchGroupResult.endItems[i].position = i + 1;
            }
            availableItemsRef.current = switchGroupResult.endItems;
            displayedItemsRef.current = switchGroupResult.startItems;
            setState(function (prevState) { return (__assign(__assign({}, prevState), { availableItems: availableItemsRef.current, displayedItems: displayedItemsRef.current, isDragging: false })); });
        }
    };
    var onSortItem = function (direction) {
        var onSort = function (items) {
            var result = [];
            var foundGap = false;
            items.forEach(function (item, index) {
                if (selectedItems.includes(item.id)) {
                    if (index > 0) {
                        if (foundGap) {
                            var copyItem = __assign({}, result[index - 1]);
                            result.splice(index - 1, 1);
                            result.push(item);
                            result.push(copyItem);
                        }
                        else {
                            result.push(item);
                        }
                    }
                    else {
                        result.push(item);
                    }
                }
                else {
                    foundGap = true;
                    result.push(item);
                }
            });
            return result;
        };
        var result = [];
        var items = __spreadArray([], displayedItemsRef.current, true);
        if (direction === 'up') {
            result = onSort(items);
        }
        else {
            items = items.reverse();
            result = onSort(items);
            result = result.reverse();
        }
        for (var i = 0; i <= result.length - 1; i += 1) {
            result[i].position = i + 1;
        }
        displayedItemsRef.current = result;
        setState(function (prevState) { return (__assign(__assign({}, prevState), { displayedItems: result })); });
    };
    var onMoveItem = function (direction) {
        var newState = {};
        if (direction === 'right') {
            var newItems_1 = [];
            availableItems.forEach(function (item) {
                if (selectedItems.includes(item.id)) {
                    newItems_1.push(item);
                }
            });
            newState.displayedItems = __spreadArray(__spreadArray([], displayedItems, true), newItems_1, true);
            newState.availableItems = availableItems.filter(function (_a) {
                var id = _a.id;
                return !selectedItems.includes(id);
            });
            for (var i = 0; i < newState.displayedItems.length; i += 1) {
                newState.displayedItems[i].position = i + 1;
            }
            setState(function (prevState) { return (__assign(__assign({}, prevState), newState)); });
            availableItemsRef.current = availableItemsRef.current.filter(function (_a) {
                var id = _a.id;
                return !selectedItems.includes(id);
            });
            displayedItemsRef.current = __spreadArray(__spreadArray([], displayedItemsRef.current, true), newItems_1, true);
        }
        else {
            var newItems_2 = [];
            displayedItems.forEach(function (item) {
                if (selectedItems.includes(item.id)) {
                    newItems_2.push(item);
                }
            });
            newState.availableItems = __spreadArray(__spreadArray([], availableItems, true), newItems_2, true).sort(function (a, b) { return Utils.sortStringAlphabetically(a, b, 'label'); });
            for (var i = 0; i < newState.availableItems.length; i += 1) {
                newState.availableItems[i].position = i + 1;
            }
            newState.displayedItems = displayedItemsRef.current.reduce(function (previous, item) {
                return selectedItems.includes(item.id)
                    ? previous
                    : __spreadArray(__spreadArray([], previous, true), [__assign(__assign({}, item), { position: previous.length + 1 })], false);
            }, []);
            displayedItemsRef.current = displayedItemsRef.current.filter(function (_a) {
                var id = _a.id;
                return !selectedItems.includes(id);
            });
            availableItemsRef.current = __spreadArray(__spreadArray([], availableItemsRef.current, true), newItems_2, true);
            setState(function (prevState) { return (__assign(__assign({}, prevState), newState)); });
        }
    };
    var handleToggle = function (id, idOption) {
        var newState = {};
        if (idOption === 'DualListBoxGroup_1') {
            var newItems_3 = [];
            availableItems.forEach(function (item) {
                if (id === Number(item.id)) {
                    newItems_3.push(item);
                }
            });
            newState.displayedItems = __spreadArray(__spreadArray([], displayedItems, true), newItems_3, true);
            newState.availableItems = availableItems.filter(function (elm) { return Number(elm.id) !== id; });
            for (var i = 0; i < newState.displayedItems.length; i += 1) {
                newState.displayedItems[i].position = i + 1;
            }
            setState(function (prevState) { return (__assign(__assign({}, prevState), newState)); });
            availableItemsRef.current = availableItemsRef.current.filter(function (elm) { return Number(elm.id) !== id; });
            displayedItemsRef.current = __spreadArray(__spreadArray([], displayedItemsRef.current, true), newItems_3, true);
        }
        else {
            var newItems_4 = [];
            displayedItems.forEach(function (item) {
                if (id === Number(item.id)) {
                    newItems_4.push(item);
                }
            });
            newState.availableItems = __spreadArray(__spreadArray([], availableItems, true), newItems_4, true).sort(function (a, b) { return Utils.sortStringAlphabetically(a, b, 'label'); });
            for (var i = 0; i < newState.availableItems.length; i += 1) {
                newState.availableItems[i].position = 0;
            }
            newState.displayedItems = displayedItemsRef.current.reduce(function (previous, item) {
                return id === Number(item.id)
                    ? previous
                    : __spreadArray(__spreadArray([], previous, true), [__assign(__assign({}, item), { position: previous.length + 1 })], false);
            }, []);
            displayedItemsRef.current = displayedItemsRef.current.filter(function (elm) { return Number(elm.id) !== id; });
            availableItemsRef.current = __spreadArray(__spreadArray([], availableItemsRef.current, true), newItems_4, true);
            setState(function (prevState) { return (__assign(__assign({}, prevState), newState)); });
        }
    };
    var handleAvailableItemClick = function (id, event) {
        var foundSelectedItem = false;
        var result = [];
        if (event === null || event === void 0 ? void 0 : event.ctrlKey) {
            result = selectedItems || [];
            if (selectedItems && selectedItems.includes(id)) {
                var position_1 = null;
                result.forEach(function (item, index) {
                    if (item === id) {
                        position_1 = index + 1;
                    }
                });
                if (position_1 !== null)
                    result.splice(position_1, 1);
            }
            else {
                result.push(id);
            }
        }
        else if (event === null || event === void 0 ? void 0 : event.shiftKey) {
            selectedItems.forEach(function (item) {
                var selectedItem = availableItems.find(function (element) { return element.id === item; });
                if (selectedItem)
                    foundSelectedItem = true;
            });
            if (selectedItems.length > 0 && foundSelectedItem) {
                selectedItems.push(id);
                var indexes_1 = [];
                availableItems.forEach(function (item, index) {
                    if (selectedItems.includes(item.id)) {
                        indexes_1.push(index);
                    }
                });
                availableItems.forEach(function (item, index) {
                    if (index >= Math.min.apply(Math, indexes_1) &&
                        index <= Math.max.apply(Math, indexes_1)) {
                        if (!result.includes(item.id)) {
                            result.push(item.id);
                        }
                    }
                });
            }
            else {
                var added_1 = false;
                availableItems.forEach(function (item) {
                    if (!added_1) {
                        result.push(item.id);
                    }
                    if (id === item.id) {
                        added_1 = true;
                    }
                });
            }
        }
        else if (selectedItems && selectedItems.includes(id)) {
            if (selectedItems.length === 1) {
                result = [];
            }
            else {
                result.push(id);
            }
        }
        else {
            result.push(id);
        }
        selectedItemsRef.current = result;
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: result })); });
    };
    var handleDisplayedItemClick = function (id, event) {
        var foundSelectedItem = false;
        var result = [];
        if (event === null || event === void 0 ? void 0 : event.ctrlKey) {
            result = selectedItems || [];
            if (selectedItems && selectedItems.includes(id)) {
                var position_2 = null;
                result.forEach(function (item, index) {
                    if (item === id) {
                        position_2 = index + 1;
                    }
                });
                if (position_2 !== null)
                    result.splice(position_2, 1);
            }
            else {
                result.push(id);
            }
        }
        else if (event === null || event === void 0 ? void 0 : event.shiftKey) {
            selectedItems.forEach(function (item) {
                var selectedItem = displayedItems.find(function (element) { return element.id === item; });
                if (selectedItem)
                    foundSelectedItem = true;
            });
            if (selectedItems.length > 0 && foundSelectedItem) {
                selectedItems.push(id);
                var indexes_2 = [];
                displayedItems.forEach(function (item, index) {
                    if (selectedItems.includes(item.id)) {
                        indexes_2.push(index);
                    }
                });
                displayedItems.forEach(function (item, index) {
                    if (index >= Math.min.apply(Math, indexes_2) &&
                        index <= Math.max.apply(Math, indexes_2)) {
                        if (!result.includes(item.id)) {
                            result.push(item.id);
                        }
                    }
                });
            }
            else {
                var added_2 = false;
                displayedItems.forEach(function (item) {
                    if (!added_2) {
                        result.push(item.id);
                    }
                    if (id === item.id) {
                        added_2 = true;
                    }
                });
            }
        }
        else if (selectedItems && selectedItems.includes(id)) {
            if (selectedItems.length === 1) {
                result = [];
            }
            else {
                result.push(id);
            }
        }
        else {
            result.push(id);
        }
        selectedItemsRef.current = result;
        setState(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: result })); });
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error --TODO
    useImperativeHandle(ref, function () { return ({
        displayedItems: displayedItemsRef.current,
        availableItems: availableItemsRef.current,
    }); });
    return (_jsx(Wrapper, { children: _jsxs(DragDropContext, { onDragStart: onDragStart, onDragEnd: onDragEnd, children: [_jsx(Col, { md: '5', children: _jsx(DualListBoxGroup, { id: "DualListBoxGroup_1", title: availableSectionTitle, listBoxItems: availableItems, onMoveItem: onMoveItem, selectedItems: selectedItems, handleItemClick: handleAvailableItemClick, onChange: onAvailableSearchChangeHandler, isDragging: isDragging, draggableId: draggableId, windowWidth: windowWidth, handleToggle: handleToggle }) }), _jsx(Col, { md: '1', className: "dualListBoxControlGroup", children: windowWidth > BREAKPOINT.md ? (_jsx(DualListBoxControls, { action: "move", onMoveItem: onMoveItem, displayedItems: displayedItems, availableItems: availableItems, selectedItems: selectedItems })) : (_jsx("div", {})) }), _jsx(Col, { md: '5', children: _jsx(DualListBoxGroup, { id: "DualListBoxGroup_2", title: displayedSectionTitle, listBoxItems: displayedItems, onMoveItem: onMoveItem, selectedItems: selectedItems, handleItemClick: handleDisplayedItemClick, onChange: onDisplayedSearchChangeHandler, isDragging: isDragging, draggableId: draggableId, windowWidth: windowWidth, handleToggle: handleToggle }) }), _jsx(Col, { md: '1', className: "dualListBoxControlGroup", children: windowWidth > BREAKPOINT.md ? (_jsx(DualListBoxControls, { action: "sort", onSortItem: onSortItem, displayedItems: displayedItems, selectedItems: selectedItems, searchTerm: displayedSearchTerm })) : (_jsx("div", {})) })] }) }));
});
DualListBox.displayName = 'DualListBox';
export default DualListBox;
