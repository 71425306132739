var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Svg from '@commons/Svg';
import Button from '@commons/Button';
import styled from 'styled-components';
var WidgetTypeOption = function (_a) {
    var data = _a.data, children = _a.children, _b = _a.className, className = _b === void 0 ? '' : _b, innerProps = _a.innerProps, innerRef = _a.innerRef;
    var iconStyle = {
        display: 'inline-block',
        marginRight: 10,
    };
    var icon = data.icon, label = data.label;
    var OptionButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    justify-content: flex-start;\n    align-items: flex-start;\n    width: '100%';\n    padding: 4px 6px;\n    margin: 2px 0;\n    text-align: left;\n  "], ["\n    display: flex;\n    justify-content: flex-start;\n    align-items: flex-start;\n    width: '100%';\n    padding: 4px 6px;\n    margin: 2px 0;\n    text-align: left;\n  "])));
    return (_jsxs(OptionButton, __assign({ variant: "text", ref: innerRef }, innerProps, { className: className, title: label, fullWidth: true, children: [_jsx("span", { style: iconStyle, children: _jsx(Svg, { icon: icon }) }), children] })));
};
export default WidgetTypeOption;
var templateObject_1;
