import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { IoEarth } from 'react-icons/io5';
import { useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';
import Button from '@commons/Button';
import useStyledTheme from '../../../hooks/useStyledTheme';
import { Wrapper } from './TimeZoneIcon.styles';
var TimeZoneIcon = function (_a) {
    var _b = _a.selectedTimeZone, selectedTimeZone = _b === void 0 ? '' : _b, isPublic = _a.isPublic;
    var formatMessage = useIntl().formatMessage;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    var breakPoints = useStyledTheme().breakPoints;
    var matchesMaxSm = useMediaQuery("(max-width: ".concat(breakPoints.sm, "px)"));
    var _c = useState(false), showTimezonePopup = _c[0], setShowTimezonePopup = _c[1];
    var renderMobile = function () {
        var onSetTimezonePopupClickHandler = function () {
            setShowTimezonePopup(!showTimezonePopup);
        };
        return (_jsxs(Wrapper, { "$isPublic": isPublic, children: [_jsx(Button, { variant: "text", className: "header-timezone_label primary-colored-text", title: selectedTimeZone || formatMessage({ id: 'Global.noTimezone' }), onClick: onSetTimezonePopupClickHandler, children: _jsx(IoEarth, { size: "22" }) }), _jsx("div", { className: "timezone-popup".concat(showTimezonePopup ? ' show' : ' hide'), children: _jsx("span", { children: selectedTimeZone }) })] }));
    };
    var renderDesktop = function () { return (_jsx(Wrapper, { children: _jsx("div", { className: "header-timezone_label", title: selectedTimeZone || formatMessage({ id: 'Global.noTimezone' }), children: _jsx(IoEarth, { size: "22" }) }) })); };
    return matchesMaxSm ? renderMobile() : renderDesktop();
};
export default TimeZoneIcon;
