var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
/*eslint-disable*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactList from 'react-list';
import Alert from 'commons/Alert/Alert';
import Checkbox from 'commons/Checkbox';
import Col from 'commons/Col';
import Spinner from 'commons/Spinner';
import Row from 'commons/Row';
import Scroll from 'commons/Scroll';
import parameters from 'constants/parameters';
import ClipSelectionStore from 'stores/ClipSelectionStore';
import FacetStore from 'stores/FacetStore';
import AdvancedFiltersItem from './AdvancedFiltersItem';
var propTypes = {
    allClipSelections: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onItemChange: PropTypes.func.isRequired,
    onSearchAllCategories: PropTypes.func.isRequired,
    selectedFacetItems: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedMenuElementId: PropTypes.string.isRequired,
    searchField: PropTypes.string,
};
var defaultProps = {
    searchField: '',
};
var BREAKPOINT = parameters.BREAKPOINT, FACET_ITEM_KEY_SEPARATOR = parameters.FACET_ITEM_KEY_SEPARATOR;
var AdvancedFiltersList = /** @class */ (function (_super) {
    __extends(AdvancedFiltersList, _super);
    function AdvancedFiltersList(props) {
        var _this = _super.call(this, props) || this;
        _this.data = [];
        _this.dataLayout = [];
        _this.facetItemsFiltered = [];
        _this.state = {
            filterText: '',
            numberOfColumns: window.innerWidth > BREAKPOINT.md ? 2 : 1,
        };
        _this.handleAllChange = _this.handleAllChange.bind(_this);
        _this.handleFilterChange = _this.handleFilterChange.bind(_this);
        _this.onResize = _this.onResize.bind(_this);
        _this.renderItem = _this.renderItem.bind(_this);
        _this.prepareData(_this.props);
        return _this;
    }
    AdvancedFiltersList.renderAlert = function () {
        return (_jsx(Alert, { type: "info", children: _jsx(FormattedMessage, { id: "AdvancedFilters.noItemAlert" }) }));
    };
    AdvancedFiltersList.prototype.componentDidMount = function () {
        window.addEventListener('resize', this.onResize);
    };
    AdvancedFiltersList.prototype.componentDidUpdate = function (prevProps) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            var selectedMenuElementId = prevProps.selectedMenuElementId;
            var filterText = this.state.filterText;
            if (selectedMenuElementId !== this.props.selectedMenuElementId &&
                filterText.length > 0) {
                this.setState({ filterText: '' });
            }
            this.prepareData(this.props);
            this.forceUpdate();
        }
    };
    AdvancedFiltersList.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this.onResize);
    };
    AdvancedFiltersList.prototype.onResize = function () {
        this.setState({
            numberOfColumns: window.innerWidth > BREAKPOINT.md ? 2 : 1,
        });
    };
    AdvancedFiltersList.prototype.handleAllChange = function () {
        var _a = this.props, loading = _a.loading, onSearchAllCategories = _a.onSearchAllCategories;
        if (!loading) {
            onSearchAllCategories();
        }
    };
    AdvancedFiltersList.prototype.handleFilterChange = function (filterText) {
        this.setState({ filterText: filterText });
    };
    AdvancedFiltersList.prototype.prepareData = function (props) {
        var getFacetItems = function () {
            var facetGroup = FacetStore.getFacetGroupById(props.selectedMenuElementId);
            return facetGroup == null ? [] : facetGroup.facetItems;
        };
        var getClipSelections = function () {
            var clipSelections = ClipSelectionStore.clipSelections;
            var facetItems = getFacetItems();
            var alreadyAddedClipSelectedIds = facetItems.map(function (_a) {
                var id = _a.id;
                return id;
            });
            return clipSelections.reduce(function (previous, current) {
                return alreadyAddedClipSelectedIds.includes(current.id)
                    ? previous
                    : __spreadArray(__spreadArray([], previous, true), [
                        __assign(__assign({}, current), { numberOfClips: 0 }),
                    ], false);
            }, facetItems);
        };
        var isClipSelectionsTab = props.selectedMenuElementId === 'clipSelections.id';
        this.data =
            isClipSelectionsTab && props.allClipSelections
                ? getClipSelections()
                : getFacetItems();
        this.props.handleGetAllFacetItems(this.data);
    };
    AdvancedFiltersList.prototype.renderItem = function (index) {
        var _this = this;
        var _a = this.props, onItemChange = _a.onItemChange, selectedFacetItems = _a.selectedFacetItems, selectedMenuElementId = _a.selectedMenuElementId;
        var _b = this.state, filterText = _b.filterText, numberOfColumns = _b.numberOfColumns;
        var facetItems = this.dataLayout[index];
        var rowKey = facetItems.length === 1
            ? facetItems[0].id
            : "".concat(facetItems[0].id, "-").concat(facetItems[1].id);
        var getFacetBundle = function () {
            var facetGroup = FacetStore.getFacetGroupById(_this.props.selectedMenuElementId);
            return facetGroup == null ? [] : facetGroup.bundle;
        };
        var renderItem = function (item, type) {
            return (_jsx(AdvancedFiltersItem, { filterText: filterText, isSelected: selectedFacetItems === null || selectedFacetItems === void 0 ? void 0 : selectedFacetItems.includes(selectedMenuElementId + FACET_ITEM_KEY_SEPARATOR + item.id), menuElement: selectedMenuElementId, item: item, onChange: onItemChange, type: type, bundle: getFacetBundle() }, item.id));
        };
        return numberOfColumns === 1 ? (renderItem(this.facetItemsFiltered[index], selectedMenuElementId === 'source.type' ? 'id' : 'name')) : (_jsx(Row, { gutter: 0, children: facetItems.map(function (facetItem) { return (_jsx(Col, { md: 12 / numberOfColumns, children: renderItem(facetItem, selectedMenuElementId === 'source.type' ? 'id' : 'name') }, facetItem.id)); }) }, rowKey));
    };
    AdvancedFiltersList.prototype.renderList = function () {
        var numberOfColumns = this.state.numberOfColumns;
        var searchField = this.props.searchField;
        this.facetItemsFiltered =
            searchField.length > 0
                ? this.data.filter(function (facetItem) {
                    return facetItem.name.toLowerCase().includes(searchField.toLowerCase());
                })
                : this.data;
        this.dataLayout = this.facetItemsFiltered.reduce(function (previous, current, index) {
            if (index % numberOfColumns === 0) {
                previous.push([current]);
            }
            else {
                previous[previous.length - 1].push(current);
            }
            return previous;
        }, []);
        return this.facetItemsFiltered.length > 0 ? (_jsx(Scroll, { className: "new-scrollbar list-width with-all-categories", children: _jsx(ReactList, { itemRenderer: this.renderItem, length: this.dataLayout.length, type: "uniform", useStaticSize: true, useTranslate3d: true }) })) : (AdvancedFiltersList.renderAlert());
    };
    AdvancedFiltersList.prototype.render = function () {
        var _a = this.props, formatMessage = _a.intl.formatMessage, loading = _a.loading, selectedFacetItems = _a.selectedFacetItems, selectedMenuElementId = _a.selectedMenuElementId;
        var filterText = this.state.filterText;
        return (_jsx("div", { className: "c-advanced-filters-list", children: loading ? _jsx(Spinner, {}) : this.renderList() }));
    };
    return AdvancedFiltersList;
}(Component));
AdvancedFiltersList.propTypes = propTypes;
AdvancedFiltersList.defaultProps = defaultProps;
export default injectIntl(AdvancedFiltersList);
