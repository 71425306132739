var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @media (min-width: ", "px) {\n    display: inline-block;\n    width: 100%;\n    min-height: 24rem;\n    vertical-align: top;\n    height: 100%;\n  }\n  .dualListBoxGroup__options {\n    margin-bottom: 0;\n    height: stretch;\n    overflow-y: auto;\n    overflow-x: hidden;\n    height: 318px;\n    margin-top: 20px;\n    border: 1px solid #d0d0d0;\n    border-radius: 4px;\n  }\n\n  .dualListBoxGroup__search_wrapper {\n    margin-bottom: var(--spacing-xx-small);\n    position: relative;\n    & svg {\n      position: absolute;\n      z-index: 2;\n      display: block;\n      height: 3rem;\n      line-height: 3rem;\n      text-align: center;\n      pointer-events: none;\n    }\n    & input {\n      padding-top: 0.7rem;\n    }\n  }\n\n  .filter-title-search {\n    font-size: 1.125rem !important;\n  }\n"], ["\n  @media (min-width: ", "px) {\n    display: inline-block;\n    width: 100%;\n    min-height: 24rem;\n    vertical-align: top;\n    height: 100%;\n  }\n  .dualListBoxGroup__options {\n    margin-bottom: 0;\n    height: stretch;\n    overflow-y: auto;\n    overflow-x: hidden;\n    height: 318px;\n    margin-top: 20px;\n    border: 1px solid #d0d0d0;\n    border-radius: 4px;\n  }\n\n  .dualListBoxGroup__search_wrapper {\n    margin-bottom: var(--spacing-xx-small);\n    position: relative;\n    & svg {\n      position: absolute;\n      z-index: 2;\n      display: block;\n      height: 3rem;\n      line-height: 3rem;\n      text-align: center;\n      pointer-events: none;\n    }\n    & input {\n      padding-top: 0.7rem;\n    }\n  }\n\n  .filter-title-search {\n    font-size: 1.125rem !important;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakPoints.sm;
});
var templateObject_1;
