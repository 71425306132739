var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable no-case-declarations */
/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import UserStore from '@stores/UserStore';
export var logicalDateTypes = {
    1: 'today',
    2: 'yesterday',
    3: 'lastweek',
    4: 'lastmonth',
    5: 'custom',
    6: 'lastquarter',
};
export var reversedLogicalDateTypes = {
    today: '1',
    yesterday: '2',
    lastweek: '3',
    lastmonth: '4',
    custom: '5',
    lastquarter: '6',
};
export function isValidDate(date) {
    return (Object.prototype.toString.call(date) === '[object Date]' &&
        !Number.isNaN(Number(date.getTime())));
}
function areSameDay(date1, date2) {
    return (isValidDate(date1) &&
        isValidDate(date2) &&
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear());
}
export function areSameTime(date1, date2) {
    return (isValidDate(date1) &&
        isValidDate(date2) &&
        date1.getHours() === date2.getHours() &&
        date1.getMinutes() === date2.getMinutes() &&
        date1.getSeconds() === date2.getSeconds() &&
        date1.getMilliseconds() === date2.getMilliseconds());
}
export function areSameDateTime(date1, date2) {
    return areSameDay(date1, date2) && areSameTime(date1, date2);
}
export function clone(d) {
    return new Date(d.getTime());
}
export function convertFromTimeStringToDateTime(date) {
    var year = date.substring(0, 4);
    var month = date.substring(4, 6);
    var day = date.substring(6, 8);
    var hours = date.substring(8, 10);
    var minutes = date.substring(10, 12);
    var seconds = date.length === 14 ? date.substring(12, 14) : '00';
    return "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hours, ":").concat(minutes, ":").concat(seconds);
}
export function createDateAsUTC(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}
export function createDateUserTZ() {
    return parseDate(new Date(moment.utc().format('YYYY-MM-DDTHH:mm:ss')));
}
export function getDateTime(date, time) {
    if (!date) {
        return null;
    }
    else if (!time) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    }
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
}
export function getLocalTimezone() {
    var timeZone = UserStore.selectedTimeZone || moment().utcOffset() || 0;
    if (timeZone && typeof timeZone === 'string') {
        return moment().tz(timeZone).utcOffset();
    }
    return timeZone;
}
export function parseDate(date) {
    var _a;
    var timeZone = ((_a = window.km) === null || _a === void 0 ? void 0 : _a.getTimeZone()) || moment.tz.guess(true);
    var parsedDate = new Date("".concat(moment(date).tz(timeZone).format('YYYY-MM-DDTHH:mm:ss'), ".000Z"));
    if (Object.prototype.toString.call(parsedDate) === '[object Date]') {
        if (!isNaN(parsedDate.getTime())) {
            return parsedDate;
        }
    }
    return new Date(date);
}
export function getDatesInUserTZ(logicalDate, dates) {
    var _a;
    var thisLogicalDate = logicalDate || '1';
    var dt = __assign({}, dates);
    var timeZone = ((_a = window === null || window === void 0 ? void 0 : window.km) === null || _a === void 0 ? void 0 : _a.getTimeZone()) || window.moment.tz.guess(true);
    var localMachineNowTime = new Date();
    var userStartOfDayTime = window.moment
        .tz(localMachineNowTime, timeZone)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    var userNowTime = window.moment.tz(localMachineNowTime, timeZone);
    var universalDateFrom = moment(userStartOfDayTime.format()).utc();
    var universalDateTo = moment(userNowTime.format()).utc();
    switch (thisLogicalDate) {
        case '1':
            dt.universalDateFrom = universalDateFrom.format('YYYYMMDDHHmmss');
            dt.universalDateTo = universalDateTo.format('YYYYMMDDHHmmss');
            break;
        case '2':
            dt.universalDateFrom = universalDateFrom
                .add(-1, 'days')
                .format('YYYYMMDDHHmmss');
            dt.universalDateTo = universalDateTo.format('YYYYMMDDHHmmss');
            break;
        case '3':
            dt.universalDateFrom = universalDateFrom
                .add(-7, 'days')
                .format('YYYYMMDDHHmmss');
            dt.universalDateTo = universalDateTo.format('YYYYMMDDHHmmss');
            break;
        case '4':
            dt.universalDateFrom = universalDateFrom
                .add(-30, 'days')
                .format('YYYYMMDDHHmmss');
            dt.universalDateTo = universalDateTo.format('YYYYMMDDHHmmss');
            break;
        case '6':
            dt.universalDateFrom = universalDateFrom
                .add(-90, 'days')
                .format('YYYYMMDDHHmmss');
            dt.universalDateTo = universalDateTo.format('YYYYMMDDHHmmss');
            break;
        case '5':
            dt.universalDateFrom = moment(window.moment.tz(dates.universalDateFrom.includes('-')
                ? dates.universalDateFrom
                : moment(dates.universalDateFrom, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'), timeZone))
                .utc()
                .format('YYYYMMDDHHmmss');
            dt.universalDateTo = moment(window.moment.tz(dates.universalDateTo.includes('-')
                ? dates.universalDateTo
                : moment(dates.universalDateTo, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'), timeZone))
                .utc()
                .format('YYYYMMDDHHmmss');
            break;
        default:
            break;
    }
    return dt;
}
export function convertToUserTZ(date, format, parseFormat) {
    var _a;
    if (format === void 0) { format = 'YYYY-MM-DD HH:mm:ss'; }
    var timeZone = ((_a = window === null || window === void 0 ? void 0 : window.km) === null || _a === void 0 ? void 0 : _a.getTimeZone()) || window.moment.tz.guess(true);
    var parsedDate = moment.utc(date, parseFormat).tz(timeZone, false);
    if (parsedDate.isValid()) {
        return parsedDate.format(format);
    }
    return date;
}
export function convertFromUserTZToBackend(date, _a) {
    var _b = _a.withoutTime, withoutTime = _b === void 0 ? false : _b;
    var momentDate = moment(date);
    var newDate = moment.tz(date, 
    // eslint-disable-next-line
    UserStore.selectedTimeZone || moment.tz.guess());
    if (withoutTime) {
        newDate = newDate.startOf('day');
    }
    else {
        newDate = newDate.set({
            hour: momentDate.hour(),
            minute: momentDate.minute(),
            second: momentDate.second(),
        });
    }
    return newDate.utc().format('YYYYMMDDHHmmss');
}
export function isDate(value) {
    switch (typeof value) {
        case 'number':
            return true;
        case 'string':
            return !isNaN(Date.parse(value));
        case 'object':
            if (value instanceof Date) {
                return !isNaN(value.getTime());
            }
            return false;
        default:
            return false;
    }
}
export function isToday(date) {
    var today = new Date();
    return (isValidDate(date) &&
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate());
}
export function isYesterday(date) {
    var yesterday = moment().add(-1, 'days');
    return (isValidDate(date) &&
        date.getFullYear() === yesterday.year() &&
        date.getMonth() === yesterday.month() &&
        date.getDate() === yesterday.date());
}
export function convertSectoDelay(totalSeconds) {
    var hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    var minutes = Math.floor(totalSeconds / 60);
    var seconds = totalSeconds % 60;
    return "".concat(hours === 0 ? '' : "".concat(hours, "h"), " ").concat(minutes === 0 ? '' : "".concat(Math.floor(minutes), "m"), " ").concat(seconds === 0 ? '' : "".concat(Math.floor(seconds), "s"), " ");
}
// @ts-expect-error: TODO: Not adding those function as global types to remove them on the old code
window.parseDate = parseDate;
// @ts-expect-error: Same
window.convertToUserTZ = convertToUserTZ;
// @ts-expect-error: Same
window.getDatesInUserTZ = getDatesInUserTZ;
