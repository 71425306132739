export function getCloudLabelTooltip(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getDefaultCloudWidgetSettings(props) {
    var widgetOptionsDictionary = props.widget.widgetOptionsDictionary;
    return {
        labelTooltip: widgetOptionsDictionary && widgetOptionsDictionary.labelTooltip
            ? widgetOptionsDictionary.labelTooltip
            : 'value',
    };
}
