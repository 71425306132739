import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from 'components/Button';
import Checkbox from 'commons/Checkbox';
var propTypes = {
    item: PropTypes.shape({
        clipSelectionId: PropTypes.string.isRequired,
        clipSelectionName: PropTypes.string.isRequired,
        clipSelectionsHierarchy: PropTypes.array,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};
function HierarchyFiltersFacetItem(_a) {
    var item = _a.item, onChange = _a.onChange, selectedIds = _a.selectedIds;
    var _b = useState(false), isCollapse = _b[0], setIsCollapse = _b[1];
    var handleChange = function () {
        onChange(item);
    };
    var handleCollapse = function () {
        setIsCollapse(function (prevState) { return !prevState; });
    };
    var hierarchyFiltersFacetItemClassNames = classnames('c-hierarchy-filters-facet-item', 'text-nowrap', { 'is-collapse': isCollapse });
    return (_jsxs("li", { className: hierarchyFiltersFacetItemClassNames, title: item.clipSelectionName, children: [_jsxs("div", { className: "c-hierarchy-filters-facet-item__name", children: [_jsx(Checkbox, { checked: selectedIds.includes(item.clipSelectionId), onChange: handleChange, children: item.clipSelectionName }), item.clipSelectionsHierarchy.length > 0 ? (_jsx(Button, { className: "c-hierarchy-filters-facet-item__collapse", onClick: handleCollapse, title: "Toggle button" })) : null] }), item.clipSelectionsHierarchy.length > 0 && isCollapse ? (_jsx("ul", { className: "c-hierarchy-filters-facet-list list-unstyled", children: item.clipSelectionsHierarchy.map(function (clipSelectionHierarchyItem) { return (_jsx(HierarchyFiltersFacetItem, { item: clipSelectionHierarchyItem, onChange: onChange, selectedIds: selectedIds }, clipSelectionHierarchyItem.clipSelectionId)); }) })) : null] }));
}
HierarchyFiltersFacetItem.propTypes = propTypes;
export default HierarchyFiltersFacetItem;
