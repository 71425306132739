var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext, useRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Button from '@commons/Button';
import Link from '@commons/Link';
import Svg from '@commons/Svg';
import { SubNavbarContext } from '@context/SubNavbarContext';
import PermissionStore from '@stores/PermissionStore';
import useClickOutsideListener from '../../hooks/useClickAwayListener';
import onClusiveImg from '../../../Content/public/img/Onclusive_Symbol.png';
import agilityImg from '../../../Content/public/img/Agility-Small.png';
import liveMediaImg from '../../../Content/public/img/LiveMedia-Small.png';
import powerBIImg from '../../../Content/public/img/PowerBI.png';
import socialEngagementSmall from '../../../Content/public/img/Social-Engagement-Small.png';
import talkwalkerImg from '../../../Content/public/img/Talkwalker.png';
import { Wrapper } from './SubNavbar.styles';
var servicesImages = {
    LiveSearch: liveMediaImg,
    MediaInfluencerstargeting: agilityImg,
    SocialEngagement: socialEngagementSmall,
    ForwardPlanning: onClusiveImg,
    TalkwalkerPlatform: talkwalkerImg,
};
var insightsImages = {
    Insight: onClusiveImg,
    PowerBI: powerBIImg,
    SocialMedia: talkwalkerImg,
};
function SubNavbar() {
    var formatMessage = useIntl().formatMessage;
    var wrapperRef = useRef(null);
    var navigate = useNavigate();
    var _a = useContext(SubNavbarContext), openSubnav = _a.openSubnav, setOpenSubnav = _a.setOpenSubnav, view = _a.view;
    var _b = useState(''), agilityUserToken = _b[0], setAgilityUserToken = _b[1];
    var _c = useState({
        servicesModules: [],
        insights: [],
    }), content = _c[0], setContent = _c[1];
    useClickOutsideListener({
        ref: openSubnav ? wrapperRef : null,
        callback: function () {
            if (openSubnav) {
                setOpenSubnav(false);
            }
        },
    });
    var handleSubmit = function () {
        setOpenSubnav(false);
        var form = document.querySelector('#agilityUrlForm');
        if (form && 'submit' in form) {
            form.submit();
        }
    };
    var handleClickAnalyticItem = function (type) { return function () {
        navigate("/analytics?dashboardType=".concat(type));
        setOpenSubnav(false);
    }; };
    var handleClickServiceItem = function () {
        setOpenSubnav(false);
    };
    useEffect(function () {
        var handleSetModules = function () {
            setContent(function (prevContent) { return (__assign(__assign({}, prevContent), { servicesModules: PermissionStore.servicesModules || [], insights: PermissionStore.insightMenu || [] })); });
            var agilityToken = PermissionStore.agilityUserToken;
            if (agilityToken)
                setAgilityUserToken(agilityToken);
        };
        if (PermissionStore.isInit())
            handleSetModules();
        PermissionStore.addChangeListener(handleSetModules);
        return function () {
            PermissionStore.removeChangeListener(handleSetModules);
        };
    }, []);
    return (_jsx(Wrapper, { ref: wrapperRef, "$isOpen": openSubnav, children: _jsxs("ul", { children: [view === 'services' &&
                    content.servicesModules.map(function (item, key) {
                        if (item.name === 'MediaInfluencerstargeting') {
                            return (_jsx("li", { children: _jsx(Button, { className: "subnav__item-btn", variant: "text", onClick: handleSubmit, tabIndex: 0, title: formatMessage({
                                        id: "Header.navigation.".concat(item.name),
                                    }), children: _jsxs("div", { className: "subnav__item", children: [_jsx("img", { src: servicesImages[item.name], alt: item.name }), _jsx("form", { id: "agilityUrlForm", action: item.url + agilityUserToken, "data-action": "AdditionalServices", className: "additionalServices__modify-item", target: "_blank", method: "post", children: _jsx(FormattedMessage, { id: "Header.navigation.".concat(item.name), tagName: "span" }) })] }) }, item.name) }, key));
                        }
                        return (_jsx("li", { children: _jsx(Link, { "data-action": "AdditionalServices", href: item.url, target: "_blank", rel: "noopener noreferrer", ariaLabel: "Additional service ".concat(item.name), title: formatMessage({
                                    id: "Header.navigation.".concat(item.name),
                                }), children: _jsxs("div", { className: "subnav__item", onClick: handleClickServiceItem, onKeyUp: handleClickServiceItem, tabIndex: 0, role: "button", children: [(item.name === 'PRGloo' && (_jsx(Svg, { icon: "onclusive-pr-manager-logo", className: "PRGloo-svg", size: "xxxx-large" }))) || (_jsx("img", { src: servicesImages[item.name], alt: item.name })), _jsx(FormattedMessage, { id: "Header.navigation.".concat(item.name), tagName: "span" })] }) }, item.name) }, key));
                    }), view === 'analytics' &&
                    content.insights.map(function (menuItem) { return (_jsx(Button, { className: "subnav__item-btn", variant: "text", onClick: handleClickAnalyticItem(menuItem.type), children: _jsxs("div", { className: "subnav__item", children: [_jsx("img", { src: insightsImages[menuItem.type], alt: "analytics" }), _jsx(FormattedMessage, { id: menuItem.ressourceKey || 'Header.navigation.Analytics', tagName: "span" })] }) }, menuItem.ressourceKey)); })] }) }));
}
export default SubNavbar;
