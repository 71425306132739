var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*eslint-disable*/
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CgEye } from 'react-icons/cg';
import Button from 'commons/Button';
import Box from 'commons/Box';
import FormElement from 'commons/FormElement';
import Input from 'commons/Input';
import Checkbox from 'commons/Checkbox';
import InputTags from 'commons/InputTags';
import Spinner from 'commons/Spinner';
import Modal, { ModalBody, ModalHeader } from 'commons/Modal';
import ReactSelect from 'commons/ReactSelect';
import Scroll from 'commons/Scroll';
import Editor from 'commons/Editor';
import parameters from 'constants/parameters';
import PushmailActionCreators from 'actions/PushmailActionCreators';
import ClipStore from 'stores/ClipStore';
import PushmailStore from 'stores/PushmailStore';
import { SnackbarContext } from 'context/SnackbarContext';
import ShareArticleFormModelClass from './ShareArticleFormModel';
import ShareArticleUserTable from './ShareArticleUserTable';
import { Wrapper, ModalStyled } from './ShareArticle.styles';
var propTypes = {
    intl: PropTypes.object.isRequired,
    comeFrom: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    onFormSave: PropTypes.func.isRequired,
    selectedClips: PropTypes.arrayOf(PropTypes.string).isRequired,
};
var defaultProps = {
    loading: false,
};
var SHAREARTICLE_TYPE_ENUM = parameters.SHAREARTICLE_TYPE_ENUM, MAX_PUSHMAIL_COMMENT_CARACTERS = parameters.MAX_PUSHMAIL_COMMENT_CARACTERS;
var ShareArticleFormModel = null;
var ShareArticleForm = /** @class */ (function (_super) {
    __extends(ShareArticleForm, _super);
    function ShareArticleForm(props) {
        var _this = _super.call(this, props) || this;
        var pushmailUserList = PushmailStore.pushmailUserList;
        var newPushmailUserList = {
            pushmailUserList: [],
            loadingUsers: true,
        };
        if (pushmailUserList && pushmailUserList.length > 0) {
            newPushmailUserList = {
                pushmailUserList: pushmailUserList,
                loadingUsers: false,
            };
        }
        ShareArticleFormModel = new ShareArticleFormModelClass(props.intl)
            .formModel;
        _this.state = __assign(__assign(__assign({}, ShareArticleForm.setInitialState()), { commentCharCount: 0, errors: {}, loadingVisualization: false, modalIsOpen: false, selectedUsers: [], subject: null, withActiveLinks: false }), newPushmailUserList);
        _this.handleFieldChange = _this.handleFieldChange.bind(_this);
        _this.handleFormElementBlur = _this.handleFormElementBlur.bind(_this);
        _this.handleUserSelect = _this.handleUserSelect.bind(_this);
        _this.handleOpenModal = _this.handleOpenModal.bind(_this);
        _this.handleCloseModal = _this.handleCloseModal.bind(_this);
        _this.handleFormSave = _this.handleFormSave.bind(_this);
        _this.renderField = _this.renderField.bind(_this);
        _this.onChange = _this.onChange.bind(_this);
        _this.getPushMailUsers = _this.getPushMailUsers.bind(_this);
        _this.htmlEditorRef = createRef();
        PushmailStore.addChangeListener(_this.onChange);
        PushmailStore.addPushmailUsersListListener(_this.getPushMailUsers);
        return _this;
    }
    ShareArticleForm.goToMonitoring = function (comeFrom) {
        return function () {
            navigate('/monitoring');
        };
    };
    ShareArticleForm.requiredFields = function () {
        return ShareArticleFormModel.filter(function (field) { return field.required; }).map(function (field) { return field.fieldName; });
    };
    ShareArticleForm.setInitialState = function () {
        var initialState = {};
        ShareArticleFormModel.forEach(function (fieldModel) {
            if (fieldModel.type === SHAREARTICLE_TYPE_ENUM.single ||
                fieldModel.type === SHAREARTICLE_TYPE_ENUM.input ||
                fieldModel.type === SHAREARTICLE_TYPE_ENUM.textarea) {
                initialState[fieldModel.fieldName] = '';
            }
            else if (fieldModel.type === SHAREARTICLE_TYPE_ENUM.multi ||
                fieldModel.type === SHAREARTICLE_TYPE_ENUM.tags) {
                initialState[fieldModel.fieldName] = [];
            }
            else if (fieldModel.type === SHAREARTICLE_TYPE_ENUM.checkbox) {
                initialState[fieldModel.fieldName] = false;
            }
        });
        return initialState;
    };
    ShareArticleForm.getSelectedUsers = function (newState) {
        var state = __assign({}, newState);
        if (state.mailingList.length !== 0) {
            // state.selectedUsers = [];
            state.mailingList.forEach(function (ml) {
                var _a;
                var usersIds = PushmailStore.getUsersIdsByGroupId(ml.value);
                (_a = state.selectedUsers).push.apply(_a, usersIds);
            });
            state.selectedUsers = Array.from(new Set(state.selectedUsers));
        }
        return state.selectedUsers;
    };
    ShareArticleForm.prototype.componentWillUnmount = function () {
        PushmailStore.removeChangeListener(this.onChange);
        PushmailStore.removePushmailUsersListListener(this.getPushMailUsers);
    };
    ShareArticleForm.prototype.onChange = function (type) {
        if (type === 'GET_VISUALIZATION_RESPONSE') {
            this.setState({
                loadingVisualization: false,
            });
        }
    };
    ShareArticleForm.prototype.getPushMailUsers = function (data) {
        this.setState({
            pushmailUserList: data,
            loadingUsers: false,
        });
    };
    ShareArticleForm.prototype.handleFieldChange = function (fieldName) {
        var _this = this;
        var state = __assign({}, this.state);
        var fieldModel = ShareArticleFormModel.find(function (field) { return field.fieldName === fieldName; });
        var errors = this.state.errors;
        return function (value) {
            var _a, _b;
            var _c;
            if (SHAREARTICLE_TYPE_ENUM.textarea === fieldModel.type) {
                state.commentCharCount = value.length;
            }
            if (SHAREARTICLE_TYPE_ENUM.checkbox === fieldModel.type) {
                state[fieldName] = !state[fieldName];
                _this.setState(__assign({}, state));
                return;
            }
            if (fieldName === 'pushmailTheme') {
                var subject = (value &&
                    PushmailStore.themes.find(function (theme) { return theme.pushmailId === value.pushmailId; })) ||
                    '';
                state.subject = subject.subject;
                if (state.subject.length > 0 &&
                    Object.keys(errors).length > 0 &&
                    ((_c = errors.subject) === null || _c === void 0 ? void 0 : _c.length) > 0) {
                    delete errors['subject'];
                    _this.setState({
                        errors: errors,
                    });
                }
                state.withActiveLinks = subject.withActiveLinks;
            }
            if (fieldName === 'mailingList') {
                state[fieldName] = value;
                state.selectedUsers = ShareArticleForm.getSelectedUsers(state);
            }
            if (fieldName === 'externalRecepient') {
                var emailErrors_1 = 0;
                value.forEach(function (fieldValue) {
                    if (fieldValue) {
                        if (!/\S+@\S+\.\S+/.test(fieldValue)) {
                            emailErrors_1 += 1;
                            state.errors[fieldName] = 'error';
                        }
                    }
                });
                if (emailErrors_1 === 0) {
                    delete state.errors[fieldName];
                }
                if (!value.includes('')) {
                    _this.setState(__assign(__assign({}, state), (_a = {}, _a[fieldName] = value, _a)), function () {
                        _this.handleFormElementBlur(fieldName);
                    });
                }
            }
            if (fieldName !== 'externalRecepient') {
                _this.setState(__assign(__assign({}, state), (_b = {}, _b[fieldName] = value, _b)), function () {
                    _this.handleFormElementBlur(fieldName);
                });
            }
        };
    };
    ShareArticleForm.prototype.handleFormElementBlur = function (fieldName) {
        var _a, _b;
        var errors = this.state.errors;
        var formatMessage = this.props.intl.formatMessage;
        var fieldValue = this.state[fieldName];
        var fieldState = Array.isArray(fieldValue) && fieldValue.length > 0
            ? fieldValue.map(function (fv) { return typeof fv === 'string' && fv.replace(/<(?:.|\n)*?>/gm, ''); }) || []
            : (typeof fieldValue === 'string' &&
                fieldValue.replace(/<(?:.|\n)*?>/gm, '')) ||
                fieldValue;
        var requiredFields = ShareArticleForm.requiredFields();
        if (fieldName === 'externalRecepient' &&
            (!fieldState || fieldState.length === 0)) {
            delete errors[fieldName];
            this.setState({
                errors: errors,
            });
        }
        if (requiredFields.includes(fieldName)) {
            if (!fieldState || (fieldState && fieldState.length === 0)) {
                this.setState({
                    errors: __assign(__assign({}, errors), (_a = {}, _a[fieldName] = formatMessage({ id: 'Global.requiredField' }), _a)),
                });
            }
            else if (errors[fieldName]) {
                delete errors[fieldName];
                this.setState({
                    errors: errors,
                });
            }
        }
        this.setState((_b = {},
            _b[fieldName] = fieldValue,
            _b));
    };
    ShareArticleForm.prototype.handleUserSelect = function (selectedUsers) {
        this.setState({
            selectedUsers: selectedUsers,
        });
    };
    ShareArticleForm.prototype.handleOpenModal = function () {
        var _a = this.state, pushmailTheme = _a.pushmailTheme, subject = _a.subject, comment = _a.comment, withActiveLinks = _a.withActiveLinks;
        var _b = this.props, selectedClips = _b.selectedClips, pressReview = _b.pressReview, isNewsDigest = _b.isNewsDigest, isReview = _b.isReview, comeFrom = _b.comeFrom;
        PushmailActionCreators.showPushmailVisualization({
            AsAttachment: false,
            comment: comment,
            CustomerClipIds: selectedClips && selectedClips.length > 0
                ? JSON.stringify(selectedClips.map(function (item) {
                    if (comeFrom === 'Clipping') {
                        var _a = ClipStore.getClipById(item), id = _a.id, indexName = _a.indexName;
                        return {
                            id: id,
                            indexName: indexName,
                        };
                    }
                    else {
                        return {
                            id: item.id,
                            indexName: item.indexName,
                        };
                    }
                }))
                : null,
            CreatedUserId: null,
            Subject: subject,
            // Culture: km.getLang().toUpperCase(),
            EmailRecipients: '',
            ESReviewId: isReview &&
                pressReview.id &&
                (selectedClips || selectedClips.length === 0)
                ? JSON.stringify([
                    {
                        id: pressReview.id,
                        indexName: pressReview.indexName,
                    },
                ])
                : null,
            ESNewsDigestId: isNewsDigest &&
                pressReview.id &&
                (!selectedClips || selectedClips.length === 0)
                ? JSON.stringify([
                    {
                        id: pressReview.id,
                        indexName: pressReview.indexName,
                    },
                ])
                : null,
            SelectedFilter: {},
            PushmailJobId: 0,
            User: null,
            UserIds: '',
            PushmailId: pushmailTheme.pushmailId.toString(),
            WithActiveLinks: withActiveLinks,
        });
        this.setState({
            loadingVisualization: true,
            modalIsOpen: true,
        });
    };
    ShareArticleForm.prototype.handleCloseModal = function () {
        this.setState({
            loadingVisualization: false,
            modalIsOpen: false,
        });
    };
    ShareArticleForm.prototype.handleFormSave = function () {
        var _this = this;
        var state = __assign({}, this.state);
        var setSnackbarObj = this.context.setSnackbarObj;
        var onFormSave = this.props.onFormSave;
        var _a = this.state, errors = _a.errors, selectedUsers = _a.selectedUsers;
        var formatMessage = this.props.intl.formatMessage;
        var requiredFields = ShareArticleForm.requiredFields();
        requiredFields.forEach(function (fieldName) {
            if (!state[fieldName]) {
                errors[fieldName] = formatMessage({ id: 'Global.requiredField' });
            }
        });
        if (Object.keys(errors).length > 0) {
            this.setState({
                errors: errors,
            });
            return;
        }
        else if (selectedUsers.length === 0 &&
            this.state.externalRecepient.length === 0) {
            setSnackbarObj({
                open: true,
                status: 'error',
                message: formatMessage({ id: 'Pushmail.mailMandatory' }),
            });
            return;
        }
        onFormSave(state, function () {
            _this.htmlEditorRef.current.reset();
            _this.setState(__assign(__assign({}, ShareArticleForm.setInitialState()), { commentCharCount: 0, errors: {}, selectedUsers: [], subject: null, withActiveLinks: false }));
        });
    };
    ShareArticleForm.prototype.renderModal = function () {
        var _a = this.state, modalIsOpen = _a.modalIsOpen, loadingVisualization = _a.loadingVisualization, pushmailTheme = _a.pushmailTheme;
        var visualization = pushmailTheme &&
            pushmailTheme.pushmailId &&
            PushmailStore.getVisualizationById(pushmailTheme.pushmailId);
        var createMarkup = {
            __html: (visualization && visualization.data) || 'Error',
        };
        return (_jsxs(ModalStyled, { isOpen: modalIsOpen, onCancel: this.handleCloseModal, size: "md", children: [loadingVisualization && _jsx(Spinner, { fullPage: true }), _jsx(ModalHeader, { onClose: this.handleCloseModal, children: _jsx(FormattedMessage, { id: "Global.preview" }) }), _jsx(ModalBody, { children: _jsx(Scroll, { className: "new-scrollbar", children: _jsx("div", { className: "p-right--medium", style: {
                                height: 450,
                                width: '100%',
                            }, children: !loadingVisualization && visualization && (_jsx("div", { dangerouslySetInnerHTML: createMarkup })) }) }) })] }));
    };
    ShareArticleForm.prototype.renderField = function (options) {
        var _this = this;
        var fieldName = options.fieldName, required = options.required, label = options.label, placeholder = options.placeholder, type = options.type, note = options.note;
        var _a = this.state, errors = _a.errors, commentCharCount = _a.commentCharCount, pushmailUserList = _a.pushmailUserList, loadingUsers = _a.loadingUsers, selectedUsers = _a.selectedUsers;
        var error = errors[fieldName];
        var fieldState = this.state[fieldName];
        var field = null;
        var _b = this.props, loading = _b.loading, formatMessage = _b.intl.formatMessage;
        // const optionsThemes = PushmailStore.themes.map(model => ({
        //   value: model.pushmailId,
        //   label: model.pushmailLayoutName,
        // }));
        var optionsMailingList = PushmailStore.mailingList.map(function (model) { return ({
            value: model.groupId,
            label: model.groupName,
        }); });
        switch (type) {
            case SHAREARTICLE_TYPE_ENUM.single: {
                field = (_jsx(ReactSelect, { inputProps: { id: fieldName }, id: fieldName, 
                    //onBlur={() => this.handleFormElementBlur(fieldName)}
                    onChange: this.handleFieldChange(fieldName), options: PushmailStore.themes, getOptionLabel: function (item) { return item.pushmailLayoutName; }, getOptionValue: function (item) { return item.PushmailId; }, noResultsText: (loading &&
                        formatMessage({ id: 'ReactSelect.loadingPlaceholder' })) ||
                        formatMessage({ id: 'ReactSelect.noResultsText' }), loadingPlaceholder: loading, placeholder: placeholder, value: fieldState }, fieldName));
                break;
            }
            case SHAREARTICLE_TYPE_ENUM.multi:
                field = (_jsx(ReactSelect, { clearable: true, inputProps: { id: fieldName }, id: fieldName, isMulti: true, onBlur: function () { return _this.handleFormElementBlur(fieldName); }, onChange: this.handleFieldChange(fieldName), options: optionsMailingList, placeholder: placeholder, value: fieldState }, fieldName));
                break;
            case SHAREARTICLE_TYPE_ENUM.textarea:
                field = (_jsx(Editor, { toolbar: {
                        options: [
                            'inline',
                            'fontSize',
                            'fontFamily',
                            'list',
                            'textAlign',
                            'colorPicker',
                            'link',
                            'emoji',
                            'remove',
                            'history',
                        ],
                        inline: {
                            inDropdown: true,
                            monospace: { icon: null, className: 'hidden' },
                        },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                    }, 
                    // TODO: why ref is undefined
                    ref: this.htmlEditorRef, 
                    // className={`qa-add-article-${id}`}
                    // wrapperClassName="c-input"
                    // onEditorStateChange={this.onEditorStateChange}
                    onBlur: function () { return _this.handleFormElementBlur(fieldName); }, onChange: this.handleFieldChange(fieldName), placeholder: placeholder, props: { id: fieldName }, showLabel: false, stripPastedStyles: true }, fieldName));
                break;
            case SHAREARTICLE_TYPE_ENUM.input:
                field = (_jsx(Input, { id: fieldName, onBlur: function () { return _this.handleFormElementBlur(fieldName); }, onChange: this.handleFieldChange(fieldName), placeholder: placeholder, type: "text", value: fieldState || '', showLabel: false }, fieldName));
                break;
            case SHAREARTICLE_TYPE_ENUM.checkbox:
                field = (_jsx(Checkbox, { checked: fieldState, id: fieldName, onChange: this.handleFieldChange(fieldName), children: _jsx(FormattedMessage, { id: "ShareArticles.withActiveLinks" }) }, fieldName));
                break;
            case SHAREARTICLE_TYPE_ENUM.tags:
                field = (_jsx(InputTags, { onBlur: function () { return _this.handleFormElementBlur(fieldName); }, onChange: this.handleFieldChange(fieldName), placeholder: placeholder, value: fieldState, maxTags: Number.MAX_VALUE }, fieldName));
                break;
            case SHAREARTICLE_TYPE_ENUM.grid:
                field = (_jsx(ShareArticleUserTable, { loadingUsers: loadingUsers, usersList: pushmailUserList, onUserSelect: this.handleUserSelect, selectedUsers: selectedUsers }));
                break;
            default:
                break;
        }
        return (_jsx(FormElement, { error: error, helpMessage: note, label: label, showLabel: type !== SHAREARTICLE_TYPE_ENUM.checkbox, required: required, children: field }, fieldName));
    };
    ShareArticleForm.prototype.render = function () {
        var _this = this;
        var _a = this.props, comeFrom = _a.comeFrom, returntokiosk = _a.returntokiosk, loading = _a.loading;
        var _b = this.state, selectedUsers = _b.selectedUsers, errors = _b.errors, pushmailTheme = _b.pushmailTheme, pushmailUserList = _b.pushmailUserList;
        return (_jsxs(Wrapper, { className: "c-sharearticle__container new-scrollbar", children: [_jsxs("div", { className: "c-sharearticle-form", children: [this.renderModal(), ShareArticleFormModel.map(function (fieldModel) {
                            return _this.renderField(fieldModel);
                        })] }), _jsxs(Box, { display: "flex", alignItems: "center", justifyContent: "flex-end", children: [_jsx(Button, { disabled: !pushmailTheme, type: "button", 
                            // id="open_visualization"
                            onClick: this.handleOpenModal, variant: "outline", color: "primary", leftIcon: _jsx(CgEye, { size: "18" }), ariaLabel: "preview button", title: "preview button", children: _jsx(FormattedMessage, { id: "Global.preview" }) }), _jsx(Button, { loading: loading, disabled: Object.keys(errors).length > 0, onClick: this.handleFormSave, color: "secondary", ariaLabel: "Save button", title: "Save button", children: _jsx(FormattedMessage, { id: "Global.ok" }) })] })] }));
    };
    ShareArticleForm.contextType = SnackbarContext;
    return ShareArticleForm;
}(Component));
ShareArticleForm.propTypes = propTypes;
ShareArticleForm.defaultProps = defaultProps;
export default injectIntl(ShareArticleForm, { forwardRef: true });
