var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import Box from '@commons/Box';
import LoaderBlock from './LoaderBlock';
var Wrapper = styled('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 10px 32px 10px 35px;\n  margin: 5px;\n  background: #ffffff;\n  border: solid 1px #ccc;\n  border-radius: 4px;\n"], ["\n  padding: 10px 32px 10px 35px;\n  margin: 5px;\n  background: #ffffff;\n  border: solid 1px #ccc;\n  border-radius: 4px;\n"])));
function LoaderCard() {
    return (_jsxs(Wrapper, { children: [_jsx(LoaderBlock, { margin: "0 0 15px 0" }), _jsx(LoaderBlock, { rows: 3, fontSize: 12 }), _jsxs(Box, { display: "flex", justifyContent: "space-between", marginTop: 8, children: [_jsx(LoaderBlock, { fontSize: 12, maxWidth: 100 }), _jsx(LoaderBlock, { fontSize: 12, maxWidth: 100 })] })] }));
}
export default LoaderCard;
var templateObject_1;
