import { useMutation } from '@tanstack/react-query';
import ServerActionCreators from '@actions/ServerActionCreators';
import UserActionCreators from '@actions/UserActionCreators';
import { postFetchQuery } from '@services/AppUtils';
import WebApi from '@services/webApi';
var useLoginAgency = function (_a) {
    var _b = _a === void 0 ? {} : _a, onError = _b.onError, onSuccess = _b.onSuccess;
    return useMutation({
        mutationFn: function (model) { return postFetchQuery('/api/access/agencyLogin', model); },
        onSuccess: function (data, model) {
            ServerActionCreators.receiveGetAgencyLogin(data);
            WebApi.getPermissions();
            UserActionCreators.getCurrentUser();
            if (onSuccess)
                onSuccess();
        },
        onError: function (e) {
            if (onError) {
                onError(e);
            }
        },
    });
};
export default useLoginAgency;
