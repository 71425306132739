import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
import { Wrapper } from './Col.styles';
var Col = function (_a) {
    var _b = _a.align, align = _b === void 0 ? 'top' : _b, _c = _a.children, children = _c === void 0 ? null : _c, _d = _a.className, className = _d === void 0 ? '' : _d, _e = _a.gutter, gutter = _e === void 0 ? 0 : _e, md = _a.md, sm = _a.sm, lg = _a.lg, xl = _a.xl, xs = _a.xs;
    var colClassNames = classnames('col', className);
    return (_jsx(Wrapper, { "$xs": xs, "$sm": sm, "$md": md, "$lg": lg, "$xl": xl, className: "".concat(colClassNames, " ").concat(className), style: {
            paddingLeft: gutter > 0 ? "".concat(gutter / 2, "rem") : '',
            paddingRight: gutter > 0 ? "".concat(gutter / 2, "rem") : '',
            verticalAlign: align,
        }, children: children }));
};
export default Col;
