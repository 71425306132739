var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
import ActionTypes from 'constants/actionTypes';
import objectHash from 'object-hash';
import { createDateAsUTC } from 'services/AppUtils';
import BaseStore from 'stores/BaseStore';
import ClipStore from 'stores/ClipStore';
import { parseDate } from 'services/DateUtils';
var CONSUMPTION_EVENT = 'consumption';
var PDF_STATE_EVENT = 'pdfState';
var SUCCESS_CREATION_EVENT = 'successCreation';
var ACCESS_NEWSDIGEST_BY_TYPE_EVENT = 'accessNewsDigestByType';
var GET_NEWS_DIGESTS_EVENT = 'getNewsDigests';
var NewsDigestStore = /** @class */ (function (_super) {
    __extends(NewsDigestStore, _super);
    function NewsDigestStore() {
        var _this = _super.call(this) || this;
        _this._pdf = {};
        _this._newsDigests = [];
        _this._searches = {};
        _this.selectedTab = '';
        _this.subscribe(function () { return _this._registerToActions.bind(_this); });
        return _this;
    }
    NewsDigestStore.setAccountLogo = function (newsdigest, accountLogo) {
        if (accountLogo === void 0) { accountLogo = ''; }
        if (typeof accountLogo === 'string') {
            newsdigest.accountLogo = accountLogo;
        }
    };
    NewsDigestStore.setAccountName = function (newsdigest, accountName) {
        if (accountName === void 0) { accountName = ''; }
        if (typeof accountName === 'string') {
            newsdigest.accountName = accountName;
        }
    };
    NewsDigestStore.setAuthor = function (newsdigest, author) {
        if (author === void 0) { author = ''; }
        if (typeof author === 'string') {
            newsdigest.author = author;
        }
    };
    NewsDigestStore.setIdIndexName = function (newsdigest, id, indexName) {
        if (id === void 0) { id = ''; }
        if (indexName === void 0) { indexName = ''; }
        if (typeof id === 'string' && typeof indexName === 'string') {
            newsdigest.id = id;
            newsdigest.indexName = indexName;
        }
    };
    NewsDigestStore.setClipAuthor = function (clip, author) {
        if (author === void 0) { author = ''; }
        if (typeof author === 'string') {
            clip.author = author;
        }
    };
    NewsDigestStore.setGlobalPdf = function (clip, globalPdf) {
        if (globalPdf === void 0) { globalPdf = 0; }
        if (typeof globalPdf === 'number') {
            clip.globalPdf = globalPdf;
        }
    };
    NewsDigestStore.setTotalClips = function (newsdigest, totalClips) {
        if (totalClips === void 0) { totalClips = 0; }
        if (typeof totalClips === 'number') {
            newsdigest.totalClips = totalClips;
        }
    };
    NewsDigestStore.setTreeCollection = function (newsdigest, treeCollection) {
        if (treeCollection === void 0) { treeCollection = []; }
        treeCollection.forEach(function (collection) {
            collection.synthesis.forEach(function (tree) {
                if (tree.clips) {
                    tree.clips.forEach(function (clip) {
                        clip.deliveredDate =
                            parseDate(clip.deliveredDate) ||
                                createDateAsUTC(new Date(clip.deliveredDate));
                        clip.publicationDate =
                            parseDate(clip.publicationDate) ||
                                createDateAsUTC(new Date(clip.publicationDate));
                        NewsDigestStore.setClipAuthor(clip, clip.author);
                    });
                }
            });
        });
        newsdigest.treeCollection = treeCollection;
    };
    NewsDigestStore.setDefaultTab = function (newsDigest, defaultTab) {
        if (defaultTab === void 0) { defaultTab = {}; }
        newsDigest.defaultTab = defaultTab;
    };
    Object.defineProperty(NewsDigestStore.prototype, "pdf", {
        get: function () {
            return this._pdf;
        },
        set: function (value) {
            this._pdf = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewsDigestStore.prototype, "newsDigests", {
        get: function () {
            return this._newsDigests;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewsDigestStore.prototype, "searches", {
        get: function () {
            return this._searches;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewsDigestStore.prototype, "newsDigest", {
        set: function (value) {
            this._newsdigest = value;
        },
        enumerable: false,
        configurable: true
    });
    NewsDigestStore.prototype.getSelectedTab = function () {
        return this.selectedTab;
    };
    NewsDigestStore.prototype.setSelectedTab = function (value) {
        this.selectedTab = value;
    };
    NewsDigestStore.prototype.getDefaultTab = function (hash) {
        var newsDigest = this.getNewsDigestByHash(hash);
        return newsDigest.defaultTab;
    };
    NewsDigestStore.prototype.getNewsDigestById = function (id) {
        return this._newsDigests.find(function (item) { return item.id === id; });
    };
    NewsDigestStore.prototype.getNewsDigestByHash = function (hash) {
        return this._newsDigests.find(function (item) { return item.hash === hash; });
    };
    NewsDigestStore.prototype.getSearchByHash = function (hash) {
        return this.searches[hash];
    };
    NewsDigestStore.prototype.updateDefaultTab = function (hash, key, value) {
        var newsDigest = this.getNewsDigestByHash(hash);
        newsDigest.defaultTab[key] = value;
        return newsDigest.defaultTab;
    };
    NewsDigestStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.pdf = {};
        this.reviews = [];
    };
    NewsDigestStore.prototype.addNewsDigest = function (_a) {
        var creationDate = _a.creationDate, title = _a.title, hash = _a.hash;
        var newsDigest = this.getNewsDigestByHash(hash);
        var date = new Date(creationDate);
        if (Object.prototype.toString.call(date) === '[object Date]' &&
            !isNaN(date.getTime()) &&
            typeof hash === 'string' &&
            typeof title === 'string' &&
            !newsDigest) {
            var newDigest = {
                creationDate: parseDate(date) || createDateAsUTC(date),
                title: title,
                hash: hash,
            };
            this._newsDigests.push(newDigest);
            return newDigest;
        }
        return newsDigest;
    };
    NewsDigestStore.prototype.emitPdfState = function () {
        this.emit(PDF_STATE_EVENT);
    };
    NewsDigestStore.prototype.addPdfStateListener = function (callback) {
        this.on(PDF_STATE_EVENT, callback);
    };
    NewsDigestStore.prototype.removePdfStateListener = function (callback) {
        this.removeListener(PDF_STATE_EVENT, callback);
    };
    NewsDigestStore.prototype.emitConsumption = function (id, type) {
        this.emit(CONSUMPTION_EVENT, id, type);
    };
    NewsDigestStore.prototype.addConsumptionListener = function (callback) {
        this.on(CONSUMPTION_EVENT, callback);
    };
    NewsDigestStore.prototype.removeConsumptionListener = function (callback) {
        this.removeListener(CONSUMPTION_EVENT, callback);
    };
    NewsDigestStore.prototype.emitSuccessCreation = function (hash) {
        this.emit(SUCCESS_CREATION_EVENT, hash);
    };
    NewsDigestStore.prototype.addSuccessCreationListener = function (callback) {
        this.on(SUCCESS_CREATION_EVENT, callback);
    };
    NewsDigestStore.prototype.removeSuccessCreationListener = function (callback) {
        this.removeListener(SUCCESS_CREATION_EVENT, callback);
    };
    NewsDigestStore.prototype.emitAccessNewsDigestByType = function (data) {
        this.emit(ACCESS_NEWSDIGEST_BY_TYPE_EVENT, data);
    };
    NewsDigestStore.prototype.addAccessNewsDigestByTypeListener = function (callback) {
        this.on(ACCESS_NEWSDIGEST_BY_TYPE_EVENT, callback);
    };
    NewsDigestStore.prototype.removeAccessNewsDigestByTypeListener = function (callback) {
        this.removeListener(ACCESS_NEWSDIGEST_BY_TYPE_EVENT, callback);
    };
    NewsDigestStore.prototype.emitGetNewsDigests = function (hash) {
        this.emit(GET_NEWS_DIGESTS_EVENT, hash);
    };
    NewsDigestStore.prototype.addGetNewsDigestsListener = function (callback) {
        this.on(GET_NEWS_DIGESTS_EVENT, callback);
    };
    NewsDigestStore.prototype.removeGetNewsDigestsListener = function (callback) {
        this.removeListener(GET_NEWS_DIGESTS_EVENT, callback);
    };
    NewsDigestStore.prototype._registerToActions = function (payload) {
        var _this = this;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_NEWSDIGEST_RESPONSE:
            case ActionTypes.GET_KIOSK_NEW_DIGEST_RESPONSE: {
                if (action.json) {
                    var _a = action.json.data, accountLogo = _a.accountLogo, accountName = _a.accountName, author = _a.author, creationDate = _a.creationDate, hash = _a.hash, id = _a.id, indexName = _a.indexName, title = _a.title, totalClips = _a.totalClips, treeCollection = _a.treeCollection, defaultTab = _a.defaultTab, globalPdf = _a.globalPdf;
                    var newsDigest = this.addNewsDigest({
                        creationDate: creationDate,
                        id: id,
                        title: title,
                        hash: hash,
                    });
                    if (newsDigest) {
                        NewsDigestStore.setAccountLogo(newsDigest, accountLogo);
                        NewsDigestStore.setAccountName(newsDigest, accountName),
                            NewsDigestStore.setGlobalPdf(newsDigest, globalPdf);
                        NewsDigestStore.setAuthor(newsDigest, author);
                        NewsDigestStore.setIdIndexName(newsDigest, id, indexName);
                        NewsDigestStore.setTotalClips(newsDigest, totalClips);
                        NewsDigestStore.setTreeCollection(newsDigest, treeCollection);
                        NewsDigestStore.setDefaultTab(newsDigest, defaultTab);
                        this.initialized = true;
                        this.emitChange(hash);
                    }
                    else {
                        var message = typeof action.json.data === 'string' &&
                            action.json.data.length > 0
                            ? action.json.data
                            : 'generic';
                        this.emitError('GET_NEWSDIGEST', message);
                    }
                }
                else {
                    this.emitError('GET_NEWSDIGEST', action.errors);
                }
                break;
            }
            case ActionTypes.GET_NEWSDIGEST_PDF_RESPONSE:
                if (action.json) {
                    this.pdf = action.json.data;
                    this.emitPdfState();
                }
                else {
                    this.emitError('GET_NEWSDIGEST_PDF');
                }
                break;
            case ActionTypes.GET_NEWSDIGEST_PDF_STATE_RESPONSE:
                if (action.json) {
                    this.pdf = action.json.data;
                    this.emitPdfState();
                }
                else {
                    this.emitError('GET_NEWSDIGEST_PDF_STATE');
                }
                break;
            case ActionTypes.SET_NEWSDIGESTSELECTED_TAB_RESPONSE:
                if (action.json && !action.json.model.consumptionDone) {
                    var content = action.json.data;
                    var _b = action.json.model, id = _b.id, type = _b.type;
                    if (type === 'pdf') {
                        ClipStore.setFileUrlByType(id, type, content);
                        this.setSelectedTab('pdf');
                    }
                    else if (type === 'text') {
                        ClipStore.updateClipText(id, content);
                        this.setSelectedTab('text');
                    }
                    if (content) {
                        this.emitConsumption(action.json.model.id, action.json.model.type);
                    }
                }
                else {
                    this.emitError();
                }
                break;
            case ActionTypes.ACCESS_NEWSDIGEST_BY_TYPE_RESPONSE:
                if (action.json && action.json.data) {
                    var _c = action.json, data = _c.data, model = _c.model;
                    // this._pdf = action.json.data;
                    this.emitAccessNewsDigestByType(data);
                }
                else {
                    this.emitError('ACCES_NEWSDIGEST_BY_TYPE', action.errors.error);
                }
                break;
            case ActionTypes.GET_NEWSDIGESTS_RESPONSE:
                if (action.json) {
                    var _d = action.json, data = _d.data, model = _d.model;
                    var searchHash = objectHash(model);
                    this.searches[searchHash] = data
                        .map(function (_a) {
                        var author = _a.author, creationDate = _a.creationDate, hash = _a.hash, title = _a.title, totalClips = _a.totalClips, id = _a.id, indexName = _a.indexName;
                        var newsDigest = _this.addNewsDigest({
                            creationDate: creationDate,
                            hash: hash,
                            id: id,
                            title: title,
                        });
                        if (newsDigest) {
                            NewsDigestStore.setAuthor(newsDigest, author);
                            NewsDigestStore.setTotalClips(newsDigest, totalClips);
                        }
                        return newsDigest;
                    })
                        .filter(function (newsDigest) { return newsDigest; });
                    this.initialized = true;
                    this.emitGetNewsDigests(searchHash);
                }
                else {
                    this.emitError('GET_REVIEWS');
                }
                break;
            default:
                break;
        }
    };
    return NewsDigestStore;
}(BaseStore));
export default new NewsDigestStore();
