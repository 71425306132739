var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useEffect, } from 'react';
var initialAlertBannerObj = {
    open: false,
    message: '',
    isRead: false,
    init: false,
};
var AlertBannerContext = React.createContext(null);
function AlertBannerProvider(props) {
    var _a = useState(initialAlertBannerObj), alertBannerObj = _a[0], setAlertBannerObj = _a[1];
    useEffect(function () {
        if (!alertBannerObj.init && alertBannerObj.open) {
            setAlertBannerObj(__assign(__assign({}, alertBannerObj), { init: true }));
        }
    }, [alertBannerObj.open, alertBannerObj.init]);
    return (_jsx(AlertBannerContext.Provider
    // @ts-expect-error expect props to override value
    , __assign({ 
        // @ts-expect-error expect props to override value
        value: { alertBannerObj: alertBannerObj, setAlertBannerObj: setAlertBannerObj } }, props)));
}
export { AlertBannerContext, AlertBannerProvider };
