import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import React from 'react';
var propTypes = {
    children: PropTypes.node.isRequired,
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
};
var AdvancedFiltersBody = function (_a) {
    var children = _a.children;
    return (_jsx("div", { className: "c-advanced-filters__main", children: _jsx("div", { className: "c-advanced-filters__body", children: children }) }));
};
AdvancedFiltersBody.propTypes = propTypes;
AdvancedFiltersBody.defaultProps = {
    icon: '',
};
export default AdvancedFiltersBody;
