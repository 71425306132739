var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Popper } from '@commons/.';
var PopperStyled = styled(Popper)(function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: #ffff;\n    border: 1px solid ", ";\n    border-radius: 5px;\n    padding: 8px;\n    z-index: 1001;\n  "], ["\n    background: #ffff;\n    border: 1px solid ", ";\n    border-radius: 5px;\n    padding: 8px;\n    z-index: 1001;\n  "])), theme.palette.common.border1);
});
export { PopperStyled };
var templateObject_1;
