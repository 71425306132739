var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { ImSpinner2 } from 'react-icons/im';
import Svg from '@commons/Svg';
import { ButtonStyled } from './ButtonIcon.styles';
function ButtonIcon(_a) {
    var children = _a.children, icon = _a.icon, _b = _a.variant, variant = _b === void 0 ? 'text' : _b, _c = _a.loading, loading = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, reactIcon = _a.reactIcon, restProps = __rest(_a, ["children", "icon", "variant", "loading", "disabled", "reactIcon"]);
    return (_jsx(ButtonStyled, __assign({ disabled: disabled || loading }, restProps, { variant: variant, children: loading ? (_jsx(ImSpinner2, { className: "button__spinner" })) : icon ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "hidden", children: "remove" }), _jsx(Svg, { icon: icon })] })) : (reactIcon !== null && reactIcon !== void 0 ? reactIcon : children) })));
}
export default memo(ButtonIcon);
