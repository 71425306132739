var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import 'react-day-picker/lib/style.css';
import DropDown from '@commons/DropDown';
function DateSelectorNavigator(_a) {
    var date = _a.date, _b = _a.selectedYear, selectedYear = _b === void 0 ? new Date().getFullYear() : _b, retentionPeriod = _a.retentionPeriod, onChange = _a.onChange;
    var _c = useState([]), years = _c[0], setYears = _c[1];
    var fillYears = function () {
        var yearsArray = __spreadArray([], years, true);
        var currentYear = selectedYear;
        var fromMonth = new Date(currentYear + 1, 0);
        var toMonth = retentionPeriod || new Date(currentYear - 4, 11);
        for (var i = fromMonth.getFullYear(); i >= toMonth.getFullYear(); i -= 1) {
            yearsArray.push(i);
        }
        setYears(yearsArray);
    };
    var textMoth = window.Messages['Global.D3'];
    var months = (textMoth // formatMessage({ id: 'Global.D3' })
        ? JSON.parse(textMoth)
        : { months: [] }).months;
    var currentTitle = date.getFullYear();
    var handleYearChange = function (e) {
        // @ts-expect-error: dataset
        var value = e.target.dataset.value;
        var month = Number(retentionPeriod ? retentionPeriod.getMonth() : date.getMonth());
        onChange(new Date(value, month));
    };
    useEffect(function () {
        if (retentionPeriod instanceof Date) {
            fillYears();
        }
    }, []);
    useEffect(function () {
        if (retentionPeriod instanceof Date) {
            fillYears();
        }
    }, [retentionPeriod]);
    return (_jsxs("div", { className: "DayPicker-Caption", children: [_jsxs("span", { className: "span_months", children: [months[date.getMonth()], " "] }), _jsx(DropDown, { title: currentTitle.toString(), className: "c-date-picker-year__selector", children: years.map(function (y) { return (_jsx("li", { children: _jsx("button", { onClick: handleYearChange, "data-value": y, children: y }) }, y)); }) })] }));
}
export default DateSelectorNavigator;
