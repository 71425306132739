import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
import { postFetch } from '@services/AppUtils';
var UserActionCreators = {
    GetUserLoginInfo: function (model, callback) {
        postFetch('/api/access/GetUserLoginInfo', model, function (json) {
            if (callback)
                callback(json);
            Dispatcher.handleServerAction({
                actionType: ActionTypes.GET_LOGIN_RESPONSE,
                errors: {},
                json: json,
            });
        });
    },
    getCurrentUser: function (callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_CURRENT_USER_REQUEST,
        });
        // eslint-disable-next-line
        WebApi.getCurrentUser(callback);
    },
    getUserProfileData: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_USER_PROFILE_DATA_REQUEST,
        });
        WebApi.getProfileData();
    },
    updateProfile: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.SAVE_USER_PROFILE_DATA_REQUEST,
        });
        WebApi.updateProfileData(model);
    },
    updateUserPassword: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.UPDATE_USER_PASSWORD_REQUEST,
        });
        WebApi.updateUserPassword(model, callback);
    },
    GetUsersGroupsProfile: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_USERS_GROUP_PROFILE_REQUEST,
        });
        WebApi.GetUsersGroupsProfile();
    },
    GetGroupsRolesCategoriesByUserAccount: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_GROUPS_ROLES_BY_USER_ACCOUNT_REQUEST,
        });
        WebApi.GetGroupsRolesCategoriesByUserAccount(model);
    },
    deleteUser: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_DELETE_USER_REQUEST,
        });
        WebApi.deleteUser(model, callback);
    },
    InsertNewUser: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_INSERT_NEW_USER_REQUEST,
        });
        WebApi.insertNewUser(model, callback);
    },
    GetProfiles: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_PROFILES_REQUEST,
        });
        WebApi.GetProfiles();
    },
    GetExportedInfoUsersListsExcel: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_EXPORTED_INFO_USERS_LISTS_EXCEL_REQUEST,
        });
        WebApi.GetProfiles();
    },
    GetInfoGroupsList: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_INFO_GROUPS_LIST_REQUEST,
        });
        WebApi.GetInfoGroupsList();
    },
    UpdateGroupUserMembership: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.UPDATE_GROUP_USER_MEMBERSHIP_REQUEST,
        });
        WebApi.UpdateGroupUserMembership(model, callback);
    },
    ResetTheme: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.RESET_THEME_PROVIDER_RESPONSE,
        });
    },
    updateUserBranding: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.UPDATE_USER_BRANDING_REQUEST,
        });
        WebApi.updateUserBranding(model, callback);
    },
    changeUserAccountStatus: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.CHANGE_USER_ACCOUNT_STATUS_REQUEST,
        });
        WebApi.changeUserAccountStatus(model, callback);
    },
    GetIncidentMessage: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_INCIDENT_MESSAGE_REQUEST,
        });
        WebApi.GetIncidentMessage();
    },
    updateUsername: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.UPDATE_USERNAME_REQUEST,
        });
        WebApi.updateUsername(model, callback);
    },
};
export default UserActionCreators;
