var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import moment from 'moment';
var KioskContext = React.createContext();
if (process.env.NODE_ENV === 'hotReload') {
    KioskContext.displayName = 'KioskContext';
}
function KioskProvider(props) {
    var _a = useState({
        UniversalDateFrom: moment()
            .startOf('day')
            // .format('YYYY-MM-DD'),
            .format('YYYYMMDDHHmmss'),
        UniversalDateTo: moment().endOf('day').format('YYYYMMDDHHmmss'),
        // .format('YYYY-MM-DD'),
        DateFrom: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        DateTo: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        kioskType: 'review',
        userAccountId: '',
        feederId: '',
        logicalDate: 'today',
        showDraft: false,
        showReview: true,
        reviewID: null,
        Skip: 0,
        Q: '',
    }), filterState = _a[0], setFilterState = _a[1];
    return (_jsx(KioskContext.Provider, __assign({ value: { filterState: filterState, setFilterState: setFilterState } }, props)));
}
export { KioskContext, KioskProvider };
