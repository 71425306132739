var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { createDateAsUTC } from '@services/AppUtils';
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
import { parseDate } from '@services/DateUtils';
import UserStore from './UserStore';
var CommentStore = /** @class */ (function (_super) {
    __extends(CommentStore, _super);
    /* eslint-enable no-param-reassign */
    function CommentStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this._registerToActions.bind(_this); });
        return _this;
    }
    /* eslint-disable no-param-reassign */
    CommentStore.setCreationDate = function (comment, creationDate) {
        if (!comment || (!comment && creationDate))
            return;
        var date = new Date(creationDate);
        comment.creationDate =
            Object.prototype.toString.call(date) === '[object Date]' &&
                !Number.isNaN(date.getTime())
                ? parseDate(date)
                : parseDate(new Date());
    };
    CommentStore.setLastModificationDate = function (comment, lastModificationDate) {
        if (!comment ||
            (!comment && lastModificationDate) ||
            !lastModificationDate) {
            return;
        }
        var date = new Date(lastModificationDate);
        comment.lastModificationDate =
            Object.prototype.toString.call(date) === '[object Date]' &&
                !Number.isNaN(date.getTime())
                ? parseDate(date) || createDateAsUTC(date)
                : undefined;
    };
    CommentStore.setText = function (comment, text) {
        if (!comment || (!comment && text))
            return;
        comment.text = typeof text === 'string' ? text : '';
    };
    CommentStore.prototype.initializeState = function () {
        this.comments = [];
    };
    CommentStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    CommentStore.prototype.addComment = function (id, clipId, userAccountId) {
        if (userAccountId === void 0) { userAccountId = UserStore.currentUser.userAccountId; }
        var comment = this.getCommentById(id);
        if (typeof id === 'string' && typeof clipId === 'string' && !comment) {
            // text and creationDate will be set in next step
            // eslint-disable-next-line
            var newComment = {
                clipId: clipId,
                id: id,
                userAccountId: Number(userAccountId),
            };
            this.comments.push(newComment);
            return newComment;
        }
        return comment;
    };
    CommentStore.prototype.getCommentById = function (commentId) {
        return this.comments.find(function (_a) {
            var id = _a.id;
            return id === commentId;
        });
    };
    CommentStore.prototype.getCommentIdsByClipId = function (clipId) {
        return this.comments
            .filter(function (commentItem) { return commentItem.clipId === clipId; })
            .sort(function (a, b) { return b.creationDate.getTime() - a.creationDate.getTime(); })
            .map(function (_a) {
            var id = _a.id;
            return id;
        });
    };
    CommentStore.prototype._registerToActions = function (payload) {
        var _this = this;
        var _a, _b, _c;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_CLIP_RESPONSE: {
                var json = action.json;
                var data = (json || {}).data;
                var model = (json || {}).model;
                var clipId_1 = (data === null || data === void 0 ? void 0 : data.id) || (model === null || model === void 0 ? void 0 : model.id) || null;
                if (data &&
                    Array.isArray(data.comments) &&
                    ((_a = data.comments) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                    clipId_1 &&
                    'comment' in data.comments[0]) {
                    // TODO: GetReview and GetNewsDigest will trigger this event but they got different models
                    var comments = data.comments;
                    comments.forEach(function (_a) {
                        var text = _a.comment, id = _a.id, lastModificationDate = _a.lastModificationDate, creationDate = _a.timeDate, userAccountId = _a.userAccountId;
                        var comment = _this.addComment(id, clipId_1, userAccountId);
                        CommentStore.setCreationDate(comment, creationDate);
                        CommentStore.setLastModificationDate(comment, lastModificationDate);
                        CommentStore.setText(comment, text);
                    });
                    this.initialized = true;
                    this.emitChange();
                }
                break;
            }
            case ActionTypes.ADD_COMMENT_RESPONSE:
                if ((_b = action.json) === null || _b === void 0 ? void 0 : _b.data) {
                    var _d = action.json.data, id = _d.id, creationDate = _d.creationDate;
                    var text = action.json.model.text;
                    var comment = this.addComment(id, action.json.model.id);
                    CommentStore.setCreationDate(comment, creationDate);
                    CommentStore.setText(comment, text);
                    this.emitChange();
                }
                break;
            case ActionTypes.DELETE_COMMENT_RESPONSE:
                if (action.json) {
                    var idComment_1 = action.json.model.idComment;
                    var index = this.comments.findIndex(function (_a) {
                        var id = _a.id;
                        return id === idComment_1;
                    });
                    this.comments.splice(index, 1);
                    this.emitChange();
                }
                break;
            case ActionTypes.UPDATE_COMMENT_RESPONSE:
                if ((_c = action.json) === null || _c === void 0 ? void 0 : _c.data) {
                    var _e = action.json, data = _e.data, model = _e.model;
                    var _f = model, idComment = _f.idComment, text = _f.text;
                    var comment = this.getCommentById(idComment);
                    CommentStore.setText(comment, text);
                    CommentStore.setLastModificationDate(comment, data);
                    this.emitChange();
                }
                break;
            default:
                break;
        }
    };
    return CommentStore;
}(BaseStore));
export default new CommentStore();
