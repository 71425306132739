import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, Fragment, useRef, } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import Button from '@commons/Button';
import ButtonIcon from '@commons/ButtonIcon';
import Svg from '@commons/Svg';
import { Wrapper } from './DropDown.styles';
var DropDown = function (_a) {
    var _b = _a.collapsed, collapsed = _b === void 0 ? false : _b, _c = _a.smartDrop, smartDrop = _c === void 0 ? '' : _c, onClick = _a.onClick, _d = _a.branded, branded = _d === void 0 ? false : _d, children = _a.children, _e = _a.className, className = _e === void 0 ? '' : _e, _f = _a.disabled, disabled = _f === void 0 ? false : _f, _g = _a.title, title = _g === void 0 ? '' : _g, _h = _a.showArrow, showArrow = _h === void 0 ? true : _h, icon = _a.icon, style = _a.style, _j = _a.type, type = _j === void 0 ? 'text' : _j, primaryClickAction = _a.primaryClickAction, _k = _a.tooltip, tooltip = _k === void 0 ? '' : _k, _l = _a.noBorder, noBorder = _l === void 0 ? false : _l, _m = _a.isLoading, isLoading = _m === void 0 ? false : _m, displayFor = _a.displayFor, splitted = _a.splitted, double = _a.double, iconRendering = _a.iconRendering, iconTitle = _a.iconTitle, onMouseDown = _a.onMouseDown, onDoubleClick = _a.onDoubleClick, _o = _a.id, id = _o === void 0 ? '' : _o, _p = _a.role, role = _p === void 0 ? '' : _p;
    var formatMessage = useIntl().formatMessage;
    var dropdownRef = useRef();
    var collpasedRef = useRef();
    var _q = useState(collapsed), collapsedElm = _q[0], setCollapsedElm = _q[1];
    useEffect(function () {
        document.addEventListener('mousedown', pageClick, false);
        return function () {
            document.removeEventListener('mousedown', pageClick, false);
        };
    }, []);
    useEffect(function () {
        if (!collapsed && collapsedElm) {
            setCollapsedElm(false);
        }
    }, [children]);
    var getDropDirection = function () {
        if (smartDrop) {
            var parentWrapper = document.querySelector(smartDrop);
            if (parentWrapper) {
                var dropdownElement = dropdownRef.current;
                if (dropdownElement) {
                    var dropDownPosition = dropdownElement.getBoundingClientRect();
                    if (parentWrapper.clientHeight - dropDownPosition.top < 0) {
                        return 'top';
                    }
                }
            }
        }
        return 'down';
    };
    var handleExpand = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setCollapsedElm(!collapsedElm);
        collpasedRef.current = !collapsedElm;
        if (typeof onClick === 'function') {
            onClick();
        }
    };
    var handleExpandDouble = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setCollapsedElm(!collapsedElm);
        collpasedRef.current = !collapsedElm;
    };
    var pageClick = function (e) {
        var _a;
        var targetElement = e.target;
        if (!((_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.contains(targetElement)) &&
            (collpasedRef === null || collpasedRef === void 0 ? void 0 : collpasedRef.current)) {
            setCollapsedElm(false);
            collpasedRef.current = false;
        }
    };
    var refDropdownElement = function (ref) {
        dropdownRef.current = ref;
    };
    var renderSplitted = function () {
        var direction = getDropDirection();
        var dropdownClassNames = classnames('drop-down', style, { 'is-open': collapsedElm }, className);
        var typeStyle = type ? "btn--".concat(type) : '';
        return (_jsxs(Wrapper, { className: dropdownClassNames, ref: refDropdownElement, children: [_jsx("div", { className: classnames('drop-down-trigger', typeStyle, {
                        disabled: disabled,
                        'u-no-border': noBorder,
                    }), children: _jsxs(Fragment, { children: [typeof title === 'function' ? (
                            // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                            title()) : (_jsx(Button, { onClick: displayFor === 'Mobile' ? handleExpand : primaryClickAction, onKeyUp: primaryClickAction, className: "drop-down_splitted__primary", variant: type || 'text', color: type === 'fill' ? 'secondary' : 'default', title: tooltip, "aria-label": tooltip, disabled: isLoading || disabled, children: _jsx("span", { dangerouslySetInnerHTML: { __html: title || '' } }) })), icon && icon !== '' && (_jsx(Svg, { className: "m-left--xx-small", icon: icon })), showArrow && (_jsxs(Button, { onClick: handleExpand, variant: type || 'text', color: type === 'fill' ? 'secondary' : 'default', disabled: disabled || isLoading, onKeyUp: handleExpand, "aria-label": tooltip, title: "Expand button", className: "drop-down-icon ".concat((branded && 't-color-primary-bg') || ''), children: [_jsx("span", { className: "hidden", children: "actions" }), _jsx(Svg, { className: "m-left--xx-small", icon: collapsedElm ? 'up' : 'down', size: "x-small" })] }))] }) }), _jsx("div", { className: collapsedElm
                        ? "dropdown-show-content ".concat(direction)
                        : 'dropdown-hide-content', children: children })] }));
    };
    var renderNormal = function () {
        var direction = getDropDirection();
        var dropdownClassNames = classnames('drop-down', style, { 'is-open': collapsedElm }, className);
        var typeStyle = type ? "btn--".concat(type) : '';
        return (_jsxs(Wrapper, { className: dropdownClassNames, ref: refDropdownElement, children: [_jsx(Button, { className: classnames('drop-down-trigger', typeStyle), variant: type || 'text', color: type === 'fill' ? 'secondary' : 'default', disabled: disabled, onClick: handleExpand, title: tooltip, "aria-label": tooltip, id: id, tabIndex: 0, children: _jsxs(Fragment, { children: [_jsx("span", { className: "hidden", children: tooltip || ' ' }), typeof title === 'function' ? (
                            // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                            title()) : title !== '' ? (_jsx("span", { className: "p-right--small", dangerouslySetInnerHTML: { __html: title || '' } })) : (''), icon && typeof icon === 'string' && (_jsx(Svg, { className: "m-left--x-small", icon: icon, size: "large" })), icon && typeof icon === 'object' && icon, showArrow && typeof icon !== 'object' && (_jsx("span", { className: "drop-down-icon sb__btn ".concat((branded && 't-color-primary-bg') || ''), title: collapsedElm
                                    ? formatMessage({ id: 'Global.collapse' })
                                    : formatMessage({ id: 'Global.expand' }), children: _jsx(Svg, { className: "m-left--x-small", icon: collapsedElm ? 'up' : 'down', size: "x-small" }) }))] }) }), _jsx("div", { role: role, className: collapsedElm
                        ? "dropdown-show-content ".concat(direction)
                        : 'dropdown-hide-content', children: children })] }));
    };
    var renderIcon = function () {
        var direction = getDropDirection();
        var dropdownClassNames = classnames('drop-down', style, { 'is-open': collapsedElm }, className);
        var typeStyle = type ? "btn--".concat(type) : '';
        return (_jsxs(Wrapper, { className: dropdownClassNames, ref: refDropdownElement, children: [_jsx(ButtonIcon, { className: classnames('drop-down-trigger', typeStyle), disabled: disabled, onClick: handleExpand, title: tooltip || iconTitle, "aria-label": tooltip, tabIndex: 0, children: icon !== '' && typeof icon === 'string' ? _jsx(Svg, { icon: icon }) : icon }), _jsx("div", { className: collapsedElm
                        ? "dropdown-show-content ".concat(direction)
                        : 'dropdown-hide-content', children: children })] }));
    };
    var renderDouble = function () {
        var direction = getDropDirection();
        var dropdownClassNames = classnames('drop-down', style, { 'is-open': collapsedElm }, className);
        var typeStyle = type ? "btn--".concat(type) : '';
        return (_jsxs(Wrapper, { className: dropdownClassNames, ref: refDropdownElement, children: [typeof title === 'function' ? (
                // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                title()) : (_jsx("div", { role: "button", tabIndex: 0, className: "".concat(disabled ? 'disabled' : '', " ").concat(typeStyle), title: tooltip, onKeyUp: onMouseDown, onClick: onMouseDown, onDoubleClick: onDoubleClick, children: _jsx("span", { dangerouslySetInnerHTML: { __html: title } }) })), showArrow && (_jsx(ButtonIcon, { icon: icon, className: typeStyle, disabled: disabled, title: tooltip, onMouseDown: handleExpandDouble, "aria-label": tooltip, tabIndex: 0 })), _jsx("div", { className: collapsedElm
                        ? "dropdown-show-content ".concat(direction)
                        : 'dropdown-hide-content', children: children })] }));
    };
    if (splitted) {
        return renderSplitted();
    }
    else if (double) {
        return renderDouble();
    }
    else if (iconRendering) {
        return renderIcon();
    }
    return renderNormal();
};
export default DropDown;
