import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useImperativeHandle, forwardRef } from 'react';
import classnames from 'classnames';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor as RdwEditor } from 'react-draft-wysiwyg';
import { getLang } from '../../../Content/services/AppUtils';
import translations from './locales';
import { Wrapper } from './Editor.styles';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
var localization = {
    locale: getLang(),
    translations: translations[getLang()],
};
var Editor = forwardRef(function (_a, ref) {
    var _b = _a.wrapperClassName, wrapperClassName = _b === void 0 ? '' : _b, _c = _a.editorClassName, editorClassName = _c === void 0 ? '' : _c, _d = _a.onChange, onChange = _d === void 0 ? null : _d, _e = _a.error, error = _e === void 0 ? '' : _e, _f = _a.helpMessage, helpMessage = _f === void 0 ? '' : _f, _g = _a.className, className = _g === void 0 ? '' : _g, _h = _a.props, props = _h === void 0 ? {} : _h, _j = _a.toolbar, toolbar = _j === void 0 ? {} : _j, _k = _a.label, label = _k === void 0 ? 'Editor' : _k, _l = _a.required, required = _l === void 0 ? false : _l, _m = _a.stripPastedStyles, stripPastedStyles = _m === void 0 ? false : _m, _o = _a.showLabel, showLabel = _o === void 0 ? true : _o, _p = _a.readOnly, readOnly = _p === void 0 ? false : _p, _q = _a.toolbarHidden, toolbarHidden = _q === void 0 ? false : _q, _r = _a.content, content = _r === void 0 ? '' : _r, _s = _a.placeholder, placeholder = _s === void 0 ? '' : _s;
    var formElementClassNames = classnames('c-form-element c-html-editor', className);
    var _t = useState(function () {
        var initialState = null;
        if (content && content !== '') {
            var blocksFromHtml = htmlToDraft(content);
            var contentBlocks = blocksFromHtml.contentBlocks, entityMap = blocksFromHtml.entityMap;
            var contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            initialState = EditorState.createWithContent(contentState);
        }
        else {
            initialState = EditorState.createEmpty();
        }
        return initialState;
    }), editorState = _t[0], setEditorState = _t[1];
    // Expose reset function to the parent
    useImperativeHandle(ref, function () { return ({
        reset: function () {
            setEditorState(EditorState.createEmpty());
        },
    }); });
    var onEditorStateChange = function (es) {
        if (typeof onChange === 'function') {
            var htmlContent = draftToHtml(convertToRaw(es.getCurrentContent()));
            if (htmlContent.includes('<p></p>') && htmlContent.length < 9) {
                htmlContent = '';
            }
            onChange(htmlContent, props);
        }
        setEditorState(es);
    };
    var renderHelp = function () {
        var createMarkup = { __html: helpMessage || error };
        return (_jsx("span", { className: "c-form-element__help", dangerouslySetInnerHTML: createMarkup }));
    };
    var renderLabel = function () { return (_jsxs("label", { className: "c-form-element__label", htmlFor: props.id, children: [required && _jsx("abbr", { className: "required", children: "* " }), _jsx("strong", { children: label })] })); };
    return (_jsx(Wrapper, { children: _jsxs("div", { className: formElementClassNames, children: [showLabel && renderLabel(), _jsx(RdwEditor, { editorState: editorState, wrapperClassName: "".concat(wrapperClassName, " ").concat((error && 'has-error') || ''), editorClassName: "".concat(editorClassName, "  ").concat((error && 'editor__has-error') || ''), onEditorStateChange: onEditorStateChange, toolbar: toolbar, stripPastedStyles: stripPastedStyles, localization: localization, readOnly: readOnly, toolbarHidden: toolbarHidden, placeholder: placeholder }), (error || helpMessage) && renderHelp()] }) }));
});
Editor.displayName = 'Editor';
export default Editor;
