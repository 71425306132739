var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import FacetGroup from 'new-components/FacetGroup';
import FacetItem from 'new-components/FacetItem';
import parameters from 'constants/parameters';
import FacetStore from 'stores/FacetStore';
import SearchStore from 'stores/SearchStore';
import HierarchyFilters from './HierarchyFilters';
import FilterCustomerMetrics from './FilterCustomerMetrics';
import { Wrapper } from './Facets.styles';
var propTypes = {
    updateFilter: PropTypes.func.isRequired,
    selectedFacetItemIds: PropTypes.arrayOf(PropTypes.string.isRequired)
        .isRequired,
};
var FACET_ITEM_KEY_SEPARATOR = parameters.FACET_ITEM_KEY_SEPARATOR, TIMEOUT_BEFORE_FILTER_UPDATE = parameters.TIMEOUT_BEFORE_FILTER_UPDATE;
function Facets(_a) {
    var updateFilter = _a.updateFilter, selectedFacetItemIdsProp = _a.selectedFacetItemIds;
    var formatMessage = useIntl().formatMessage;
    var interval = useRef(0);
    var _b = useState([]), selectedFacetItemIds = _b[0], setSelectedFacetItemIds = _b[1];
    var _c = useState(false), setRerender = _c[1];
    useEffect(function () {
        FacetStore.addFacetsUpdateListener(onChange);
        FacetStore.addSaveListener(onChange);
        return function () {
            FacetStore.removeFacetsUpdateListener(onChange);
            FacetStore.removeSaveListener(onChange);
        };
    }, []);
    useEffect(function () {
        if (selectedFacetItemIdsProp) {
            setSelectedFacetItemIds(selectedFacetItemIdsProp);
        }
    }, [selectedFacetItemIdsProp]);
    var onChange = function () {
        setRerender(function (prevState) { return !prevState; });
    };
    var handleFacetGroupChange = function (id) {
        var facetItems = FacetStore.getFacetGroupById(id).facetItems.filter(function (_a) {
            var isDisplayInSearch = _a.isDisplayInSearch;
            return isDisplayInSearch;
        });
        var addFacet = SearchStore.addFacet, deleteFacet = SearchStore.deleteFacet;
        var isChecked = facetItems.every(function (_a) {
            var facetItemId = _a.id;
            return selectedFacetItemIds.includes(id + FACET_ITEM_KEY_SEPARATOR + facetItemId);
        });
        var getFacetItemObject = function (_a) {
            var name = _a.name, facetItemId = _a.id;
            return ({
                id: id,
                text: name,
                value: facetItemId,
            });
        };
        if (isChecked) {
            setSelectedFacetItemIds(function (prevState) {
                return prevState.filter(function (facetItem) { return facetItem.split(FACET_ITEM_KEY_SEPARATOR)[0] !== id; });
            });
            facetItems.forEach(function (facetItem) {
                deleteFacet(getFacetItemObject(facetItem));
            });
        }
        else {
            setSelectedFacetItemIds(function (prevState) { return __spreadArray(__spreadArray([], prevState, true), facetItems.reduce(function (previous, _a) {
                var facetItemId = _a.id;
                var facetItemKey = id + FACET_ITEM_KEY_SEPARATOR + facetItemId;
                var isFacetItemAlreadyAdded = prevState.includes(facetItemKey);
                return isFacetItemAlreadyAdded
                    ? previous
                    : __spreadArray(__spreadArray([], previous, true), [facetItemKey], false);
            }, []), true); });
            facetItems.forEach(function (facetItem) {
                addFacet(getFacetItemObject(facetItem));
            });
        }
        updateFilters();
    };
    var handleFacetItemChange = function (id, _a) {
        var name = _a.name, facetItemId = _a.id;
        var addFacet = SearchStore.addFacet, deleteFacet = SearchStore.deleteFacet;
        var facetItemKey = id + FACET_ITEM_KEY_SEPARATOR + facetItemId;
        var isBooleanValue = facetItemId === 'false' || facetItemId === 'true';
        var getTranslationByBooleanId = function () {
            return "Global.".concat(facetItemId === 'true' ? 'yes' : 'no');
        };
        var facetItemObject = {
            id: id,
            text: isBooleanValue
                ? "".concat(name, " - ").concat(formatMessage({ id: getTranslationByBooleanId() }))
                : name,
            value: facetItemId,
        };
        var isFacetAlreadyAdded = selectedFacetItemIds.includes(facetItemKey);
        if (isFacetAlreadyAdded) {
            setSelectedFacetItemIds(function (prevState) {
                return prevState.filter(function (key) { return key !== facetItemKey; });
            });
            deleteFacet(facetItemObject);
        }
        else {
            setSelectedFacetItemIds(function (prevState) { return __spreadArray(__spreadArray([], prevState, true), [facetItemKey], false); });
            addFacet(facetItemObject);
        }
        updateFilters();
    };
    var updateFilters = function () {
        clearTimeout(interval.current);
        interval.current = setTimeout(updateFilter, TIMEOUT_BEFORE_FILTER_UPDATE);
    };
    var renderCustomMetrics = function (cmFacet) { return (_jsx(FilterCustomerMetrics, { facet: cmFacet, onFacetItemChange: handleFacetItemChange, selectedFacetItemIds: selectedFacetItemIds }, cmFacet.id)); };
    var renderFacetGroup = function (_a) {
        var facetItems = _a.facetItems, id = _a.id, filterElasticFieldName = _a.filterElasticFieldName, bundle = _a.bundle, filterSourceType = _a.filterSourceType;
        var topFacetItems = facetItems
            .filter(function (_a) {
            var isDisplayInSearch = _a.isDisplayInSearch;
            return isDisplayInSearch;
        })
            .sort(function (a, b) { return b.numberOfClips - a.numberOfClips; });
        var topFive = facetItems
            .sort(function (a, b) { return b.numberOfClips - a.numberOfClips; })
            .slice(0, 5);
        var isAllChildrenAreChecked = topFacetItems.every(function (_a) {
            var facetItemId = _a.id;
            return selectedFacetItemIds.includes(id + FACET_ITEM_KEY_SEPARATOR + facetItemId);
        });
        var isAtLeastOneChildrenIsChecked = topFacetItems.some(function (_a) {
            var facetItemId = _a.id;
            return selectedFacetItemIds.includes(id + FACET_ITEM_KEY_SEPARATOR + facetItemId);
        });
        var displayedFacetName = '';
        if (filterSourceType === 'CM') {
            displayedFacetName = filterElasticFieldName;
        }
        else {
            displayedFacetName = formatMessage({ id: bundle });
        }
        return topFacetItems.length > 0 || topFive.length > 0 ? (_jsx(FacetGroup, { checked: isAllChildrenAreChecked && topFacetItems.length !== 0, id: id, indeterminate: isAtLeastOneChildrenIsChecked && !isAllChildrenAreChecked, name: displayedFacetName, onChange: handleFacetGroupChange, onOpen: function () { }, selectedFacetItemIds: selectedFacetItemIds, children: ((topFacetItems.length && topFacetItems) || topFive).map(function (facetItem) { return (_jsx(FacetItem, { facetGroupId: id, item: facetItem, onChange: handleFacetItemChange, selectedFacetItemIds: selectedFacetItemIds }, facetItem.id)); }) }, id)) : null;
    };
    var renderHierarchyFilters = function (_a) {
        var id = _a.id;
        return (_jsx(FacetGroup, { hasAdvancedFilters: false, id: id, name: formatMessage({ id: 'Global.Dimension.Hierarchies' }), children: _jsx(HierarchyFilters, { updateFilter: updateFilter }) }, id));
    };
    var filterFacetGroupWithValidPosition = function (fi) {
        return fi.filterFacetSelected && (fi.forAdvancedFacetFilter || fi.forFacetFilter);
    };
    var facets = FacetStore.facetGroupCollection.collection
        .filter(filterFacetGroupWithValidPosition)
        .sort(function (a, b) { return a.filterFacetPosition - b.filterFacetPosition; });
    return (_jsx(Wrapper, { className: "c-facets", children: facets.map(function (facetGroupItem) {
            if (facetGroupItem.filterSourceType === 'CM') {
                return renderCustomMetrics(facetGroupItem);
            }
            else if (facetGroupItem.filterElasticFieldPath === 'clipSelections.name') {
                return renderHierarchyFilters(facetGroupItem);
            }
            return renderFacetGroup(facetGroupItem);
        }) }));
}
Facets.propTypes = propTypes;
export default Facets;
