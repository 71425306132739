var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import parameters from '@constants/parameters';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Input from '@commons/Input';
import DualListBoxItem from '../DualListBoxItem';
import { AiOutlineSearch } from 'react-icons/ai';
import { Wrapper } from './DualListBoxGroup.styles';
var BREAKPOINT = parameters.BREAKPOINT;
var DualListBoxGroup = function (_a) {
    var listBoxItems = _a.listBoxItems, title = _a.title, id = _a.id, onMoveItem = _a.onMoveItem, selectedItems = _a.selectedItems, handleItemClick = _a.handleItemClick, onChange = _a.onChange, isDragging = _a.isDragging, draggableId = _a.draggableId, windowWidth = _a.windowWidth, handleToggle = _a.handleToggle;
    var formatMessage = useIntl().formatMessage;
    var _b = useState(''), textSearch = _b[0], setTextSearch = _b[1];
    var isSelected = function (item) {
        return selectedItems &&
            selectedItems.length > 0 &&
            selectedItems.includes(item.id);
    };
    var showCount = function (item) {
        var count = selectedItems.length;
        return count > 1 && isDragging && item.id === draggableId;
    };
    var onTextSearchChangeHandler = function (value) {
        setTextSearch(value);
        onChange(value);
    };
    return (_jsxs(Wrapper, { className: classnames({
            'p-bottom--medium': windowWidth < BREAKPOINT.md,
        }), children: [_jsx("div", { className: "c-form-element__label filter-title-search", children: _jsxs("span", { children: [title, " (", listBoxItems === null || listBoxItems === void 0 ? void 0 : listBoxItems.length, ")"] }) }), _jsxs("div", { className: "dualListBoxGroup__search_wrapper", children: [_jsx(AiOutlineSearch, { size: "1.5em" }), _jsx(Input, { onChange: onTextSearchChangeHandler, value: textSearch, placeholder: formatMessage({
                            id: 'Monitoring.SearchBar.ConfigureYourFilter.SearchArticle',
                        }), showLabel: false, style: { backgroundColor: 'unset' } })] }), _jsx(Droppable, { droppableId: String(id), children: function (provided) { return (_jsxs("div", __assign({ id: "".concat(id, "_options"), ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { className: "dualListBoxGroup__options list-unstyled new-scrollbar", children: [listBoxItems === null || listBoxItems === void 0 ? void 0 : listBoxItems.map(function (item, index) { return (_jsx(Draggable, { draggableId: String(item.id), index: index, children: function (provided) { return (_jsx("div", __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { children: _jsx(DualListBoxItem
                                // isLast={listBoxItems.length === index + 1}
                                , { 
                                    // isLast={listBoxItems.length === index + 1}
                                    item: item, onClick: handleItemClick, onMoveItem: onMoveItem, count: selectedItems.length, selected: isSelected(item), showCount: showCount(item), windowWidth: windowWidth, handleToggle: handleToggle, idOption: id }, item.id) }))); } }, item.id)); }), provided.placeholder] }))); } })] }));
};
export default DualListBoxGroup;
