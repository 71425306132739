import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'commons/ReactSelect';
import { v4 as uuid } from 'uuid';
var ValueSelector = function (_a) {
    var value = _a.value, options = _a.options, handleOnChange = _a.handleOnChange, className = _a.className, placeholder = _a.placeholder;
    var result = options.map(function (option) { return ({
        label: option.label,
        value: option.name,
    }); });
    var uniqueId = uuid();
    return (_jsxs(Fragment, { children: [_jsx("label", { htmlFor: (value && value.value) || uniqueId, className: "hidden", children: (value && value.value) || uniqueId }), _jsx(ReactSelect, { id: (value && value.value) || uniqueId, className: className, onChange: handleOnChange, options: result, placeholder: placeholder, value: result === null || result === void 0 ? void 0 : result.find(function (item) { return item.value === value; }) })] }));
};
ValueSelector.displayName = 'ValueSelector';
ValueSelector.defaultProps = {
    className: '',
};
ValueSelector.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        }),
    ]),
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
    })).isRequired,
    className: PropTypes.string,
    handleOnChange: PropTypes.func.isRequired,
};
export default ValueSelector;
