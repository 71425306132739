export default {
    // Generic
    'generic.add': 'Hinzufügen',
    'generic.cancel': 'Abbrechen',
    // BlockType
    'components.controls.blocktype.h1': 'Überschrift 1',
    'components.controls.blocktype.h2': 'Überschrift 2',
    'components.controls.blocktype.h3': 'Überschrift 3',
    'components.controls.blocktype.h4': 'Überschrift 4',
    'components.controls.blocktype.h5': 'Überschrift 5',
    'components.controls.blocktype.h6': 'Überschrift 6',
    'components.controls.blocktype.blockquote': 'Zitat',
    'components.controls.blocktype.code': 'Quellcode',
    'components.controls.blocktype.blocktype': 'Blocktyp',
    'components.controls.blocktype.normal': 'Normal',
    // Color Picker
    'components.controls.colorpicker.colorpicker': 'Farbauswahl',
    'components.controls.colorpicker.text': 'Text',
    'components.controls.colorpicker.background': 'Hintergrund',
    // Embedded
    'components.controls.embedded.embedded': 'Eingebettet',
    'components.controls.embedded.embeddedlink': 'Eingebetteter Link',
    'components.controls.embedded.enterlink': 'Link eingeben',
    // Emoji
    'components.controls.emoji.emoji': 'Emoji',
    // FontFamily
    'components.controls.fontfamily.fontfamily': 'Schriftart',
    // FontSize
    'components.controls.fontsize.fontsize': 'Schriftgröße',
    // History
    'components.controls.history.history': 'Historie',
    'components.controls.history.undo': 'Zurücknehmen',
    'components.controls.history.redo': 'Wiederholen',
    // Image
    'components.controls.image.image': 'Bild',
    'components.controls.image.fileUpload': 'Datei-Upload',
    'components.controls.image.byURL': 'URL',
    'components.controls.image.dropFileText': 'Dateien ziehen und ablegen, oder klicken zum Hochladen',
    // Inline
    'components.controls.inline.bold': 'Fett',
    'components.controls.inline.italic': 'Kursiv',
    'components.controls.inline.underline': 'Unterstreichen',
    'components.controls.inline.strikethrough': 'Durchstreichen',
    'components.controls.inline.monospace': 'Monospace',
    'components.controls.inline.superscript': 'Hochgestellt',
    'components.controls.inline.subscript': 'Tiefgestellt',
    // Link
    'components.controls.link.linkTitle': 'Link-Titel',
    'components.controls.link.linkTarget': 'Link-Ziel',
    'components.controls.link.linkTargetOption': 'Link in neuem Fenster öffnen',
    'components.controls.link.link': 'Link',
    'components.controls.link.unlink': 'Aufheben',
    // List
    'components.controls.list.list': 'Liste',
    'components.controls.list.unordered': 'Aufzählung',
    'components.controls.list.ordered': 'Nummerierte Liste',
    'components.controls.list.indent': 'Einzug vergrößern',
    'components.controls.list.outdent': 'Einzug reduzieren',
    // Remove
    'components.controls.remove.remove': 'Entfernen',
    // TextAlign
    'components.controls.textalign.textalign': 'Textausrichtung',
    'components.controls.textalign.left': 'Linksbündig',
    'components.controls.textalign.center': 'Zentrieren',
    'components.controls.textalign.right': 'Rechtsbündig',
    'components.controls.textalign.justify': 'Blocksatz',
};
