import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*eslint-disable*/
import React, { useState, forwardRef, useImperativeHandle, useRef, } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import AdvancedFiltersTools from './AdvancedFiltersTools';
import FavoriteSearchForm from './FavoriteSearchForm';
import { Wrapper } from './FavoriteSearch.styles';
var propTypes = {
    getFavoriteSeachName: PropTypes.func,
    isError: PropTypes.bool,
    handleChangeErrorState: PropTypes.func,
};
var defaultProps = {
    getFavoriteSeachName: function () { },
    isError: false,
    handleChangeErrorState: function () { },
};
var FavoriteSearch = forwardRef(function (_a, ref) {
    var mode = _a.mode, favoriteSearch = _a.favoriteSearch, handleCloseModal = _a.handleCloseModal, getFavoriteSeachName = _a.getFavoriteSeachName, getNumberFilter = _a.getNumberFilter, shareWithCoworker = _a.shareWithCoworker, isError = _a.isError, handleChangeErrorState = _a.handleChangeErrorState, updateFilter = _a.updateFilter, expandedDropDown = _a.expandedDropDown;
    var advancedFilterRef = useRef();
    var _b = useState({}), favoriteSeachFormState = _b[0], setFavoriteSeachFormState = _b[1];
    var getFormValues = function (value) {
        setFavoriteSeachFormState(value);
        getFavoriteSeachName(value.favoriteSearchName);
    };
    useImperativeHandle(ref, function () { return ({
        handleValidationClick: function () {
            if (advancedFilterRef.current) {
                advancedFilterRef.current.handleValidationClick();
            }
        },
    }); });
    return (_jsxs(Wrapper, { ref: ref, children: [_jsx(FavoriteSearchForm, { mode: mode, favoriteSearch: favoriteSearch, getFormValues: getFormValues, ref: advancedFilterRef, isError: isError, handleChangeErrorState: handleChangeErrorState }), _jsx(AdvancedFiltersTools, { mode: mode, favoriteSeachFormState: favoriteSeachFormState, getNumberFilter: getNumberFilter, favoriteSearch: favoriteSearch, ref: advancedFilterRef, handleCloseModal: handleCloseModal, shareWithCoworker: shareWithCoworker, updateFilter: updateFilter })] }));
});
FavoriteSearch.defaultProps = defaultProps;
FavoriteSearch.propTypes = propTypes;
export default injectIntl(FavoriteSearch, { forwardRef: true });
