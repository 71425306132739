var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import moment from 'moment';
import ActionTypes from '@constants/actionTypes';
import * as AppUtils from '@services/AppUtils';
import BaseStore from '@stores/BaseStore';
import { initialTheme } from '@theme/initial-theme';
import { isValidColor } from '@utils/helpers';
var GET_CURRENT_USER = 'getCurrentUser';
var CHANGE_CURRENT_USER_LANG = 'changeCurrentUserLang';
var LOGOUT = 'LOGOUT';
var RESET = 'RESET_THEME';
var UPDATE_USER_BRAND_USER = 'UPDATE_USER_BRAND_USER';
var GET_MINCIDENT_MESSAGE = 'GET_MINCIDENT_MESSAGE';
var defaultLogo = 'https://kmplus.s3.eu-west-1.amazonaws.com/img/Onclusive/logo.png';
var UserStore = /** @class */ (function (_super) {
    __extends(UserStore, _super);
    /* eslint-enable no-param-reassign */
    function UserStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this._registerToActions.bind(_this); });
        return _this;
    }
    /* eslint-disable no-param-reassign */
    UserStore.setAvatar = function (user, avatar) {
        if (!user || (!avatar && user.avatar)) {
            return;
        }
        user.avatar = typeof avatar === 'string' ? avatar : '';
    };
    UserStore.setAccountId = function (user, accountId) {
        if (!user || (!accountId && user.accountId)) {
            return;
        }
        user.accountId = typeof accountId === 'number' ? accountId : undefined;
    };
    UserStore.setFirstName = function (user, firstName) {
        if (!user || (!firstName && user.firstName)) {
            return;
        }
        user.firstName = typeof firstName === 'string' ? firstName : '';
    };
    UserStore.setAccountName = function (user, accountName) {
        if (!user || (!accountName && user.accountName)) {
            return;
        }
        user.accountName = typeof accountName === 'string' ? accountName : '';
    };
    UserStore.setProfileName = function (user, profileName) {
        if (!user || (!profileName && user.profileName)) {
            return;
        }
        user.profileName = typeof profileName === 'string' ? profileName : '';
    };
    UserStore.setLastName = function (user, lastName) {
        if (!user || (!lastName && user.lastName)) {
            return;
        }
        user.lastName = typeof lastName === 'string' ? lastName : '';
    };
    UserStore.setUserId = function (user, userId) {
        if (!user || (!userId && user.userId)) {
            return;
        }
        user.userId = typeof userId === 'number' ? userId : undefined;
    };
    UserStore.setEmail = function (user, email) {
        if (!user || (!email && user.email)) {
            return;
        }
        user.email = typeof email === 'string' ? email : '';
    };
    UserStore.setAccountLogo = function (user, accountLogo) {
        if (!user || (!accountLogo && user.accountLogo)) {
            return;
        }
        user.accountLogo = accountLogo || '';
    };
    UserStore.setBrand = function (user, Brand) {
        if (!user || (!Brand && user.Brand)) {
            return;
        }
        user.Brand = Brand || '';
    };
    UserStore.setInsightAccess = function (user, kmplus_InsightAccess) {
        if (!user) {
            return;
        }
        user.kmplus_InsightAccess = kmplus_InsightAccess;
    };
    UserStore.setInsightCanAccessExport = function (user, kmplus_InsightCanAccessExport) {
        if (!user) {
            return;
        }
        user.kmplus_InsightCanAccessExport = kmplus_InsightCanAccessExport;
    };
    UserStore.setInsightCanEditDashboard = function (user, kmplus_InsightCanEditDashboard) {
        if (!user) {
            return;
        }
        user.kmplus_InsightCanEditDashboard = kmplus_InsightCanEditDashboard;
    };
    UserStore.setInsightCanExportInfography = function (user, kmplus_InsightCanExportInfography) {
        if (!user) {
            return;
        }
        user.kmplus_InsightCanExportInfography = kmplus_InsightCanExportInfography;
    };
    UserStore.setCurrency = function (user, accountCurrency) {
        if (!user || (!accountCurrency && user.accountCurrency)) {
            return;
        }
        user.accountCurrency = AppUtils.convertCurrencyNameToCurrencySymbol(accountCurrency);
        // this line is meant for exposing the currency globally in the winow object
        // @ts-expect-error TODO: remove variables from global
        window.km.global.currency =
            AppUtils.convertCurrencyNameToCurrencySymbol(accountCurrency);
    };
    UserStore.setKantarUser = function (user, isKantarUser) {
        if (!user) {
            return;
        }
        user.isKantarUser = isKantarUser;
    };
    UserStore.setIsoCode = function (user, isoCode) {
        if (!user) {
            return;
        }
        user.isoCode = isoCode;
    };
    UserStore.prototype.initializeState = function () {
        this.currentUser = {
            clipReadIds: [],
            firstName: '',
            lastName: '',
            accountName: '',
            profileName: '',
        };
        this.isoCode = '';
        this._users = [];
        this._userProfile = null;
        this._selectedTimeZone = '';
        this._fbLoginPageURL = '';
        this._usersList = [];
        this._userRoleAndClipSelectionById = null;
        this._profiles = [];
        this._groups = [];
        this._accountHasSocialMedia = '';
        this.initializedProfiles = false;
        this.initializedGroups = false;
        this.incidentMessageData = null;
    };
    UserStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    UserStore.prototype.resetOnSwitchAccount = function () {
        this._groups = [];
        this._usersList = [];
    };
    Object.defineProperty(UserStore.prototype, "users", {
        get: function () {
            return this._users;
        },
        set: function (value) {
            this._users = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserStore.prototype, "userRoleAndClipSelectionById", {
        get: function () {
            return this._userRoleAndClipSelectionById;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserStore.prototype, "userProfile", {
        get: function () {
            return this._userProfile;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserStore.prototype, "selectedTimeZone", {
        get: function () {
            return this._selectedTimeZone;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserStore.prototype, "groups", {
        get: function () {
            return this._groups;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserStore.prototype, "usersList", {
        get: function () {
            return this._usersList;
        },
        set: function (users) {
            this._usersList = users;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserStore.prototype, "profiles", {
        get: function () {
            return this._profiles;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserStore.prototype, "fbLoginPageURL", {
        get: function () {
            return this._fbLoginPageURL;
        },
        enumerable: false,
        configurable: true
    });
    UserStore.prototype.setFbLoginPageUrl = function (fbLoginPageURL) {
        this._fbLoginPageURL = fbLoginPageURL;
    };
    Object.defineProperty(UserStore.prototype, "accountHasSocialMedia", {
        get: function () {
            return this._accountHasSocialMedia;
        },
        enumerable: false,
        configurable: true
    });
    UserStore.prototype.getIncidentMessageData = function () {
        return this.incidentMessageData;
    };
    UserStore.prototype.setIncidentMessageData = function (bannerInfo) {
        this.incidentMessageData = bannerInfo;
    };
    UserStore.prototype.setAccountHasSocialMedia = function (accountHasSocialMedia) {
        this._accountHasSocialMedia = accountHasSocialMedia;
    };
    UserStore.prototype.setSelectedTimeZone = function (selectedTimeZone) {
        this._selectedTimeZone = selectedTimeZone;
    };
    UserStore.prototype.addUser = function (userAccountId) {
        var user = this.getUserByUserAccountId(userAccountId);
        if (typeof Number(userAccountId) === 'number' &&
            Object.entries(user).length === 0) {
            var newUser = { userAccountId: userAccountId };
            this.users.push(newUser);
            return newUser;
        }
        return user;
    };
    UserStore.prototype.getCurrentUser = function () {
        return this.currentUser;
    };
    UserStore.prototype.getUserByUserAccountId = function (userAccountId) {
        return (this.users.find(function (userItem) { return userItem.userAccountId === userAccountId; }) ||
            {});
    };
    UserStore.prototype.getInitializedGroups = function () {
        return this.initializedGroups;
    };
    UserStore.prototype.delete = function (userAccountId) {
        var res = this.usersList.filter(function (itemX) { return userAccountId !== Number(itemX.userAccountId); });
        this.usersList = res;
    };
    UserStore.prototype.getInitializedProfiles = function () {
        return this.initializedProfiles;
    };
    UserStore.prototype.setInitializedProfiles = function (initializedProfiles) {
        this.initializedProfiles = initializedProfiles;
    };
    UserStore.prototype.emitGetCurrentUser = function () {
        this.emit(GET_CURRENT_USER);
    };
    UserStore.prototype.emitCurrentUserLanguageListener = function (newLang) {
        this.emit(CHANGE_CURRENT_USER_LANG, newLang);
    };
    UserStore.prototype.addGetCurrentUserListener = function (callback) {
        this.on(GET_CURRENT_USER, callback);
    };
    UserStore.prototype.addCurrentUserLanguageListener = function (callback) {
        this.on(CHANGE_CURRENT_USER_LANG, callback);
    };
    UserStore.prototype.removeCurrentUserLanguageListener = function (callback) {
        this.removeListener(CHANGE_CURRENT_USER_LANG, callback);
    };
    UserStore.prototype.removeGetCurrentUserListener = function (callback) {
        this.removeListener(GET_CURRENT_USER, callback);
    };
    UserStore.prototype.emitLogout = function () {
        this.emit(LOGOUT);
    };
    UserStore.prototype.addLogoutListener = function (callback) {
        this.on(LOGOUT, callback);
    };
    UserStore.prototype.removeLogoutListener = function (callback) {
        this.removeListener(LOGOUT, callback);
    };
    UserStore.prototype.emitResetTheme = function () {
        this.emit(RESET);
    };
    UserStore.prototype.addResetThemeListener = function (callback) {
        this.on(RESET, callback);
    };
    UserStore.prototype.removeResetThemeListener = function (callback) {
        this.removeListener(RESET, callback);
    };
    UserStore.prototype.emitChangeUserBrandUser = function () {
        this.emit(UPDATE_USER_BRAND_USER);
    };
    UserStore.prototype.addChangeUserBrandUserListener = function (callback) {
        this.on(UPDATE_USER_BRAND_USER, callback);
    };
    UserStore.prototype.removeUserBrandUserListener = function (callback) {
        this.removeListener(UPDATE_USER_BRAND_USER, callback);
    };
    // message incident message event listeners
    UserStore.prototype.emitChangeIncidentMessage = function () {
        this.emit(GET_MINCIDENT_MESSAGE);
    };
    UserStore.prototype.addChangeIncidentMessageListener = function (callback) {
        this.on(GET_MINCIDENT_MESSAGE, callback);
    };
    UserStore.prototype.removeIncidentMessageListener = function (callback) {
        this.removeListener(GET_MINCIDENT_MESSAGE, callback);
    };
    UserStore.prototype._registerToActions = function (payload) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_CLIP_RESPONSE: {
                if ((_a = action.json) === null || _a === void 0 ? void 0 : _a.data) {
                    var _q = action.json, model = _q.model, data = _q.data;
                    if (model && !((_b = this.currentUser.clipReadIds) === null || _b === void 0 ? void 0 : _b.includes(model.id))) {
                        this.currentUser.clipReadIds = __spreadArray(__spreadArray([], (this.currentUser.clipReadIds || []), true), [
                            model.id,
                        ], false);
                    }
                    if (data && Array.isArray(data.comments)) {
                        var comments = data.comments;
                        comments.forEach(function (comment) {
                            if ('user' in comment) {
                                var userFormComment = comment.user;
                                var avatar = userFormComment.avatar, firstName = userFormComment.firstName, lastName = userFormComment.lastName, userAccountId = userFormComment.userAccountId;
                                var user = _this.addUser(Number(userAccountId));
                                UserStore.setAvatar(user, avatar);
                                UserStore.setFirstName(user, firstName);
                                UserStore.setLastName(user, lastName);
                            }
                        });
                        this.emitChange();
                    }
                }
                break;
            }
            case ActionTypes.GET_CLIPS_RESPONSE:
            case ActionTypes.GET_NEXT_CLIPS_RESPONSE:
                if (action.json) {
                    var data = ((_c = action.json.data) === null || _c === void 0 ? void 0 : _c.data) || action.json.data;
                    if (!Array.isArray(data)) {
                        return;
                    }
                    data
                        .filter(function (_a) {
                        var isAlreadyVisited = _a.isAlreadyVisited;
                        return isAlreadyVisited;
                    })
                        .map(function (_a) {
                        var id = _a.id;
                        return id;
                    })
                        .forEach(function (clipId) {
                        var _a;
                        if (!((_a = _this.currentUser.clipReadIds) === null || _a === void 0 ? void 0 : _a.includes(clipId))) {
                            _this.currentUser.clipReadIds = __spreadArray(__spreadArray([], (_this.currentUser.clipReadIds || []), true), [
                                clipId,
                            ], false);
                        }
                    });
                }
                else {
                    this.emitError('GET_CLIPS_READ');
                }
                break;
            case ActionTypes.GET_CURRENT_USER_RESPONSE:
                if ((_d = action.json) === null || _d === void 0 ? void 0 : _d.data) {
                    var _r = action.json.data, accountId = _r.accountId, accountCurrency = _r.accountCurrency, avatar = _r.avatar, firstName = _r.firstName, isoCode = _r.isoCode, lastName = _r.lastName, limitCategorize = _r.limitCategorize, limitLikes = _r.limitLikes, limitSentiment = _r.limitSentiment, limitTrash = _r.limitTrash, limitFavourite = _r.limitFavourite, userAccountId = _r.userAccountId, isKantarUser = _r.isKantarUser, accountLogo = _r.accountLogo, limitDraftList = _r.limitDraftList, kmplus_InsightAccess = _r.kmplus_InsightAccess, kmplus_InsightCanAccessExport = _r.kmplus_InsightCanAccessExport, kmplus_InsightCanEditDashboard = _r.kmplus_InsightCanEditDashboard, kmplus_InsightCanExportInfography = _r.kmplus_InsightCanExportInfography, brand = _r.brand, accountName = _r.accountName, profileName = _r.profileName, fbLoginPageURL = _r.fbLoginPageURL, accountHasSocialMedia = _r.accountHasSocialMedia, 
                    // to avoid rerender
                    email = _r.email, sellingCountry = _r.sellingCountry, selectedTimeZone = _r.selectedTimeZone, userId = _r.userId;
                    var user = this.addUser(userAccountId);
                    var availableIsoCodes = [
                        'ca',
                        'de',
                        'en',
                        'es',
                        'eu',
                        'fr',
                        'it',
                        'zh',
                    ];
                    this.setSelectedTimeZone(selectedTimeZone);
                    // Convert brand from string to object
                    var userBrand = (brand && Array.isArray(brand.split(';')) && brand.split(';')) ||
                        [];
                    var brandObj = userBrand.reduce(function (obj, currentValue) {
                        var _a;
                        var x = currentValue.split(':');
                        var key = x[0];
                        var value = x[1];
                        return __assign(__assign({}, obj), (_a = {}, _a[key] = value, _a));
                    }, {});
                    this.initialized = true;
                    this.isoCode = availableIsoCodes.includes(isoCode) ? isoCode : 'en';
                    this.limitCategorize = limitCategorize;
                    this.limitDraftList = limitDraftList;
                    this.limitLikes = limitLikes;
                    this.limitSentiment = limitSentiment;
                    this.limitTrash = limitTrash;
                    this.limitFavourite = limitFavourite;
                    this.setFbLoginPageUrl(fbLoginPageURL);
                    this.setAccountHasSocialMedia(accountHasSocialMedia);
                    UserStore.setAccountId(user, accountId);
                    UserStore.setKantarUser(user, isKantarUser);
                    UserStore.setIsoCode(user, this.isoCode);
                    UserStore.setCurrency(user, accountCurrency);
                    UserStore.setAvatar(user, avatar);
                    UserStore.setFirstName(user, firstName);
                    UserStore.setLastName(user, lastName);
                    UserStore.setEmail(user, email);
                    UserStore.setAccountLogo(user, accountLogo || defaultLogo);
                    UserStore.setAccountName(user, accountName);
                    UserStore.setProfileName(user, profileName);
                    // TODO: Pass brandObj (parsed object) unstead of brand ("string")
                    UserStore.setBrand(user, brand);
                    UserStore.setInsightAccess(user, kmplus_InsightAccess);
                    UserStore.setInsightCanAccessExport(user, kmplus_InsightCanAccessExport);
                    UserStore.setInsightCanEditDashboard(user, kmplus_InsightCanEditDashboard);
                    UserStore.setInsightCanExportInfography(user, kmplus_InsightCanExportInfography);
                    UserStore.setUserId(user, userId);
                    this.currentUser = user;
                    var lang = window.localStorage.getItem('lang');
                    if (lang == null ||
                        lang.toLowerCase() !== (isoCode || 'en').toLowerCase()) {
                        this.emitCurrentUserLanguageListener(isoCode);
                    }
                    var primaryBackgroundColor = isValidColor(brandObj['primary-background-color'])
                        ? brandObj['primary-background-color']
                        : initialTheme.palette.primary.main;
                    var primaryTextColor = isValidColor(brandObj['primary-text-color'])
                        ? brandObj['primary-text-color']
                        : initialTheme.palette.primary.contrastText;
                    var secondaryBackgroundColor = isValidColor(brandObj['secondary-background-color'])
                        ? brandObj['secondary-background-color']
                        : initialTheme.palette.secondary.main;
                    var secondaryTextColor = isValidColor(brandObj['secondary-text-color'])
                        ? brandObj['secondary-text-color']
                        : initialTheme.palette.primary.main;
                    localStorage.setItem('primary-background-color', primaryBackgroundColor);
                    localStorage.setItem('primary-text-color', primaryTextColor);
                    localStorage.setItem('secondary-background-color', secondaryBackgroundColor);
                    localStorage.setItem('secondary-text-color', secondaryTextColor);
                    this.currentUser.brandObj = {
                        'primary-background-color': primaryBackgroundColor,
                        'primary-text-color': primaryTextColor,
                        'secondary-background-color': secondaryBackgroundColor,
                        'secondary-text-color': secondaryTextColor,
                    };
                    // @ts-expect-error FS is added via tag script
                    window.FS.identify(userAccountId, {
                        accountName: accountName,
                        email: email,
                        displayName: "".concat(firstName || '', " ").concat(lastName || '').concat(isKantarUser ? ' (Onclusive User)' : ''),
                        isKantarUser: isKantarUser,
                        language: isoCode,
                        sellingCountry: sellingCountry,
                    });
                    // @ts-expect-error Intercom is added via tag script
                    window.Intercom('update', {
                        name: "".concat(firstName || '', " ").concat(lastName || ''), // Full name
                        email: email,
                        user_id: userAccountId,
                        created_at: moment().unix(),
                        language_override: AppUtils.isoCodeToLocale(isoCode),
                    });
                    this.emitGetCurrentUser();
                }
                else {
                    this.emitError('GET_CURRENT_USER');
                }
                break;
            case ActionTypes.GET_USER_PROFILE_DATA_RESPONSE:
                if ((_e = action.json) === null || _e === void 0 ? void 0 : _e.data) {
                    this._userProfile = action.json.data;
                    this.emitChange('GET_USER_PROFILE_DATA_RESPONSE');
                }
                else if (action.errors) {
                    this.emitError('GET_USER_PROFILE');
                }
                break;
            case ActionTypes.SAVE_USER_PROFILE_DATA_RESPONSE:
                if ((_f = action.json) === null || _f === void 0 ? void 0 : _f.data) {
                    this._userProfile = __assign(__assign({}, action.json.model), { isAuth0: !!((_g = this._userProfile) === null || _g === void 0 ? void 0 : _g.isAuth0) });
                    this.emitChange('SAVE_USER_PROFILE_DATA_RESPONSE', action.json.message);
                }
                else if (action.errors) {
                    this.emitError('UPDATE_USER_PROFILE', action.errors.error);
                }
                break;
            case ActionTypes.UPDATE_USER_BRANDING_RESPONSE:
                if (action.json) {
                    var branding = action.json.model.branding;
                    var user = this.getUserByUserAccountId(this.currentUser.userAccountId);
                    var brandObj = branding
                        .split(';')
                        .map(function (x) { return x.split(':').map(function (y) { return y.trim(); }); })
                        .reduce(function (a, x) {
                        // @ts-expect-error expect this deep access as string
                        a[x[0]] = x[1];
                        return a;
                    }, {});
                    localStorage.setItem('primary-background-color', brandObj['primary-background-color']);
                    localStorage.setItem('primary-text-color', brandObj['primary-text-color']);
                    localStorage.setItem('secondary-background-color', brandObj['secondary-background-color']);
                    localStorage.setItem('secondary-text-color', brandObj['secondary-text-color']);
                    UserStore.setBrand(user, branding);
                    this.currentUser.Brand = branding;
                    this.currentUser.brandObj = brandObj;
                    this.emitChangeUserBrandUser();
                }
                else if (action.errors) {
                    this.emitError('UPDATE_USER_PROFILE');
                }
                break;
            case ActionTypes.GET_LOGIN_WITH_TICKET_RESPONSE:
            case ActionTypes.GET_LOGIN_RESPONSE: {
                if ('resulType' in action.json && action.json.resulType === 'SUCCESS') {
                    var user = action.json.user;
                    this.currentUser.firstName = user.firstName;
                    this.currentUser.lastName = user.lastName;
                    this.currentUser.lang = user.lang;
                    this.currentUser.userId = user.userId;
                    this.users = [];
                    var _lang = window.localStorage.getItem('lang');
                    if ('accountId' in user && user.accountId) {
                        // @ts-expect-error TODO: to check with login with ticket response
                        this.currentUser.accountId = user.accountId;
                    }
                    if (_lang == null ||
                        _lang.toLowerCase() !== this.currentUser.lang.toLowerCase()) {
                        this.emitCurrentUserLanguageListener(this.currentUser.lang);
                    }
                    this.emitGetCurrentUser();
                }
                break;
            }
            /* Get Users List and Group */
            case ActionTypes.GET_USERS_GROUP_PROFILE_RESPONSE: {
                if ((_h = action.json) === null || _h === void 0 ? void 0 : _h.data) {
                    this._usersList = action.json.data;
                    this.emitChange('GET_USERS_GROUP_PROFILE_RESPONSE');
                }
                break;
            }
            case ActionTypes.GET_INFO_GROUPS_LIST_RESPONSE: {
                if (action.json) {
                    if (action.errors) {
                        this._errors.push(action.errors);
                    }
                    else if ({}.hasOwnProperty.call(action, 'json')) {
                        this._groups = ((_j = action.json) === null || _j === void 0 ? void 0 : _j.data) || [];
                        this.initializedGroups = true;
                        this.emitChange('GET_INFO_GROUPS_LIST_RESPONSE');
                    }
                }
                break;
            }
            case ActionTypes.GET_GROUPS_ROLES_BY_USER_ACCOUNT_RESPONSE:
                if ((_k = action.json) === null || _k === void 0 ? void 0 : _k.data) {
                    var data = action.json.data;
                    this._userRoleAndClipSelectionById = data;
                    this.emitChange();
                }
                else {
                    this.emitError('GET_GROUPS_ROLES_BY_USER_ACCOUNT_RESPONSE');
                }
                break;
            case ActionTypes.GET_DELETE_USER_RESPONSE:
                if (action.json) {
                    var UserAccountId = action.json.model.UserAccountId;
                    this.delete(UserAccountId);
                    this.emitChange('GET_DELETE_USER_RESPONSE');
                }
                else {
                    this.emitError('GET_DELETE_USER_RESPONSE');
                }
                break;
            case ActionTypes.GET_INSERT_NEW_USER_RESPONSE:
                if ((_l = action.json) === null || _l === void 0 ? void 0 : _l.data) {
                    var data = action.json.data.data;
                    if (data) {
                        var getProfile = function (profileId) {
                            var profile = _this._profiles.find(function (_a) {
                                var id = _a.id;
                                return id === profileId;
                            });
                            if (profile) {
                                return {
                                    profileId: profile.id,
                                    name: profile.name,
                                    description: profile.description,
                                    isAdministrator: profile.isAdministrator,
                                    isLocked: profile.isLocked,
                                };
                            }
                        };
                        this._usersList = __spreadArray(__spreadArray([], this._usersList, true), [
                            {
                                userId: data.id,
                                userName: data.userName,
                                firstName: data.firstName,
                                lastName: data.lastName,
                                status: 0,
                                userAccountId: data.userAccountId,
                                avatar: data.avatar,
                                email: data.email,
                                groups: [],
                                feeders: {
                                    feeders: [],
                                    isAccountAdministrator: false,
                                    isSystemAdministrator: false,
                                },
                                profile: getProfile(data.profileId),
                                // TODO: to check the flow because data does not have lastModificationPassword
                                // lastModificationPassword: data.lastModificationPassword,
                            },
                        ], false);
                        this.emitChange('GET_INSERT_NEW_USER_RESPONSE');
                    }
                    else {
                        this.emitError('GET_INSERT_NEW_USER_RESPONSE', action.json.data.message);
                    }
                }
                else {
                    this.emitError('GET_INSERT_NEW_USER_RESPONSE');
                }
                break;
            case ActionTypes.CHANGE_USER_ACCOUNT_STATUS_RESPONSE:
                if (action.json) {
                    var _s = action.json.model, userAccountId_1 = _s.userAccountId, IsActive = _s.IsActive;
                    var findIndexUser = this.usersList.findIndex(function (elm) { return elm.userAccountId === userAccountId_1; });
                    if (findIndexUser > -1) {
                        this.usersList[findIndexUser].status = Number(!IsActive);
                        this.emitChange('CHANGE_USER_ACCOUNT_STATUS_RESPONSE');
                    }
                }
                else {
                    this.emitError('CHANGE_USER_ACCOUNT_STATUS_RESPONSE');
                }
                break;
            case ActionTypes.GET_PROFILES_RESPONSE:
                if ((_m = action.json) === null || _m === void 0 ? void 0 : _m.data) {
                    var profiles = action.json.data.profiles;
                    this._profiles = profiles;
                    this.initializedProfiles = true;
                    this.emitChange('GET_PROFILES_RESPONSE');
                }
                else {
                    this.emitError('GET_PROFILES_RESPONSE');
                }
                break;
            case ActionTypes.UPDATE_GROUP_USER_MEMBERSHIP_RESPONSE:
                if (action.json) {
                    this.emitChange('UPDATE_GROUP_USER_MEMBERSHIP_RESPONSE');
                }
                else {
                    this.emitError('UPDATE_GROUP_USER_MEMBERSHIP_RESPONSE');
                }
                break;
            // @ts-expect-error TODO: remove this expect wehn adding specefic types to NewsDigest
            case ActionTypes.GET_NEWSDIGEST_RESPONSE:
            case ActionTypes.GET_REVIEW_RESPONSE:
                if (action.json.data) {
                    // TODO: accountCurrency and kmplus access are not returned from the backen
                    var _t = action.json.data, 
                    // accountCurrency,
                    userAccountId = _t.userAccountId, brand = _t.brand, 
                    // kmplus_InsightAccess,
                    // kmplus_InsightCanAccessExport,
                    // kmplus_InsightCanEditDashboard,
                    // kmplus_InsightCanExportInfography,
                    selectedLanguage = _t.selectedLanguage;
                    var user = this.addUser(Number(userAccountId));
                    var userBrand = (brand && Array.isArray(brand.split(';')) && brand.split(';')) ||
                        [];
                    var brandObj = userBrand.reduce(function (obj, currentValue) {
                        var _a;
                        var x = currentValue.split(':');
                        var key = x[0];
                        var value = x[1];
                        return __assign(__assign({}, obj), (_a = {}, _a[key] = value, _a));
                    }, {});
                    // UserStore.setCurrency(user, accountCurrency);
                    // UserStore.setInsightAccess(user, kmplus_InsightAccess);
                    // UserStore.setInsightCanAccessExport(
                    //   user,
                    //   kmplus_InsightCanAccessExport
                    // );
                    // UserStore.setInsightCanEditDashboard(
                    //   user,
                    //   kmplus_InsightCanEditDashboard
                    // );
                    // UserStore.setInsightCanExportInfography(
                    //   user,
                    //   kmplus_InsightCanExportInfography
                    // );
                    UserStore.setBrand(user, brand);
                    this.emitChangeUserBrandUser();
                    this.currentUser.brandObj = brandObj;
                    this.currentUser.lang = selectedLanguage;
                    this.emitGetCurrentUser();
                }
                else {
                    this.emitError('GET_CURRENT_USER');
                }
                break;
            case ActionTypes.GET_DASHBOARD_INFO_RESPONSE:
                if ((_o = action.json) === null || _o === void 0 ? void 0 : _o.data) {
                    var _u = action.json.data, accountCurrency = _u.accountCurrency, userAccountId = _u.userAccountId, accountLogo = _u.accountLogo, brand = _u.brand, kmplus_InsightAccess = _u.kmplus_InsightAccess, kmplus_InsightCanAccessExport = _u.kmplus_InsightCanAccessExport, kmplus_InsightCanEditDashboard = _u.kmplus_InsightCanEditDashboard, kmplus_InsightCanExportInfography = _u.kmplus_InsightCanExportInfography;
                    var user = this.addUser(userAccountId);
                    var userBrand = (brand && Array.isArray(brand.split(';')) && brand.split(';')) ||
                        [];
                    var brandObj = userBrand.reduce(function (obj, currentValue) {
                        var _a;
                        var x = currentValue.split(':');
                        var key = x[0];
                        var value = x[1];
                        return __assign(__assign({}, obj), (_a = {}, _a[key] = value, _a));
                    }, {});
                    UserStore.setBrand(user, brand);
                    UserStore.setCurrency(user, accountCurrency);
                    UserStore.setInsightAccess(user, kmplus_InsightAccess);
                    UserStore.setAccountLogo(user, accountLogo);
                    UserStore.setInsightCanAccessExport(user, kmplus_InsightCanAccessExport);
                    UserStore.setInsightCanEditDashboard(user, kmplus_InsightCanEditDashboard);
                    UserStore.setInsightCanExportInfography(user, kmplus_InsightCanExportInfography);
                    // this.currentUser.accountId = accountId;
                    this.currentUser = user;
                    this.currentUser.brandObj = brandObj;
                    this.emitGetCurrentUser();
                }
                else {
                    this.emitError('GET_CURRENT_USER');
                }
                break;
            case ActionTypes.GET_LOGOUT_REQUEST:
                this.emitLogout();
                break;
            case ActionTypes.RESET_THEME_PROVIDER_RESPONSE:
                this.emitResetTheme();
                break;
            case ActionTypes.GET_INCIDENT_MESSAGE_RESPONSE:
                if ((_p = action.json) === null || _p === void 0 ? void 0 : _p.data) {
                    var data = action.json.data;
                    this.setIncidentMessageData(data);
                    this.emitChangeIncidentMessage();
                }
                else {
                    this.emitError('GET_INCIDENT_MESSAGE_RESPONSE');
                }
                break;
            default:
                break;
        }
    };
    return UserStore;
}(BaseStore));
export default new UserStore();
