import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import keycode from 'keycode';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Svg from 'commons/Svg';
import HierarchyStore from 'stores/HierarchyStore';
var propTypes = {
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
var HierarchyFiltersItem = function (_a) {
    var id = _a.id, onClick = _a.onClick;
    var _b = HierarchyStore.getHierarchyById(id), clipSelectionsCount = _b.clipSelectionsCount, name = _b.name;
    var handleClick = function () {
        onClick(id);
    };
    var handleKeyDown = function (event) {
        if (event.keyCode === keycode('enter')) {
            handleClick();
        }
    };
    return (_jsxs("li", { className: "c-hierarchy-filters-item", onClick: handleClick, onKeyDown: handleKeyDown, role: "menuitem", tabIndex: 0, title: name, children: [_jsx("div", { className: "c-hierarchy-filters-item__name", children: name }), _jsx("div", { className: "c-hierarchy-filters-item__category-count", children: _jsx(FormattedMessage, { id: "HierarchyFilters.clipSelectionsCount", values: { numClipSelections: clipSelectionsCount } }) }), _jsx("div", { className: "c-hierarchy-filters-item__icon-wrapper", children: _jsx(Svg, { icon: "next", size: "small" }) })] }));
};
HierarchyFiltersItem.propTypes = propTypes;
export default HierarchyFiltersItem;
