import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
var SelectStyled = styled(Select)(function () { return ({
    '& .MuiSelect-select': {
        paddingTop: 5,
        paddingBottom: 5,
        borderBottom: '1px solid #00000',
    },
    '& fieldset': {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: 0,
    },
}); });
export { SelectStyled };
