import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Svg from 'commons/Svg';
import Button from 'commons/Button';
import UserStore from 'stores/UserStore';
import { Wrapper } from './EmptyPanelPlaceholder.styles';
var propTypes = {
    onAddWidgetClickHandler: PropTypes.func,
    onCreateWidgetClickHandler: PropTypes.func,
    disabled: PropTypes.bool,
    onAddTabClickHandler: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
};
var defaultProps = {
    onAddWidgetClickHandler: null,
    onCreateWidgetClickHandler: null,
    disabled: false,
};
var EmptyPanelPlaceholder = function (_a) {
    var onAddWidgetClickHandler = _a.onAddWidgetClickHandler, onCreateWidgetClickHandler = _a.onCreateWidgetClickHandler, disabled = _a.disabled, onAddTabClickHandler = _a.onAddTabClickHandler, mode = _a.mode;
    var pathname = useLocation().pathname;
    var user = UserStore.getCurrentUser();
    var kmplus_InsightCanEditDashboard = user.kmplus_InsightCanEditDashboard;
    return (_jsxs(Wrapper, { children: [mode !== 'filters' && (_jsx(FormattedMessage, { id: "Insight.Tab.emptyTab", children: function (text) { return _jsxs("h1", { className: "empty-pannel__title", children: [" ", text] }); } })), onAddWidgetClickHandler &&
                onCreateWidgetClickHandler &&
                pathname !== '/Public/dashboard/permalink/Index' && (_jsx("div", { className: "empty-pannel__actions", children: disabled &&
                    onAddTabClickHandler &&
                    kmplus_InsightCanEditDashboard ? (_jsx(Button, { color: "secondary", leftIcon: _jsx(Svg, { size: "medium", icon: "plus" }), fullWidth: true, onClick: onAddTabClickHandler, children: _jsx(FormattedMessage, { id: "Insight.tabulation.addTab" }) })) : (_jsxs(_Fragment, { children: [_jsx(Button, { color: "secondary", leftIcon: _jsx(Svg, { size: "medium", icon: "plus" }), fullWidth: true, onClick: onAddWidgetClickHandler, children: _jsx(FormattedMessage, { id: "Insight.tabulation.addWidget" }) }), _jsx(Button, { variant: "outline", color: "primary", leftIcon: _jsx(Svg, { size: "medium", icon: "plus" }), fullWidth: true, onClick: onCreateWidgetClickHandler, children: _jsx(FormattedMessage, { id: "Insight.tabulation.createWidget" }) })] })) }))] }));
};
EmptyPanelPlaceholder.defaultProps = defaultProps;
EmptyPanelPlaceholder.propTypes = propTypes;
export default EmptyPanelPlaceholder;
