var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { useMediaQuery } from '@mui/material';
import moment from 'moment';
import useStyledTheme from '../../hooks/useStyledTheme';
import { IoMdClose, IoMdMenu } from 'react-icons/io';
import Button from '@commons/Button';
import { logOut } from '@services/AuthUtils';
import UserStore from '@stores/UserStore';
import LoginActionCreators from '@actions/LoginActionCreators';
import PermissionStore from '@stores/PermissionStore';
import OnclusiveLogo from '../../../Content/public/svg/Onclusive_Symbol.svg';
import TimeZoneIcon from './TimeZoneIcon';
import NavbarScreen from './NavbarScreen';
import NavbarMobile from './NavbarMobile';
import { Wrapper } from './Navbar.styles';
var GlobalStyles = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    @media ( max-width: 768px){\n      .container-wrapper {\n        position: fixed !important;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n        height: 100%;\n        width: 100%;\n        .slide-routes .item  {\n          height: 100%;\n          /* overflow: hidden; */\n        }\n      }\n\n  }\n"], ["\n    @media ( max-width: 768px){\n      .container-wrapper {\n        position: fixed !important;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n        height: 100%;\n        width: 100%;\n        .slide-routes .item  {\n          height: 100%;\n          /* overflow: hidden; */\n        }\n      }\n\n  }\n"])));
var Navbar = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var isNotAgencyPage = location.pathname !== '/agencies';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    var breakPoints = useStyledTheme().breakPoints;
    var matchesMaxSm = useMediaQuery("(max-width: ".concat(breakPoints.sm, "px)"));
    var currentPath = location.pathname.replace('/', '');
    var isErrorPage = useMatch('/authorization') || useMatch('/unexpected');
    var isPublic = useMatch('/Public');
    var _a = useState(null), currentUser = _a[0], setCurrentUser = _a[1];
    var _b = useState(''), accountLogo = _b[0], setAccountLogo = _b[1];
    var _c = useState(false), openMenu = _c[0], setOpenMenu = _c[1];
    var _d = useState([]), servicesModules = _d[0], setservicesModules = _d[1];
    var _e = useState(''), selectedTimeZone = _e[0], setSelectedTimeZone = _e[1];
    useEffect(function () {
        onGetCurrentUser();
        var onGetRoles = function () {
            var _a;
            var servicesModules = PermissionStore.servicesModules;
            setservicesModules(servicesModules);
            var selectedTz = UserStore.selectedTimeZone;
            if (selectedTz) {
                var timezone = (_a = moment
                    .tz(selectedTz)) === null || _a === void 0 ? void 0 : _a.format("Z [".concat(UserStore.selectedTimeZone, "]"));
                setSelectedTimeZone(timezone);
            }
        };
        PermissionStore.addChangeListener(onGetRoles);
        UserStore.addGetCurrentUserListener(onGetCurrentUser);
        return function () {
            PermissionStore.removeChangeListener(onGetRoles);
            UserStore.removeGetCurrentUserListener(onGetCurrentUser);
        };
    }, []);
    var onGetCurrentUser = function () {
        var user = UserStore.getCurrentUser();
        if (user && Object.keys(user).length > 0) {
            setCurrentUser(__assign({}, user));
            setAccountLogo(user.accountLogo || '');
        }
    };
    var handleLogOut = function () {
        logOut();
        LoginActionCreators.logOut();
    };
    var goTo = function (pathName) {
        (function () {
            if (currentPath !== pathName) {
                navigate(pathName, { replace: true });
            }
        })();
    };
    var handleClickOpenMenu = function () {
        setOpenMenu(!openMenu);
    };
    var handleCloseMenu = function () {
        setOpenMenu(false);
    };
    return (_jsxs(Wrapper, { "$isPublic": isPublic || isErrorPage, className: "navbar__container", children: [_jsx("div", { className: "logo", children: _jsx(OnclusiveLogo, {}) }), accountLogo && (_jsx("img", { className: "navbar__account-logo", src: accountLogo, alt: "account logo" })), _jsx(GlobalStyles, {}), matchesMaxSm && (_jsx(TimeZoneIcon, { selectedTimeZone: selectedTimeZone, windowWidth: 1000, isPublic: undefined })), !(isPublic || isErrorPage) && (_jsxs(Button, { onClick: handleClickOpenMenu, variant: "text", className: "navbar__menu-icon", size: "small", "aria-label": "navbar__menuIcon", children: [_jsx("span", { className: "hidden", children: openMenu ? 'open menu' : 'close menu' }), openMenu ? _jsx(IoMdClose, { size: "26" }) : _jsx(IoMdMenu, { size: "26" })] })), !(isPublic || isErrorPage) && (_jsx(NavbarMobile, { open: openMenu, currentPath: currentPath, currentUser: currentUser, isNotAgencyPage: isNotAgencyPage, onLogOut: handleLogOut, onClose: handleCloseMenu })), _jsx(NavbarScreen, { isNotAgencyPage: isNotAgencyPage, currentPath: currentPath, goTo: goTo, currentUser: currentUser, onLogOut: handleLogOut, servicesModules: servicesModules, selectedTimeZone: selectedTimeZone })] }));
};
export default Navbar;
var templateObject_1;
