var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function getLineLabelContent(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getLineLabelPosition(formatMessage) {
    return [
        {
            value: 'top',
            label: formatMessage({
                id: 'Global.above',
            }),
        },
        {
            value: 'bottom',
            label: formatMessage({
                id: 'Global.below',
            }),
        },
    ];
}
export function getLineLabelTooltip(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getLineLegendContent(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getLineLegendPosition(formatMessage) {
    return [
        {
            value: 'top',
            label: formatMessage({
                id: 'Global.top',
            }),
        },
        {
            value: 'bottom',
            label: formatMessage({
                id: 'Global.bottom',
            }),
        },
        {
            value: 'right',
            label: formatMessage({
                id: 'Global.right',
            }),
        },
    ];
}
export function getLineDisplayedAxis(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'xAxis',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.xAxis',
            }),
        },
        {
            value: 'yAxis',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.yAxis',
            }),
        },
        {
            value: 'all',
            label: formatMessage({
                id: 'Global.All',
            }),
        },
    ];
}
export function getLineXAxisOrientation(formatMessage) {
    return [
        {
            value: 'horizontal',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.horizontal',
            }),
        },
        {
            value: 'leftRight',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.leftRight',
            }),
        },
        {
            value: 'vertical',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.vertical',
            }),
        },
    ];
}
export function getDefaultLineWidgetSettings(props) {
    var widgetOptionsDictionary = props.widget.widgetOptionsDictionary;
    var getLegendDefaultSettings = function () {
        if (props.widget.widgetFamilyName === 'Simple')
            return null;
        return {
            legendContent: widgetOptionsDictionary && widgetOptionsDictionary.legendContent
                ? widgetOptionsDictionary.legendContent
                : 'value',
            legendPosition: widgetOptionsDictionary && widgetOptionsDictionary.legendPosition
                ? widgetOptionsDictionary.legendPosition
                : 'bottom',
        };
    };
    return __assign(__assign({ labelContent: widgetOptionsDictionary && widgetOptionsDictionary.labelContent
            ? widgetOptionsDictionary.labelContent
            : 'none', labelPosition: widgetOptionsDictionary && widgetOptionsDictionary.labelPosition
            ? widgetOptionsDictionary.labelPosition
            : 'top', labelTooltip: widgetOptionsDictionary && widgetOptionsDictionary.labelTooltip
            ? widgetOptionsDictionary.labelTooltip
            : 'value' }, getLegendDefaultSettings(props)), { displayedAxis: widgetOptionsDictionary && widgetOptionsDictionary.displayedAxis
            ? widgetOptionsDictionary.displayedAxis
            : 'all', xAxisOrientation: widgetOptionsDictionary && widgetOptionsDictionary.xAxisOrientation
            ? widgetOptionsDictionary.xAxisOrientation
            : 'leftRight' });
}
