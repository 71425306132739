import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
import parameters from '@constants/parameters';
var TIMEOUT_BEFORE_FILTER_UPDATE = parameters.TIMEOUT_BEFORE_FILTER_UPDATE;
var updateFilterInterval;
var SearchActionCreators = {
    updateFilter: function (model, callback) {
        clearTimeout(updateFilterInterval);
        Dispatcher.handleViewAction({
            actionType: ActionTypes.UPDATE_FILTER_REQUEST,
        });
        updateFilterInterval = setTimeout(function () {
            WebApi.updateFilter(model, callback);
        }, TIMEOUT_BEFORE_FILTER_UPDATE);
    },
    getFavouriteSearches: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_FAVOURITE_SEARCHES_REQUEST,
        });
        WebApi.getFavouriteSearches();
    },
    createFavoriteSearch: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.CREATE_FAVOURITE_SEARCH_REQUEST,
        });
        WebApi.createFavoriteSearch(model);
    },
    deleteFavoriteSearch: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.DELETE_FAVORITE_SEARCH_REQUEST,
        });
        WebApi.deleteFavoriteSearch(model);
    },
    updateFavoriteSearch: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.UPDATE_FAVORITE_SEARCH_REQUEST,
        });
        WebApi.updateFavoriteSearch(model, callback);
    },
};
export default SearchActionCreators;
