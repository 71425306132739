import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'commons/Button';
import Svg from 'commons/Svg';
var ActionElement = function (props) {
    var label = props.label, className = props.className, handleOnClick = props.handleOnClick, title = props.title, id = props.id, disabled = props.disabled;
    var buttonType = (['removeGroup', 'removeRule', 'remove'].includes(id) && 'danger') ||
        'success';
    var component = null;
    switch (id) {
        case 'remove':
            component = (_jsx("div", { className: "rule__delete", role: "button", tabIndex: 0, onClick: function (e) { return handleOnClick(e); }, onKeyUp: function (e) { return handleOnClick(e); }, "aria-label": "rule-delete", title: title, children: _jsx(Svg, { icon: "trash", size: "x-large" }) }));
            break;
        default:
            component = (_jsx(Button, { type: buttonType, title: title, className: className, color: "secondary", onClick: function (e) { return handleOnClick(e); }, disabled: disabled, size: "small", children: label }));
    }
    return component;
};
ActionElement.displayName = 'ActionElement';
ActionElement.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    handleOnClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};
export default ActionElement;
