var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@commons/Button';
import { BsArrowUpCircle, BsArrowDownCircle } from 'react-icons/bs';
var DualListBoxControlsSort = function (_a) {
    var selectedItems = _a.selectedItems, displayedItems = _a.displayedItems, onSortItem = _a.onSortItem, searchTerm = _a.searchTerm;
    var isSortButtonDisabled = function (direction) {
        var foundInStart = false;
        var result = false;
        var hasSelections = false;
        if (searchTerm !== '')
            return true;
        var onSort = function (items) {
            items.forEach(function (item, index) {
                if (selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.includes(item.id)) {
                    if (selectedItems.length === 1) {
                        if (index === 0)
                            result = true;
                    }
                    else {
                        selectedItems.forEach(function (element) {
                            var foundItemIndex = items.findIndex(function (newItem) { return element === newItem.id; });
                            if (foundInStart && foundItemIndex < selectedItems.length) {
                                result = true;
                            }
                            else {
                                result = false;
                            }
                            if (foundItemIndex === 0 && index === 0) {
                                foundInStart = true;
                            }
                        });
                    }
                }
            });
            return result;
        };
        var items = __spreadArray([], displayedItems, true);
        items.forEach(function (item) {
            if (selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.includes(item.id))
                hasSelections = true;
        });
        if (hasSelections) {
            if (displayedItems &&
                displayedItems.length > 0 &&
                selectedItems &&
                selectedItems.length > 0) {
                if (direction === 'up') {
                    result = onSort(items);
                }
                else {
                    items = items.reverse();
                    result = onSort(items);
                }
            }
        }
        else {
            result = true;
        }
        return result;
    };
    var handleDownClick = function () {
        if (typeof onSortItem === 'function')
            onSortItem('down');
    };
    var handleUpClick = function () {
        if (typeof onSortItem === 'function')
            onSortItem('up');
    };
    return (_jsx("div", { className: "c-dualListBoxControlsSort", children: _jsx("div", { className: "dualListBoxControls__controls", children: _jsxs("div", { className: "dualListBoxControls__button__button-group button-group", children: [_jsx(Button, { variant: "text", className: "dualListBoxControls__button", disabled: isSortButtonDisabled('up'), onClick: handleUpClick, title: "Move up", children: _jsx(BsArrowUpCircle, { size: "1.5em" }) }), _jsx(Button, { variant: "text", className: "dualListBoxControls__button", disabled: isSortButtonDisabled('down'), onClick: handleDownClick, title: "Move down", children: _jsx(BsArrowDownCircle, { size: "1.5em" }) })] }) }) }));
};
export default DualListBoxControlsSort;
