import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, Fragment, useRef, } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { GoClock } from 'react-icons/go';
import 'react-day-picker/lib/style.css';
import DropDownSelect from '@commons/DropDownSelect';
import Radio from '@commons/Radio';
import Svg from '@commons/Svg/Svg';
import SearchStore from '@stores/SearchStore';
import { logicalDateTypes } from '@services/DateUtils';
import DateSelectorItem from './DateSelectorItemSearch';
import { Wrapper, ModalCalendarStyled } from './DateSelectorSearch.styles';
var dateTypes = {
    delivery: 2,
    publication: 1,
};
var reversedDateTypes = {
    1: 'publication',
    2: 'delivery',
};
function DateSelectorSearch(_a) {
    var updateFilter = _a.updateFilter, onChange = _a.onChange, _b = _a.mode, mode = _b === void 0 ? 'sidebar' : _b, lastPeriod = _a.lastPeriod, _c = _a.className, className = _c === void 0 ? '' : _c;
    var formatMessage = useIntl().formatMessage;
    var modalCalendarRef = useRef(null);
    var _d = useState('today'), selectedOptionId = _d[0], setSelectedOptionId = _d[1];
    var _e = useState(''), selectedOption = _e[0], setSelectedOption = _e[1];
    var _f = useState(reversedDateTypes[lastPeriod.dateType] || 'delivery'), selectedDateType = _f[0], setSelectedDateType = _f[1];
    var _g = useState(false), expandedDropDown = _g[0], setExpandedDropDown = _g[1];
    useEffect(function () {
        SearchStore.setDateType(parseInt(lastPeriod.dateType, 10) || 2);
        if (mode !== 'kiosk-topBar') {
            var logicalDate = SearchStore.getLogicalDate(true);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setSelectedOptionId(logicalDateTypes[logicalDate]);
        }
        SearchStore.addChangeListener(onSearchObjectUpdate);
        SearchStore.addSearchObjectUpdateListener(onSearchObjectUpdate);
        return function () {
            SearchStore.removeChangeListener(onSearchObjectUpdate);
            SearchStore.removeSearchObjectUpdateListener(onSearchObjectUpdate);
        };
    }, []);
    var onSearchObjectUpdate = function () {
        var logicalDate = SearchStore.getLogicalDate();
        var dateType = SearchStore.getDateType();
        var dates = SearchStore.getSearchDates();
        var from = moment(dates.dateFrom).format('DD/MM/YYYY');
        var to = moment(dates.dateTo).format('DD/MM/YYYY');
        var translation = '';
        if (logicalDate === 'custom' || logicalDate === '6') {
            translation = "".concat(from, " \u27F7 ").concat(to);
        }
        else {
            translation = window.Messages["SearchBar.dateSelector.".concat(logicalDate)];
        }
        setSelectedOption(translation);
        setSelectedDateType(reversedDateTypes[dateType] || '');
    };
    var handleOnClick = function () {
        setExpandedDropDown(!expandedDropDown);
    };
    var handleItemClick = function (selectedOpt, dates) {
        if (!selectedOpt || !dates || typeof onChange !== 'function') {
            return;
        }
        var translation = null;
        if (selectedOpt === 'custom') {
            if (!dates.universalDateFrom || !dates.universalDateTo) {
                translation = formatMessage({
                    id: "SearchBar.dateSelector.".concat(selectedOpt),
                });
            }
            else {
                translation = "\n          ".concat(moment(dates.dateFrom).format('DD/MM/YYYY'), " \u27F7\n          ").concat(moment(dates.dateTo).format('DD/MM/YYYY'), "\n        ");
            }
        }
        else {
            translation = formatMessage({
                id: "SearchBar.dateSelector.".concat(selectedOpt),
            });
        }
        if (selectedOpt === 'custom') {
            if (modalCalendarRef.current) {
                modalCalendarRef.current.handleOpenModal();
            }
        }
        onChange(selectedOpt, dates);
        setSelectedOptionId(selectedOpt);
        setSelectedOption(translation);
        setExpandedDropDown(false);
    };
    var handleDateTypeChange = function (event) {
        var value = event.target.value;
        if (value === 'publication' || value === 'delivery') {
            SearchStore.setDateType(dateTypes[value]);
            if (typeof updateFilter === 'function')
                updateFilter();
            setSelectedDateType(value);
            setExpandedDropDown(false);
        }
    };
    var renderDropDownTitle = function () { return (_jsxs("div", { title: selectedDateType === 'delivery'
            ? formatMessage({ id: 'SearchBar.dateSelector.delivery' })
            : formatMessage({ id: 'SearchBar.dateSelector.publication' }), children: [mode === 'sidebar' &&
                (selectedDateType === 'delivery' ? (_jsx(GoClock, { size: "16px", style: { strokeWidth: 0.5 } })) : (_jsx(Svg, { icon: "publication_date", size: "medium" }))), _jsx("span", { className: "date-text", children: (selectedOption && selectedOption) || window.Messages['Global.today'] })] })); };
    var renderOptions = function () { return (_jsxs(Fragment, { children: [mode !== 'kiosk-topBar' && (_jsxs("div", { className: "date-selector_datetype", children: [_jsx(Radio, { checked: selectedDateType === 'delivery', name: "dateType", id: "datetype-delivery", value: "delivery", onChange: handleDateTypeChange, children: _jsx("span", { children: formatMessage({ id: 'SearchBar.dateSelector.delivery' }) }) }), _jsx(Radio, { checked: selectedDateType === 'publication', name: "dateType", id: "datetype-publication", value: "publication", onChange: handleDateTypeChange, children: _jsx("span", { children: formatMessage({ id: 'SearchBar.dateSelector.publication' }) }) })] })), _jsx("div", { className: "separator" }), _jsxs("ul", { className: "date-selector_list", role: "listBox", children: [_jsx(DateSelectorItem, { label: window.Messages['Global.today'], mode: "today", selected: selectedOptionId === 'today', onClick: handleItemClick, icon: null }), _jsx(DateSelectorItem, { label: window.Messages['SearchBar.dateSelector.yesterday'], mode: "yesterday", selected: selectedOptionId === 'yesterday', onClick: handleItemClick, icon: null }), _jsx(DateSelectorItem, { label: window.Messages['SearchBar.dateSelector.lastweek'], mode: "lastweek", selected: selectedOptionId === 'lastweek', onClick: handleItemClick, icon: null }), _jsx(DateSelectorItem, { label: window.Messages['SearchBar.dateSelector.lastmonth'], mode: "lastmonth", selected: selectedOptionId === 'lastmonth', onClick: handleItemClick, icon: null }), _jsx(DateSelectorItem, { label: window.Messages['SearchBar.dateSelector.lastquarter'], mode: "lastquarter", selected: selectedOptionId === 'lastquarter', onClick: handleItemClick, icon: null }), _jsx(DateSelectorItem, { label: formatMessage({ id: 'SearchBar.dateSelector.custom' }), mode: "custom", selected: selectedOptionId === 'custom', icon: "calendar", onClick: handleItemClick })] })] })); };
    return (_jsxs(Fragment, { children: [_jsx(ModalCalendarStyled, { ref: modalCalendarRef, onChange: handleItemClick, reset: onSearchObjectUpdate }), _jsx(Wrapper, { children: _jsx(DropDownSelect, { className: "c-date-selector ".concat(mode === 'kiosk-topBar'
                        ? "c-date-selector-review-kiosk kioskCreator-container ".concat(className)
                        : '', " "), title: renderDropDownTitle, onClick: handleOnClick, collapsed: expandedDropDown, smartDrop: ".container-wrapper", children: renderOptions() }) })] }));
}
export default DateSelectorSearch;
