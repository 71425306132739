var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var colSizes = {
    'col--1': '8.333333%',
    'col--2': '16.666667%',
    'col--3': '25%',
    'col--4': '33.333333%',
    'col--5': '41.666667%',
    'col--6': '50%',
    'col--7': '58.333333%',
    'col--8': '66.666667%',
    'col--9': '75%',
    'col--10': '83.333333%',
    'col--11': '91.666667%',
    'col--12': '100%',
};
export var Wrapper = styled('div')(function (_a) {
    var $xs = _a.$xs, $sm = _a.$sm, $md = _a.$md, $lg = _a.$lg, _b = _a.$xl, $xl = _b === void 0 ? 12 : _b, theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: inline-block;\n    position: relative;\n    min-height: 1px;\n    width: ", ";\n\n    @media (max-width: ", "px) {\n      width: 100%;\n    }\n    @media (max-width: ", "px) {\n      width: 100%;\n    }\n  "], ["\n    display: inline-block;\n    position: relative;\n    min-height: 1px;\n    width: ", ";\n\n    @media (max-width: ", "px) {\n      width: 100%;\n    }\n    @media (max-width: ", "px) {\n      width: 100%;\n    }\n  "])), $xs
        ? colSizes["col--".concat($xs)]
        : $md
            ? colSizes["col--".concat($md)]
            : $sm
                ? colSizes["col--".concat($sm)]
                : $lg
                    ? colSizes["col--".concat($lg)]
                    : // @ts-expect-error col--${xl} will be always a key of colSizes
                        colSizes["col--".concat($xl)], theme.breakPoints.sm, theme.breakPoints.xs);
});
var templateObject_1;
