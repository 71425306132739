/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import ClipStore from 'stores/ClipStore';
import ClipSelectionStore from 'stores/ClipSelectionStore';
import CodificationStore from 'stores/CodificationStore';
import CommonStore from 'stores/CommonStore';
import CopyrightStore from 'stores/CopyrightStore';
import CustomMetricsStore from 'stores/CustomMetricsStore';
import DashboardStore from 'stores/DashboardStore';
import DistributionAndProfileStore from 'stores/DistributionAndProfileStore';
import DraftStore from 'stores/DraftStore';
import EmailAlertsStores from 'stores/EmailAlertsStores';
import ExportStore from 'stores/ExportStore';
import FacetStore from 'stores/FacetStore';
import HierarchyStore from 'stores/HierarchyStore';
import KioskStore from 'stores/KioskStore';
import LoginStore from 'stores/LoginStore';
import PermissionStore from 'stores/PermissionStore';
import SearchStore from 'stores/SearchStore';
import UserStore from 'stores/UserStore';
import CommentStore from 'stores/CommentStore';
import HighlightStore from 'stores/HighlightStore';
import InfographyStore from 'stores/InfographyStore';
import NewWidgetStore from 'stores/NewWidgetStore';
import NewsDigestStore from 'stores/NewsDigestStore';
import ReviewStore from 'stores/ReviewStore';
import VisualizationStore from 'stores/VisualizationStore';
import WidgetStore from 'stores/WidgetStore';
import PushmailStore from 'stores/PushmailStore';
import PercolatorStore from 'stores/PercolatorStore';
import { initiateIntercom } from 'services/AppUtils';
/**
 * reset all the stores when loging out or changing account
 * @params {object} options - options
 * @params {boolean} options.isLogout - true in case of a logout
 */
export function resetStores(_a) {
    var _b = _a === void 0 ? { isLogout: false } : _a, isLogout = _b.isLogout;
    ClipStore.reset();
    ClipSelectionStore.reset();
    CodificationStore.reset();
    CommonStore.reset();
    CopyrightStore.reset();
    DashboardStore.reset();
    DistributionAndProfileStore.reset();
    DraftStore.reset();
    EmailAlertsStores.reset();
    ExportStore.reset();
    FacetStore.reset();
    KioskStore.reset();
    HierarchyStore.reset();
    LoginStore.reset();
    PermissionStore.reset();
    SearchStore.reset();
    CommentStore.reset();
    CustomMetricsStore.reset();
    HighlightStore.reset();
    InfographyStore.reset();
    NewWidgetStore.reset();
    NewsDigestStore.reset();
    ReviewStore.reset();
    VisualizationStore.reset();
    WidgetStore.reset();
    UserStore.resetOnSwitchAccount();
    PushmailStore.reset();
    PercolatorStore.reset();
    if (isLogout) {
        UserStore.reset();
    }
}
export function logOut(_a) {
    var _b = _a === void 0 ? { isInactive: false } : _a, isInactive = _b.isInactive;
    ClipStore.initializeState();
    localStorage.removeItem('SESSION');
    localStorage.removeItem('UserSession');
    localStorage.removeItem('fullArticleView');
    localStorage.removeItem('searchModel');
    localStorage.removeItem('isFilterOpen');
    localStorage.removeItem('chechHierarchy');
    localStorage.removeItem('primary-background-color');
    localStorage.removeItem('primary-text-color');
    localStorage.removeItem('secondary-background-color');
    localStorage.removeItem('secondary-text-color');
    localStorage.removeItem('userLogin');
    localStorage.removeItem('checkSession');
    localStorage.removeItem('sessionTime');
    localStorage.removeItem('primary-background-color');
    localStorage.removeItem('primary-text-color');
    localStorage.removeItem('secondary-background-color');
    localStorage.removeItem('secondary-text-color');
    resetStores({ isLogout: true });
    // Logout from Intercom and Reinitiate
    window.Intercom('shutdown');
    initiateIntercom();
    if (isInactive) {
        window.location.replace('/login?inactive=true');
    }
}
