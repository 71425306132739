export function getDefaultPictogramWidgetSettings(props) {
    var widgetOptionsDictionary = props.widget.widgetOptionsDictionary;
    return {
        labelTooltip: widgetOptionsDictionary && widgetOptionsDictionary.labelTooltip
            ? widgetOptionsDictionary.labelTooltip
            : 'value',
        legendContent: widgetOptionsDictionary && widgetOptionsDictionary.legendContent
            ? widgetOptionsDictionary.legendContent
            : 'value',
        legendPosition: widgetOptionsDictionary && widgetOptionsDictionary.legendPosition
            ? widgetOptionsDictionary.legendPosition
            : 'bottom',
    };
}
export function getPictogramBrickLegendPosition(formatMessage) {
    return [
        {
            value: 'top',
            label: formatMessage({
                id: 'Global.top',
            }),
        },
        {
            value: 'bottom',
            label: formatMessage({
                id: 'Global.bottom',
            }),
        },
    ];
}
export function getPictogramBrickLabelTooltip(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
        {
            value: 'percentage',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.percentage',
            }),
        },
        {
            value: 'valuePercentage',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.valuePercentage',
            }),
        },
    ];
}
export function getPictogramBrickLegendContent(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
