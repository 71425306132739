var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var variants = {
    middle: '80%',
    fullWidth: '100%',
};
var margins = {
    small: '5px auto',
    medium: '8px auto',
    large: '16px auto',
};
var HrStyled = styled('hr')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var palette = _a.theme.palette, variant = _a.variant, _b = _a.margin, margin = _b === void 0 ? 'medium' : _b;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 1px;\n    min-height: 1px;\n    max-height: 1px;\n    width: ", ";\n    border: none;\n    background-color: ", ";\n    margin: ", ";\n  "], ["\n    height: 1px;\n    min-height: 1px;\n    max-height: 1px;\n    width: ", ";\n    border: none;\n    background-color: ", ";\n    margin: ", ";\n  "])), variant ? variants[variant] : '100%', palette.common.border1, margins[margin]);
});
export { HrStyled };
var templateObject_1, templateObject_2;
