import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { FaRegFilePdf } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Typography from 'commons/Typography';
import Button from 'commons/Button';
import Box from 'commons/Box';
import Checkbox from 'commons/Checkbox';
import ReactSelect from 'commons/ReactSelect';
import LinearProgress from 'commons/LinearProgress';
import Link from 'commons/Link';
import FormElement from 'commons/FormElement';
import Spinner from 'commons/Spinner';
import LoginActionCreators from 'actions/LoginActionCreators';
import CommonActionCreators from 'actions/CommonActionCreators';
import ExportActionCreators from 'actions/ExportActionCreators';
import CommonStore from 'stores/CommonStore';
import searchStore from 'stores/SearchStore';
import PermissionStore from 'stores/PermissionStore';
import ClipStore from 'stores/ClipStore';
import { getUrlParameter, dowloadZip } from 'services/AppUtils';
import { postFetch } from '@services/AppUtils';
import { SnackbarContext } from 'context/SnackbarContext';
import { getLocalTimezone } from 'services/DateUtils';
import UserStore from 'stores/UserStore';
import moment from 'moment-timezone';
import { groupOrderPdf, downloadFile } from './ExportPdf.utils';
import { Wrapper } from './ExportPdf.styles';
var ExportPdfPropTypes = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    hierarchy: PropTypes.object,
    selectedClips: PropTypes.array,
    collectDataPdf: PropTypes.node,
    isDirectDownloadPdf: PropTypes.bool,
    isFullNLA: PropTypes.bool,
    getMonitoringListInfo: PropTypes.func,
};
var ExportPdfDefaultProps = {
    open: false,
    hierarchy: null,
    selectedClips: null,
    mode: 'pdf',
    collectDataPdf: {},
    isDirectDownloadPdf: null,
    getMonitoringListInfo: null,
    isFullNLA: false,
};
function ExportPdf(_a) {
    var open = _a.open, hierarchy = _a.hierarchy, selectedClips = _a.selectedClips, mode = _a.mode, isDirectDownloadPdf = _a.isDirectDownloadPdf, collectDataPdf = _a.collectDataPdf, getMonitoringListInfo = _a.getMonitoringListInfo, isFullNLA = _a.isFullNLA;
    var formatMessage = useIntl().formatMessage;
    var pdfSettingsRef = useRef(null);
    var documentIdRef = useRef('');
    var isAvailableCoverPage = useMemo(function () { return PermissionStore.getSavedSettings('isAvailableCoverPage'); }, []);
    var isAvailableFullPage = useMemo(function () { return PermissionStore.getSavedSettings('isAvailableFullPage'); }, []);
    var _b = useState(false), isFullPages = _b[0], setIsFullPages = _b[1];
    var _c = useState(false), isCoverPages = _c[0], setIsCoverPages = _c[1];
    var _d = useState(true), isLoadingPdfList = _d[0], setIsLoadingPdfList = _d[1];
    var _e = useState((hierarchy === null || hierarchy === void 0 ? void 0 : hierarchy.pdfTemplateId) || ''), pdfTemplate = _e[0], setPdfTemplate = _e[1];
    var _f = useState([]), pdfTemplateList = _f[0], setPdfTemplateList = _f[1];
    var _g = useState(0), progress = _g[0], setProgress = _g[1];
    var _h = useState(''), description = _h[0], setDescription = _h[1];
    var _j = useState(false), isCanceled = _j[0], setIsCanceled = _j[1];
    var _k = useState(''), linkPdf = _k[0], setLinkPdf = _k[1];
    var _l = useState(false), loading = _l[0], setLoading = _l[1];
    var _m = useState(true), disableSave = _m[0], setDisableSave = _m[1];
    var _o = useState(false), error = _o[0], setError = _o[1];
    var _p = useState(''), srcIframe = _p[0], setSrcIframe = _p[1];
    var navigate = useNavigate();
    var setSnackbarObj = useContext(SnackbarContext).setSnackbarObj;
    useEffect(function () {
        if (pdfTemplateList.length === 1)
            setPdfTemplateList(pdfTemplateList[0]);
    }, [pdfTemplateList]);
    // test if there is any change on the settings
    useEffect(function () {
        if (!isDirectDownloadPdf) {
            if (pdfSettingsRef.current) {
                var _a = pdfSettingsRef.current, isFullPagesRef = _a.isFullPages, isCoverPagesRef = _a.isCoverPages, pdfTemplateRef = _a.pdfTemplate;
                if (isFullPages !== isFullPagesRef ||
                    isCoverPages !== isCoverPagesRef ||
                    pdfTemplate !== pdfTemplateRef) {
                    pdfSettingsRef.current = { isFullPages: isFullPages, isCoverPages: isCoverPages, pdfTemplate: pdfTemplate };
                    setDisableSave(false);
                }
                else {
                    setDisableSave(true);
                }
            }
        }
    }, [isFullPages, isCoverPages, pdfTemplate]);
    // eslint-disable-next-line
    useEffect(function () {
        if (!isDirectDownloadPdf) {
            pdfSettingsRef.current = { isFullPages: isFullPages, isCoverPages: isCoverPages, pdfTemplate: pdfTemplate };
            var handleSetPdfTemplates_1 = function () {
                var pdfTemplatesListe = CommonStore.getPdfTemplates();
                setIsLoadingPdfList(false);
                setPdfTemplateList(pdfTemplatesListe);
                if (pdfTemplatesListe.length <= 1 &&
                    !isAvailableCoverPage &&
                    !isAvailableFullPage) {
                    handleGeneratePdf();
                }
            };
            setIsLoadingPdfList(true);
            CommonActionCreators.getPdfTemplates();
            CommonStore.addChangeListener(handleSetPdfTemplates_1);
            return function () {
                CommonStore.removeChangeListener(handleSetPdfTemplates_1);
                if (documentIdRef.current) {
                    ExportActionCreators.cancelPdfGeneration({
                        id: documentIdRef.current,
                    });
                }
            };
        }
        setIsLoadingPdfList(false);
        handleGenerateDirectDownloadPdf();
    }, []);
    // UseEffect is need cause a Request an GetEditingRequestState can return  after the CancelRequest
    useEffect(function () {
        if (isCanceled) {
            setProgress(0);
            setDescription('');
            setLoading(false);
        }
    }, [isCanceled, progress, description]);
    var searchModel = useMemo(function () { return searchStore.searchModel; }, []);
    var clipsData = useMemo(function () {
        return (hierarchy === null || hierarchy === void 0 ? void 0 : hierarchy.origin) !== 'kiosk' && selectedClips && selectedClips.length > 0
            ? selectedClips.map(function (clipId) {
                var _a = ClipStore.getClipById(clipId), id = _a.id, indexName = _a.indexName;
                return {
                    id: id,
                    indexName: indexName,
                };
            })
            : (hierarchy === null || hierarchy === void 0 ? void 0 : hierarchy.origin) === 'kiosk'
                ? selectedClips
                : null;
    }, []);
    // 00_Export ExportPage.savePdfTemplate replacement
    var handleSavePdfTemplateSettings = function (callback) {
        var model = {
            hash: hierarchy ? hierarchy.hash : null,
            pdfTemplateId: pdfTemplateList.length > 1 ? pdfTemplate : pdfTemplateList[0],
            isCoverPages: isCoverPages,
            isFullPages: isFullPages,
        };
        ExportActionCreators.updateReviewPdfTemplate(model, function () {
            setDisableSave(true);
            if (callback)
                callback();
        });
    };
    var handleGenerateDashboardPdf = function () {
        var model = JSON.parse(localStorage.getItem('dashboardModel'));
        setLoading(true);
        dowloadZip('/Insights/ExportDashboard', model, function () {
            setLoading(false);
            localStorage.removeItem('dashboardModel');
        });
    };
    var handleSkipNLA = function () {
        postFetch('/api/pdf/UserConfirmation', {
            pdfRequestId: documentIdRef.current,
            userConfirmation: true,
        });
    };
    var handleGeneratePdf = function () {
        setIsCanceled(false);
        setLinkPdf('');
        var _a = groupOrderPdf(collectDataPdf), clipGroups = _a.clipGroups, groupsOrder = _a.groupsOrder;
        // if (pdfTemplateList.length > 1 && pdfTemplate === '') setError(true);
        window.localStorage.setItem('PdfGenerationState', '1');
        var createPDFModel = {
            clips: clipsData || (hierarchy === null || hierarchy === void 0 ? void 0 : hierarchy.clips),
            clipSelections: hierarchy ? hierarchy.clipSelections : null,
            localTimezone: getLocalTimezone(),
            timezone: UserStore.selectedTimeZone || moment.tz.guess(),
            pdfTemplateId: pdfTemplate,
            clipGroups: clipGroups,
            groupsOrder: groupsOrder,
            mode: mode === 'newsDigest' ? 2 : null,
            articlesTypeListSelected: selectedClips
                ? [
                    {
                        key: 'pdf',
                        value: !isFullPages,
                    },
                    {
                        key: 'fullpage',
                        value: isFullPages,
                    },
                    {
                        key: 'coverpage',
                        value: isCoverPages,
                    },
                ]
                : null,
            // isCoverPages,
            // isFullPages,
            searchModel: searchModel,
            reviewName: hierarchy ? hierarchy.reviewName : null,
            hash: hierarchy ? hierarchy.hash : null,
            globalPdf: 1,
        };
        // If articles in monitoring are grouped by
        var informationsFromArticleList = getMonitoringListInfo === null || getMonitoringListInfo === void 0 ? void 0 : getMonitoringListInfo();
        if ((informationsFromArticleList === null || informationsFromArticleList === void 0 ? void 0 : informationsFromArticleList.type) === 2) {
            var groupsOrderArr_1 = [];
            var buildGroupingModelToCreatePdf_1 = function (groups) {
                return [].concat(groups.reduce(function (previous, current) {
                    groupsOrderArr_1.push(current.name);
                    return previous.concat([
                        {
                            clipIds: informationsFromArticleList.association[current.id].filter(function (clipId) {
                                return informationsFromArticleList.selectedDisplayedClipIds.indexOf(clipId) !== -1;
                            }),
                            name: current.name,
                            subGroups: current.groups
                                ? buildGroupingModelToCreatePdf_1(current.groups)
                                : [],
                        },
                    ]);
                }, []));
            };
            createPDFModel.clipGroups = buildGroupingModelToCreatePdf_1(informationsFromArticleList.groupingModel);
            createPDFModel.groupsOrder = groupsOrderArr_1;
        }
        setLoading(true);
        setDescription(formatMessage({ id: 'Export.queued' }));
        var handleCallback = function (res) {
            // if it rquires authentication
            if (res.ContentLinkBatchUri !== null) {
                setSrcIframe(res.ContentLinkBatchUri);
            }
            documentIdRef.current = res.documentId || res.DocumentId;
            downloadFile(res, setProgress, setLinkPdf, setLoading, setSrcIframe, setDescription, (hierarchy === null || hierarchy === void 0 ? void 0 : hierarchy.hash) ? hierarchy === null || hierarchy === void 0 ? void 0 : hierarchy.hash : res.model.hash, setSnackbarObj, formatMessage({ id: 'Global.genericError' }), (hierarchy === null || hierarchy === void 0 ? void 0 : hierarchy.globalPdf) ? hierarchy === null || hierarchy === void 0 ? void 0 : hierarchy.globalPdf : res.model.globalPdf);
        };
        if (!isDirectDownloadPdf) {
            // savePdfTemplate if there are changes
            if (mode === 'newsDigest') {
                ExportActionCreators.createGlobalNewsDigestPDF(createPDFModel, handleCallback);
                return;
            }
            if (!disableSave) {
                handleSavePdfTemplateSettings(function () {
                    setDisableSave(true);
                    ExportActionCreators[mode === 'review' ? 'createReviewPDF' : 'createPDF'](createPDFModel, mode === 'review' ? handleCallbackDirectDownloadPDF : handleCallback);
                });
            }
            else {
                ExportActionCreators[mode === 'review' ? 'createReviewPDF' : 'createPDF'](createPDFModel, mode === 'review' ? handleCallbackDirectDownloadPDF : handleCallback);
            }
        }
    };
    function toCamel(o) {
        var newKey;
        var value;
        if (o instanceof Array) {
            return o.map(function (item) {
                if (typeof item === 'object') {
                    item = toCamel(item);
                }
                return item;
            });
        }
        var newO = {};
        // eslint-disable-next-line
        for (var origKey in o) {
            if (Object.prototype.hasOwnProperty.call(o, origKey)) {
                newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString();
                value = o[origKey];
                if (value instanceof Array ||
                    (value !== null && value.constructor === Object)) {
                    value = toCamel(value);
                }
                newO[newKey] = value;
            }
        }
        return newO;
    }
    var handleError = function (res) {
        if (res && res.code === 400) {
            if (res.customCode === 404) {
                navigate('/authorization', {
                    Type: 404,
                });
                return true;
            }
            if (res.customCode === 552 ||
                res.customCode === 551 ||
                res.customCode === 550 ||
                res.customCode === 553 ||
                res.customCode === 554) {
                navigate('/authorization', {
                    Type: res.customCode,
                });
            }
            if (res.customCode === 300) {
                if (res.data.indexOf('clipNotAuthorized') > 0) {
                    navigate('/authorization', {
                        Type: 553,
                    });
                }
                else if (res.data.indexOf('clipNotAvailable') > 0) {
                    navigate('/authorization', {
                        Type: 554,
                    });
                }
            }
            if (res.customCode === 302) {
                var redirectParams = res.data;
                if (redirectParams.returnUrl) {
                    window.lastVisitedUrl = window.location.href.replace(window.location.origin, '');
                }
                if (redirectParams.redirectUrl === 'LogOff') {
                    LoginActionCreators.logOut();
                }
                else if (redirectParams.redirectUrl === 'Login') {
                    navigate("/login?returnUrl=".concat(window.location.href).concat(redirectParams.expiration ? '&expiration=true' : null));
                }
            }
            return true;
        }
        return false;
    };
    var handleCallbackDownloadPushmailPDF = function (res) {
        if (handleError(res)) {
            return;
        }
        if (res.code === 200) {
            if (res.customCode === 302 && res.data.pdfPath) {
                document.location = res.data.pdfPath;
            }
            else if (res.customCode === 201) {
                var createUnitPDFModel = {
                    id: res.data.idClip,
                    indexName: res.data.indexName,
                    localTimezone: getLocalTimezone(),
                    timezone: UserStore.selectedTimeZone || moment.tz.guess(),
                };
                ExportActionCreators.createUnitPDF(createUnitPDFModel, handleCallbackDirectDownloadPDF);
            }
        }
    };
    var handleCallbackDirectDownloadPDF = function (res) {
        var _a;
        var downloadPdf = function (url) {
            document.location = url;
        };
        documentIdRef.current = (_a = res.data) === null || _a === void 0 ? void 0 : _a.documentId;
        if (handleError(res)) {
            return;
        }
        // if it rquires authentication
        if (res.data.contentLinkBatchUri !== null) {
            setSrcIframe(res.data.contentLinkBatchUri);
        }
        var isDownload = getUrlParameter('id') ||
            getUrlParameter('indexName') ||
            getUrlParameter('hash') ||
            window.location.pathname.toLowerCase().indexOf('public/globalpdf') > 0 ||
            window.location.pathname.toLowerCase().indexOf('public/accessreviewpdf') >
                0 ||
            window.location.pathname.toLowerCase().indexOf('directpdfdownload') > 0 ||
            window.location.pathname.toLowerCase().indexOf('newsdigestglobalpdf') >
                0 ||
            window.location.pathname.toLowerCase().indexOf('getcategorypdf') > 0;
        downloadFile(toCamel(res.data), setProgress, isDownload ? downloadPdf : setLinkPdf, setLoading, setSrcIframe, setDescription, (hierarchy === null || hierarchy === void 0 ? void 0 : hierarchy.hash) ? hierarchy === null || hierarchy === void 0 ? void 0 : hierarchy.hash : res.model.hash, setSnackbarObj, formatMessage({ id: 'Global.genericError' }), (hierarchy === null || hierarchy === void 0 ? void 0 : hierarchy.globalPdf) || res.model.globalPdf || res.data.globalPdf);
    };
    var handleGenerateDirectDownloadPdf = function () {
        setLoading(true);
        setDescription(formatMessage({ id: 'Export.queued' }));
        window.localStorage.setItem('PdfGenerationState', '1');
        if (window.location.pathname.toLowerCase().indexOf('newsdigestglobalpdf') > 0) {
            var createPDFModel = {
                hash: decodeURIComponent(getUrlParameter('hash')),
                localTimezone: getLocalTimezone(), // window.km.getTimeZone(),
                timezone: UserStore.selectedTimeZone || moment.tz.guess(),
                globalPdf: getUrlParameter('globalPdf'),
            };
            ExportActionCreators.createGlobalNewsDigestPDF(createPDFModel, handleCallbackDirectDownloadPDF);
            return;
        }
        if (window.location.pathname.toLowerCase().indexOf('getcategorypdf') > 0) {
            ExportActionCreators.createGetCategoryNewsDigestPDF(null, handleCallbackDirectDownloadPDF);
            return;
        }
        var hash = getUrlParameter('hash');
        var pushmailJobId = getUrlParameter('pushmailJobId');
        if (getUrlParameter('id') && getUrlParameter('indexName')) {
            var createUnitPDFModel = {
                id: getUrlParameter('id'),
                indexName: getUrlParameter('indexName'),
                localTimezone: getLocalTimezone(),
                timezone: UserStore.selectedTimeZone || moment.tz.guess(),
            };
            ExportActionCreators.createUnitPDF(createUnitPDFModel, handleCallbackDirectDownloadPDF);
        }
        else if (hash || pushmailJobId) {
            var createReviewPDFModel = {
                localTimezone: getLocalTimezone(),
                timezone: UserStore.selectedTimeZone || moment.tz.guess(),
                globalPdf: getUrlParameter('globalPdf'),
            };
            if (pushmailJobId) {
                createReviewPDFModel.pushmailJobId = decodeURIComponent(pushmailJobId);
            }
            if (hash) {
                createReviewPDFModel.hash = decodeURIComponent(hash);
            }
            ExportActionCreators.createReviewPDF(createReviewPDFModel, handleCallbackDirectDownloadPDF);
        }
        else if (window.location.pathname.toLowerCase().indexOf('public/globalpdf') > 0) {
            ExportActionCreators.createGlobalPDF({
                localTimezone: getLocalTimezone(),
                timezone: UserStore.selectedTimeZone || moment.tz.guess(),
            }, handleCallbackDirectDownloadPDF);
        }
        else if (window.location.pathname.toLowerCase().indexOf('directpdfdownload') > 0) {
            ExportActionCreators.createUnitPushmailPDF({
                key: window.location.pathname.replace('/public/clip/DirectPDFDownload/', ''),
            }, handleCallbackDownloadPushmailPDF);
        }
        else if (window.location.pathname.toLowerCase().indexOf('public/clip/index') > 0) {
            ExportActionCreators.createUnitPushmailPDF({
                key: window.location.pathname.replace('/Public/Clip/Index/', ''),
                isClipIdIndex: true,
            }, handleCallbackDownloadPushmailPDF);
        }
    };
    var handleSelectPdf = function (template) {
        setPdfTemplate(template.id);
        if (error)
            setError(false);
    };
    var handleCancelPdfGeneration = function () {
        if (documentIdRef.current) {
            ExportActionCreators.cancelPdfGeneration({ id: documentIdRef.current }, function () {
                setIsCanceled(true);
                setSnackbarObj({
                    open: true,
                    status: 'info',
                    message: formatMessage({ id: 'ExportReview.pdf.cancelMessage' }),
                });
            });
            documentIdRef.current = null;
        }
    };
    var handleChangeCoverPage = function () {
        setIsCoverPages(function (prevState) { return !prevState; });
    };
    var handleChangeFullPage = function () {
        setIsFullPages(function (prevState) { return !prevState; });
    };
    return open ? (_jsx(Wrapper, { "$isNewdigest": mode === 'newsDigest', children: srcIframe &&
            description === 'PendingAuthorization' &&
            progress !== 100 ? (_jsxs("div", { children: [!isFullNLA ? (_jsx(Button, { onClick: handleSkipNLA, variant: "link", className: "content-link-skip", children: formatMessage({ id: 'ExportReview.pdf.skipNLAButton' }) })) : null, _jsx("iframe", { id: "content-link-iframe", title: "Content links iframe", height: "500", width: "100%", src: srcIframe, className: "review-export__iframe" })] })) : (_jsxs("div", { className: "review-export__pdf", children: [(mode === 'review' || mode === 'pdf') &&
                    (isLoadingPdfList ? (_jsx(Box, { flex: "1", children: _jsx(Spinner, {}) })) : (_jsxs("div", { children: [pdfTemplateList.length > 1 && (_jsxs(_Fragment, { children: [_jsx(Typography, { className: "review-export__method-title", variant: "h4", component: "h2", children: formatMessage({ id: 'ExportReview.pdf' }) }), _jsxs(FormElement, { showLabel: false, error: error
                                            ? formatMessage({ id: 'Global.requiredField' })
                                            : null, id: "pdf-template", children: [_jsx(ReactSelect, { id: "pdf-template", inputProps: { 'data-id': 'pdf-template' }, placeholder: formatMessage({
                                                    id: 'ExportReview.pdf.placeholder',
                                                }), getOptionLabel: function (item) { return item.name; }, getOptionValue: function (item) { return item.id; }, onChange: handleSelectPdf, options: pdfTemplateList, value: pdfTemplateList === null || pdfTemplateList === void 0 ? void 0 : pdfTemplateList.find(function (item) { return item.id === pdfTemplate; }) }), _jsx("p", { className: "pdf__message", children: formatMessage({ id: 'ExportReview.pdf.message' }) })] })] })), (isAvailableCoverPage || isAvailableFullPage) && (_jsxs(_Fragment, { children: [' ', _jsx(Typography, { className: "review-export__method-title", variant: "h4", component: "h2", children: formatMessage({ id: 'ExportReview.pdf.type' }) }), _jsxs("div", { className: "pdf__checkbox-container", children: [isAvailableCoverPage && (_jsx(Checkbox, { checked: isCoverPages, onChange: handleChangeCoverPage, children: _jsx(FormattedMessage, { id: "ExportReview.pdf.coverPages" }) })), isAvailableFullPage && (_jsx(Checkbox, { checked: isFullPages, onChange: handleChangeFullPage, children: _jsx(FormattedMessage, { id: "ExportReview.pdf.fullPages" }) }))] })] }))] }))), mode === 'newsDigest' && (_jsx(Typography, { className: "review-export__method-title", variant: "h4", component: "h2", children: formatMessage({ id: 'ExportReview.pdf.type' }) })), _jsxs("div", { className: "review-export__footer", children: [description && progress !== 100 && (_jsx(LinearProgress, { description: description, progress: progress, onCancel: handleCancelPdfGeneration })), linkPdf && (_jsx(Link, { className: "pdf__link", href: linkPdf, rel: "noopener noreferrer", target: "_blank", ariaLabel: "Open generated pdf", children: formatMessage({ id: 'ExportReview.pdf.openPdfLink' }) })), _jsx(Box, { display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "16px", flex: "1", children: (pdfTemplateList.length > 1 ||
                                isAvailableCoverPage ||
                                isAvailableFullPage ||
                                loading) && (_jsxs(_Fragment, { children: [mode !== 'newsDigest' && (_jsx(Button, { disabled: isLoadingPdfList || loading || disableSave || linkPdf, variant: "outline", color: "primary", onClick: handleSavePdfTemplateSettings, title: formatMessage({ id: 'Global.save' }), children: formatMessage({ id: 'Global.save' }) })), _jsx(Button, { loading: loading, disabled: isLoadingPdfList, 
                                        // className="pdf__generate-btn"
                                        variant: "fill", color: "secondary", 
                                        // disabled={pdfTemplate.length === 0}
                                        leftIcon: _jsx(FaRegFilePdf, {}), onClick: mode === 'customerSnapshotDashboard'
                                            ? handleGenerateDashboardPdf
                                            : handleGeneratePdf, title: formatMessage({
                                            id: 'ExportReview.pdf.generateButton',
                                        }), children: formatMessage({ id: 'ExportReview.pdf.generateButton' }) })] })) })] })] })) })) : isDirectDownloadPdf ? (_jsx(Wrapper, { children: _jsxs("div", { className: "export-create-unit-pdf", children: [_jsx(Typography, { className: "review-export__method-title", variant: "h4", component: "h2", children: formatMessage({ id: 'CreatePDF.pageTitle' }) }), progress !== 100 && (_jsxs("div", { children: [_jsx("p", { children: formatMessage({ id: 'CreatePDF.progressMessageText' }) }), _jsx(LinearProgress, { description: description, progress: progress })] })), srcIframe ? (_jsx("div", { children: _jsx("iframe", { id: "content-link-iframe", title: "Content links iframe", height: "500", width: "100%", src: srcIframe, className: "review-export__iframe" }) })) : null] }) })) : null;
}
ExportPdf.propTypes = ExportPdfPropTypes;
ExportPdf.defaultProps = ExportPdfDefaultProps;
export default ExportPdf;
