import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import ReactSelect from 'commons/ReactSelect';
import QueryBuilderStore from 'stores/QueryBuilderStore';
var propTypes = {
    field: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    ops: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        })),
        PropTypes.string,
        PropTypes.instanceOf(Date),
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    ]).isRequired,
    handleOnChange: PropTypes.func.isRequired,
};
function ValueEditor(_a) {
    var field = _a.field, ops = _a.ops, value = _a.value, handleOnChange = _a.handleOnChange, placeholder = _a.placeholder;
    var _b = useState([]), options = _b[0], setOptions = _b[1];
    useEffect(function () {
        var fieldName = (typeof field === 'string' && field) || field.value;
        var storeField = QueryBuilderStore.getFieldByName(fieldName);
        var fieldValues = storeField &&
            QueryBuilderStore.getValuesByFieldId(storeField.filterElasticFieldPath);
        var filterFieldValues = fieldValues === null || fieldValues === void 0 ? void 0 : fieldValues.filter(function (elm) { return elm.key && elm.value; });
        setOptions(filterFieldValues || []);
    }, [field, ops]);
    var fieldName = (typeof field === 'string' && field) || field.value;
    var uniqueId = uuid();
    var operator = (typeof ops === 'string' && ops) ||
        (typeof ops === 'object' && ops !== null && ops.value);
    if (operator === '1' || operator === '2') {
        return null;
    }
    if (['7', '8', '9', '10'].includes(operator)) {
        return (_jsx("div", { className: "c-query_builder-value_editor", children: _jsx("input", { type: "text", className: "c-input", value: typeof value === 'string' ? value : (value && value.value) || '', onChange: function (e) { return handleOnChange(e.target.value); } }) }));
    }
    var optionsArr = (Array.isArray(options) &&
        options.map(function (fld) { return ({
            label: fld.value,
            value: fieldName === 'source.name.untouched' ? fld.value : fld.key,
        }); })) ||
        [];
    return (_jsxs("div", { className: "c-query_builder-value_editor", children: [_jsx("label", { htmlFor: fieldName || uniqueId, className: "hidden", children: fieldName || uniqueId }), _jsx(ReactSelect, { id: fieldName || uniqueId, autoload: false, cache: false, isMulti: ['3', '4'].includes(operator), onChange: handleOnChange, options: optionsArr, placeholder: placeholder, value: value })] }));
}
ValueEditor.displayName = 'ValueEditor';
ValueEditor.propTypes = propTypes;
ValueEditor.defaultProps = {
    ops: null,
};
export default ValueEditor;
