var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getDefaultVerticalBarWidgetSettings } from 'adapters/OptionsAdapters/VerticalBarLayoutSettingsAdapter';
import { getDefaultHorizontalBarWidgetSettings } from 'adapters/OptionsAdapters/HorizontalBarLayoutSettingsAdapter';
import { getDefaultCloudWidgetSettings } from 'adapters/OptionsAdapters/CloudLayoutSettingsAdapter';
import { getDefaultPictogramWidgetSettings } from 'adapters/OptionsAdapters/PictogramBrickLayoutSettingsAdapter';
import { getDefaultPieWidgetSettings } from 'adapters/OptionsAdapters/PieLayoutSettingsAdapter';
import { getDefaultLineWidgetSettings } from 'adapters/OptionsAdapters/LineLayoutSettingsAdapter';
import { getDefaultGridWidgetSettings } from 'adapters/OptionsAdapters/GridLayoutSettingsAdapter';
import { getDefaultComparisonWidgetSettings } from 'adapters/OptionsAdapters/ComparisonLayoutSettingsAdapter';
import { getDefaultSortSettings } from 'adapters/OptionsAdapters/SortSettingsAdapter';
function getDefaultWidgetSettings(props) {
    var _a;
    var widgetSettings = {};
    if ((_a = props.widget) === null || _a === void 0 ? void 0 : _a.widgetTypeName) {
        switch (props.widget.widgetTypeName) {
            case 'Horizontal Bar':
                widgetSettings.width = 1;
                widgetSettings.height = 2;
                return __assign(__assign(__assign({}, widgetSettings), getDefaultHorizontalBarWidgetSettings(props)), getDefaultSortSettings(props));
            case 'Vertical Bar':
                widgetSettings.width = 2;
                widgetSettings.height = 1;
                return __assign(__assign(__assign({}, widgetSettings), getDefaultVerticalBarWidgetSettings(props)), getDefaultSortSettings(props));
            case 'Comparison':
                if (props.widget.widgetFamilyName === 'Horizontal') {
                    widgetSettings.width = 1;
                    widgetSettings.height = 2;
                }
                if (props.widget.widgetFamilyName === 'Vertical') {
                    widgetSettings.width = 2;
                    widgetSettings.height = 1;
                }
                return __assign(__assign(__assign({}, widgetSettings), getDefaultComparisonWidgetSettings(props)), getDefaultSortSettings(props));
            case 'Cloud':
                widgetSettings.width = 2;
                widgetSettings.height = 1;
                return __assign(__assign(__assign({}, widgetSettings), getDefaultCloudWidgetSettings(props)), getDefaultSortSettings(props));
            case 'KeyFigure':
                widgetSettings.width = 1;
                widgetSettings.height = 1;
                return __assign(__assign({}, widgetSettings), getDefaultSortSettings(props));
            case 'Pictogram':
                widgetSettings.width = 2;
                widgetSettings.height = 1;
                return __assign(__assign(__assign({}, widgetSettings), getDefaultPictogramWidgetSettings(props)), getDefaultSortSettings(props));
            case 'Pie':
                widgetSettings.width = 2;
                widgetSettings.height = 1;
                return __assign(__assign(__assign({}, widgetSettings), getDefaultPieWidgetSettings(props)), getDefaultSortSettings(props));
            case 'Line':
            case 'Area':
                widgetSettings.width = 2;
                widgetSettings.height = 1;
                return __assign(__assign(__assign({}, widgetSettings), getDefaultLineWidgetSettings(props)), getDefaultSortSettings(props));
            case 'Map':
                widgetSettings.width = 2;
                widgetSettings.height = 1;
                return __assign(__assign({}, widgetSettings), getDefaultSortSettings(props));
            case 'Grid':
                widgetSettings.width = 2;
                widgetSettings.height = 1;
                return __assign(__assign(__assign({}, widgetSettings), getDefaultGridWidgetSettings(props)), getDefaultSortSettings(props));
            case 'Timeline':
                widgetSettings.width = 2;
                widgetSettings.height = 1;
                return __assign({}, widgetSettings);
            case 'Free Form':
                widgetSettings.width = 1;
                widgetSettings.height = 1;
                return __assign({}, widgetSettings);
            default:
                return null;
        }
    }
    return null;
}
export { getDefaultWidgetSettings };
