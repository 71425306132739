import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
var QueryBuilderActionCreators = {
    getAllFilters: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_ALL_FILTERS_REQUEST,
        });
        WebApi.getAllFilters();
    },
    getFields: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_QUERYBUILDER_FIELDS_REQUEST,
        });
        WebApi.getQueryBuilderFields();
    },
    getFieldValues: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_QUERYBUILDER_FIELD_VALUES_REQUEST,
        });
        WebApi.getQueryBuilderFieldValues(model);
    },
};
export default QueryBuilderActionCreators;
