import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
var CommonActionCreators = {
    getSourcesType: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_SOURCE_TYPES_REQUEST,
        });
        WebApi.getSourcesType();
    },
    getPdfTemplates: function () {
        WebApi.getPdfTemplates();
    },
    savePdfExportUserSetting: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.SAVE_PDF_EXPORT_USER_SETTINGS_REQUEST,
        });
        WebApi.savePdfExportUserSetting(model);
    },
    getUserCountries: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_USER_COUNTRIES_REQUEST,
        });
        WebApi.getUserCountries();
    },
};
export default CommonActionCreators;
