function getSortByAxis(props) {
    if (props.widget.widgetOptionsDictionary &&
        props.widget.widgetOptionsDictionary.sort) {
        var sort = null;
        try {
            sort = JSON.parse(props.widget.widgetOptionsDictionary.sort);
        }
        catch (error) {
            sort = null;
        }
        if (sort && sort.dimensionId) {
            return sort.dimensionId;
        }
        return (sort && sort.indicatorDimensionId) || null;
    }
    return ((Array.isArray(props.widget.widgetIndicators) &&
        props.widget.widgetIndicators[0] &&
        props.widget.widgetIndicators[0] &&
        props.widget.widgetIndicators[0].indicatorId) ||
        null);
}
function getSortDirection(props) {
    if (props.widget.widgetOptionsDictionary &&
        props.widget.widgetOptionsDictionary.sort) {
        var sort = null;
        try {
            sort = JSON.parse(props.widget.widgetOptionsDictionary.sort);
        }
        catch (error) {
            sort = null;
        }
        return sort && sort.sortDirection ? sort.sortDirection : 1;
    }
    return 1;
}
export function getDefaultSortSettings(props) {
    var widget = props.widget, widgetOptionsDictionary = props.widget.widgetOptionsDictionary;
    var getTopN = function () {
        if (widget.widgetTypeName === 'Grid') {
            return 100;
        }
        return 10;
    };
    if (props && widgetOptionsDictionary) {
        return {
            showAll: widgetOptionsDictionary && widgetOptionsDictionary.showAll
                ? widgetOptionsDictionary.showAll === 'true'
                : false,
            topN: widgetOptionsDictionary && widgetOptionsDictionary.topN
                ? widgetOptionsDictionary.topN
                : getTopN(),
            sortDirection: getSortDirection(props),
            sortByAxis: getSortByAxis(props),
            allOthers: widgetOptionsDictionary && widgetOptionsDictionary.allOthers
                ? (widgetOptionsDictionary.allOthers === 'true' && true) || false
                : false,
        };
    }
    return {
        showAll: false,
        topN: getTopN(),
        allOthers: false,
    };
}
