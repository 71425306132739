export function getComparisonLabelContent(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getComparisonLabelPosition(formatMessage, widgetFamilyName) {
    if (!widgetFamilyName)
        return [];
    if (widgetFamilyName.toLowerCase() === 'vertical') {
        return [
            {
                value: 'inside',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.inside',
                }),
            },
            {
                value: 'outside',
                label: formatMessage({
                    id: 'Insight.widget.settings.layout.outside',
                }),
            },
        ];
    }
    return [
        {
            value: 'inside',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.inside',
            }),
        },
        {
            value: 'right',
            label: formatMessage({
                id: 'Global.right',
            }),
        },
    ];
}
export function getComparisonLabelTooltip(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'value',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.value',
            }),
        },
    ];
}
export function getComparisonDisplayedAxis(formatMessage) {
    return [
        {
            value: 'none',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.none',
            }),
        },
        {
            value: 'xAxis',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.xAxis',
            }),
        },
        {
            value: 'yAxis',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.yAxis',
            }),
        },
        {
            value: 'all',
            label: formatMessage({
                id: 'Global.All',
            }),
        },
    ];
}
export function getComparisonXAxisOrientation(formatMessage) {
    return [
        {
            value: 'horizontal',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.horizontal',
            }),
        },
        {
            value: 'leftRight',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.leftRight',
            }),
        },
        {
            value: 'vertical',
            label: formatMessage({
                id: 'Insight.widget.settings.layout.vertical',
            }),
        },
    ];
}
export function getDefaultComparisonWidgetSettings(props) {
    var widgetOptionsDictionary = props.widget.widgetOptionsDictionary;
    return {
        labelContent: widgetOptionsDictionary && widgetOptionsDictionary.labelContent
            ? widgetOptionsDictionary.labelContent
            : 'none',
        labelPosition: widgetOptionsDictionary && widgetOptionsDictionary.labelPosition
            ? widgetOptionsDictionary.labelPosition
            : 'inside',
        labelTooltip: widgetOptionsDictionary && widgetOptionsDictionary.labelTooltip
            ? widgetOptionsDictionary.labelTooltip
            : 'value',
        displayedAxis: widgetOptionsDictionary && widgetOptionsDictionary.displayedAxis
            ? widgetOptionsDictionary.displayedAxis
            : 'all',
        xAxisOrientation: widgetOptionsDictionary && widgetOptionsDictionary.xAxisOrientation
            ? widgetOptionsDictionary.xAxisOrientation
            : 'leftRight',
    };
}
