export default {
    // Generic
    'generic.add': 'Ok',
    'generic.cancel': 'Annuler',
    // BlockType
    'components.controls.blocktype.h1': 'Titre 1',
    'components.controls.blocktype.h2': 'Titre 2',
    'components.controls.blocktype.h3': 'Titre 3',
    'components.controls.blocktype.h4': 'Titre 4',
    'components.controls.blocktype.h5': 'Titre 5',
    'components.controls.blocktype.h6': 'Titre 6',
    'components.controls.blocktype.blockquote': 'Citation',
    'components.controls.blocktype.code': 'Code',
    'components.controls.blocktype.blocktype': 'Type bloc',
    'components.controls.blocktype.normal': 'Normal',
    // Color Picker
    'components.controls.colorpicker.colorpicker': 'Palette de couleur',
    'components.controls.colorpicker.text': 'Texte',
    'components.controls.colorpicker.background': 'Fond',
    // Embedded
    'components.controls.embedded.embedded': 'Embedded',
    'components.controls.embedded.embeddedlink': 'Lien iFrame',
    'components.controls.embedded.enterlink': 'Entrer le lien',
    // Emoji
    'components.controls.emoji.emoji': 'Emoji',
    // FontFamily
    'components.controls.fontfamily.fontfamily': 'Police',
    // FontSize
    'components.controls.fontsize.fontsize': 'Taille de police',
    // History
    'components.controls.history.history': 'Historique',
    'components.controls.history.undo': 'Précédent',
    'components.controls.history.redo': 'Suivant',
    // Image
    'components.controls.image.image': 'Image',
    'components.controls.image.fileUpload': 'Téléchargement',
    'components.controls.image.byURL': 'URL',
    'components.controls.image.dropFileText': 'Glisser une image ou cliquer pour télécharger',
    // Inline
    'components.controls.inline.bold': 'Gras',
    'components.controls.inline.italic': 'Italique',
    'components.controls.inline.underline': 'Souligner',
    'components.controls.inline.strikethrough': 'Barrer',
    'components.controls.inline.monospace': 'Monospace',
    'components.controls.inline.superscript': 'Exposant',
    'components.controls.inline.subscript': 'Indice',
    // Link
    'components.controls.link.linkTitle': 'Titre du lien',
    'components.controls.link.linkTarget': 'Cible du lien',
    'components.controls.link.linkTargetOption': 'Ouvrir le lien dans une nouvelle fenêtre',
    'components.controls.link.link': 'Lier',
    'components.controls.link.unlink': 'Délier',
    // List
    'components.controls.list.list': 'Liste',
    'components.controls.list.unordered': 'Désordonnée',
    'components.controls.list.ordered': 'Ordonnée',
    'components.controls.list.indent': 'Augmenter le retrait',
    'components.controls.list.outdent': 'Diminuer le retrait',
    // Remove
    'components.controls.remove.remove': 'Supprimer',
    // TextAlign
    'components.controls.textalign.textalign': 'Alignement du texte',
    'components.controls.textalign.left': 'Gauche',
    'components.controls.textalign.center': 'Centre',
    'components.controls.textalign.right': 'Droite',
    'components.controls.textalign.justify': 'Justifier',
};
