var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { BsX, BsEnvelope } from 'react-icons/bs';
import ButtonIcon from '@commons/ButtonIcon';
import { AlertBannerContext } from '@context/AlertBannerContext';
import ProductNewsActionCreators from '@actions/ProductNewsActionCreators';
import UserStore from '@stores/UserStore';
import { Wrapper } from './AlertBanner.styles';
function AlertBanner() {
    var _a = useContext(AlertBannerContext), alertBannerObj = _a.alertBannerObj, setAlertBannerObj = _a.setAlertBannerObj;
    var message = alertBannerObj.message, open = alertBannerObj.open, init = alertBannerObj.init;
    var handleClose = function () {
        var productNews = UserStore.getIncidentMessageData();
        ProductNewsActionCreators.markAllProductAsRead({
            productNewsIds: [productNews.productNewsId],
        }, function () {
            setAlertBannerObj(__assign(__assign({}, alertBannerObj), { open: false }));
        });
    };
    var messageToRender = useMemo(function () {
        var isHtmlReg = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
        if (!message) {
            return null;
        }
        else if (isHtmlReg.test(message)) {
            return _jsx("span", { dangerouslySetInnerHTML: { __html: message } });
        }
        return message;
    }, [message]);
    return (_jsxs(Wrapper, { "$open": open, "$init": init, children: [_jsx("div", { className: "envelop-block", children: _jsx(BsEnvelope, { size: 20 }) }), _jsx("p", { className: "alertBanner__text", children: messageToRender }), _jsx(ButtonIcon, { onClick: handleClose, children: _jsx(BsX, { size: 32 }) })] }));
}
export default AlertBanner;
