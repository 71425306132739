var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
var GET_SUPPORT_CONTACTS = 'getSupportContacts';
var SEND_EMAIL_SUPPORT_CONTACTS = 'sendEmailSupportContacts';
var GET_LOGOUT = 'getLogout';
var GET_LOGIN = 'getLogin';
var GET_NEWS_BANNERS = 'getsNewsBanners';
var GET_IS_LOGIN_SSO = 'getIsLoginSSO';
var LoginStore = /** @class */ (function (_super) {
    __extends(LoginStore, _super);
    function LoginStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    LoginStore.prototype.initializeState = function () {
        this.init = false;
        this.loginInfo = {};
        this.newsBanners = [];
    };
    LoginStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    LoginStore.prototype.setLoginInfo = function (username, password, account) {
        if (account === void 0) { account = null; }
        this.loginInfo.username = username;
        this.loginInfo.password = password;
        if (account) {
            this.loginInfo.account = account;
        }
    };
    LoginStore.prototype.getLoginInfo = function () {
        return this.loginInfo;
    };
    LoginStore.prototype.getBanners = function () {
        return this.newsBanners;
    };
    LoginStore.prototype.setBanners = function (banners) {
        this.newsBanners = banners;
    };
    LoginStore.prototype.emitGetSupportContacts = function (data) {
        this.emit(GET_SUPPORT_CONTACTS, data);
    };
    LoginStore.prototype.addGetSupportContactsListener = function (callback) {
        this.on(GET_SUPPORT_CONTACTS, callback);
    };
    LoginStore.prototype.removeGetSupportContactsListener = function (callback) {
        this.removeListener(GET_SUPPORT_CONTACTS, callback);
    };
    LoginStore.prototype.emitGetNewsBanners = function (data) {
        this.emit(GET_NEWS_BANNERS, data);
    };
    LoginStore.prototype.addGetNewsBannersListener = function (callback) {
        this.on(GET_NEWS_BANNERS, callback);
    };
    LoginStore.prototype.removeGetNewsBannersListener = function (callback) {
        this.removeListener(GET_NEWS_BANNERS, callback);
    };
    LoginStore.prototype.emitIsLoginSSO = function (data) {
        this.emit(GET_IS_LOGIN_SSO, data);
    };
    LoginStore.prototype.addGetIsLoginSSOListener = function (callback) {
        this.on(GET_IS_LOGIN_SSO, callback);
    };
    LoginStore.prototype.removeGetIsLoginSSOListener = function (callback) {
        this.removeListener(GET_IS_LOGIN_SSO, callback);
    };
    LoginStore.prototype.emitGetLogin = function (type, data) {
        this.emit(GET_LOGIN, type, data);
    };
    LoginStore.prototype.addGetLoginListener = function (callback) {
        this.on(GET_LOGIN, callback);
    };
    LoginStore.prototype.removeGetLoginListener = function (callback) {
        this.removeListener(GET_LOGIN, callback);
    };
    LoginStore.prototype.emitGetLogout = function () {
        this.emit(GET_LOGOUT);
    };
    LoginStore.prototype.addGetLogoutListener = function (callback) {
        this.on(GET_LOGOUT, callback);
    };
    LoginStore.prototype.removeGetLogoutListener = function (callback) {
        this.removeListener(GET_LOGOUT, callback);
    };
    LoginStore.prototype.emitSendEmailSupportContacts = function (data) {
        this.emit(SEND_EMAIL_SUPPORT_CONTACTS, data);
    };
    LoginStore.prototype.addSendEmailContactsListener = function (callback) {
        this.on(SEND_EMAIL_SUPPORT_CONTACTS, callback);
    };
    LoginStore.prototype.removeSendEmailContactsListener = function (callback) {
        this.removeListener(SEND_EMAIL_SUPPORT_CONTACTS, callback);
    };
    LoginStore.prototype.registerToActions = function (payload) {
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_SUPPORT_CONTACTS_RESPONSE:
                if (action.json === null || action.json.data === null) {
                    this.emitError('GET_SUPPORT_CONTACTS');
                }
                else {
                    this.init = true;
                    this.supportContacts = action.json.data;
                    this.emitGetSupportContacts(this.supportContacts);
                }
                break;
            case ActionTypes.GET_NEWS_BANNERS_RESPONSE:
                if (action.json === null || action.json.data === null) {
                    this.emitError('GET_NEWS_BANNERS_RESPONSE');
                }
                else {
                    this.init = true;
                    this.newsBanners = action.json.data;
                    this.emitGetNewsBanners(this.newsBanners);
                }
                break;
            case ActionTypes.GET_IS_LOGIN_RESPONSE:
                if (action.json === null || action.json.data === null) {
                    this.emitError('GET_IS_LOGIN_RESPONSE');
                }
                else {
                    this.emitIsLoginSSO(action.json.data);
                }
                break;
            case ActionTypes.GET_PUBLIC_LOGIN_RESPONSE:
            case ActionTypes.GET_LOGIN_RESPONSE:
                if (action.json == null ||
                    action.json.resulType === 'ERROR' ||
                    action.json.resulType === 'BLOCKED') {
                    this.emitError('GET_LOGIN_RESPONSE', action.json);
                }
                else {
                    this.init = true;
                    this.emitGetLogin(action.actionType, action.json);
                }
                break;
            case ActionTypes.GET_LOGOUT_RESPONSE:
                if (action.json === null) {
                    this.emitError('GET_LOGOUT_RESPONSE');
                }
                else {
                    this.init = false;
                    if (action.json.message) {
                        window.localStorage.removeItem('AccountIdSSO');
                        window.localStorage.setItem('AccountIdSSO', action.json.message);
                    }
                    this.emitGetLogout();
                }
                break;
            case ActionTypes.SEND_EMAIL_SUPPORT_CONTACTS_RESPONSE:
                if (action.errors) {
                    this.emitError('SEND_EMAIL_SUPPORT_CONTACTS');
                }
                else {
                    this.codeStatus = action.json.code;
                    this.emitSendEmailSupportContacts(this.codeStatus);
                }
                break;
            default:
                break;
        }
    };
    return LoginStore;
}(BaseStore));
export default new LoginStore();
