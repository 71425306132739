var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable no-param-reassign */
import ActionTypes from 'constants/actionTypes';
import BaseStore from 'stores/BaseStore';
import { getDefaultWidgetSettings } from 'services/WidgetDefaultOptions';
import { lowerCaseKeys } from 'services/AppUtils';
var GET_WIDGETS = 'getWidgets';
var CREATE_WIDGET = 'createWidget';
var UPDATE_WIDGET = 'updateWidget';
var QUICK_WIDGET = 'quickWidget';
var LOAD_WIDGETS = 'loadWidgets';
var HIDE_DASHBOARD_LOADER = 'hideDashboardLoader';
var GET_WIDGET_DATA = 'getWidgetData';
var GET_CHART_DATA = 'getChartData';
var GET_QUICK_WIDGETS = 'getQuickWidgets';
var DELETE_WIDGET = 'deleteWidget';
var GET_TIMELINE_SETTINGS = 'getTimelineSettings';
var ADD_WIDGETS = 'addWidgets';
var GET_NEXT_TIMELINE_DATA_EVENT = 'getNextTimelineData';
var CREATE_WIDGET_MODEL = 'getCreateWidgetModel';
var WIDGET_DIMENSIONS_MODEL = 'widgetDimensionsModel';
var GENERATE_DUPLICATE_WIDGET_NAME = 'generateDuplicateWidgetName';
var COPY_WIDGET = 'copyWidget';
var GET_WIDGET_DRILLDOWN_DATA = 'getWidgetDrilldownData';
var GET_NEXT_WIDGET_DRILLDOWN_DATA = 'getNextWidgetDrilldownData';
var SET_WIDGET_ORDER_EVENT = 'setWidgetOrder';
var WidgetStore = /** @class */ (function (_super) {
    __extends(WidgetStore, _super);
    function WidgetStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    WidgetStore.prototype.initializeState = function () {
        this.tabulationWidgets = [];
        this.widgetsData = [];
        this.WidgetFilterSearchDates = null;
        this.quickWidgets = [];
        this.init = {};
        this.CreateWidgetModel = {
            widgetTypes: null,
            widgetDimensions: null,
        };
    };
    WidgetStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(WidgetStore.prototype, "widgetFilterSearchDates", {
        get: function () {
            return this.WidgetFilterSearchDates;
        },
        set: function (data) {
            this.WidgetFilterSearchDates = data;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WidgetStore.prototype, "metricsWidgetTypesModel", {
        get: function () {
            return this.CreateWidgetModel.widgetTypes;
        },
        set: function (model) {
            this.CreateWidgetModel.widgetTypes = model;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WidgetStore.prototype, "metricsWidgetDimensions", {
        get: function () {
            return this.CreateWidgetModel.widgetDimensions;
        },
        set: function (model) {
            this.CreateWidgetModel.widgetDimensions = model;
        },
        enumerable: false,
        configurable: true
    });
    WidgetStore.prototype.isInitialized = function (property) {
        return this.init[property];
    };
    WidgetStore.prototype.addWidget = function (widgetId, widget) {
        var widgetObject = __assign(__assign({}, widget), { widgetId: widgetId });
        this.tabulationWidgets.push(widgetObject);
        return widgetObject;
    };
    WidgetStore.prototype.updateWidget = function (_a) {
        var _this = this;
        var widget = _a.widget;
        this.tabulationWidgets.forEach(function (item, index) {
            if (item.widgetId === widget.widgetId) {
                if (Array.isArray(widget.widgetFilters)) {
                    var reviewFilterIndex = widget.widgetFilters.findIndex(function (wf) { return wf.filterElasticFieldPath === 'source.Review'; });
                    if (reviewFilterIndex > -1) {
                        widget.widgetFilters[reviewFilterIndex].values = JSON.parse(widget.widgetFilters[reviewFilterIndex].values);
                    }
                }
                _this.tabulationWidgets[index] = widget;
            }
        });
    };
    WidgetStore.prototype.getWidgetById = function (id) {
        return this.tabulationWidgets.find(function (widget) { return widget.widgetId === id; });
    };
    WidgetStore.prototype.getDataWidgetById = function (id) {
        var data = this.widgetsData.find(function (widget) { return widget.widgetId === parseInt(id, 10); });
        return data;
    };
    WidgetStore.prototype.getTabulationWidgets = function () {
        return this.tabulationWidgets;
    };
    WidgetStore.prototype.setTabulationWidgets = function (wdgts) {
        if (!wdgts)
            this.tabulationWidgets = [];
        var widgets = lowerCaseKeys(wdgts);
        widgets.forEach(function (widget, index) {
            if (Array.isArray(widget.widgetFilters)) {
                var reviewIndex = widget.widgetFilters.findIndex(function (wf) { return wf.filterElasticFieldPath === 'source.review'; });
                if (reviewIndex > -1) {
                    widget.widgetFilters[reviewIndex].values = JSON.parse(widget.widgetFilters[reviewIndex].values);
                }
            }
            if (widgets[index].widgetOptionsDictionary) {
                widgets[index].widgetOptionsDictionary = __assign(__assign({}, getDefaultWidgetSettings({
                    widget: widget,
                })), widget.widgetOptionsDictionary);
            }
        });
        this.tabulationWidgets = widgets || [];
    };
    WidgetStore.prototype.addQuickWidget = function (widget) {
        try {
            this.quickWidgets.push(widget);
            return true;
        }
        catch (error) {
            return false;
        }
    };
    WidgetStore.prototype.deleteQuickWidget = function (widgetId) {
        try {
            var wi = this.quickWidgets.findIndex(function (qw) { return qw.widgetId === widgetId; });
            if (wi > -1) {
                this.quickWidgets.splice(wi, 1);
                return true;
            }
            return false;
        }
        catch (error) {
            return false;
        }
    };
    WidgetStore.prototype.getQuickWidgets = function () {
        return this.quickWidgets;
    };
    WidgetStore.prototype.setQuickWidgets = function (widgets) {
        this.quickWidgets = widgets || [];
        this.init.quickWidgets = true;
    };
    WidgetStore.prototype.deleteWidget = function (id) {
        this.tabulationWidgets = this.tabulationWidgets.filter(function (item) { return item.widgetId !== id; });
        this.tabulationWidgets.forEach(function (item, index) {
            item.order = index + 1;
        });
    };
    WidgetStore.prototype.updateWidgetSettings = function (setting) {
        var widget = this.tabulationWidgets.find(function (item) { return item.widgetId === setting.widgetId; });
        if (!widget)
            return;
        var options = widget.widgetOptionsDictionary;
        options.width = setting.widgetSettingsModel.width;
        options.height = setting.widgetSettingsModel.height;
    };
    WidgetStore.prototype.updateTimelineWidgetSettings = function (setting) {
        var widget = this.tabulationWidgets.find(function (item) { return item.widgetId === setting.widgetId; });
        if (!widget)
            return;
        if (widget.data.selectedFields)
            widget.data.selectedFields = setting.Value;
    };
    WidgetStore.prototype.emitGetTimelineSettingsCallback = function (settings) {
        this.emit(GET_TIMELINE_SETTINGS, settings);
    };
    WidgetStore.prototype.addGetTimelineSettingsListener = function (callback) {
        this.on(GET_TIMELINE_SETTINGS, callback);
    };
    WidgetStore.prototype.removeGetTimelineSettingsListener = function (callback) {
        this.removeListener(GET_TIMELINE_SETTINGS, callback);
    };
    WidgetStore.prototype.emitGetNextTimelineData = function (data) {
        this.emit(GET_NEXT_TIMELINE_DATA_EVENT, data);
    };
    WidgetStore.prototype.addGetNextTimelineDataListener = function (callback) {
        this.on(GET_NEXT_TIMELINE_DATA_EVENT, callback);
    };
    WidgetStore.prototype.removeGetNextTimelineDataListener = function (callback) {
        this.removeListener(GET_NEXT_TIMELINE_DATA_EVENT, callback);
    };
    WidgetStore.prototype.emitSetWidgetsOrder = function (data) {
        this.emit(SET_WIDGET_ORDER_EVENT, data);
    };
    WidgetStore.prototype.addSetWidgetsOrderListener = function (callback) {
        this.on(SET_WIDGET_ORDER_EVENT, callback);
    };
    WidgetStore.prototype.removeSetWidgetsOrderListener = function (callback) {
        this.removeListener(SET_WIDGET_ORDER_EVENT, callback);
    };
    WidgetStore.prototype.setWidgetsOrder = function (widgets) {
        var _this = this;
        widgets.forEach(function (widget) {
            var widgetDetails = _this.tabulationWidgets.find(function (item) { return item.widgetId === widget.widgetId; });
            if (widgetDetails) {
                widgetDetails.order = widget.order;
            }
        });
        this.tabulationWidgets.sort(function (a, b) { return (a.order > b.order ? 1 : -1); });
    };
    WidgetStore.prototype.getQuickWidgetById = function (widgetId) {
        return this.quickWidgets.find(function (w) { return w.widgetId === widgetId; });
    };
    WidgetStore.prototype.getWidgetData = function (id) {
        var widget = this.getDataWidgetById(id);
        if (!widget || !widget.data)
            return null;
        return JSON.parse(JSON.stringify(widget.data));
    };
    WidgetStore.prototype.setWidgetData = function (data, id) {
        var widget = this.getDataWidgetById(id);
        if (!widget) {
            this.widgetsData.push({
                widgetId: id,
                data: typeof data === 'string' ? data : lowerCaseKeys(data),
            });
        }
        else {
            widget.data = typeof data === 'string' ? data : lowerCaseKeys(data);
        }
    };
    WidgetStore.prototype.emitGetWidgets = function (action) {
        this.emit(GET_WIDGETS, action);
    };
    WidgetStore.prototype.addGetWidgetsListener = function (callback) {
        this.on(GET_WIDGETS, callback);
    };
    WidgetStore.prototype.removeGetWidgetsListener = function (callback) {
        this.removeListener(GET_WIDGETS, callback);
    };
    WidgetStore.prototype.emitAddWidgets = function (widgetIds) {
        this.emit(ADD_WIDGETS, widgetIds);
    };
    WidgetStore.prototype.addAddWidgetsListener = function (callback) {
        this.on(ADD_WIDGETS, callback);
    };
    WidgetStore.prototype.removeAddWidgetsListener = function (callback) {
        this.removeListener(ADD_WIDGETS, callback);
    };
    WidgetStore.prototype.emitHideDashboardLoader = function () {
        this.emit(HIDE_DASHBOARD_LOADER);
    };
    WidgetStore.prototype.emitLoadWidgets = function () {
        this.emit(LOAD_WIDGETS);
    };
    WidgetStore.prototype.addLoadWidgetsListener = function (callback) {
        this.on(LOAD_WIDGETS, callback);
    };
    WidgetStore.prototype.removeLoadWidgetsListener = function (callback) {
        this.removeListener(LOAD_WIDGETS, callback);
    };
    WidgetStore.prototype.emitGetWidgetDrillDownDataCallback = function (data) {
        this.emit(GET_WIDGET_DRILLDOWN_DATA, data);
    };
    WidgetStore.prototype.addGetWidgetDrillDownDataListener = function (callback) {
        this.on(GET_WIDGET_DRILLDOWN_DATA, callback);
    };
    WidgetStore.prototype.removeGetWidgetDrillDownDataListener = function (callback) {
        this.removeListener(GET_WIDGET_DRILLDOWN_DATA, callback);
    };
    WidgetStore.prototype.emitGetNextWidgetDrillDownDataCallback = function (data) {
        this.emit(GET_NEXT_WIDGET_DRILLDOWN_DATA, data);
    };
    WidgetStore.prototype.addGetNextWidgetDrillDownDataListener = function (callback) {
        this.on(GET_NEXT_WIDGET_DRILLDOWN_DATA, callback);
    };
    WidgetStore.prototype.removeGetNextWidgetDrillDownDataListener = function (callback) {
        this.removeListener(GET_NEXT_WIDGET_DRILLDOWN_DATA, callback);
    };
    WidgetStore.prototype.emitDeleteWidgetCallback = function (id) {
        this.emit(DELETE_WIDGET, id);
    };
    WidgetStore.prototype.addDeleteWidgetListener = function (callback) {
        this.on(DELETE_WIDGET, callback);
    };
    WidgetStore.prototype.removeDeleteWidgetListener = function (callback) {
        this.removeListener(DELETE_WIDGET, callback);
    };
    WidgetStore.prototype.emitGenerateDuplicateWidgetNameCallback = function (newName) {
        this.emit(GENERATE_DUPLICATE_WIDGET_NAME, newName);
    };
    WidgetStore.prototype.addGenerateDuplicateWidgetNameListener = function (callback) {
        this.on(GENERATE_DUPLICATE_WIDGET_NAME, callback);
    };
    WidgetStore.prototype.removeGenerateDuplicateWidgetNameListener = function (callback) {
        this.removeListener(GENERATE_DUPLICATE_WIDGET_NAME, callback);
    };
    WidgetStore.prototype.emitCopyWidgetCallback = function (id) {
        this.emit(COPY_WIDGET, id);
    };
    WidgetStore.prototype.addCopyWidgetListener = function (callback) {
        this.on(COPY_WIDGET, callback);
    };
    WidgetStore.prototype.removeCopyWidgetListener = function (callback) {
        this.removeListener(COPY_WIDGET, callback);
    };
    WidgetStore.prototype.emitGetQuickWidgetsCallback = function () {
        this.emit(GET_QUICK_WIDGETS);
    };
    WidgetStore.prototype.addGetQuickWidgetsListener = function (callback) {
        this.on(GET_QUICK_WIDGETS, callback);
    };
    WidgetStore.prototype.removeGetQuickWidgetsListener = function (callback) {
        this.removeListener(GET_QUICK_WIDGETS, callback);
    };
    WidgetStore.prototype.emitGetWidgetData = function (widgetId, mode) {
        this.emit(GET_WIDGET_DATA, widgetId, mode);
    };
    WidgetStore.prototype.addGetWidgetDataListener = function (callback) {
        this.on(GET_WIDGET_DATA, callback);
    };
    WidgetStore.prototype.removeGetWidgetDataListener = function (callback) {
        this.removeListener(GET_WIDGET_DATA, callback);
    };
    WidgetStore.prototype.emitGetChartData = function (data) {
        this.emit(GET_CHART_DATA, data);
    };
    WidgetStore.prototype.addGetChartDataListener = function (callback) {
        this.on(GET_CHART_DATA, callback);
    };
    WidgetStore.prototype.removeGetChartDataListener = function (callback) {
        this.removeListener(GET_CHART_DATA, callback);
    };
    WidgetStore.prototype.emitGetCreateWidgetModel = function () {
        this.emit(CREATE_WIDGET_MODEL);
    };
    WidgetStore.prototype.addGetCreateWidgetModelListener = function (callback) {
        this.on(CREATE_WIDGET_MODEL, callback);
    };
    WidgetStore.prototype.removeGetCreateWidgetModelListener = function (callback) {
        this.removeListener(CREATE_WIDGET_MODEL, callback);
    };
    WidgetStore.prototype.emitGetCreateWidgetDimensions = function () {
        this.emit(WIDGET_DIMENSIONS_MODEL);
    };
    WidgetStore.prototype.addGetCreateWidgetDimensionsListener = function (callback) {
        this.on(WIDGET_DIMENSIONS_MODEL, callback);
    };
    WidgetStore.prototype.removeGetCreateWidgetDimensionsListener = function (callback) {
        this.removeListener(WIDGET_DIMENSIONS_MODEL, callback);
    };
    WidgetStore.prototype.emitCreateWidget = function (widget) {
        this.emit(CREATE_WIDGET, widget);
    };
    WidgetStore.prototype.addCreateWidgetListener = function (callback) {
        this.on(CREATE_WIDGET, callback);
    };
    WidgetStore.prototype.removeCreateWidgetListener = function (callback) {
        this.removeListener(CREATE_WIDGET, callback);
    };
    WidgetStore.prototype.emitUpdateWidget = function (widgetId) {
        if (widgetId === void 0) { widgetId = null; }
        this.emit(UPDATE_WIDGET, widgetId);
    };
    WidgetStore.prototype.addCreateQuickWidgetListener = function (callback) {
        this.on(QUICK_WIDGET, callback);
    };
    WidgetStore.prototype.removeCreateQuickWidgetListener = function (callback) {
        this.removeListener(QUICK_WIDGET, callback);
    };
    WidgetStore.prototype.emitAddQuickWidget = function (widgetId) {
        if (widgetId === void 0) { widgetId = null; }
        this.emit(QUICK_WIDGET, widgetId);
    };
    WidgetStore.prototype.addUpdateWidgetListener = function (callback) {
        this.on(UPDATE_WIDGET, callback);
    };
    WidgetStore.prototype.removeUpdateWidgetListener = function (callback) {
        this.removeListener(UPDATE_WIDGET, callback);
    };
    WidgetStore.prototype.registerToActions = function (payload) {
        var _this = this;
        var _a, _b;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.CREATE_WIDGET_RESPONSE:
                if (action.json && action.json.data) {
                    var widget = this.addWidget(action.json.data, action.json.model);
                    this.emitCreateWidget(widget);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.UPDATE_WIDGET_RESPONSE:
                if (action.json) {
                    var model = action.json.model;
                    this.updateWidget({ widget: model });
                    this.emitUpdateWidget(action.json.data);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.GET_TABULATION_WIDGETS_RESPONSE:
                if (action.json && action.json.data) {
                    var mode = action.json.model.mode;
                    var widgets = Array.isArray(action.json.data)
                        ? action.json.data
                        : [action.json.data];
                    this.initialized = true;
                    this.setTabulationWidgets(widgets);
                    this.emitGetWidgets('GET_TABULATION_WIDGETS_RESPONSE');
                    if (mode === 'static') {
                        widgets.forEach(function (widget) {
                            _this.setWidgetData(widget.data, widget.widgetId);
                        });
                    }
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.GET_WIDGET_DATA_RESPONSE:
                if (action.json && action.json.data) {
                    var _c = action.json.model, widgetId = _c.widget.widgetId, isExpanded = _c.isExpanded, resolve = _c.resolve;
                    if (widgetId) {
                        this.setWidgetData(action.json.data, widgetId);
                    }
                    if (isExpanded) {
                        this.emitGetChartData({
                            data: action.json.data,
                            widget: action.json.model.widget,
                        });
                    }
                    else {
                        resolve('true');
                        this.emitGetWidgetData(widgetId);
                    }
                }
                else if (typeof action.errors === 'object') {
                    this.emitError(action.errors.error, action.errors.model.widget.widgetId);
                }
                else {
                    this.emitError('error', null);
                }
                break;
            case ActionTypes.GET_CREATE_WIDGET_MODEL_RESPONSE:
                if (action.json && action.json.data) {
                    var data = action.json.data;
                    this.metricsWidgetTypesModel = data;
                    this.init.createWidgetModel = true;
                    this.emitGetCreateWidgetModel();
                }
                else {
                    this.init.createWidgetModel = true;
                    this.emitError('GET_CREATE_WIDGET_MODEL_RESPONSE');
                }
                break;
            case ActionTypes.GET_WIDGET_FILTER_SEARCH_DATE_RESPONSE:
                if (action.json && action.json.data) {
                    var data = action.json.data;
                    this.widgetFilterSearchDates = data;
                    this.init.getWidgetFilterSearchDate = true;
                    this.emitGetCreateWidgetModel();
                }
                else {
                    this.init.getWidgetFilterSearchDate = true;
                    this.emitError('GET_WIDGET_FILTER_SEARCH_DATE_RESPONSE');
                }
                break;
            case ActionTypes.GET_WIDGET_DIMENSIONS_RESPONSE:
                if (action.json && action.json.data) {
                    var data = action.json.data;
                    this.metricsWidgetDimensions = data;
                    this.init.createWidgetDimensions = true;
                    // this.emitGetCreateWidgetModel();
                    this.emitGetCreateWidgetDimensions();
                }
                else {
                    this.init.createWidgetDimensions = true;
                    this.emitError('GET_WIDGET_DIMENSIONS_RESPONSE');
                }
                break;
            case ActionTypes.UPDATE_TAB_RESPONSE:
                if (((_a = action.json) === null || _a === void 0 ? void 0 : _a.data) && action.json.model.tabulationWidgets) {
                    this.setTabulationWidgets(action.json.model.tabulationWidgets);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.ADD_DASHBOARD_RESPONSE:
                if (((_b = action.json) === null || _b === void 0 ? void 0 : _b.data) && action.json.model.tabulationWidgets) {
                    this.setTabulationWidgets(action.json.model.tabulationWidgets);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.GET_QUICK_WIDGETS_RESPONSE:
                if (action.json && action.json.data) {
                    this.setQuickWidgets(action.json.data);
                    this.init.quickWidgets = true;
                    this.emitGetQuickWidgetsCallback();
                }
                else {
                    this.init.quickWidgets = true;
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.SORT_WIDGETS_RESPONSE:
                if (action.json) {
                    this.setWidgetsOrder(action.json.model);
                    this.emitSetWidgetsOrder();
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.DELETE_WIDGET_RESPONSE:
                if (action.json) {
                    var isQuickWidget = action.json.model.isQuickWidget;
                    if (isQuickWidget) {
                        this.deleteQuickWidget(action.json.model.widgetId);
                    }
                    else {
                        this.deleteWidget(action.json.model.widgetId);
                    }
                    this.emitDeleteWidgetCallback(action.json.model.widgetId);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.GET_WIDGET_DRILLDOWN_DATA_RESPONSE:
                if (action.json) {
                    this.emitGetWidgetDrillDownDataCallback(action.json.data);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.GET_NEXT_WIDGET_DRILLDOWN_DATA_RESPONSE:
                if (action.json) {
                    this.emitGetNextWidgetDrillDownDataCallback(action.json.data.drillDownData);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.GENERATE_DUPLICATE_WIDGET_NAME_RESPONSE:
                if (action.json && action.json.data) {
                    this.emitGenerateDuplicateWidgetNameCallback(action.json.data);
                }
                else {
                    this.emitError(action.actionType, action.errors);
                }
                break;
            case ActionTypes.COPY_WIDGET_RESPONSE:
                if (action.json) {
                    this.emitCopyWidgetCallback(action.json.model.widgetId);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.UPDATE_WIDGET_SETTINGS_RESPONSE:
                if (action.json) {
                    this.updateWidgetSettings(action.json.model);
                    this.emitGetWidgetData(action.json.model.widgetId, 'resize');
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.ADD_INSIGHTS_WIDGET_RESPONSE:
                if (action.json && action.json.data) {
                    action.json.data.forEach(function (widget) {
                        _this.addWidget(widget.widgetId, widget);
                    });
                    this.emitAddWidgets(action.json.data);
                }
                else {
                    this.emitError(action.actionType, action.errors);
                }
                break;
            case ActionTypes.GET_NEXT_TIMELINE_DATA_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.emitGetNextTimelineData(data);
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            case ActionTypes.CREATE_WIDGET_AS_MY_QUICK_WIDGET_RESPONSE:
                if (action.json) {
                    var _d = action.json, data = _d.data, model = _d.model;
                    var widget = __assign(__assign({}, model), { isMyQuickWidget: true, isQuickWidget: true, widgetId: data });
                    this.emitAddWidgets(widget);
                    var w = this.addQuickWidget(widget);
                    if (w) {
                        this.emitAddQuickWidget(data);
                    }
                }
                else {
                    this.emitError(action.actionType);
                }
                break;
            default:
                break;
        }
    };
    return WidgetStore;
}(BaseStore));
export default new WidgetStore();
