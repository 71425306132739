var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
var GET_INSIGHT_MENUS = 'GET_INSIGHT_MENUS';
var GET_LIMIT_PDF = 'GET_LIMIT_PDF';
var CommonStore = /** @class */ (function (_super) {
    __extends(CommonStore, _super);
    function CommonStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    CommonStore.prototype.initializeState = function () {
        this.initializedField = {};
        this.sourcesType = [];
        this.countries = null;
        this.insightMenus = null;
        this.pdfTemplates = [];
        this.limitPdf = null;
    };
    CommonStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    CommonStore.prototype.setSourceTypes = function (sourcesType) {
        this.sourcesType = sourcesType;
    };
    CommonStore.prototype.setCountries = function (countries) {
        this.countries = countries;
    };
    CommonStore.prototype.setInsightMenus = function (menus) {
        this.insightMenus = menus;
    };
    CommonStore.prototype.setLimitPdf = function (limitPdf) {
        this.limitPdf = limitPdf;
    };
    CommonStore.prototype.getLimitPdf = function () {
        return this.limitPdf;
    };
    CommonStore.prototype.setPdfTemplates = function (pdfTemplates) {
        this.pdfTemplates = pdfTemplates;
    };
    CommonStore.prototype.getInsightMenu = function () {
        return this.insightMenus;
    };
    CommonStore.prototype.getCountries = function () {
        return this.countries;
    };
    CommonStore.prototype.getPdfTemplates = function () {
        return this.pdfTemplates;
    };
    CommonStore.prototype.isFieldInitialized = function (fieldName) {
        return this.initializedField[fieldName];
    };
    Object.defineProperty(CommonStore.prototype, "sourceTypes", {
        get: function () {
            return this.sourcesType;
        },
        enumerable: false,
        configurable: true
    });
    CommonStore.prototype.addGetInsightsMenusListener = function (callback) {
        this.on(GET_INSIGHT_MENUS, callback);
    };
    CommonStore.prototype.removeGetInsightsMenusListener = function (callback) {
        this.removeListener(GET_INSIGHT_MENUS, callback);
    };
    CommonStore.prototype.emitGetInsightsMenus = function () {
        this.emit(GET_INSIGHT_MENUS);
    };
    CommonStore.prototype.addGetLimitPdfListener = function (callback) {
        this.on(GET_LIMIT_PDF, callback);
    };
    CommonStore.prototype.removeGetLimitPdfListener = function (callback) {
        this.removeListener(GET_LIMIT_PDF, callback);
    };
    CommonStore.prototype.emitGetLimitPdf = function (data) {
        this.emit(GET_LIMIT_PDF, data);
    };
    CommonStore.prototype.registerToActions = function (payload) {
        var _a, _b, _c, _d, _e;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_SOURCE_TYPES_RESPONSE:
                if ((_a = action.json) === null || _a === void 0 ? void 0 : _a.data) {
                    this.setSourceTypes(action.json.data);
                    this.initializedField.sourcesType = true;
                    this.emitChange();
                }
                else {
                    this.emitError('GET_SOURCE_TYPES');
                }
                break;
            case ActionTypes.GET_USER_COUNTRIES_RESPONSE:
                if ((_b = action.json) === null || _b === void 0 ? void 0 : _b.data) {
                    var data = action.json.data;
                    this.setCountries(data);
                    // this.initializedField.countries = true;
                    this.initialized = true;
                    this.emitChange();
                }
                else {
                    this.emitError('GET_ALL_COUNTRIES');
                }
                break;
            // ===================
            // TODO: GET_ALL_SERVICES_RESPONSE sent from dashboard does not have services on Data
            // ===================
            // case ActionTypes.GET_ALL_SERVICES_RESPONSE:
            //   if (action.json) {
            //     const { services } = action.json.data;
            //     this.setServices(services);
            //     this.initializedField.services = true;
            //     this.emitChange();
            //   } else {
            //     this.emitError('GET_ALL_COUNTRIES');
            //   }
            // break;
            // TODO: to change with getCurrentUserInfo api
            case ActionTypes.GET_PERMISSIONS_RESPONSE:
                if ((_c = action.json) === null || _c === void 0 ? void 0 : _c.data) {
                    var insightMenu = action.json.data.insightMenu;
                    this.setInsightMenus(insightMenu);
                    this.initializedField.insightMenu = true;
                    this.emitGetInsightsMenus();
                }
                else {
                    this.emitError('GET_INSIGHT_MENUS');
                }
                break;
            case ActionTypes.GET_PDF_TEMPLATES_RESPONSE:
                if ((_d = action.json) === null || _d === void 0 ? void 0 : _d.data) {
                    var data = action.json.data;
                    this.setPdfTemplates(data);
                    this.emitChange();
                }
                break;
            case ActionTypes.ACCESS_NEWSDIGEST_BY_TYPE_RESPONSE:
            case ActionTypes.ACESS_REVIEW_BY_TYPE_RESPONSE:
                if ((_e = action.json) === null || _e === void 0 ? void 0 : _e.data) {
                    var data = action.json.data;
                    this.setLimitPdf(data.limitpdf);
                    this.emitGetLimitPdf(data.limitpdf);
                }
                break;
            default:
                break;
        }
    };
    return CommonStore;
}(BaseStore));
export default new CommonStore();
