var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable no-underscore-dangle */
import ActionTypes from 'constants/actionTypes';
import BaseStore from 'stores/BaseStore';
import { convertToUserTZ, parseDate } from 'services/DateUtils';
import moment from 'moment';
var GET_STRING_QUERY_PERCOLATOR = 'getQueryStringPercolator';
var ACTIVATE_DESACTIVATE_PERCOLATOR = 'activateDesactivatePercolator';
var CHECK_QUERY_PERCOLATOR = 'checkQueryPercoltaor';
var TEST_QUERY_PERCOLATOR = 'testQueryPercoltaor';
var SAVE_NEW_RETROACTIVELY_QUERY_PERCOLATOR = 'saveNewRetroactivelyPerco';
var PercolatorStore = /** @class */ (function (_super) {
    __extends(PercolatorStore, _super);
    function PercolatorStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.saveAs = _this.saveAs.bind(_this);
        _this.handletViewTestResult = _this.handletViewTestResult.bind(_this);
        return _this;
    }
    PercolatorStore.prototype.initializeState = function () {
        var _this = this;
        this._percolators = [];
        this._percolatorsRetroactively = [];
        this.subscribe(function () { return _this.registerToActions.bind(_this); });
        this.searchModel = [];
        this.blob = {};
        this.excelFileName = '';
        this.disposition = '';
        this.limitOfPerco = 20;
        this._infos = [];
        this.categories = [];
    };
    PercolatorStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(PercolatorStore.prototype, "percolators", {
        get: function () {
            return this._percolators;
        },
        set: function (percolators) {
            this._percolators =
                (percolators === null || percolators === void 0 ? void 0 : percolators.map(function (pr) { return (__assign(__assign({}, pr), { updatedDate: pr.updatedDate
                        ? convertToUserTZ(pr.updatedDate, 'YYYY/MM/DD - HH:mm')
                        : null, originCreatedDate: pr.originCreatedDate ||
                        (pr.createdDate && pr.createdDate.includes('T')
                            ? pr.createdDate
                            : moment(pr.createdDate).format('YYYY-MM-DDTHH:mm:ss')), createdDate: pr.createdDate && pr.createdDate.includes('T')
                        ? convertToUserTZ(pr.createdDate, 'YYYY/MM/DD - HH:mm')
                        : pr.createdDate, keywords: pr.typepercolator === 'keyword' && pr.keywords
                        ? Array.isArray(pr.keywords)
                            ? pr.keywords.join(' | ')
                            : pr.keywords
                        : pr.typepercolator === 'clipselection' && pr.clipselections
                            ? pr.clipselections.map(function (elm) { return elm.name; }).join('| ')
                            : '' })); }).map(function (pr) { return (__assign(__assign({}, pr), { name: !pr.name || Array.isArray(pr.name) ? pr.keywords : pr.name })); })) || [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PercolatorStore.prototype, "percolatorsRetroactively", {
        get: function () {
            return this._percolatorsRetroactively;
        },
        set: function (percoRetroactively) {
            this._percolatorsRetroactively =
                percoRetroactively &&
                    percoRetroactively.map(function (pr) { return (__assign(__assign({}, pr), { startDateToDisplay: pr.startDate
                            ? convertToUserTZ(pr.startDate, 'YYYY/MM/DD - HH:mm')
                            : null, endDateToDisplay: pr.endDate
                            ? convertToUserTZ(pr.endDate, 'YYYY/MM/DD - HH:mm')
                            : null })); });
        },
        enumerable: false,
        configurable: true
    });
    PercolatorStore.prototype.emitQueryStringChange = function (query) {
        this.emit(GET_STRING_QUERY_PERCOLATOR, query);
    };
    PercolatorStore.prototype.addGetQueryStringListener = function (callback) {
        this.on(GET_STRING_QUERY_PERCOLATOR, callback);
    };
    PercolatorStore.prototype.removeGetQueryStringListener = function (callback) {
        this.removeListener(GET_STRING_QUERY_PERCOLATOR, callback);
    };
    //
    PercolatorStore.prototype.emitSaveNewRetroactivelyPercoChange = function (data) {
        this.emit(SAVE_NEW_RETROACTIVELY_QUERY_PERCOLATOR, data);
    };
    PercolatorStore.prototype.addSaveNewRetroactivelyPercoListener = function (callback) {
        this.on(SAVE_NEW_RETROACTIVELY_QUERY_PERCOLATOR, callback);
    };
    PercolatorStore.prototype.removeSaveNewRetroactivelyPercoListener = function (callback) {
        this.removeListener(SAVE_NEW_RETROACTIVELY_QUERY_PERCOLATOR, callback);
    };
    //
    PercolatorStore.prototype.emitCheckPercoChange = function (data) {
        this.emit(CHECK_QUERY_PERCOLATOR, data);
    };
    PercolatorStore.prototype.addCheckPercoChangeListener = function (callback) {
        this.on(CHECK_QUERY_PERCOLATOR, callback);
    };
    PercolatorStore.prototype.removeCheckPercoChangeListener = function (callback) {
        this.removeListener(CHECK_QUERY_PERCOLATOR, callback);
    };
    //
    PercolatorStore.prototype.emitTestPercoChange = function (data) {
        this.emit(TEST_QUERY_PERCOLATOR, data);
    };
    PercolatorStore.prototype.addTestPercoChangeListener = function (callback) {
        this.on(TEST_QUERY_PERCOLATOR, callback);
    };
    PercolatorStore.prototype.removeTestPercoChangeListener = function (callback) {
        this.removeListener(TEST_QUERY_PERCOLATOR, callback);
    };
    //
    PercolatorStore.prototype.emitActivateDesactivatePercolatorChange = function () {
        this.emit(ACTIVATE_DESACTIVATE_PERCOLATOR);
    };
    PercolatorStore.prototype.addActivateDesactivatePercolatorChange = function (callback) {
        this.on(ACTIVATE_DESACTIVATE_PERCOLATOR, callback);
    };
    PercolatorStore.prototype.removeActivateDesactivatePercolatorChange = function (callback) {
        this.removeListener(ACTIVATE_DESACTIVATE_PERCOLATOR, callback);
    };
    PercolatorStore.prototype.setVisible = function (id, visibleValue) {
        var percolatorIndex = this.percolators.findIndex(function (cm) { return cm.id === id; });
        var updatedDate = window.moment
            .tz(new Date(), km.getTimeZone())
            .format('YYYY/MM/DD - HH:mm');
        if (percolatorIndex === -1)
            return;
        var percolator = this.percolators[percolatorIndex];
        percolator.isactive = visibleValue;
        percolator.updatedDate = visibleValue
            ? percolator.updatedDate
            : updatedDate;
        // this.emitChange();
    };
    PercolatorStore.prototype.deletePercolator = function (id) {
        var percolatorIndex = this.percolators.findIndex(function (cm) { return cm.id === id; });
        if (percolatorIndex === -1)
            return;
        this.percolators = this.percolators.filter(function (percolator) { return percolator.id !== id; });
        // this.emitChange();
    };
    PercolatorStore.prototype.updatePerco = function (perco) {
        this.percolators = this.percolators.map(function (percolator) {
            return percolator.id === perco.id ? __assign({}, perco) : percolator;
        });
    };
    PercolatorStore.prototype.updatePercoRetroactively = function (perco) {
        this.percolatorsRetroactively = this._percolatorsRetroactively.map(function (percolator) {
            return percolator.percolatorRetroactivelyId === perco.percolatorRetroactivelyId
                ? __assign({}, perco) : percolator;
        });
    };
    PercolatorStore.prototype.cancelPercoRetroactively = function (percoId) {
        this.percolatorsRetroactively = this._percolatorsRetroactively.map(function (percolator) {
            return percolator.percolatorRetroactivelyId === percoId
                ? __assign(__assign({}, percolator), { status: 4 }) : percolator;
        });
    };
    PercolatorStore.prototype.deletePercoRetroactively = function (id) {
        var percolatorIndex = this._percolatorsRetroactively.findIndex(function (cm) { return cm.percolatorRetroactivelyId === id; });
        if (percolatorIndex === -1)
            return;
        this.percolatorsRetroactively = this._percolatorsRetroactively.filter(function (percolator) { return percolator.percolatorRetroactivelyId !== id; });
        // this.emitChange();
    };
    PercolatorStore.prototype.getSearchModel = function () {
        return this.searchModel;
    };
    PercolatorStore.prototype.setSearchModel = function (searchModel) {
        this.searchModel = searchModel;
    };
    PercolatorStore.prototype.saveAs = function (blob, fileNameToSave, disposition) {
        this.blob = blob;
        this.excelFileName = fileNameToSave;
        this.disposition = disposition;
    };
    PercolatorStore.prototype.handletViewTestResult = function () {
        var fileNameFromTemplate = '';
        if (this.disposition && this.disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(this.disposition);
            if (matches != null && matches[1]) {
                fileNameFromTemplate = matches[1].replace(/['"]/g, '');
            }
        }
        // const fileNameToSave =
        //   fileNameFromTemplate != '' ? fileNameFromTemplate : this.excelFileName;
        var url = window.URL.createObjectURL(this.blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "".concat(fileNameFromTemplate || 'excel.xls');
        document.body.appendChild(a);
        a.click();
        a.remove();
        // callback(reponse);
        // this.saveAs(this.blob, fileNameToSave);
    };
    Object.defineProperty(PercolatorStore.prototype, "infos", {
        get: function () {
            return this._infos;
        },
        set: function (infos) {
            this._infos = infos;
        },
        enumerable: false,
        configurable: true
    });
    PercolatorStore.prototype.getPercolatorById = function (id) {
        return this.percolators.find(function (percoltaor) { return percoltaor.id === id; });
    };
    PercolatorStore.prototype.addCategories = function (id, name) {
        var categories = this.getCategoriesById(id);
        if (typeof id === 'string' && typeof name === 'string' && !categories) {
            var newCategorie = {
                id: id,
                name: name,
            };
            this.categories.push(newCategorie);
            return newCategorie;
        }
        return categories;
    };
    PercolatorStore.prototype.getCategoriesById = function (id) {
        return this.categories.find(function (categorie) { return categorie.id === id; });
    };
    PercolatorStore.prototype.getCategories = function () {
        return this.categories;
    };
    PercolatorStore.prototype.setCategories = function (categories) {
        this.categories = categories;
    };
    PercolatorStore.prototype.registerToActions = function (payload) {
        var _this = this;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_ALL_PERCOLATORS_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.percolators =
                        data &&
                            data.length > 0 &&
                            data
                                .filter(function (pr) {
                                return pr.typepercolator === 'clipselection' ||
                                    pr.typepercolator === 'keyword';
                            })
                                .map(function (item) { return (__assign(__assign({}, item), { updatedDate: parseDate(item.updatedDate) })); });
                    this.initialized = true;
                    this.emitChange();
                }
                else {
                    this.emitError('GET_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.GET_QUERY_STRING_PERCOLATOR_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    var query = data.query;
                    // this.repestrie = id;
                    this.emitQueryStringChange(query);
                }
                else {
                    this.emitError('REMOVE_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.ACTIVATE_DESACTIVATE_PERCOLATOR_RESPONSE:
                if (action.json) {
                    var model = action.json.model;
                    var id = model.id, status = model.status;
                    this.setVisible(id, status);
                    // this.emitActivateDesactivatePercolatorChange();
                    this.emitChange();
                }
                else {
                    this.emitError('REMOVE_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.DELETE_PERCOLATOR_RESPONSE:
                if (action.json) {
                    var model = action.json.model;
                    var id = model.id;
                    this.deletePercolator(id);
                    this.emitChange();
                }
                else {
                    this.emitError('REMOVE_PERCOLATOR_RESPONSE');
                }
                break;
            case ActionTypes.INSERT_PERCOLATOR_RESPONSE:
                if (action.json) {
                    var _a = action.json, data = _a.data, model = _a.model;
                    if (model.id) {
                        this.updatePerco(data);
                    }
                    else {
                        this.percolators = this.percolators.concat(data);
                    }
                    this.emitChange();
                }
                else {
                    this.emitError('INSERT_PERCOLATOR_RESPONSE');
                }
                break;
            case ActionTypes.TEST_PERCOLATOR_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    // this.percolators.push(data);
                    this.emitTestPercoChange(data);
                }
                else {
                    this.emitError('INSERT_PERCOLATOR_RESPONSE');
                }
                break;
            case ActionTypes.CHECK_PERCOLATOR_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    // this.percolators.push(data);
                    this.emitCheckPercoChange(data);
                }
                else {
                    this.emitError('INSERT_PERCOLATOR_RESPONSE');
                }
                break;
            case ActionTypes.GET_SEARCH_MODEL_PERCOLATOR_RESPONSE:
                if (action.json) {
                    var searchModel = action.json.data.searchModel;
                    this.setSearchModel(searchModel);
                    this.emitChange();
                }
                else {
                    this.emitError('INSERT_PERCOLATOR_RESPONSE');
                }
                break;
            case ActionTypes.GET_ALL_PERCOLATORS_RETROACTIVELY_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.percolatorsRetroactively = data;
                    this.emitChange();
                }
                else {
                    this.emitError('INSERT_PERCOLATOR_RESPONSE');
                }
                break;
            case ActionTypes.SAVE_PERCOLATORS_RETROACTIVELY_RESPONSE:
                if (action.json) {
                    var _b = action.json, data = _b.data, model = _b.model;
                    if (model.percolatorRetroactivelyId !== 0) {
                        this.updatePercoRetroactively(data);
                    }
                    else {
                        this.percolatorsRetroactively = this._percolatorsRetroactively.concat(data);
                        this.emitSaveNewRetroactivelyPercoChange(data);
                    }
                    this.emitChange();
                }
                else {
                    this.emitError('INSERT_PERCOLATOR_RESPONSE');
                }
                break;
            case ActionTypes.DELETE_PERCOLATORS_RETROACTIVELY_RESPONSE:
                if (action.json) {
                    var model = action.json.model;
                    var id = model.id;
                    this.deletePercoRetroactively(model.id);
                    this.emitChange();
                }
                else {
                    this.emitError('INSERT_PERCOLATOR_RESPONSE');
                }
                break;
            case ActionTypes.CANCEL_PERCOLATORS_RETROACTIVELY_RESPONSE:
                if (action.json) {
                    var model = action.json.model;
                    this.cancelPercoRetroactively(model.id);
                    this.emitChange();
                }
                else {
                    this.emitError('INSERT_PERCOLATOR_RESPONSE');
                }
                break;
            case ActionTypes.GET_Auto_TAGGING_INFOS_RESPONSE:
                if (action.json) {
                    var data_1 = action.json.data;
                    Object.keys(data_1.categories).forEach(function (csId) {
                        var name = data_1.categories[csId];
                        if (csId !== '0') {
                            _this.addCategories(csId, name);
                        }
                    });
                    this.infos = data_1;
                    this.emitChange();
                }
                else {
                    this.emitError('INSERT_PERCOLATOR_RESPONSE');
                }
                break;
            default:
                break;
        }
    };
    return PercolatorStore;
}(BaseStore));
export default new PercolatorStore();
