var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import { Wrapper } from './FormElementInline.styles';
var FormElementInline = function (_a) {
    var className = _a.className, _b = _a.controlClassName, controlClassName = _b === void 0 ? '' : _b, error = _a.error, _c = _a.hasError, hasError = _c === void 0 ? false : _c, _d = _a.hasLeftIcon, hasLeftIcon = _d === void 0 ? false : _d, _e = _a.hasRightIcon, hasRightIcon = _e === void 0 ? false : _e, helpMessage = _a.helpMessage, id = _a.id, label = _a.label, _f = _a.showLabel, showLabel = _f === void 0 ? true : _f, _g = _a.required, required = _g === void 0 ? false : _g, tooltip = _a.tooltip, children = _a.children;
    var renderRequired = function () { return (_jsx("abbr", { className: "required", children: "* " })); };
    var renderControl = function () {
        var elementControlClassName = classnames('c-form-element-inline__control', {
            'c-input-has-icon': hasLeftIcon || hasRightIcon,
            'c-input-has-icon--left': hasLeftIcon && !hasRightIcon,
            'c-input-has-icon--left-right': hasLeftIcon && hasRightIcon,
            'c-input-has-icon--right': hasRightIcon && !hasLeftIcon,
        }, controlClassName);
        return _jsx("div", { className: elementControlClassName, children: children });
    };
    var renderLabel = function () {
        var labelCssClass = !showLabel ? 'hidden' : '';
        return (_jsxs("label", __assign({ className: "c-form-element-inline__label ".concat(labelCssClass), htmlFor: id }, (tooltip ? { 'data-tooltip': tooltip } : {}), { children: [required && renderRequired(), _jsx("strong", { children: label })] })));
    };
    var renderHelp = function () {
        var createMarkup = { __html: helpMessage || error || '' };
        return (_jsx("span", { className: "c-form-element-inline__help", dangerouslySetInnerHTML: createMarkup }));
    };
    var FormElementInlineClassNames = classnames('c-form-element-inline', { 'has-error': error != null || hasError }, className);
    return (_jsx(Wrapper, { children: _jsxs("div", { className: FormElementInlineClassNames, children: [renderLabel(), _jsxs("div", { className: "c-form-element-inline__wrapper", children: [renderControl(), (error || helpMessage) && renderHelp()] })] }) }));
};
export default FormElementInline;
