import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@commons/Typography';
import ButtonIcon from '@commons/ButtonIcon';
import { Wrapper } from './ExportPermalink.styles';
function ExportPermalink(_a) {
    var ticket = _a.ticket, indexName = _a.indexName;
    var formatMessage = useIntl().formatMessage;
    var _b = useState(false), copied = _b[0], setCopied = _b[1];
    var apiRoot = window.location.origin;
    var kioskType = indexName.split('-')[0];
    var permaLinkHref = "".concat(apiRoot, "/Public/").concat(kioskType === 'newsdigest' ? 'NewsDigestKiosk' : 'ReviewKiosk', "?ticket=").concat(ticket);
    var handleCopyLink = function () {
        void navigator.clipboard.writeText(permaLinkHref);
        setCopied(true);
    };
    return (_jsxs(Wrapper, { children: [_jsx(Typography, { variant: "h4", component: "h2", children: formatMessage({ id: 'ExportReview.permalink' }) }), _jsxs("div", { className: "permalink__link-container", children: [_jsx("span", { className: "text-clamp cursor", children: _jsx("a", { target: "_blank", href: permaLinkHref, rel: "noreferrer", children: permaLinkHref }) }), _jsx(ButtonIcon, { icon: "copy", onClick: handleCopyLink, title: formatMessage({ id: 'Global.copy' }), children: copied && _jsx("span", { className: "tooltiptext", children: "Copied" }) })] }), _jsx("p", { className: "permalink__warning", children: formatMessage({ id: 'ExportReview.permalink.warning' }) })] }));
}
export default ExportPermalink;
