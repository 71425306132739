var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var Wrapper = styled('li')(function (_a) {
    var $hasNumberOfClips = _a.$hasNumberOfClips;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: 0.85rem;\n    ", "\n\n    .c-facet-item__number {\n      float: right;\n      text-align: right;\n      text-align: center;\n      margin-right: 3px;\n    }\n\n    .checkbox {\n      label {\n        padding-top: 2px;\n      }\n    }\n  "], ["\n    font-size: 0.85rem;\n    ", "\n\n    .c-facet-item__number {\n      float: right;\n      text-align: right;\n      text-align: center;\n      margin-right: 3px;\n    }\n\n    .checkbox {\n      label {\n        padding-top: 2px;\n      }\n    }\n  "])), $hasNumberOfClips
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          & > .checkbox {\n            margin-right: 3.75rem;\n            font-size: 0.85rem;\n          }\n        "], ["\n          & > .checkbox {\n            margin-right: 3.75rem;\n            font-size: 0.85rem;\n          }\n        "]))) : '');
});
export { Wrapper };
var templateObject_1, templateObject_2;
