var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/*eslint-disable*/
import ActionTypes from 'constants/actionTypes';
import BaseStore from 'stores/BaseStore';
import ClipStore from 'stores/ClipStore';
import * as Utils from 'services/AppUtils';
import * as DateUtils from 'services/DateUtils';
import * as StringUtils from 'services/StringUtils';
import HierarchyStore from 'stores/HierarchyStore';
import UserStore from 'stores/UserStore';
var ADD_TO_DRAFT_EVENT = 'addToDraft';
var CREATE_DRAFT_EVENT = 'createDraft';
var DELETE_DRAFT_EVENT = 'deleteDraft';
var REMOVE_FROM_DRAFT_EVENT = 'removeFromDraft';
var UPDATE_DRAFT_EVENT = 'updateDraft';
var UPDATE_USER_LIST_PREFERENCE_EVENT = ' updateUserListPreference';
/**
 * Class representing a clip selection in a draft.
 */
var ClipSelectionDraft = /** @class */ (function () {
    /**
     * Create a clip selection of a draft.
     * @param {string[]} clipIds.
     * @param {string} id.
     */
    function ClipSelectionDraft(clipIds, id) {
        this.clipIds = [];
        this.id = id;
        this.setClipIds(clipIds);
    }
    ClipSelectionDraft.prototype.addClip = function (clipId) {
        this.clipIds.push(clipId);
    };
    ClipSelectionDraft.prototype.removeClip = function (clipId) {
        var index = this.clipIds.indexOf(clipId);
        if (index !== -1) {
            this.clipIds.splice(index, 1);
        }
    };
    /**
     * Sets the list of clip ids who are uncategorized to the draft.
     * @param {string[]} clipIds.
     */
    ClipSelectionDraft.prototype.setClipIds = function (clipIds) {
        if (Array.isArray(clipIds)) {
            this.clipIds = clipIds.filter(function (id) { return typeof id === 'string'; });
        }
    };
    return ClipSelectionDraft;
}());
/**
 * Class representing a draft.
 */
var Draft = /** @class */ (function () {
    /**
     * Create a draft.
     * @param {string} createdDate.
     * @param {string} hierarchyId.
     * @param {string} id.
     * @param {string} name.
     * @param {array} userIds.
     */
    function Draft(_a) {
        var createdDate = _a.createdDate, hierarchyId = _a.hierarchyId, id = _a.id, name = _a.name, userIds = _a.userIds, isDeduplicated = _a.isDeduplicated, totalClips = _a.totalClips;
        // Default values
        this.initializeState();
        // Valid and set properties
        this.setCreatedDate((km.getTimeZone() &&
            new Date(window
                .moment(createdDate)
                .tz(km.getTimeZone())
                .format('YYYY-MM-DD HH:mm:ss'))) ||
            new Date(createdDate));
        this.setHierarchyId(hierarchyId);
        this.setId(id);
        this.setName(name);
        this.setUserIds(userIds);
        this.setIsDeduplicated(isDeduplicated);
        this.setTotalClips(totalClips);
        this.setClips();
    }
    Draft.prototype.initializeState = function () {
        this.clipSelections = [];
        this.clipIds = [];
        this.clips = [];
        this.allClips = [];
        this.createdDate = new Date();
        this.hasDetails = false;
        this.hierarchyId = '';
        this.id = '';
        this.name = '';
        this.userIds = [];
        this.isDeduplicated = false;
        this.totalClips = 0;
    };
    /**
     * Reset uncategorized and categorized clips of the draft.
     */
    Draft.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    /**
     * Add a clip in the draft.
     * @param {string} clipId.
     */
    Draft.prototype.addClip = function (clipId) {
        var _this = this;
        if (!this.hasDetails || this.isClipAlreadyInDraft(clipId)) {
            return;
        }
        var hierarchy = HierarchyStore.getHierarchyById(this.hierarchyId);
        var clipSelectionIds = hierarchy.getClipSelectionIds();
        var clip = ClipStore.getClipById(clipId);
        var isCategorized = clip.clipSelections.some(function (_a) {
            var id = _a.id;
            return clipSelectionIds.includes(id);
        });
        if (isCategorized) {
            var clipSelectionIdsInClip = clip.clipSelections.map(function (_a) {
                var id = _a.id;
                return id;
            });
            clipSelectionIdsInClip.forEach(function (id) {
                if (clipSelectionIds.includes(id)) {
                    var clipSelectionDraft = _this.clipSelections.find(function (_a) {
                        var clipSelectionId = _a.id;
                        return clipSelectionId === id;
                    });
                    if (clipSelectionDraft == null) {
                        var index = clipSelectionIds.indexOf(id);
                        _this.clipSelections.splice(index, 0, new ClipSelectionDraft([clipId], id));
                    }
                    else {
                        clipSelectionDraft.addClip(clipId);
                    }
                }
            });
        }
        else {
            this.clipIds.push(clipId);
        }
    };
    /**
     * Add a clip in the draft.
     * @param {Array} clipIds.
     */
    Draft.prototype.addClips = function (clipIds) {
        var _this = this;
        clipIds.forEach(function (clipId) { return _this.addClip(clipId); });
    };
    /**
     * Build clip selections of a draft from server data.
     * @param {Array} clipSelections.
     * @param {string} clipSelections[].clipSelectionId.
     * @param {string} clipSelections[].clipSelectionName.
     * @param {Array.<{
     *   id: string,
     *   indexName: string
     * }>} clipSelections[].clips.
     */
    Draft.prototype.buildClipSelections = function (clipSelections) {
        var _this = this;
        if (!Array.isArray(clipSelections)) {
            return;
        }
        // case of temporary hierachy
        var hierarchyId = this.withoutHierarchy
            ? "temp_hierarchy_".concat(this.name)
            : this.hierarchyId;
        var hierarchy = HierarchyStore.getHierarchyById(hierarchyId);
        var clipSelectionIds = hierarchy.getClipSelectionIds();
        this.clipSelections = clipSelections
            .filter(function (_a) {
            var clipSelectionId = _a.clipSelectionId, clips = _a.clips;
            if (!clipSelectionIds.includes(clipSelectionId)) {
                _this.clipIds = __spreadArray(__spreadArray([], _this.clipIds, true), (Array.isArray(clips)
                    ? clips
                        .map(function (_a) {
                        var id = _a.id;
                        return id;
                    })
                        .filter(function (clipId) { return !_this.clipIds.includes(clipId); })
                    : []), true);
            }
            return clipSelectionIds.includes(clipSelectionId);
        })
            .map(function (_a) {
            var clipSelectionId = _a.clipSelectionId, _b = _a.clips, clips = _b === void 0 ? [] : _b;
            return new ClipSelectionDraft(clips.map(function (_a) {
                var id = _a.id;
                return id;
            }), clipSelectionId);
        });
        this.hasDetails = true;
    };
    /**
     * Reset and build clips and clip selections of a draft from a hierarchy.
     */
    Draft.prototype.buildWithNewHierarchy = function () {
        var _this = this;
        var clipIds = this.getClipIds();
        var clips = clipIds.map(function (clipId) { return ClipStore.getClipById(clipId); });
        var hierarchy = HierarchyStore.getHierarchyById(this.hierarchyId);
        var clipSelectionIds = hierarchy.getClipSelectionIds();
        this.reset();
        this.clipIds = clips
            .filter(function (_a) {
            var clipSelections = _a.clipSelections;
            return clipSelections
                .map(function (_a) {
                var clipSelectionId = _a.id;
                return clipSelectionId;
            })
                .every(function (clipSelectionIdInClip) {
                return !clipSelectionIds.includes(clipSelectionIdInClip);
            });
        })
            .map(function (_a) {
            var id = _a.id;
            return id;
        });
        clipSelectionIds.forEach(function (clipSelectionId) {
            var categorizedClips = clips.filter(function (_a) {
                var clipSelections = _a.clipSelections;
                return clipSelections.find(function (_a) {
                    var id = _a.id;
                    return id === clipSelectionId;
                }) != null;
            });
            var categorizedClipIds = categorizedClips.map(function (_a) {
                var id = _a.id;
                return id;
            });
            if (categorizedClipIds.length > 0) {
                _this.clipSelections.push(new ClipSelectionDraft(categorizedClipIds, clipSelectionId));
            }
        });
        this.hasDetails = true;
    };
    /**
     * Get the list of clip ids present in a draft.
     * @param {boolean} [unique=true] - An article can be present in several categories.
     * If this parameter is false, the ID of a clip can be present several times in the array of the list.
     * If this parameter is true, the ID do not duplicate in the array.
     * @returns {string[]} The list of clip ids.
     */
    Draft.prototype.getClipIds = function (unique) {
        if (unique === void 0) { unique = true; }
        var ids = __spreadArray([], this.clipIds, true);
        this.clipSelections.forEach(function (_a) {
            var clipIds = _a.clipIds;
            ids = __spreadArray(__spreadArray([], ids, true), (unique ? clipIds.filter(function (clipId) { return !ids.includes(clipId); }) : clipIds), true);
        });
        return ids;
    };
    /**
     * Lets know if the clip is present in the draft.
     * @param {string} clipId.
     * @returns {boolean}
     */
    Draft.prototype.isClipAlreadyInDraft = function (clipId) {
        return this.getClipIds().includes(clipId);
    };
    /**
     * Remove clips from a draft.
     * @param {Array.<{
     *   clipSelectionId: string,
     *   id: string,
     *   indexName: string
     * }>} clips.
     */
    Draft.prototype.removeClips = function (clips) {
        var _this = this;
        if (!this.hasDetails) {
            return;
        }
        clips.forEach(function (_a) {
            var clipSelectionId = _a.clipSelectionId, id = _a.id;
            // if clip is uncategorized
            if (clipSelectionId === '0') {
                var index = _this.clipIds.indexOf(id);
                if (index !== -1) {
                    _this.clipIds.splice(index, 1);
                }
                // if clip is categorized
            }
            else {
                var clipSelectionDraft = _this.clipSelections.find(function (clipSelectionItem) { return clipSelectionItem.id === clipSelectionId; });
                clipSelectionDraft.removeClip(id);
            }
        });
    };
    /**
     * Sets the list of clip ids who are uncategorized of the draft.
     * @param {string[]} clipIds.
     */
    Draft.prototype.setClipIds = function (clipIds) {
        if (Array.isArray(clipIds)) {
            this.clipIds = clipIds.filter(function (id) { return typeof id === 'string'; });
        }
    };
    /**
     * Sets Clips.
     * @param {object[]} clips.
     */
    Draft.prototype.setClips = function (clips) {
        if (Array.isArray(clips)) {
            this.clips = clips.map(function (clip) { return (__assign(__assign({}, clip), { publicationDate: DateUtils.parseDate(clip.publicationDate), deliveredDate: DateUtils.parseDate(clip.deliveredDate) })); });
            // clips.forEach(({ id, indexName }) => {
            //   ClipActionCreators.getClip({
            //     id,
            //     indexName,
            //     isRelatedArticle: false,
            //     localTimezone: 60,
            //     searchText: '',
            //     draftId,
            //   });
            // });
            // const filtredClipIds = clipIds.filter(id => typeof id === 'string');
            // this.clipIds = filtredClipIds;
            // filtredClipIds.forEach((id, index) => {
            //   this.clips[index] = { id };
            //   ClipActionCreators.getClip({
            //     id,
            //     indexName: clip.indexName,
            //     isRelatedArticle: false,
            //     localTimezone: 60,
            //     searchText: '',
            //   });
            // });
        }
    };
    Draft.prototype.setAllClips = function (clips) {
        if (Array.isArray(clips)) {
            this.allClips = clips.map(function (clip) { return (__assign(__assign({}, clip), { publicationDate: DateUtils.parseDate(clip.publicationDate), deliveredDate: DateUtils.parseDate(clip.deliveredDate) })); });
        }
    };
    /**
     * Sets the creation date of the draft.
     * @param {Object} createdDate.
     */
    Draft.prototype.setCreatedDate = function (createdDate) {
        if (DateUtils.isValidDate(createdDate)) {
            this.createdDate = DateUtils.createDateAsUTC(createdDate);
        }
    };
    /**
     * @param {Object} groupBy.
     */
    Draft.prototype.setGroupBy = function (groupBy) {
        if (groupBy) {
            this.groupBy = groupBy;
        }
    };
    Draft.prototype.setUserIds = function (userIds) {
        if (Array.isArray(userIds)) {
            this.userIds = userIds;
        }
    };
    Draft.prototype.setIsDeduplicated = function (value) {
        this.isDeduplicated = value;
    };
    /**
     * Sets the hierarchy ID of the draft.
     * @param {string} hierarchyId.
     */
    Draft.prototype.setHierarchyId = function (hierarchyId) {
        this.hierarchyId = hierarchyId;
    };
    /**
     * Sets the ID of the draft.
     * @param {string} id.
     */
    Draft.prototype.setId = function (id) {
        if (DraftCollection.checkId(id)) {
            this.id = id;
        }
    };
    /**
     * Sets the name of the draft.
     * @param {string} name.
     */
    Draft.prototype.setName = function (name) {
        if (DraftCollection.checkName(name)) {
            this.name = name;
        }
    };
    Draft.prototype.setTotalClips = function (totalClips) {
        this.totalClips = totalClips || 0;
    };
    return Draft;
}());
/**
 * Class representing a collection of drafts.
 */
var DraftCollection = /** @class */ (function () {
    function DraftCollection() {
        this.collection = [];
        this.userListPreferences = [];
    }
    /**
     * Check if the created date is valid.
     * @param {string} createdDate.
     * @return {boolean}
     */
    DraftCollection.checkCreatedDate = function (createdDate) {
        return DateUtils.isValidDate(new Date(createdDate));
    };
    /**
     * Check if the hierarchy ID is valid.
     * @param {string} hierarchyId.
     * @return {boolean}
     */
    DraftCollection.checkHierarchyId = function (hierarchyId) {
        return typeof hierarchyId === 'string' && hierarchyId.length > 0;
    };
    /**
     * Check if the ID is valid.
     * @param {string} id.
     * @return {boolean}
     */
    DraftCollection.checkId = function (id) {
        return typeof id === 'string' && id.length > 0;
    };
    /**
     * Check if the name is valid.
     * @param {string} name.
     * @return {boolean}
     */
    DraftCollection.checkName = function (name) {
        return typeof name === 'string' && name.length > 0;
    };
    /**
     * Check if the userId is valid
     * @param {number} id.
     * @return {boolean}
     */
    DraftCollection.checkUserId = function (id) {
        return typeof id === 'number' && id !== 0;
    };
    /**
     * Add a draft in the collection.
     * @param {string} draft[].createdDate.
     * @param {string} draft[].hierarchyId.
     * @param {string} draft[].id.
     * @param {string} draft[].name.
     * @return {Draft}
     */
    DraftCollection.prototype.add = function (draft) {
        var createdDate = draft.createdDate, hierarchyId = draft.hierarchyId, id = draft.id, name = draft.name, isDeduplicated = draft.isDeduplicated, indexName = draft.indexName, author = draft.author, _a = draft.withoutHierarchy, withoutHierarchy = _a === void 0 ? false : _a, hash = draft.hash;
        if (DraftCollection.checkCreatedDate(createdDate) &&
            DraftCollection.checkId(id) &&
            DraftCollection.checkName(name)) {
            var d = this.getDraftById(id);
            var newDraft_1 = d || new Draft(draft);
            var isDraftExist = this.collection.find(function (dr) { return dr.id === newDraft_1.id; });
            newDraft_1.createdDate =
                DateUtils.parseDate(createdDate) ||
                    Utils.createDateAsUTC(new Date(createdDate));
            newDraft_1.isDeduplicated = isDeduplicated;
            newDraft_1.indexName = indexName;
            newDraft_1.withoutHierarchy = withoutHierarchy;
            newDraft_1.author = author;
            if (hash)
                newDraft_1.hash = hash;
            if (newDraft_1 != null && !isDraftExist) {
                this.collection.unshift(newDraft_1);
            }
            return newDraft_1;
        }
        return null;
    };
    /**
     * Add user preferences
     * @param {array} userPreferences.
     */
    DraftCollection.prototype.addUserPreferences = function (userPreferences) {
        var _this = this;
        this.emptyUserPreferences();
        userPreferences.forEach(function (element) {
            return _this.userListPreferences.includes(element)
                ? null
                : _this.userListPreferences.push(element);
        });
    };
    DraftCollection.prototype.emptyUserPreferences = function () {
        this.userListPreferences = [];
    };
    /**
     * Delete a draft from the collection by its ID.
     * @param {string} draftId.
     */
    DraftCollection.prototype.delete = function (draftId) {
        var index = this.collection.findIndex(function (_a) {
            var id = _a.id;
            return id === draftId;
        });
        this.collection.splice(index, 1);
    };
    /**
     * Get the collection.
     */
    DraftCollection.prototype.get = function () {
        return this.collection.sort(function (a, b) {
            return Utils.sortStringAlphabetically(a, b, 'name');
        });
    };
    /**
     * Get a draft by its ID.
     * @param {string} draftId.
     * @return {Draft}
     */
    DraftCollection.prototype.getDraftById = function (draftId) {
        return this.collection.find(function (_a) {
            var id = _a.id;
            return id === draftId;
        });
    };
    /**
     * Get a draft by its Hash.
     * @param {string} draftHash.
     * @return {Draft}
     */
    DraftCollection.prototype.getDraftByHash = function (draftHash) {
        return this.collection.find(function (_a) {
            var hash = _a.hash;
            return hash === draftHash;
        });
    };
    DraftCollection.prototype.getUserPreferences = function () {
        return this.userListPreferences;
    };
    /**
     * Reset the collection.
     */
    DraftCollection.prototype.reset = function () {
        this.collection = [];
    };
    return DraftCollection;
}());
var DraftStore = /** @class */ (function (_super) {
    __extends(DraftStore, _super);
    function DraftStore() {
        var _this = _super.call(this) || this;
        _this.draftCollection = new DraftCollection();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        _this.openedDraftIds = [];
        return _this;
    }
    Object.defineProperty(DraftStore.prototype, "drafts", {
        get: function () {
            return this.draftCollection.get();
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Get a draft by its ID.
     * @param {string} draftId.
     * @return {Draft}
     */
    DraftStore.prototype.getDraftById = function (draftId) {
        return this.draftCollection.getDraftById(draftId);
    };
    /**
     * Get a draft by its Hash.
     * @param {string} draftHash.
     * @return {Draft}
     */
    DraftStore.prototype.getDraftByHash = function (draftHash) {
        return this.draftCollection.getDraftByHash(draftHash);
    };
    DraftStore.prototype.getUserPreferences = function () {
        return this.draftCollection.getUserPreferences();
    };
    /**
     * Check if a draft already exists with a name.
     * @param {string} draftId.
     * @param {string} string - The name to check.
     * @return {boolean}
     */
    DraftStore.prototype.isNameExist = function (draftId, string) {
        return (this.draftCollection
            .get()
            .find(function (_a) {
            var id = _a.id, name = _a.name;
            return id !== draftId &&
                name.toLowerCase() === StringUtils.trim(string).toLowerCase();
        }) != null);
    };
    DraftStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.draftCollection.reset();
    };
    DraftStore.prototype.setOpenedDraftIds = function (id) {
        this.openedDraftIds = Array.from(new Set(__spreadArray(__spreadArray([], this.openedDraftIds, true), [id], false)));
    };
    DraftStore.prototype.getOpenedDraftIds = function () {
        return this.openedDraftIds;
    };
    DraftStore.prototype.emitAddToDraft = function (draftId) {
        this.emit(ADD_TO_DRAFT_EVENT, draftId);
    };
    DraftStore.prototype.addAddToDraftListener = function (callback) {
        this.on(ADD_TO_DRAFT_EVENT, callback);
    };
    DraftStore.prototype.removeAddToDraftListener = function (callback) {
        this.removeListener(ADD_TO_DRAFT_EVENT, callback);
    };
    /**
     * @param {string} draftId.
     */
    DraftStore.prototype.emitCreateDraft = function (draftId) {
        this.emit(CREATE_DRAFT_EVENT, draftId);
    };
    DraftStore.prototype.addCreateDraftListener = function (callback) {
        this.on(CREATE_DRAFT_EVENT, callback);
    };
    DraftStore.prototype.removeCreateDraftListener = function (callback) {
        this.removeListener(CREATE_DRAFT_EVENT, callback);
    };
    DraftStore.prototype.emitDeleteDraft = function () {
        this.emit(DELETE_DRAFT_EVENT);
    };
    DraftStore.prototype.addDeleteDraftListener = function (callback) {
        this.on(DELETE_DRAFT_EVENT, callback);
    };
    DraftStore.prototype.removeDeleteDraftListener = function (callback) {
        this.removeListener(DELETE_DRAFT_EVENT, callback);
    };
    /**
     * @param {string} draftId.
     * @param {Array.<{
     *   clipSelectionId: string,
     *   id: string,
     *   indexName: string
     * }>} clips.
     */
    DraftStore.prototype.emitRemoveFromDraft = function (draftId, clips) {
        this.emit(REMOVE_FROM_DRAFT_EVENT, draftId, clips);
    };
    DraftStore.prototype.addRemoveFromDraftListener = function (callback) {
        this.on(REMOVE_FROM_DRAFT_EVENT, callback);
    };
    DraftStore.prototype.removeRemoveFromDraftListener = function (callback) {
        this.removeListener(REMOVE_FROM_DRAFT_EVENT, callback);
    };
    /**
     * @param {string} draftId.
     */
    DraftStore.prototype.emitUpdateDraft = function (draftId) {
        this.emit(UPDATE_DRAFT_EVENT, draftId);
    };
    DraftStore.prototype.addUpdateDraftListener = function (callback) {
        this.on(UPDATE_DRAFT_EVENT, callback);
    };
    DraftStore.prototype.removeUpdateDraftListener = function (callback) {
        this.removeListener(UPDATE_DRAFT_EVENT, callback);
    };
    DraftStore.prototype.emitUpdateUserPreferences = function () {
        this.emit(UPDATE_USER_LIST_PREFERENCE_EVENT);
    };
    DraftStore.prototype.addUpdateUserPreferencesListener = function (callback) {
        this.on(UPDATE_USER_LIST_PREFERENCE_EVENT, callback);
    };
    DraftStore.prototype.removeUpdateUserPreferencesListener = function (callback) {
        this.removeListener(UPDATE_USER_LIST_PREFERENCE_EVENT, callback);
    };
    DraftStore.prototype.registerToActions = function (payload) {
        var _this = this;
        var action = payload.action;
        switch (action.actionType) {
            /**
             * @property {string} ActionTypes.ADD_TO_DRAFT_RESPONSE
             */
            case ActionTypes.ADD_TO_DRAFT_RESPONSE:
                if (action.json == null) {
                    if (action.errors.error === 'ErrorLimitDraft') {
                        this.emitError('ErrorLimitDraft');
                    }
                    else {
                        this.emitError('ADD_TO_DRAFT');
                    }
                }
                else {
                    var draftId = action.json.model.draftId;
                    // if (draft != null) {
                    //   const newClips = [
                    //     ...draft.clips,
                    //     ...clips.map(({ id }) => ClipStore.getClipById(id)),
                    //     ...clipSelections.reduce(
                    //       (previous, clipSelectionItem) => [
                    //         ...previous,
                    //         ...clipSelectionItem.clips.map(({ id }) =>
                    //           ClipStore.getClipById(id)
                    //         ),
                    //       ],
                    //       []
                    //     ),
                    //   ];
                    //   draft.setClips(newClips);
                    //   draft.setClipIds(newClips.map(({ id }) => id));
                    // }
                    this.emitAddToDraft(draftId);
                }
                break;
            /**
             * @property {string} ActionTypes.DELETE_DRAFT_RESPONSE
             */
            case ActionTypes.DELETE_DRAFT_RESPONSE:
            case ActionTypes.REMOVE_KIOSK_REVIEW_RESPONSE:
                if (action.json) {
                    var draftId = action.json.model.draftId;
                    if (action.json.model.Type === 'draft') {
                        this.draftCollection.delete(action.json.model.Id);
                    }
                    if (draftId) {
                        this.draftCollection.delete(draftId);
                    }
                    this.emitDeleteDraft();
                }
                else {
                    this.emitError('DELETE_DRAFT');
                }
                break;
            case ActionTypes.GET_DRAFT_USER_PREFERENCE_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.draftCollection.addUserPreferences(data);
                    this.emitChange('GET_DRAFT_USER_PREFERENCE');
                }
                else {
                    this.emitError('GET_DRAFT_USER_PREFERENCE');
                }
                break;
            /**
             * @property {string} ActionTypes.GET_DRAFT_RESPONSE
             */
            case ActionTypes.GET_CLIPS_FOR_DRAFT_RESPONSE:
            case ActionTypes.GET_DRAFT_RESPONSE: {
                this.dispatcher.waitFor([HierarchyStore.dispatchToken]);
                if (action.json == null ||
                    action.json.data == null ||
                    action.json.data.draft == null) {
                    this.emitError('GET_DRAFT');
                }
                else {
                    var _a = action.json.data.draft, clips = _a.clips, clipsSelections = _a.clipsSelections, createdDate = _a.createdDate, hierarchyId = _a.hierarchyId, id = _a.id, name = _a.name, indexName = _a.indexName, userAccount = _a.userAccount, isDeduplicated = _a.isDeduplicated, totalClips = _a.totalClips, groupBy = _a.groupBy, isWithoutHierarchy = _a.isWithoutHierarchy, hash = _a.hash;
                    var emitEvents = action.json.model.emitEvents;
                    var draft = this.getDraftById(id);
                    if (draft == null) {
                        draft = this.draftCollection.add({
                            createdDate: DateUtils.convertFromTimeStringToDateTime(createdDate),
                            hierarchyId: hierarchyId === 0 ? null : String(hierarchyId),
                            id: id,
                            name: name,
                            isDeduplicated: isDeduplicated,
                            clipsIndexation: clips,
                            indexName: indexName,
                            withoutHierarchy: isWithoutHierarchy,
                            hash: hash,
                        });
                        /* if (draft == null) {
                          this.emitError('GET_DRAFT');
                          return;
                        } */
                    }
                    //in case of update hierarchy of draft from organize review
                    if (hierarchyId == 0 ||
                        draft.hierarchyId === 'null' ||
                        draft.hierarchyId === null) {
                        draft.hierarchyId = null;
                        draft.hierarchy = action.json.data.draft.hierarchy;
                    }
                    if (Array.isArray(clips)) {
                        var clipsIds = clips.map(function (_a) {
                            var clipId = _a.id;
                            return clipId;
                        });
                        draft.setClipIds(clipsIds);
                        draft.setClips(clips);
                    }
                    // get all draft clips and filter duplicated ones
                    var allCLips = __spreadArray(__spreadArray([], (clips || []), true), (clipsSelections || []).reduce(function (acc, curr) { return __spreadArray(__spreadArray([], acc, true), curr.clips.filter(function (clip) {
                        return acc.findIndex(function (accClip) { return accClip.id === clip.id; }) === -1;
                    }), true); }, []), true);
                    draft.setGroupBy(groupBy);
                    draft.setAllClips(allCLips);
                    draft.setTotalClips(totalClips || 0);
                    draft.withoutHierarchy = isWithoutHierarchy;
                    draft.hierarchyId =
                        !hierarchyId && isWithoutHierarchy
                            ? null
                            : String(draft.hierarchyId);
                    draft.buildClipSelections(clipsSelections);
                    draft.setUserIds(userAccount);
                    this.emitChange(action.actionType, {
                        shouldUpdateFilter: emitEvents,
                        draftId: id,
                    });
                }
                break;
            }
            /**
             * @property {string} ActionTypes.GET_DRAFTS_RESPONSE
             */
            case ActionTypes.GET_DRAFTS_RESPONSE:
                if (action.json == null) {
                    this.emitError('GET_DRAFTS');
                }
                else {
                    if (action.json === 'ErrorLimitDraft') {
                        this.emitError('ErrorLimitDraft');
                    }
                    else {
                        var draftList = action.json.data;
                        if (draftList && Array.isArray(draftList.draftList)) {
                            draftList.draftList.forEach(function (draftItem) {
                                var hierarchyId = !draftItem.hierarchyId && draftItem.isWithoutHierarchy
                                    ? null
                                    : String(draftItem.hierarchyId);
                                _this.draftCollection.add(__assign(__assign({}, draftItem), { hierarchyId: hierarchyId }));
                            });
                        }
                    }
                    this.initialized = true;
                    this.emitChange();
                }
                break;
            /**
             * @property {string} ActionTypes.UPDATE_USER_LIST_PREFERENCE_RESPONSE
             */
            case ActionTypes.UPDATE_USER_LIST_PREFERENCE_RESPONSE:
                if (action.json == null) {
                    this.draftCollection.emptyUserPreferences();
                }
                else {
                    var data = action.json.data;
                    this.draftCollection.addUserPreferences(data);
                }
                this.emitChange('UPDATE_USER_LIST_PREFERENCE');
                break;
            /**
             * @property {string} ActionTypes.REMOVE_FROM_DRAFT_RESPONSE
             */
            case ActionTypes.REMOVE_FROM_DRAFT_RESPONSE:
                if (action.json == null) {
                    this.emitError('REMOVE_FROM_DRAFT');
                }
                else {
                    var _b = action.json.model, clips = _b.clips, draftId = _b.draftId;
                    var draft = this.getDraftById(draftId);
                    if (draft != null) {
                        draft.removeClips(clips);
                    }
                    this.emitRemoveFromDraft(draftId, clips);
                }
                break;
            /**
             * @property {string} ActionTypes.DUPLICATE_DRAFT_RESPONSE
             */
            case ActionTypes.DUPLICATE_DRAFT_RESPONSE:
                if (action.json == null || action.json.data == null) {
                    this.emitError('SAVE_DRAFT');
                }
                else {
                    var data = action.json.data;
                    var _c = data.draft, id = _c.id, clips = _c.clips, clipSelections = _c.clipSelections, name = _c.name, isDeduplicated = _c.isDeduplicated, createdDate = _c.createdDate, userIds = _c.userAcount, indexName = _c.indexName, hash = _c.hash, totalClips = _c.totalClips;
                    var hierarchyId = data.hierarchy ? data.hierarchy.id : null;
                    var draft = this.getDraftById(id);
                    if (draft) {
                        // The hierarchyId has to be set before clip selections
                        // because the "buildClipSelections" needs to have
                        // the right hierarchyId to build the right clip selections array.
                        if (hierarchyId)
                            draft.setHierarchyId(String(hierarchyId));
                        draft.setIsDeduplicated(isDeduplicated);
                        if (clips != null) {
                            draft.setClipIds(clips.map(function (_a) {
                                var clipId = _a.id;
                                return clipId;
                            }));
                        }
                        draft.setUserIds(userIds);
                        if (clipSelections != null) {
                            draft.buildClipSelections(clipSelections);
                        }
                        draft.setName(name);
                        this.emitUpdateDraft(id);
                    }
                    else {
                        draft = this.draftCollection.add({
                            createdDate: DateUtils.convertFromTimeStringToDateTime(createdDate),
                            hierarchyId: String(hierarchyId),
                            id: id,
                            name: name,
                            isDeduplicated: isDeduplicated,
                            indexName: indexName,
                            hash: hash,
                            totalClips: totalClips,
                        });
                        if (draft == null) {
                            this.emitError('SAVE_DRAFT');
                            return;
                        }
                        if (Array.isArray(clips)) {
                            draft.setClipIds(clips.map(function (_a) {
                                var clipId = _a.id;
                                return clipId;
                            }));
                        }
                        draft.setUserIds(userIds);
                        draft.buildClipSelections(clipSelections);
                        this.emitCreateDraft(id);
                    }
                }
                break;
            case ActionTypes.SAVE_DRAFT_RESPONSE:
                if (action.json == null || action.json.data == null) {
                    this.emitError('SAVE_DRAFT');
                }
                else {
                    var _d = action.json, _e = _d.data, createdDate = _e.createdDate, id = _e.id, indexName = _e.indexName, hash = _e.hash, _f = _d.model, clips = _f.clips, clipSelections = _f.clipSelections, hierarchyId = _f.hierarchyId, name = _f.name, userIds = _f.userIds, isDeduplicated = _f.isDeduplicated;
                    var draft = this.getDraftById(id);
                    if (draft) {
                        // The hierarchyId has to be set before clip selections
                        // because the "buildClipSelections" needs to have
                        // the right hierarchyId to build the right clip selections array.
                        draft.setHierarchyId(String(hierarchyId));
                        draft.setIsDeduplicated(isDeduplicated);
                        if (clips != null) {
                            draft.setClipIds(clips.map(function (_a) {
                                var clipId = _a.id;
                                return clipId;
                            }));
                        }
                        draft.setUserIds(userIds);
                        if (clipSelections != null) {
                            draft.buildClipSelections(clipSelections);
                        }
                        draft.setName(name);
                        this.emitUpdateDraft(id);
                    }
                    else {
                        var user = UserStore.getCurrentUser();
                        draft = this.draftCollection.add({
                            createdDate: createdDate,
                            hierarchyId: String(hierarchyId),
                            id: id,
                            name: name,
                            isDeduplicated: isDeduplicated,
                            indexName: indexName,
                            hash: hash,
                            author: "".concat(user.firstName, " ").concat(user.lastName),
                        });
                        if (draft == null) {
                            this.emitError('SAVE_DRAFT');
                            return;
                        }
                        if (Array.isArray(clips)) {
                            draft.setClipIds(clips.map(function (_a) {
                                var clipId = _a.id;
                                return clipId;
                            }));
                            draft.setClips(clips);
                        }
                        // get all draft clips and filter duplicated ones
                        var allCLips = __spreadArray(__spreadArray([], (clips || []), true), (clipSelections || []).reduce(function (acc, curr) { return __spreadArray(__spreadArray([], acc, true), curr.clips.filter(function (clip) {
                            return acc.findIndex(function (accClip) { return accClip.id === clip.id; }) === -1;
                        }), true); }, []), true);
                        draft.setAllClips(allCLips);
                        draft.setTotalClips(allCLips.length);
                        draft.setUserIds(userIds);
                        draft.buildClipSelections(clipSelections);
                        this.emitCreateDraft(id);
                    }
                }
                break;
            /**
             * @property {string} ActionTypes.UPDATE_DRAFT_RESPONSE
             */
            case ActionTypes.UPDATE_DRAFT_RESPONSE:
                if (action.json == null) {
                    this.emitError('UPDATE_DRAFT');
                }
                else {
                    var _g = action.json.model, hierarchyId = _g.hierarchyId, id = _g.id, name = _g.name, userIds = _g.userIds, isDeduplicated = _g.isDeduplicated;
                    var draft = this.getDraftById(id);
                    // The hierarchyId has to be set before
                    // because the "buildWithNewHierarchy" needs to have
                    // the right hierarchyId to build the draft with the right hierarchy.
                    draft.setHierarchyId(String(hierarchyId));
                    draft.setName(name);
                    draft.setUserIds(userIds);
                    draft.buildWithNewHierarchy();
                    draft.setIsDeduplicated(isDeduplicated);
                    this.emitUpdateDraft(id);
                }
                break;
            default:
                break;
        }
    };
    return DraftStore;
}(BaseStore));
export default new DraftStore();
