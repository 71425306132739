import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ModalBody, ModalFooter, ModalHeader } from '@commons/Modal';
import Button from '@commons/Button';
import { SweetPopupContext, initialSweetPopup, } from '@context/SweetPopupContext';
import { ModalStyled } from './SweetPopup.styles';
function SweetPopup() {
    var formatMessage = useIntl().formatMessage;
    var _a = useContext(SweetPopupContext), sweetPopup = _a.sweetPopup, setSweetPopup = _a.setSweetPopup;
    var isOpen = sweetPopup.isOpen, onClose = sweetPopup.onClose, title = sweetPopup.title, bodyMessage = sweetPopup.bodyMessage, okText = sweetPopup.okText, okClick = sweetPopup.okClick, showCancelButton = sweetPopup.showCancelButton, cancelText = sweetPopup.cancelText, cancelClick = sweetPopup.cancelClick, closeOnClickOutside = sweetPopup.closeOnClickOutside, closeOnEsc = sweetPopup.closeOnEsc, showClose = sweetPopup.showClose;
    var handleClose = function () {
        setSweetPopup(initialSweetPopup);
        if (onClose)
            onClose();
        if (cancelClick)
            cancelClick();
    };
    var handleClickOk = function () {
        if (onClose)
            onClose();
        if (okClick)
            okClick();
        setSweetPopup(initialSweetPopup);
    };
    var titleToRender = useMemo(function () {
        var isHtmlReg = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
        if (!title) {
            return formatMessage({ id: 'Global.areYouSure' });
        }
        else if (isHtmlReg.test(title)) {
            return _jsx("span", { dangerouslySetInnerHTML: { __html: title } });
        }
        return title;
    }, [title]);
    var messageToRender = useMemo(function () {
        var isHtmlReg = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
        if (!bodyMessage) {
            return null;
        }
        else if (isHtmlReg.test(bodyMessage)) {
            return _jsx("span", { dangerouslySetInnerHTML: { __html: bodyMessage } });
        }
        return bodyMessage;
    }, [bodyMessage]);
    var renderCancelButton = function () {
        return showCancelButton || cancelText || cancelClick ? (_jsx(Button, { onClick: handleClose, variant: "outline", title: cancelText || formatMessage({ id: 'Global.cancelAction' }), children: cancelText || formatMessage({ id: 'Global.cancelAction' }) })) : (_jsx(_Fragment, {}));
    };
    var returnOkClick = function () {
        if (okClick) {
            return (_jsx(Button, { onClick: handleClickOk, variant: "fill", color: "secondary", title: okText || formatMessage({ id: 'Global.continueAction' }), children: okText || formatMessage({ id: 'Global.continueAction' }) }));
        }
        return _jsx(_Fragment, {});
    };
    return (_jsxs(ModalStyled, { isOpen: isOpen, onCancel: handleClose, width: "small", height: "small", disableBackDropClick: !closeOnClickOutside, alert: true, closeOnEsc: closeOnEsc, children: [_jsx(ModalHeader, { showClose: showClose, onClose: handleClose, children: _jsx("span", { children: titleToRender }) }), _jsx(ModalBody, { children: messageToRender }), _jsxs(ModalFooter, { className: "", children: [renderCancelButton(), returnOkClick()] })] }));
}
export default SweetPopup;
