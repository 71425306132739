var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import LoaderBlock from './LoaderBlock';
var Wrapper = styled('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 14px;\n  margin: 2px 0;\n  background: #ffffff;\n  border: solid 1px #ccc;\n  border-radius: 4px;\n  > * {\n    margin: 4px 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 14px;\n  margin: 2px 0;\n  background: #ffffff;\n  border: solid 1px #ccc;\n  border-radius: 4px;\n  > * {\n    margin: 4px 0;\n  }\n"])));
function LoaderDraft() {
    return (_jsxs(Wrapper, { children: [_jsx(LoaderBlock, { fontSize: 12, maxWidth: 400 }), _jsx(LoaderBlock, { fontSize: 18 }), _jsx(LoaderBlock, { fontSize: 12, maxWidth: 300 })] }));
}
export default LoaderDraft;
var templateObject_1;
