var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
import { parseDate } from '@services/DateUtils';
import moment from 'moment';
var GET_KIOSK_EVENT = 'GET_KIOSK_EVENT';
var UPDATE_KIOSK_EVENT = 'UPDATE_KIOSK_EVENT';
var GET_ALL_KIOSKS_EVENT = 'GET_ALL_KIOSKS_EVENT';
var DELETE_INFOGRAPHY_EVENT = 'DELETE_INFOGRAPHY_EVENT';
var EDIT_INFOGRAPHY_EVENT = 'EDIT_INFOGRAPHY_EVENT';
var GET_INFOGRAPHY_BY_HASH = 'getInfographyByHash';
var CHANGE_DATES_EVENT = 'CHANGE_DATES_EVENT';
var KioskStore = /** @class */ (function (_super) {
    __extends(KioskStore, _super);
    function KioskStore() {
        var _this = _super.call(this) || this;
        _this.setKioskId = function (id) {
            _this.kioskId = id;
        };
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    KioskStore.prototype.initializeState = function () {
        this.totalRow = 0;
        this.users = [];
        this.KioskList = [];
        this.pdfTemplates = [];
        this.repestrie = '';
        this.kioskId = 0;
        this.kioskShowDraft = false;
        this.kioskShowReview = false;
        this.currentSkip = undefined;
        this.dates = {
            universalDateFrom: moment().startOf('day').format('YYYYMMDDHHmmss'),
            universalDateTo: moment().endOf('day').format('YYYYMMDDHHmmss'),
            dateFrom: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            dateTo: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        };
        this.logicalDate = 'today';
    };
    KioskStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    KioskStore.prototype.getList = function () {
        return this.KioskList.sort(
        // @ts-expect-error TODO: refactor this line to a proper sort by date
        function (a, b) { return new Date(b.creationDate) - new Date(a.creationDate); });
    };
    KioskStore.prototype.getIsShowDraft = function () {
        return this.kioskShowDraft;
    };
    KioskStore.prototype.getIsShowReview = function () {
        return this.kioskShowReview;
    };
    KioskStore.prototype.gettotalChild = function () {
        return this.totalRow;
    };
    KioskStore.prototype.getUsers = function () {
        return this.users;
    };
    KioskStore.prototype.getFeeders = function () {
        return this.feeders;
    };
    KioskStore.prototype.getPdfTemplate = function () {
        return this.pdfTemplates;
    };
    KioskStore.prototype.getReviewByHash = function (hash) {
        return this.KioskList.find(function (reviewItem) { return reviewItem.hash === hash; });
    };
    KioskStore.prototype.removeKoskItem = function (id) {
        this.KioskList = this.KioskList.filter(function (elm) { return elm.id !== id; });
        this.totalRow = this.totalRow - 1;
        this.emitGetAllKiosks();
    };
    KioskStore.prototype.getKioskId = function () {
        return this.kioskId;
    };
    KioskStore.prototype.addReview = function (_a) {
        var creationDate = _a.creationDate, hash = _a.hash, title = _a.title, indexName = _a.indexName, isDraft = _a.isDraft, type = _a.type, id = _a.id, url = _a.url;
        var review = this.getReviewByHash(hash);
        // const date = new Date(createdDate);
        if (!review) {
            var newReview = {
                creationDate: creationDate,
                hash: hash,
                title: title,
                indexName: indexName,
                isDraft: isDraft,
                type: type,
                id: id,
                url: url,
            };
            this.KioskList.push(newReview);
            return newReview;
        }
        return review;
    };
    KioskStore.setAccountLogo = function (review, accountLogo) {
        if (accountLogo === void 0) { accountLogo = ''; }
        if (typeof accountLogo === 'string') {
            review.accountLogo = accountLogo;
        }
    };
    KioskStore.setAuthor = function (review, author) {
        if (author === void 0) { author = ''; }
        if (typeof author === 'string') {
            review.author = author;
        }
    };
    KioskStore.setTitle = function (review, title) {
        if (title === void 0) { title = ''; }
        if (typeof title === 'string') {
            review.title = title;
        }
    };
    KioskStore.setGlobalPdf = function (review, globalPdf) {
        if (globalPdf === void 0) { globalPdf = 0; }
        if (typeof globalPdf === 'number') {
            review.globalPdf = globalPdf;
        }
    };
    KioskStore.setTotalClips = function (review, totalClips) {
        if (totalClips === void 0) { totalClips = 0; }
        if (typeof totalClips === 'number') {
            review.totalClips = totalClips;
        }
    };
    KioskStore.setIsWithoutHierarchy = function (review, withoutHierarchy) {
        if (withoutHierarchy === void 0) { withoutHierarchy = false; }
        if (typeof withoutHierarchy === 'boolean') {
            review.withoutHierarchy = withoutHierarchy;
        }
    };
    KioskStore.setPdfTemplateId = function (review, pdfTemplateId) {
        if (pdfTemplateId === void 0) { pdfTemplateId = null; }
        if (typeof pdfTemplateId === 'number') {
            review.pdfTemplateId = pdfTemplateId;
        }
    };
    KioskStore.hierarchyId = function (review, hierarchyId) {
        if (hierarchyId === void 0) { hierarchyId = null; }
        if (hierarchyId && typeof hierarchyId === 'number') {
            review.hierarchyId = hierarchyId;
        }
    };
    KioskStore.userAccountId = function (review, userAccountId) {
        review.userAccountId = userAccountId;
    };
    KioskStore.setGroupBy = function (review, groupBy) {
        review.groupBy = groupBy;
    };
    KioskStore.setClipAuthor = function (clip, author) {
        if (author === void 0) { author = ''; }
        if (typeof author === 'string') {
            clip.author = author;
        }
    };
    KioskStore.setTreeCollection = function (review, treeCollection) {
        if (treeCollection === void 0) { treeCollection = []; }
        treeCollection.forEach(function (tree) {
            if (tree.clips) {
                tree.clips.forEach(function (clip) {
                    clip.deliveredDate = parseDate(clip.deliveredDate);
                    clip.publicationDate = parseDate(clip.publicationDate);
                    KioskStore.setClipAuthor(clip, clip.author);
                });
            }
        });
        review.treeCollection = treeCollection;
    };
    // persit dates calendar
    KioskStore.prototype.setLogicalDate = function (logicalDate) {
        this.logicalDate = logicalDate;
        this.emitChangeDates();
    };
    KioskStore.prototype.getLogicalDate = function () {
        return this.logicalDate;
    };
    KioskStore.prototype.setDates = function (_a) {
        var dateFrom = _a.dateFrom, dateTo = _a.dateTo, universalDateFrom = _a.universalDateFrom, universalDateTo = _a.universalDateTo;
        this.dates = {
            universalDateFrom: universalDateFrom,
            universalDateTo: universalDateTo,
            dateFrom: dateFrom,
            dateTo: dateTo,
        };
        this.emitChangeDates();
    };
    KioskStore.prototype.getSearchDates = function () {
        return {
            from: this.dates.universalDateFrom || null,
            to: this.dates.universalDateTo || null,
            dateFrom: this.dates.dateFrom || null,
            dateTo: this.dates.dateTo || null,
        };
    };
    KioskStore.prototype.getDates = function () {
        return this.dates;
    };
    KioskStore.prototype.emitGetKiosk = function (kiosk) {
        this.emit(GET_KIOSK_EVENT, kiosk);
    };
    KioskStore.prototype.addGetKioskListener = function (callback) {
        this.on(GET_KIOSK_EVENT, callback);
    };
    KioskStore.prototype.removeGetKioskListener = function (callback) {
        this.removeListener(GET_KIOSK_EVENT, callback);
    };
    KioskStore.prototype.emitUpdateKioskELement = function (data) {
        this.emit(UPDATE_KIOSK_EVENT, data);
    };
    KioskStore.prototype.addUpdateKioskELementListener = function (callback) {
        this.on(UPDATE_KIOSK_EVENT, callback);
    };
    KioskStore.prototype.removeUpdateKioskELementListener = function (callback) {
        this.removeListener(UPDATE_KIOSK_EVENT, callback);
    };
    KioskStore.prototype.emitGetAllKiosks = function () {
        this.emit(GET_ALL_KIOSKS_EVENT);
    };
    KioskStore.prototype.addGetAllKiosksListener = function (callback) {
        this.on(GET_ALL_KIOSKS_EVENT, callback);
    };
    KioskStore.prototype.removeGetAllKiosksListener = function (callback) {
        this.removeListener(GET_ALL_KIOSKS_EVENT, callback);
    };
    // change dates selector
    KioskStore.prototype.emitChangeDates = function () {
        this.emit(CHANGE_DATES_EVENT);
    };
    KioskStore.prototype.addChangeDatesListener = function (callback) {
        this.on(CHANGE_DATES_EVENT, callback);
    };
    KioskStore.prototype.removeChangeDatesListener = function (callback) {
        this.removeListener(CHANGE_DATES_EVENT, callback);
    };
    KioskStore.prototype.emitDeleteInfographyFromKiosk = function () {
        this.emit(DELETE_INFOGRAPHY_EVENT);
    };
    KioskStore.prototype.addDeleteInfographyFromKiosk = function (callback) {
        this.on(DELETE_INFOGRAPHY_EVENT, callback);
    };
    KioskStore.prototype.removeDeleteInfographyFromKiosk = function (callback) {
        this.removeListener(DELETE_INFOGRAPHY_EVENT, callback);
    };
    KioskStore.prototype.emitEditInfographyFromKiosk = function () {
        this.emit(EDIT_INFOGRAPHY_EVENT);
    };
    KioskStore.prototype.addEditInfographyFromKiosk = function (callback) {
        this.on(EDIT_INFOGRAPHY_EVENT, callback);
    };
    KioskStore.prototype.removeEditInfographyFromKiosk = function (callback) {
        this.removeListener(EDIT_INFOGRAPHY_EVENT, callback);
    };
    KioskStore.prototype.emitGetInfographyByHash = function (data) {
        this.emit(GET_INFOGRAPHY_BY_HASH, data);
    };
    KioskStore.prototype.addGetInfographyByHash = function (callback) {
        this.on(GET_INFOGRAPHY_BY_HASH, callback);
    };
    KioskStore.prototype.removeGetInfographyByHash = function (callback) {
        this.removeListener(GET_INFOGRAPHY_BY_HASH, callback);
    };
    KioskStore.prototype.registerToActions = function (payload) {
        var _this = this;
        var _a, _b, _c;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_KIOSKS_RESPONSE:
                if (action.json) {
                    var _d = action.json.data, exportedItems = _d.exportedItems, users = _d.users, feeders = _d.feeders, pdfTemplates = _d.pdfTemplates, totalRow = _d.totalRow;
                    var SearchLibraryModel = action.json.model.SearchLibraryModel;
                    var Skip = SearchLibraryModel.Skip, ShowDraft = SearchLibraryModel.ShowDraft, ShowReview = SearchLibraryModel.ShowReview;
                    this.kioskShowDraft = ShowDraft;
                    this.kioskShowReview = ShowReview;
                    this.initialized = true;
                    if (!Skip) {
                        this.KioskList = [];
                        this.currentSkip = undefined;
                        this.emitLoading(true);
                    }
                    else {
                        this.currentSkip = Skip;
                    }
                    this.totalRow = totalRow;
                    this.pdfTemplates = pdfTemplates;
                    this.users = users;
                    this.feeders = feeders === null || feeders === void 0 ? void 0 : feeders.filter(Boolean);
                    if ((exportedItems === null || exportedItems === void 0 ? void 0 : exportedItems.length) > 0) {
                        exportedItems
                            .map(function (_a) {
                            var author = _a.author, creationDate = _a.creationDate, hash = _a.hash, title = _a.title, totalClips = _a.totalClips, indexName = _a.indexName, isDraft = _a.isDraft, type = _a.type, id = _a.id, url = _a.url, pdfTemplateId = _a.pdfTemplateId;
                            var review = _this.getReviewByHash(hash) ||
                                _this.addReview({
                                    creationDate: creationDate,
                                    hash: hash,
                                    title: title,
                                    indexName: indexName,
                                    isDraft: isDraft,
                                    type: type,
                                    id: id,
                                    url: url,
                                    pdfTemplateId: pdfTemplateId,
                                });
                            if (review) {
                                KioskStore.setAuthor(review, author);
                                KioskStore.setTotalClips(review, totalClips);
                                KioskStore.setPdfTemplateId(review, pdfTemplateId);
                                KioskStore.setTitle(review, title);
                            }
                            return review;
                        })
                            .filter(function (review) { return review; });
                    }
                    this.emitGetAllKiosks();
                }
                else {
                    this.emitError('GET_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.GET_KIOSK_NEW_DIGEST_RESPONSE:
            case ActionTypes.GET_DRAFT_REVIEW_RESPONSE:
            case ActionTypes.GET_KIOSK_REVIEW_RESPONSE:
                if (action.json) {
                    var _e = action.json.data, indexName = _e.indexName, type = _e.type, id = _e.id, accountLogo = _e.accountLogo, author = _e.author, creationDate = _e.creationDate, hash = _e.hash, title = _e.title, totalClips = _e.totalClips, treeCollection = _e.treeCollection, globalPdf = _e.globalPdf, pdfTemplateId = _e.pdfTemplateId, isDraft = _e.isDraft, hierarchyId = _e.hierarchyId, userAccountId = _e.userAccountId, groupBy = _e.groupBy, url = _e.url, withoutHierarchy = _e.withoutHierarchy;
                    var reviewFromStore = this.getReviewByHash(hash);
                    var review = reviewFromStore ||
                        this.addReview({
                            creationDate: creationDate,
                            hash: hash,
                            title: title,
                            indexName: indexName,
                            isDraft: isDraft,
                            type: type,
                            id: id,
                            url: url,
                        });
                    if (review) {
                        KioskStore.setAccountLogo(review, accountLogo);
                        KioskStore.setAuthor(review, author);
                        KioskStore.setTitle(review, title);
                        KioskStore.setGlobalPdf(review, globalPdf);
                        KioskStore.setTotalClips(review, totalClips);
                        KioskStore.setIsWithoutHierarchy(review, withoutHierarchy);
                        KioskStore.setTreeCollection(review, treeCollection);
                        KioskStore.setPdfTemplateId(review, pdfTemplateId);
                        KioskStore.hierarchyId(review, hierarchyId);
                        KioskStore.userAccountId(review, userAccountId);
                        KioskStore.setGroupBy(review, groupBy);
                        this.initialized = true;
                        this.emitGetKiosk(action.json.data);
                        // emit kiosk in case of adding a new review
                        if (!reviewFromStore)
                            this.emitGetAllKiosks();
                    }
                    else {
                        var message = typeof action.json.data === 'string' &&
                            // @ts-expect-error action.json.data is a string
                            action.json.data.length > 0
                            ? action.json.data
                            : 'generic';
                        this.emitError('GET_REVIEW', message);
                    }
                }
                else {
                    this.emitError('REMOVE_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.EDIT_KIOSK_REVIEW_RESPONSE:
            case ActionTypes.EDIT_KIOSK_NEW_DIGEST_RESPONSE:
            case ActionTypes.EDIT_KIOSK_INFOGRAPHY_RESPONSE:
            case ActionTypes.EDIT_KIOSK_DASHBORD_RESPONSE:
                if (action.json) {
                    this.emitUpdateKioskELement(action.json.data);
                }
                else {
                    this.emitError('REMOVE_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.DELETE_DRAFT_RESPONSE:
                if (action.json) {
                    this.removeKoskItem(action.json.model.draftId);
                }
                else {
                    this.emitError('DELETE_DRAFT');
                }
                break;
            case ActionTypes.SAVE_HIERARCHY_RESPONSE:
                if ((_a = action.json) === null || _a === void 0 ? void 0 : _a.data) {
                    var id_1 = action.json.model.id;
                    // remove treeCollection for reviews and drafts to refetch details
                    if (id_1) {
                        this.KioskList.forEach(function (item) {
                            if ((item === null || item === void 0 ? void 0 : item.hierarchyId) === Number(id_1)) {
                                item.treeCollection = null;
                            }
                        });
                    }
                }
                else {
                    this.emitError('SAVE_HIERARCHY');
                }
                break;
            case ActionTypes.REMOVE_KIOSK_REVIEW_RESPONSE:
            case ActionTypes.REMOVE_KIOSK_INFOGRAPHY_RESPONSE:
            case ActionTypes.REMOVE_KIOSK_DASHBORD_RESPONSE:
            case ActionTypes.REMOVE_KIOSK_NEWS_DIGEST_RESPONSE:
                if (action.json) {
                    this.removeKoskItem(action.json.data);
                }
                else {
                    this.emitError('REMOVE_KIOSKS_RESPONSE');
                }
                break;
            case ActionTypes.GET_DASHBOARD_SNAPSHOT_RESPONSE:
                if (action.json) {
                    this.initialized = false;
                }
                break;
            case ActionTypes.DELETE_INFOGRAPHY_RESPONSE:
                if (action.json) {
                    this.initialized = false;
                    this.emitDeleteInfographyFromKiosk();
                    this.removeKoskItem(action.json.data);
                }
                else {
                    this.emitError('DELETE_INFOGRAPHY_RESPONSE');
                }
                break;
            case ActionTypes.EDIT_INFOGRAPHY_RESPONSE:
                if ((_b = action.json) === null || _b === void 0 ? void 0 : _b.data) {
                    this.initialized = false;
                    this.emitEditInfographyFromKiosk();
                    this.emitUpdateKioskELement(action.json.data);
                }
                else {
                    this.emitError('EDIT_INFOGRAPHY_RESPONSE');
                }
                break;
            case ActionTypes.GET_INFOGRAPPHY_BY_HASH_RESPONSE:
                if ((_c = action.json) === null || _c === void 0 ? void 0 : _c.data) {
                    var data = action.json.data;
                    var infographyHash = __assign(__assign({}, data), { type: 'infography', blockInfographyRendered: data.blockInfographyRendered });
                    this.emitGetInfographyByHash(infographyHash);
                }
                else {
                    this.emitError('GET_INFOGRAPPHY_BY_HASH_RESPONSE');
                }
                break;
            default:
                break;
        }
    };
    return KioskStore;
}(BaseStore));
export default new KioskStore();
