var writeCSSVarsFromTheme = function (theme) {
    var getVariables = function (item, parent) {
        if (parent === void 0) { parent = ''; }
        if (typeof item === 'object') {
            return Object.entries(item)
                .map(function (_a) {
                var key = _a[0], value = _a[1];
                var tree = "".concat(parent, "-").concat(key);
                return getVariables(value, tree);
            })
                .join('');
        }
        return "-".concat(parent, ": ").concat(item, ";");
    };
    return getVariables(theme);
};
export default writeCSSVarsFromTheme;
