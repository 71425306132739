var initialTheme = {
    palette: {
        primary: {
            main: '#1e1e23',
            light: '#353535',
            dark: '#000000',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#503c7d',
            light: '#AB82E5',
            dark: '#9874CC',
            contrastText: '#ffffff',
        },
        default: {
            main: '#111111',
            light: '#333333',
            dark: '#000000',
            contrastText: '#ffffff',
        },
        disabled: {
            main: '#e0e0e0',
            contrastText: '#a9a9a9',
        },
        success: {
            main: '#2e7d32',
            light: '#4caf50',
            dark: '#1b5e20',
            contrastText: '#ffffff',
        },
        danger: {
            main: '#e43411',
            light: '#ef4a2a',
            dark: '#c8391c',
            contrastText: '#ffffff',
        },
        warning: {
            main: '#ED6C02',
            light: '#ff9800',
            dark: '#e65100',
            contrastText: '#ffffff',
        },
        error: {
            main: '#d32f2f',
            light: '#ef5350',
            dark: '#c62828',
            contrastText: '#ffffff',
        },
        info: {
            main: '#2979FF',
            light: '#448AFF',
            dark: '#2962FF',
            contrastText: '#ffffff',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
        common: {
            border1: '#d0d0d0', // for containers
            border2: '#979797', // for inputs mainly
            background1: '#ffffff', // for the tag body
            background2: '#f7f7f7', // for the fields mainly
            paperBg1: '#F3F1F8', // for the common background
            bgHover1: '#F3F1F8',
        },
    },
    typography: {
        h1: {
            fontFamily: 'KantarBrown',
            fontWeight: 300,
            fontSize: '2rem',
            lineHeight: '2.4rem',
        },
        h2: {
            fontFamily: 'KantarBrown',
            fontWeight: 700,
            fontSize: '1.25rem',
            lineHeight: '1.5rem',
        },
        h3: {
            fontFamily: 'KantarBrown',
            fontWeight: 700,
            fontSize: '1.125rem',
            lineHeight: '1.375rem',
        },
        h4: {
            fontFamily: 'KantarBrown',
            fontWeight: 700,
            fontSize: '1rem',
            lineHeight: '1.5rem',
        },
        h5: {
            fontFamily: 'KantarBrown',
            fontWeight: 'bold',
            fontSize: '0.85rem',
            lineHeight: '1.1875rem',
        },
        subtitle1: {
            fontFamily: 'KantarBrown',
            fontWeight: 300,
            fontSize: '1.25rem',
            lineHeight: '1.5rem',
        },
        subtitle2: {
            fontFamily: 'KantarBrown',
            fontWeight: 400,
            fontSize: '1.125rem',
            lineHeight: '1.371rem',
        },
        subtitle3: {
            fontFamily: 'KantarBrown',
            fontWeight: 300,
            fontSize: '0.85rem',
            lineHeight: '1.371rem',
        },
        body1: {
            fontFamily: 'KantarBrown',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '1.1875rem',
        },
        body2: {
            fontFamily: 'KantarBrown',
            fontWeight: 300,
            fontSize: '0.875rem',
            lineHeight: '1.125rem',
        },
        info1: {
            fontFamily: 'KantarBrown',
            fontWeight: 400,
            fontSize: '0.85rem',
            lineHeight: '1rem',
        },
        info2: {
            fontFamily: 'KantarBrown',
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: '1rem',
        },
        title1: {
            fontFamily: 'KantarBrown',
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: '0.75rem',
        },
    },
    spacing: {
        none: '0',
        'xxx-small': '0.125rem',
        'xx-small': '0.25rem',
        'x-small': '0.5rem',
        'x-medium': '0.35rem',
        small: '0.75rem',
        medium: '1rem',
        large: '1.5rem',
        'x-large': '2rem',
        'xx-large': '3rem',
        'xxx-large': '4rem',
    },
    font: {
        'size-x-small': '0.625rem',
        'size-small': '0.75rem',
        'size-medium': '0.8125rem',
        'size-medium-a': '0.875rem',
        'size-medium-b': '0.9rem',
        'size-large': '1rem',
        'size-x-large': '1.25rem',
        'size-xx-large': '1.75rem',
        'size-xxx-large': '2.25rem',
    },
    borderRadius: {
        sm: '4px',
        md: '7px',
        lg: '12px',
    },
    shape: {
        mobileNavbarHeight: 60,
        screenNavbarWidth: 60,
    },
    breakPoints: {
        xs: 600,
        sm: 768,
        md: 960,
        lg: 1280,
        xl: 1920,
    },
};
export { initialTheme };
