var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import ActionTypes from 'constants/actionTypes';
import BaseStore from 'stores/BaseStore';
import SearchStore from 'stores/SearchStore';
import { lowerCaseKeys } from 'services/AppUtils';
var SAVE_EVENT = 'save';
var SAVE_INSIGHTS_FILTERS_USER = 'SaveInsightFiltersUser';
var GET_INSIGHT_FACET_ITEMS = 'GetInsightFacetItems';
var FACETS_UPDATE_EVENT = 'UpdateFacetsEvent';
/**
 * Class representing a FacetItem.
 * @property {string} id
 * @property {boolean} isDisplayInSearch
 * @property {string} name
 * @property {number} numberOfClips
 */
var FacetItem = /** @class */ (function () {
    /**
     * Create a FacetItem.
     * @param {object} FacetItem
     * @param {string} FacetItem.id
     * @param {boolean} FacetItem.isDisplayInSearch
     * @param {string} FacetItem.name
     * @param {number} FacetItem.numberOfClips
     */
    function FacetItem(_a) {
        var id = _a.id, isDisplayInSearch = _a.isDisplayInSearch, name = _a.name, numberOfClips = _a.numberOfClips;
        this.id = id;
        this.isDisplayInSearch = isDisplayInSearch;
        this.name = name;
        this.numberOfClips = numberOfClips;
    }
    return FacetItem;
}());
/**
 * Class representing a FacetGroup.
 * @property {FacetItem[]} facetItems
 * @property {string} id
 * @property {number} position
 */
var FacetGroup = /** @class */ (function () {
    /**
     * Create a FacetGroup.
     * @param {object} FacetGroup
     * @param {string} FacetGroup.id
     * @param {number} FacetGroup.position
     */
    function FacetGroup(_a) {
        var id = _a.id, filterFacetPosition = _a.filterFacetPosition, nameFacet = _a.nameFacet, icon = _a.icon, filterSourceType = _a.filterSourceType, bundle = _a.bundle, filterScope = _a.filterScope, dimensionId = _a.dimensionId, filterElasticFieldName = _a.filterElasticFieldName, filterElasticFieldPath = _a.filterElasticFieldPath, isTranslated = _a.isTranslated, filterFacetSelected = _a.filterFacetSelected;
        // Default values
        this.facetItems = [];
        this.hasAllFacetItems = false;
        this.id = id;
        this.position = filterFacetPosition;
        this.nameFacet = nameFacet;
        this.icon = icon;
        this.filterSourceType = filterSourceType;
        this.bundle = bundle;
        this.filterScope = filterScope;
        this.dimensionId = dimensionId;
        this.filterElasticFieldName = filterElasticFieldName;
        this.filterElasticFieldPath = filterElasticFieldPath;
        this.isTranslated = isTranslated;
        this.filterFacetPosition = filterFacetPosition;
        this.filterFacetSelected = filterFacetSelected;
    }
    /**
     * Check if the number of clips of a facet item is valid.
     * @param {number} numberOfClips
     * @return {boolean}
     */
    FacetGroup.checkNumberOfClips = function (numberOfClips) {
        return typeof numberOfClips === 'number' && numberOfClips >= 0;
    };
    /**
     * Check if the name of a facet item is valid.
     * @param {string} name
     * @return {boolean}
     */
    FacetGroup.checkName = function (name) {
        return typeof name === 'string' && name.length > 0;
    };
    /**
     * Check if the value of a facet item is valid.
     * @param {string} value
     * @return {boolean}
     */
    FacetGroup.checkValue = function (value) {
        return typeof value === 'string' && value.length > 0;
    };
    FacetGroup.checkCode = function (value) {
        return value && typeof value === 'string';
    };
    /**
     * Add facet items in the facet group.
     * @param {Array.<{
     *   count: number,
     *   text: string,
     *   value: string,
     * }>} facetItems - FacetItems object from back-end.
     * @param {boolean} [allFacetItems] - if we add the completeness of facetItems
     */
    FacetGroup.prototype.addFacetItems = function (facetItems, allFacetItems) {
        if (allFacetItems === void 0) { allFacetItems = false; }
        if (allFacetItems) {
            this.hasAllFacetItems = true;
        }
        if (Array.isArray(facetItems)) {
            this.facetItems = __spreadArray([], facetItems.reduce(function (previous, _a) {
                var count = _a.count, text = _a.text, value = _a.value;
                if (FacetGroup.checkNumberOfClips(count) &&
                    FacetGroup.checkName(text) &&
                    FacetGroup.checkValue(value)) {
                    var array = __spreadArray(__spreadArray([], previous, true), [
                        new FacetItem({
                            id: value,
                            isDisplayInSearch: !allFacetItems,
                            name: text,
                            numberOfClips: count,
                        }),
                    ], false);
                    return array;
                }
                return previous;
            }, []), true).sort(function (a, b) { return b.numberOfClips - a.numberOfClips; });
        }
        return this.facetItems;
    };
    /**
     * Get a facet item by its ID.
     * @param {string} facetItemId
     * @return {FacetItem}
     */
    FacetGroup.prototype.getFacetItemById = function (facetItemId) {
        return this.facetItems.find(function (_a) {
            var id = _a.id;
            return id === facetItemId;
        });
    };
    /**
     * Replace facet items of the facet group.
     * @param {FacetItem[]} facetItems
     */
    FacetGroup.prototype.replaceFacetItems = function (facetItems) {
        this.facetItems = facetItems;
    };
    /**
     * Update the position of the facet group.
     * @param {number} position
     * @return {FacetGroup}
     */
    FacetGroup.prototype.updatePosition = function (position) {
        if (FacetGroupCollection.checkPosition(position)) {
            this.filterFacetPosition = position;
        }
    };
    FacetGroup.prototype.updateFacetSelected = function (selected) {
        this.filterFacetSelected = selected;
    };
    FacetGroup.prototype.updateIcon = function (icon) {
        this.icon = icon;
    };
    FacetGroup.prototype.updateFilterSourceType = function (filterSourceType) {
        this.filterSourceType = filterSourceType;
    };
    FacetGroup.prototype.updateBundle = function (bundle) {
        this.bundle = bundle;
    };
    FacetGroup.prototype.updateFilterScope = function (filterScope) {
        this.filterScope = filterScope;
    };
    FacetGroup.prototype.updateDimensionId = function (dimensionId) {
        this.dimensionId = dimensionId;
    };
    FacetGroup.prototype.updateFilterElasticFieldName = function (filterElasticFieldName) {
        this.filterElasticFieldName = filterElasticFieldName;
    };
    FacetGroup.prototype.updateFilterElasticFieldPath = function (filterElasticFieldPath) {
        this.filterElasticFieldPath = filterElasticFieldPath;
    };
    FacetGroup.prototype.updateIsTranslated = function (isTranslated) {
        this.isTranslated = isTranslated;
    };
    FacetGroup.prototype.updateFilterFacetPosition = function (filterFacetPosition) {
        this.filterFacetPosition = filterFacetPosition;
    };
    FacetGroup.prototype.updateFilterFacetSelected = function (filterFacetSelected) {
        this.filterFacetSelected = filterFacetSelected;
    };
    return FacetGroup;
}());
/**
 * Class representing a FacetGroupCollection.
 */
var FacetGroupCollection = /** @class */ (function () {
    /**
     * Create a FacetGroupCollection.
     * @property {FacetGroup[]} collection
     */
    function FacetGroupCollection() {
        // Default values
        this.collection = [];
    }
    /**
     * Check if the ID is valid.
     * @param {string} id
     * @return {boolean}
     */
    FacetGroupCollection.checkId = function (id) {
        return typeof id === 'string' && id.length > 0;
    };
    /**
     * Check if the position is valid.
     * @param {number} position
     * @return {boolean}
     */
    FacetGroupCollection.checkPosition = function (position) {
        return typeof position === 'number' && position >= 0;
    };
    /**
     * Add a facet group in the collection.
     * @param {object} facetGroup
     * @param {string} facetGroup[].id
     * @param {number} facetGroup[].position
     * @return {FacetGroup}
     */
    FacetGroupCollection.prototype.add = function (facetGroup) {
        var id = facetGroup.id, filterElasticFieldPath = facetGroup.filterElasticFieldPath, filterFacetPosition = facetGroup.filterFacetPosition;
        var facetId = id || filterElasticFieldPath;
        var advancedFilterMenu = SearchStore.advancedFilterMenu;
        if (FacetGroupCollection.checkId(facetId) &&
            FacetGroupCollection.checkPosition(filterFacetPosition)) {
            var maybeExistingFacetGroup = this.getFacetGroupById(facetId);
            if (!maybeExistingFacetGroup) {
                var newFacetGroup = new FacetGroup(facetGroup);
                this.collection = __spreadArray(__spreadArray([], this.collection, true), [newFacetGroup], false);
                return newFacetGroup;
            }
            // TODO: position mangement should be fixed ASAP
            if (filterFacetPosition > 0) {
                maybeExistingFacetGroup.filterFacetPosition = filterFacetPosition;
            }
            if (!facetGroup.icon) {
                var entry = advancedFilterMenu.find(function (afm) { return afm.id === facetId; });
                if (entry) {
                    maybeExistingFacetGroup.updateIcon(entry.icon);
                }
            }
            else {
                maybeExistingFacetGroup.updateIcon(facetGroup.icon);
            }
            maybeExistingFacetGroup.updateFilterSourceType(facetGroup.filterSourceType);
            maybeExistingFacetGroup.updateBundle(facetGroup.bundle);
            maybeExistingFacetGroup.updateFilterScope(facetGroup.filterScope);
            maybeExistingFacetGroup.updateDimensionId(facetGroup.dimensionId);
            maybeExistingFacetGroup.updateFilterElasticFieldName(facetGroup.filterElasticFieldName);
            maybeExistingFacetGroup.updateFilterElasticFieldPath(facetGroup.filterElasticFieldPath);
            maybeExistingFacetGroup.updateIsTranslated(facetGroup.isTranslated);
            maybeExistingFacetGroup.updateFilterFacetSelected(facetGroup.filterFacetSelected);
            maybeExistingFacetGroup.updateFilterFacetPosition(facetGroup.filterFacetPosition);
            return maybeExistingFacetGroup;
        }
        return null;
    };
    /**
     * Get the collection.
     */
    FacetGroupCollection.prototype.get = function () {
        return this.collection.sort(function (a, b) { return a.position - b.position; });
    };
    /**
     * Get a facet group by its ID.
     * @param {string} facetGroupId
     * @return {FacetGroup}
     */
    FacetGroupCollection.prototype.getFacetGroupById = function (facetGroupId) {
        var found = this.collection.find(function (_a) {
            var id = _a.id, filterElasticFieldPath = _a.filterElasticFieldPath;
            return id === facetGroupId || filterElasticFieldPath === facetGroupId;
        });
        return found;
    };
    FacetGroupCollection.prototype.getFacetGroupByDimensionId = function (facetGroupId) {
        var found = this.collection.find(function (_a) {
            var dimensionId = _a.dimensionId;
            return dimensionId === facetGroupId;
        });
        return found;
    };
    /**
     * Reset the collection.
     */
    FacetGroupCollection.prototype.reset = function () {
        this.collection = [];
    };
    return FacetGroupCollection;
}());
var FacetStore = /** @class */ (function (_super) {
    __extends(FacetStore, _super);
    function FacetStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    FacetStore.prototype.initializeState = function () {
        this.facetGroupCollection = new FacetGroupCollection();
    };
    /**
     * Reset the store.
     */
    FacetStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(FacetStore.prototype, "facets", {
        /**
         * Get the collection of facet groups.
         * @return {FacetGroup[]}
         */
        get: function () {
            return this.facetGroupCollection.get();
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Get facet group by its ID.
     * @param {string} facetGroupId
     * @return {FacetGroup}
     */
    FacetStore.prototype.getFacetGroupById = function (facetGroupId) {
        return this.facetGroupCollection.getFacetGroupById(facetGroupId);
    };
    FacetStore.prototype.getAllFilters = function () {
        return this.allFiltersList;
    };
    FacetStore.prototype.getFilterById = function (facetGroupId) {
        if (this.allFiltersList) {
            return this.allFiltersList.find(function (_a) {
                var id = _a.id, filterElasticFieldPath = _a.filterElasticFieldPath;
                return id === facetGroupId || filterElasticFieldPath === facetGroupId;
            });
        }
        return undefined;
    };
    FacetStore.prototype.emitSave = function () {
        this.emit(SAVE_EVENT);
    };
    FacetStore.prototype.addSaveListener = function (callback) {
        this.on(SAVE_EVENT, callback);
    };
    FacetStore.prototype.removeSaveListener = function (callback) {
        this.removeListener(SAVE_EVENT, callback);
    };
    FacetStore.prototype.emitSaveInsightFiltersUser = function () {
        this.emit(SAVE_INSIGHTS_FILTERS_USER);
    };
    FacetStore.prototype.addSaveInsightFiltersUser = function (callback) {
        this.on(SAVE_INSIGHTS_FILTERS_USER, callback);
    };
    FacetStore.prototype.removeSaveInsightFiltersUser = function (callback) {
        this.removeListener(SAVE_INSIGHTS_FILTERS_USER, callback);
    };
    FacetStore.prototype.emitGetInsightFacetItemsCallback = function () {
        this.emit(GET_INSIGHT_FACET_ITEMS);
    };
    FacetStore.prototype.addGetInsightFacetItemsListener = function (callback) {
        this.on(GET_INSIGHT_FACET_ITEMS, callback);
    };
    FacetStore.prototype.removeGetInsightFacetItemsListener = function (callback) {
        this.removeListener(GET_INSIGHT_FACET_ITEMS, callback);
    };
    FacetStore.prototype.emitFacetsUpdate = function () {
        this.emit(FACETS_UPDATE_EVENT);
    };
    FacetStore.prototype.addFacetsUpdateListener = function (callback) {
        this.on(FACETS_UPDATE_EVENT, callback);
    };
    FacetStore.prototype.removeFacetsUpdateListener = function (callback) {
        this.removeAllListeners(FACETS_UPDATE_EVENT, callback);
    };
    FacetStore.prototype.registerToActions = function (payload) {
        var _this = this;
        var action = payload.action;
        switch (action.actionType) {
            /**
             * @property {string} ActionTypes.GET_FACET_ITEMS_RESPONSE
             */
            case ActionTypes.GET_INSIGHT_FACET_ITEMS_RESPONSE:
                if (action.json == null || action.json.data == null) {
                    this.emitError('GET_INSIGHT_FACET_ITEMS');
                }
                else {
                    var _a = action.json, data = _a.data, filterElasticFieldPath = _a.model.selectedFacetFilter.filterElasticFieldPath;
                    var facetGroup = this.getFacetGroupById(filterElasticFieldPath);
                    if (facetGroup == null) {
                        facetGroup = this.facetGroupCollection.add({
                            filterElasticFieldPath: filterElasticFieldPath,
                            filterFacetPosition: 0,
                            filterFacetSelected: false,
                        });
                    }
                    facetGroup.addFacetItems(data, true);
                    this.emitGetInsightFacetItemsCallback();
                    this.emitFacetsUpdate();
                    this.emitChange();
                }
                break;
            case ActionTypes.UPDATE_FILTER_REQUEST: {
                this.loading = true;
                var shouldGetClips = SearchStore.getShouldGetClips();
                if (shouldGetClips) {
                    this.emitLoading(true);
                }
                break;
            }
            case ActionTypes.UPDATE_FILTER_RESPONSE:
                if (action.json == null || action.json.data == null) {
                    this.emitError('GET_FACETS');
                }
                else {
                    var _b = action.json, data = _b.data, _c = _b.model, model = _c === void 0 ? {} : _c;
                    var allFilters = data.allFilters;
                    var facets_1 = data.facets, totalArticles = data.totalArticles;
                    this.facetGroupCollection.collection.splice(0, this.facetGroupCollection.collection.length);
                    if (!model.loadAllFilters) {
                        allFilters = this.facetGroupCollection.collection;
                    }
                    if (allFilters == null ||
                        allFilters.length === 0 ||
                        Object.keys(facets_1).length === 0 ||
                        totalArticles === 0) {
                        this.facetGroupCollection.reset();
                    }
                    else {
                        this.allFiltersList = allFilters;
                        this.facetGroupCollection.collection.forEach(function (fgc) {
                            fgc.filterFacetPosition = 0;
                        });
                        allFilters.forEach(function (facetGroupId) {
                            var facetItems = [];
                            var nameFacet = null;
                            Object.keys(facets_1).forEach(function (item) {
                                if (item === facetGroupId.filterElasticFieldPath) {
                                    // eslint-disable-next-line
                                    facetItems = facets_1[item].facetItems;
                                    // eslint-disable-next-line
                                    nameFacet = facets_1[item].nameFacet;
                                }
                            });
                            var _a = lowerCaseKeys(facetGroupId), filterElasticFieldPath = _a.filterElasticFieldPath, filterElasticFieldId = _a.filterElasticFieldId, icon = _a.icon, filterSourceType = _a.filterSourceType, bundle = _a.bundle, filterScope = _a.filterScope, forWidgetFilter = _a.forWidgetFilter, filterFacetPosition = _a.filterFacetPosition, forAdvancedFacetFilter = _a.forAdvancedFacetFilter, filterElasticFieldName = _a.filterElasticFieldName, filterFacetSelected = _a.filterFacetSelected, forFacetFilter = _a.forFacetFilter, dimensionId = _a.dimensionId, isTranslated = _a.isTranslated;
                            if (!facetItems.length && nameFacet !== 'clipSelections.name')
                                return;
                            var facetGroup = _this.facetGroupCollection.add({
                                id: filterElasticFieldPath,
                                filterFacetPosition: filterFacetPosition,
                                filterFacetSelected: filterFacetSelected,
                                filterElasticFieldId: filterElasticFieldId,
                                nameFacet: nameFacet || filterElasticFieldPath,
                                icon: icon,
                                filterSourceType: filterSourceType,
                                bundle: bundle,
                                filterElasticFieldName: filterElasticFieldName,
                                filterScope: filterScope,
                                forWidgetFilter: forWidgetFilter,
                                forAdvancedFacetFilter: forAdvancedFacetFilter,
                                forFacetFilter: forFacetFilter,
                                filterElasticFieldPath: filterElasticFieldPath,
                                dimensionId: dimensionId,
                                isTranslated: isTranslated,
                            });
                            facetGroup.addFacetItems(facetItems, false);
                            facetGroup.forFacetFilter = forFacetFilter;
                            facetGroup.forWidgetFilter = forWidgetFilter;
                            facetGroup.forAdvancedFacetFilter = forAdvancedFacetFilter;
                        });
                    }
                    this.initialized = true;
                    this.emitFacetsUpdate();
                    this.emitChange();
                }
                break;
            case ActionTypes.SAVE_INSIGHTS_FILTERS_USER_RESPONSE:
                if (action.json != null) {
                    this.emitChange();
                    this.emitSave();
                    this.emitSaveInsightFiltersUser();
                }
                break;
            default:
                break;
        }
    };
    return FacetStore;
}(BaseStore));
export default new FacetStore();
