import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Wrapper } from './Button.styles';
var propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseDown: PropTypes.func,
    onDoubleClick: PropTypes.func,
    size: PropTypes.oneOf(['sm', 'lg']),
    title: PropTypes.string,
    onMouseOut: PropTypes.func,
    onBlur: PropTypes.func,
    type: PropTypes.oneOf([
        'close',
        'danger',
        'default',
        'icon',
        'info',
        'link',
        'primary',
        'secondary',
        'success',
        'submit',
        'warning',
    ]),
    ariaLabel: PropTypes.string,
};
var defaultProps = {
    children: null,
    className: '',
    disabled: false,
    id: '',
    name: 'button',
    onClick: function () { },
    onMouseEnter: function () { },
    onBlur: function () { },
    onMouseLeave: function () { },
    onMouseDown: function () { },
    onDoubleClick: function () { },
    onMouseOut: function () { },
    size: null,
    title: '',
    type: null,
    ariaLabel: '',
};
var Button = forwardRef(function (_a, ref) {
    var id = _a.id, children = _a.children, className = _a.className, disabled = _a.disabled, onClick = _a.onClick, name = _a.name, size = _a.size, type = _a.type, title = _a.title, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, onMouseDown = _a.onMouseDown, onDoubleClick = _a.onDoubleClick, onMouseOut = _a.onMouseOut, onBlur = _a.onBlur, ariaLabel = _a.ariaLabel;
    var handleClick = function (event) { return onClick(event); };
    var buttonClassNames = classnames('btn', type && "btn--".concat(type), size && "btn--".concat(size), className);
    return (_jsx(Wrapper, { className: buttonClassNames, disabled: disabled, id: id, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onMouseDown: onMouseDown, onDoubleClick: onDoubleClick, onMouseOut: onMouseOut, onBlur: onBlur, onClick: handleClick, type: type === 'submit' ? 'submit' : 'button', title: title, name: name, ref: ref, children: children }));
});
Button.displayName = 'Button';
Button.defaultProps = defaultProps;
Button.propTypes = propTypes;
export default Button;
