var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useRef } from 'react';
import { ClickAwayListener } from '@commons/.';
import { PopperStyled } from './Popover.styles';
var Popover = forwardRef(function (_a, ref) {
    var children = _a.children, onClickAway = _a.onClickAway, open = _a.open, anchorEl = _a.anchorEl, restProps = __rest(_a, ["children", "onClickAway", "open", "anchorEl"]);
    // close when switching route
    var lastPathRef = useRef('');
    var currentPathName = window.location.href;
    useEffect(function () {
        if (open &&
            lastPathRef.current &&
            lastPathRef.current !== currentPathName) {
            onClickAway();
        }
        lastPathRef.current = currentPathName;
    }, [open, currentPathName]);
    return (_jsx(ClickAwayListener, { onClickAway: function (e) {
            // @ts-expect-error target
            if (anchorEl && !anchorEl.contains(e.target)) {
                onClickAway();
            }
        }, children: _jsx(PopperStyled, __assign({ ref: ref, open: open, anchorEl: anchorEl }, restProps, { children: children })) }));
});
Popover.displayName = 'Popover';
export default Popover;
