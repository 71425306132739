var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { IoIosArrowBack } from 'react-icons/io';
import Typography from '@commons/Typography';
import Button from '@commons/Button';
import UserStore from '@stores/UserStore';
import { Wrapper, GlobalHeight } from './PageHeader.styles';
function PageHeader(_a) {
    var children = _a.children, title = _a.title, onClickBack = _a.onClickBack, _b = _a.withBorder, withBorder = _b === void 0 ? false : _b, backTo = _a.backTo, _c = _a.hasLogo, hasLogo = _c === void 0 ? false : _c, _d = _a.spacing, spacing = _d === void 0 ? 'medium' : _d, className = _a.className, centerItem = _a.centerItem, logo = _a.logo, restProps = __rest(_a, ["children", "title", "onClickBack", "withBorder", "backTo", "hasLogo", "spacing", "className", "centerItem", "logo"]);
    var _e = useState(''), accountLogo = _e[0], setAccountLogo = _e[1];
    var urlRef = useRef('');
    useEffect(function () {
        var onGetUser = function () {
            var user = UserStore.getCurrentUser();
            setAccountLogo(user.accountLogo);
            urlRef.current = user.accountLogo;
        };
        var updateNewAvtar = function (data) {
            var _a, _b, _c;
            if ((data === null || data === void 0 ? void 0 : data.uploadedFiles) && UserStore.isInit()) {
                var newUserLogo = '';
                var user = UserStore.getCurrentUser();
                if ((_a = urlRef.current) === null || _a === void 0 ? void 0 : _a.includes('?')) {
                    newUserLogo = (_c = (_b = user.accountLogo) === null || _b === void 0 ? void 0 : _b.split('?')) === null || _c === void 0 ? void 0 : _c[0];
                }
                else {
                    newUserLogo = user.accountLogo;
                }
                setAccountLogo(newUserLogo);
                urlRef.current = newUserLogo;
            }
        };
        if (UserStore.isInit()) {
            var user = UserStore.getCurrentUser();
            setAccountLogo(user.accountLogo);
            urlRef.current = user.accountLogo;
        }
        UserStore.addGetCurrentUserListener(onGetUser);
        UserStore.addChangeUserBrandUserListener(updateNewAvtar);
        return function () {
            UserStore.removeUserBrandUserListener(updateNewAvtar);
            UserStore.removeGetCurrentUserListener(onGetUser);
        };
    }, []);
    return (_jsxs(Wrapper, __assign({ "$withBorder": withBorder, "$hasLogo": Boolean(hasLogo && (accountLogo || logo)), "$hasAction": Boolean(children), className: "page-header ".concat(className || '') }, restProps, { children: [_jsx(GlobalHeight, { "$hasAction": Boolean(children) }), onClickBack && (_jsx("div", { className: "page-header__back", children: _jsx(Button, { onClick: onClickBack, variant: "text", size: "small", title: "Back to previous page button", "aria-label": "Back to previous page button", leftIcon: _jsx(IoIosArrowBack, { size: "16" }), children: _jsx("span", { className: "page-header__back-title", children: backTo || _jsx(FormattedMessage, { id: "Global.back" }) }) }) })), typeof title === 'string' ? (_jsx(Typography, { className: "page-header__title", variant: "h1", children: title })) : (_jsx("div", { className: "page-header__title", children: title })), (centerItem || hasLogo) && (_jsx("div", { className: "page-header__center", children: centerItem || (_jsx("img", { className: "page-header__image", src: accountLogo || logo, alt: "account logo" })) })), _jsx("div", { className: "page-header__actions", children: children })] })));
}
export default PageHeader;
