var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from 'styled-components';
import Box from '@mui/material/Box';
export var Wrapper = styled(Box)(function (props) {
    // filter keys that are not CSS properties
    var propsFiltered = Object.keys(__assign({}, props)).reduce(function (acc, curr) {
        var _a;
        if (!['forwardedRef', 'children', 'forwardedComponent', 'theme'].includes(curr)) {
            return __assign(__assign({}, acc), (_a = {}, _a[curr] = props[curr], _a));
        }
        return __assign({}, acc);
    }, {});
    return propsFiltered;
});
