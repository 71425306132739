function getDefaultGridWidgetSettings(props) {
    var widgetOptionsDictionary = props.widget.widgetOptionsDictionary;
    return {
        topN: widgetOptionsDictionary && widgetOptionsDictionary.topN
            ? widgetOptionsDictionary.topN
            : '100',
        showTotal: widgetOptionsDictionary && widgetOptionsDictionary.showTotal
            ? widgetOptionsDictionary.showTotal === 'true'
            : 'false',
    };
}
export { getDefaultGridWidgetSettings };
