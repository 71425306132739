export default {
    // Generic
    'generic.add': 'Añadir',
    'generic.cancel': 'Cancelar',
    // BlockType
    'components.controls.blocktype.h1': 'H1',
    'components.controls.blocktype.h2': 'H2',
    'components.controls.blocktype.h3': 'H3',
    'components.controls.blocktype.h4': 'H4',
    'components.controls.blocktype.h5': 'H5',
    'components.controls.blocktype.h6': 'H6',
    'components.controls.blocktype.blockquote': 'Blockquote',
    'components.controls.blocktype.code': 'Código',
    'components.controls.blocktype.blocktype': 'Tipo de bloque',
    'components.controls.blocktype.normal': 'Normal',
    // Color Picker
    'components.controls.colorpicker.colorpicker': 'Seleccionar color',
    'components.controls.colorpicker.text': 'Texto',
    'components.controls.colorpicker.background': 'Subrayado',
    // Embedded
    'components.controls.embedded.embedded': 'Adjuntar',
    'components.controls.embedded.embeddedlink': 'Adjuntar Link',
    'components.controls.embedded.enterlink': 'Introducir link',
    // Emoji
    'components.controls.emoji.emoji': 'Emoji',
    // FontFamily
    'components.controls.fontfamily.fontfamily': 'Fuente',
    // FontSize
    'components.controls.fontsize.fontsize': 'Tamaño de fuente',
    // History
    'components.controls.history.history': 'Histórico',
    'components.controls.history.undo': 'Deshacer',
    'components.controls.history.redo': 'Rehacer',
    // Image
    'components.controls.image.image': 'Imagen',
    'components.controls.image.fileUpload': 'Subir archivo',
    'components.controls.image.byURL': 'URL',
    'components.controls.image.dropFileText': 'Arrastra el archivo o haz click para subirlo',
    // Inline
    'components.controls.inline.bold': 'Negrita',
    'components.controls.inline.italic': 'Cursiva',
    'components.controls.inline.underline': 'Subrayado',
    'components.controls.inline.strikethrough': 'Tachado',
    'components.controls.inline.monospace': 'Monospace',
    'components.controls.inline.superscript': 'Sobreíndice',
    'components.controls.inline.subscript': 'Subíndice',
    // Link
    'components.controls.link.linkTitle': 'Título del enlace',
    'components.controls.link.linkTarget': 'Objetivo del enlace',
    'components.controls.link.linkTargetOption': 'Abrir en nueva ventana',
    'components.controls.link.link': 'Enlazar',
    'components.controls.link.unlink': 'Desenlazar',
    // List
    'components.controls.list.list': 'Lista',
    'components.controls.list.unordered': 'Desordenada',
    'components.controls.list.ordered': 'Ordenada',
    'components.controls.list.indent': 'Indentada',
    'components.controls.list.outdent': 'Dentada',
    // Remove
    'components.controls.remove.remove': 'Eliminar',
    // TextAlign
    'components.controls.textalign.textalign': 'Alineación del texto',
    'components.controls.textalign.left': 'Izquierda',
    'components.controls.textalign.center': 'Centrado',
    'components.controls.textalign.right': 'Derecha',
    'components.controls.textalign.justify': 'Justificado',
};
