var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useMemo } from 'react';
import { ThemeProvider as SCThemProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider, createTheme, } from '@mui/material/styles';
import { darken, lighten } from 'polished';
import UserStore from '@stores/UserStore';
import GlobalStyles from './GlobalStyles';
import { initialTheme } from './initial-theme';
function ThemeProvider(_a) {
    var children = _a.children;
    var _b = useState(initialTheme), theme = _b[0], setTheme = _b[1];
    var muiThemeObj = useMemo(function () {
        return createTheme({
            palette: {
                primary: theme.palette.primary,
                secondary: theme.palette.secondary,
                error: theme.palette.error,
                warning: theme.palette.warning,
                success: theme.palette.success,
            },
            components: {
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            border: "1px solid ".concat(theme.palette.common.border1),
                        },
                    },
                    defaultProps: {
                        elevation: 4,
                    },
                },
                MuiSwitch: {
                    styleOverrides: {
                        root: {
                            width: 48,
                            height: 32,
                            padding: 7,
                        },
                    },
                },
            },
        });
    }, [theme]);
    useEffect(function () {
        var onGetUser = function () {
            var currentUserBrand = UserStore.getCurrentUser().brandObj;
            var primaryColor = (currentUserBrand === null || currentUserBrand === void 0 ? void 0 : currentUserBrand['primary-background-color']) ||
                localStorage.getItem('primary-background-color') ||
                '';
            var primaryContrastText = (currentUserBrand === null || currentUserBrand === void 0 ? void 0 : currentUserBrand['primary-text-color']) ||
                localStorage.getItem('primary-text-color') ||
                '';
            var secondaryColor = (currentUserBrand === null || currentUserBrand === void 0 ? void 0 : currentUserBrand['secondary-background-color']) ||
                localStorage.getItem('secondary-background-color') ||
                '';
            var secondaryContrastText = (currentUserBrand === null || currentUserBrand === void 0 ? void 0 : currentUserBrand['secondary-text-color']) ||
                localStorage.getItem('secondary-text-color') ||
                '';
            if (primaryColor) {
                setTheme(__assign(__assign({}, theme), { palette: __assign(__assign({}, theme.palette), { primary: {
                            main: primaryColor,
                            dark: darken(0.1, primaryColor),
                            light: lighten(0.1, primaryColor),
                            contrastText: primaryContrastText,
                        }, secondary: {
                            main: secondaryColor,
                            dark: darken(0.1, secondaryColor),
                            light: lighten(0.1, secondaryColor),
                            contrastText: secondaryContrastText,
                        } }) }));
            }
        };
        // check localStorage and initiateTheme
        onGetUser();
        // clear theme in localStorage and reset Theme
        var onLogout = function () {
            localStorage.removeItem('primary-background-color');
            localStorage.removeItem('primary-text-color');
            localStorage.removeItem('secondary-background-color');
            localStorage.removeItem('secondary-text-color');
            setTheme(initialTheme);
        };
        UserStore.addChangeUserBrandUserListener(onGetUser);
        UserStore.addGetCurrentUserListener(onGetUser);
        UserStore.addResetThemeListener(onLogout);
        return function () {
            UserStore.removeUserBrandUserListener(onGetUser);
            UserStore.removeGetCurrentUserListener(onGetUser);
            UserStore.removeResetThemeListener(onLogout);
        };
    }, []);
    return (_jsx(MuiThemeProvider, { theme: muiThemeObj, children: _jsxs(SCThemProvider, { theme: theme, children: [_jsx(GlobalStyles, {}), children] }) }));
}
export default ThemeProvider;
