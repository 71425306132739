var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import ActionTypes from 'constants/actionTypes';
import BaseStore from 'stores/BaseStore';
var GET_PUSHMAIL_USERS_EVENT = 'GET_PUSHMAIL_USERS_EVENT';
var PushmailStore = /** @class */ (function (_super) {
    __extends(PushmailStore, _super);
    function PushmailStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    PushmailStore.prototype.initializeState = function () {
        this.pushmailThemes = [];
        this.pushmailMailingList = [];
        this.pushmailUserList = [];
        this.visualizations = [];
    };
    PushmailStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(PushmailStore.prototype, "themes", {
        get: function () {
            return __spreadArray([], this.pushmailThemes, true);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PushmailStore.prototype, "mailingList", {
        get: function () {
            return __spreadArray([], this.pushmailMailingList, true);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PushmailStore.prototype, "userList", {
        get: function () {
            return __spreadArray([], this.pushmailUserList, true);
        },
        enumerable: false,
        configurable: true
    });
    PushmailStore.prototype.getUsersIdsByGroupId = function (groupId) {
        var usersIds = [];
        this.pushmailUserList.forEach(function (user) {
            var groupsIds = user.groups.map(function (group) { return group.groupId; });
            if (groupsIds.includes(groupId)) {
                usersIds.push(user.userId);
            }
        });
        return usersIds;
    };
    PushmailStore.prototype.getVisualizationById = function (pushmailId) {
        var visualization = this.visualizations.find(function (vis) { return vis.id === pushmailId; });
        return visualization || null;
    };
    PushmailStore.prototype.setVisualization = function (pushmailId, data) {
        var visualization = this.visualizations.find(function (vis) { return vis.id === +pushmailId; });
        if (visualization) {
            visualization.data = data;
        }
        else {
            this.visualizations.push({
                id: +pushmailId,
                data: data,
            });
        }
    };
    PushmailStore.prototype.emitPushmailUsersListChange = function (data) {
        this.emit(GET_PUSHMAIL_USERS_EVENT, data);
    };
    PushmailStore.prototype.addPushmailUsersListListener = function (callback) {
        this.on(GET_PUSHMAIL_USERS_EVENT, callback);
    };
    PushmailStore.prototype.removePushmailUsersListListener = function (callback) {
        this.removeListener(GET_PUSHMAIL_USERS_EVENT, callback);
    };
    PushmailStore.prototype.registerToActions = function (payload) {
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_PUSHMAIL_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.pushmailThemes = data.pushmailIdsTemplates;
                    this.initialized = true;
                    this.emitChange();
                }
                else {
                    this.emitError('GET_PUSHMAIL_RESPONSE');
                }
                break;
            case ActionTypes.GET_USERS_BY_GROUPS_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.pushmailMailingList = data.groups;
                    this.pushmailUserList = data.users;
                    this.initialized = true;
                    this.emitPushmailUsersListChange(this.pushmailUserList);
                }
                else {
                    this.emitError('GET_USERS_BY_GROUPS_RESPONSE');
                }
                break;
            case ActionTypes.SAVE_PUSHMAIL_RESPONSE:
                if (action.json) {
                    this.emitChange('SAVE_PUSHMAIL_RESPONSE');
                }
                else {
                    this.emitError('SAVE_PUSHMAIL_RESPONSE');
                }
                break;
            case ActionTypes.GET_VISUALIZATION_RESPONSE:
                if (action.json) {
                    var _a = action.json, PushmailId = _a.model.PushmailId, data = _a.data;
                    this.setVisualization(PushmailId, data);
                    this.emitChange('GET_VISUALIZATION_RESPONSE');
                }
                else {
                    this.emitError('GET_VISUALIZATION_RESPONSE');
                }
                break;
            default:
                break;
        }
    };
    return PushmailStore;
}(BaseStore));
export default new PushmailStore();
