var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var Wrapper = styled('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .header-timezone_label {\n    position: relative;\n    height: 100%;\n    svg {\n      margin: 12px;\n      fill: ", ";\n      opacity: 0.5;\n    }\n  }\n  .timezone-popup {\n    display: none;\n    position: absolute;\n    z-index: 100;\n    top: 50px;\n    right: 10px;\n    min-width: 180px;\n    max-width: 250px;\n    padding: 8px;\n    background: #ffffff;\n    border: ", ";\n    border-radius: 4px;\n    color: #000000;\n  }\n\n  @media (max-width: ", "px) {\n    .header-timezone_label svg {\n      fill: #000000;\n    }\n  }\n"], ["\n  .header-timezone_label {\n    position: relative;\n    height: 100%;\n    svg {\n      margin: 12px;\n      fill: ", ";\n      opacity: 0.5;\n    }\n  }\n  .timezone-popup {\n    display: none;\n    position: absolute;\n    z-index: 100;\n    top: 50px;\n    right: 10px;\n    min-width: 180px;\n    max-width: 250px;\n    padding: 8px;\n    background: #ffffff;\n    border: ", ";\n    border-radius: 4px;\n    color: #000000;\n  }\n\n  @media (max-width: ", "px) {\n    .header-timezone_label svg {\n      fill: #000000;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme, $isPublic = _a.$isPublic;
    return $isPublic ? '#000000' : theme.palette.primary.contrastText;
}, function (_a) {
    var theme = _a.theme;
    return "1px solid ".concat(theme.palette.common.border1);
}, function (_a) {
    var theme = _a.theme;
    return theme.breakPoints.sm;
});
export { Wrapper };
var templateObject_1;
