export function capitalizeFirstChar(string) {
    return typeof string === 'string'
        ? string.charAt(0).toUpperCase() + string.substring(1)
        : '';
}
export function trim(string) {
    return typeof string === 'string' ? string.replace(/\s\s+/g, ' ').trim() : '';
}
export function isNotEmpty(string) {
    return typeof string === 'string' && string.length > 0;
}
function leftPad(value, padding, charRepeat) {
    var returnString = '';
    if (value != null) {
        var zeroes = charRepeat;
        for (var i = 0; i < padding; i += 1) {
            zeroes += charRepeat;
        }
        returnString = (zeroes + value).slice(padding * -1);
    }
    return returnString;
}
export function zeroFill(number, width) {
    return String(leftPad(number, width, '0'));
}
