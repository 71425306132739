var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var Wrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: calc(100% - 60px);\n    position: relative;\n    left: 60px;\n    height: 100vh;\n    overflow: auto;\n\n    /* position: relative; */\n    /* height: 100vh; */\n    /* width: 100%; */\n    /* margin: 0 auto; */\n    padding-top: var(--topbar-height, 0);\n    & > .container-page-content {\n      display: flex;\n      flex-direction: column;\n      padding: 0 15px;\n      height: 100%;\n    }\n\n    @media (max-width: ", "px) {\n      left: 0;\n      top: 0;\n      right: 0;\n      bottom: 0;\n      width: 100%;\n      height: 100%;\n      padding-bottom: var(--bottombar-height);\n    }\n\n    .wrapper-public-content {\n      width: 100%;\n      left: 10px;\n    }\n\n    .agency-wrapper {\n      width: 100%;\n      left: 0;\n    }\n  "], ["\n    width: calc(100% - 60px);\n    position: relative;\n    left: 60px;\n    height: 100vh;\n    overflow: auto;\n\n    /* position: relative; */\n    /* height: 100vh; */\n    /* width: 100%; */\n    /* margin: 0 auto; */\n    padding-top: var(--topbar-height, 0);\n    & > .container-page-content {\n      display: flex;\n      flex-direction: column;\n      padding: 0 15px;\n      height: 100%;\n    }\n\n    @media (max-width: ", "px) {\n      left: 0;\n      top: 0;\n      right: 0;\n      bottom: 0;\n      width: 100%;\n      height: 100%;\n      padding-bottom: var(--bottombar-height);\n    }\n\n    .wrapper-public-content {\n      width: 100%;\n      left: 10px;\n    }\n\n    .agency-wrapper {\n      width: 100%;\n      left: 0;\n    }\n  "])), theme.breakPoints.sm);
});
export { Wrapper };
var templateObject_1;
