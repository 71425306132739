var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
import * as Utils from '@services/AppUtils';
var SAVE_HIERARCHY_EVENT = 'saveHierarchy';
var DELETE_HIERARCHY_EVENT = 'DELETE_HIERARCHY_EVENT';
/**
 * Enum for hierarchy types.
 * @enum {number}
 */
var ENUM_HIERARCHY_TYPES = {
    TEMPORARY: 3, // temporary hierarchy for reviews from prod
    BOTH: 2,
    CATEGORIES: 1,
    PRESS_REVIEW: 0,
};
/**
 * Class representing a clip selection hierarchy.
 */
var ClipSelectionHierarchy = /** @class */ (function () {
    /**
     * Create a clip selection hierarchy.
     * @param {string} clipSelectionId.
     * @param {string} clipSelectionName.
     * @param {ClipSelectionHierarchy[]} clipSelectionsHierarchy.
     * @param {number} level.
     */
    function ClipSelectionHierarchy(_a) {
        var clipSelectionId = _a.clipSelectionId, clipSelectionName = _a.clipSelectionName, clipSelectionsHierarchy = _a.clipSelectionsHierarchy, level = _a.level, css = _a.css;
        // Default values
        this.clipSelectionId = '';
        this.clipSelectionName = '';
        this.clipSelectionsHierarchy = [];
        this.level = 0;
        this.css = '';
        // Valid and set properties
        this.setClipSelectionId(clipSelectionId);
        this.setClipSelectionName(clipSelectionName);
        this.setClipSelectionsHierarchy(clipSelectionsHierarchy);
        this.setLevel(level);
        this.setCss(css);
    }
    /**
     * Sets the ID of the clip selection hierarchy.
     * @param {string} clipSelectionId.
     */
    ClipSelectionHierarchy.prototype.setClipSelectionId = function (clipSelectionId) {
        if (typeof clipSelectionId === 'string' && clipSelectionId.length > 0) {
            this.clipSelectionId = clipSelectionId;
        }
    };
    /**
     * Sets the name of the clip selection hierarchy.
     * @param {string} clipSelectionName.
     */
    ClipSelectionHierarchy.prototype.setClipSelectionName = function (clipSelectionName) {
        if (typeof clipSelectionName === 'string' && clipSelectionName.length > 0) {
            this.clipSelectionName = clipSelectionName;
        }
    };
    /**
     * Sets the name of the clip selection hierarchy.
     * @param {ClipSelectionHierarchy[]} clipSelectionsHierarchy.
     */
    ClipSelectionHierarchy.prototype.setClipSelectionsHierarchy = function (clipSelectionsHierarchy) {
        if (!Array.isArray(clipSelectionsHierarchy)) {
            return;
        }
        var arr = [];
        clipSelectionsHierarchy.forEach(function (clipSelectionHierarchyItem) {
            // Filter groups from hierarchies generated from treeCollection
            if (clipSelectionHierarchyItem.clipSelectionId !==
                clipSelectionHierarchyItem.clipSelectionName) {
                arr.push(new ClipSelectionHierarchy(__assign(__assign({}, clipSelectionHierarchyItem), { clipSelectionId: String(clipSelectionHierarchyItem.clipSelectionId) })));
            }
        });
        this.clipSelectionsHierarchy = arr;
    };
    /**
     * Sets the level of the clip selection hierarchy.
     * @param {number} level.
     */
    ClipSelectionHierarchy.prototype.setLevel = function (level) {
        if (typeof level === 'number' && level >= 0) {
            this.level = level;
        }
    };
    ClipSelectionHierarchy.prototype.setCss = function (css) {
        if (css) {
            this.css = css;
        }
    };
    return ClipSelectionHierarchy;
}());
/**
 * Class representing a hierarchy.
 */
var Hierarchy = /** @class */ (function () {
    /**
     * Create a hierarchy.
     * @param {number} [clipSelectionsCount].
     * @param {ClipSelectionHierarchy[]} [clipSelectionsHierarchy].
     * @param {string} id.
     * @param {string} [name].
     * @param {string} [templateId].
     * @param {number} [type].
     */
    function Hierarchy(_a) {
        var clipSelectionsCount = _a.clipSelectionsCount, clipSelectionsHierarchy = _a.clipSelectionsHierarchy, id = _a.id, name = _a.name, templateId = _a.templateId, type = _a.type;
        // Default values
        this.clipSelectionsCount = 0;
        this.clipSelectionsHierarchy = [];
        this.hasDetails = false;
        this.id = '';
        this.name = '';
        this.templateId = '';
        this.type = 0;
        // Valid and set properties
        this.setClipSelectionsCount(clipSelectionsCount);
        this.setClipSelectionsHierarchy(clipSelectionsHierarchy);
        this.setId(id);
        this.setName(name);
        this.setTemplateId(templateId);
        this.setType(type);
    }
    Hierarchy.getClipSelectionIdsInClipSelectionsHierarchy = function (clipSelectionsHierarchy) {
        return Array.isArray(clipSelectionsHierarchy)
            ? clipSelectionsHierarchy.reduce(function (previous, current) { return __spreadArray(__spreadArray(__spreadArray([], previous, true), [current.clipSelectionId], false), Hierarchy.getClipSelectionIdsInClipSelectionsHierarchy(current.clipSelectionsHierarchy), true); }, [])
            : [];
    };
    Hierarchy.getClipSelectionInClipSelectionsHierarchy = function (clipSelectionsHierarchy) {
        return Array.isArray(clipSelectionsHierarchy)
            ? clipSelectionsHierarchy.reduce(function (previous, current) { return __spreadArray(__spreadArray(__spreadArray([], previous, true), [current], false), Hierarchy.getClipSelectionInClipSelectionsHierarchy(current.clipSelectionsHierarchy), true); }, [])
            : [];
    };
    /**
     * Get all clip selection IDs present in the hierarchy.
     * @return {string[]}.
     */
    Hierarchy.prototype.getClipSelectionIds = function () {
        return Hierarchy.getClipSelectionIdsInClipSelectionsHierarchy(this.clipSelectionsHierarchy);
    };
    /**
     * Sets the number of clip selections present in the hierarchy.
     * @param {number} clipSelectionsCount.
     */
    Hierarchy.prototype.setClipSelectionsCount = function (clipSelectionsCount) {
        if (HierarchyCollection.checkClipSelectionsCount(clipSelectionsCount)) {
            this.clipSelectionsCount = clipSelectionsCount;
        }
    };
    /**
     * Sets the clip selections hierarchy in the hierarchy.
     * @param {ClipSelectionHierarchy[]} clipSelectionsHierarchy.
     */
    Hierarchy.prototype.setClipSelectionsHierarchy = function (clipSelectionsHierarchy, hasDetails) {
        if (hasDetails === void 0) { hasDetails = false; }
        if (!Array.isArray(clipSelectionsHierarchy)) {
            if (hasDetails) {
                this.hasDetails = true;
            }
            return;
        }
        this.clipSelectionsHierarchy = clipSelectionsHierarchy.map(function (clipSelectionHierarchyItem) {
            // @ts-expect-error ts will rise an error about the Id is a umber
            return new ClipSelectionHierarchy(__assign(__assign({}, clipSelectionHierarchyItem), { clipSelectionId: String(clipSelectionHierarchyItem.clipSelectionId) }));
        });
        this.hasDetails = true;
    };
    /**
     * Sets the ID of the hierarchy.
     * @param {string} id.
     */
    Hierarchy.prototype.setId = function (id) {
        if (HierarchyCollection.checkId(id)) {
            this.id = id;
        }
    };
    /**
     * Sets the name of the hierarchy.
     * @param {string} name.
     */
    Hierarchy.prototype.setName = function (name) {
        if (HierarchyCollection.checkName(name)) {
            this.name = name;
        }
    };
    /**
     * Sets the template ID of the hierarchy.
     * @param {string} templateId.
     */
    Hierarchy.prototype.setTemplateId = function (templateId) {
        if (HierarchyCollection.checkTemplateId(templateId)) {
            this.templateId = templateId;
        }
    };
    /**
     * Sets the type of the hierarchy.
     * @param {number} type.
     */
    Hierarchy.prototype.setType = function (type) {
        if (HierarchyCollection.checkType(type)) {
            this.type = type;
        }
    };
    return Hierarchy;
}());
export { Hierarchy };
/**
 * Class representing a collection of hierarchies.
 */
var HierarchyCollection = /** @class */ (function () {
    function HierarchyCollection() {
        this.collection = [];
    }
    /**
     * Check if the clip selections count is valid.
     * @param {number} clipSelectionsCount.
     * @return {boolean}
     */
    HierarchyCollection.checkClipSelectionsCount = function (clipSelectionsCount) {
        return typeof clipSelectionsCount === 'number' && clipSelectionsCount >= 0;
    };
    /**
     * Check if the ID is valid.
     * @param {string} id.
     * @return {boolean}
     */
    HierarchyCollection.checkId = function (id) {
        return typeof id === 'string' && id.length > 0;
    };
    /**
     * Check if the name is valid.
     * @param {string} name.
     * @return {boolean}
     */
    HierarchyCollection.checkName = function (name) {
        return typeof name === 'string' && name.length > 0;
    };
    /**
     * Check if the template ID is valid.
     * @param {string} templateId.
     * @return {boolean}
     */
    HierarchyCollection.checkTemplateId = function (templateId) {
        return typeof templateId === 'string' && templateId.length > 0;
    };
    /**
     * Check if the type is valid.
     * @param {number} type.
     * @return {boolean}
     */
    HierarchyCollection.checkType = function (type) {
        return Object.values(ENUM_HIERARCHY_TYPES).includes(type);
    };
    /**
     * Add a hierarchy in the collection.
     * @param {number} hierarchy[].clipSelectionsCount.
     * @param {string} hierarchy[].id.
     * @param {string} hierarchy[].name.
     * @param {string} hierarchy[].templateId.
     * @param {number} hierarchy[].type.
     * @return {Hierarchy}
     */
    HierarchyCollection.prototype.add = function (hierarchy) {
        var id = hierarchy.id;
        if (HierarchyCollection.checkId(id)) {
            var newHierarchy = this.getHierarchyById(id) == null ? new Hierarchy(hierarchy) : null;
            if (newHierarchy != null) {
                this.collection.push(newHierarchy);
            }
            return newHierarchy;
        }
        return null;
    };
    /**
     * Delete a hierarchy from the collection by its ID.
     * @param {string} hierarchyId.
     */
    HierarchyCollection.prototype.delete = function (hierarchyId) {
        var index = this.collection.findIndex(function (_a) {
            var id = _a.id;
            return id === hierarchyId;
        });
        this.collection.splice(index, 1);
    };
    /**
     * Get the collection.
     */
    HierarchyCollection.prototype.get = function () {
        return this.collection.sort(function (a, b) {
            return Utils.sortStringAlphabetically(a, b, 'name');
        });
    };
    /**
     * Get hierarchies whose type is "categories" or "both" in the collection.
     */
    HierarchyCollection.prototype.getHierarchiesCategories = function () {
        return this.collection
            .filter(function (_a) {
            var type = _a.type;
            return type === ENUM_HIERARCHY_TYPES.CATEGORIES ||
                type === ENUM_HIERARCHY_TYPES.BOTH;
        })
            .sort(function (a, b) { return Utils.sortStringAlphabetically(a, b, 'name'); });
    };
    /**
     * Get hierarchies whose type is "press review" or "both" in the collection.
     */
    HierarchyCollection.prototype.getHierarchiesPressReview = function () {
        return this.collection
            .filter(function (_a) {
            var type = _a.type, id = _a.id;
            return (type === ENUM_HIERARCHY_TYPES.PRESS_REVIEW ||
                type === ENUM_HIERARCHY_TYPES.BOTH) &&
                id !== 'null';
        })
            .sort(function (a, b) { return Utils.sortStringAlphabetically(a, b, 'name'); });
    };
    /**
     * Get a hierarchy by its ID.
     * @param {string} hierarchyId.
     * @return {Hierarchy}
     */
    HierarchyCollection.prototype.getHierarchyById = function (hierarchyId) {
        return this.collection.find(function (_a) {
            var id = _a.id;
            return id === hierarchyId;
        });
    };
    /**
     * Reset the collection.
     */
    HierarchyCollection.prototype.reset = function () {
        this.collection = [];
    };
    return HierarchyCollection;
}());
var HierarchyStore = /** @class */ (function (_super) {
    __extends(HierarchyStore, _super);
    function HierarchyStore() {
        var _this = _super.call(this) || this;
        _this.hierarchyId = '';
        _this.hierarchyCollection = new HierarchyCollection();
        _this.selectedHierarchy = '';
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    HierarchyStore.prototype.getHierarchyId = function () {
        return this.hierarchyId;
    };
    HierarchyStore.prototype.setHierarchyId = function (hierarchyId) {
        this.hierarchyId = hierarchyId;
    };
    Object.defineProperty(HierarchyStore.prototype, "hierarchies", {
        get: function () {
            return this.hierarchyCollection.get();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HierarchyStore.prototype, "hierarchiesCategories", {
        get: function () {
            return this.hierarchyCollection.getHierarchiesCategories();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HierarchyStore.prototype, "hierarchiesPressReview", {
        get: function () {
            return this.hierarchyCollection.getHierarchiesPressReview();
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Get a hierarchy by its ID.
     * @param {string} hierarchyId.
     * @return {Hierarchy}
     */
    HierarchyStore.prototype.getHierarchyById = function (hierarchyId) {
        return this.hierarchyCollection.getHierarchyById(hierarchyId);
    };
    HierarchyStore.prototype.deleteHierarchyById = function (hierarchyId) {
        this.hierarchyCollection.delete(hierarchyId);
    };
    HierarchyStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.hierarchyCollection.reset();
    };
    HierarchyStore.prototype.emitSaveHierarchy = function (hierarchyId) {
        this.emit(SAVE_HIERARCHY_EVENT, hierarchyId);
    };
    HierarchyStore.prototype.addSaveHierarchyListener = function (callback) {
        this.on(SAVE_HIERARCHY_EVENT, callback);
    };
    HierarchyStore.prototype.removeSaveHierarchyListener = function (callback) {
        this.removeListener(SAVE_HIERARCHY_EVENT, callback);
    };
    HierarchyStore.prototype.emitDeleteHierarchy = function () {
        this.emit(DELETE_HIERARCHY_EVENT);
    };
    HierarchyStore.prototype.addDeleteHierarchyListener = function (callback) {
        this.on(DELETE_HIERARCHY_EVENT, callback);
    };
    HierarchyStore.prototype.removeDeleteHierarchyListener = function (callback) {
        this.removeListener(DELETE_HIERARCHY_EVENT, callback);
    };
    HierarchyStore.prototype.registerToActions = function (payload) {
        var _this = this;
        var _a, _b;
        var action = payload.action;
        switch (action.actionType) {
            /**
             * @property {string} ActionTypes.GET_DRAFT_RESPONSE
             */
            case ActionTypes.GET_DRAFT_RESPONSE:
                if ((_a = action.json) === null || _a === void 0 ? void 0 : _a.data) {
                    var _c = action.json.data, draft = _c.draft, draftHierachy = _c.hierarchy;
                    if (draftHierachy) {
                        var clipSelectionsCount = draftHierachy.clipSelectionsCount, clipSelectionsHierarchy = draftHierachy.clipSelectionsHierarchy, hierarchyType = draftHierachy.hierarchyType, id = draftHierachy.id, name = draftHierachy.name, templateId = draftHierachy.templateId;
                        var hierarchy = this.getHierarchyById(String(id));
                        if (hierarchy == null) {
                            this.hierarchyCollection.add({
                                clipSelectionsCount: clipSelectionsCount,
                                clipSelectionsHierarchy: clipSelectionsHierarchy,
                                id: String(id),
                                name: name,
                                templateId: templateId,
                                type: hierarchyType,
                            });
                        }
                        else {
                            hierarchy.setClipSelectionsCount(clipSelectionsCount);
                            hierarchy.setClipSelectionsHierarchy(clipSelectionsHierarchy);
                            hierarchy.setName(name);
                            hierarchy.setType(hierarchyType);
                            hierarchy.setTemplateId(templateId);
                        }
                        this.emitChange(String(id));
                    }
                    else if (draft.hierarchy) {
                        var id = "temp_hierarchy_".concat(draft.name);
                        this.hierarchyCollection.add({
                            clipSelectionsHierarchy: draft.hierarchy.clipSelectionsHierarchy,
                            id: id,
                            name: id,
                            type: 3,
                        });
                        this.emitChange(String(id));
                    }
                }
                break;
            /**
             * @property {string} ActionTypes.GET_HIERARCHIES_RESPONSE
             */
            case ActionTypes.GET_HIERARCHIES_RESPONSE:
                if (action.json == null) {
                    this.emitError('GET_HIERARCHIES');
                }
                else {
                    var data = action.json.data;
                    if (Array.isArray(data)) {
                        this.setHierarchyId(data[0].id);
                        data.forEach(function (_a) {
                            var clipSelectionsCount = _a.clipSelectionsCount, id = _a.id, name = _a.name, templateId = _a.templateId, type = _a.type;
                            var hierarchy = _this.getHierarchyById(id);
                            if (hierarchy == null) {
                                _this.hierarchyCollection.add({
                                    clipSelectionsCount: clipSelectionsCount,
                                    id: id,
                                    name: name,
                                    templateId: templateId,
                                    type: type,
                                });
                            }
                            else {
                                hierarchy.setClipSelectionsCount(clipSelectionsCount);
                                hierarchy.setName(name);
                                hierarchy.setTemplateId(templateId);
                                hierarchy.setType(type);
                            }
                        });
                    }
                    this.hierarchyCollection.add({
                        clipSelectionsCount: 1,
                        id: 'null',
                        name: 'Review',
                        templateId: '-1',
                        type: 0,
                    });
                    this.initialized = true;
                    this.emitChange();
                }
                break;
            /**
             * @property {string} ActionTypes.GET_HIERARCHY_RESPONSE
             */
            case ActionTypes.GET_HIERARCHY_RESPONSE:
                if (action.json == null) {
                    this.emitError('GET_HIERARCHY');
                }
                else {
                    var data = action.json.data;
                    var id = action.json.model.id;
                    var hierarchy = this.getHierarchyById(id);
                    if (hierarchy == null && data) {
                        // TODO: normaly it never happens but it needs a re-check
                        this.hierarchyCollection.add({
                            clipSelectionsHierarchy: data,
                            id: id,
                            name: '',
                            type: 0,
                        });
                    }
                    else if (hierarchy && data) {
                        hierarchy.setClipSelectionsHierarchy(data, true);
                    }
                    this.emitChange(String(id));
                }
                break;
            /**
             * @property {string} ActionTypes.SAVE_HIERARCHY_RESPONSE
             */
            case ActionTypes.SAVE_HIERARCHY_RESPONSE:
                // eslint-disable-next-line
                if (action.json == null || action.json.data == null || action.errors) {
                    this.emitError('SAVE_HIERARCHY', (_b = action.errors) === null || _b === void 0 ? void 0 : _b.error);
                }
                else {
                    var _d = action.json.data, clipSelectionsHierarchy = _d.clipSelectionsHierarchy, idCreated = _d.id;
                    var name = action.json.model.Name;
                    var _e = action.json.model, id = _e.id, hierarchyType = _e.HierarchyType;
                    if (!id) {
                        this.hierarchyCollection.add({
                            id: idCreated.toString(),
                            name: name,
                            type: Number(hierarchyType),
                            clipSelectionsHierarchy: clipSelectionsHierarchy,
                        });
                    }
                    else if (id) {
                        var hierarchy = this.getHierarchyById(String(id));
                        if (hierarchy) {
                            hierarchy.setName(name);
                            hierarchy.setType(Number(hierarchyType));
                            hierarchy.setClipSelectionsHierarchy(clipSelectionsHierarchy);
                        }
                    }
                    this.emitSaveHierarchy(String(idCreated));
                }
                break;
            case ActionTypes.SAVE_TEMPORARY_HIERARCHY:
                if (action.data) {
                    var _f = action.data, id = _f.id, clipSelectionsHierarchy = _f.clipSelectionsHierarchy;
                    var hierarchy = this.getHierarchyById(id.toString());
                    if (hierarchy) {
                        hierarchy.setName("temp_".concat(id));
                        hierarchy.setType(3);
                        hierarchy.setClipSelectionsHierarchy(clipSelectionsHierarchy);
                    }
                    else {
                        this.hierarchyCollection.add({
                            clipSelectionsCount: 1,
                            id: String(id),
                            name: "temp_".concat(id),
                            type: 3,
                            clipSelectionsHierarchy: clipSelectionsHierarchy,
                        });
                    }
                    this.emitSaveHierarchy(id.toString());
                }
                break;
            case ActionTypes.DELETE_HIERARCHY_RESPONSE:
                if (action.json === null || action.json.data === null) {
                    this.emitError('DELETE_HIERARCHY');
                }
                else {
                    if (!Array.isArray(action.json.data)) {
                        var hierarchyId = action.json.model.hierarchyId;
                        this.deleteHierarchyById(hierarchyId);
                    }
                    this.emitChange('DELETE_HIERARCHY', action.json.data);
                }
                break;
            default:
                break;
        }
    };
    return HierarchyStore;
}(BaseStore));
export default new HierarchyStore();
