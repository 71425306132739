import en from './en';
import fr from './fr';
import zh from './zh';
import it from './it';
import de from './de';
import es from './es';
import ca from './ca';
export default {
    en: en,
    fr: fr,
    zh: zh,
    it: it,
    de: de,
    es: es,
    ca: ca,
};
