var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import Spinner from 'commons/Spinner';
import Svg from 'commons/Svg';
import SearchStore from 'stores/SearchStore';
import HierarchyActionCreators from 'actions/HierarchyActionCreators';
import HierarchyStore, { Hierarchy } from 'stores/HierarchyStore';
import parameters from 'constants/parameters';
import HierarchyFiltersFacetItem from './HierarchyFiltersFacetItem';
import HierarchyFiltersItem from './HierarchyFiltersItem';
import { Wrapper } from './HierarchyFilters.styles';
var TIMEOUT_BEFORE_FILTER_UPDATE = parameters.TIMEOUT_BEFORE_FILTER_UPDATE;
var propTypes = {
    updateFilter: PropTypes.func,
};
var defaultProps = {
    updateFilter: function () { },
};
function HierarchyFilters(_a) {
    var updateFilter = _a.updateFilter;
    var interval = useRef(0);
    var _b = useState(null), hierarchyId = _b[0], setHierarchyId = _b[1];
    var _c = useState(!HierarchyStore.isInit()), loading = _c[0], setLoading = _c[1];
    var _d = useState([]), selectedIds = _d[0], setSelectedIds = _d[1];
    useEffect(function () {
        if (!HierarchyStore.isInit()) {
            HierarchyActionCreators.getHierarchies();
        }
        if (SearchStore.isInit()) {
            updateSelectedFacets();
        }
        SearchStore.addChangeListener(onUpdateFilter);
        HierarchyStore.addChangeListener(onChange);
        return function () {
            SearchStore.removeChangeListener(onUpdateFilter);
            HierarchyStore.removeChangeListener(onChange);
        };
    }, []);
    var buildFacetItemObject = function (clipSelectionId, clipSelectionName) { return ({
        id: 'clipSelections.id',
        text: clipSelectionName,
        value: clipSelectionId,
    }); };
    var onUpdateFilter = function () {
        updateSelectedFacets();
    };
    var onChange = function () {
        setLoading(false);
    };
    var handleFacetItemChange = function (_a) {
        var clipSelectionsHierarchy = _a.clipSelectionsHierarchy, clipSelectionId = _a.clipSelectionId, clipSelectionName = _a.clipSelectionName;
        var addFacet = SearchStore.addFacet, deleteFacet = SearchStore.deleteFacet;
        var isAlreadySelected = selectedIds.includes(clipSelectionId);
        var clipSelectionChildren = Hierarchy.getClipSelectionInClipSelectionsHierarchy(clipSelectionsHierarchy);
        var clipSelectionChildrenIds = clipSelectionChildren.map(function (clipSelectionChildItem) { return clipSelectionChildItem.clipSelectionId; });
        var updateFacetMethod = isAlreadySelected ? deleteFacet : addFacet;
        updateFacetMethod(buildFacetItemObject(clipSelectionId, clipSelectionName));
        clipSelectionChildren.forEach(function (clipSelectionHierarchy) {
            updateFacetMethod(buildFacetItemObject(clipSelectionHierarchy.clipSelectionId, clipSelectionHierarchy.clipSelectionName));
        });
        updateFilters();
        setSelectedIds(function (prevState) {
            var filterClipSelectionChildIdAlreadySelected = function (clipSelectionChildId) {
                return !selectedIds.includes(clipSelectionChildId);
            };
            var addId = function () { return __spreadArray(__spreadArray(__spreadArray([], prevState, true), [
                clipSelectionId
            ], false), clipSelectionChildrenIds.filter(filterClipSelectionChildIdAlreadySelected), true); };
            var removeId = function () {
                return prevState.filter(function (selectedId) {
                    return selectedId !== clipSelectionId &&
                        !clipSelectionChildrenIds.includes(selectedId);
                });
            };
            return isAlreadySelected ? removeId() : addId();
        });
    };
    var handleItemClick = function (id) {
        var hasDetails = HierarchyStore.getHierarchyById(id).hasDetails;
        if (!hasDetails) {
            setLoading(true);
            HierarchyActionCreators.getHierarchy({ id: id });
        }
        setHierarchyId(id);
    };
    var handleReturnClick = function () {
        setHierarchyId(null);
    };
    var updateFilters = function () {
        clearTimeout(interval.current);
        interval.current = setTimeout(updateFilter, TIMEOUT_BEFORE_FILTER_UPDATE);
    };
    var updateSelectedFacets = function () {
        var selectedFacet = SearchStore.getSelectedFacets();
        setSelectedIds(selectedFacet['clipSelections.id'] == null
            ? []
            : selectedFacet['clipSelections.id'].map(function (_a) {
                var value = _a.value;
                return value;
            }));
    };
    var handleCreateClick = function () {
        window.location =
            '/delivery/Account/Index?ViewName=configuration&&redirect=y';
    };
    var renderHierarchyList = function () {
        var hierarchies = HierarchyStore.hierarchiesCategories;
        if (hierarchies.length > 0) {
            return (_jsx("ul", { className: "c-hierarchy-filters-list list-unstyled", children: hierarchies.map(function (_a) {
                    var id = _a.id;
                    return (_jsx(HierarchyFiltersItem, { id: id, onClick: handleItemClick }, id));
                }) }));
        }
        else if (HierarchyStore.isInit()) {
            return (_jsxs("div", { className: "c-hierarchy-filters__layout", children: [_jsx("p", { className: "c-hierarchy-filters__no-result", children: _jsx(FormattedMessage, { id: "HierarchyFilters.noHierarchyMessage" }) }), _jsx("div", { className: "text-right", children: _jsx(Button, { className: "t-primary-left-column-bg", href: "/delivery/Account/Index?ViewName=configuration&&redirect=y", onClick: handleCreateClick, title: "Create button", children: _jsx("span", { className: "btn__label", children: _jsx(FormattedMessage, { id: "HierarchyFilters.createButtonLabel" }) }) }) })] }));
        }
        return null;
    };
    var renderHierarchy = function () {
        var _a = HierarchyStore.getHierarchyById(hierarchyId), clipSelectionsHierarchy = _a.clipSelectionsHierarchy, name = _a.name;
        return (_jsxs("div", { className: "c-hierarchy-filters__layout", children: [_jsxs(Button, { className: "m-top--x-small m-bottom--xx-small p-horizontal--none", onClick: handleReturnClick, title: "Return button", children: [_jsx(Svg, { className: "btn__icon--left", icon: "prev", size: "small" }), name] }), _jsx("ul", { className: "c-hierarchy-filters-facet-list list-unstyled", children: clipSelectionsHierarchy.map(function (clipSelectionHierarchyItem) { return (_jsx(HierarchyFiltersFacetItem, { item: clipSelectionHierarchyItem, onChange: handleFacetItemChange, selectedIds: selectedIds }, clipSelectionHierarchyItem.clipSelectionId)); }) })] }));
    };
    var hierarchyFiltersStyles = { minHeight: loading ? '6.25rem' : null };
    return (_jsxs(Wrapper, { className: "c-hierarchy-filters", style: hierarchyFiltersStyles, children: [loading ? _jsx(Spinner, {}) : null, hierarchyId == null ? renderHierarchyList() : renderHierarchy()] }));
}
HierarchyFilters.propTypes = propTypes;
HierarchyFilters.defaultProps = defaultProps;
export default HierarchyFilters;
