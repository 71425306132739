var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import AdvancedFiltersMenuElement from './AdvancedFiltersMenuElement';
var propTypes = {
    menuElements: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        id: PropTypes.string.isRequired,
    })).isRequired,
    searchFieldByName: PropTypes.string,
    onMenuElementClick: PropTypes.func.isRequired,
    selectedFacetItems: PropTypes.arrayOf(PropTypes.shape),
    isDeletable: PropTypes.bool,
    isEmbedded: PropTypes.bool,
    onRemoveElement: PropTypes.func,
};
var defaultProps = {
    selectedFacetItems: null,
    isDeletable: false,
    isEmbedded: false,
    onRemoveElement: null,
    searchFieldByName: '',
};
var AdvancedFiltersMenu = function (_a) {
    var menuElements = _a.menuElements, onMenuElementClick = _a.onMenuElementClick, isDeletable = _a.isDeletable, isEmbedded = _a.isEmbedded, onRemoveElement = _a.onRemoveElement, selectedFacetItems = _a.selectedFacetItems, searchFieldByName = _a.searchFieldByName;
    var menuElementToduisplay = useMemo(function () {
        var newMenuElements = menuElements;
        if (selectedFacetItems) {
            var menuElementsWithFilters = menuElements.filter(function (elm) {
                return selectedFacetItems.find(function (elmTab) { return elmTab.includes(elm.id); });
            });
            var menuElementsWithoutFilters = menuElements.filter(function (elm) { return !selectedFacetItems.find(function (elmTab) { return elmTab.includes(elm.id); }); });
            newMenuElements = __spreadArray(__spreadArray([], menuElementsWithFilters, true), menuElementsWithoutFilters, true);
        }
        return newMenuElements.map(function (elm) {
            var filterName = elm.filterElasticFieldName;
            if (elm.isTranslated) {
                filterName = window.Messages[elm.bundle];
            }
            return __assign(__assign({}, elm), { filterName: filterName });
        });
    }, [menuElements, selectedFacetItems]);
    var menuElementToduisplayAfterSearch = searchFieldByName.length > 0
        ? menuElementToduisplay.filter(function (facetItem) {
            return facetItem.filterName
                .toLowerCase()
                .includes(searchFieldByName.toLowerCase());
        })
        : menuElementToduisplay;
    return (_jsx("ul", { className: "c-advanced-filters__menu list-unstyled", children: menuElementToduisplayAfterSearch.map(function (menuElement) { return (_jsx(AdvancedFiltersMenuElement, { item: menuElement, onClick: onMenuElementClick, isDeletable: isDeletable, isEmbedded: isEmbedded, onRemoveElement: onRemoveElement, selectedFacetItems: selectedFacetItems }, menuElement.dimensionId)); }) }));
};
AdvancedFiltersMenu.propTypes = propTypes;
AdvancedFiltersMenu.defaultProps = defaultProps;
export default AdvancedFiltersMenu;
