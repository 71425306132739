var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
var ClipSelectionStore = /** @class */ (function (_super) {
    __extends(ClipSelectionStore, _super);
    function ClipSelectionStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this._registerToActions.bind(_this); });
        return _this;
    }
    ClipSelectionStore.prototype.initializeState = function () {
        this._clipSelections = [];
    };
    ClipSelectionStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(ClipSelectionStore.prototype, "clipSelections", {
        get: function () {
            return this._clipSelections;
        },
        set: function (value) {
            this._clipSelections = value;
        },
        enumerable: false,
        configurable: true
    });
    ClipSelectionStore.prototype.addClipSelection = function (id, name) {
        var clipSelection = this.getClipSelectionById(id);
        if (typeof id === 'string' && typeof name === 'string' && !clipSelection) {
            var newClipSelection = {
                id: id,
                name: name,
            };
            this.clipSelections.push(newClipSelection);
            return newClipSelection;
        }
        return clipSelection;
    };
    ClipSelectionStore.prototype.getClipSelectionById = function (id) {
        return this.clipSelections.find(function (clipSelection) { return clipSelection.id === id; });
    };
    ClipSelectionStore.prototype.getClipSelections = function () {
        return this._clipSelections;
    };
    ClipSelectionStore.prototype._registerToActions = function (payload) {
        var _this = this;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_CLIPSELECTIONS_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    if (Array.isArray(data)) {
                        data.forEach(function (_a) {
                            var id = _a.id, name = _a.name;
                            return _this.addClipSelection(String(id), name);
                        });
                    }
                    this.initialized = true;
                    this.emitChange();
                }
                else {
                    this.emitError('GET_CLIPSELECTIONS');
                }
                break;
            case ActionTypes.SET_CLIPSELECTIONS:
                if (action.data) {
                    action.data.forEach(function (cs) {
                        if (cs.id !== '0') {
                            _this.addClipSelection(cs.id, cs.name);
                        }
                    });
                    this.emitChange();
                }
                break;
            case ActionTypes.GET_ALL_CLIP_SELECTIONS_RESPONSE:
                if (action.json.data) {
                    var categories_1 = action.json.data.categories;
                    if (categories_1) {
                        Object.keys(categories_1).forEach(function (csId) {
                            var name = categories_1[Number(csId)];
                            if (csId !== '0') {
                                _this.addClipSelection(csId, name);
                            }
                        });
                        this.emitChange();
                    }
                }
                break;
            case ActionTypes.ADD_CLIP_SELECTION_RESPONSE:
                if (action.json) {
                    var _a = action.json, data = _a.data, message = _a.message;
                    if (data) {
                        var objectToArrayData = [];
                        objectToArrayData.push(data);
                        objectToArrayData.forEach(function (_a) {
                            var id = _a.id, name = _a.name;
                            return _this.addClipSelection(String(id), name);
                        });
                        this.emitChange();
                    }
                    else if (!data && (message === null || message === void 0 ? void 0 : message.length)) {
                        this.emitError(message);
                    }
                }
                else {
                    this.emitError('ADD_CLIP_SELECTION_RESPONSE');
                }
                break;
            default:
                break;
        }
    };
    return ClipSelectionStore;
}(BaseStore));
export default new ClipSelectionStore();
