import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
var ClipSelectionActionCreators = {
    getClipSelections: function (callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_CLIPSELECTIONS_REQUEST,
        });
        WebApi.getClipSelections(callback);
    },
    setClipSelections: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.SET_CLIPSELECTIONS,
            data: model,
        });
    },
};
export default ClipSelectionActionCreators;
