import { Dispatcher } from 'flux';
import parameters from '../constants/parameters';
var Payload = parameters.PAYLOAD;
export default Object.assign(new Dispatcher(), {
    handleServerAction: function (action) {
        var payload = {
            action: action,
            source: Payload.SERVER_ACTION,
        };
        this.dispatch(payload);
    },
    handleViewAction: function (action) {
        var _this = this;
        if (this.isDispatching()) {
            setTimeout(function () {
                _this.handleViewAction(action);
            }, 0);
        }
        else {
            var payload = {
                action: action,
                source: Payload.VIEW_ACTION,
            };
            this.dispatch(payload);
        }
    },
});
