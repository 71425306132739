var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState, } from 'react';
var initialSweetPopup = {
    isOpen: false,
    onClose: null,
    title: '',
    bodyMessage: '',
    okText: '',
    okClick: null,
    showCancelButton: false,
    cancelText: '',
    cancelClick: null,
    closeOnClickOutside: true,
    closeOnEsc: true,
    showClose: true,
    cancelOnConfirm: true,
};
var SweetPopupContext = React.createContext(null);
function SweetPopupProvider(props) {
    var _a = useState(initialSweetPopup), sweetPopup = _a[0], setSweetPopup = _a[1];
    useEffect(function () {
        if (!sweetPopup.onClose) {
            var handleClose = function () {
                setSweetPopup(initialSweetPopup);
            };
            setSweetPopup(__assign(__assign({}, sweetPopup), { onClose: handleClose }));
        }
    }, [sweetPopup.onClose]);
    return (_jsx(SweetPopupContext.Provider
    // @ts-expect-error expect props to override value
    , __assign({ 
        // @ts-expect-error expect props to override value
        value: { sweetPopup: sweetPopup, setSweetPopup: setSweetPopup } }, props)));
}
export { SweetPopupContext, SweetPopupProvider, initialSweetPopup };
