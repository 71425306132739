var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import swal from 'sweetalert';
import WebApi from 'services/webApi';
var failedGeneratedPDfAction = function (message, setSnackbarObj, genericErrorMsg) {
    window.localStorage.removeItem('PdfDocumentId');
    window.localStorage.removeItem('PdfGenerationState');
    setSnackbarObj({
        open: true,
        status: 'error',
        message: "ERROR:' ".concat(typeof message === 'string' ? message : genericErrorMsg),
    });
};
var downloadFile = function (initialData, setProgress, setLinkPdf, setLoading, setSrcIframe, setDescription, hash, setSnackbarObj, genericErrorMsg, globalPdf) {
    var userActionProceed = false;
    var downloadingFile = function (resData) {
        var data = __assign({}, resData);
        window.localStorage.setItem('PdfDocumentId', data.DocumentId);
        // if (data.Progression === 0 || data.Progression === 1) {
        //   data.Progression = 5;
        // }
        if (data.Progression > 100) {
            data.Progression = 100;
        }
        // 01 Set progress
        setProgress(data.Progression);
        setDescription(data.Description);
        if (data.Type !== 400 && data.Type !== 500) {
            setTimeout(function () {
                WebApi.getEditingRequestState({
                    id: data.DocumentId,
                    pushMailJobId: data.PushmailJobId,
                    hash: hash,
                    globalPdf: globalPdf,
                }, function (result, messages) {
                    if (result.Type !== 102 && userActionProceed === false) {
                        if (result.Type === 103) {
                            userActionProceed = true;
                            if (data.ContentLinkClipsCount === data.ClipsCount) {
                                swal({
                                    allowEscapeKey: false,
                                    allowOutsideClick: false,
                                    cancelButtonText: result.Messages.PDFExportErrorClose,
                                    closeOnConfirm: true,
                                    customClass: 'export_PDF_ContentLink',
                                    text: result.Messages.PDFExportErrorTryLaterMsg,
                                    title: result.Messages.PDFExportErrorTryLaterTitle,
                                    type: 'warning',
                                }, function (Close) {
                                    WebApi.userConfiramation({
                                        pdfRequestId: data.DocumentId,
                                        userConfirmation: false,
                                    });
                                    setSrcIframe('');
                                    // ***** 01 display export params
                                    // var exportTtemplatePdfSection = document.getElementById(
                                    //   'export-template__pdf-section'
                                    // );
                                    // var iframeContainer = document.getElementById(
                                    //   'IframeContainer'
                                    // );
                                    // exportTtemplatePdfSection.style.display = 'block';
                                    // iframeContainer.style.display = 'block';
                                    // var exportPdfTemplateSelectionSelectbox = document.getElementById(
                                    //   'sb-export-pdf-template-selection'
                                    // );
                                    // if (exportPdfTemplateSelectionSelectbox) {
                                    //   exportPdfTemplateSelectionSelectbox.classList.remove(
                                    //     'is-disabled'
                                    //   );
                                    // }
                                    // document
                                    //   .getElementsByClassName('js-return-modes')[0]
                                    //   .click();
                                    // document
                                    //   .getElementsByClassName('js-return-monitoring')[0]
                                    //   .click();
                                });
                            }
                            else {
                                swal({
                                    allowEscapeKey: false,
                                    allowOutsideClick: false,
                                    cancelButtonText: result.Messages.PDFExportErrorNo,
                                    closeOnConfirm: true,
                                    confirmButtonText: result.Messages.PDFExportErrorYes,
                                    customClass: 'export_PDF_ContentLink',
                                    showCancelButton: true,
                                    showConfirmButton: true,
                                    text: "".concat(result.Messages.PDFExportErrorTryLaterMsg, " ").concat(data.ClipsCount - data.ContentLinkClipsCount, "/").concat(data.ClipsCount, " ").concat(result.Messages.PDFExportErrorProceed),
                                    title: result.Messages.PDFExportErrorTryLaterTitle,
                                    type: 'warning',
                                }, function (isConfirm) {
                                    if (isConfirm) {
                                        WebApi.userConfiramation({
                                            pdfRequestId: data.DocumentId,
                                            userConfirmation: true,
                                        });
                                        setSrcIframe('');
                                        //   var exportTtemplatePdfSection = document.getElementById(
                                        //     'export-template__pdf-section'
                                        //   );
                                        //   var iframeContainer = document.getElementById(
                                        //     'IframeContainer'
                                        //   );
                                        //   exportTtemplatePdfSection.style.display = 'block';
                                        //   iframeContainer.style.display = 'block';
                                    }
                                    else {
                                        WebApi.userConfiramation({
                                            pdfRequestId: data.DocumentId,
                                            userConfirmation: false,
                                        });
                                        setSrcIframe('');
                                        //   var exportTtemplatePdfSection = document.getElementById(
                                        //     'export-template__pdf-section'
                                        //   );
                                        //   var iframeContainer = document.getElementById(
                                        //     'IframeContainer'
                                        //   );
                                        //   exportTtemplatePdfSection.style.display = 'block';
                                        //   iframeContainer.style.display = 'block';
                                        //   var exportPdfTemplateSelectionSelectbox = document.getElementById(
                                        //     'sb-export-pdf-template-selection'
                                        //   );
                                        //   if (exportPdfTemplateSelectionSelectbox) {
                                        //     exportPdfTemplateSelectionSelectbox.classList.remove(
                                        //       'is-disabled'
                                        //     );
                                        //   }
                                        //   document
                                        //     .getElementsByClassName('js-return-modes')[0]
                                        //     .click();
                                        //   document
                                        //     .getElementsByClassName('js-return-monitoring')[0]
                                        //     .click();
                                    }
                                });
                            }
                        }
                        else {
                            // contentLinkIframe.style.display = 'none';
                            // exportWindow.style.display = 'block';
                            // var iframeContainer = document.getElementById('IframeContainer');
                            // iframeContainer.style.display = 'block';
                            // var exportTtemplatePdfSection = document.getElementById(
                            //   'export-template__pdf-section'
                            // );
                            // exportTtemplatePdfSection.style.display = 'block';
                        }
                    }
                    if (!result.Cancelled) {
                        downloadingFile(result);
                    }
                }, function (res) {
                    failedGeneratedPDfAction(res.message, setSnackbarObj, genericErrorMsg);
                });
            }, 1000);
        }
        // if Succeed
        if (data.Type === 500) {
            setSrcIframe('');
            setProgress(100);
            setLoading(false);
            // 02 ******** SetLink
            setLinkPdf(data.DownloadUrl);
            window.localStorage.removeItem('PdfDocumentId');
            window.localStorage.removeItem('PdfGenerationState');
        }
        // if Failed
        if (data.Type === 400 || data.code === 500) {
            setLoading(false);
            setProgress(100);
            setSrcIframe('');
            failedGeneratedPDfAction(data.message, setSnackbarObj, genericErrorMsg);
        }
    };
    downloadingFile(initialData);
};
var groupOrderPdf = function (informationsFromArticleList) {
    var clipGroups = [];
    var groupsOrder = [];
    var buildGroupingModelToCreatePdf = function (groups) {
        return [].concat(groups.reduce(function (previous, current) {
            groupsOrder.push(current.name);
            return previous.concat([
                {
                    clipIds: informationsFromArticleList.association[current.id].filter(function (clipId) {
                        return informationsFromArticleList.selectedDisplayedClipIds.indexOf(clipId) !== -1;
                    }),
                    name: current.name,
                    subGroups: current.groups
                        ? buildGroupingModelToCreatePdf(current.groups)
                        : [],
                },
            ]);
        }, []));
    };
    if (informationsFromArticleList.type === 2) {
        clipGroups = buildGroupingModelToCreatePdf(informationsFromArticleList.groupingModel);
    }
    return { clipGroups: clipGroups, groupsOrder: groupsOrder };
};
export { downloadFile, groupOrderPdf };
