import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import Button from '@commons/Button';
import Svg from '@commons/Svg';
import { AdvancedFilterContext } from '@context/AdvancedFilterContext';
var FacetGroupSearch = function (_a) {
    var id = _a.id, name = _a.name;
    var formatMessage = useIntl().formatMessage;
    var setAdvancedFilterId = useContext(AdvancedFilterContext).setAdvancedFilterId;
    var handleClick = function () {
        setAdvancedFilterId(id);
    };
    return (_jsxs(Button, { variant: "text", className: "c-facet-group-search", onClick: handleClick, title: formatMessage({ id: 'Insight.widget.openFilters' }), "aria-label": "Filter ".concat(name, " search button"), name: "".concat(name, " button"), children: [_jsx(Svg, { icon: "search", size: "small" }), _jsx("span", { className: "hidden", children: "Filter ".concat(name, " search button") })] }));
};
export default FacetGroupSearch;
