import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal, { ModalHeader, ModalBody, ModalFooter } from 'commons/Modal';
import Button from 'commons/Button';
import { Wrapper } from './AdvancedSearchModal.styles';
var AdvancedSearchModalProptypes = {
    isOpenAdvancedSearch: PropTypes.bool.isRequired,
    handlecloseSearchModal: PropTypes.func.isRequired,
};
function AdvancedSearchModal(_a) {
    var isOpenAdvancedSearch = _a.isOpenAdvancedSearch, handlecloseSearchModal = _a.handlecloseSearchModal;
    var formatMessage = useIntl().formatMessage;
    var dataAdvancedSearch = [
        {
            title: "".concat(formatMessage({
                id: "QueryBuilder.help.quotationMark",
            }), " \u201C \u201D : "),
            description: formatMessage({
                id: "QueryBuilder.help.quotationMarkDescription",
            }),
        },
        {
            title: 'AND : ',
            description: formatMessage({
                id: "QueryBuilder.help.andDescription",
            }),
        },
        {
            title: 'OR : ',
            description: formatMessage({
                id: "QueryBuilder.help.orDescription",
            }),
        },
        {
            title: 'NOT : ',
            description: formatMessage({
                id: "QueryBuilder.help.notDescription",
            }),
        },
        {
            title: "".concat(formatMessage({ id: "QueryBuilder.help.bracket" }), " () : "),
            description: formatMessage({
                id: "QueryBuilder.help.bracketDescription",
            }),
        },
        {
            title: "".concat(formatMessage({ id: "QueryBuilder.help.asterisk" }), " * : "),
            description: formatMessage({
                id: "QueryBuilder.help.asteriskDescription",
            }),
        },
        {
            title: "".concat(formatMessage({ id: "QueryBuilder.help.questionMark" }), " ? : "),
            description: formatMessage({
                id: "QueryBuilder.help.questionMarkDescription",
            }),
        },
    ];
    return (_jsxs(Modal, { isOpen: isOpenAdvancedSearch, onCancel: handlecloseSearchModal, maxContent: true, width: "small", height: "medium", children: [_jsxs(ModalHeader, { onClose: handlecloseSearchModal, children: [_jsx(FormattedMessage, { id: "QueryBuilder.modalTitle" }), "\u00A0", _jsx(FormattedMessage, { id: "QueryBuilder.modalTitleStrong" })] }), _jsx(ModalBody, { children: _jsx(Wrapper, { children: dataAdvancedSearch.map(function (data, index) { return (
                    // eslint-disable-next-line
                    _jsxs("p", { children: [_jsx("span", { className: "advanced-search-title", children: data.title }), _jsx("span", { className: "advanced-search-description", children: data.description })] }, index)); }) }) }), _jsx(ModalFooter, { children: _jsx(Button, { variant: "fill", color: "secondary", onClick: handlecloseSearchModal, title: formatMessage({ id: 'Global.okIGot' }), children: _jsx(FormattedMessage, { id: "Global.okIGot" }) }) })] }));
}
AdvancedSearchModal.propTypes = AdvancedSearchModalProptypes;
export default AdvancedSearchModal;
