import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import DualListBoxControlsMove from '../DualListBoxControlsMove';
import DualListBoxControlsSort from '../DualListBoxControlsSort';
import { Wrapper } from './DualListBoxControls.styles';
var DualListBoxControls = function (_a) {
    var action = _a.action, onMoveItem = _a.onMoveItem, onSortItem = _a.onSortItem, selectedItems = _a.selectedItems, displayedItems = _a.displayedItems, availableItems = _a.availableItems, searchTerm = _a.searchTerm;
    return (_jsxs(Wrapper, { className: "c-dualListBoxControls", children: [action === 'move' ? (_jsx(DualListBoxControlsMove, { onMoveItem: onMoveItem, selectedItems: selectedItems, availableItems: availableItems, displayedItems: displayedItems })) : null, action === 'sort' ? (_jsx(DualListBoxControlsSort, { onSortItem: onSortItem, selectedItems: selectedItems, displayedItems: displayedItems, searchTerm: searchTerm })) : null] }));
};
export default DualListBoxControls;
