export default {
    // Generic
    'generic.add': '添加',
    'generic.cancel': '取消',
    // BlockType
    'components.controls.blocktype.h1': '标题1',
    'components.controls.blocktype.h2': '标题2',
    'components.controls.blocktype.h3': '标题3',
    'components.controls.blocktype.h4': '标题4',
    'components.controls.blocktype.h5': '标题5',
    'components.controls.blocktype.h6': '标题6',
    'components.controls.blocktype.blockquote': '引用',
    'components.controls.blocktype.code': '源码',
    'components.controls.blocktype.blocktype': '样式',
    'components.controls.blocktype.normal': '正文',
    // Color Picker
    'components.controls.colorpicker.colorpicker': '选色器',
    'components.controls.colorpicker.text': '文字',
    'components.controls.colorpicker.background': '背景',
    // Embedded
    'components.controls.embedded.embedded': '内嵌',
    'components.controls.embedded.embeddedlink': '内嵌网页',
    'components.controls.embedded.enterlink': '输入网页地址',
    // Emoji
    'components.controls.emoji.emoji': '表情符号',
    // FontFamily
    'components.controls.fontfamily.fontfamily': '字体',
    // FontSize
    'components.controls.fontsize.fontsize': '字号',
    // History
    'components.controls.history.history': '历史',
    'components.controls.history.undo': '撤销',
    'components.controls.history.redo': '恢复',
    // Image
    'components.controls.image.image': '图片',
    'components.controls.image.fileUpload': '来自文件',
    'components.controls.image.byURL': '在线图片',
    'components.controls.image.dropFileText': '点击或者拖拽文件上传',
    // Inline
    'components.controls.inline.bold': '粗体',
    'components.controls.inline.italic': '斜体',
    'components.controls.inline.underline': '下划线',
    'components.controls.inline.strikethrough': '删除线',
    'components.controls.inline.monospace': '等宽字体',
    'components.controls.inline.superscript': '上标',
    'components.controls.inline.subscript': '下标',
    // Link
    'components.controls.link.linkTitle': '超链接',
    'components.controls.link.linkTarget': '输入链接地址',
    'components.controls.link.linkTargetOption': '在新窗口中打开链接',
    'components.controls.link.link': '链接',
    'components.controls.link.unlink': '删除链接',
    // List
    'components.controls.list.list': '列表',
    'components.controls.list.unordered': '项目符号',
    'components.controls.list.ordered': '编号',
    'components.controls.list.indent': '增加缩进量',
    'components.controls.list.outdent': '减少缩进量',
    // Remove
    'components.controls.remove.remove': '清除格式',
    // TextAlign
    'components.controls.textalign.textalign': '文本对齐',
    'components.controls.textalign.left': '文本左对齐',
    'components.controls.textalign.center': '居中',
    'components.controls.textalign.right': '文本右对齐',
    'components.controls.textalign.justify': '两端对齐',
};
