export default {
    // Generic
    'generic.add': 'Aggiungi',
    'generic.cancel': 'Annulla',
    // BlockType
    'components.controls.blocktype.h1': 'H1',
    'components.controls.blocktype.h2': 'H2',
    'components.controls.blocktype.h3': 'H3',
    'components.controls.blocktype.h4': 'H4',
    'components.controls.blocktype.h5': 'H5',
    'components.controls.blocktype.h6': 'H6',
    'components.controls.blocktype.blockquote': 'Citazione',
    'components.controls.blocktype.code': 'Codice',
    'components.controls.blocktype.blocktype': 'Stili',
    'components.controls.blocktype.normal': 'Normale',
    // Color Picker
    'components.controls.colorpicker.colorpicker': 'Colore testo',
    'components.controls.colorpicker.text': 'Testo',
    'components.controls.colorpicker.background': 'Evidenziazione',
    // Embedded
    'components.controls.embedded.embedded': 'Incorpora',
    'components.controls.embedded.embeddedlink': 'Incorpora link',
    'components.controls.embedded.enterlink': 'Inserisci link',
    // Emoji
    'components.controls.emoji.emoji': 'Emoji',
    // FontFamily
    'components.controls.fontfamily.fontfamily': 'Carattere',
    // FontSize
    'components.controls.fontsize.fontsize': 'Dimensione carattere',
    // History
    'components.controls.history.history': 'Modifiche',
    'components.controls.history.undo': 'Annulla',
    'components.controls.history.redo': 'Ripristina',
    // Image
    'components.controls.image.image': 'Immagine',
    'components.controls.image.fileUpload': 'Carica immagine',
    'components.controls.image.byURL': 'URL',
    'components.controls.image.dropFileText': 'Trascina il file o clicca per caricare',
    // Inline
    'components.controls.inline.bold': 'Grassetto',
    'components.controls.inline.italic': 'Corsivo',
    'components.controls.inline.underline': 'Sottolineato',
    'components.controls.inline.strikethrough': 'Barrato',
    'components.controls.inline.monospace': 'Monospace',
    'components.controls.inline.superscript': 'Apice',
    'components.controls.inline.subscript': 'Pedice',
    // Link
    'components.controls.link.linkTitle': 'Testo',
    'components.controls.link.linkTarget': 'Link',
    'components.controls.link.linkTargetOption': 'Apri link in una nuova finestra',
    'components.controls.link.link': 'Inserisci link',
    'components.controls.link.unlink': 'Rimuovi link',
    // List
    'components.controls.list.list': 'Lista',
    'components.controls.list.unordered': 'Elenco puntato',
    'components.controls.list.ordered': 'Elenco numerato',
    'components.controls.list.indent': 'Indent',
    'components.controls.list.outdent': 'Outdent',
    // Remove
    'components.controls.remove.remove': 'Rimuovi formattazione',
    // TextAlign
    'components.controls.textalign.textalign': 'Allineamento del testo',
    'components.controls.textalign.left': 'Allinea a sinistra',
    'components.controls.textalign.center': 'Allinea al centro',
    'components.controls.textalign.right': 'Allinea a destra',
    'components.controls.textalign.justify': 'Giustifica',
};
