var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Wrapper } from './Spinner.styles';
function Spinner(_a) {
    var _b = _a.size, size = _b === void 0 ? 'medium' : _b, _c = _a.fullPage, fullPage = _c === void 0 ? false : _c, _d = _a.id, id = _d === void 0 ? '' : _d, restProps = __rest(_a, ["size", "fullPage", "id"]);
    return (_jsx(Wrapper, __assign({ "$size": size, "$fullPage": fullPage }, restProps, { children: _jsx("div", { className: "ie-fallback", children: _jsxs("svg", { viewBox: "0 0 500 500", width: "500pt", height: "500pt", children: [_jsxs("g", { className: "spinner-line", children: [_jsx("path", { d: " M 426.894 73.41 C 381.629 28.068 319.067 0 250 0 C 112.021 0 0 112.021 0 250 L 0 250 C 0 319.067 28.068 381.629 73.41 426.894 L 123.45 376.854 C 90.92 344.401 70.781 299.534 70.781 250 L 70.781 250 C 70.781 151.087 151.087 70.781 250 70.781 C 299.534 70.781 344.401 90.92 376.854 123.45 L 426.894 73.41 Z ", fill: "rgb(0,230,95)" }), _jsx("path", { d: " M 426.894 73.41 C 472.057 118.65 500 181.088 500 250 C 500 387.979 387.979 500 250 500 C 181.088 500 118.65 472.057 73.41 426.894 L 123.45 376.854 C 155.878 409.205 200.62 429.219 250 429.219 C 348.913 429.219 429.219 348.913 429.219 250 C 429.219 200.62 409.205 155.878 376.854 123.45 L 426.894 73.41 Z ", fill: "#b4ffb4" })] }), _jsx("path", { className: "spinner-main", d: " M 426.894 73.41 C 472.057 118.65 500 181.088 500 250 C 500 387.979 387.979 500 250 500 C 181.088 500 118.65 472.057 73.41 426.894 L 123.45 376.854 C 155.878 409.205 200.62 429.219 250 429.219 C 348.913 429.219 429.219 348.913 429.219 250 C 429.219 200.62 409.205 155.878 376.854 123.45 L 426.894 73.41 Z ", fill: "rgb(30,30,35)" })] }) }) })));
}
export default Spinner;
