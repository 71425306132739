var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState, } from 'react';
var AdvancedFilterContext = createContext(null);
var AdvancedFilterProvider = function (props) {
    var _a = useState(''), advancedFilterId = _a[0], setAdvancedFilterId = _a[1];
    return (_jsx(AdvancedFilterContext.Provider
    // @ts-expect-error expect props to override value
    , __assign({ 
        // @ts-expect-error expect props to override value
        value: {
            isAdvancedFilterOpen: Boolean(advancedFilterId),
            advancedFilterId: advancedFilterId,
            setAdvancedFilterId: setAdvancedFilterId,
        } }, props)));
};
export { AdvancedFilterContext, AdvancedFilterProvider };
