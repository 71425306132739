import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
var FacetActionCreators = {
    getInsightFacetItems: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_INSIGHT_FACET_ITEMS_REQUEST,
            data: model,
        });
        WebApi.getInsightFacetItems(model);
    },
    saveInsightsFiltersUser: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.SAVE_INSIGHTS_FILTERS_USER_REQUEST,
            data: model,
        });
        WebApi.saveInsightsFiltersUser(model);
    },
};
export default FacetActionCreators;
