var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/**
 * Multi-line plain-text editing control.
 */
import classnames from 'classnames';
import { useMemo, forwardRef, useRef, } from 'react';
import { v4 as uuid } from 'uuid';
import FormElement from '@commons/FormElement';
import Scroll from '@commons/Scroll';
import { Wrapper } from './Textarea.styles';
var Textarea = forwardRef(function (_a, ref) {
    var idProp = _a.id, className = _a.className, error = _a.error, helpMessage = _a.helpMessage, label = _a.label, _b = _a.readOnly, readOnly = _b === void 0 ? false : _b, _c = _a.required, required = _c === void 0 ? false : _c, _d = _a.rows, rows = _d === void 0 ? 7 : _d, value = _a.value, _e = _a.showLabel, showLabel = _e === void 0 ? true : _e, cursorPosition = _a.cursorPosition, onBlur = _a.onBlur, onChange = _a.onChange, props = _a.props, restProps = __rest(_a, ["id", "className", "error", "helpMessage", "label", "readOnly", "required", "rows", "value", "showLabel", "cursorPosition", "onBlur", "onChange", "props"]);
    var nodeRef = ref ||
        useRef(null);
    var id = useMemo(function () { return idProp || "textarea-".concat(uuid()); }, []);
    var onFocus = function () {
        if (nodeRef.current && cursorPosition === 'end') {
            var position = nodeRef.current.value.length;
            nodeRef.current.setSelectionRange(position, position);
        }
    };
    var handleBlur = function () {
        if (onBlur)
            onBlur(nodeRef.current.value, props);
    };
    var handleChange = function () {
        if (onChange)
            onChange(nodeRef.current.value, props);
    };
    var textareaClassNames = classnames('c-textarea', className);
    return (_jsx(FormElement, { controlClassName: readOnly ? 'u-border--bottom' : '', error: error, helpMessage: helpMessage, id: id, label: label, required: required, showLabel: showLabel, children: readOnly ? (_jsx(Scroll, { children: _jsx("span", { className: "c-form-element__static", children: value }) })) : (_jsx(Wrapper, __assign({ className: textareaClassNames, id: id, onBlur: handleBlur, onChange: handleChange, ref: nodeRef, onFocus: onFocus, rows: rows, value: value, required: true }, restProps))) }));
});
Textarea.displayName = 'Textarea';
export default Textarea;
