var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ActionTypes from 'constants/actionTypes';
import BaseStore from 'stores/BaseStore';
/* eslint-disable no-underscore-dangle */
var ADD_WIDGET_EVENT = 'addWidget';
var WidgetStore = /** @class */ (function (_super) {
    __extends(WidgetStore, _super);
    function WidgetStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this._registerToActions.bind(_this); });
        return _this;
    }
    WidgetStore.prototype.initializeState = function () {
        this._widgets = [];
        this._indicators = [];
        this._types = [];
        this._widget = '';
        this._errors = [];
    };
    WidgetStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(WidgetStore.prototype, "widgets", {
        get: function () {
            return this._widgets;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WidgetStore.prototype, "widget", {
        get: function () {
            return this._widget;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WidgetStore.prototype, "indicators", {
        get: function () {
            var unique = {};
            var distinct = [];
            this.widgets.forEach(function (widget) {
                if (!unique[widget.widgetIndicatorName]) {
                    distinct.push({
                        id: widget.widgetIndicatorId,
                        name: widget.widgetIndicatorName,
                    });
                    unique[widget.widgetIndicatorName] = true;
                }
            });
            return distinct;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WidgetStore.prototype, "types", {
        get: function () {
            var unique = {};
            var distinct = [];
            this.widgets.forEach(function (widget) {
                if (!unique[widget.widgetFamilyName]) {
                    distinct.push({
                        id: widget.widgetFamilyId,
                        name: widget.widgetFamilyName,
                    });
                    unique[widget.widgetFamilyName] = true;
                }
            });
            return distinct;
        },
        enumerable: false,
        configurable: true
    });
    WidgetStore.prototype.emitAddWidget = function () {
        this.emit(ADD_WIDGET_EVENT);
    };
    WidgetStore.prototype.addWidgetListener = function (callback) {
        this.on(ADD_WIDGET_EVENT, callback);
    };
    WidgetStore.prototype.removeAddWidgetListener = function (callback) {
        this.removeListener(ADD_WIDGET_EVENT, callback);
    };
    WidgetStore.prototype._registerToActions = function (payload) {
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_WIDGETS_RESPONSE:
                if (action.errors) {
                    this._errors.push(action.errors);
                }
                else if ({}.hasOwnProperty.call(action, 'json')) {
                    this._widgets = action.json.data;
                    this.initialized = true;
                }
                this.emitChange();
                break;
            case ActionTypes.ADD_WIDGET_RESPONSE:
                if (action.errors) {
                    this._errors.push(action.errors);
                }
                else if ({}.hasOwnProperty.call(action, 'json')) {
                    this._widget = action.json;
                }
                this.emitAddWidget();
                break;
            default:
                break;
        }
    };
    return WidgetStore;
}(BaseStore));
export default new WidgetStore();
