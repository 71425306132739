var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// eslint-disable @typescript-eslint/no-unsafe-argument
import { createGuid, getUrlParameter, isKantarNamespaceExist, } from '@services/AppUtils';
import ServerActionCreators from '@actions/ServerActionCreators';
import { logOut } from '@services/AuthUtils';
import config from '@constants/parameters';
// import { history } from '../../src/App';
var TIMEOUT = 90000;
var TICKET = getUrlParameter('ticket');
var controllerPool = [];
export function abortAll() {
    controllerPool.reverse().forEach(function (controller) {
        if ('abort' in controller) {
            controller.abort();
        }
    });
}
/**
 * Get the area in which you are located.
 * @return {string} Area
 */
function getArea() {
    if (!isKantarNamespaceExist() ||
        window.location.pathname.toLowerCase().includes('/public')) {
        return 'public';
    }
    return window.km ? 'delivery' : 'public';
}
function getHash() {
    var searchParams = new URLSearchParams(location.search);
    var hash = searchParams.get('hash');
    if (hash) {
        return encodeURIComponent(hash);
    }
    return '';
}
function postFetch(url, method, model, callback, sendDataToTheStore, ignoreResponseFormat) {
    var _a, _b;
    if (sendDataToTheStore === void 0) { sendDataToTheStore = true; }
    if (ignoreResponseFormat === void 0) { ignoreResponseFormat = false; }
    var hash = getHash();
    var ticket = TICKET;
    if ((method === 'receiveGetReview' ||
        method === 'receiveGetReviewPdf' ||
        method === 'receivesetSelectedTab' ||
        method === 'receiveGetKioskReview' ||
        method === 'receiveGetClip' ||
        method === 'receivesetNewsDigestSelectedTab' ||
        method === 'receiveGetReviews' ||
        method === 'receiveGetNewsDigests') &&
        (model === null || model === void 0 ? void 0 : model.ticket)) {
        ticket = model.ticket;
    }
    if ((method === 'receiveGetDashboardTabulations' ||
        method === 'receiveGetWidgetData' ||
        method === 'receiveGetTabulationWidgets' ||
        method === 'receiveGetDashboardInfo' ||
        method === 'receiveGetWidgetMetrics' ||
        method === 'receiveGetWidgetFilterSearchDate') &&
        hash) {
        ticket = hash;
    }
    var controller = 'AbortController' in window ? new AbortController() : { signal: null };
    var signal = controller.signal;
    // @ts-expect-error: TODO: To check { signal: null } will lead to an error when abortAll
    controllerPool.push(controller);
    var headers = new Headers({
        Accept: 'application/json, charset=utf-8',
        'Content-Type': 'application/json',
        requestId: createGuid(),
        ticket: ticket,
        'X-Requested-With': 'XMLHttpRequest',
    });
    if ((_b = (_a = window.km) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.currentId) {
        headers.set('currentId', window.km.global.currentId);
    }
    var init = {
        body: model ? JSON.stringify(model) : undefined,
        credentials: 'include',
        headers: headers,
        signal: signal,
        method: 'POST',
    };
    var req = new Request("".concat(config.API_ROOT).concat(url), init);
    Promise.race([
        fetch(req, init).then(function (e) {
            if (e.status === 403 && getArea() !== 'public') {
                var confirmReload = e.headers.get('confirmReload');
                // reload if user is navigating with an old connected account
                if (confirmReload === 'true') {
                    window.localStorage.removeItem('selectedFacets');
                    window.localStorage.removeItem('isFilterOpen');
                    window.location.reload();
                }
                else {
                    window.location.pathname = '/authorization?code=550';
                }
            }
            if (e.status === 401) {
                logOut();
                localStorage.setItem('checkSession', 'inactive');
                window.location.href = '/login?inactive=true';
            }
            return e;
        }),
        new Promise(function (resolve, reject) {
            if (url === '/Delivery/Clip/AddCustomerClip') {
                setTimeout(function () {
                    reject(new Error('request timeout'));
                }, 600000);
            }
            else {
                setTimeout(function () {
                    reject(new Error('request timeout'));
                }, TIMEOUT);
            }
        }),
    ])
        .then(function (response) {
        if (!(response === null || response === void 0 ? void 0 : response.ok)) {
            // logout the user if anay request return 401 code
            if (method && typeof ServerActionCreators[method] === 'function') {
                // @ts-expect-error TODO: unify erros format to { error: string }
                ServerActionCreators[method](null, response.statusText);
            }
            return {
                code: response.status,
                message: response.statusText,
            };
        }
        return response.json();
    })
        .then(function (response) {
        if ((response && response.code === 200) || ignoreResponseFormat) {
            var json = model ? __assign(__assign({}, response), { model: model }) : response;
            if (typeof callback === 'function') {
                callback(json);
            }
            if (sendDataToTheStore && method) {
                ServerActionCreators[method](json);
            }
        }
        else {
            var json = model ? { error: response.message, model: model } : response;
            // @ts-expect-error TODO: unify erros format to { error: response.message, ...}
            if (method)
                ServerActionCreators[method](null, json);
        }
        localStorage.setItem('sessionTime', new Date().getTime().toString());
    })
        .catch(function (e) {
        var errors = model ? { error: e, model: model } : e;
        console.log('%c url ', 'color: #ff9323', url);
        if (method) {
            console.log('%c method', 'color: #ff9323', method);
            // @ts-expect-error TODO: unify erros format to { error: e, ...}
            ServerActionCreators[method](null, errors);
        }
        throw new Error(e);
    });
}
var webApi = {
    getCustomMetrics: function () {
        postFetch('/api/settingCustomerMetric/GetCustomerMetricsWithoutValues', 'receiveGetCustomMetrics');
    },
    addCustomMetrics: function (model) {
        postFetch('/api/settingCustomerMetric/InsertCustomerMetric', 'receiveAddCustomMetrics', model);
    },
    activateDeactivateCustomMetrics: function (model) {
        postFetch('/api/settingCustomerMetric/ActivateDesactivateCustomerMetric', 'receiveActivateDeactivateCustomMetrics', model);
    },
    UpdateCustomerMetric: function (model, callback) {
        postFetch('/api/settingCustomerMetric/UpdateCustomerMetric', 'receiveUpdateCustomerMetric', model, callback);
    },
    updateCustomerMetrics: function (model) {
        postFetch('/api/settingCustomerMetric/UpdateCustomerMetrics', 'receiveUpdateCustomerMetrics', model);
    },
    getCustomerMetricActiveValues: function (model) {
        postFetch('/api/settingCustomerMetric/GetCustomerMetricValues', 'receiveGetCustomerMetricActiveValues', model);
    },
    getCustomerMetricInactiveValues: function (model) {
        postFetch('/api/settingCustomerMetric/GetCustomerMetricValues', 'receiveGetCustomerMetricInactiveValues', model);
    },
    addCustomMetricsValues: function (model) {
        postFetch('/api/settingCustomerMetric/InsertCustomerMetricValues', 'receiveAddCustomMetricsValues', model);
    },
    activateDeactivateCustomMetricsValues: function (model) {
        postFetch('/api/settingCustomerMetric/ActivateDesactivateCustomerMetricValues', 'receiveActivateDesactivateCustomerMetricValues', model);
    },
    updateCustomerMetricValues: function (model, callback) {
        postFetch('/api/settingCustomerMetric/UpdateCustomerMetricValues', 'receiveUpdateCustomerMetricValues', model, callback);
    },
    getCustomerMetricRanges: function (model) {
        postFetch('/api/settingCustomerMetric/GetCustomerMetricRanges', 'receiveGetCustomerMetricRanges', model);
    },
    addCustomMetricsRanges: function (model) {
        postFetch('/api/settingCustomerMetric/InsertCustomerMetricRanges', 'receiveAddCustomMetricsRanges', model);
    },
    updateCustomerMetricRanges: function (model) {
        postFetch('/api/settingCustomerMetric/UpdateCustomerMetricRanges', 'receiveUpdateCustomerMetricRanges', model);
    },
    getAccountList: function () {
        postFetch('/api/access/agencies', 'receiveGetAccountList');
    },
    GetUserLoginInfo: function (model, callback) {
        postFetch('/api/access/GetUserLoginInfo', 'receiveGetUserLoginInfo', model, callback, true, true);
    },
    agencyLogin: function (model, callback) {
        postFetch('/api/access/agencyLogin', 'receiveGetAgencyLogin', model, callback);
    },
    addClip: function (model) {
        postFetch('/Delivery/Clip/AddCustomerClip', 'receiveAddClip', model);
    },
    resetSession: function () {
        postFetch('/Delivery/Clip/ResetSession', 'receiveResetSession');
    },
    addComment: function (model) {
        postFetch('/Clipping/AddComment', 'receiveAddComment', model);
    },
    addConsumption: function (model) {
        var url = getArea() === 'delivery' ? 'Clipping' : 'Review';
        postFetch("/".concat(url, "/AddConsumptionClip"), 'receiveAddConsumption', model);
    },
    addEvaluateConsumption: function (model) {
        var url = getArea() === 'delivery' ? 'Clipping' : 'Review';
        postFetch("/".concat(url, "/AddConsumptionClip"), 'receiveEvaluateConsumption', model);
    },
    getLang: function () {
        postFetch('/Common/GetKmplusLanguages', 'receiveGetLang');
    },
    getMicrosoftLang: function () {
        postFetch('/Common/GetMicrosoftLanguages', 'receiveGetMicrosoftLang');
    },
    addHighlight: function (model) {
        postFetch('/Clipping/AddHighlightSelection', 'receiveAddHighlight', model);
    },
    addToDraft: function (model, callback) {
        postFetch('/Draft/AddClipsToDraft', 'receiveAddToDraft', model, callback);
    },
    addWidget: function (model) {
        postFetch('/Analytics/CreateWidget', 'receiveAddWidget', model);
    },
    insightAddWidget: function (model) {
        postFetch('/Insights/AddSelectedWidgets ', 'receiveInsightsAddWidget', model);
    },
    addAsMyQuickWidget: function (model) {
        postFetch('/Insights/CreateWidgetAsMyQuickWidget ', 'receiveCreateWidgetAsMyQuickWidget', model);
    },
    getWidgetDrillDownData: function (model) {
        postFetch('/Insights/GetWidgetDrillDownData ', 'receiveGetWidgetDrillDownData', model);
    },
    getNextWidgetDrillDownData: function (model) {
        postFetch('/Insights/GetWidgetDrillDownData ', 'receiveNextWidgetDrillDownData', model);
    },
    getWidgetFilterSearchDate: function () {
        postFetch('/Insights/GetWidgetFilterSearchDate', 'receiveGetWidgetFilterSearchDate');
    },
    getDashboardInfo: function (model, callback) {
        postFetch('/api/dashboardNewController/dashboardinfo', 'receiveGetDashboardInfo', model, callback);
    },
    getSupportContacts: function () {
        postFetch('/Access/GetSupportContacts', 'receiveGetSupportContacts');
    },
    getNewsBanners: function (model) {
        postFetch('/api/productNews/loginBannerProductNews', 'receivegetNewsBanners', model);
    },
    getIsLoginSSOPage: function (model, callback) {
        postFetch('/api/access/login/IsSSO/', 'receivegetIsSSOPage', model, callback);
    },
    getLogout: function (callback) {
        postFetch('/api/access/logOff', 'receiveGetLogout', undefined, callback);
    },
    sendEmail: function (model) {
        postFetch('/Access/SendEmailForSupportContact', 'receiveSendEmailForSupportContacts', model);
    },
    categorizeClip: function (model) {
        postFetch('/Delivery/Clipping/UpdateClipSelections', 'receiveCategorizeClip', model);
    },
    createClipOrder: function (model) {
        var url = getArea() === 'delivery' ? 'Clipping' : 'Public/review';
        postFetch("/".concat(url, "/CreateOrderRequest"), 'receiveCreateClipOrder', model);
    },
    createReview: function (model, callback) {
        postFetch('/Clipping/CreateReview', 'receiveCreateReview', model, callback);
    },
    getPdfTemplates: function () {
        postFetch('/api/clip/GetUserPdfTemplates', 'receivePdfTemplates');
    },
    updateReviewPdfTemplate: function (model, callback) {
        postFetch('/Clipping/UpdateReviewPdfTemplate', 'receiveUpdateReviewPdfTemplate', model, callback, true, true);
    },
    createReviewPDF: function (model, callback) {
        postFetch('/api/pdf/getPDF', 'receiveCreateReviewPDF', model, callback, true, true);
    },
    createGlobalPDF: function (model, callback) {
        postFetch('/api/pdf/GlobalPDF', 'receiveCreateReviewPDF', model, callback, true, true);
    },
    createGetCategoryNewsDigestPDF: function (callback) {
        postFetch('/api/pdf/GetCategoryPDF', 'receiveCreateReviewPDF', null, callback, true, true);
    },
    createGlobalNewsDigestPDF: function (model, callback) {
        postFetch('/api/pdf/GetPDFNewsDigest', 'receiveCreateReviewPDF', model, callback, true, true);
    },
    createUnitPushmailPDF: function (model, callback) {
        postFetch("/api/pdf/Public/Clip/DirectPDFDownload/".concat(model.key), 'receiveCreateUnitPDF', model, callback, true, true);
    },
    createPDF: function (model, callback) {
        postFetch('/api/pdf/CreatePDF', 'receiveCreatePDF', model, callback, true, true);
    },
    createUnitPDF: function (model, callback) {
        postFetch('/api/pdf/CreateSimplePDF', 'receiveCreateUnitPDF', model, callback, false, true);
    },
    createZip: function (model, callback) {
        postFetch('/Clipping/CreateZip', 'receiveCreateZip', model, callback, true, true);
    },
    createExcel: function (model, callback) {
        postFetch('/Clipping/CreateExcel', 'receiveCreateExcel', model, callback, true, true);
    },
    getEditingRequestState: function (model, callback) {
        postFetch('/api/pdf/GetEditingRequestState', 'receiveGetEditingRequestState', model, callback, true, true);
    },
    userConfiramation: function (model, callback) {
        postFetch('/api/pdf/UserConfirmation', 'receiveUserConfirmation', model, callback, true, true);
    },
    cancelPdfGeneration: function (model, callback) {
        postFetch('/api/pdf/CancelRequest', '', model, callback, false, true);
    },
    deleteComment: function (model) {
        postFetch('/Clipping/DeleteComment', 'receiveDeleteComment', model);
    },
    deleteDraft: function (model, callback) {
        postFetch('/Draft/DeleteDraft', 'receiveDeleteDraft', model, callback);
    },
    deleteFavoriteSearch: function (model) {
        postFetch('/api/userfilteringcriteria/DeleteFavouritesSearches', 'receiveDeleteFavoriteSearch', model, undefined, true, true);
    },
    deleteHighlight: function (model) {
        postFetch('/Delivery/Clipping/DeleteHighlight', 'receiveDeleteHighlight', model);
    },
    denyArticle: function (model) {
        postFetch('/Clipping/CreateBulkDenyRequest', 'receiveDenyArticle', model);
    },
    getAllClips: function (model, totalClips, skip, clips) {
        var _this = this;
        if (skip === void 0) { skip = 0; }
        if (clips === void 0) { clips = []; }
        var MAX_SELECT_ALL = 1000;
        var searchModel = __assign(__assign({}, model), {
            searchModel: __assign(__assign({}, model.searchModel), { skip: skip, take: MAX_SELECT_ALL }),
        });
        postFetch('/Clipping/GetAllClips', 'receiveGetAllClips', searchModel, function (json) {
            var dataLength = json.data.length;
            var toSkip = skip + dataLength;
            if (dataLength === MAX_SELECT_ALL && totalClips > toSkip) {
                _this.getAllClips(model, totalClips, toSkip, __spreadArray(__spreadArray([], clips, true), json.data, true));
            }
            else {
                var modifiedJson = __assign(__assign({}, json), { data: __spreadArray(__spreadArray([], clips, true), json.data, true) });
                ServerActionCreators.receiveGetAllClips(modifiedJson);
                ServerActionCreators.receiveGetClipsRead(modifiedJson);
            }
        }, false);
    },
    getClip: function (model) {
        // const url = getArea() === 'delivery' ? 'Clipping' : 'Review';
        var url = !model.defaultTabList ? 'Clipping' : 'Review';
        postFetch("/".concat(url, "/GetClip"), 'receiveGetClip', __assign(__assign({}, model), { isNewSite: true }));
    },
    getClips: function (model) {
        postFetch('/api/clip/GetClips', 'receiveGetClips', model);
    },
    setClipSelectedTab: function (model) {
        postFetch('/api/clip/SetSelectedTab', 'receiveSetClipSelectedTab', model);
    },
    getClipSelections: function (callback) {
        postFetch('/Account/GetActualUserClipSelections', 'receiveGetClipSelections', null, callback);
    },
    getSourcesType: function () {
        postFetch('/Common/GetSupportTypes', 'receiveGetSourcesType');
    },
    getClipSelectionsByClipIds: function (model) {
        postFetch('/Delivery/Clipping/GetClipSelectionsByClipIds', 'receiveGetClipSelectionsByClipIds', model);
    },
    getEvaluation: function (model) {
        postFetch('/customermetric/EvaluateClips', 'receiveGetEvaluation', model);
    },
    savePdfExportUserSetting: function (model) {
        postFetch('/Delivery/Insights/SaveExportDashboardUserSetting', undefined, model, undefined, false, true);
    },
    getCustomerMetrics: function (model) {
        postFetch('/customermetric/GetCustomerMetrics', 'receiveGetCustomerMetrics', model);
    },
    saveCodification: function (model) {
        postFetch('/customermetric/UpdateEvaluatedClips', 'receiveSaveCodification', model);
    },
    assignToMe: function (model) {
        postFetch('/customermetric/AssignToMe', 'receiveAssignToMe', model);
    },
    unlockClips: function (model) {
        postFetch('/customermetric/UnlockClip', 'receiveUnlockClips', model);
    },
    getMediaImpactScore: function (model) {
        postFetch('/customerMetric/CalculateMediaImpactScore', 'receiveGetMediaImpactScore', model);
    },
    getDraft: function (model, callback) {
        postFetch('/api/Draft/GetDraftById', 'receiveGetDraft', model, callback);
    },
    getClipsForDraft: function (model, callback) {
        postFetch('/api/Draft/GetClipsForDraft', 'receiveGetClipsForDraft', model, callback);
    },
    getDraftReview: function (model, callback) {
        postFetch('/api/Draft/GetDraftReview', 'receiveGetDraftReview', model, callback);
    },
    getDrafts: function () {
        postFetch('/api/Draft/GetDrafts', 'receiveGetDrafts');
    },
    getInsightFacetItems: function (model) {
        postFetch('/Common/GetAdvancedSearchItems', 'receiveInsightGetFacetItems', model);
    },
    getExcelHeader: function (callback) {
        postFetch('/common/getExcelHeader', 'receiveExcelHeaderItems', undefined, callback);
    },
    getFavouriteSearches: function () {
        postFetch('/api/userfilteringcriteria/GetFavouritesSearches', 'receiveGetFavouriteSearches', undefined, undefined, true, true);
    },
    createFavoriteSearch: function (model) {
        postFetch('/api/userfilteringcriteria/CreateFavouritesSearches', 'receiveCreateFavoriteSearches', model, undefined, true, true);
    },
    getFulltextClip: function (model, callback) {
        var url = getArea() === 'delivery' ? 'Clipping' : 'Review';
        postFetch("/".concat(url, "/GetFullTextClip"), 'receiveGetFulltextClip', model, callback);
    },
    getHierarchies: function (model, callback) {
        postFetch('/Common/GetHierarchies', 'receiveGetHierarchies', model, callback);
    },
    getHierarchy: function (model) {
        postFetch('/Common/GetHierarchy', 'receiveGetHierarchy', model);
    },
    getMediaClip: function (model) {
        postFetch('/Review/GetMediaClip', 'receiveGetMediaClip', model);
    },
    getMediaClipNewsdigest: function (model) {
        postFetch('/Newsdigest/GetMediaClip', 'receiveGetMediaClipNewsdigest', model);
    },
    getNextClips: function (model) {
        postFetch('/Clipping/GetNextClips', 'receiveGetNextClips', model, function (json) {
            ServerActionCreators.receiveGetClipsRead(json);
        });
    },
    getPermissions: function (callback) {
        postFetch('/Account/GetActualUserRoles', 'receiveGetPermissions', null, callback);
    },
    getRelatedClips: function (model) {
        postFetch('/Clipping/RelatedArticles', 'receiveGetRelatedClips', model);
    },
    getReview: function (model) {
        postFetch('/api/review/GetReview', 'receiveGetReview', model);
    },
    getQueryBuilderFields: function () {
        postFetch('/Common/GetQueryBuilderSetting', 'receiveGetQueryBuilderFields');
    },
    getQueryBuilderFieldValues: function (model) {
        postFetch('/Common/GetFieldValues', 'receiveGetQueryBuilderFieldValues', model);
    },
    getNewsDigests: function (model) {
        var url = getArea() === 'delivery' ? 'api/newsDigest' : 'Public';
        postFetch("/".concat(url, "/GetNewsDigests"), 'receiveGetNewsDigests', model);
    },
    getNewsDigest: function (model) {
        postFetch('/api/newsDigest/GetNewsDigest', 'receiveGetNewsDigest', model);
    },
    getNewsDigestPdf: function (model) {
        postFetch('/newsDigest/GetPDF', 'receiveGetNewsDigestPdf', model);
    },
    getNewsDigestClip: function (model) {
        // const url = getArea() === 'delivery' ? 'Clipping' : 'Newsdigest';
        var url = !model.defaultTabList ? 'Clipping' : 'Newsdigest';
        postFetch("/".concat(url, "/GetClip"), 'receiveGetClip', model);
    },
    setNewsDigetsSelectedTab: function (model) {
        postFetch('/newsDigest/setSelectedTab', 'receivesetNewsDigestSelectedTab', model);
    },
    UpdateCopyrightDeclarations: function (model) {
        postFetch('/api/settingCopyright/UpdateCopyrightDeclarations', 'recieveUpdateCopyrightDeclarations', model);
    },
    getReviewPdf: function (model) {
        postFetch('/review/GetPDF', 'receiveGetReviewPdf', model);
    },
    getReviewPdfState: function (model) {
        postFetch('/review/GetEditingRequestState', 'receiveGetReviewPdfState', model);
    },
    getNewsDigestPdfState: function (model) {
        postFetch('/api/pdf/GetEditingRequestState', 'receiveGetNewsDigestPdfState', model);
    },
    getReviews: function (model) {
        var currentPath = window.location.href;
        var url = currentPath.toLowerCase().includes('public')
            ? 'Public'
            : 'Common';
        postFetch("/".concat(url, "/GetReviews"), 'receiveGetReviews', model);
    },
    getDashboardSnapshot: function (model, callback) {
        postFetch('/api/dashboard/GetDashboardSnapshot', 'receiveGetDashboardSnapshot', model, callback);
    },
    getCurrentUser: function (callback) {
        postFetch('/api/user/currentUserInfo', 'receiveGetCurrentUser', undefined, callback);
    },
    getProfileData: function () {
        postFetch('/Account/GetProfileData', 'receiveGetUserProfileData');
    },
    updateProfileData: function (model) {
        postFetch("/Account/UpdateUserProfileInfo?language=".concat(model.selectedLanguage), 'receiveUpdateUserProfileData', model);
    },
    updateUserPassword: function (model, callback) {
        postFetch('/account/UpdateUserPassword', 'receiveUpdateUserPassword', model, callback);
    },
    getDraftUserPreferences: function () {
        postFetch('/Draft/GetUserListPreferences ', 'receiveGetDraftUserPreferences');
    },
    getWidgets: function (model) {
        postFetch('/Analytics/GetWidgets', 'receiveGetWidgets', model);
    },
    getPushmailData: function (model) {
        postFetch('/Clipping/GetPushmailData', 'receiveGetPushmailData', model);
    },
    getUsersByGroups: function () {
        postFetch('/Common/GetUsersByGroups ', 'receiveGetUsersByGroups');
    },
    getVisualization: function (model) {
        postFetch('/Common/GetHtmlPushmail', 'receiveGetVisualization', model);
    },
    savePushmail: function (model, callback) {
        postFetch('/Common/SendPushmail', 'receiveSavePushmailData', model, callback);
    },
    removeFromDraft: function (model) {
        postFetch('/api/draft/DeleteArticleFromDraft', 'receiveRemoveFromDraft', model);
    },
    saveDraft: function (model, callback) {
        postFetch('/Draft/SaveDraft', 'receiveSaveDraft', model, callback, true, true);
    },
    saveInsightsFiltersUser: function (model) {
        postFetch('/Common/SaveFilterConfiguration', 'receiveSaveInsightFiltersUser', model);
    },
    setSelectedTab: function (model) {
        postFetch('/Review/setSelectedTab', 'receivesetSelectedTab', model);
    },
    updateClip: function (model) {
        postFetch('/Delivery/Clipping/UpdateClipDetailEdit', 'receiveUpdateClip', model);
    },
    updateComment: function (model) {
        postFetch('/Clipping/UpdateComment', 'receiveUpdateComment', model);
    },
    updateFavoriteSearch: function (model, callback) {
        postFetch('/api/userfilteringcriteria/UpdateFavouritesSearches', 'receiveUpdateFavoriteSearch', model, callback
        // true,
        // true
        );
    },
    UpdateFavorite: function (model) {
        postFetch('/Access/UpdateFavorite', 'receiveUpdateFavorite', model);
    },
    // getInsightAdvancedFilterMenu() {
    //   postFetch(
    //     '/Common/GetAllFilters',
    //     'receiveGetInsightAdvancedFilterMenu'
    //   );
    // },
    updateDraft: function (model, callback) {
        postFetch('/Draft/updateDraftWithNewHierarchy', 'receiveUpdateDraft', model, callback);
    },
    duplicateDraft: function (model, callback) {
        postFetch('/api/Draft/DuplicatDraft', 'receiveDuplicateDraft', model, callback);
    },
    updateFilter: function (model, callback) {
        postFetch('/api/facet/GetFacets', 'receiveUpdateFilter', model, callback);
    },
    updateLike: function (model) {
        postFetch('/Clipping/UpdateLike', 'receiveUpdateLike', model);
    },
    updateLikes: function (model) {
        postFetch('/Clipping/UpdateLikes', 'receiveUpdateLikes', model);
    },
    updateFavourite: function (model) {
        postFetch('/Clipping/UpdateFavourite', 'receiveUpdateFavourite', model);
    },
    updateFavourites: function (model) {
        postFetch('/Clipping/UpdateFavourites', 'receiveUpdateFavourites', model);
    },
    updateSentiments: function (model) {
        postFetch('/Clipping/UpdateSentiments', 'receiveUpdateSentiments', model);
    },
    updateUserListPreference: function (model) {
        postFetch('/Draft/UpdateUserListPreference', 'receiveUpdateUserListPreference', model);
    },
    translateClip: function (model) {
        postFetch('/Common/TranslateText', 'receiveTranslateText', model);
    },
    updateTrash: function (model) {
        postFetch('/Clipping/UpdateTrashed', 'receiveUpdateTrash', model);
    },
    getFavourites: function (model) {
        postFetch('/Clipping/GetFavorites', 'receiveGetFavorites', model);
    },
    getDashboards: function () {
        postFetch('/Insights/GetDashboards', 'receiveGetDashboards');
    },
    getCreateWidgetTypesModel: function (model) {
        postFetch('/Insights/GetWidgetTypesModel', 'receiveGetCreateWidgetModel', model);
    },
    getWidgetDimensions: function (model) {
        postFetch('/Insights/GetWidgetMetrics', 'receiveGetWidgetMetrics', model);
    },
    getDashboardTabulations: function (model) {
        postFetch('/Insights/GetDashboardTabulations', 'receiveGetDashboardTabulations', model);
    },
    getTabulationWidgets: function (model) {
        postFetch('/Insights/GetTabulationWidgets', 'receiveGetTabulationWidgets', model);
    },
    updateDashboardName: function (model) {
        postFetch('/Insights/UpdateDashboard', 'receiveUpdateDashboardName', model);
    },
    generateDuplicateDashboardName: function (model) {
        postFetch('/Insights/GenerateDashboardName', 'receiveGenerateDuplicateDashboardName', model);
    },
    generateDuplicateTabulationName: function (model) {
        postFetch('/Insights/GenerateTabulationName', 'receiveGenerateDuplicateTabulationName', model);
    },
    removeDashboard: function (model, callback) {
        postFetch('/Insights/DeleteDashboard', 'receiveDeleteDashboard', model, callback);
    },
    addTab: function (model) {
        postFetch('/Insights/InsertTabulation', 'receiveAddTab', model);
    },
    addDashboard: function (model) {
        postFetch('/Insights/CreateDashboard', 'receiveAddDashboard', model);
    },
    updateTab: function (model) {
        postFetch('/Insights/UpdateTabulation', 'receiveUpdateTab', model);
    },
    downloadTabImage: function (model) {
        postFetch('/Insights/ExportChartsTozip', 'receiveDownloadTabImage', model);
    },
    duplicateDashboard: function (model) {
        postFetch('/Insights/DuplicateDashboard', 'receiveDuplicateDashboard', model);
    },
    exportDashboardSnapshot: function (model) {
        postFetch('/DashboardNew/CreateDashboardSnapshot', 'receiveExportDashboardSnapshot', model);
    },
    createWidget: function (model) {
        postFetch('/Insights/CreateWidget', 'receiveCreateWidget', model);
    },
    updateWidget: function (model) {
        postFetch('/Insights/UpdateWidget', 'receiveUpdateWidget', model);
    },
    getWidgetData: function (model) {
        postFetch('/Insights/GetWidgetData', 'receiveGetWidgetData', model);
    },
    getQuickWidgets: function () {
        postFetch('/Insights/GetQuickWidgets', 'receiveGetQuickWidgets');
    },
    deleteTabulation: function (model) {
        postFetch('/Insights/DeleteTabulation', 'receiveDeleteTabulation', model);
    },
    setDefaultDashboard: function (model) {
        postFetch('/Insights/UpdateDefaultDashboard', 'receiveSetDefaultDashboard', model);
    },
    setTabulationOrder: function (model) {
        postFetch('/Insights/SetTabulationsOrder', 'receiveSetTabulationOrder', model);
    },
    copyTabulation: function (model) {
        postFetch('/Insights/DuplicateTabulation', 'receiveCopyTabulation', model);
    },
    renameTabulation: function (model) {
        postFetch('/Insights/RenameTabulation', 'receiveRenameTabulation', model);
    },
    sortWidgets: function (model) {
        postFetch('/Insights/SetWidgetsOrder', 'receiveSetWidgetOrder', model);
    },
    deleteWidget: function (model) {
        postFetch('/Insights/DeleteWidget', 'receiveDeleteWidget', model);
    },
    copyWidget: function (model) {
        postFetch('/Insights/CreateWidget', 'receiveCopyWidget', model);
    },
    updateWidgetSettings: function (model, callback) {
        postFetch('/Insights/UpdateWidgetSettings', 'receiveUpdateWidgetSettings', model, callback);
    },
    getNextTimelineData: function (model) {
        postFetch('/Insights/GetWidgetData', 'receiveGetNextTimelineData', model);
    },
    getHashDashboard: function (model) {
        postFetch('/DashboardNew/CreateHashDashboard', 'receiveDashBoardHash', model);
    },
    getKiosks: function (model) {
        postFetch('/api/review/GetKiosque', 'receiveGetKiosks', model);
    },
    getKioskReview: function (model) {
        postFetch('/api/review/GetKiosqueReview', 'receiveGetKioskReview', model);
    },
    editKioskReview: function (model, callback) {
        postFetch('/api/review/EditReview', 'receiveEditKioskReview', model, callback);
    },
    editKioskNewsGigest: function (model, callback) {
        postFetch('/api/newsDigest/EditNewDigest', 'receiveEditKioskNewDigest', model, callback);
    },
    editKioskInfographi: function (model, callback) {
        postFetch('/api/infography/EditInfography', 'receiveEditKioskInfography', model, callback);
    },
    editKioskDashboard: function (model, callback) {
        postFetch('/api/dashboard/EditDashboard', 'receiveEditKioskDashboard', model, callback);
    },
    removeKioskDashboard: function (model, callback) {
        postFetch('/api/dashboard/RemoveDashboard', 'receiveRemoveKioskDashboard', model, callback);
    },
    removeKioskInfography: function (model, callback) {
        postFetch('/api/infography/RemoveInfography', 'receiveRemoveKioskInfography', model, callback);
    },
    removeKioskReview: function (model, callback) {
        postFetch('/api/review/RemoveReview', 'receiveRemoveKioskReview', model, callback);
    },
    removeKioskNewsGigest: function (model, callback) {
        postFetch('/api/newsDigest/RemoveNewDigest', 'receiveRemoveKioskNewDigest', model, callback);
    },
    accessReviewByType: function (model) {
        var newModel = model.ticket ? model : __assign(__assign({}, model), { ticket: TICKET });
        postFetch('/api/review/AccessReviewByType', 'receiveAccessReviewByType', newModel);
    },
    getKioskNewsDigest: function (model) {
        postFetch('/api/newsDigest/GetKioskNewsDigest', 'receiveGetKioskNewsDigest', model);
    },
    accessNewsDigestByType: function (model) {
        // const newModel = model.ticket ? model : { ...model, ticket: TICKET };
        postFetch('/api/newsDigest/AccessNewsDigestByType', 'receiveAccessNewsDigestByType', model);
    },
    deleteKioskElement: function (model) {
        postFetch('/Kiosque/RemoveKiosqueElement', 'receiveDeleteKiosks', model);
    },
    updateKioskElement: function (model, callback) {
        postFetch('/Kiosque/EditKiosqueElement', 'receiveUpdateKiosks', model, callback);
    },
    getUserCountries: function () {
        postFetch('/common/GetCountries', 'receiveGetUserCountries');
    },
    getProductNews: function (model) {
        postFetch('/api/productNews/GetProductNews', 'receiveGetProductNews', model);
    },
    getProductNewsById: function (model) {
        postFetch('/api/productNews/GetProductNewsById', 'receiveGetProductNewsById', model);
    },
    getRelatedProductNews: function (model) {
        postFetch('/api/productNews/getRelatedProductNews', 'receiveRelatedProductNews', model);
    },
    markAllProductAsRead: function (model, callback) {
        postFetch('/api/productNews/MarkNewsAsRead', 'receiveMarkAllProductsAsRead', model, callback);
    },
    getProductNewsTypes: function () {
        postFetch('/api/productNews/productNewsTypes', 'receiveGetProductNewsTypes');
    },
    getUnreadableCountProductNews: function () {
        postFetch('/api/productNews/GetUnReadNewsCount', 'receiveUnreadableCountProductNews');
    },
    GetPercolators: function () {
        postFetch('/api/settingAutoTagging/GetPercolators', 'receiveGetPercolators');
    },
    getPercolatorRetroactively: function () {
        postFetch('/api/settingAutoTagging/GetPercolatorRetroactivelyByAccountID', 'receiveGetPercolatorRetroactively');
    },
    savePercolatorRetroactively: function (model) {
        postFetch('/api/settingAutoTagging/SavePercolatorRetroactively', 'receiveSavePercolatorRetroactively', model);
    },
    deletePercolatorRetroactively: function (model) {
        postFetch('/api/settingAutoTagging/DeletePercolatorRetroactively', 'receiveDeletePercolatorRetroactively', model);
    },
    cancelPercolatorRetroactively: function (model) {
        postFetch('/api/settingAutoTagging/CancelPercolatorRetroactively', 'receiveCancelPercolatorRetroactively', model);
    },
    getQueryStringPercolator: function (model) {
        postFetch('/api/settingAutoTagging/GetQueryStringPercolator', 'receiveGetQueryStringPercolator', model);
    },
    activateDesactivatePercolator: function (model) {
        postFetch('/api/settingAutoTagging/ActivateDesactivatePercolator', 'receiveActivateDesactivatePercolator', model);
    },
    deletePercolator: function (model) {
        postFetch('/api/settingAutoTagging/DeletePercolator', 'receiveDeletePercolator', model);
    },
    insertPercolator: function (model) {
        postFetch('/api/settingAutoTagging/InsertPercolator', 'receiveInsertPercolator', model);
    },
    testPercolator: function (model) {
        postFetch('/api/settingAutoTagging/TestPercolator', 'receiveTestPercolator', model);
    },
    checkPercolator: function (model) {
        postFetch('/api/settingAutoTagging/CheckPercolator', 'receiveCheckPercolator', model);
    },
    getAutoTaggingInfos: function () {
        postFetch('/api/settingAutoTagging/GetAutoTaggingInfos', 'receiveGetAutoTaggingInfos');
    },
    getAllFilters: function () {
        postFetch('/Common/GetAllFilters', 'receiveGetAllFilters');
    },
    getCookiesAndPrivacy: function () {
        postFetch('/delivery/Account/GetCookieAndPrivacyPolicies', 'recieveGetCookiesAndPrivacy');
    },
    GetUsersGroupsProfile: function () {
        postFetch('/api/settinguser/GetUsersGroupsProfile', 'recieveGetUsersGroupsProfile');
    },
    GetGroupsRolesCategoriesByUserAccount: function (model) {
        postFetch('/api/settinguser/GetGroupsRolesCategoriesByUserAccount', 'recieveGetGroupsRolesCategoriesByUserAccount', model);
    },
    deleteUser: function (model, callback) {
        postFetch('/api/settinguser/DeleteUserAccount', 'recieveDeleteUser', model, callback);
    },
    insertNewUser: function (model, callback) {
        postFetch('/api/settinguser/InsertUser', 'recieveInsertUser', model, callback);
    },
    GetProfiles: function () {
        postFetch('/api/settingDistributionProfile/GetProfiles', 'recieveGetProfiles');
    },
    GetExportedInfoUsersListsExcel: function (model) {
        postFetch('/api/settinguser/GetExportedInfoUsersListsExcel', 'recieveGetExportedInfoUsersListsExcel', model);
    },
    uploadFreeFomrImage: function (model, callback) {
        postFetch('/Insights/uploadFreeFomrImage', '', model, callback, false);
    },
    GetInfoGroupsList: function () {
        postFetch('/api/settinguser/GetInfoGroupsList', 'recieveGetInfoGroupsList');
    },
    UpdateGroupUserMembership: function (model, callback) {
        postFetch('/api/settinguser/UpdateGroupUserMembership', 'recieveUpdateGroupUserMembership', model, callback);
    },
    getEmailAlerts: function (model) {
        postFetch('/api/settingEmailAlerts/GetEmailAlerts', 'recieveGetEmailAlerts', model);
    },
    getEmailAlertsPushMailLayout: function () {
        postFetch('/api/settingEmailAlerts/GetPushmailLayoutsAccount', 'recieveGetEmailAlertsPushMailLayout');
    },
    getEmailAlertsUserClipSelection: function () {
        postFetch('/api/settingEmailAlerts/GetUserClipSelection', 'recieveGetEmailAlertsUserClipSelection');
    },
    activateDesactivateEmailAlert: function (model) {
        postFetch('/api/settingEmailAlerts/ActivateDesactivateEmailAlert', 'recieveActivateDesactivateEmailAlert', model);
    },
    deleteEmailAlert: function (model) {
        postFetch('/api/settingEmailAlerts/DeleteEmailAlert', 'recieveDeleteEmailAlert', model);
    },
    getEmailAlert: function (model) {
        postFetch('/api/settingEmailAlerts/GetEmailAlert', 'recieveGetEmailAlert', model);
    },
    updateEmailAlert: function (model) {
        postFetch('/api/settingEmailAlerts/UpdateEmailAlert', 'recieveUpdateEmailAlert', model);
    },
    insertEmailAlert: function (model) {
        postFetch('/api/settingEmailAlerts/CreateEmailAlert', 'recieveCreateEmailAlert', model);
    },
    updateUserBranding: function (model, callback) {
        postFetch('/api/settingBranding/UpdateAccountBranding', 'recieveUpdateBranding', model, callback);
    },
    getCategories: function () {
        postFetch('/api/SettingCategories/categories', 'recieveGetCategories');
    },
    getCategoryGroups: function (model, callback) {
        postFetch('/api/SettingCategories/GetCategoryAuthorizations', 'recieveGetCategoriyGroups', model, callback);
    },
    getCategoryGroupsMembers: function (model) {
        postFetch('/api/SettingCategories/GetCategoryGroupsMembers', 'recieveGetCategoryGroupsMembers', model);
    },
    AddClipSelection: function (model, callback) {
        postFetch('/api/SettingCategories/AddClipSelection', 'recieveAddClipSelection', model, callback);
    },
    UpdateClipSelection: function (model, callback) {
        postFetch('/api/SettingCategories/UpdateClipSelection', 'recieveUpdateClipSelection', model, callback);
    },
    DeleteClipSelection: function (model, callback) {
        postFetch('/api/SettingCategories/DeleteClipSelection', 'recieveDeleteClipSelection', model, callback);
    },
    SetClipSelectionAuthorization: function (model, callback) {
        postFetch('/api/SettingCategories/SetClipSelectionAuthorization', 'recieveSetClipSelectionAuthorization', model, callback);
    },
    GetCopyrightDeclarations: function () {
        postFetch('/api/settingCopyright/GetCopyrightDeclarations', 'recieveGetCopyrightDeclarations');
    },
    GetTopVisualizationsBySource: function (model) {
        postFetch('/api/settingVisualization/GetTopVisualizationsBySource', 'recieveGetTopVisualizationsBySource', model);
    },
    GetTopVisualizationsByClip: function (model) {
        postFetch('/api/settingVisualization/GetTopVisualizationsByClip', 'recieveGetTopVisualizationsByClip', model);
    },
    GetTopVisualizationsByDate: function (model) {
        postFetch('/api/settingVisualization/GetTopVisualizationsByDate', 'recieveGetTopVisualizationsByDate', model);
    },
    GetDistributions: function () {
        postFetch('/api/settingDistributionProfile/GetDistributions', 'recieveGetDistributions');
    },
    GetDistributionMembers: function (model, callback) {
        postFetch('/api/settingDistributionProfile/GetDistributionMembers', 'recieveGetDistributionMembers', model, callback);
    },
    GetDistributionAuthorizations: function (model) {
        postFetch('/api/settingDistributionProfile/GetDistributionAuthorizations', 'recieveGetDistributionAuthorizations', model);
    },
    GetProfileById: function (model) {
        postFetch('/api/settingDistributionProfile/GetProfileById', 'recieveGetProfileById', model);
    },
    UpdateProfileRoles: function (model, callback) {
        postFetch('/api/settingDistributionProfile/UpdateProfileRoles', 'recieveUpdateProfileRoles', model, callback);
    },
    CreateProfile: function (model) {
        postFetch('/api/settingDistributionProfile/CreateProfile', 'recieveCreateProfile', model);
    },
    UpdateProfile: function (model) {
        postFetch('/api/settingDistributionProfile/UpdateProfile', 'recieveUpdateProfile', model);
    },
    DeleteProfile: function (model) {
        postFetch('/api/settingDistributionProfile/DeleteProfile', 'recieveDeleteProfile', model);
    },
    CreateDistribution: function (model) {
        postFetch('/api/settingDistributionProfile/CreateDistribution', 'recieveCreateDistribution', model);
    },
    UpdateDistribution: function (model) {
        postFetch('/api/settingDistributionProfile/UpdateDistribution', 'recieveUpdateDistribution', model);
    },
    DeleteDistribution: function (model) {
        postFetch('/api/settingDistributionProfile/DeleteDistribution', 'recieveDeleteDistribution', model);
    },
    DeleteGroupMember: function (model) {
        postFetch('/api/settingDistributionProfile/DeleteGroupMember', 'recieveDeleteGroupMember', model);
    },
    DeleteUserProfile: function (model) {
        postFetch('/api/settingDistributionProfile/DeleteUserProfile', 'recieveDeleteUserProfile', model);
    },
    UpdateUserProfile: function (model) {
        postFetch('/api/settingDistributionProfile/UpdateUserProfile', 'recieveUpdateUserProfile', model);
    },
    createHierarchy: function (model, callback) {
        postFetch('/api/settingPressReviewTemplate/createHierarchy', 'recieveCreateHierarchy', model, callback, true, true);
    },
    saveHierarchy: function (model, callback) {
        postFetch('/api/settingPressReviewTemplate/saveHierarchy', 'receiveSaveHierarchy', model, callback);
    },
    deleteHierarchy: function (model, callback) {
        postFetch('/api/settingPressReviewTemplate/deleteHierarchy', 'receiveDeleteHierarchy', model, callback);
    },
    changeUserAccountStatus: function (model, callback) {
        postFetch('/api/settinguser/changeUserAccountStatus', 'recieveChangeUserAccountStatus', model, callback);
    },
    getInforgraphy: function () {
        postFetch('/api/infography/GetInfographySettings', 'receiveGetInforgraphy');
    },
    getWidgetInforgraphy: function (model, callback) {
        postFetch('/api/infography/GetWidgetData', 'receiveGetWidgetInforgraphy', model, callback, true, true);
    },
    InsertInfographySettings: function (model, callback) {
        postFetch('/api/infography/InsertInfographySettings', 'recieveInsertInfographySettings', model, callback);
    },
    ConverInfographyToLink: function (model, callback) {
        postFetch('/api/infography/ConverInfographyToLink', 'recieveConvertInfoGraphyToLink', model, callback, true, true);
    },
    ConvertInfographyToPDF: function (model, callback) {
        postFetch('/delivery/infography/ConverInfographyToPDF', 'recieveConvertInfoGraphyToPdf', model, callback, true, true);
    },
    GetInforgraphyByHash: function (model, callback) {
        postFetch('/api/infography/GetInforgraphyByHash', 'recieveGetInforgraphyByHash', model, callback);
    },
    RemoveInfography: function (model, callback) {
        postFetch('/api/infography/RemoveInfography', 'recieveRemoveInfography', model, callback);
    },
    GetIncidentMessage: function () {
        postFetch('/api/productNews/GetIncidentMessage', 'recieveGetIncidentMessage');
    },
    updateClipsAlsoIn: function (model) {
        postFetch('/api/review/UpdateClipsAlsoIn', 'receiveUpdateClipsAlsoIn', model);
    },
    updateUsername: function (model, callback) {
        postFetch('/api/user/updateusername', 'recieveUpdateUsername', model, callback, true, true);
    },
};
export default webApi;
