var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
 * Input is used to create interactive controls for web-based forms
 * in order to accept data from the user.
 */
import classnames from 'classnames';
import { useRef, useEffect, forwardRef, useImperativeHandle, } from 'react';
import { v4 as uuid } from 'uuid';
import ButtonIcon from '@commons/ButtonIcon';
import FormElement from '@commons/FormElement';
import FormElementInline from '@commons/FormElementInline';
import Svg from '@commons/Svg';
import { Wrapper } from './Input.styles';
var Input = forwardRef(function (inputProps, ref) {
    var _a = inputProps.className, className = _a === void 0 ? '' : _a, _b = inputProps.clearable, clearable = _b === void 0 ? false : _b, error = inputProps.error, _c = inputProps.hasError, hasError = _c === void 0 ? false : _c, helpMessage = inputProps.helpMessage, _d = inputProps.iconLeft, IconLeft = _d === void 0 ? null : _d, _e = inputProps.iconRight, IconRight = _e === void 0 ? null : _e, _f = inputProps.label, label = _f === void 0 ? '' : _f, _g = inputProps.readOnly, readOnly = _g === void 0 ? false : _g, _h = inputProps.required, required = _h === void 0 ? false : _h, value = inputProps.value, _j = inputProps.disableAutocomplete, disableAutocomplete = _j === void 0 ? false : _j, _k = inputProps.autoFocus, autoFocus = _k === void 0 ? false : _k, style = inputProps.style, _l = inputProps.showLabel, showLabel = _l === void 0 ? true : _l, _m = inputProps.inline, inline = _m === void 0 ? false : _m, _o = inputProps.autoComplete, autoComplete = _o === void 0 ? 'off' : _o, _p = inputProps.hideBackground, hideBackground = _p === void 0 ? false : _p, onFocus = inputProps.onFocus, onChange = inputProps.onChange, _q = inputProps.props, props = _q === void 0 ? {} : _q, onBlur = inputProps.onBlur, id = inputProps.id, _r = inputProps.preventDefault, preventDefault = _r === void 0 ? false : _r, restProps = __rest(inputProps, ["className", "clearable", "error", "hasError", "helpMessage", "iconLeft", "iconRight", "label", "readOnly", "required", "value", "disableAutocomplete", "autoFocus", "style", "showLabel", "inline", "autoComplete", "hideBackground", "onFocus", "onChange", "props", "onBlur", "id", "preventDefault"]);
    var inputRef = useRef(null);
    useImperativeHandle(ref, function () { return ({
        focus: function () {
            if (inputRef.current)
                inputRef.current.focus();
        },
        inputRef: inputRef,
    }); });
    useEffect(function () {
        if (autoFocus && inputRef.current)
            inputRef.current.focus();
    }, []);
    var onKeyPressHandler = function (e) {
        if (e.key === 'Enter' && preventDefault) {
            e.preventDefault();
        }
    };
    var handleBlur = function (event) {
        if (typeof onBlur === 'function') {
            onBlur(event.target.value, props);
        }
    };
    var handleChange = function (event) {
        if (typeof onChange === 'function') {
            onChange(event.target.value, props);
        }
    };
    var handleClear = function () {
        if (typeof onChange === 'function') {
            onChange('', props);
        }
    };
    var handleFocus = function (event) {
        if (typeof onFocus === 'function') {
            onFocus(event.target.value, props);
        }
    };
    var renderClear = function () {
        return clearable && !!value ? (_jsx(ButtonIcon
        // icon="close"x
        , { 
            // icon="close"x
            className: "c-input__icon c-input__icon--clearable c-input__icon--right", onClick: handleClear, title: "Clear button", children: _jsx(Svg, { icon: "close", size: "x-small" }) })) : null;
    };
    var renderLeftIcon = function () {
        if (typeof IconLeft === 'string') {
            return (_jsx(Svg, { className: "c-input__icon c-input__icon--left", icon: IconLeft }));
        }
        if (typeof IconLeft === 'function') {
            return (_jsx(IconLeft, { className: "c-input__icon c-input__icon--lef c-input__react-icon", size: "20" }));
        }
        return null;
    };
    var renderRightIcon = function () {
        if (typeof IconRight === 'string') {
            return (_jsx(Svg, { className: "c-input__icon c-input__icon--right", icon: IconRight }));
        }
        if (typeof IconRight === 'function') {
            return (_jsx(IconRight, { className: "c-input__icon c-input__icon--right c-input__react-icon", size: "20" }));
        }
        return null;
    };
    var unqueId = id !== null && id !== void 0 ? id : "input-".concat(uuid());
    var inputClassNames = classnames('c-input', { 'c-input--hide-background': hideBackground }, { 'has-error': (error != null && error !== '') || hasError }, className);
    // const additionalProps = ObjectUtils.omit(restProps, Object.keys(propTypes));
    return (_jsx(_Fragment, { children: (inline && (_jsxs(FormElementInline, { controlClassName: readOnly ? 'u-border--bottom' : '', error: error, hasError: hasError, hasLeftIcon: IconLeft !== null, hasRightIcon: IconRight !== null || clearable, helpMessage: helpMessage, id: unqueId || id, label: label, required: required, showLabel: showLabel, children: [renderLeftIcon(), readOnly ? (_jsx("span", { className: "c-form-element__static", children: value })) : (_jsx(Wrapper, __assign({ ref: inputRef, className: inputClassNames, id: unqueId || id, onBlur: handleBlur, onChange: handleChange, onFocus: handleFocus, onKeyPress: onKeyPressHandler, value: value, readOnly: disableAutocomplete, style: style, autoComplete: autoComplete, required: required, "aria-required": required }, restProps))), renderClear(), renderRightIcon()] }))) || (_jsxs(FormElement, { controlClassName: readOnly ? 'u-border--bottom' : '', error: error, hasError: hasError, hasLeftIcon: IconLeft !== null, hasRightIcon: IconRight !== null || clearable, helpMessage: helpMessage, id: id !== null && id !== void 0 ? id : props.id, label: label, required: required, showLabel: showLabel, children: [renderLeftIcon(), readOnly ? (_jsx("span", { className: "c-form-element__static", children: value })) : (_jsx(Wrapper, __assign({ className: inputClassNames, ref: inputRef, id: unqueId || id, onBlur: handleBlur, onChange: handleChange, onFocus: handleFocus, onKeyPress: onKeyPressHandler, value: value, readOnly: disableAutocomplete, style: style, required: required }, restProps))), renderClear(), renderRightIcon()] })) }));
});
Input.displayName = 'Input';
export default Input;
