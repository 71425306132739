import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getRuleById } from 'new-components/QueryBuilderConfiguration/QueryBuilderUtils';
import Rule from './Rule';
import CombinatorsGroup from './controls/CombinatorsGroup';
import { ActionElement } from './controls';
function RuleGroup(_a) {
    var id = _a.id, parentId = _a.parentId, combinator = _a.combinator, rules = _a.rules, translations = _a.translations, schema = _a.schema, onRuleChange = _a.onRuleChange, parentCombinator = _a.parentCombinator, query = _a.query, index = _a.index, lastRuleId = _a.lastRuleId;
    var intl = useIntl();
    var formatMessage = intl.formatMessage;
    var onPropChange = schema.onPropChange, createRule = schema.createRule, onRuleAdd = schema.onRuleAdd, onRuleRemove = schema.onRuleRemove, getLevel = schema.getLevel, classNames = schema.classNames, onGroupRemove = schema.onGroupRemove, createRuleGroup = schema.createRuleGroup, onGroupAdd = schema.onGroupAdd;
    var onCombinatorChange = function (value) {
        onPropChange('combinator', value, id);
    };
    var hasParentGroup = function () {
        return parentId;
    };
    var addRule = function (event) {
        event.preventDefault();
        event.stopPropagation();
        var newRule = createRule();
        onRuleAdd(newRule, id);
    };
    var addGroup = function (event) {
        event.preventDefault();
        event.stopPropagation();
        var newGroup = createRuleGroup();
        onGroupAdd(newGroup, id);
    };
    var removeGroup = function (event) {
        event.preventDefault();
        event.stopPropagation();
        onGroupRemove(id, parentId);
    };
    var findRule = function (ruleId) {
        var result = getRuleById(query, ruleId);
        return result;
    };
    var combinators = [
        {
            type: 'button',
            handleClick: onCombinatorChange,
            disabled: false,
            name: 'not',
            buttonClassNames: 'btn btn--default',
            title: formatMessage({ id: 'QueryBuilder.not' }),
        },
        {
            type: 'radio-button',
            children: [
                {
                    type: 'radio',
                    handleClick: onCombinatorChange,
                    disabled: rules && rules.length <= 1,
                    name: 'and',
                    buttonClassNames: 'btn btn--primary',
                    title: formatMessage({ id: 'QueryBuilder.and' }),
                    checked: 'checked',
                },
                {
                    type: 'radio',
                    handleClick: onCombinatorChange,
                    disabled: rules && rules.length <= 1,
                    name: 'or',
                    buttonClassNames: 'btn btn--primary',
                    title: formatMessage({ id: 'QueryBuilder.or' }),
                },
            ],
        },
    ];
    var level = getLevel(id);
    return (_jsxs("div", { className: "ruleGroup ".concat(classNames.ruleGroup), children: [(parentCombinator && (_jsx("div", { className: "group_operator_label", children: _jsx("span", { className: "group_operator_parent-text", children: (parentCombinator &&
                        parentCombinator.ops &&
                        index > 0 &&
                        formatMessage({
                            id: "QueryBuilder.".concat(parentCombinator.ops),
                        })) ||
                        formatMessage({
                            id: 'QueryBuilder.select',
                        }) }) }))) || (_jsx("div", { className: "group_operator_label btn--primary", children: _jsx("span", { className: "group_operator_parent-text", children: formatMessage({
                        id: 'QueryBuilder.select',
                    }) }) })), _jsxs("div", { className: "ruleGroup__actions", children: [_jsx("div", { className: "ruleGroup__actions-selector", children: _jsx(CombinatorsGroup, { combinators: combinators, combinator: combinator }) }), _jsxs("div", { className: "ruleGroup__actions-wrapper", children: [_jsx(ActionElement, { id: translations.addRule.id, label: translations.addRule.label, title: translations.addRule.title, className: "ruleGroup-addRule ".concat(classNames.addRule), handleOnClick: addRule, rules: rules, level: level }), _jsx(ActionElement, { id: translations.addGroup.id, label: translations.addGroup.label, title: translations.addGroup.title, className: "ruleGroup-addGroup ".concat(classNames.addGroup), handleOnClick: addGroup, rules: rules, level: level, disabled: level === 2 }), hasParentGroup() ? (_jsx(ActionElement, { id: translations.removeGroup.id, label: translations.removeGroup.label, title: translations.removeGroup.title, className: "ruleGroup-remove ".concat(classNames.removeGroup), handleOnClick: removeGroup, rules: rules, level: level })) : null] })] }), _jsx("div", { className: "ruleGroup__children", children: rules.map(function (r, i) {
                    return r.isGroup ? (_jsx(Fragment, { children: _jsx(RuleGroup, { intl: intl, id: r.id, index: i, schema: schema, parentId: id, parentCombinator: combinator, combinator: r.combinator, translations: translations, rules: r.rules, onRuleChange: onRuleChange, findRuleById: findRule, lastRuleId: lastRuleId }, r.id) }, i)) : (_jsx(Rule, { id: r.id, index: i, field: r.field, value: r.value, ops: r.ops, schema: schema, parentId: id, translations: translations, onRuleRemove: onRuleRemove, onRuleChange: onRuleChange, combinator: combinator, findRuleById: findRule, lastRuleId: lastRuleId, mode: "percolator" }, r.id));
                }) })] }));
}
RuleGroup.propTypes = {
    id: PropTypes.string.isRequired,
    parentId: PropTypes.string,
    index: PropTypes.number,
    onRuleChange: PropTypes.func.isRequired,
    rules: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        field: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(Date),
            PropTypes.shape({
                startDate: PropTypes.instanceOf(moment),
                endDate: PropTypes.instanceOf(moment),
            }),
        ]),
        value: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                label: PropTypes.string,
            })),
            PropTypes.string,
            PropTypes.instanceOf(Date),
            PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.string,
            }),
        ]),
        ops: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                name: PropTypes.string,
                label: PropTypes.string,
            }),
        ]),
    })),
    translations: PropTypes.shape({
        fields: PropTypes.shape({
            title: PropTypes.string.isRequired,
        }).isRequired,
        operators: PropTypes.shape({
            title: PropTypes.string.isRequired,
        }).isRequired,
        value: PropTypes.shape({
            title: PropTypes.string.isRequired,
        }).isRequired,
        removeRule: PropTypes.shape({
            title: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired,
        removeGroup: PropTypes.shape({
            title: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired,
        addRule: PropTypes.shape({
            title: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired,
        addGroup: PropTypes.shape({
            title: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    combinator: PropTypes.shape({
        ops: PropTypes.string.isRequired,
        not: PropTypes.bool.isRequired,
    }).isRequired,
    parentCombinator: PropTypes.shape({
        ops: PropTypes.string.isRequired,
        not: PropTypes.bool.isRequired,
    }),
    schema: PropTypes.shape({
        fields: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.instanceOf(Date),
                PropTypes.shape({
                    startDate: PropTypes.instanceOf(moment),
                    endDate: PropTypes.instanceOf(moment),
                }),
            ]),
        })).isRequired,
        operators: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
        })).isRequired,
        classNames: PropTypes.shape({
            queryBuilder: PropTypes.string,
            ruleGroup: PropTypes.string,
            addRule: PropTypes.string,
            addGroup: PropTypes.string,
            removeGroup: PropTypes.string,
            rule: PropTypes.string,
            fields: PropTypes.string,
            operators: PropTypes.string,
            value: PropTypes.string,
            removeRule: PropTypes.string,
        }),
        createRule: PropTypes.func.isRequired,
        createRuleGroup: PropTypes.func.isRequired,
        onRuleAdd: PropTypes.func.isRequired,
        onGroupAdd: PropTypes.func.isRequired,
        onRuleRemove: PropTypes.func.isRequired,
        onGroupRemove: PropTypes.func.isRequired,
        onPropChange: PropTypes.func.isRequired,
        getLevel: PropTypes.func.isRequired,
        isRuleGroup: PropTypes.func.isRequired,
        controls: PropTypes.shape({
            addGroupAction: PropTypes.func,
            addRuleAction: PropTypes.func,
            combinatorSelector: PropTypes.func,
            fieldSelector: PropTypes.func,
            operatorSelector: PropTypes.func,
            removeGroupAction: PropTypes.func,
            removeRuleAction: PropTypes.func,
            valueEditor: PropTypes.func,
        }).isRequired,
        getOperators: PropTypes.func.isRequired,
    }).isRequired,
};
RuleGroup.defaultProps = {
    rules: [],
    parentId: null,
    index: 0,
    parentCombinator: {
        ops: '',
        not: false,
    },
};
export default RuleGroup;
