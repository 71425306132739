var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { validateEmail } from 'services/AppUtils';
import { ModalHeader, ModalBody, ModalFooter } from 'commons/Modal';
import Button from 'commons/Button';
import Input from 'commons/Input';
import Svg from 'commons/Svg';
import UserActionCreators from 'actions/UserActionCreators';
import UserStore from 'stores/UserStore';
import { SnackbarContext } from 'context/SnackbarContext';
import { ModalStyled } from './UpdateEmailAlert.styles';
var propTypes = {
    handleCloseModal: PropTypes.func,
    openUpdateEmailAlert: PropTypes.bool.isRequired,
};
var defaultProps = {
    handleCloseModal: null,
};
var isHtmlReg = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
var UpdateEmailAlert = function (_a) {
    var handleCloseModal = _a.handleCloseModal, openUpdateEmailAlert = _a.openUpdateEmailAlert;
    var formatMessage = useIntl().formatMessage;
    var setSnackbarObj = useContext(SnackbarContext).setSnackbarObj;
    var _b = useState({
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
    }), inputs = _b[0], setInputs = _b[1];
    var _c = useState({ email: '', confirmEmail: '' }), errors = _c[0], setErrors = _c[1];
    var userInfo = UserStore.getCurrentUser();
    var returnMessagesTypes = function (code) {
        switch (code) {
            case 200:
                handleCloseModal();
                setSnackbarObj({
                    open: true,
                    status: 'success',
                    message: 'Email was updated succesfully',
                });
                localStorage.removeItem('userLogin');
                break;
            case 300:
                setSnackbarObj({
                    open: true,
                    status: 'error',
                    message: formatMessage({ id: 'Users.loginAlreadyUsed' }),
                });
                break;
            case 400:
                setSnackbarObj({
                    open: true,
                    status: 'error',
                    message: formatMessage({ id: 'Global.ServerErrorTitle' }),
                });
                break;
            default:
                break;
        }
    };
    useEffect(function () {
        if (userInfo) {
            setInputs(function (prevState) { return (__assign(__assign({}, prevState), { firstName: userInfo.firstName, lastName: userInfo.lastName, email: userInfo.email, confirmEmail: userInfo.email })); });
        }
    }, [userInfo]);
    var validateInput = function (id, value) {
        setErrors(function (prev) {
            var _a;
            var stateObj = __assign(__assign({}, prev), (_a = {}, _a[id] = '', _a));
            switch (id) {
                case 'email':
                    if (!value) {
                        stateObj[id] = formatMessage({ id: 'User.enterEmailAddress' });
                    }
                    else if (value.length > 0 && !validateEmail(value)) {
                        stateObj[id] = formatMessage({ id: 'User.invalidEmailAddress' });
                    }
                    else if (inputs.confirmEmail && value !== inputs.confirmEmail) {
                        stateObj.confirmEmail = formatMessage({ id: 'User.emailMatch' });
                    }
                    else {
                        stateObj.confirmEmail = inputs.confirmEmail
                            ? ''
                            : errors.confirmPassword;
                    }
                    break;
                case 'confirmEmail':
                    if (!value) {
                        stateObj[id] = formatMessage({ id: 'User.enterEmailAddress' });
                    }
                    else if (value.length > 0 && !validateEmail(value)) {
                        stateObj[id] = formatMessage({ id: 'User.invalidEmailAddress' });
                    }
                    else if (inputs.email && value !== inputs.email) {
                        stateObj[id] = formatMessage({ id: 'User.emailMatch' });
                    }
                    break;
                default:
                    break;
            }
            return stateObj;
        });
    };
    var handleChangeInfo = function (value, id) {
        setInputs(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[id] = value, _a)));
        });
        validateInput(id, value);
    };
    var handleUpdateUserInfo = function () {
        if (errors.email.length === 0 && errors.confirmEmail.length === 0) {
            var model = { email: inputs.email };
            UserActionCreators.updateUsername(model, function (res) {
                returnMessagesTypes(res.code);
            });
        }
    };
    var message = formatMessage({ id: 'User.updateUserInfoDescription' });
    return (_jsxs(ModalStyled, { className: "modal-wrapper", isOpen: openUpdateEmailAlert, onCancel: handleCloseModal, width: "small", children: [_jsx(ModalHeader, { showClose: false, children: formatMessage({ id: 'User.updateUserInfoTitle' }) }), _jsx(ModalBody, { children: _jsxs("div", { className: "update-email-alert__section", children: [isHtmlReg.test(message) && (_jsx("div", { className: "update-email-alert__section-description", dangerouslySetInnerHTML: {
                                __html: message,
                            } })), _jsxs("div", { className: "update-email-alert__section-first-last-name", children: [_jsx(Input, { label: formatMessage({ id: 'ProfileInfo.Firstname' }), readOnly: true, placeholder: formatMessage({ id: 'ProfileInfo.Firstname' }), type: "text", value: inputs.firstName }), _jsx(Input, { label: formatMessage({ id: 'ProfileInfo.Lastname' }), readOnly: true, placeholder: formatMessage({ id: 'ProfileInfo.Lastname' }), type: "text", value: inputs.lastName })] }), _jsxs("div", { className: "update-email-alert__section-email", children: [_jsxs("div", { children: [_jsx("label", { children: formatMessage({ id: 'ProfileInfo.Emailadress' }) }), _jsxs("span", { className: "email-note", children: [_jsx(Svg, { icon: "info-circle" }), formatMessage({ id: 'User.updateInfoNote' })] }), _jsx(Input, { onChange: function (e) { return handleChangeInfo(e, 'email'); }, error: (errors.email.length > 0 && errors.email) || null, placeholder: formatMessage({ id: 'ProfileInfo.Emailadress' }), type: "text", value: inputs.email })] }), _jsx("div", { className: "update-email-alert__section-confirm-email", children: _jsx(Input, { label: formatMessage({ id: 'User.confirmEmailAddress' }), onChange: function (e) { return handleChangeInfo(e, 'confirmEmail'); }, error: (errors.confirmEmail.length > 0 && errors.confirmEmail) ||
                                            null, placeholder: formatMessage({ id: 'User.confirmEmailAddress' }), type: "text", value: inputs.confirmEmail }) })] })] }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { variant: "outline", color: "primary", onClick: handleCloseModal, title: formatMessage({ id: 'User.skipNow' }), children: formatMessage({ id: 'User.skipNow' }) }), _jsx(Button, { variant: "fill", color: "secondary", onClick: handleUpdateUserInfo, title: formatMessage({ id: 'User.saveInfo' }), children: formatMessage({ id: 'User.saveInfo' }) })] })] }));
};
UpdateEmailAlert.propTypes = propTypes;
UpdateEmailAlert.defaultProps = defaultProps;
export default UpdateEmailAlert;
