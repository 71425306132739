var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
var ExportStore = /** @class */ (function (_super) {
    __extends(ExportStore, _super);
    function ExportStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    ExportStore.prototype.initializeState = function () {
        this.excelItemsPrivate = {
            availableColumns: [],
            selectedColumns: [],
        };
    };
    ExportStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(ExportStore.prototype, "excelItems", {
        get: function () {
            return this.excelItemsPrivate;
        },
        set: function (value) {
            this.excelItemsPrivate = value;
        },
        enumerable: false,
        configurable: true
    });
    ExportStore.prototype.registerToActions = function (payload) {
        var _a;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_EXCEL_HEADER_ITEMS_RESPONSE:
                if ((_a = action.json) === null || _a === void 0 ? void 0 : _a.data) {
                    this.excelItems = action.json.data;
                    this.emitChange();
                }
                else {
                    this.emitError('GET_EXCEL_HEADER_ITEMS');
                }
                break;
            default:
                break;
        }
    };
    return ExportStore;
}(BaseStore));
export default new ExportStore();
