var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, } from 'react';
import ReactDOM from 'react-dom';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import { Wrapper } from './Modal.styles';
function Modal(_a) {
    var isOpen = _a.isOpen, onCancel = _a.onCancel, disableBackDropClick = _a.disableBackDropClick, children = _a.children, _b = _a.width, width = _b === void 0 ? 'medium' : _b, _c = _a.height, height = _c === void 0 ? 'medium' : _c, _d = _a.comeFrom, comeFrom = _d === void 0 ? 'default' : _d, _e = _a.alert, alert = _e === void 0 ? false : _e, _f = _a.closeOnEsc, closeOnEsc = _f === void 0 ? false : _f, _g = _a.maxContent, maxContent = _g === void 0 ? false : _g, restProps = __rest(_a, ["isOpen", "onCancel", "disableBackDropClick", "children", "width", "height", "comeFrom", "alert", "closeOnEsc", "maxContent"]);
    useEffect(function () {
        // exit modal on click Escape button
        var handleKeyDown = function (event) {
            if (closeOnEsc && onCancel && event.code === '27') {
                onCancel(event);
            }
        };
        if (!disableBackDropClick && isOpen) {
            window.addEventListener('keydown', handleKeyDown);
        }
        return function () {
            if (!isOpen)
                window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, closeOnEsc]);
    return isOpen
        ? ReactDOM.createPortal(
        // eslint-disable-next-line
        _jsxs(Wrapper, __assign({ role: "dialog", "$width": width, "$height": height, "$alert": alert, "$maxContent": maxContent }, restProps, { children: [_jsx("div", { onClick: !disableBackDropClick ? onCancel : undefined, onKeyUp: !disableBackDropClick ? onCancel : undefined, tabIndex: 0, role: "button", className: "modal__backdrop", "aria-label": "modal-dialog" }), _jsx("div", { className: "modal__container ".concat(comeFrom === 'kiosEdit' && 'modal-edit-kios-container'), children: children })] })), document.getElementById('modal-root'))
        : null;
}
export default Modal;
export { ModalBody, ModalFooter, ModalHeader };
